import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, ObjectUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import SearchInput from 'utils/SearchInput';

import '../Report.module.css';

/*
 ** [공통 개발시 유의 사항 입니다]
 ** 1. 변수명은 camelCase 로 작성하시길 바랍니다.
 ** 2. 다국어 표현은 <Trans>글자</Trans> 또는 i18n.t('글자')로 부탁 드립니다.
 */
export default class ProjectDateRangeSearch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      disables: {
        dateRange: true,
        submit: true,
      },
      params: {
        field: 'projectCode',
        dateFormat: SessionUtil.getDateFormat(),
        startDate: DateUtil.toDateFormat(new Date()),
        endDate: DateUtil.toDateFormat(new Date()),
      },
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // farmCode 또는 Area 정보가 설정이 되면, 날짜와 검색 버튼이 활성화 됩니다.
    if (prevState.params.projCode !== this.state.params.projCode) {
      const flag = _.isEmpty(this.state.params.projCode);
      this.setState(
        produce((draft) => {
          draft.disables['dateRange'] = flag;
          draft.disables['submit'] = flag;
        }),
        function () {}
      );
    }
  }

  onBtSearch = () => {
    const { params } = this.state;
    this.props.onSearch(ObjectUtil.getClearnedObject(params));
  };

  onChangeRangePicker = (value, dateString) => {
    const that = this;
    this.setState(
      produce((draft) => {
        draft.params['startDate'] = dateString[0];
        draft.params['endDate'] = dateString[1];
      }),
      function () {
        // that.props.setCode(that.props.name, value);
        const { params } = this.state;
        console.log(params);
      }
    );
  };

  onChangeSelect = (value) => {
    this.setState(
      produce((draft) => {
        draft.params['selId'] = value;
      }),
      function () {
        // const { params } = this.state;
        // console.log(params);
      }
    );
  };

  setCode = (name, value) => {
    this.setState(
      produce((draft) => {
        draft.params[name] = value;
      }),
      function () {}
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="f_left langsel">
          <SearchInput
            placeholder={i18n.t('projectCode')}
            style={{
              width: 200,
              marginRight: 20,
              // textTransform: 'uppercase'
            }}
            inputParam="projectCode" // SelectInput의 분류
            inputName="projectCode" // member variable of this.state.params
            inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
            // farmClass="A"
            defaultValue={this.state.params.projCode}
            getValue={(value) => this.setCode('projCode', value)} //***** */
            that={this} //***** */
          />

          {/* 검색기간 설정 */}
          <DatePicker.RangePicker
            defaultValue={[
              moment(this.state.params.startDate, this.state.params.dateFormat),
              moment(this.state.params.endDate, this.state.params.dateFormat),
            ]}
            format={this.state.params.dateFormat}
            onChange={this.onChangeRangePicker}
            allowClear={false}
            disabled={this.state.disables.dateRange}
          />

          <button
            className={this.state.disables.submit ? 'btn_gray' : 'btn_green'}
            disabled={this.state.disables.submit}
            onClick={this.onBtSearch}
          >
            <Trans>Search</Trans>
          </button>
        </div>
      </React.Fragment>
    );
  }
}
