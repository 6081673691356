import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, ObjectUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import '../Report.module.css';

/*
 ** [공통 개발시 유의 사항 입니다]
 ** 1. 변수명은 camelCase 로 작성하시길 바랍니다.
 ** 2. 다국어 표현은 <Trans>글자</Trans> 또는 i18n.t('글자')로 부탁 드립니다.
 */
export default class DateSearch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      params: {
        dateFormat: SessionUtil.getDateFormat(),
        today: DateUtil.now(),
      },
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  // -----------------------------------------------------------
  // 참고용 : ES/ETL 서버 주소(API)를 지정해야 합니다.
  // -----------------------------------------------------------
  fnGetRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/work/selectTcTaskDtl.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtSearch = () => {
    const { params } = this.state;
    this.props.onSearch(ObjectUtil.getClearnedObject(params));
  };

  onChangeRangePicker = (value, dateString) => {
    const that = this;
    this.setState(
      produce((draft) => {
        draft.params['startDate'] = dateString[0];
        draft.params['endDate'] = dateString[1];
      }),
      function () {
        // that.props.setCode(that.props.name, value);
        const { params } = this.state;
        console.log(params);
      }
    );
  };

  onChangeRadio = (e) => {
    const that = this;
    const { name, value } = e.target;
    this.setState(
      produce((draft) => {
        draft.params['kind'] = value;
      }),
      function () {
        const { params } = this.state;
        console.log(params);
      }
    );
  };

  onChangeSelect = (value) => {
    this.setState(
      produce((draft) => {
        draft.params['selId'] = value;
      }),
      function () {
        // const { params } = this.state;
        // console.log(params);
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="f_left langsel">
          {/* Flock/HenHouse 선택 */}

          <DatePicker
            defaultValue={this.state.params.today}
            format={this.state.params.dateFormat}
          />

          <Select placeholder={i18n.t('Select')} onChange={this.onChangeSelect}>
            <Select.Option value="9ho">Search Idx</Select.Option>
          </Select>

          <button className="btn_green" onClick={this.onBtSearch}>
            <Trans>Search</Trans>
          </button>
        </div>
      </React.Fragment>
    );
  }
}
