import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil, PopSearchCust } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { Input, Select, Radio, Popconfirm } from 'antd';
import NumberFormat from 'react-number-format';
import { useAlert } from 'react-alert';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';
const { Search } = Input;

/*
 ** Breeding > Deliver > PS Sales - PsSalesForm
 ** =============================================
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class MoveinForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salesListView: true,
      epDate: DateUtil.today(),
      form: [],
      hhList: [],
      flockList: [],
    };
  }
  componentDidMount() {
    let that = this;
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.setState({ form: this.props.form }, function () {
      that.fnSearch();
      that.hhId.focus();
    });
  }

  fnSearch = async () => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.sql = 'selectTcBreedHhInfoList';

    const url = Server.getRestAPI();
    await axios({
      method: 'post',
      url: url + '/breed/receive/selectSql.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ hhList: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
    data.sql = 'selectFlockList';
    await axios({
      method: 'post',
      url: url + '/breed/release/selectSql.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ flockList: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };

  //Sales 저장
  fnSave = () => {
    let that = this;
    let data = this.state.form;

    let moveQtyF = Number(data.moveQtyF ? data.moveQtyF : '0');
    let moveQtyM = Number(data.moveQtyM ? data.moveQtyM : '0');

    if (!data.hhId) {
      that.props.alert.show(i18n.t('Please enter Hen House.'));
      this.hhId.focus();
      return false;
    }
    if (!data.flockId) {
      that.props.alert.show(i18n.t('Please enter Flock.'));
      this.flockId.focus();
      return false;
    }

    if (!data.moveDate) {
      that.props.alert.show(i18n.t('Please enter Move Date.'));
      this.moveDate.focus();
      return false;
    }
    if (moveQtyF === 0 && moveQtyM === 0) {
      this.props.alert.show(i18n.t('No data entered.'));
    }

    if (moveQtyF + moveQtyM > Number(data.capacity)) {
      this.setState({ visible: true });
    } else {
      this.setState({ visible: false });
      this.confirm(); //수량 정상이면 저장
    }
  };

  confirm = () => {
    let that = this;
    let data = this.state.form;
    let moveQtyF = Number(data.moveQtyF ? data.moveQtyF : 0);
    let moveQtyM = Number(data.moveQtyM ? data.moveQtyM : 0);

    data.fromFarmCode = data.farmCode; //***순서중요 SessionUtil 보다 먼저*/
    data.fromTransferNo = data.transferNo;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.moveDate = data.moveDate ? data.moveDate : DateUtil.today();
    data.moveQtyF = moveQtyF;
    data.moveQtyM = moveQtyM;
    data.iuFlag = 'I';
    // //empty check
    // if (!data.flockId) {
    //   data.flockId = data.fromFlockId;
    //   //data.hhId = data.fromHhId;
    // }

    //data.receiveNo = '';
    //console.log('saveMoveout', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/saveMovein.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        that.props.fnInit();
        that.props.fnClose('moveinFormView');
        if (data === 0)
          return that.props.alert.error(
            i18n.t(
              'The original information has been deleted and cannot be saved.'
            )
          );
        that.props.alert.show(i18n.t('Saved'));
      })
      .catch(function (error) {
        return [];
      });
  };
  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  setOnChange = (e, params) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    that.setState({ form: { ...this.state.form, [col]: val } }, function () {
      if (params.type === 'SELECT' && col === 'hhId') {
        const found = that.state.hhList.find((element) => element.hhId === val);
        that.setState({
          form: {
            ...this.state.form,
            ['flockId']: found.flockId,
            ['capacity']: found.capacity,
          },
        });
      }
      // if (params.type === 'N') {
      //   let closingStock = Number(
      //     that.state.form[col.replace('Release', 'Stock')]
      //   );
      //   if (Number(val) > closingStock) {
      //     that.setState({
      //       form: {
      //         ...that.state.form,
      //         [col]: 0,
      //         [col.replace('Release', 'Qty')]: 0,
      //       },
      //     });
      //     //useAlert.error('You just broke something!');
      //     that.props.alert.show(i18n.t('The quantity is exceeded.'));
      //     return false;
      //   }
      //   that.setState({
      //     form: {
      //       ...that.state.form,
      //       [col.replace('Release', 'Qty')]: Number(val),
      //     },
      //   });
      // }
    });
  };

  //거래처 찾기
  handleSearch = (value) => {};
  handleChange = (value) => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmNmNo();
    data.dateFormat = SessionUtil.getDateFormat();

    data.customerName = value;
    console.log('save', data);
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/selectCustomerList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('savePsSales', response.data);
        const children = [];

        response.data.rows1.forEach((row) => {
          children.push({
            value: row.customerCode,
            text: row.customerName,
          });
        });
        const options = response.data.rows1.map((d) => (
          <Option key={d.value}>{d.text}</Option>
        ));

        that.setState(
          {
            customerList: options,
          },
          function () {
            console.log('savePsSales', that.state);
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  setOnChangeSelect = (value, col) => {
    this.setState({ form: { ...this.state.form, [col]: value } }, function () {
      console.log('%%%%%%', this.state);
    });
  };
  onChangeTransferType = (e, col) => {
    this.setState(
      { form: { ...this.state.form, [col]: e.target.value } },
      function () {
        console.log('onChangeTranferType', this.state);
      }
    );
  };

  fnClosePop = (data) => {
    this.setState({ [data]: false }, function () {});
  };
  setOnChangeCust = (e) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          customerName: e.target.value,
          customerCode: '',
        },
      },
      function () {}
    );
  };
  render() {
    if (!this.props.moveinFormView) return null;
    const data = this.state.form;
    //console.log('=========>', data);
    const Option = Select.Option;
    const hhOptions = this.state.hhList.map((d, i) => (
      <Option key={i} value={d.hhId}>
        {d.hhId}
        {d.flockId ? '(' + d.flockId + ')' : ''}
      </Option>
    ));
    const flockOptions = this.state.flockList.map((d, i) => (
      <Option key={i} value={d.flockId}>
        {d.flockId}
      </Option>
    ));
    return (
      <Fragment>
        <div className="board_list2 mT10">
          <h3>
            <Trans>Move In Info</Trans>
          </h3>
          <table>
            <colgroup>
              <col style={{ width: '20%' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '20%' }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <Trans>Move Out No</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'transferNo', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    disabled: true,
                  })}
                </td>
                <th>
                  <Trans>Order No</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'orderNo', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 200, // px제외 : 없으면 100% */
                    disabled: true,
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Farm</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'farmName', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 210, // px제외 : 없으면 100% */
                    disabled: true,
                  })}
                </td>
                <th>
                  <Trans>Move Out Date</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'fromMoveDate', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    disabled: true,
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Move Out Female</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'fromMoveQtyF', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    disabled: true,
                  })}
                </td>
                <th>
                  <Trans>Move Out Male</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'fromMoveQtyM', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    disabled: true,
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Strain</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'strainName', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 200, // px제외 : 없으면 100% */
                    disabled: true,
                  })}
                </td>
                <th>
                  <Trans>Truck No</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'truckNo', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 200, // px제외 : 없으면 100% */
                    disabled: true,
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="board_list2 mT20 mB20">
          <table>
            <colgroup>
              <col style={{ width: '20%' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '20%' }} />
              <col />
            </colgroup>

            <tbody>
              <tr>
                <th>
                  <Trans>Hen House</Trans> <span className="red">*</span>
                </th>
                <td style={{ textAlign: 'left' }}>
                  {GridUtil.renderInput({
                    inputName: 'hhId', //*** */
                    type: 'SELECT', //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    that: this, //*** */
                    options: hhOptions, //*** */ type:'SELECT' options*/
                    placeholder: 'Hen House', //*** */
                    //width: 210, // px제외 : 없으면 30 */
                  })}
                </td>
                <th>
                  <Trans>Flock</Trans> <span className="red">*</span>
                </th>
                <td style={{ textAlign: 'left' }}>
                  {GridUtil.renderInput({
                    inputName: 'flockId', //*** */
                    type: 'SELECT', //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    that: this, //*** */
                    options: flockOptions, //*** */ type:'SELECT' options*/
                    placeholder: 'Flock', //*** */
                    width: 210, // px제외 : 없으면 30 */
                    disabled: data.flockId ? true : false,
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Female</Trans> <span className="red">*</span>
                </th>
                <td style={{ textAlign: 'left' }}>
                  {GridUtil.renderInput({
                    inputName: 'moveQtyF', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    setOnChange: this.setOnChange, //*** */
                  })}
                </td>
                <th>
                  <Trans>Male</Trans> <span className="red">*</span>
                </th>
                <td style={{ textAlign: 'left' }}>
                  {GridUtil.renderInput({
                    inputName: 'moveQtyM', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    setOnChange: this.setOnChange, //*** */
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Move Date</Trans> <span className="red">*</span>
                </th>
                <td colSpan="3" style={{ textAlign: 'left' }}>
                  {GridUtil.renderInput({
                    inputName: 'moveDate', //*** */
                    type: 'D', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    placeholder: 'moveDate',
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Remarks</Trans>
                </th>
                <td colSpan="3" style={{ textAlign: 'left' }}>
                  {GridUtil.renderInput({
                    inputName: 'moveReason', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    placeholder: '',
                    maxLength: 1000,
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="txt_center mT10 mB10">
          <button
            className="btn_gray"
            onClick={() => this.props.fnClose('moveinFormView')}
          >
            <Trans>Cancel</Trans>
          </button>
          <Popconfirm
            title={i18n.t(
              'Over Capacity\nExceeded the appropriate number.\nWould you like to put it though?'
            )}
            visible={this.state.visible}
            onVisibleChange={this.handleVisibleChange}
            onConfirm={this.confirm} //실행
            onCancel={this.cancel} //this.state.visible:false
            okText={i18n.t('OK')}
            cancelText={i18n.t('Cancel')}
          >
            <button className="btn_save" onClick={() => this.fnSave()}>
              <Trans>save</Trans>
            </button>
          </Popconfirm>
        </div>
      </Fragment>
    );
  }
}
export default withAlert()(MoveinForm);
