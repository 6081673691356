import React, { Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { message } from 'antd';
import '../Hatchery.module.css';

/*
 ** Hatchery - Deliver - PsDiscardDoc - DiscaRDlIST
 **
 ** =============================
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class DiscardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      gridHeight: '350px',
      defaultColDef: {
        filter: true,
        resizable: true,
        editable: true,
        sortable: true,
        suppressSizeToFit: true,
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      paginationPageSize: 10,
      // editType: 'fullRow', // selectEditor와 함께 사용하지 말라고 하네요...
      columnDefs: [
        {
          headerName: i18n.t('Hatchery'),
          field: 'hatcheryId',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('Date'),
          field: 'discardDate',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('F Qty'),
          field: 'psdocFQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('M Qty'),
          field: 'psdocMQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        // {
        //   headerName: i18n.t('Sex Qty'),
        //   field: 'seQty',
        //   type: 'numericColumn',
        //   width: 100,
        //   valueFormatter: this.formatNumber,
        //   cellStyle: { textAlign: 'right' },
        //   suppressSizeToFit: true
        // },
        {
          headerName: i18n.t('S/P Qty'),
          field: 'spQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('A2 Qty'),
          field: 'a2Qty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('A1 Qty'),
          field: 'a1Qty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('BM Qty'),
          field: 'bmQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('B Qty'),
          field: 'bQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('Polos Qty'),
          field: 'polosQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('Hatch Date'),
          field: 'hatchDate',
          width: 130,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: i18n.t('Remarks'),
          width: 200,
          field: 'remarks',
          cellStyle: { textAlign: 'left' },
        },
      ],
      defaultColDef: {
        editable: true,
        sortable: true,
        resizable: true,
        filter: true,
        suppressSizeToFit: false,
      },
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('g==========Props', prevState, nextProps);
    if (prevState.rowData !== nextProps.rowData) {
      //if (nextProps.psDocFormView) prevState.currentInstance.fnSearch();
      return {
        rowData: nextProps.rowData,
      };
    }
    return null;
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  onCellValueChanged = async (params) => {
    const fieldId = params.column.getId(); // 변경된 cell의 field 값이 전달된다.
    const that = this;

    // newValue의 경우 editType: 'fullRow'아닌 경우에만 의미가 있습니다.
    // fullRow 의 경우 data에 이미 변경된 값이 전달 됩니다.
    // 이 경우 data에 검증이 필요합니다. UNDO할 것 인가? APPLY할 것 인가?
    const { data, newValue } = params;
    let param = _.cloneDeep(data);

    param[fieldId] = newValue;

    console.log('newValue:', newValue);
    console.log('param:', param);
  };

  onFilterTextBoxChanged = (e) => {
    const { value } = e.target;
    this.gridApi.setQuickFilter(value);
  };

  render() {
    // return null 은 아무것도 표시하지 않는다는 의미입니다.
    // if (!this.props.localeListView) return null;
    return (
      <Fragment>
        <h3>
          <Trans>Deliver - Discard List</Trans>
        </h3>
        {/*
          <div className="f_left mB20">
            <label htmlFor="">
              <Trans>Filter</Trans>
            </label>
            <input
              type="text"
              className="input_type1"
              onChange={e => this.onFilterTextBoxChanged(e)}
            />
          </div>
          */}
        <div className="board_list">
          <div
            id="myGrid"
            style={{
              height: this.state.gridHeight,
              width: '100%',
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs}
              // components={this.state.components}
              // frameworkComponents={this.state.frameworkComponents}
              defaultColDef={this.state.defaultColDef}
              rowSelection={this.state.rowSelection}
              editType={this.state.editType}
              onGridReady={this.onGridReady}
              //pagination={true}
              //paginationPageSize={this.state.paginationPageSize}
              getRowHeight={(e) => GridUtil.getRowHeight(e)}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
