import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import { moment } from 'components';
import i18n from 'i18next';
import '../Breeding.module.css';
/*
 ** breeding/receive/MovingLog
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class MovingLogList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      columnDefs: [
        {
          headerName: 'Seq No',
          field: 'seqNo',
          width: 80,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Move Date',
          field: 'moveDate',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Flock ID',
          field: 'flockId',
          width: 180,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Hen House',
          field: 'hhId',
          width: 180,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        // {
        //   headerName: 'BeforeMove',
        //   children: [
        //     {
        //       headerName: 'F Qty',
        //       field: 'qtyBeforeMoveF',
        //       type: 'numericColumn',
        //       width: 100,
        //       valueFormatter: this.formatNumber,
        //       cellStyle: { textAlign: 'right' },
        //       suppressSizeToFit: true
        //     },
        //     {
        //       headerName: 'M Qty',
        //       field: 'qtyBeforeMoveM',
        //       type: 'numericColumn',
        //       width: 100,
        //       valueFormatter: this.formatNumber,
        //       cellStyle: { textAlign: 'right' },
        //       suppressSizeToFit: true
        //     }
        //   ]
        // },
        {
          headerName: 'Move',
          children: [
            {
              headerName: 'Female Qty',
              field: 'moveQtyF',
              type: 'numericColumn',
              width: 120,
              valueFormatter: this.formatNumber,
              cellStyle: { textAlign: 'right' },
              suppressSizeToFit: true,
            },
            {
              headerName: 'Male Qty',
              field: 'moveQtyM',
              width: 120,
              type: 'numericColumn',
              valueFormatter: this.formatNumber,
              //precision: 0,
              cellStyle: { textAlign: 'right' },
              suppressSizeToFit: true,
            },
          ],
        },
        // {
        //   headerName: 'After Move',
        //   children: [
        //     {
        //       headerName: 'F Qty',
        //       field: 'qtyAfterMoveF',
        //       type: 'numericColumn',
        //       width: 100,
        //       valueFormatter: this.formatNumber,
        //       cellStyle: { textAlign: 'right' },
        //       suppressSizeToFit: true
        //     },
        //     {
        //       headerName: 'M Qty',
        //       field: 'qtyAfterMoveM',
        //       type: 'numericColumn',
        //       width: 100,
        //       valueFormatter: this.formatNumber,
        //       cellStyle: { textAlign: 'right' },
        //       suppressSizeToFit: true
        //     }
        //   ]
        // },
        {
          headerName: 'Remarks',
          field: 'moveReason',
          width: 200,
          cellStyle: { textAlign: 'left' },
          suppressSizeToFit: false,
        },
      ],
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        suppressSizeToFit: false,
      },
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }
  onRowClicked = (e) => {
    //console.log('onRowClicked', e.data);
  };
  onCellClicked = (e) => {
    if (e.column.colId === 'ck') {
      //선택버튼 클릭
      this.props.fnSelectHatchery(e.data);
    }
  };
  formatNumber = (params) => {
    return Math.floor(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  ageCellRendererFunc = (params) => {
    //params.$scope.ageClicked = ageClicked;
    //return '<button ng-click="ageClicked(data.age)" ng-bind="data.age"></button>';
    return '<button ng-click="ageClicked(data.age)">Age</button>';
  };
  render() {
    if (this.props.movingLogList && this.props.movingLogList.length > 0) {
    } else return null;
    //console.log('this.props.rowData', this.props.movingLogList);
    return (
      <Fragment>
        {/* 디자인 영역 */}

        <h3 className="mT20">
          <Trans>Move History</Trans>
        </h3>

        <div
          style={{ height: '300px', width: '100%' }}
          className="board_list ag-theme-balham"
        >
          <AgGridReact
            onGridReady={this.onGridReady}
            columnDefs={this.state.columnDefs}
            rowData={this.props.movingLogList}
            defaultColDef={this.state.defaultColDef}
            onRowClicked={(e) => this.onRowClicked(e)}
            onCellClicked={(e) => this.onCellClicked(e)}
          ></AgGridReact>
        </div>
      </Fragment>
    );
  }
}
export default MovingLogList;
