/* eslint-disable no-undef */
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import { DateUtil } from 'utils';

import '../Breeding.module.css';
/*
 ** breeding/receive/FlockList
 ** 입고저장 후 계군에 저장
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class PopFlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      tpBreedRecvData: {},
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    console.log('***** FlockList');

    this.setState(
      { tpBreedRecvData: this.props.tpBreedRecvData },
      function () {}
    );
    this.fnSearch();
    this.fnNewFlock();
  }
  fnSearch = () => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    //data.receiveNo = '';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/selectBreedReceiveFlockList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('searchFlockList', response.data);

        that.setState({ rowData: response.data.rows1 }, function () {
          console.log(that.state);
        });
      })
      .catch(function (error) {
        return [];
      });
  };
  fnSave = (e) => {
    //validate
    let that = this;
    let data = this.state.form;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.iuFlag = 'I';
    data.flockId = '20'; //"계군 ID/ Flock ID    계군 입식시 자체 생성    품종(2) + 년도(2) + 농장약어코드(3) + Serial(5)"

    console.log('this.state.recvQtyF', data);
    if (!data.flockInQtyF) data.flockInQtyF = 0;
    if (!data.flockInQtyM) data.flockInQtyM = 0;

    console.log('this.state.recvQtyF', data);
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/saveFlockInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('saveFlockInfo', response.data);
        that.fnNewFlock();
        that.fnSearch();
        //this.setState({ form: {} }, function() {});
      })
      .catch(function (error) {
        return [];
      });
  };
  fnCancel = (e) => {
    this.props.fnCancelFromHatchery(this.state.form);
  };
  onCellClicked = (e) => {
    console.log('onCellClicked', e);
    if (e.column.colId === 'ck') {
      //선택버튼 클릭
      this.props.fnSelectFlock(e.data, this.state.tpBreedRecvData);
    }
  };
  setOnChange = (e) => {
    const col = e.target.name ? e.target.name : '';
    this.setState(
      {
        form: { ...this.state.form, [col]: col, [col]: e.target.value },
      },
      function () {
        //console.log('=====>', this.state);
      }
    );
  };
  setChangeSelect = (e) => {
    const col = e.target.name ? e.target.name : '';
    //console.log("setChange", e.target);
    const txt = e.target.options[e.target.selectedIndex].text;
    this.setState(
      {
        form: { ...this.state.form, [col]: txt, [col]: e.target.value },
      },
      function () {}
    );
  };
  fnNewFlock = (e) => {
    let data = {};
    data.iuFlag = 'I';
    data.flockCdate = DateUtil.today();
    data.flockInQtyF = 0;
    data.flockInQtyM = 0;
    data.strainCode = '1';
    data.remarks = '';

    this.setState({ form: data }, function () {});
  };
  render() {
    if (!this.props.popFlock) return null;
    return (
      <Fragment>
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            margin: 'auto',
            zIndex: 1000,
            backgroundColor: 'rgba(0,0,0, 0.5)',
          }}
        >
          {/* 디자인 영역 */}
          <div
            style={{
              position: 'absolute',
              width: '900px',
              height: 'auto',
              left: '50%',
              transform: 'translateX(-50%)',
              top: '15%',
              bottom: '15%',
              margin: 'auto',
              borderRadius: '10px',
              background: 'white',
            }}
          >
            {/* 디자인 영역 */}
            <h2>
              <Trans>FlockList</Trans>
            </h2>

            <div className="con_box">
              <div className="board_list2 mB20">
                <table>
                  <colgroup>
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '35%' }} />
                    <col style={{ width: '15%' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <Trans>Create Date</Trans>
                      </th>
                      <td>
                        <input
                          type="text"
                          name="flockCdate"
                          className="input_type1"
                          value={this.state.form.flockCdate || ''}
                          onChange={(e) => this.setOnChange(e)}
                        />
                      </td>
                      <th>
                        <Trans>Strain</Trans>
                      </th>
                      <td>
                        <select
                          name="strainCode"
                          title=""
                          className="sel_type2"
                          value={this.state.form.strainCode || ''}
                          onChange={(e) => this.setChangeSelect(e)}
                        >
                          <option value="1">ROSS FF</option>
                          <option value="2">COBB SF</option>
                          <option value="3">COBB FF</option>
                          <option value="4">Hubbard</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans>Remarks</Trans>
                      </th>
                      <td>
                        <input
                          type="text"
                          name="remarks"
                          className="input_type1"
                          value={this.state.form.remarks || ''}
                          onChange={(e) => this.setOnChange(e)}
                        />
                      </td>
                      <th>
                        <Trans>Qty</Trans>
                      </th>
                      <td className="spac50_left">
                        <label htmlFor="">
                          <Trans>F</Trans>
                        </label>
                        <input
                          type="text"
                          name="flockInQtyF"
                          className="input_type2"
                          value={this.state.form.flockInQtyF || ''}
                          onChange={(e) => this.setOnChange(e)}
                        />
                        <label htmlFor="">
                          <Trans>M</Trans>
                        </label>
                        <input
                          type="text"
                          name="flockInQtyM"
                          className="input_type2"
                          value={this.state.form.flockInQtyM || ''}
                          onChange={(e) => this.setOnChange(e)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="f_right mB10">
                <button className="btn_blue" onClick={(e) => this.fnNewFlock()}>
                  <Trans>New Flock</Trans>
                </button>

                <button className="btn_save" onClick={(e) => this.fnSave()}>
                  <Trans>Save</Trans>
                </button>
                <button
                  className="btn_gray"
                  onClick={(e) => this.props.fnClosePopFlock()}
                >
                  <Trans>Cancel</Trans>
                </button>
              </div>
            </div>

            {/* 디자인 영역 */}
          </div>
        </div>
      </Fragment>
    );
  }
}
export default PopFlock;
