import React, { Fragment } from 'react';
import {
  SessionUtil,
  DateUtil,
  GridUtil,
  PopSearchCust,
  NumericEditor,
} from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import { Const, Server } from 'components/Properties';
import { Select, Radio, Input, Modal } from 'antd';
import axios from 'axios';
import qs from 'qs';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import NumberFormat from 'react-number-format';
import i18n from 'i18next';
import EggRelease from './EggRelease';
import EggSalesList from './EggSalesList';
import scrollToComponent from 'react-scroll-to-component';
import SearchInput from '../receive/SearchInput';
import { withAlert } from 'react-alert';
import '../Hatchery.module.css';
const { Search } = Input;
const { confirm } = Modal;

/*
 ** Hatchery - Transfer - Egg 부화장 계란 출고
 ** Hatchery - Deliver - Egg Sales
 ** TL_EGG_TRANSFER_MAST
 ** TL_CE_TRANSFER_DTL
 ** 부화장에 입고된 Egg 중 검란(Grading)이 끝나고 분류된 Egg를 출고(판매 또는 기부) 한다.
 ** ==========================================
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class EggSales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInstance: this,
      customerList: [],
      form: [
        {
          transferType: 'S',
          searchFromWkDt: moment(DateUtil.today())
            .add('-1', 'M')
            .format('YYYY-MM-DD'),
          searchToWkDt: DateUtil.today(),
        },
      ],
      deliverSalesQtyList: [],
      eggReleaseFormView: false,
      broilerDocFormView: false,
      dataListView: false,
      eggReleaseForm: [],
      broilerDocForm: [],
      rowData: [],
      tlEggTransferForm: [],
      eggTransferMastList: [],
      eggTransferDtlList: [],
      selectInfo: '',
      columnDefs: [],
      columnDefsEggTransferMast: [
        {
          headerName: 'Date',
          field: 'transferDate',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Type',
          field: 'transferTypeText',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Customer',
          field: 'customerName',
          width: 150,
          cellStyle: { textAlign: 'left' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Miss Shape',
          children: [
            {
              headerName: 'Qty',
              field: 'missShapeEggQty',
              type: 'numericColumn',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
            },
            {
              headerName: 'Weight',
              field: 'missShapeEggWeight',
              type: 'numericColumn',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
            },
          ],
        },
        {
          headerName: 'Crack',
          children: [
            {
              headerName: 'Qty',
              field: 'crackEggQty',
              type: 'numericColumn',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
            },
            {
              headerName: 'Weight',
              field: 'crackEggWeight',
              type: 'numericColumn',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
            },
          ],
        },
        {
          headerName: 'Dirty',
          children: [
            {
              headerName: 'Qty',
              field: 'dirtyEggQty',
              type: 'numericColumn',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
            },
            {
              headerName: 'Weight',
              field: 'dirtyEggWeight',
              type: 'numericColumn',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
            },
          ],
        },
        {
          headerName: 'Jumbo',
          children: [
            {
              headerName: 'Qty',
              field: 'jumboEggQty',
              type: 'numericColumn',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
            },
            {
              headerName: 'Weight',
              field: 'jumboEggWeight',
              type: 'numericColumn',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
            },
          ],
        },
        {
          headerName: 'Small',
          children: [
            {
              headerName: 'Qty',
              field: 'smallEggQty',
              type: 'numericColumn',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
            },
            {
              headerName: 'Weight',
              field: 'smallEggWeight',
              type: 'numericColumn',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
            },
          ],
        },
        {
          headerName: 'Thin',
          children: [
            {
              headerName: 'Qty',
              field: 'thinEggQty',
              type: 'numericColumn',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
            },
            {
              headerName: 'Weight',
              field: 'thinEggWeight',
              type: 'numericColumn',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
            },
          ],
        },
        {
          headerName: 'Cutting',
          children: [
            {
              headerName: 'Qty',
              field: 'cuttingEggQty',
              type: 'numericColumn',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
            },
            {
              headerName: 'Weight',
              field: 'cuttingEggWeight',
              type: 'numericColumn',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
            },
          ],
        },
        {
          headerName: '',
          field: 'delete',
          suppressSizeToFit: false,
          width: 70,
          cellStyle: { textAlign: 'center' },
          editable: false,
          cellRenderer: (params) => {
            return '<button class="btn_sel">' + i18n.t('Delete') + '</button>';
          },
          suppressSizeToFit: true,
        },
      ],
      columnDefsEggTransferDtl: [
        {
          headerName: 'Date',
          field: 'receiveDate',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Miss Shape',
          field: 'missShapeEggQty',
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'Crack',
          field: 'crackEggQty',
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'Dirty',
          field: 'dirtyEggQty',
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'Jumbo',
          field: 'jumboEggQty',
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'Small',
          field: 'smallEggQty',
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'Thin',
          field: 'thinEggQty',
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'Cutting',
          field: 'cuttingEggQty',
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
      ],
      defaultColDef: {
        editable: false,
        width: 100,
        filter: true,
        resizable: true,
        sortable: true,
        suppressSizeToFit: false,
      },
      frameworkComponents: {
        numericEditor: NumericEditor,
      },
      editType: 'fullRow',
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fileInputRef = React.createRef();
    this.hatchDate = React.createRef();
    this.transferDate = React.createRef();

    this.fnInit();
  }
  fnSearch = async () => {
    let that = this;
    await this.setState({ dataListView: true }, function () {});

    window.scrollTo(0, 0);

    //거래처 리스트 불러오기
    //this.selectCustomerList("'11','12','13','14'");

    let data = {};
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.countryCode = SessionUtil.getCountryCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.searchFromWkDt = this.state.form.searchFromWkDt;
    data.searchToWkDt = this.state.form.searchToWkDt;

    //data.receiveNo = '';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/transfer/selectTransferEggkList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            selectInfo: i18n.t('Detail'),
            deliverSalesQtyList: response.data.rows1 ? response.data.rows1 : [],
            eggTransferMastList: response.data.rows2 ? response.data.rows2 : [],
            eggTransferDtlList: [],
            columnDefs: that.fnColumnDefs(),
          },
          function () {
            //console.log('selectGlowingLogList', response.data.rows2.length);
            if (response.data.rows2 && response.data.rows2.length > 0) {
              that.onCellClicked(that.gridApiMast.getRowNode(0));
            } else {
            }
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  fnColumnDefs = () => {
    let that = this;
    let columnDefs = [
      {
        headerName: 'Ep Date',
        field: 'receiveDate',
        width: 100,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: true,
      },
    ];
    let hhList = [
      'MissShape,missShape',
      'Crack,crack',
      'Dirty,dirty',
      'Jumbo,jumbo',
      'Small,small',
      'Thin,thin',
      'Cutting(Grade),cutting',
    ];

    hhList.forEach((row) => {
      let field = row.split(',');
      let headDef = {};
      let childrenDef1 = {};
      let childrenDef2 = {};
      let childrenDef = [];

      childrenDef1.headerName = 'Qty';
      childrenDef1.field = field[1] + 'EggQty';
      childrenDef1.type = 'numericColumn';
      childrenDef1.suppressSizeToFit = true;
      (childrenDef1.valueFormatter = function (params) {
        return GridUtil.formatNumber(params);
      }),
        (childrenDef1.cellStyle = function (params) {
          return GridUtil.getEditCell('D', 100);
        });

      childrenDef2.headerName = 'Sales'; //            field: 'crackEggSaleQty',
      childrenDef2.field = field[1] + 'EggSaleQty';
      childrenDef2.type = 'numericColumn';
      childrenDef2.suppressSizeToFit = true;
      childrenDef2.cellEditor = 'numericEditor';
      childrenDef2.cellEditorParams = function () {
        return {
          handleChange: (e) => that.fnGridHandleChange(e),
        };
      };
      childrenDef2.editable = function (params) {
        if (params.data[field[1] + 'EggQty'] === 0) return false;
        else return true;
      };
      childrenDef2.valueFormatter = function (params) {
        return GridUtil.formatNumber(params);
      };
      childrenDef2.cellStyle = function (params) {
        if (params.data[field[1] + 'EggQty'] === 0)
          return GridUtil.getEditCell('P', 100);
        else return GridUtil.getEditCell('E', 100);
      };
      headDef.headerName = field[0];
      headDef.children = [childrenDef1, childrenDef2];
      columnDefs.push(headDef);
    });

    return columnDefs;
  };
  /*
   * Ag-Grid key Down 수량 체크 및 합계 처리
   */
  fnGridHandleChange = (params, op) => {
    let that = this;
    let col = params.column.colId.replace('SaleQty', 'Qty');
    let qty = Number(params[col] ? params[col] : 0);
    // if (Number(params[params.column.colId]) > qty) {
    //   that.props.alert.show(i18n.t('The quantity is exceeded.'));
    //   that.gridApi.stopEditing();
    //   var rowNode = that.gridApi.getDisplayedRowAtIndex(params.rowIndex);
    //   rowNode.setDataValue(params.column.colId, 0);
    //   //that.gridApi.tabToPreviousCell();
    //   that.gridApi.setFocusedCell(params.rowIndex, params.column.colId);
    // } else {
    that.printAllDisplayedRows(params.column.colId, params.value); //현재입력받는 숫자도 더함
    // }
  };
  fnGridHandleChange = (params, op) => {
    let that = this;
    let col = params.column.colId.replace('SalesQty', 'Qty');
    let qty = Number(params[col] ? params[col] : 0);
    // AgGrid 음수 입력 방지 기능
    if (Number(params.val) < 0 || params.val === '-') {
      that.gridApi.stopEditing();
      var rowNode = that.gridApi.getDisplayedRowAtIndex(params.rowIndex);
      rowNode.setDataValue(params.column.colId, 0);
      that.gridApi.setFocusedCell(params.rowIndex, params.column.colId);
      that.gridApi.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: params.column.colId,
      });
      return;
    }
    that.printAllDisplayedRows(params.column.colId, params.value); //현재입력받는 숫자도 더함
  };
  //Sales 저장
  fnSave = () => {
    let that = this;
    let data = this.state.form;
    let validationChk = 0;
    data.iuFlag = 'I';

    let eggGoodList = [];

    this.gridApi.stopEditing();

    if (!data.transferDate) {
      that.props.alert.show(i18n.t('Please enter transferDate.'));
      this.transferDate.current.focus();
      return false;
    }

    if (!data.customerCode) {
      that.props.alert.show(i18n.t('Please enter customerCode.'));

      //this.setState({ customerCodeFocus: true }, function() {});
      this.customerName.focus();
      return false;
    }

    if (!checkQty(that)) {
      return false;
    }

    let goodEggQty = 0;
    let crackEggQty = 0;
    let dirtyEggQty = 0;
    let jumboEggQty = 0;
    let smallEggQty = 0;
    let thinEggQty = 0;
    let missShapeEggQty = 0;
    let cuttingEggQty = 0;

    this.gridApi.forEachNode(function (rowNode, index) {
      let crackEggSaleQty = rowNode.data.crackEggSaleQty
        ? Number(rowNode.data.crackEggSaleQty)
        : 0;
      let dirtyEggSaleQty = rowNode.data.dirtyEggSaleQty
        ? Number(rowNode.data.dirtyEggSaleQty)
        : 0;
      let jumboEggSaleQty = rowNode.data.jumboEggSaleQty
        ? Number(rowNode.data.jumboEggSaleQty)
        : 0;
      let smallEggSaleQty = rowNode.data.smallEggSaleQty
        ? Number(rowNode.data.smallEggSaleQty)
        : 0;
      let thinEggSaleQty = rowNode.data.thinEggSaleQty
        ? Number(rowNode.data.thinEggSaleQty)
        : 0;
      let missShapeEggSaleQty = rowNode.data.missShapeEggSaleQty
        ? Number(rowNode.data.missShapeEggSaleQty)
        : 0;
      let cuttingEggSaleQty = rowNode.data.cuttingEggSaleQty
        ? Number(rowNode.data.cuttingEggSaleQty)
        : 0;

      if (
        rowNode.data.crackEggQty < crackEggSaleQty ||
        rowNode.data.dirtyEggQty < dirtyEggSaleQty ||
        rowNode.data.jumboEggQty < jumboEggSaleQty ||
        rowNode.data.smallEggQty < smallEggSaleQty ||
        rowNode.data.thinEggQty < thinEggSaleQty ||
        rowNode.data.missShapeEggQty < missShapeEggSaleQty ||
        rowNode.data.cuttingEggQty < cuttingEggSaleQty
      ) {
        validationChk = 1;
        return false;
      } else {
        validationChk = 0;
      }

      console.log(crackEggSaleQty);

      if (
        crackEggSaleQty > 0 ||
        dirtyEggSaleQty > 0 ||
        jumboEggSaleQty > 0 ||
        smallEggSaleQty > 0 ||
        thinEggSaleQty > 0 ||
        missShapeEggSaleQty > 0 ||
        cuttingEggSaleQty > 0
      ) {
        let dataDef = {};
        dataDef.receiveDate = rowNode.data.receiveDate;
        dataDef.crackEggQty = Number(crackEggSaleQty);
        dataDef.dirtyEggQty = Number(dirtyEggSaleQty);
        dataDef.jumboEggQty = Number(jumboEggSaleQty);
        dataDef.smallEggQty = Number(smallEggSaleQty);
        dataDef.thinEggQty = Number(thinEggSaleQty);
        dataDef.missShapeEggQty = Number(missShapeEggSaleQty);
        dataDef.cuttingEggQty = Number(cuttingEggSaleQty);

        crackEggQty += Number(crackEggSaleQty);
        dirtyEggQty += Number(dirtyEggSaleQty);
        jumboEggQty += Number(jumboEggSaleQty);
        smallEggQty += Number(smallEggSaleQty);
        thinEggQty += Number(thinEggSaleQty);
        missShapeEggQty += Number(missShapeEggSaleQty);
        cuttingEggQty += Number(cuttingEggSaleQty);

        eggGoodList.push(dataDef);
      }
    });

    data.eggGoodList = eggGoodList;
    data.eggGoodLength = eggGoodList.length;

    // if (
    //   goodEggQty > 0 ||
    //   crackEggQty > 0 ||
    //   dirtyEggQty > 0 ||
    //   jumboEggQty > 0 ||
    //   smallEggQty > 0 ||
    //   thinEggQty > 0 ||
    //   missShapeEggQty > 0 ||
    //   cuttingEggQty > 0
    // ) {
    // } else {
    //   that.props.alert.show(i18n.t('Please enter.'));

    //   return false;
    // }

    if (validationChk === 1) {
      that.props.alert.show(
        i18n.t('The quantity available for sale has been exceeded')
      );
      return false;
    }

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.countryCode = SessionUtil.getCountryCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.updateId = SessionUtil.getUserId();

    console.log('save', data);
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/transfer/saveEggSales.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.alert.show(i18n.t('Has been saved.'));

        that.fnInit();
        that.fnSearch();
      })
      .catch(function (error) {
        return [];
      });

    //수량체크
    function checkQty(that) {
      let clos = [
        'missShapeEggQty',
        'crackEggQty',
        'dirtyEggQty',
        'jumboEggQty',
        'smallEggQty',
        'thinEggQty',
        'cuttingEggQty',
      ];
      let chk = true;
      clos.some((col) => {
        let co = col.replace('SaleQty', 'Qty');
        let hatchingQty = 0;
        that.gridApi.forEachNode(function (rowNode, index) {
          hatchingQty += Number(rowNode.data[col]);

          console.log(rowNode.data);

          if (rowNode.data.crackEggQty < Number(rowNode.data.crackEggSaleQty)) {
            chk = false;
          }
          if (rowNode.data.dirtyEggQty < Number(rowNode.data.dirtyEggSaleQty)) {
            chk = false;
          }
          if (rowNode.data.jumboEggQty < Number(rowNode.data.jumboEggSaleQty)) {
            chk = false;
          }
          if (
            rowNode.data.missShapeEggQty <
            Number(rowNode.data.missShapeEggSaleQty)
          ) {
            chk = false;
          }
          if (rowNode.data.smallEggQty < Number(rowNode.data.smallEggSaleQty)) {
            chk = false;
          }
          if (rowNode.data.thinEggQty < Number(rowNode.data.thinEggSaleQty)) {
            chk = false;
          }
        });

        if (chk == false) {
          that.props.alert.show(i18n.t('The quantity is exceeded.'));
          chk = false;
          return true;
        }
      });
      return chk;
    }
  };
  fnInit = () => {
    let that = this;
    this.setState(
      {
        dataListView: false,
        form: {
          //...that.state.form,전체clear
          transferDate: DateUtil.today(),
          transferType: 'S',
          searchFromWkDt: moment(DateUtil.today())
            .add('-1', 'M')
            .format('YYYY-MM-DD'),
          searchToWkDt: DateUtil.today(),
        },
      },
      function () {
        that.fnSearch();
      }
    );
  };
  onGridReadyMast = (params) => {
    this.gridApiMast = params.api;
    params.api.sizeColumnsToFit();
  };
  onGridReady = (params) => {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  };
  onGridReadyEdit = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  //Grid row 선택
  onCellClicked = async (e) => {
    let that = this;

    let data = e.data;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.countryCode = SessionUtil.getCountryCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.userId = SessionUtil.getUserId();

    const url = Server.getRestAPI();
    await axios({
      method: 'post',
      url: url + '/hatchery/transfer/selectTlEggTransferDtlList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        //console.log('onCellClicked', response.data.rows1);
        that.setState(
          {
            selectInfo:
              e.data.transferDate +
              '  ' +
              e.data.customerName +
              i18n.t('  Detail'),
            eggTransferDtlList: response.data.rows1,
          },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });

    if (e.column && e.column.colId === 'delete') {
      confirm({
        title: i18n.t('Are you sure delete this task?'),
        //icon: <ExclamationCircleOutlined />,
        //content: 'Some descriptions',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          that.fnDelete(e.data);
        },
        onCancel() {},
      });
    }
  };

  fnDelete = (data) => {
    let that = this;

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.updateId = SessionUtil.getUserId();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.iuFlag = 'D';
    //console.log('data', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/transfer/saveEggSales.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.fnInit();
      })
      .catch(function (error) {
        return [];
      });
  };

  fnClickRelease = () => {
    this.setState(
      {
        broilerDocFormView: false,
        eggReleaseFormView: true,
        eggReleaseForm: {
          iuFlag: 'I',
          hatchDate: DateUtil.today(),
          transferDate: DateUtil.today(),
        },
      },
      function () {
        scrollToComponent(this['eggReleaseFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };

  setOnChange = (e, params) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }

    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    this.setState({ form: { ...this.state.form, [col]: val } }, function () {
      if (params.type === 'N') {
      }
    });
  };

  excelBtnClick = (e) => {
    this.fileInputRef.current.click();
  };
  onChangeTransferType = (e, col) => {
    this.setState(
      { form: { ...this.state.form, [col]: e.target.value } },
      function () {
        console.log('onChangeTranferType', this.state);
      }
    );
  };
  //거래처 찾기
  selectCustomerList = (value) => {
    let data = {};
    let that = this;
    data.dateFormat = SessionUtil.getDateFormat();
    data.customerName = '';
    data.customerKind = value;
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/selectCustomerList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ customerList: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };
  formatNumber = (params) => {
    if (params.value) {
      return Math.floor(params.value)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else return '';
  };
  onCellValueChanged = (params) => {
    let that = this;
    let col = params.column.colId.replace('SaleQty', 'Qty');
    var rowNode = this.gridApi.getDisplayedRowAtIndex(params.rowIndex);
    let qty = Number(rowNode.data[col] ? rowNode.data[col] : 0);
    if (Number(params.value) > qty) {
      that.gridApi.setFocusedCell(params.rowIndex, params.column.colId);
    } else {
      this.printAllDisplayedRows(params.column.colId);
    }
  };
  //Egg Goog List edit
  printAllDisplayedRows = (col) => {
    var count = this.gridApi.getDisplayedRowCount();
    let qty = 0;
    let tot = 0;
    for (var i = 0; i < count; i++) {
      var rowNode = this.gridApi.getDisplayedRowAtIndex(i);
      tot += Number(rowNode.data[col] ? rowNode.data[col] : 0);
    }

    col = col.replace('EggSaleQty', '') + 'EggQty';
    this.setState(
      {
        form: {
          ...this.state.form,
          [col]: tot,
        },
      },
      function () {}
    );
  };
  setOnChangeSelect = (value, col) => {
    this.setState(
      { form: { ...this.state.form, [col]: value } },
      function () {}
    );
  };
  fnSearchCust = (value) => {
    this.setState(
      {
        popSearchCustView: true,
        customerCode: value,
        customerKind: ['11', '12', '13', '14'],
      },
      function () {}
    );
  };
  fnSelectCust = (data) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          customerCode: data.customerCode,
          customerName: data.customerName,
        },
      },
      function () {}
    );
  };
  fnClosePop = (data) => {
    this.setState({ [data]: false }, function () {});
  };
  setOnChangeCust = (e) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          customerCode: e.target.value,
          customerName: '',
        },
      },
      function () {}
    );
  };
  render() {
    let form = this.state.form;
    const Option = Select.Option;
    const customerOptions = this.state.customerList.map((d, i) => (
      <Option key={i} value={d.customerCode}>
        {d.customerName}
      </Option>
    ));

    return (
      <Fragment>
        <h2>
          <Trans>Deliver - Egg Sales</Trans>
        </h2>

        <div className="con_box">
          <h3>Egg Sale List</h3>
          <div className="f_right">
            <label htmlFor="">
              <Trans>Date</Trans>
            </label>
            {GridUtil.renderInput({
              inputName: 'searchFromWkDt', //*** */
              type: 'D', //*** */
              that: this, //*** */
              statsForm: this.state.form, //*** */
              setOnChange: this.setOnChange, //*** */
              width: 120, // px제외 : 없으면 100% */
              placeholder: 'Date',
            })}
            &nbsp; ~ &nbsp;
            {GridUtil.renderInput({
              inputName: 'searchToWkDt', //*** */
              type: 'D', //*** */
              that: this, //*** */
              statsForm: this.state.form, //*** */
              setOnChange: this.setOnChange, //*** */
              width: 120, // px제외 : 없으면 100% */
              placeholder: 'Date',
            })}
            <button className="btn_gray" onClick={() => this.fnSearch()}>
              <Trans>Search</Trans>
            </button>
          </div>
          {this.state.dataListView ? (
            <div
              className="board_list ag-theme-balham"
              style={{ height: '300px', width: '100%' }}
            >
              <AgGridReact
                columnDefs={this.state.columnDefsEggTransferMast}
                defaultColDef={this.state.defaultColDef}
                rowData={
                  this.state.eggTransferMastList.length > 0
                    ? this.state.eggTransferMastList
                    : []
                }
                onCellClicked={(e) => this.onCellClicked(e)}
                onGridReady={this.onGridReadyMast}
                rowSelection={'single'}
                onCellFocused={(e) => {
                  e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
                }}
              ></AgGridReact>
            </div>
          ) : null}

          <div className="mT20">
            <h3 htmlFor="">{this.state.selectInfo}</h3>
          </div>
          <div
            className="board_list mB20 ag-theme-balham"
            style={{ height: '300px', width: '100%' }}
          >
            <AgGridReact
              columnDefs={this.state.columnDefsEggTransferDtl}
              defaultColDef={this.state.defaultColDef}
              rowData={
                this.state.eggTransferDtlList.length > 0
                  ? this.state.eggTransferDtlList
                  : []
              }
              onGridReady={this.onGridReady}
              getRowHeight={(e) => GridUtil.getRowHeight(e)}
            />
          </div>
          <h3>
            <Trans>Egg Sale</Trans>
          </h3>
          <div className="layer_list w100">
            <div className="board_list2">
              <table>
                <colgroup>
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '30%' }} />
                  <col style={{ width: '20%' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <Trans>Date</Trans>
                    </th>
                    <td>
                      {GridUtil.renderInput({
                        inputName: 'transferDate', //*** */
                        type: 'D', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                        placeholder: 'transferDate',
                      })}
                    </td>
                    <th>
                      <Trans>Type</Trans>
                    </th>

                    <td className="spac50_right">
                      <Radio.Group
                        onChange={(e) =>
                          this.onChangeTransferType(e, 'transferType')
                        }
                        value={this.state.form.transferType}
                      >
                        <Radio value={'S'}>Sales</Radio>
                        <Radio value={'D'}>Donation</Radio>
                      </Radio.Group>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Trans>Customer</Trans>
                      {this.state.tlEggTransferForm.customerName}
                    </th>
                    <td>
                      <Search
                        placeholder="Customer Code"
                        onSearch={(value) => this.fnSearchCust(value)}
                        enterButton
                        //style={{ width: '300px' }}
                        className="w100"
                        ref={(input) => {
                          this['customerCode'] = input;
                        }}
                        value={this.state.form.customerCode}
                        onChange={(value) => this.setOnChangeCust(value)}
                      />
                    </td>
                    <th>
                      <Trans>Truck No.</Trans>
                    </th>
                    <td>
                      {GridUtil.renderInput({
                        inputName: 'truckNo', //*** */
                        type: '', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                        maxLength: 10,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Trans>Remarks</Trans>
                    </th>
                    <td>
                      {GridUtil.renderInput({
                        inputName: 'remarks', //*** */
                        type: '', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        //width: 120, // px제외 : 없으면 100% */
                        maxLength: 1000,
                      })}
                    </td>
                    <th></th>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              className="board_list ag-theme-balham mT30"
              style={{ height: '200px', width: '100%' }}
            >
              <AgGridReact
                onGridReady={this.onGridReadyEdit}
                columnDefs={this.state.columnDefs}
                rowData={
                  this.state.deliverSalesQtyList.length > 0
                    ? this.state.deliverSalesQtyList
                    : []
                }
                defaultColDef={this.state.defaultColDef}
                enterMovesDownAfterEdit={true}
                enterMovesDown={true}
                singleClickEdit={true}
                frameworkComponents={this.state.frameworkComponents}
              ></AgGridReact>
            </div>

            <div className="board_list mT20">
              <span className="f_right mR10">Weight : Kg</span>
              <table>
                <colgroup>
                  <col style={{ width: '7%' }} />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th colSpan="2">
                      <Trans>Miss Shape</Trans>
                    </th>
                    <th colSpan="2">
                      <Trans>Crack</Trans>
                    </th>
                    <th colSpan="2">
                      <Trans>Dirty</Trans>
                    </th>
                    <th colSpan="2">
                      <Trans>Jumbo</Trans>
                    </th>
                    <th colSpan="2">
                      <Trans>Small</Trans>
                    </th>
                    <th colSpan="2">
                      <Trans>Thin</Trans>
                    </th>
                    <th colSpan="2">
                      <Trans>Cutting(Grade)</Trans>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <Trans>Qty</Trans>
                    </th>
                    <th>
                      <Trans>Weight</Trans>
                    </th>
                    <th>
                      <Trans>Qty</Trans>
                    </th>
                    <th>
                      <Trans>Weight</Trans>
                    </th>
                    <th>
                      <Trans>Qty</Trans>
                    </th>
                    <th>
                      <Trans>Weight</Trans>
                    </th>
                    <th>
                      <Trans>Qty</Trans>
                    </th>
                    <th>
                      <Trans>Weight</Trans>
                    </th>
                    <th>
                      <Trans>Qty</Trans>
                    </th>
                    <th>
                      <Trans>Weight</Trans>
                    </th>
                    <th>
                      <Trans>Qty</Trans>
                    </th>
                    <th>
                      <Trans>Weight</Trans>
                    </th>
                    <th>
                      <Trans>Qty</Trans>
                    </th>
                    <th>
                      <Trans>Weight</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ height: '30px' }}>
                    <td style={{ width: '100px' }}>
                      {GridUtil.renderInput({
                        inputName: 'missShapeEggQty', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        disabled: true,
                        minVal: 0,
                      })}
                    </td>
                    <td style={{ width: '100px' }}>
                      {GridUtil.renderInput({
                        inputName: 'missShapeEggWeight', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 60, // px제외 : 없으면 100% */
                        dec: 1,
                        minVal: 0,
                        //maxLength: 6,
                        //maxVal: 999.99,
                      })}
                    </td>
                    <td style={{ width: '100px' }}>
                      {GridUtil.renderInput({
                        inputName: 'crackEggQty', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        disabled: true,
                        minVal: 0,
                      })}
                    </td>
                    <td style={{ width: '100px' }}>
                      {GridUtil.renderInput({
                        inputName: 'crackEggWeight', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 60, // px제외 : 없으면 100% */
                        dec: 1,
                        minVal: 0,
                      })}
                    </td>
                    <td style={{ width: '100px' }}>
                      {GridUtil.renderInput({
                        inputName: 'dirtyEggQty', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        disabled: true,
                        minVal: 0,
                      })}
                    </td>
                    <td style={{ width: '100px' }}>
                      {GridUtil.renderInput({
                        inputName: 'dirtyEggWeight', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 60, // px제외 : 없으면 100% */
                        dec: 1,
                        minVal: 0,
                      })}
                    </td>
                    <td style={{ width: '100px' }}>
                      {GridUtil.renderInput({
                        inputName: 'jumboEggQty', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        disabled: true,
                        minVal: 0,
                      })}
                    </td>
                    <td style={{ width: '100px' }}>
                      {GridUtil.renderInput({
                        inputName: 'jumboEggWeight', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 60, // px제외 : 없으면 100% */
                        dec: 1,
                        minVal: 0,
                      })}
                    </td>
                    <td style={{ width: '100px' }}>
                      {GridUtil.renderInput({
                        inputName: 'smallEggQty', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        disabled: true,
                        minVal: 0,
                      })}
                    </td>
                    <td style={{ width: '100px' }}>
                      {GridUtil.renderInput({
                        inputName: 'smallEggWeight', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 60, // px제외 : 없으면 100% */
                        dec: 1,
                        minVal: 0,
                      })}
                    </td>
                    <td style={{ width: '100px' }}>
                      {GridUtil.renderInput({
                        inputName: 'thinEggQty', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        disabled: true,
                        minVal: 0,
                      })}
                    </td>
                    <td style={{ width: '100px' }}>
                      {GridUtil.renderInput({
                        inputName: 'thinEggWeight', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 60, // px제외 : 없으면 100% */
                        dec: 1,
                        minVal: 0,
                      })}
                    </td>
                    <td style={{ width: '100px' }}>
                      {GridUtil.renderInput({
                        inputName: 'cuttingEggQty', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        disabled: true,
                        minVal: 0,
                      })}
                    </td>
                    <td style={{ width: '100px' }}>
                      {GridUtil.renderInput({
                        inputName: 'cuttingEggWeight', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 60, // px제외 : 없으면 100% */
                        dec: 1,
                        minVal: 0,
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="txt_center mT10 mB10">
              <button
                className="btn_gray"
                //onClick={() => this.props.fnClose('psDocFormView')}
                onClick={() => this.fnInit()}
              >
                <Trans>Cancel</Trans>
              </button>
              <button className="btn_save" onClick={() => this.fnSave()}>
                <Trans>save</Trans>
              </button>
            </div>
          </div>
        </div>
        {this.state.popSearchCustView ? (
          <PopSearchCust
            popSearchCustView={this.state.popSearchCustView}
            customerCode={this.state.customerCode}
            customerKind={this.state.customerKind}
            fnClosePop={this.fnClosePop}
            fnSelectCust={this.fnSelectCust}
          />
        ) : null}
      </Fragment>
    );
  }
}
export default withAlert()(EggSales);
