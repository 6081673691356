import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, FormUtil, PagingUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import { Radio } from 'antd';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { Input } from 'antd';
import SearchInput from 'utils/SearchInput';
import styled, { css } from 'styled-components';

import FarmView from './FarmView';

import '../Common.module.css';

// const SearchBox = styled.input.attrs({
//   type: 'text'
// })`
//   margin-bottom: 8px;
// `;

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class FarmInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rowNode: {},
      rowData: [],
      params: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        rowCnt: 0
      },
      visable: false,
      ColDefs: [
        {
          headerName: i18n.t('Farm Code'),
          field: 'farmCode',
          width: 120,
          minWidth: 100,
          cellStyle: { textAlign: 'center' },
          // pinned: 'left',
          getQuickFilterText: function(params) {
            return params.value;
          }
        },
        {
          headerName: i18n.t('Short Farm Code'),
          field: 'shortFarmCode',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Farm Name'),
          field: 'farmName',
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Farm Class'),
          field: 'farmClass',
          width: 120,
          minWidth: 100,
          hide: true
        },
        {
          headerName: i18n.t('Farm Class'),
          field: 'farmClassName',
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Farm Head'),
          field: 'farmHead',
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Mobile Phone'),
          field: 'mobilePhone',
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Start Date'),
          field: 'startDate',
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('End Date'),
          field: 'endDate',
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Farm Status'),
          field: 'farmStatus',
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Short Farm Code'),
          field: 'shortFarmCode',
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Telephone'),
          field: 'telephone',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Fax'),
          field: 'fax',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Division'),
          field: 'division',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Division'),
          field: 'divisionName',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Division'),
          field: 'divisionInfo',
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Region'),
          field: 'region',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Region'),
          field: 'regionName',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Region'),
          field: 'regionInfo',
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Sub Region'),
          field: 'subRegion',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Sub Region'),
          field: 'subRegionName',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Sub Region'),
          field: 'subRegionInfo',
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Zip Code'),
          field: 'zipCode',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Addr1'),
          field: 'addr1',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Addr2'),
          field: 'addr2',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Chief Name'),
          field: 'chiefName',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Experience'),
          field: 'experience',
          hide: true,
          width: 120,
          minWidth: 100,
          hide: true
        },
        {
          headerName: i18n.t('Experience'),
          field: 'experienceName',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Education'),
          field: 'education',
          hide: true,
          width: 120,
          minWidth: 100,
          hide: true
        },
        {
          headerName: i18n.t('Education'),
          field: 'educationName',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Capacity'),
          field: 'capacity',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('HH Qty'),
          field: 'hhQty',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Remarks'),
          field: 'remarks',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Update Id'),
          field: 'updateId',
          hide: true,
          width: 120,
          minWidth: 100
        },
        {
          headerName: i18n.t('Update Time'),
          field: 'updateTime',
          hide: true,
          width: 120,
          minWidth: 100
        }
      ],
      gridHeight: '365px',
      defaultColDef: {
        filter: true,
        resizable: true,
        editable: false,
        sortable: true
        // suppressSizeToFit: true
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagination: false,
      paginationPageSize: 10
      // editType: 'fullRow', // selectEditor와 함께 사용하지 말라고 하네요...
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    const { params } = this.state;
    this.fnGetRowData(params);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.rowData !== this.state.rowData) {
      this.setState({ visable: false, rowNode: {} });
    }
  }

  fnGetRowData = params => {
    const that = this;
    let url = Server.getRestAPI() + '/basis/selectTcFarmInfo.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        const { data } = response;
        console.log(data);
        if (data) {
          that.setState(
            produce(draft => {
              draft.rowData = data;
              draft.params['rowCnt'] = data.length;
              draft.params['total'] = data.length > 0 ? data[0].total : 0;
            }),
            function() {
              console.log(this.state);
            }
          );
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  onRowSelected = params => {
    const that = this;
    if (!isRowSelected(params)) return;
    this.setState(
      produce(draft => {
        draft.visable = true;
        draft.rowNode = params.data;
      }),
      function() {}
    );
  };

  onClick = pageNo => {
    const that = this;
    console.log('pageNo:', pageNo);
    this.setState(
      produce(draft => {
        draft.params['pageNo'] = pageNo;
      }),
      function() {
        const { params } = this.state;
        this.fnGetRowData(params);
      }
    );
  };

  setCode = (name, value) => {
    this.setState(
      produce(draft => {
        draft.params[name] = value;
      }),
      function() {
        console.log(this.state.params);
      }
    );
  };

  onFilterTextBoxChanged = e => {
    const { value } = e.target;
    this.gridApi.setQuickFilter(value);
  };
  onChangeParam = e => {
    const { name, value } = e.target;
    this.setState(
      produce(draft => {
        draft.params[name] = value;
      })
    );
  };

  onSearch = value => {
    this.setState(
      produce(draft => {
        draft.params['searchKey'] = value;
      }),
      function() {
        const { params } = this.state;
        this.fnGetRowData(params);
      }
    );
  };

  render() {
    // return null 은 아무것도 표시하지 않는다는 의미입니다.
    // if (!this.props.localeListView) return null;
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Basic - Farm</Trans>
        </h2>

        <div className="con_box">
          {/* 모든 필드검색으로 대체 합니다.
          <div className="f_left both">
            <SearchInput
              placeholder={i18n.t('Division')}
              style={{
                width: 180
              }}
              inputParam="division" // SelectInput의 분류
              inputName="division" // member variable of this.state.params
              inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한)
              // farmClass="A"
              defaultValue={this.state.params.division}
              getValue={value => {
                this.setCode('division', value);
                this.setCode('region', undefined);
                this.setCode('subRegion', undefined);
              }} //*****
              that={this} //*****
            />
            <SearchInput
              placeholder={i18n.t('Region')}
              style={{
                width: 180
              }}
              inputParam="region" // SelectInput의 분류
              inputName="region" // member variable of this.state.params
              inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한)
              // farmClass="A"
              pValue0={this.state.params.division} // division이 먼저 선택되어 있어야 활성화
              defaultValue={this.state.params.region}
              getValue={value => {
                this.setCode('region', value);
                this.setCode('subRegion', undefined);
              }} //*****
              that={this} //*****
            />
            <SearchInput
              placeholder={i18n.t('Sub Region')}
              style={{
                width: 180
              }}
              inputParam="subRegion" // SelectInput의 분류
              inputName="subRegion" // member variable of this.state.params
              inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한)
              // farmClass="A"
              pValue0={this.state.params.division} // division이 먼저 선택되어 있어야 활성화
              pValue1={this.state.params.region} // division이 먼저 선택되어 있어야 활성화
              defaultValue={this.state.params.subRegion}
              getValue={value => this.setCode('subRegion', value)} //*****
              that={this} //*****
            />
          </div>
          */}
          <div className="f_left mB20 both">
            {/*
            <Input
              type="text"
              style={{ width: 180 }}
              placeholder={i18n.t('Filter')}
              onChange={e => this.onFilterTextBoxChanged(e)}
            />
          */}
            <Input.Search
              name="searchKey"
              placeholder={i18n.t('Search')}
              onSearch={this.onSearch}
            />
          </div>

          <div className="board_list_s">
            <div
              id="myGrid"
              style={{
                height: this.state.gridHeight,
                width: '100%'
              }}
              className="ag-theme-balham"
            >
              <AgGridReact
                modules={this.state.modules}
                columnDefs={this.state.ColDefs}
                // components={this.state.components}
                // frameworkComponents={this.state.frameworkComponents}
                defaultColDef={this.state.defaultColDef}
                rowSelection={this.state.rowSelection}
                editType={this.state.editType}
                // enterMovesDownAfterEdit={true}
                // enterMovesDown={true}
                // rowModelType={this.state.rowModelType}

                onGridReady={this.onGridReady}
                onCellEditingStarted={this.onCellEditingStarted}
                onCellEditingStopped={this.onCellEditingStopped}
                onRowClicked={this.onRowSelected}
                onRowSelected={this.onRowSelected}
                onRowEditingStarted={this.onRowEditingStarted}
                onRowEditingStopped={this.onRowEditingStopped}
                onCellValueChanged={this.onCellValueChanged}
                rowData={this.state.rowData}
                pagination={this.state.pagination}
                paginationPageSize={this.state.paginationPageSize}
              />

              <PagingUtil
                total={this.state.params.total}
                rowCnt={this.state.rowData.length}
                pageSize={this.state.params.pageSize}
                pageNo={this.state.params.pageNo}
                onClick={this.onClick}
              />
            </div>
          </div>
        </div>

        <FarmView
          visable={this.state.visable}
          rowNode={this.state.rowNode}
        ></FarmView>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
