import React, { Component, Fragment } from 'react';

import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

// import { AgGridReact } from 'components';
import {
  AgGridReact,
  AllModules,
  isRowSelected
} from 'components/commons/ag-grid/AgGridUtil';

import { moment } from 'components';
import i18n from 'i18next';
import '../Breeding.module.css';
/*
 ** breeding/receive/MoveIn
 ** 다른농장에서 이동
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class MovedIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],

      columnDefs: [
        {
          headerName: '',
          field: 'ck',
          width: 60,
          cellStyle: { textAlign: 'center' },
          editable: true,
          cellRenderer: params => {
            return '<button class="selbg">&nbsp;&nbsp;선택&nbsp;&nbsp;</button>';
          }
        },
        {
          headerName: 'Transfer No',
          field: 'transferNo',
          width: 130,
          cellStyle: { textAlign: 'center' }
        },
        {
          headerName: 'Transfer Date',
          field: 'transferDate',
          width: 150,
          cellStyle: { textAlign: 'center' }
        },
        {
          headerName: 'Order No',
          field: 'orderNo',
          width: 100,
          cellStyle: { textAlign: 'center' }
        },
        {
          headerName: 'Flock Id',
          field: 'flockId',
          width: 150,
          cellStyle: { textAlign: 'center' }
        },
        {
          headerName: 'Chickin Date',
          field: 'chickinDate',
          width: 150,
          cellStyle: { textAlign: 'center' }
        },
        {
          headerName: 'Strain',
          field: 'strainCode',
          width: 100,
          cellStyle: { textAlign: 'center' }
        },
        {
          headerName: 'vendor Code',
          field: 'vendorCode',
          width: 120,
          cellStyle: { textAlign: 'center' }
        },
        {
          headerName: 'Move Qty(F)',
          field: 'moveQtyF',
          width: 100,
          cellStyle: { textAlign: 'right' }
        },
        {
          headerName: 'Move Qty(M)',
          field: 'moveQtyM',
          width: 170,
          cellStyle: { textAlign: 'right' }
        },
        {
          headerName: 'Truck No ',
          field: 'truckNo ',
          width: 100,
          cellStyle: { textAlign: 'center' }
        },
        {
          headerName: 'HH_ID',
          field: 'hhId',
          width: 100,
          cellStyle: { textAlign: 'center' }
        }
      ]
    };
  }
  //componentWillMount() {}
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  onRowClicked = e => {
    //console.log('onRowClicked', e.data);
  };
  onCellClicked = e => {
    if (e.column.colId === 'ck') {
      //선택버튼 클릭
      this.props.fnSelectMovedIn(e.data);
    }
  };

  ageCellRendererFunc = params => {
    //params.$scope.ageClicked = ageClicked;
    //return '<button ng-click="ageClicked(data.age)" ng-bind="data.age"></button>';
    return '<button ng-click="ageClicked(data.age)">Age</button>';
  };
  render() {
    if (!this.props.movedInView) return null;
    console.log('this.props.rowData', this.props.movedInData);
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Move In</Trans>
        </h2>

        <div
          style={{ height: '200px', width: '100%' }}
          className="board_list ag-theme-balham"
        >
          <AgGridReact
            columnDefs={this.state.columnDefs}
            rowData={this.props.movedInData}
            onRowClicked={e => this.onRowClicked(e)}
            onCellClicked={e => this.onCellClicked(e)}
          ></AgGridReact>
        </div>
      </Fragment>
    );
  }
}
export default MovedIn;
