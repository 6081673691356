import React, { Fragment } from 'react';
import { message, DatePicker, Select } from 'antd';
import { SessionUtil, DateUtil } from 'utils';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import querystring from 'querystring';

const { Option } = Select;

let timeout;
let currentValue;

function fetch(val, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = val.value;

  function fake() {
    const str = querystring.encode({
      code: 'utf-8',
      q: val.value.toUpperCase()
    });

    let data = {};
    let that = this;
    //data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();

    let url = Server.getRestAPI();

    if (val.inputParam === 'farmCode') {
      if (val.value.length >= Number(val.inputMinLen ? val.inputMinLen : 0)) {
        data.farmCode = val.value.toUpperCase();
        data.farmClass = val.farmClass ? val.farmClass : '';
        url = url + '/breed/release/selectFarmList.do';
      } else return;
    } else if (val.inputParam === 'customerCode') {
      data.customerName = val.value;
      url = url + '/breed/release/selectCustomerList.do';
    } else if (val.inputParam === 'hatcheryId') {
      data.customerName = val.value;
      url = url + '/breed/release/selectHatcheryList.do';
    }

    axios({
      method: 'post',
      url: url,
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        console.log('savePsSales', response.data);
        //if (currentValue === value) {
        const result = response.data.rows1;
        const data = [];
        if (val.inputParam === 'farmCode') {
          result.forEach(r => {
            data.push({
              value: r.farmCode,
              text: r.farmInfo
            });
          });
        } else if (val.inputParam === 'customerCode') {
          result.forEach(r => {
            data.push({
              value: r.customerCode,
              text: r.customerName
            });
          });
        }
        callback(data);
      })
      .catch(function(error) {
        return [];
      });
  }

  timeout = setTimeout(fake, 300);
}

export default class SearchInput extends React.Component {
  state = {
    data: [],
    value: undefined
  };

  componentDidMount() {
    this[this.props.inputParam] = React.createRef();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.focus !== this.props.focus && nextProps.focus) {
      //console.log('=========select', this.props.inputName);
      //this[this.props.inputName].current.focus();
      //console.log('=========select', this.props.inputName);
    }
  }

  handleSearch = value => {
    if (value) {
      let val = {};
      val.value = value;
      val.inputParam = this.props.inputParam;
      val.farmClass = this.props.farmClass;
      val.inputMinLen = this.props.inputMinLen;
      fetch(val, data => this.setState({ data }));
    } else {
      this.setState({ data: [] });
    }
  };

  handleChange = value => {
    this.setState({ value: value });
    this.props.getValue(value);
  };

  render() {
    const options = this.state.data.map(d => (
      <Option key={d.value}>{d.text}</Option>
    ));
    return (
      <Select
        showSearch
        value={this.state.value ? this.state.value : null}
        placeholder={this.props.placeholder}
        style={this.props.style}
        defaultActiveFirstOption={false}
        showArrow={true}
        onSearch={this.handleSearch}
        onChange={this.handleChange}
        notFoundContent={null}
        optionFilterProp="children"
        ref={select => {
          this.props.that[this.props.inputName] = select;
        }}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options}
      </Select>
    );
  }
}
