import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, FormUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import '../Common.module.css';

import CountryCode from 'containers/pages/common/system/CountryCode';

import { Radio } from 'antd';
import produce from 'immer';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { message } from 'antd';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class FarmView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      params: {}
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.rowNode !== this.props.rowNode) {
      let { rowNode } = this.props;
      console.log(rowNode);
      this.setState(
        produce(draft => {
          draft.params['farmCode'] = rowNode['farmCode'];
          draft.params['farmName'] = rowNode['farmName'];
          draft.params['farmClass'] = rowNode['farmClass'];
          draft.params['shortFarmCode'] = rowNode['shortFarmCode'];
          draft.params['farmHead'] = rowNode['farmHead'];
          draft.params['chiefName'] = rowNode['chiefName'];
          draft.params['division'] = rowNode['division'];
          draft.params['telephone'] = rowNode['telephone'];
          draft.params['region'] = rowNode['region'];
          draft.params['mobilePhone'] = rowNode['mobilePhone'];
          draft.params['subRegion'] = rowNode['subRegion'];
          draft.params['fax'] = rowNode['fax'];
          draft.params['zipCode'] = rowNode['zipCode'];
          draft.params['addr1'] = rowNode['addr1'];
          draft.params['addr2'] = rowNode['addr2'];
          draft.params['startDate'] = rowNode['startDate'];
          draft.params['endDate'] = rowNode['endDate'];
          draft.params['experience'] = rowNode['experience'];
          draft.params['education'] = rowNode['education'];
          draft.params['hhQty'] = rowNode['hhQty'];
          draft.params['capacity'] = rowNode['capacity'];
          draft.params['remarks'] = rowNode['remarks'];
          draft.params['farmStatus'] = rowNode['farmStatus'];
          draft.params['deleted'] = rowNode['deleted'];
        }),
        function() {
          // console.log('changed params : ', this.state.params);
        }
      );
    }
  }

  render() {
    if (!this.props.visable) return null;
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <div className="con_box">
          <h3>
            <Trans>Farm Info</Trans>
          </h3>

          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Farm Code</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'farmCode',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Farm Name</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'farmName',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Farm Class</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'farmClass',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Short Farm Code</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'shortFarmCode',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Farm Head</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'farmHead',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Chief Name</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'chiefName',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Division</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'division',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Telephone</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'telephone',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Region</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'region',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Mobile Phone</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'mobilePhone',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Sub Region</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'subRegion',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Fax</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'fax',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Zip Code</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'zipCode',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Addr1</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'addr1',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Addr2</Trans>
                  </th>
                  <td colSpan="3">
                    {FormUtil.renderInput({
                      inputName: 'addr2',
                      type: 'S',
                      className: 'w-80',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Start Date</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'startDate',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>End Date</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'endDate',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Experience</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'experience',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Education</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'education',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>HH Qty</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'hhQty',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Capacity</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'capacity',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td colSpan="3">
                    {FormUtil.renderInput({
                      inputName: 'remarks',
                      type: 'S',
                      className: 'w-80',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Farm Status</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'farmStatus',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Deleted</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'deleted',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/*
            CJ CMS에서 가져오는 정보이므로, 에그플랜에서는 수정 안됨.
          <div className="txt_center mT40">
            <button className="btn_gray" onClick={this.onBtCancel}>
              <Trans>Cancel</Trans>
            </button>
            <button className="btn_save" onClick={this.onBtSave}>
              <Trans>Save</Trans>
            </button>
          </div>
          */}
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
