import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import produce from 'immer';
import axios from 'axios';
import qs from 'qs';
import { withAlert } from 'react-alert';

import { Server } from 'components/Properties';
import { message, DatePicker } from 'antd';

import StrainCode from 'containers/pages/common/system/StrainCode';
import AgeType from 'containers/pages/common/system/AgeType';
import BreedingType from 'containers/pages/common/system/BreedingType';
import StdFarmClass from 'containers/pages/common/system/StdFarmClass';

import STD_LIST_IMAGE from 'images/imsi/std_list.png';
import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class ScoreMastRegist extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        // countryCode: SessionUtil.getCountryCode(), // Backend Server에서 결정
        // standardId: '', // MAX+1
        farmClass: props.farmClass,
        breedingType: '',
        strainCode: '',
        activeDate: DateUtil.today(),
        ageType: '',
        standardName: '',
        remarks: '',
      },
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  onChangeParam = (e) => {
    const { name, value } = e.target;
    this.setState(
      produce((draft) => {
        draft.params[name] = name === 'mesgId' ? value.toUpperCase() : value;
      })
    );
  };

  onBtCancel = () => {
    console.log('onBtCancel');
    this.props.setEditing(false);
  };

  onBtSave = () => {
    const that = this;
    const params = _.cloneDeep(this.state.params); // cloneDeep 중요
    console.log('onBtSave:', this.state.params);
    let url = Server.getRestAPI() + '/std/insertTcStandardMast.do';
    axios({
      withCredentials: true,
      credentials: 'same-origin',
      method: 'post',
      url: url,
      params: params,
    })
      .then(function (response) {
        const { data } = response;
        console.log('onBtSave response.data:', data);
        if (data.error) {
          return that.props.alert.error(i18n.t(data.error));
        }
        if (data > 0) {
          that.fnClearParams();
          that.props.setRegDate();
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  fnClearParams = () => {
    const that = this;
    this.setState(
      produce((draft) => {
        Object.keys(that.state.params).forEach((key) => {
          draft.params[`${key}`] = '';
        });
      }),
      function () {
        console.log('state.params:', that.state.params);
      }
    );
  };

  setCode = (name, value) => {
    this.setState(
      produce((draft) => {
        draft.params[name] = value;
      }),
      function () {
        console.log(this.state.params);
      }
    );
  };

  isBroiler = () => {
    if (this.state.params.farmClass === 'B') return true;
    return false;
  };

  setFarmClass = (name, value) => {
    const that = this;
    this.setState(
      produce((draft) => {
        draft.params[name] = value;
      }),
      function () {
        // Broiler 를 선택한 경우
        // Strain Code, Breeding Type은 입력 하지 못하게.
        // 데이터는 Null 처리
        console.log(this.state.params);
      }
    );
  };

  setOnChangeTime = (date, dateString, inputName) => {
    const col = inputName ? inputName : 'undefineCol';
    this.setState(
      {
        params: {
          ...this.state.params,
          [col]: dateString,
        },
      },
      function () {}
    );
  };

  render() {
    if (!this.props.editing) return null;
    const tz = SessionUtil.getTz();
    const today = DateUtil.today(null, tz);
    const dateFormat = SessionUtil.getDateFormat();
    return (
      <Fragment>
        {/* 디자인 영역 */}
        {/* regist 상세내용 */}
        <div className="con_box">
          <h3>
            <Trans>New Master Score</Trans>
          </h3>
          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Standard Name</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="standardName"
                      className="input_type1 w30"
                      value={this.state.params.standardName}
                      onChange={(e) => this.onChangeParam(e)}
                    />
                  </td>
                  <th>
                    <Trans>Active Date</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    <DatePicker
                      name="activeDate"
                      defaultValue={moment(today, dateFormat)}
                      format={dateFormat}
                      onChange={(date, dateString) =>
                        this.setOnChangeTime(date, dateString, 'activeDate')
                      }
                      allowClear={false}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Farm Class</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    <StdFarmClass
                      name="farmClass"
                      setCode={this.setFarmClass}
                      code={this.state.params.farmClass}
                      farmClass={this.state.params.farmClass}
                    />
                  </td>
                  <th>
                    <Trans>Strain Code</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    <StrainCode
                      name="strainCode"
                      setCode={this.setCode}
                      code={this.state.params.strainCode}
                      // disabled={this.isBroiler()}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Breeding Type</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    <BreedingType
                      name="breedingType"
                      setCode={this.setCode}
                      code={this.state.params.breedingType}
                      isBroiler={this.isBroiler()}
                      // disabled={this.isBroiler()}
                    />
                  </td>
                  <th>
                    <Trans>Age Type</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    <AgeType
                      name="ageType"
                      setCode={this.setCode}
                      code={this.state.params.ageType}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td colSpan="3">
                    <input
                      type="text"
                      name="remarks"
                      className="input_type1 w90"
                      value={this.state.params.remarks}
                      onChange={(e) => this.onChangeParam(e)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="txt_center mT40">
            <button className="btn_gray" onClick={this.onBtCancel}>
              <Trans>Cancel</Trans>
            </button>
            <button className="btn_save" onClick={this.onBtSave}>
              <Trans>Save</Trans>
            </button>
          </div>
        </div>
        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}

export default withAlert()(ScoreMastRegist);
