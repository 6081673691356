import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, ExcelUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import produce from 'immer';
import * as XLSX from 'xlsx';

import {
  AgGridReact,
  AllModules,
  isRowSelected
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { Upload, message, Button, Icon } from 'antd';
import ExcelUpload from './ExcelUpload';
import { PopupLayer } from 'components';
// import { useAlert } from 'react-alert'; // class에서는 hook을 호출할 수 없습니다.
import { withAlert } from 'react-alert';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class ScheduleDtlExcel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      gridHeight: '500px',
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: true,
        suppressSizeToFit: true
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      paginationPageSize: 15,
      // editType: 'fullRow', // selectEditor와 함께 사용하지 말라고 하네요...
      ColDefs: [
        {
          headerName: i18n.t('Age of Day'),
          // 데이타 로우의 rowSpan
          // rowSpan: function(params) {
          //  return params.data.country==='Russia' ? 2 : 1;
          // },
          field: 'ageDay',
          width: 100,
          minWidth: 100,
          // cellStyle: { textAlign: 'center' },
          getQuickFilterText: function(params) {
            return params.value;
          }
        },
        { field: 'farmCode', hide: true },
        { field: 'strainCode', hide: true },
        { field: 'sex', hide: true },
        {
          headerName: i18n.t('Feed'),
          // headerGroupComponent: FeedGroupComponent,
          children: [
            {
              headerName: i18n.t('Code'),
              field: 'feedCode1',
              width: 120,
              minWidth: 120
            },
            {
              headerName: i18n.t('Amount'),
              field: 'feedAmt1',
              width: 120,
              minWidth: 120
            },
            {
              headerName: i18n.t('Code'),
              field: 'feedCode2',
              width: 120,
              minWidth: 120
            },
            {
              headerName: i18n.t('Amount'),
              field: 'feedAmt2',
              width: 120,
              minWidth: 120
            },
            {
              headerName: i18n.t('Code'),
              field: 'feedCode3',
              width: 120,
              minWidth: 120
            },
            {
              headerName: i18n.t('Amount'),
              field: 'feedAmt3',
              width: 120,
              minWidth: 120
            }
          ],
          editable: false
        },
        {
          headerName: i18n.t('Vaccine'),
          // headerGroupComponent: VaccineGroupComponent,
          children: [
            {
              headerName: i18n.t('Code'),
              field: 'vaccineCode1',
              width: 120,
              minWidth: 120
            },
            {
              headerName: i18n.t('Amount'),
              field: 'vacAmt1',
              width: 120,
              minWidth: 120
            },
            {
              headerName: i18n.t('Code'),
              field: 'vaccineCode2',
              width: 120,
              minWidth: 120
            },
            {
              headerName: i18n.t('Amount'),
              field: 'vacAmt2',
              width: 120,
              minWidth: 120
            },
            {
              headerName: i18n.t('Code'),
              field: 'vaccineCode3',
              width: 120,
              minWidth: 120
            },
            {
              headerName: i18n.t('Amount'),
              field: 'vacAmt3',
              width: 120,
              minWidth: 120
            }
          ],
          editable: false
        },
        {
          headerName: i18n.t('Medicine'),
          // headerGroupComponent: FeedGroupComponent,
          children: [
            {
              headerName: i18n.t('Code'),
              field: 'medicineCode1',
              width: 120,
              minWidth: 120
            },
            {
              headerName: i18n.t('Amount'),
              field: 'medAmt1',
              width: 120,
              minWidth: 120
            },
            {
              headerName: i18n.t('Code'),
              field: 'medicineCode2',
              width: 120,
              minWidth: 120
            },
            {
              headerName: i18n.t('Amount'),
              field: 'medAmt2',
              width: 120,
              minWidth: 120
            },
            {
              headerName: i18n.t('Code'),
              field: 'medicineCode3',
              width: 120,
              minWidth: 120
            },
            {
              headerName: i18n.t('Amount'),
              field: 'medAmt3',
              width: 120,
              minWidth: 120
            }
          ],
          editable: false
        },
        {
          headerName: i18n.t('Remarks'),
          field: 'remarks'
        }
      ]
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fileInputRef = React.createRef();
  }

  onImportExcel = file => {
    ExcelUtil.importExcelFile(
      file,
      this.handleExcelData,
      this.props.alert.error
    );
  };

  handleExcelData = data => {
    data.map((row, i) => {
      console.log(row);
    });
    this.setState({ rowData: data }, function() {});
  };

  onBtExcelFile = e => {
    const that = this;
    this.setState({ rowData: [] }, function() {
      that.fileInputRef.current.click();
    });
  };

  onBtExcelClose = e => {
    const that = this;
    this.setState({ rowData: [] }, function() {
      that.props.fnClose('scheduleDtlExcelView');
    });
  };

  onBtSave = () => {
    const that = this;

    if (this.state.rowData.length === 0) {
      // 모두 대문자로 변경해 버립니다.
      return this.props.alert.error(i18n.t('Excel Data Not Exist!!'));
    }

    // params['rowData'] = this.state.rowData;
    // params['mastParams'] = this.props.mastParams;

    console.log('rowData:', this.state.rowData.length);
    const params = {
      masterParams: this.props.mastParams,
      rowDataLength: this.state.rowData.length,
      rowData: this.state.rowData
    };
    let url = Server.getRestAPI() + '/work/insertTcTaskDtl.do';
    axios({
      url: url,
      method: 'post',
      // headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        const { data } = response;
        console.log('onBtSave response.data:', data);
        if (data.error) {
          return that.props.alert.error(i18n.t(data.error));
        }
        if (data > 0) {
          that.props.setRegDate();
          that.props.fnClose('scheduleDtlExcelView');
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  render() {
    if (!this.props.scheduleDtlExcelView) return null;
    return (
      <Fragment>
        <PopupLayer>
          <div className="con_box">
            <div className="f_left mB20">
              <h3>Work Schedule - Excel Upload</h3>
            </div>
            <div className="f_right mB20">
              {/* button을 Button으로 사용할 수 있을까요? 문실장님 */}
              <button className="btn_sky" onClick={e => this.onBtExcelFile()}>
                <Trans>Excel File</Trans>
              </button>
              {/* input type='file'을 안보이게 숨김 */}
              <input
                ref={this.fileInputRef}
                type="file"
                accept=".xlsx, .xls"
                onChange={this.onImportExcel}
                hidden={true}
              />

              <button className="btn_blue" onClick={this.onBtSave}>
                <Trans>Regist</Trans>
              </button>

              <button className="btn_gray" onClick={e => this.onBtExcelClose()}>
                <Trans>Cancel</Trans>
              </button>
            </div>

            <div className="board_list_s">
              <div className="board_list2">
                <div
                  id="myGrid"
                  style={{
                    height: this.state.gridHeight,
                    width: '100%'
                  }}
                  className="ag-theme-balham"
                >
                  <AgGridReact
                    modules={this.state.modules}
                    columnDefs={this.state.ColDefs}
                    // components={this.state.components}
                    // frameworkComponents={this.state.frameworkComponents}
                    defaultColDef={this.state.defaultColDef}
                    rowSelection={this.state.rowSelection}
                    editType={this.state.editType}
                    // enterMovesDownAfterEdit={true}
                    // enterMovesDown={true}
                    // rowModelType={this.state.rowModelType}

                    onGridReady={this.onGridReady}
                    onCellEditingStarted={this.onCellEditingStarted}
                    onCellEditingStopped={this.onCellEditingStopped}
                    onRowClicked={this.onRowSelected}
                    onRowSelected={this.onRowSelected}
                    onRowEditingStarted={this.onRowEditingStarted}
                    onRowEditingStopped={this.onRowEditingStopped}
                    onCellValueChanged={this.onCellValueChanged}
                    rowData={this.state.rowData}
                    pagination={true}
                    paginationPageSize={this.state.paginationPageSize}
                  />
                </div>
              </div>
            </div>
          </div>
        </PopupLayer>
      </Fragment>
    );
  }
}
export default withAlert()(ScheduleDtlExcel);
