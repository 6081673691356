import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import FHDateRangeSearch from '../utils/FHDateRangeSearch';
import AFDateRangeSearch from '../utils/AFDateRangeSearch';
import ReportUtil from '../utils/ReportUtil';

import '../Report.module.css';

// 11. 종계 부화 보고서 (Egg Prod, 주/HH별)
export default class HatEpWklyHhR extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      excelName: i18n.t('Hatching Report (Egg Prod, Week/HH)'),
      rowData: [],
      params: {},
      ColDefs: [
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Date'),
              field: 'date',
              pinned: 'left',
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Age'),
              field: 'age',
              pinned: 'left',
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            { headerName: i18n.t('Prod'), field: 'prod', pinned: 'left' },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('No. of birds'),
              children: [
                {
                  headerName: i18n.t('Female'),
                  field: 'popsFemale',
                },
                {
                  headerName: i18n.t('Male'),
                  field: 'popsMale',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Cooling Room'),
          children: [
            {
              headerName: '',
              children: [
                {
                  headerName: i18n.t('Receive'),
                  field: 'cReceive',
                },
              ],
            },
            {
              headerName: '',
              children: [
                {
                  headerName: i18n.t('Cutting'),
                  field: 'cCutting',
                },
              ],
            },
            {
              headerName: i18n.t('Move'),
              children: [
                {
                  headerName: i18n.t('In'),
                  field: 'cmIn',
                },
                {
                  headerName: i18n.t('Out'),
                  field: 'cmOut',
                },
              ],
            },
            {
              headerName: i18n.t('By Product'),
              children: [
                {
                  headerName: i18n.t('Miss'),
                  field: 'cbMiss',
                },
                {
                  headerName: i18n.t('Thin'),
                  field: 'cbThin',
                },
                {
                  headerName: i18n.t('Crack'),
                  field: 'cbCrack',
                },
                {
                  headerName: i18n.t('Damage'),
                  field: 'cbDamage',
                },
                {
                  headerName: i18n.t('Dirty'),
                  field: 'cbDirty',
                },
                {
                  headerName: i18n.t('Small'),
                  field: 'cbSmall',
                },
                {
                  headerName: i18n.t('Jumbo'),
                  field: 'cbJumbo',
                },
                {
                  headerName: i18n.t('Total'),
                  field: 'cbTotal',
                },
              ],
            },
            {
              headerName: '',
              children: [
                {
                  headerName: i18n.t('HE'),
                  field: 'cHE',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Setter'),
          children: [
            {
              headerName: '',
              children: [
                {
                  headerName: i18n.t('Setting'),
                  field: 'sSetting',
                },
              ],
            },
            {
              headerName: '',
              children: [
                {
                  headerName: i18n.t('Cutting'),
                  field: 'sCutting',
                },
              ],
            },
            {
              headerName: i18n.t('By Product'),
              children: [
                {
                  headerName: i18n.t('Explode'),
                  field: 'sbExplode',
                },
                {
                  headerName: i18n.t('Infertile'),
                  field: 'sbInfertile',
                },
                {
                  headerName: i18n.t('Total'),
                  field: 'sbTotal',
                },
              ],
            },
            {
              headerName: '',
              children: [
                {
                  headerName: i18n.t('Fertile'),
                  field: 'scFertile',
                },
              ],
            },
            {
              headerName: '',
              children: [
                {
                  headerName: i18n.t('Cutting'),
                  field: 'scCutting',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Hatcher'),
          children: [
            {
              headerName: '',
              children: [
                {
                  headerName: i18n.t('Fertile'),
                  field: 'hFertile',
                },
              ],
            },
            {
              headerName: i18n.t('By Product'),
              children: [
                {
                  headerName: i18n.t('Bloat'),
                  field: 'prodBloat',
                },
                {
                  headerName: i18n.t('Dead'),
                  field: 'prodDead',
                },
                {
                  headerName: i18n.t('Def'),
                  field: 'prodDef',
                },
                {
                  headerName: i18n.t('Ompal'),
                  field: 'prodOmpal',
                },
                {
                  headerName: i18n.t('Week'),
                  field: 'prodWeek',
                },
                {
                  headerName: i18n.t('DIC'),
                  field: 'prodDIC',
                },
                {
                  headerName: i18n.t('Total'),
                  field: 'prodTotal',
                },
              ],
            },
            {
              headerName: '',
              children: [
                {
                  headerName: i18n.t('DOC'),
                  field: 'hDOC',
                },
              ],
            },
            {
              headerName: '',
              children: [
                {
                  headerName: i18n.t('Cutting'),
                  field: 'hCutting',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Sales'),
          children: [
            {
              headerName: i18n.t('PS DOC'),
              children: [
                {
                  headerName: i18n.t('Female'),
                  field: 'spFemale',
                },
                {
                  headerName: i18n.t('Male'),
                  field: 'spMale',
                },
              ],
            },
            {
              headerName: i18n.t('Broiler DOC'),
              children: [
                {
                  headerName: i18n.t('Sex'),
                  field: 'sfSEX',
                },
                {
                  headerName: i18n.t('S/P'),
                  field: 'sfSP',
                },
                {
                  headerName: i18n.t('A2'),
                  field: 'sfA2',
                },
                {
                  headerName: i18n.t('A1'),
                  field: 'sfA1',
                },
                {
                  headerName: i18n.t('BM'),
                  field: 'sfBM',
                },
                {
                  headerName: i18n.t('B'),
                  field: 'sfB',
                },
                {
                  headerName: i18n.t('Polos'),
                  field: 'sfPolos',
                },
              ],
            },
            {
              headerName: '',
              children: [
                {
                  headerName: i18n.t('Total'),
                  field: i18n.t('sfTotal'),
                },
              ],
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('Fertile(%)'),
              field: 'fertile',
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('Hatch(%)'),
              field: 'hatch',
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('Hatch of Fertile'),
              field: 'hatchOfFertile',
              minWidth: 140,
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('SC/PS'),
              field: 'scps',
            },
          ],
        },
      ],
      gridHeight: '640px',
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        width: 100,
        minWidth: 100,
        cellClass: 'text-center',
        suppressSizeToFit: false,
        valueFormatter: function (params) {
          return GridUtil.formatReport(params);
        },
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagenation: false,
      paginationPageSize: 10,
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    if (Const.RPT_TEST) {
      const params = ReportUtil.getDefaultParams(this.props);
      console.log('params:', params);
      this.fnGetRowData(params);
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  // -----------------------------------------------------------
  // 참고용 : ES/ETL 서버 주소(API)를 지정해야 합니다.
  // -----------------------------------------------------------
  fnGetRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/stat/breeding/hatEpWklyHhR.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data.rows1 });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtExport = () => {
    const fileName =
      i18n.t(this.state.excelName) + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t(this.state.excelName);
    const params = {
      allColumns: false,
      columnGroups: true,
      fileName: fileName,
      sheetName: sheetName,
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onSearch = (params) => {
    console.log(params);
    this.fnGetRowData(params);
  };

  render() {
    return (
      <React.Fragment>
        <h3>
          {/* 11. 부화 보고서(주/계란생산) */}
          <Trans>Title Hat EP, Wkly/HH R</Trans>
        </h3>

        <div className="con_box">
          <FHDateRangeSearch onSearch={this.onSearch}> </FHDateRangeSearch>

          <div className="f_right mB20">
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
          </div>
          <div className="board_list_s">
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  excelStyles={this.state.excelStyles}
                  onGridReady={this.onGridReady}
                  rowData={this.state.rowData}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// let i = 0;
// const sampleData = [ { date: i++, age: i++, prod: i++, pFemale: i++, pMale: i++, pRatio: i++, cReceive: i++, cAborsi: i++, cbMiss: i++, cbThin: i++, cbCrack: i++, cbDamage: i++, cbDirty: i++, cbSmall: i++, cbTotal: i++, cmIn: i++, cmOut: i++, cHE: i++, sSetting: i++, sAborsi: i++, sbExplode: i++, sbInfertile: i++, sbTotal: i++, sFertile: i++, hAborsi: i++, hbBloat: i++, hbDead: i++, hbDef: i++, hbOmpal: i++, hbWeak: i++, hbDIC: i++, hbTotal: i++, hdPremium: i++, hdStandard: i++, hdBM: i++, hdTotal: i++, sPremium: i++, sStandart1: i++, sStandard2: i++, sBM: i++, sGradeB: i++, sPolos: i++, fertile: i++, hatch: i++, scps: i++, }, ];
