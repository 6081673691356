import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import '../Hatchery.module.css';

/*
 ** Hatchery - Transfer - Egg 부화장 계란 출고
 ** ==========================================
 ** 부화장에 입고된 Egg 중 검란(Grading)이 끝나고 분류된 Egg를 출고(판매 또는 기부) 한다.
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class EggRelease extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInstance: this,
      form: [],
      eggReleaseFormView: false,
      broilerDocFormView: false,
      discardFormView: false,
      eggReleaseForm: [],
      broilerDocForm: [],
      discardForm: [],
      hatchingInfoList: [],
      hatchingData: {},
      strainCodeList: []
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('getDerivedStateFromProps', prevState, nextProps);
    if (prevState.eggReleaseFormView !== nextProps.eggReleaseFormView) {
      if (nextProps.eggReleaseFormView) prevState.currentInstance.fnSearch();
      return {
        eggReleaseFormView: nextProps.eggReleaseFormView,
        form: nextProps.eggReleaseForm
      };
    }
    return null;
  }
  fnSearch = () => {};

  render() {
    if (!this.props.eggReleaseFormView) return null;
    return (
      <Fragment>
        {/* 디자인 영역  */}
        <div className="mT20 con_box">
          <h3 className="mT0">
            <Trans>Release Egg</Trans>
          </h3>
          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '30%' }} />
                <col style={{ width: '20%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Date</Trans>
                  </th>
                  <td>
                    <input type="text" name="" id="" className="input_type1" />
                  </td>
                  <th>
                    <Trans>Release No</Trans>
                  </th>
                  <td>
                    <input type="text" name="" id="" className="input_type1" />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Customer</Trans>
                  </th>
                  <td>
                    <select id="" name="" title="" className="sel_type2">
                      <option value="">-</option>
                      <option value="">-</option>
                    </select>
                  </td>
                  <th>
                    <Trans>Truck No</Trans>
                  </th>
                  <td>
                    <input type="text" name="" id="" className="input_type1" />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Total</Trans>
                  </th>
                  <td>
                    <input type="text" name="" id="" className="input_type2" />
                    <font className="mR20">Box</font>

                    <input
                      type="text"
                      name=""
                      id=""
                      className="input_type2 mL20"
                    />
                    <font className="mL10 mR10">+</font>
                    <input type="text" name="" id="" className="input_type2" />
                  </td>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td>
                    <input
                      type="text"
                      name=""
                      id=""
                      className="input_type1 w100"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="board_list mT20">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <Trans>Miss Shape</Trans>
                  </th>
                  <th>
                    <Trans>Crack</Trans>
                  </th>
                  <th>
                    <Trans>Dirty</Trans>
                  </th>
                  <th>
                    <Trans>Miss Shape</Trans>
                  </th>
                  <th>
                    <Trans>Thin</Trans>
                  </th>
                  <th>
                    <Trans>Small</Trans>
                  </th>
                  <th>
                    <Trans>Cutting(Grade)</Trans>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Trans>Qty</Trans>
                  </td>
                  <td>100</td>
                  <td>200</td>
                  <td>100</td>
                  <td>100</td>
                  <td>200</td>
                  <td>100</td>
                  <td>100</td>
                </tr>
                <tr>
                  <td>
                    <Trans>Release</Trans>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="txt_center mT40">
            <Link to="#">
              <button className="btn_gray">
                <Trans>Cancel</Trans>
              </button>
            </Link>
            <Link to="#">
              <button className="btn_save">
                <Trans>Save</Trans>
              </button>
            </Link>
          </div>
        </div>
        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
