import * as Broiler from 'containers/pages/broiler';
import * as Report from 'containers/pages/report';
import i18n from 'i18next';

const BroilerRouteList = [
  { uri: '/broiler/dashboard', page: Broiler.Dashboard.Dashboard },
  { uri: '/broiler/receive/hatchery', page: Broiler.Receive.RcvHatchery },
  { uri: '/broiler/receive/supplier', page: Broiler.Receive.RcvSupplier },

  { uri: '/broiler/growing/growlog', page: Broiler.Growing.GrowLog },

  { uri: '/broiler/report/dailyAreaR', page: Report.Broiler.DailyAreaR },
  {
    uri: '/broiler/report/dailyProjectR',
    page: Report.Broiler.DailyProjectR,
  },
  { uri: '/broiler/report/salesR', page: Report.Broiler.SalesR },
  { uri: '/broiler/report/projGphAnlysR', page: Report.Broiler.ProjGphAnlysR },
  { uri: '/broiler/report/projIdxAnlysR', page: Report.Broiler.ProjIdxAnlysR },
];
export default BroilerRouteList;
