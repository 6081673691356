import React, { Fragment } from 'react';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';
import scrollToComponent from 'react-scroll-to-component';

import { moment } from 'components';
import GradeDiscardForm from './GradeDiscardForm';
import GradeListForDiscard from './GradeListForDiscard';
import GradeDiscardHistList from './GradeDiscardHistList';
import '../Hatchery.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class GradeDiscard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gradeDiscardFormView: false,
      rowData: [],
      regDate: DateUtil.toDateTimeString(),
      iuFlag: 'I',
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  fnClickGrade = (data) => {
    console.log(data);
    this.setState(
      { gradeDiscardFormView: true, rowData: data, iuFlag: 'I' },
      function () {
        scrollToComponent(this['gradeDiscardFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };

  fnSetRegDate = () => {
    this.setState({
      regDate: DateUtil.toDateTimeString(),
      gradeDiscardFormView: false,
    });
  };

  fnClickModify = (data) => {
    this.setState(
      {
        rowData: data,
        gradeDiscardFormView: true,
        iuFlag: 'U',
      },
      function () {
        scrollToComponent(this['gradeDiscardFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );

    if (data.hatchingEggQty) {
      this.setState({
        rowData: {
          ...this.state.rowData,
          discardQtyInput: data.hatchingEggQty,
        },
      });
    }
  };

  fnClickCancel = () => {
    this.setState({ gradeDiscardFormView: false }, function () {});
  };

  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Grade Discard</Trans>
        </h2>

        <GradeListForDiscard
          fnClickGrade={this.fnClickGrade}
          regDate={this.state.regDate}
        />

        <GradeDiscardForm
          ref={(section) => {
            this.gradeDiscardFormView = section;
          }}
          gradeDiscardFormView={this.state.gradeDiscardFormView}
          rowData={this.state.rowData}
          fnSetRegDate={this.fnSetRegDate}
          iuFlag={this.state.iuFlag}
          fnClickCancel={this.fnClickCancel}
        />

        <GradeDiscardHistList
          regDate={this.state.regDate}
          fnClickModify={this.fnClickModify}
          fnSetRegDate={this.fnSetRegDate}
        />

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
