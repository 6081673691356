import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { moment } from 'components';
import i18n from 'i18next';
import FromHatchery from './FromHatchery';
import HatcheryTransferListDtl from './HatcheryTransferListDtl';
import HatcheryReceivedList from './HatcheryReceivedList';

import { DateUtil } from 'utils';
import scrollToComponent from 'react-scroll-to-component';
import { withAlert } from 'react-alert';
import '../Broiler.module.css';

/*
 ** breeding/receive/Flock
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class RcvHatchery extends React.Component {
  state = {
    hatcheryTransferListDtlView: false,
    regDate: DateUtil.toDateTimeString(),
    transferInfo: [],
    transferQty: '',
    iuFlag: '',
  };

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  fnSelectTransferNo = (data) => {
    this.setState(
      {
        transferInfo: data,
        iuFlag: 'I',
        hatcheryTransferListDtlView: true,
      },
      function () {
        scrollToComponent(this['hatcheryTransferListDtlView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: this.getFieldValue(event.target),
    });
  }

  fnSetRegDate = () => {
    this.setState({
      regDate: DateUtil.toDateTimeString(),
      hatcheryTransferListDtlView: false,
    });
  };

  fnClickModify = (data) => {
    console.log('ch1', data);
    this.setState(
      {
        regDate: DateUtil.toDateTimeString(),
        iuFlag: 'U',
        hatcheryTransferListDtlView: true,
        transferInfo: data,
      },
      function () {
        scrollToComponent(this['hatcheryTransferListDtlView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };

  fnClickCancel = () => {
    this.setState({ hatcheryTransferListDtlView: false }, function () {});
  };

  render() {
    return (
      <Fragment>
        <h2>
          <Trans>Receive From Hatchery</Trans>
        </h2>

        <FromHatchery
          fnSelectTransferNo={this.fnSelectTransferNo}
          regDate={this.state.regDate}
        ></FromHatchery>

        <HatcheryTransferListDtl
          ref={(section) => {
            this.hatcheryTransferListDtlView = section;
          }}
          hatcheryTransferListDtlView={this.state.hatcheryTransferListDtlView}
          transferInfo={this.state.transferInfo}
          fnClickCancel={this.fnClickCancel}
          fnClickReceive={this.fnClickReceive}
          fnSetRegDate={this.fnSetRegDate}
          iuFlag={this.state.iuFlag}
        />

        <HatcheryReceivedList
          regDate={this.state.regDate}
          fnSetRegDate={this.fnSetRegDate}
          fnClickModify={this.fnClickModify}
        />
      </Fragment>
    );
  }
}
export default withAlert()(RcvHatchery);
