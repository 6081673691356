import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import { Radio } from 'antd';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { message } from 'antd';

import '../Hatchery.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class MoveInList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      gridHeight: '350px',
      defaultColDef: {
        filter: true,
        resizable: true,
        editable: true,
        sortable: true,
        suppressSizeToFit: true
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      paginationPageSize: 10,
      // editType: 'fullRow', // selectEditor와 함께 사용하지 말라고 하네요...
      ColDefs: [
        {
          headerName: i18n.t('Release No'),
          field: 'releaseNo',
          editable: false,
          getQuickFilterText: function(params) {
            return params.value;
          }
        },
        {
          headerName: i18n.t('Release Date'),
          field: 'releaseDate',
          editable: false
        },
        {
          headerName: i18n.t('Customer'),
          field: 'customer'
        },
        {
          headerName: i18n.t('Qty'),
          field: 'qty'
        },
        {
          headerName: i18n.t('Truck No'),
          field: 'truckNo'
        },
        {
          headerName: i18n.t('Order No'),
          field: 'orderNo'
        },
        {
          headerName: i18n.t('Remarks'),
          field: 'remarks'
        }
      ]
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  onCellValueChanged = async params => {
    const fieldId = params.column.getId(); // 변경된 cell의 field 값이 전달된다.
    const that = this;

    // newValue의 경우 editType: 'fullRow'아닌 경우에만 의미가 있습니다.
    // fullRow 의 경우 data에 이미 변경된 값이 전달 됩니다.
    // 이 경우 data에 검증이 필요합니다. UNDO할 것 인가? APPLY할 것 인가?
    const { data, newValue } = params;
    let param = _.cloneDeep(data);

    param[fieldId] = newValue;

    console.log('newValue:', newValue);
    console.log('param:', param);
  };

  onFilterTextBoxChanged = e => {
    const { value } = e.target;
    this.gridApi.setQuickFilter(value);
  };

  render() {
    // return null 은 아무것도 표시하지 않는다는 의미입니다.
    // if (!this.props.localeListView) return null;
    return (
      <Fragment>
        {/* 디자인 영역 */}

        <div className="con_box">
          <h3>
            <Trans>Supplier - Received List</Trans>
          </h3>
          {/*
          <div className="f_left mB20">
            <label htmlFor="">
              <Trans>Filter</Trans>
            </label>
            <input
              type="text"
              className="input_type1"
              onChange={e => this.onFilterTextBoxChanged(e)}
            />
          </div>
          */}
          <div className="board_list_s">
            <div
              id="myGrid"
              style={{
                height: this.state.gridHeight,
                width: '100%'
              }}
              className="ag-theme-balham"
            >
              <AgGridReact
                modules={this.state.modules}
                columnDefs={this.state.ColDefs}
                // components={this.state.components}
                // frameworkComponents={this.state.frameworkComponents}
                defaultColDef={this.state.defaultColDef}
                rowSelection={this.state.rowSelection}
                editType={this.state.editType}
                // enterMovesDownAfterEdit={true}
                // enterMovesDown={true}
                // rowModelType={this.state.rowModelType}

                onGridReady={this.onGridReady}
                onCellEditingStarted={this.onCellEditingStarted}
                onCellEditingStopped={this.onCellEditingStopped}
                onRowClicked={this.onRowSelected}
                onRowSelected={this.onRowSelected}
                onRowEditingStarted={this.onRowEditingStarted}
                onRowEditingStopped={this.onRowEditingStopped}
                onCellValueChanged={this.onCellValueChanged}
                rowData={this.state.rowData}
                pagination={true}
                paginationPageSize={this.state.paginationPageSize}
              />
            </div>
          </div>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
