import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil, PopSearchCust } from 'utils';
import { Trans } from 'react-i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { moment } from 'components';
import i18n from 'i18next';
import { Popconfirm, Select, Input } from 'antd';
import NumberFormat from 'react-number-format';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';
const { Search } = Input;

/*
 ** breeding/receive/BreedRecvForm
 ** 종계입고 폼, 저장 후 계군에 합산
 ** ===============================
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class BreedRecvFormHatchery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInstance: this,
      form: {},
      prop: {},
      strainCodeList: [],
      hatcheryList: [],
      popSearchCustView: false,
      customerKind: [],
      customerName: '',
      breedRecvFormView: false,
      delView: false,
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.selectCodeList('EP26', 'strainCodeList', "AND CODE_VALUE LIKE 'B%'");
    let data = this.props.breedRecvForm;
    if (!data.receiveDate) {
      data.receiveDate = DateUtil.today();
    }
    if (data.iuFlag === 'I') {
      data.remarks = ''; //From Hatchery List 에서 넘어오는 값을 지움
    }
    this.setState(
      {
        breedRecvForm: this.props.breedRecvForm,
        breedRecvFormView: this.props.breedRecvFormView,
        form: this.fnCalcQty(data, ''),
      },
      function () {}
    );
  }

  selectCodeList = async (codeId, value, codeValue) => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.codeId = codeId;
    //data.codeValue = codeValue ? codeValue : '';
    data.sql = 'selectTcCodeDtl';
    const url = Server.getRestAPI();
    await axios({
      method: 'post',
      url: url + '/breed/release/selectSql.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ [value]: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
    that.selectHatcheryList();
  };
  selectHatcheryList = () => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.hatcheryId = '';
    data.sql = 'selectHatcheryInfoList';

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/selectSql.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ hatcheryList: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };
  fnSearch = () => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.hatchDate = DateUtil.today();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/transfer/selectTlHatchingInfoList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('selectGlowingLogList', response.data);
        if (response.data.rows1.length > 0) {
          that.setState(
            {
              hatchingInfoList: response.data.rows1,
              strainCodeList: response.data.rows2,
              hatchingData: response.data.rows3 ? response.data.rows3[0] : {},
            },
            function () {}
          );
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  fnCalcQty = (data, col) => {
    let recvQtyF = Number(data.recvQtyF ? data.recvQtyF : 0);
    let bonusQtyF = Number(data.bonusQtyF ? data.bonusQtyF : 0);
    let deathQtyF = Number(data.deathQtyF ? data.deathQtyF : 0);
    let quarQtyF = Number(data.quarQtyF ? data.quarQtyF : 0);

    let recvQtyM = Number(data.recvQtyM ? data.recvQtyM : 0);
    let bonusQtyM = Number(data.bonusQtyM ? data.bonusQtyM : 0);
    let deathQtyM = Number(data.deathQtyM ? data.deathQtyM : 0);
    let quarQtyM = Number(data.quarQtyM ? data.quarQtyM : 0);

    if (col === '') {
      data.totalRecvQtyF = recvQtyF + bonusQtyF - deathQtyF - quarQtyF;
      data.totalRecvQtyM = recvQtyM + bonusQtyM - deathQtyM - quarQtyM;
      //data.sampleWeightAvg = sampleWeight / sampleQty;
    } else if (
      col === 'recvQtyF' ||
      col === 'bonusQtyF' ||
      col === 'deathQtyF' ||
      col === 'quarQtyF'
    ) {
      data.totalRecvQtyF = recvQtyF + bonusQtyF - deathQtyF - quarQtyF;
    } else if (
      col === 'recvQtyM' ||
      col === 'bonusQtyM' ||
      col === 'deathQtyM' ||
      col === 'quarQtyM'
    ) {
      data.totalRecvQtyM = recvQtyM + bonusQtyM - deathQtyM - quarQtyM;
    } else if (col === 'sampleQty' || col === 'sampleWeight') {
      let sampleQty = Number(data.sampleQty ? data.sampleQty : 0);
      let sampleWeight = Number(data.sampleWeight ? data.sampleWeight : 0);
      data.sampleWeightAvg = sampleWeight / sampleQty;
    }
    return data;
  };

  fnSave = (e) => {
    let that = this;
    //validate
    let params = this.state.form;
    console.log('save', params);
    // if (!data.receiveNo) {
    //   alert('Receive No를 입력하세요.');
    //   this.receiveNo.current.focus();

    //   return false;
    // }
    if (!params.receiveDate) {
      that.props.alert.show(i18n.t('Please enter Receive Date.'));

      this.receiveDate.current.focus();
      return false;
    }

    if (!params.recvQtyF) params.recvQtyF = 0;
    if (!params.bonusQtyF) params.bonusQtyF = 0;
    if (!params.deathQtyF) params.deathQtyF = 0;
    if (!params.quarQtyF) params.quarQtyF = 0;
    if (!params.recvQtyM) params.recvQtyM = 0;
    if (!params.bonusQtyM) params.bonusQtyM = 0;
    if (!params.deathQtyM) params.deathQtyM = 0;
    if (!params.quarQtyM) params.quarQtyM = 0;

    if (!params.totalRecvQtyF) params.totalRecvQtyF = 0;
    if (!params.totalRecvQtyM) params.totalRecvQtyM = 0;

    if (!params.sampleWeightAvg) params.sampleWeightAvg = 0;

    if (params.totalRecvQtyF === 0 && params.totalRecvQtyM === 0) {
      that.props.alert.show(i18n.t('Please enter quantity.'));
      that.recvQtyF.focus(); //Number
      return false;
    }

    console.log('save', params);
    params.farmCode = SessionUtil.getFarmCode();
    params.dateFormat = SessionUtil.getDateFormat();

    //data.receiveNo = '';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/saveFromHatchery.do',
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        //console.log('fnSaveFromHatchery', response);
        if (params.iuFlag === 'I') {
          if (data.rows1 === false) {
            // Hatchery/Deliver/PS DOC Transfer에서 이미 취소한 경우
            that.props.fnInit();
            return that.props.alert.error(
              i18n.t(
                'The hatchery transfer information has been deleted and cannot be registered.'
              )
            );
          }

          params.docReceiveNo = data.rows1[0].docReceiveNo;
          //console.log('fnSaveFromHatchery', data, response);
        }
        that.props.alert.show(i18n.t('Has been saved.'));
        that.props.fnSaveFromHatchery(params); //flock 선택
        //} else {
        //that.props.fnInit();
        //}

        // that.setState(
        //   { showFlockList: true, tpBreedRecvData: response.data.rows1[0] },
        //   function() {
        //     console.log(that.state);
        //   }
        // );
      })
      .catch(function (error) {
        return [];
      });
  };

  fnNew = () => {
    let data = {};
    data.iuFlag = 'I';
    data.receiveDate = DateUtil.today();
    this.setState({ form: data }, function () {});
  };
  setOnChange = (e, params, dateString) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';

    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
      if (params.minVal) {
        if (Number(val) < Number(params.minVal)) {
          val = Number(params.minVal);
        }
      }
    }
    this.setState({ form: { ...that.state.form, [col]: val } }, function () {
      if (params.type === 'N') {
        let data = that.fnCalcQty(that.state.form, col);
        if (data.totalRecvQtyF < 0 || data.totalRecvQtyM < 0) {
          that.props.alert.show(i18n.t('The quantity is exceeded.'));
          data[col] = 0;
          that[col].focus();
          data = that.fnCalcQty(data, '');
        }
        that.setState({ form: data }, function () {});
      }
    });
  };

  fnTest = (e) => {
    this.props.fnSaveFromHatchery('1');
  };
  fnSearchCust = (value) => {
    this.setState(
      {
        popSearchCustView: true,
        customerCode: value,
        customerKind: ['01', '02', '03', '04', '05', '06', '07'],
      },
      function () {}
    );
  };
  fnSelectCust = (data) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          customerCode: data.customerCode,
          customerName: data.customerName,
        },
      },
      function () {}
    );
  };
  fnClosePop = (data) => {
    this.setState({ [data]: false }, function () {});
  };
  setOnChangeCust = (e) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          customerName: e.target.value,
          customerCode: '',
        },
      },
      function () {}
    );
  };

  fnDelete = (e) => {
    let that = this;
    let data = this.state.form;

    this.setState({ delView: true });
  };

  confirm = () => {
    let data = this.state.form;
    data.iuFlag = 'D';
    this.fnSave(data);
  };
  render() {
    if (!this.props.breedRecvFormView) return null;
    let form = this.state.form;
    const Option = Select.Option;
    let strainOptions = [];
    if (this.state.strainCodeList && this.state.strainCodeList.length > 0) {
      strainOptions = this.state.strainCodeList.map((row, i) => {
        return (
          <Option key={i} value={row.codeValue}>
            {row.codeText}
          </Option>
        );
      });
    }
    let hatcheryOptions = [];
    if (this.state.hatcheryList && this.state.hatcheryList.length > 0) {
      hatcheryOptions = this.state.hatcheryList.map((row, i) => {
        return (
          <Option key={i} value={row.hatcheryId}>
            {row.hatcheryId}
          </Option>
        );
      });
    }
    // const customerOptions = this.state.customerList.map((d, i) => (
    //   <Option key={i} value={d.customerCode}>
    //     {d.customerName}
    //   </Option>
    // ));

    return (
      <Fragment>
        <h3>
          <Trans>Receive Info</Trans>
        </h3>

        <div className="con_box">
          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Transfer No</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'transferNo', //*** */
                      type: '', //*** */
                      that: this, //*** */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 120, // px제외 : 없으면 30 */
                      disabled: true,
                    })}
                  </td>
                  <th>
                    <Trans>PO No</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'pOrderNo', //*** */
                      type: '', //*** */
                      that: this, //*** */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 180, // px제외 : 없으면 30 */
                      maxLength: 20,
                      disabled: form.transferNo ? true : false,
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Strain</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'strainCode', //*** */
                      type: 'SELECT', //*** */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      options: strainOptions, //*** */ type:'SELECT' options*/
                      placeholder: 'Strain', //*** */
                      width: 250, // px제외 : 없으면 30 */
                      disabled: form.transferNo ? true : false,
                    })}
                  </td>
                  <th>
                    <Trans>Hatchery</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'hatcheryId', //*** */
                      type: 'SELECT', //*** */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      options: hatcheryOptions, //*** */ type:'SELECT' options*/
                      placeholder: 'Hatchery', //*** */
                      width: 180, // px제외 : 없으면 30 */
                      disabled: form.transferNo ? true : false,
                    })}
                  </td>
                </tr>
                {form.transferNo ? (
                  <tr>
                    <th>
                      <Trans>Female</Trans>
                    </th>
                    <td>
                      {form.transferNo ? (
                        <label htmlFor="">
                          <Trans>Qty</Trans>{' '}
                          {GridUtil.renderInput({
                            type: 'S', //*** */
                            width: 100, // px제외 : 없으면 30 */
                            textAlign: 'right',
                            value: form.recvQtyF
                              ? form.recvQtyF.toLocaleString()
                              : '',
                          })}
                        </label>
                      ) : null}
                      {form.transferNo ? (
                        <label htmlFor="">
                          <Trans>Bonus</Trans>{' '}
                          {GridUtil.renderInput({
                            type: 'S', //*** */
                            width: 100, // px제외 : 없으면 30 */
                            textAlign: 'right',
                            value: form.bonusQtyF
                              ? form.bonusQtyF.toLocaleString()
                              : '',
                          })}
                        </label>
                      ) : null}
                    </td>
                    <th>
                      <Trans>Male</Trans>
                    </th>
                    <td>
                      {form.transferNo ? (
                        <label htmlFor="">
                          <Trans>Qty</Trans>{' '}
                          {GridUtil.renderInput({
                            type: 'S', //*** */
                            width: 100, // px제외 : 없으면 30 */
                            textAlign: 'right',
                            value: form.recvQtyM
                              ? form.recvQtyM.toLocaleString()
                              : '',
                          })}
                        </label>
                      ) : null}
                      {form.transferNo ? (
                        <label htmlFor="">
                          <Trans>Bonus</Trans>{' '}
                          {GridUtil.renderInput({
                            type: 'S', //*** */
                            width: 100, // px제외 : 없으면 30 */
                            textAlign: 'right',
                            value: form.bonusQtyM
                              ? form.bonusQtyM.toLocaleString()
                              : '',
                          })}
                        </label>
                      ) : null}
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
          <div className="board_list2 mT10">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Receive Date</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'receiveDate', //*** */
                      type: 'D', //*** */
                      that: this, //*** */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 120, // px제외 : 없으면 30 */
                      disabled: form.flockId ? true : false,
                      placeholder: 'Date',
                    })}
                  </td>
                  <th>
                    <Trans>Truck No</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'truckNo', //*** */
                      placeholder: '', //*** */
                      type: '', //*** */
                      width: 180, // px제외 : 없으면 30 */
                      //placeholder: 'Date',
                      maxLength: 10,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                  </td>
                </tr>

                {/* <tr>
                  <th>
                    <Trans>Supplier</Trans>
                  </th>
                  <td>
                    {
                      <Search
                        placeholder="Customer Code"
                        onSearch={value => this.fnSearchCust(value)}
                        enterButton
                        style={{ width: '300px' }}
                        ref={input => {
                          this['customerCode'] = input;
                        }}
                        value={this.state.form.customerCode}
                        disabled={form.flockId ? true : false}
                        onChange={value => this.setOnChangeCust(value)}
                      />
                    }
                  </td>
                  <th></th>
                  <td></td>
                </tr> */}
                <tr>
                  <th>
                    <Trans>Female</Trans>
                  </th>
                  <td colSpan="3" className="spac50_left">
                    <label htmlFor="">
                      <Trans>Receive</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'recvQtyF', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      width: 70, // px제외 : 없으면 30 */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                    })}
                    <label htmlFor="">
                      <Trans>Bonus</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'bonusQtyF', //*** */
                      type: 'N', //*** */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                      minVal: 0,
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                    <label htmlFor="">
                      <Trans>Death</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'deathQtyF', //*** */
                      type: 'N', //*** */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                      minVal: 0,
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                    <label htmlFor="">
                      <Trans>Quarantine</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'quarQtyF', //*** */
                      type: 'N', //*** */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                      minVal: 0,
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                    <label htmlFor="">
                      <Trans>Total</Trans>
                    </label>
                    {GridUtil.renderInput({
                      value: form.totalRecvQtyF
                        ? form.totalRecvQtyF.toLocaleString()
                        : '0', //*** */
                      type: 'S', //*** */
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      that: this, //*** */
                      textAlign: 'right',
                    })}{' '}
                    <Trans>birds</Trans>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Male</Trans>
                  </th>
                  <td colSpan="3" className="spac50_left">
                    <label htmlFor="">
                      <Trans>Receive</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'recvQtyM', //*** */
                      type: 'N', //*** */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                      minVal: 0,
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                    <label htmlFor="">
                      <Trans>Bonus</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'bonusQtyM', //*** */
                      type: 'N', //*** */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                      minVal: 0,
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                    <label htmlFor="">
                      <Trans>Death</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'deathQtyM', //*** */
                      type: 'N', //*** */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                      minVal: 0,
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                    <label htmlFor="">
                      <Trans>Quarantine</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'quarQtyM', //*** */
                      type: 'N', //*** */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                      minVal: 0,
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                    <label htmlFor="">
                      <Trans>Total</Trans>
                    </label>
                    {GridUtil.renderInput({
                      value: form.totalRecvQtyM
                        ? form.totalRecvQtyM.toLocaleString()
                        : '0', //*** */
                      type: 'S', //*** */
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      that: this, //*** */
                      textAlign: 'right',
                    })}{' '}
                    <Trans>birds</Trans>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td colSpan="5">
                    {GridUtil.renderInput({
                      inputName: 'remarks', //*** */
                      type: '', //*** */
                      //dec: 2,
                      maxLength: 100,
                      //maxVal: 999.99,
                      //width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p className="line"></p>
          <p className="txt17 bold mT10">
            <Trans>Sample</Trans>
          </p>

          <div className="board_list2 mT10">
            <table>
              <colgroup>
                <col style={{ width: '12%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '12%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Qty</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'sampleQty', //*** */
                      type: 'N', //*** */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                      minVal: 0,
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                  </td>
                  <th>
                    <Trans>Weight(g)</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'sampleWeight', //*** */
                      type: 'N', //*** */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                      minVal: 0,
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                  </td>
                  <th>
                    <Trans>Average(g)</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      value: form.sampleWeightAvg
                        ? form.sampleWeightAvg.toFixed(1).toLocaleString()
                        : '0', //*** */
                      type: 'S', //*** */
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                      textAlign: 'right',
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="txt_center mT10">
            <button className="btn_sky" onClick={(e) => this.fnNew()}>
              <Trans>Clear</Trans>
            </button>
            {form.iuFlag === 'U' && !form.flockId ? (
              <Popconfirm
                title={i18n.t('Are you sure delete this task?')}
                visible={this.state.delView}
                onVisibleChange={this.handleVisibleChange}
                onConfirm={this.confirm}
                onCancel={this.cancel}
                okText="OK"
                cancelText="Cancel"
              >
                <button className="btn_gray" onClick={(e) => this.fnDelete()}>
                  <Trans>Delete</Trans>
                </button>
              </Popconfirm>
            ) : null}
            {!form.flockId ? (
              <button className="btn_save" onClick={(e) => this.fnSave()}>
                <Trans>Save</Trans>
              </button>
            ) : null}
            {/* <button className="btn_save" onClick={e => this.fnTest()}>
              <Trans>Test</Trans>
            </button> */}
          </div>
        </div>

        {this.state.popSearchCustView ? (
          <PopSearchCust
            popSearchCustView={this.state.popSearchCustView}
            customerCode={this.state.customerCode}
            customerKind={this.state.customerKind}
            fnClosePop={this.fnClosePop}
            fnSelectCust={this.fnSelectCust}
          />
        ) : null}
      </Fragment>
    );
  }
}
export default withAlert()(BreedRecvFormHatchery);
