import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil, PopSearchCust } from 'utils';
import { Trans } from 'react-i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { moment } from 'components';
import i18n from 'i18next';
import { Popconfirm, Select, Input } from 'antd';
import NumberFormat from 'react-number-format';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';
const { Search } = Input;

/*
 ** breeding/receive/BreedRecvForm
 ** 종계입고 폼, 저장 후 계군에 합산
 ** ===============================
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class BreedRecvForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInstance: this,
      addType: '',
      form: {},
      prop: {},
      customerKind: [],
      customerList: [],
      strainCodeList: [],
      popSearchCustView: false,
      customerName: '',
      delView: false,
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.docReceiveNo = React.createRef();
    this.receiveDate = React.createRef();
    this.recvQtyF = React.createRef();

    let data = this.props.breedRecvForm;
    if (!data.receiveDate) {
      data.receiveDate = DateUtil.today();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.addType !== nextProps.addType) {
      prevState.currentInstance.selectCustomerList("'21'");
      prevState.currentInstance.selectCodeList(
        'EP26',
        'strainCodeList',
        "AND CODE_VALUE LIKE 'B%'"
      );

      let data = nextProps.breedRecvForm;
      //data.remarks = '';
      return {
        addType: nextProps.addType,
        strainCodeList: nextProps.strainCodeList,
        breedRecvForm: nextProps.breedRecvForm,
        form: prevState.currentInstance.fnCalcQty(data, ''),
      };
    } else return null;
  }

  //거래처 찾기
  selectCustomerList = (value) => {
    let data = {};
    let that = this;
    //data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.customerName = '';
    data.customerKind = value;
    //customerKind="'11','12','13','14'"
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/selectCustomerList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ customerList: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };
  selectCodeList = async (codeId, value, codeValue) => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.codeId = codeId;
    //data.codeValue = codeValue ? codeValue : '';
    data.sql = 'selectTcCodeDtl';
    const url = Server.getRestAPI();
    await axios({
      method: 'post',
      url: url + '/breed/release/selectSql.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ [value]: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };
  fnSearch = () => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.hatchDate = DateUtil.today();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/transfer/selectTlHatchingInfoList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('selectGlowingLogList', response.data);
        if (response.data.rows1.length > 0) {
          that.setState(
            {
              hatchingInfoList: response.data.rows1,
              strainCodeList: response.data.rows2,
              hatchingData: response.data.rows3 ? response.data.rows3[0] : {},
            },
            function () {}
          );
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  fnCalcQty = (data, col) => {
    let recvQtyF = Number(data.recvQtyF ? data.recvQtyF : 0);
    let bonusQtyF = Number(data.bonusQtyF ? data.bonusQtyF : 0);
    let deathQtyF = Number(data.deathQtyF ? data.deathQtyF : 0);
    let quarQtyF = Number(data.quarQtyF ? data.quarQtyF : 0);

    let recvQtyM = Number(data.recvQtyM ? data.recvQtyM : 0);
    let bonusQtyM = Number(data.bonusQtyM ? data.bonusQtyM : 0);
    let deathQtyM = Number(data.deathQtyM ? data.deathQtyM : 0);
    let quarQtyM = Number(data.quarQtyM ? data.quarQtyM : 0);

    if (col === '') {
      data.totalRecvQtyF = recvQtyF + bonusQtyF - deathQtyF - quarQtyF;
      data.totalRecvQtyM = recvQtyM + bonusQtyM - deathQtyM - quarQtyM;
      //data.sampleWeightAvg = sampleWeight / sampleQty;
    } else if (
      col === 'recvQtyF' ||
      col === 'bonusQtyF' ||
      col === 'deathQtyF' ||
      col === 'quarQtyF'
    ) {
      data.totalRecvQtyF = recvQtyF + bonusQtyF - deathQtyF - quarQtyF;
    } else if (
      col === 'recvQtyM' ||
      col === 'bonusQtyM' ||
      col === 'deathQtyM' ||
      col === 'quarQtyM'
    ) {
      data.totalRecvQtyM = recvQtyM + bonusQtyM - deathQtyM - quarQtyM;
    } else if (col === 'sampleQty' || col === 'sampleWeight') {
      let sampleQty = Number(data.sampleQty ? data.sampleQty : 0);
      let sampleWeight = Number(data.sampleWeight ? data.sampleWeight : 0);
      data.sampleWeightAvg = sampleWeight / sampleQty;
    }
    return data;
  };

  fnSave = (e) => {
    let that = this;
    //validate
    let data = this.state.form;

    if (!data.customerCode) {
      that.props.alert.show(i18n.t('Please enter Customer.'));
      this.customerName.focus();
      return false;
    }
    if (!data.receiveDate) {
      that.props.alert.show(i18n.t('Please enter Receive Date'));
      this.receiveDate.focus();
      return false;
    }

    if (!data.recvQtyF) data.recvQtyF = 0;
    if (!data.bonusQtyF) data.bonusQtyF = 0;
    if (!data.deathQtyF) data.deathQtyF = 0;
    if (!data.quarQtyF) data.quarQtyF = 0;
    if (!data.recvQtyM) data.recvQtyM = 0;
    if (!data.bonusQtyM) data.bonusQtyM = 0;
    if (!data.deathQtyM) data.deathQtyM = 0;
    if (!data.quarQtyM) data.quarQtyM = 0;

    if (!data.totalRecvQtyF) data.totalRecvQtyF = 0;
    if (!data.totalRecvQtyM) data.totalRecvQtyM = 0;

    if (!data.sampleWeightAvg) data.sampleWeightAvg = 0;

    if (data.totalRecvQtyF === 0 && data.totalRecvQtyM === 0) {
      that.props.alert.show(i18n.t('Please enter quantity.'));
      that.recvQtyF.focus(); //Number
      return false;
    }

    console.log('save', data);
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/saveFromSupplier.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.alert.show(i18n.t('Has been saved.'));
        if (data.iuFlag === 'I') {
          data.docReceiveNo = response.data.rows1[0].docReceiveNo;
          //         console.log('fnSaveFromHatchery', data, response);
        }
        that.props.fnSaveFromHatchery(data); //flock 선택
        //} else {
        //that.props.fnInit();
        //}

        // that.setState(
        //   { showFlockList: true, tpBreedRecvData: response.data.rows1[0] },
        //   function() {
        //     console.log(that.state);
        //   }
        // );
      })
      .catch(function (error) {
        return [];
      });
  };
  fnDelete = (e) => {
    this.setState({ delView: true });
  };

  confirm = () => {
    let data = this.state.form;
    data.iuFlag = 'D';
    this.fnSave(data);
    this.setState({ delView: false });
  };
  fnCancel = (e) => {
    this.props.fnCancelFromHatchery(this.state.form);
  };

  fnNew = () => {
    let data = {};
    data.iuFlag = 'I';
    data.receiveDate = DateUtil.today();
    this.setState({ form: data }, function () {});
  };
  setOnChange = (e, params, dateString) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';

    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }
    this.setState({ form: { ...that.state.form, [col]: val } }, function () {
      if (params.type === 'N') {
        let data = that.fnCalcQty(that.state.form, col);
        if (data.totalRecvQtyF < 0 || data.totalRecvQtyM < 0) {
          that.props.alert.show(i18n.t('The quantity is exceeded.'));
          data[col] = 0;
          that[col].focus();
          data = that.fnCalcQty(data, '');
        }
        that.setState({ form: data }, function () {});
      }
    });
  };

  fnTest = (e) => {
    this.props.fnSaveFromHatchery('1');
  };
  fnSearchCust = (value) => {
    this.setState(
      {
        popSearchCustView: true,
        customerCode: value,
        customerKind: ['01', '02', '03', '04', '05', '06', '07'],
      },
      function () {}
    );
  };
  fnSelectCust = (data) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          customerCode: data.customerCode,
          customerName: data.customerName,
        },
      },
      function () {}
    );
  };
  fnClosePop = (data) => {
    this.setState({ [data]: false }, function () {});
  };
  setOnChangeCust = (e) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          customerCode: e.target.value,
          customerName: '',
        },
      },
      function () {}
    );
  };
  render() {
    if (!this.props.breedRecvFormView) return null;

    let form = this.state.form;
    const Option = Select.Option;
    let strainOptions = [];
    if (this.state.strainCodeList && this.state.strainCodeList.length > 0) {
      strainOptions = this.state.strainCodeList.map((row, i) => {
        return (
          <Option key={i} value={row.codeValue}>
            {row.codeText}
          </Option>
        );
      });
    }
    // const customerOptions = this.state.customerList.map((d, i) => (
    //   <Option key={i} value={d.customerCode}>
    //     {d.customerName}
    //   </Option>
    // ));

    return (
      <Fragment>
        <h3>
          <Trans>Breed Receive</Trans>
        </h3>

        <div className="con_box">
          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Supplier</Trans> <span className="red">*</span>
                  </th>
                  <td>
                    <Search
                      placeholder="Customer Code"
                      onSearch={(value) => this.fnSearchCust(value)}
                      enterButton
                      style={{ width: '300px' }}
                      ref={(input) => {
                        this['customerCode'] = input;
                      }}
                      value={this.state.form.customerCode}
                      onChange={(value) => this.setOnChangeCust(value)}
                      disabled={form.flockId ? true : false}
                    />
                  </td>

                  <th>
                    <Trans>
                      Receive Date <span className="red">*</span>
                    </Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'receiveDate', //*** */
                      type: 'D', //*** */
                      that: this, //*** */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 120, // px제외 : 없으면 30 */
                      placeholder: 'Date',
                      disabled: form.flockId ? true : false,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Strain</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'strainCode', //*** */
                      type: 'SELECT', //*** */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      options: strainOptions, //*** */ type:'SELECT' options*/
                      placeholder: 'Strain', //*** */
                      width: 300, // px제외 : 없으면 30 */
                      disabled: form.flockId ? true : false,
                    })}
                  </td>
                  <th>
                    <Trans>PO No</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'pOrderNo', //*** */
                      type: '', //*** */
                      that: this, //*** */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 180, // px제외 : 없으면 30 */
                      maxLength: 20,
                      disabled: form.flockId ? true : false,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Female</Trans>
                  </th>
                  <td colSpan="3" className="spac50_left">
                    <label htmlFor="">
                      <Trans>Receive</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'recvQtyF', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 70, // px제외 : 없으면 30 */
                      disabled: form.flockId ? true : false,
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                    })}
                    <label htmlFor="">
                      <Trans>Bonus</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'bonusQtyF', //*** */
                      type: 'N', //*** */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                      minVal: 0,
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      disabled: form.flockId ? true : false,
                    })}
                    <label htmlFor="">
                      <Trans>Death</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'deathQtyF', //*** */
                      type: 'N', //*** */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                      minVal: 0,
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                    <label htmlFor="">
                      <Trans>Quarantine</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'quarQtyF', //*** */
                      type: 'N', //*** */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                      minVal: 0,
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                    <label htmlFor="">
                      <Trans>Total</Trans>
                    </label>
                    {GridUtil.renderInput({
                      value: form.totalRecvQtyF
                        ? form.totalRecvQtyF.toLocaleString()
                        : '0', //*** */
                      type: 'S', //*** */
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                      textAlign: 'right',
                    })}{' '}
                    <Trans>birds</Trans>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Male</Trans>
                  </th>
                  <td colSpan="3" className="spac50_left">
                    <label htmlFor="">
                      <Trans>Receive</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'recvQtyM', //*** */
                      type: 'N', //*** */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                      minVal: 0,
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                    <label htmlFor="">
                      <Trans>Bonus</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'bonusQtyM', //*** */
                      type: 'N', //*** */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                      minVal: 0,
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                    <label htmlFor="">
                      <Trans>Death</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'deathQtyM', //*** */
                      type: 'N', //*** */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                      minVal: 0,
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                    <label htmlFor="">
                      <Trans>Quarantine</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'quarQtyM', //*** */
                      type: 'N', //*** */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                      minVal: 0,
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                    <label htmlFor="">
                      <Trans>Total</Trans>
                    </label>
                    {GridUtil.renderInput({
                      value: form.totalRecvQtyM
                        ? form.totalRecvQtyM.toLocaleString()
                        : '0', //*** */
                      type: 'S', //*** */
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                      textAlign: 'right',
                    })}{' '}
                    <Trans>birds</Trans>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Truck No</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'truckNo', //*** */
                      placeholder: '', //*** */
                      type: '', //*** */
                      width: 180, // px제외 : 없으면 30 */
                      //placeholder: 'Date',
                      maxLength: 10,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                  </td>
                  <th></th>
                  <td></td>
                </tr>
                <tr>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td colSpan="5">
                    {GridUtil.renderInput({
                      inputName: 'remarks', //*** */
                      type: '', //*** */
                      //dec: 2,
                      maxLength: 1000,
                      //maxVal: 999.99,
                      //width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p className="line"></p>
          <p className="txt17 bold mT10">
            <Trans>Sample</Trans>
          </p>

          <div className="board_list2 mT10">
            <table>
              <colgroup>
                <col style={{ width: '12%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '12%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Qty</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'sampleQty', //*** */
                      type: 'N', //*** */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                      minVal: 0,
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}
                  </td>
                  <th>
                    <Trans>Weight(g)</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'sampleWeight', //*** */
                      type: 'N', //*** */
                      //dec: 2,
                      //maxLength: 6,
                      //maxVal: 999.99,
                      minVal: 0,
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                    })}{' '}
                    <Trans>g</Trans>
                  </td>
                  <th>
                    <Trans>Average(g)</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      value: form.sampleWeightAvg
                        ? form.sampleWeightAvg.toFixed(1).toLocaleString()
                        : '0', //*** */
                      type: 'S', //*** */
                      width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      disabled: form.flockId ? true : false,
                      that: this, //*** */
                      textAlign: 'right',
                    })}{' '}
                    <Trans>g</Trans>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="txt_center mT10">
            <button className="btn_sky" onClick={(e) => this.fnNew()}>
              <Trans>Clear</Trans>
            </button>

            {form.iuFlag === 'U' && !form.flockId ? (
              <Popconfirm
                title={i18n.t('Are you sure delete this task?')}
                visible={this.state.delView}
                onVisibleChange={this.handleVisibleChange}
                onConfirm={this.confirm}
                onCancel={this.cancel}
                okText="OK"
                cancelText="Cancel"
              >
                <button className="btn_gray" onClick={(e) => this.fnDelete()}>
                  <Trans>Delete</Trans>
                </button>
              </Popconfirm>
            ) : null}
            {!form.flockId ? (
              <button className="btn_save" onClick={(e) => this.fnSave()}>
                <Trans>Save</Trans>
              </button>
            ) : null}
          </div>
        </div>

        {this.state.popSearchCustView ? (
          <PopSearchCust
            popSearchCustView={this.state.popSearchCustView}
            customerCode={this.state.customerCode}
            customerKind={this.state.customerKind}
            fnClosePop={this.fnClosePop}
            fnSelectCust={this.fnSelectCust}
          />
        ) : null}
      </Fragment>
    );
  }
}
export default withAlert()(BreedRecvForm);
