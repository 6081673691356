import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import moment from 'moment';
import 'moment/locale/ko.js';

import 'moment/locale/vi.js';

import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';

import '../Breeding.module.css';
/*
 ** breeding/receive/HenHouseList
 ** 계사(HenHouse-HH)
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class HhMoveinList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hhMoveView: false,
      form: {},
      prop: {},
      rowData: []
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState !== nextProps) {
      //if (nextProps.hhChickinList && nextProps.hhChickinList.length > 0) {
      return nextProps;
      //}
      //return null;
    }
    return null;
  }

  fnSearch = () => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    //data.receiveNo = '';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/selectHenHouseList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        console.log('selectTpFlockInfo', response.data);

        that.setState({ rowData: response.data.rows1 }, function() {
          console.log(that.state);
        });
      })
      .catch(function(error) {
        return [];
      });
  };
  fnClickMove = row => {
    this.props.fnClickMove(row);
  };
  fnClickCancel = row => {
    this.props.fnClickCancel(row);
  };
  fnClickEmpty = row => {};
  //Move out - Move click
  fnClickMovein = row => {
    console.log('fnClickMovein', row);
    this.props.fnClickMovein(row, this.props.moveinRow);
  };
  hhButton = row => {
    //console.log('hhButton', row);
    //Moveout List 에서 넘어온 data 0 아니면
    if (
      Number(row.fromMoveQtyF ? row.fromMoveQtyF : 0) !== 0 ||
      Number(row.fromMoveQtyM ? row.fromMoveQtyM : 0) !== 0
    ) {
      if (this.props.flockListFlockId === row.flockId) {
        return (
          <dd>
            <button className="btn_blue" onClick={e => this.fnClickMovein(row)}>
              <Trans>Move In</Trans>
            </button>
          </dd>
        );
      } else return null;
    } else {
      return null;
    }
  };
  hhEmptyButton = row => {
    if (
      Number(row.fromMoveQtyF ? row.fromMoveQtyF : 0) !== 0 ||
      Number(row.fromMoveQtyM ? row.fromMoveQtyM : 0) !== 0
    ) {
      return (
        <dd>
          <button
            className="btn_blue"
            onClick={e => this.fnClickMovein(row, 'E')}
          >
            <Trans>Move In</Trans>
          </button>
        </dd>
      );
    } else {
      return null;
    }
  };
  render() {
    if (!this.state.hhMoveView) return null;
    let that = this;
    let hhChickinList = [];
    let cc = 0;
    let cc10 = 0;
    const ccClass = 'full_01 h';
    const ccClass100 = 'full_03 h100';
    if (this.state.hhChickinList && this.state.hhChickinList.length > 0) {
      hhChickinList = this.state.hhChickinList.map((row, i) => {
        if (that.state.moveinRow) {
          row.fromMoveQtyF = that.state.moveinRow.fromMoveQtyF;
          row.fromMoveQtyM = that.state.moveinRow.fromMoveQtyM;
        }
        row.propsHhId = that.state.hhId;
        if (row.flockId) {
          cc = parseInt(
            ((row.currentQtyF + row.currentQtyM) / row.capacity) * 100,
            10
          );
          cc10 = parseInt(cc / 10, 10) * 10;
          row.capa = row.capacity - (row.currentQtyF + row.currentQtyM);
          //console.log('HenHouseList', row, that.state.moveinRow);
          return (
            <div key={i} className="full">
              <dl className="reinfo">
                <dt>
                  <Trans>{row.hhId}</Trans>
                </dt>
                <dd className="f_left mL10">
                  {row.lastWorkDate > row.lastMoveDate
                    ? row.lastWorkDate
                    : row.lastMoveDate}
                </dd>
                <dd className="f_right mR10">
                  {row.days ? row.days.toLocaleString() : '0'}{' '}
                  <Trans>Days</Trans>
                  {'/'}
                  {row.weeks ? row.weeks.toLocaleString() : '0'}{' '}
                  <Trans>Weeks</Trans>
                </dd>
                <dd>&nbsp; </dd>

                <dd className="txt_left mL10">
                  <Trans>Birds</Trans>
                  {' ('}
                  <Trans>Current</Trans> / <Trans>Chick-In</Trans> ){' '}
                </dd>
                <dd className="txt_left mL10">
                  <Trans>F</Trans> :{' '}
                  {row.currentQtyF ? row.currentQtyF.toLocaleString() : '0'} /{' '}
                  {row.chickinQtyF ? row.chickinQtyF.toLocaleString() : '0'}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Trans>M</Trans> :{' '}
                  {row.currentQtyM ? row.currentQtyM.toLocaleString() : '0'} /{' '}
                  {row.chickinQtyM ? row.chickinQtyM.toLocaleString() : '0'}
                </dd>

                <dd className="txt_left mL10">
                  <Trans>Flock </Trans>: {row.flockId}
                </dd>
                <dd className="txt_left mL10">
                  <Trans>Strain </Trans>: {row.strainName}
                </dd>
                {/* 병아리가 들어있는 계사는 Move 버튼 활성화 */}
                {that.hhButton(row)}
              </dl>
            </div>
          );
        } else {
          return (
            <div key={i} className="full">
              <dl className="reinfo">
                <dt>
                  <Trans>{row.hhId}</Trans>
                </dt>
                <dd>
                  <span className="green">
                    <Trans>EMPTY</Trans>
                  </span>
                </dd>
                {that.hhEmptyButton(row)}
                {/* 
                <dd>
                  <button
                    className="btn_gray2"
                    onClick={e => this.fnClickEmpty(row)}
                  >
                    <Trans>EMPTY</Trans>
                  </button>
                </dd> */}
              </dl>
            </div>
          );
        }
      });
    }
    return (
      <Fragment>
        {/* Hen House */}
        <li className="divide_right">
          <div className="dititle">
            <i className="mdi mdi-grid"></i> <Trans>Hen House</Trans>
          </div>
          {hhChickinList}
        </li>
      </Fragment>
    );
  }
}
export default HhMoveinList;
