import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import FHDateRangeSearch from '../utils/FHDateRangeSearch';
import AFDateRangeSearch from '../utils/AFDateRangeSearch';
import ReportUtil from '../utils/ReportUtil';

import '../Report.module.css';

// 15. 완료 계군 분석 보고서 (기간/Flock별)
export default class ClsFlkAnlysFH extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      excelName: i18n.t('Closed Flock Analysis Report (Term/Flock)'),
      rowData: [],
      params: {},
      ColDefs: [
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Farm'),
              field: 'farm',
              pinned: 'left',
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Flock'),
              field: 'flock',
              minWidth: 120,
              pinned: 'left',
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('HH'),
              field: 'hh',
              pinned: 'left',
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('Chick In'),
              children: [
                {
                  headerName: i18n.t('Date'),
                  field: 'ciDate',
                },
                {
                  headerName: i18n.t('Female'),
                  field: 'ciFemale',
                },
                {
                  headerName: i18n.t('Male'),
                  field: 'ciMale',
                },
              ],
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('Chick Out'),
              children: [
                {
                  headerName: i18n.t('Date'),
                  field: 'coDate',
                },
                {
                  headerName: i18n.t('Female'),
                  field: 'coFemale',
                },
                {
                  headerName: i18n.t('Male'),
                  field: 'coMale',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Egg Production'),
          children: [
            {
              headerName: i18n.t(''),
              children: [
                {
                  headerName: i18n.t('Egg Prod'),
                  field: 'epEggProd',
                },
              ],
            },
            {
              headerName: i18n.t(''),
              children: [
                {
                  headerName: i18n.t('Egg Good'),
                  field: 'epEggGood',
                },
              ],
            },
            {
              headerName: i18n.t(''),
              children: [
                {
                  headerName: i18n.t('Culling Egg'),
                  field: 'epCullingEgg',
                },
              ],
            },
            {
              headerName: i18n.t('EP Cum. Per HH'),
              children: [
                {
                  headerName: i18n.t('Act'),
                  field: 'epAct',
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'epStd',
                },
              ],
            },
            {
              headerName: i18n.t('Egg Good Weight'),
              children: [
                {
                  headerName: i18n.t('Act'),
                  field: 'egAct',
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'egStd',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('HE'),
          children: [
            {
              headerName: '',
              children: [
                {
                  headerName: i18n.t('HE'),
                  field: 'hHE',
                },
              ],
            },
            {
              headerName: '',
              children: [
                {
                  headerName: i18n.t('CE'),
                  field: 'hCE',
                },
              ],
            },
            {
              headerName: i18n.t('HE Cum. Per HH'),
              children: [
                {
                  headerName: i18n.t('Act'),
                  field: 'eeAct',
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'eeStd',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Saleable Chick'),
          children: [
            {
              headerName: '',
              children: [
                {
                  headerName: i18n.t('DOC'),
                  field: 'sDOC',
                },
              ],
            },
            {
              headerName: i18n.t('SC Per HH'),
              children: [
                {
                  headerName: i18n.t('Act'),
                  field: 'ssAct',
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'ssStd',
                },
              ],
            },
          ],
        },
      ],
      gridHeight: '640px',
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        width: 100,
        minWidth: 100,
        cellClass: 'text-center',
        suppressSizeToFit: false,
        valueFormatter: function (params) {
          return GridUtil.formatReport(params);
        },
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagenation: false,
      paginationPageSize: 10,
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    if (Const.RPT_TEST) {
      const params = ReportUtil.getDefaultParams(this.props);
      console.log('params:', params);
      this.fnGetRowData(params);
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  // -----------------------------------------------------------
  // 참고용 : ES/ETL 서버 주소(API)를 지정해야 합니다.
  // -----------------------------------------------------------
  fnGetRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/stat/breeding/clsFlkAnlysFH.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data.rows1 });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtExport = () => {
    const fileName =
      i18n.t(this.state.excelName) + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t(this.state.excelName);
    const params = {
      allColumns: false,
      columnGroups: true,
      fileName: fileName,
      sheetName: sheetName,
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onSearch = (params) => {
    console.log(params);
    this.fnGetRowData(params);
  };

  render() {
    return (
      <React.Fragment>
        <h3>
          {/* 17. 완료 계군 분석 보고서 (기간/Flock별) */}
          <Trans>Title Cls Flk Anlys(F/H)</Trans>
        </h3>

        <div className="con_box">
          <AFDateRangeSearch onSearch={this.onSearch}></AFDateRangeSearch>

          <div className="f_right mB20">
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
          </div>
          <div className="board_list_s">
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  excelStyles={this.state.excelStyles}
                  onGridReady={this.onGridReady}
                  rowData={this.state.rowData}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

let i = 0;
const sampleData = [
  {
    farm: i++,
    flock: i++,
    ciDate: i++,
    ciFemale: i++,
    ciMale: i++,
    coDate: i++,
    coFemale: i++,
    coMale: i++,
    epEggProd: i++,
    epCullingEgg: i++,
    epAct: i++,
    epStd: i++,
    egAct: i++,
    egStd: i++,
    hHE: i++,
    hCE: i++,
    eeAct: i++,
    eeStd: i++,
    sDOC: i++,
    ssAct: i++,
    ssStd: i++,
  },
];
