import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, CommonUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected
} from 'components/commons/ag-grid/AgGridUtil';
import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { message, Select } from 'antd';

// import _ from 'lodash';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class CountryCode extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        masterCodeId: 'EP20'
      },
      codes: [],
      code: undefined,
      disabled: false
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnGetRowData(this.state.params);
  }

  // =========================================================================
  // deprecated 되었으므로, 사용을 금합니다.
  // componentWillReceiveProps(nextProps) {}
  // =========================================================================
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.disabled !== this.props.disabled) {
      this.setState({ code: '', disabled: this.props.disabled });
    }
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.onSearch(this.props.defaultValue);
    }
  }

  // 국가 코드 정보 가져오기
  fnGetRowData = params => {
    const that = this;
    CommonUtil.selectCodes({ masterCodeId: 'EP20' })
      .then(function(response) {
        const { data } = response;
        const codes = [];
        if (data) {
          data.map((row, i) => {
            const obj = {
              codeValue: row.codeValue,
              codeText: row.codeText
            };
            codes.push(obj);
          });
          // console.log(codes);
          that.setState({ codes });
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  onSearch = value => {
    this.setState({ code: value });
  };

  onChange = value => {
    const that = this;
    this.setState(
      produce(draft => {
        draft.code = value;
      }),
      function() {
        that.props.setCode('countryCode', value);
      }
    );
  };

  render() {
    return (
      <Select
        showSearch
        value={this.state.code}
        // name="countryCode"
        // value={this.state.code}
        onSearch={this.onSearch}
        onChange={this.onChange}
        placeholder={i18n.t('Select Code')} // SearchInput에는 안나오는 현상 있음
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={this.props.style}
        disabled={this.props.disabled}
      >
        {this.state.codes.map((row, i) => {
          // console.log(row);
          return (
            /* key값으로 검색을 합니다. (중요) */
            <Select.Option key={row.codeValue} value={row.codeValue}>
              {row.codeText}
            </Select.Option>
          );
        })}
      </Select>
    );
  }
}
