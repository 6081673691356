import React, { Fragment } from 'react';
import Hatcher from './Hatcher';

export default class HatcherGPS extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      farmType: 'PS',
    };
  }

  render() {
    return (
      <Hatcher farmType={this.state.farmType} />
    );
  }
}