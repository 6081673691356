import React, { Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil, PopSearchCust } from 'utils';
import { Trans } from 'react-i18next';
import { Const, Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';
import { moment } from 'components';
import { Select, Input } from 'antd';
import i18n from 'i18next';
import { withAlert } from 'react-alert';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
  GridOptions,
} from 'components/commons/ag-grid/AgGridUtil';
import '../Hatchery.module.css';
const { Search } = Input;

/*
 ** Hatchery - Transfer - Chick - PsBroilerDocSales
 ** ============================= 2020-04-23
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class BroilerDocSalesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFromWkDt: moment(DateUtil.today())
        .add('-1', 'M')
        .format('YYYY-MM-DD'),
      searchToWkDt: DateUtil.today(),
      currentInstance: this,
      form: [],
      broilerDocFormView: false,
      popSearchCustView: false,
      hatchingInfoList: [],
      hatchingData: {},
      hatchingDataList: [],
      customerList: [],
      customerName: '',
      customerKind: '',
      columnDefs: [
        {
          headerName: 'Hen House',
          field: 'hhId',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'StrainCode',
          field: 'strainCode',
          hide: true,
        },
        {
          headerName: 'Hatch Date',
          field: 'hatchDate',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'PS DOC(F)',
          field: 'psdocFQty',
          type: 'numericColumn',
          width: 80,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'PS DOC(M)',
          field: 'psdocMQty',
          type: 'numericColumn',
          width: 80,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'S/P',
          field: 'spQty',
          width: 80,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'A2',
          field: 'a2Qty',
          type: 'numericColumn',
          width: 80,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'A1',
          field: 'a1Qty',
          type: 'numericColumn',
          width: 80,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'BM',
          field: 'bmQty',
          type: 'numericColumn',
          width: 80,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'B',
          field: 'bQty',
          type: 'numericColumn',
          width: 80,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Polos',
          field: 'polosQty',
          type: 'numericColumn',
          width: 80,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Sex',
          field: 'sexQty',
          type: 'numericColumn',
          width: 80,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
      ],
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    //필수 항목 setFocus를 위한 정의
    this.transferDate = React.createRef();
    this.hatchDate = React.createRef();
    this.strainCode = React.createRef();
    this.closingRelease = React.createRef();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.broilerDocFormView !== nextProps.broilerDocFormView) {
      if (nextProps.broilerDocFormView) prevState.currentInstance.fnSearch();
      return {
        broilerDocFormView: nextProps.broilerDocFormView,
        form: nextProps.broilerDocForm,
      };
    }
    return null;
  }
  fnSearch = () => {
    window.scrollTo(0, 0);
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.searchFromWkDt = this.state.searchFromWkDt;
    data.searchToWkDt = this.state.searchToWkDt;
    data.hatchDate = DateUtil.today();

    data.codeId = 'EP26';
    data.calType = 'docSales';

    if (data.searchFromWkDt > data.searchToWkDt) {
      that.props.alert.show(i18n.t('Please check the date.'));
      that.searchFromWkDt.focus();
      return false;
    }

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/transfer/selectBroilerDocSalesInfoList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            hatchingDataList: response.data.rows1,
            strainCodeList: response.data.rows2,
          },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  //Sales 저장
  fnSave = () => {
    let that = this;
    let data = this.state.form;

    if (!data.hatchDate) {
      that.props.alert.show(i18n.t('Please enter hatchDate.'));
      that.hatchDate.focus();
      return false;
    }
    if (!data.transferDate) {
      that.props.alert.show(i18n.t('Please enter transferDate.'));
      that.transferDate.focus();
      return false;
    }

    if (data.transferDate < data.hatchDate) {
      that.props.alert.show(i18n.t('TransferDate must be behind HatchDate.'));
      that.transferDate.focus();
      return false;
    }

    if (!data.customerCode) {
      that.props.alert.show(i18n.t('Please enter customerCode.'));
      that.customerCode.focus();
      return false;
    }

    data.psdocFBox = Number(data.psdocFBox ? data.psdocFBox : 0);
    data.psdocFQty = Number(data.psdocFQty ? data.psdocFQty : 0);
    data.psdocFBonus = Number(data.psdocFBonus ? data.psdocFBonus : 0);

    data.psdocMBox = Number(data.psdocMBox ? data.psdocMBox : 0);
    data.psdocMQty = Number(data.psdocMQty ? data.psdocMQty : 0);
    data.psdocMBonus = Number(data.psdocMBonus ? data.psdocMBonus : 0);

    data.spBox = Number(data.spBox ? data.spBox : 0);
    data.spQty = Number(data.spQty ? data.spQty : 0);
    data.spBonus = Number(data.spBonus ? data.spBonus : 0);

    data.a2Box = Number(data.a2Box ? data.a2Box : 0);
    data.a2Qty = Number(data.a2Qty ? data.a2Qty : 0);
    data.a2Bonus = Number(data.a2Bonus ? data.a2Bonus : 0);

    data.a1Box = Number(data.a1Box ? data.a1Box : 0);
    data.a1Qty = Number(data.a1Qty ? data.a1Qty : 0);
    data.a1Bonus = Number(data.a1Bonus ? data.a1Bonus : 0);

    data.bmBox = Number(data.bmBox ? data.bmBox : 0);
    data.bmQty = Number(data.bmQty ? data.bmQty : 0);
    data.bmBonus = Number(data.bmBonus ? data.bmBonus : 0);

    data.bBox = Number(data.bBox ? data.bBox : 0);
    data.bQty = Number(data.bQty ? data.bQty : 0);
    data.bBonus = Number(data.bBonus ? data.bBonus : 0);

    data.polosBox = Number(data.polosBox ? data.polosBox : 0);
    data.polosQty = Number(data.polosQty ? data.polosQty : 0);
    data.polosBonus = Number(data.polosBonus ? data.polosBonus : 0);

    data.sexBox = Number(data.sexBox ? data.sexBox : 0);
    data.sexQty = Number(data.sexQty ? data.sexQty : 0);
    data.sexBonus = Number(data.sexBonus ? data.sexBonus : 0);

    if (!checkQty(that)) {
      return false;
    }

    console.log('data', data);

    let qty =
      data.psdocFQty +
      data.psdocFBonus +
      data.psdocMQty +
      data.psdocMBonus +
      data.spQty +
      data.spBonus +
      data.a2Qty +
      data.a2Bonus +
      data.a1Qty +
      data.a1Bonus +
      data.bmQty +
      data.bmBonus +
      data.bQty +
      data.bBonus +
      data.polosQty +
      data.polosBonus;
    if (!qty || qty === 0) {
      that.props.alert.show(i18n.t('Please enter quantity.'));
      return false;
    }

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.updateId = SessionUtil.getUserId();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.docRelType = 'S';
    data.strainCode = data.strainCode;

    console.log('save', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/transfer/savePsDoc.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.fnInit();
      })
      .catch(function (error) {
        return [];
      });
    function checkQty(that) {
      let clos = [
        'spQty',
        'a2Qty',
        'a1Qty',
        'bmQty',
        'bQty',
        'polosQty',
        'psdocFQty',
        'psdocMQty',
      ];
      let chk = true;
      let strainCode = that.state.form.strainCode;
      clos.some((col) => {
        let co = col.replace('Box', '').replace('Qty', '').replace('Bonus', '');
        let hatchingQty = 0;
        let hatching = that.state.hatchingDataList.find(
          (row) => row.strainCode === strainCode
        );
        if (hatching) {
          //hatchingQty = hatching ? Number(hatching[co + 'Qty']) : 0;

          hatchingQty = that.state.clickedForm[co + 'Qty'];

          let qty =
            Number(
              that.state.form[co + 'Qty'] ? that.state.form[co + 'Qty'] : 0
            ) +
            Number(
              that.state.form[co + 'Bonus'] ? that.state.form[co + 'Bonus'] : 0
            );

          if (qty > hatchingQty) {
            that.props.alert.show(i18n.t('The quantity is exceeded.'));
            that[col].focus();
            chk = false;
            return true;
          }
        } else {
          chk = false;
          that.props.alert.show(i18n.t('Please check Strain.'));
          return true;
        }
      });
      return chk;
    }
  };
  //거래처 찾기
  selectCustomerList = (value) => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.customerName = '';
    data.customerKind = value;
    //customerKind="'11','12','13','14'"
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/selectCustomerList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ customerList: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };
  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text

  setOnChange = (e, params) => {
    let that = this;
    let col = '';
    let val = '';
    let qtys = 0;
    let bonus = 0;

    console.log('1', e);
    console.log('2', params);

    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    let colBox = col.substring(col.length - 3, col.length);
    let colBonus = col.substring(col.length - 5, col.length);
    console.log(col);
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
      if (col === 'psdocFBox' || col === 'psdocMBox') {
        qtys = val * 70;
        bonus = val * 3;
      }
      if (colBox === 'Box' && !(col === 'psdocFBox' || col === 'psdocMBox')) {
        qtys = val * 100;
        bonus = val * 2;
      }
    }

    that.setState(
      {
        [col]: val,
        form: {
          ...that.state.form,
          [col]: val,
          [colBox === 'Box' ? col.replace('Box', 'Qty') : null]: qtys,
          [colBox === 'Box' ? col.replace('Box', 'Bonus') : null]: bonus,
        },
      },
      function () {
        that.fnSelectHatchingList(col);
      }
    );
  };
  fnSelectHatchingList = (col) => {
    let data = {};
    let that = this;

    if (col === 'hatchDate' || col === 'strainCode') {
      let hatchDate = that.state.form.hatchDate
        ? that.state.form.hatchDate
        : DateUtil.today();
      if (hatchDate) {
        data.farmCode = SessionUtil.getFarmCode();
        data.countryCode = SessionUtil.getCountryCode();
        data.dateFormat = SessionUtil.getDateFormat();
        data.hatcheryId = SessionUtil.getHatcheryId();
        data.hatchDate = hatchDate;

        //console.log('fnSelectHatchingList', data);
        //data.receiveNo = '';
        const url = Server.getRestAPI();
        axios({
          method: 'post',
          url: url + '/hatchery/transfer/selectHatchingList.do',
          data: qs.stringify(data),
          withCredentials: true,
          credentials: 'same-origin',
        })
          .then(function (response) {
            that.setState(
              {
                hatchingDataList: response.data.rows1,
              },
              function () {
                console.log('hatchingData', that.state.hatchingDataList);
              }
            );
          })
          .catch(function (error) {
            return [];
          });
      }
    }
  };
  fnSearchCust = (value) => {
    this.setState(
      {
        popSearchCustView: true,
        customerCode: value,
        customerKind: [],
      },
      function () {}
    );
  };
  fnSelectCust = (data) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          customerCode: data.customerCode,
          customerName: data.customerName,
        },
      },
      function () {}
    );
  };
  fnClosePop = (data) => {
    this.setState({ [data]: false }, function () {});
  };
  setOnChangeCust = (e) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          customerCode: e.target.value,
          customerName: '',
        },
      },
      function () {}
    );
  };
  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  onCellClicked = (e) => {
    console.log('onCellClicked e.data : ', e.data);
    this.setState(
      {
        form: {
          ...this.state.form,
          strainCodeNm: e.data.strainCodeNm,
          strainCode: e.data.strainCode,
          hatchDate: e.data.hatchDate,
          psdocFQty: e.data.psdocFQty,
          psdocMQty: e.data.psdocMQty,
          spQty: e.data.spQty,
          a1Qty: e.data.a1Qty,
          a2Qty: e.data.a2Qty,
          bQty: e.data.bQty,
          bmQty: e.data.bmQty,
          polosQty: e.data.polosQty,
          hhId: e.data.hhId,
          sexQty: e.data.sexQty,
          eggReceiveNo: e.data.eggReceiveNo,
          settingSeq: e.data.settingSeq,
        },
        clickedForm: {
          ...this.state.clickedForm,
          strainCodeNm: e.data.strainCodeNm,
          strainCode: e.data.strainCode,
          hatchDate: e.data.hatchDate,
          psdocFQty: e.data.psdocFQty,
          psdocMQty: e.data.psdocMQty,
          spQty: e.data.spQty,
          a1Qty: e.data.a1Qty,
          a2Qty: e.data.a2Qty,
          bQty: e.data.bQty,
          bmQty: e.data.bmQty,
          polosQty: e.data.polosQty,
          hhId: e.data.hhId,
          sexQty: e.data.sexQty,
          eggReceiveNo: e.data.eggReceiveNo,
          settingSeq: e.data.settingSeq,
        },
        iuFlag: 'I',
      },
      function () {}
    );
  };

  fnCancel = () => {
    this.setState(
      {
        form: {
          iuFlag: 'I',
          hatchDate: DateUtil.today(),
          transferDate: DateUtil.today(),
        },
      },
      function () {}
    );
  };
  render() {
    if (!this.props.broilerDocFormView) return null;
    let strainOptions = [];
    const Option = Select.Option;
    if (this.state.strainCodeList && this.state.strainCodeList.length > 0) {
      strainOptions = this.state.strainCodeList.map((row, i) => {
        return (
          <Option key={i} value={row.strainCode}>
            {row.strainCodeNm}
          </Option>
        );
      });
    }

    return (
      <Fragment>
        <h3 className="mT20">
          <Trans>Broiler DOC Sales</Trans>
        </h3>

        <div className="board_list2">
          <table>
            <colgroup>
              <col style={{ width: '15%' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '15%' }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <Trans>Hatch Date</Trans>
                </th>
                <td>{this.state.form.hatchDate}</td>
                <th>
                  <Trans>Hen House</Trans> <span className="red">*</span>
                </th>
                <td>{this.state.form.hhId}</td>
              </tr>
              <tr>
                <th>
                  <Trans>Transfer Date</Trans> <span className="red">*</span>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'transferDate', //*** */
                    type: 'D', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    placeholder: 'transferDate',
                  })}
                </td>
                <th>
                  <Trans>Sales Order No</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'orderNo', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 150, // px제외 : 없으면 100% */
                    maxLength: 20,
                    //placeholder: 'Date',
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Customer</Trans> <span className="red">*</span>
                </th>
                <td>
                  <Search
                    placeholder="Customer Code"
                    onSearch={(value) => this.fnSearchCust(value)}
                    enterButton
                    //style={{ width: '300px' }}
                    className="w100"
                    ref={(input) => {
                      this['customerCode'] = input;
                    }}
                    value={this.state.form.customerCode}
                    onChange={(value) => this.setOnChangeCust(value)}
                  />
                </td>
                <th>
                  <Trans>Truck No.</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'truckNo', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    maxLength: 10,
                    //placeholder: 'Date',
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Remarks</Trans>
                </th>
                <td colSpan="3">
                  {GridUtil.renderInput({
                    inputName: 'remarks', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    //width: 120, // px제외 : 없으면 100% */
                    maxLength: 1000,
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Broiler DOC</Trans>
                </th>
                <td colSpan="3">
                  <font className="wtitle">
                    <Trans>PS DOC Female</Trans>
                  </font>
                  {GridUtil.renderInput({
                    inputName: 'psdocFBox', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}

                  <font className="mR20"> Box</font>
                  {GridUtil.renderInput({
                    inputName: 'psdocFQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}

                  <font className="mR10"> Qty</font>
                  <font className="mR10">+</font>
                  {GridUtil.renderInput({
                    inputName: 'psdocFBonus', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}

                  <font className="mR10"> Bonus</font>

                  <br />
                  <font className="wtitle">
                    <Trans>PS DOC Male</Trans>
                  </font>
                  {GridUtil.renderInput({
                    inputName: 'psdocMBox', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}

                  <font className="mR20"> Box</font>
                  {GridUtil.renderInput({
                    inputName: 'psdocMQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}

                  <font className="mR10"> Qty</font>
                  <font className="mR10">+</font>
                  {GridUtil.renderInput({
                    inputName: 'psdocMBonus', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}

                  <font className="mR10"> Bonus</font>
                  <br />

                  <font className="wtitle">
                    <Trans>S/P</Trans>
                  </font>
                  {GridUtil.renderInput({
                    inputName: 'spBox', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR20"> Box</font>
                  {GridUtil.renderInput({
                    inputName: 'spQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Qty</font>
                  <font className="mR10">+</font>
                  {GridUtil.renderInput({
                    inputName: 'spBonus', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Bonus</font>

                  <br />
                  <font className="wtitle">
                    <Trans>A2</Trans>
                  </font>
                  {GridUtil.renderInput({
                    inputName: 'a2Box', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR20"> Box</font>
                  {GridUtil.renderInput({
                    inputName: 'a2Qty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Qty</font>
                  <font className="mR10">+</font>
                  {GridUtil.renderInput({
                    inputName: 'a2Bonus', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Bonus</font>

                  <br />
                  <font className="wtitle">
                    <Trans>A1</Trans>
                  </font>
                  {GridUtil.renderInput({
                    inputName: 'a1Box', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR20"> Box</font>
                  {GridUtil.renderInput({
                    inputName: 'a1Qty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Qty</font>
                  <font className="mR10">+</font>
                  {GridUtil.renderInput({
                    inputName: 'a1Bonus', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Bonus</font>

                  <br />
                  <font className="wtitle">
                    <Trans>BM</Trans>
                  </font>
                  {GridUtil.renderInput({
                    inputName: 'bmBox', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR20"> Box</font>
                  {GridUtil.renderInput({
                    inputName: 'bmQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Qty</font>
                  <font className="mR10">+</font>
                  {GridUtil.renderInput({
                    inputName: 'bmBonus', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Bonus</font>

                  <br />
                  <font className="wtitle">
                    <Trans>B</Trans>
                  </font>
                  {GridUtil.renderInput({
                    inputName: 'bBox', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR20"> Box</font>
                  {GridUtil.renderInput({
                    inputName: 'bQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Qty</font>
                  <font className="mR10">+</font>
                  {GridUtil.renderInput({
                    inputName: 'bBonus', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Bonus</font>

                  <br />
                  <font className="wtitle">
                    <Trans>Polos</Trans>
                  </font>
                  {GridUtil.renderInput({
                    inputName: 'polosBox', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR20"> Box</font>
                  {GridUtil.renderInput({
                    inputName: 'polosQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Qty</font>
                  <font className="mR10">+</font>
                  {GridUtil.renderInput({
                    inputName: 'polosBonus', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Bonus</font>

                  <br />
                  <font className="wtitle">
                    <Trans>Sex</Trans>
                  </font>
                  {GridUtil.renderInput({
                    inputName: 'sexBox', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR20"> Box</font>
                  {GridUtil.renderInput({
                    inputName: 'sexQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Qty</font>
                  <font className="mR10">+</font>
                  {GridUtil.renderInput({
                    inputName: 'sexBonus', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Bonus</font>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="txt_center mT10 mB10">
          <button className="btn_gray" onClick={() => this.fnCancel()}>
            <Trans>Cancel</Trans>
          </button>
          <button className="btn_save" onClick={() => this.fnSave()}>
            <Trans>save</Trans>
          </button>
        </div>

        <div
          className="board_list ag-theme-balham"
          style={{ height: '300px', width: '100%' }}
        >
          {GridUtil.renderInput({
            inputName: 'searchFromWkDt', //*** */
            type: 'D', //*** */
            that: this, //*** */
            statsForm: this.state, //*** */
            setOnChange: this.setOnChange, //*** */
            width: 120, // px제외 : 없으면 100% */
            placeholder: 'Date',
          })}
          &nbsp; ~ &nbsp;
          {GridUtil.renderInput({
            inputName: 'searchToWkDt', //*** */
            type: 'D', //*** */
            that: this, //*** */
            statsForm: this.state, //*** */
            setOnChange: this.setOnChange, //*** */
            width: 120, // px제외 : 없으면 100% */
            placeholder: 'Date',
          })}
          <button className="btn_gray" onClick={() => this.fnSearch()}>
            <Trans>Search</Trans>
          </button>
          <AgGridReact
            onGridReady={(e) => this.onGridReady(e, 'g2')}
            columnDefs={this.state.columnDefs}
            rowData={
              this.state.hatchingDataList ? this.state.hatchingDataList : []
            }
            defaultColDef={this.state.defaultColDef}
            onCellClicked={(e) => this.onCellClicked(e)}
            rowSelection="single"
            onCellFocused={(e) => {
              e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
            }}
          ></AgGridReact>
        </div>

        {this.state.popSearchCustView ? (
          <PopSearchCust
            popSearchCustView={this.state.popSearchCustView}
            customerCode={this.state.customerCode}
            customerKind={this.state.customerKind}
            fnClosePop={this.fnClosePop}
            fnSelectCust={this.fnSelectCust}
          />
        ) : null}
      </Fragment>
    );
  }
}
export default withAlert()(BroilerDocSalesForm);
