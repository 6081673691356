import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import i18n from 'i18n';
import { Trans } from 'react-i18next';
import { Const, Server } from 'components/Properties';
import MenuUtil from './MenuUtil';

import { inject, observer } from 'mobx-react';
import { Menu, Icon, Tooltip, Popover } from 'antd';
import qs from 'qs';

import './Layout.module.css';

@inject((stores) => ({ mainStore: stores.MainStore }))
@observer
class BreedingMenu extends React.Component {
  constructor(props) {
    super(props);
    const { mainStore } = props;
    const { groupId, userLevel } = mainStore.getUser();
    this.state = {
      collapsed: false,
      target: '',
      openKeys: [],
      selectedKeys: [],
      groupId: groupId,
      userLevel: userLevel,
    };
  }

  UNSAFE_componentWillMount() {
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    // console.log('query : ', query);
    const selectedKey = MenuUtil.fnMenuSelectedKey();
    const openKey = MenuUtil.fnMenuOpenKey();
    this.setState({
      selectedKeys: [selectedKey],
      openKeys: [openKey],
    });
  }

  okPermission = (menuId) => {
    const { groupId, userLevel } = this.state;
    if (groupId === Const.ADMIN || groupId === Const.DEVEL) return true;
    switch (menuId) {
      case 'Dashboard':
      case 'Receive.Hatchery':
      case 'Receive.Supplier':
      case 'Chick In/Out.Flock':
      case 'Chick In/Out.Hen House':
      case 'Move.Move Out':
      case 'Move.Move In':
      case 'Growing Log':
      case 'Egg Production Log':
      case 'Deliver.Egg Sales':
      case 'Deliver.Egg Transfer':
      case 'Deliver.Egg Discard':
      case 'Deliver.PS Sales':
      case 'Deliver.PS Discard':
        if (groupId === Const.BREED) {
          if (userLevel === Const.FARM) return true;
        }
        return false;

      case 'Report.Grw Daily R':
      case 'Report.Grw Weekly R':
      case 'Report.Grw Flock/HH R':
      case 'Report.Grw Idx Anlys R':
      case 'Report.Grw Total R 1':
      case 'Report.Grw Total R 2':
      case 'Report.Lay Daily R':
      case 'Report.Lay Weekly R':
      case 'Report.Lay Term/HH R':
      case 'Report.Hat EP, Daily/HH R':
      case 'Report.Hat EP, Wkly/HH R':
      case 'Report.Hat EP, Term/HH R':
      case 'Report.Hat DOC, T/HH R':
      case 'Report.Hat Stk, T/HH R 1':
      case 'Report.Cls Flk Anlys(F/H)':
      case 'Report.Lay Idx Anlys R':
      case 'Report.Lay Total R 1':
      case 'Report.Lay Total R 2':
        if (groupId === Const.BREED || groupId === Const.CONSULT) {
          if (
            // PPL만 제외 됩니다.
            userLevel === Const.FARM ||
            userLevel === Const.COUNTRY ||
            userLevel === Const.DIVISION ||
            userLevel === Const.REGION ||
            userLevel === Const.SUB_REGION
          )
            return true;
        }
        return false;
    }
    return false;
  };

  render() {
    if (this.props.menu !== Const.MENU_BREED) return null;
    const { location } = this.props;
    let i = 0;
    return (
      <Menu
        onClick={(e) => MenuUtil.fnMenuClick(e, this.state, this.props)}
        theme="dark"
        // style={{ width: 256 }}
        defaultSelectedKeys={this.state.selectedKeys}
        defaultOpenKeys={this.state.openKeys}
        selectedKeys={[location.pathname]}
        mode="inline"
      >
        {this.okPermission('Dashboard') && (
          <Menu.Item key="/breeding/dashboard">
            <Icon type="border-outer" />
            <span>
              <Trans>Dashboard</Trans>
            </span>
          </Menu.Item>
        )}
        {/*
        <Menu.SubMenu
          key="_user"
          title={
            <span>
              <i className="mdi mdi-account-location"></i>
              <span>
                <Trans>Member</Trans>
              </span>
            </span>
          }
        >
          <Menu.Item key="/breeding/member/0">
            <Trans>ID</Trans>
          </Menu.Item>
          <Menu.Item key="/breeding/group/0">
            <Trans>Group</Trans>
          </Menu.Item>
        </Menu.SubMenu>
      */}

        {/* 공통으로 이동합니다. 2020.1.13 장태학 위원 요청사항
        <Menu.Item key="/breeding/standard/score">
          <Icon type="form" />
          <span>
            <Trans>Standard</Trans>
          </span>
        </Menu.Item>

        <Menu.Item key="/breeding/work/schedule">
          <Icon type="calendar" />
          <span>
            <Trans>Work Schedule</Trans>
          </span>
        </Menu.Item>

        <Menu.SubMenu
          key="_mat"
          title={
            <span>
              <Icon type="deployment-unit" />
              <span>
                <Trans>Materials</Trans>
              </span>
            </span>
          }
        >
          <Menu.Item key="/breeding/materials/feed">
            <span>
              <Trans>Feed</Trans>
            </span>
          </Menu.Item>
          <Menu.Item key="/breeding/materials/vaccine">
            <span>
              <Trans>Vaccine</Trans>
            </span>
          </Menu.Item>
          <Menu.Item key="/breeding/materials/medicine">
            <span>
              <Trans>Medicine</Trans>
            </span>
          </Menu.Item>
        </Menu.SubMenu>
      */}

        {(this.state.groupId === Const.ADMIN ||
          this.state.groupId === Const.DEVEL ||
          (this.state.groupId === Const.BREED &&
            this.state.userLevel === Const.FARM)) && (
          <Menu.SubMenu
            key="_rcv"
            title={
              <span>
                <Icon type="snippets" />
                <span>
                  <Trans>Receive</Trans>
                </span>
              </span>
            }
          >
            {this.okPermission('Receive.Hatchery') && (
              <Menu.Item key="/breeding/receive/hatchery">
                <span>
                  <Trans>Hatchery</Trans>
                </span>
              </Menu.Item>
            )}

            {/* PO Receive 버튼 메뉴 */}
            {this.okPermission('Receive.Supplier') && (
              <Menu.Item key="/breeding/receive/supplier">
                <span>
                  <Trans>Supplier</Trans>
                </span>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {/* 기존 Receive/Hen House 메뉴 */}
        {(this.state.groupId === Const.ADMIN ||
          this.state.groupId === Const.DEVEL ||
          (this.state.groupId === Const.BREED &&
            this.state.userLevel === Const.FARM)) && (
          <Menu.SubMenu
            key="_chick"
            title={
              <span>
                <Icon type="border-outer" />
                <span>
                  <Trans>Chick In/Out</Trans>
                </span>
              </span>
            }
          >
            {this.okPermission('Chick In/Out.Flock') && (
              <Menu.Item key="/breeding/chickin/flock">
                <span>
                  <Trans>Flock</Trans>
                </span>
              </Menu.Item>
            )}
            {this.okPermission('Chick In/Out.Hen House') && (
              <Menu.Item key="/breeding/chickin/henhouse">
                <span>
                  <Trans>Hen House</Trans>
                </span>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {(this.state.groupId === Const.ADMIN ||
          this.state.groupId === Const.DEVEL ||
          (this.state.groupId === Const.BREED &&
            this.state.userLevel === Const.FARM)) && (
          <Menu.SubMenu
            key="_move"
            title={
              <span>
                <Icon type="swap" />
                <span>
                  <Trans>Move</Trans>
                </span>
              </span>
            }
          >
            {this.okPermission('Move.Move Out') && (
              <Menu.Item key="/breeding/move/out">
                <span>
                  <Trans>Move Out</Trans>
                </span>
              </Menu.Item>
            )}
            {this.okPermission('Move.Move In') && (
              <Menu.Item key="/breeding/move/in">
                <span>
                  <Trans>Move In</Trans>
                </span>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {this.okPermission('Growing Log') && (
          <Menu.Item key="/breeding/growing/growlog">
            <Icon type="border-outer" />
            <span>
              <Trans>Growing Log</Trans>
            </span>
          </Menu.Item>
        )}

        {this.okPermission('Egg Production Log') && (
          <Menu.Item key="/breeding/egg/egglog">
            <Icon type="file-sync" />
            <span>
              <Trans>Egg Production Log</Trans>
            </span>
          </Menu.Item>
        )}

        {(this.state.groupId === Const.ADMIN ||
          this.state.groupId === Const.DEVEL ||
          (this.state.groupId === Const.BREED &&
            this.state.userLevel === Const.FARM)) && (
          <Menu.SubMenu
            key="_deli"
            title={
              <span>
                <Icon type="fullscreen" />
                <span>
                  <Trans>Deliver</Trans> {/* 기존 Release에서 명칭 변경 */}
                </span>
              </span>
            }
          >
            {/*
            EggXXX 메뉴 상단에 List 가 존재하므로 삭제 처리함 . 2020.02.21
          <Menu.Item key="/breeding/deliver/deliverEggList">
            <span>
              <Trans>Egg List</Trans>
            </span>
          </Menu.Item>
        */}
            {this.okPermission('Deliver.Egg Transfer') && (
              <Menu.Item key="/breeding/deliver/deliverEggTransfer">
                <span>
                  <Trans>Egg Transfer</Trans>
                </span>
              </Menu.Item>
            )}
            {this.okPermission('Deliver.Egg Sales') && (
              <Menu.Item key="/breeding/deliver/deliverEggSales">
                <span>
                  <Trans>Egg Sales</Trans>
                </span>
              </Menu.Item>
            )}
            {this.okPermission('Deliver.Egg Discard') && (
              <Menu.Item key="/breeding/deliver/deliverEggDiscard">
                <span>
                  <Trans>Egg Discard</Trans>
                </span>
              </Menu.Item>
            )}

            {/*
            PsXXX 메뉴 상단에 List 가 존재하므로 삭제 처리함 . 2020.02.21
          <Menu.Item key="/breeding/deliver/deliverPsList">
            <span>
              <Trans>PS List</Trans>
            </span>
          </Menu.Item>
          */}

            {this.okPermission('Deliver.PS Sales') && (
              <Menu.Item key="/breeding/deliver/deliverPsSales">
                <span>
                  <Trans>PS Sales</Trans>
                </span>
              </Menu.Item>
            )}

            {this.okPermission('Deliver.PS Discard') && (
              <Menu.Item key="/breeding/deliver/deliverPsDiscard">
                <span>
                  <Trans>PS Discard</Trans>
                </span>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        <Menu.SubMenu
          key="_rpt"
          title={
            <span>
              <Icon type="logout" />
              <span>
                <Trans>Report</Trans>
              </span>
            </span>
          }
        >
          {this.okPermission('Report.Grw Daily R') && (
            <Menu.Item key="/breeding/report/grwDailyR">
              <Popover content={i18n.t('Grw Daily R')}></Popover>
              <span>
                {++i}.<Trans>Grw Daily R</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Grw Weekly R') && (
            <Menu.Item key="/breeding/report/grwWeeklyR">
              <Popover content={i18n.t('Grw Weekly R')}></Popover>
              <span>
                {++i}.<Trans>Grw Weekly R</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Grw Flock/HH R') && (
            <Menu.Item key="/breeding/report/grwFlockHhR">
              <Popover content={i18n.t('Grw Flock/HH R')}></Popover>
              <span>
                {++i}.<Trans>Grw Flock/HH R</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Grw Idx Anlys R') && (
            <Menu.Item key="/breeding/report/grwIdxAnlysR">
              <Popover content={i18n.t('Grw Idx Anlys R')}></Popover>
              <span>
                {++i}.<Trans>Grw Idx Anlys R</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Grw Total R 1') && (
            <Menu.Item key="/breeding/report/grwTotalR1">
              <Popover content={i18n.t('Grw Total R 1')}></Popover>
              <span>
                {++i}.<Trans>Grw Total R 1</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Grw Total R 2') && (
            <Menu.Item key="/breeding/report/grwTotalR2">
              <Popover content={i18n.t('Grw Total R 2')}></Popover>
              <span>
                {++i}.<Trans>Grw Total R 2</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Lay Daily R') && (
            <Menu.Item key="/breeding/report/layDailyR">
              <Popover content={i18n.t('Lay Daily R')}></Popover>
              <span>
                {++i}.<Trans>Lay Daily R</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Lay Weekly R') && (
            <Menu.Item key="/breeding/report/layWeeklyR">
              <Popover content={i18n.t('Lay Weekly R')}></Popover>
              <span>
                {++i}.<Trans>Lay Weekly R</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Lay Term/HH R') && (
            <Menu.Item key="/breeding/report/layFlockHhR">
              <Popover content={i18n.t('Lay Flock/HH R')}></Popover>
              <span>
                {++i}.<Trans>Lay Flock/HH R</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Hat EP, Daily/HH R') && (
            <Menu.Item key="/breeding/report/hatEpDailyHhR">
              <Popover content={i18n.t('Hat EP, Daily/HH R')}></Popover>
              <span>
                {++i}.<Trans>Hat EP, Daily/HH R</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Hat EP, Wkly/HH R') && (
            <Menu.Item key="/breeding/report/hatEpWklyHhR">
              <Popover content={i18n.t('Hat EP, Wkly/HH R')}></Popover>
              <span>
                {++i}.<Trans>Hat EP, Wkly/HH R</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Hat EP, Term/HH R') && (
            <Menu.Item key="/breeding/report/hatEpTermHhR">
              <Popover content={i18n.t('Hat EP, Term/HH R')}></Popover>
              <span>
                {++i}.<Trans>Hat EP, Term/HH R</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Hat DOC, T/HH R') && (
            <Menu.Item key="/breeding/report/hatDocTermHhR">
              <Popover content={i18n.t('Hat DOC, T/HH R')}></Popover>
              <span>
                {++i}.<Trans>Hat DOC, T/HH R</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Hat Stk, T/HH R 1') && (
            <Menu.Item key="/breeding/report/hatStkTermHhR1">
              <Popover content={i18n.t('Hat Stk, T/HH R 1')}></Popover>
              <span>
                {++i}.<Trans>Hat Stk, T/HH R 1</Trans>
              </span>
            </Menu.Item>
          )}
          {/* 종계 부화 보고서(Stock 기준, 기간/HH별)2 는 개발 안함
            2020.04.06 by 정대용 위원
          <Menu.Item key="/breeding/report/hatStkTermHhR2">
            <span>
              <Trans>Hat Stk, T/HH R 2</Trans>
            </span>
          </Menu.Item>
          // 완료계군분석보고서 (F/H) 삭제 요청 by 김기철 수석 (2020.04.13)
          <Menu.Item key="/breeding/report/clsFlkAnlysDF">
            <Popover content={i18n.t('Cls Flk Anlys(D/F)')}></Popover>
            <span>
              <Trans>Cls Flk Anlys(D/F)</Trans>
            </span>
          </Menu.Item>
          */}
          {this.okPermission('Report.Cls Flk Anlys(F/H)') && (
            <Menu.Item key="/breeding/report/clsFlkAnlysFH">
              <Popover content={i18n.t('Cls Flk Anlys(F/H)')}></Popover>
              <span>
                {++i}.<Trans>Cls Flk Anlys(F/H)</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Lay Idx Anlys R') && (
            <Menu.Item key="/breeding/report/layIdxAnlysR">
              <Popover content={i18n.t('Lay Idx Anlys R')}></Popover>
              <span>
                {++i}.<Trans>Lay Idx Anlys R</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Lay Total R 1') && (
            <Menu.Item key="/breeding/report/layTotalR1">
              <Popover content={i18n.t('Lay Total R 1')}></Popover>
              <span>
                {++i}.<Trans>Lay Total R 1</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Lay Total R 2') && (
            <Menu.Item key="/breeding/report/layTotalR2">
              <Popover content={i18n.t('Lay Total R 2')}></Popover>
              <span>
                {++i}.<Trans>Lay Total R 2</Trans>
              </span>
            </Menu.Item>
          )}
        </Menu.SubMenu>
      </Menu>
    );
  }
}

export default withRouter(BreedingMenu);
