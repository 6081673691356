import React, { Fragment } from 'react';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { moment } from 'components';
import i18n from 'i18next';
import BreedRecvForm from './BreedRecvForm'; //입력Form
import MovedIn from './MovedIn'; //MovedIn List => 선택
import FlockList from './FlockList'; //입력 후 Flock(계군) 선택, Flock 추가
import BreedRecvList from './BreedRecvList';
import scrollToComponent from 'react-scroll-to-component';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';

/*
 ** breeding/receive/Flock
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class Supplier extends React.Component {
  state = {
    addType: '4', //1:FromHatchery  2:PO  3:MovedIn
    target: '',
    rowData: [],
    form: {},
    breedRecvForm: {},
    poReceiveData: [],
    movedInData: [],
    tpBreedRecvData: {},
    showSelectFlock: false,
    showFlockList: false,
    addTypeP: '',
    breedRecvListView: true,
    breedRecvFormView: false,
    fromHatcheryView: true,
    movedInView: false,
  };
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    // this.fnSetAddType('fromHatchery');
    //this.fnSetAddType('po');
    this.fnInit();
  }

  fnInit = () => {
    let that = this;

    let data = [];
    data.iuFlag = 'I';
    data.receiveDate = DateUtil.today();

    window.scrollTo(0, 0);
    this.setState(
      {
        breedRecvFormView: false,
        showFlockList: false,
        breedRecvForm: data,
        breedRecvListView: false,
      },
      function () {
        that.setState({ breedRecvFormView: true, breedRecvListView: true });
        //that.fnSearch();
      }
    );
  };

  fnSetAddType = (param) => {
    window.scrollTo(0, 0);
    this.setState({ addType: param, addTypeP: param }, function () {
      let data = {};
      data.iuFlag = 'I';
      data.receiveDate = DateUtil.today();
      this.setState(
        {
          fromHatcheryView: false,
          movedInView: false,
          addType: '4',
          breedRecvForm: data,
        },
        function () {}
      );
    });
  };

  fnSearch = () => {
    let that = this;
    let data = {};
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.customer = 'Y';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/selectDocTransfer.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          { breedRecvFormView: true, breedRecvList: response.data.rows3 },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  fnMovedIn = () => {
    let that = this;
    let data = {};
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    //data.receiveNo = '';
    that.setState(
      { fromHatcheryView: false, movedInView: true },
      function () {}
    );

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/selectMovedIn.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('fnMovedIn response', response);
        that.setState(
          { addType: '6', movedInData: response.data.rows1 },
          function () {
            console.log('fnMovedIn', that.state);
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };

  fnCancelFromHatchery = (data) => {
    let _fromHatcheryView = this.state.movedInView ? false : true;

    this.setState(
      {
        fromHatcheryView: _fromHatcheryView,
        addType: 'fromHatchery',
        breedRecvForm: null,
      },
      function () {}
    );
  };
  fnSaveFromHatchery = (data) => {
    let that = this;

    if (data.iuFlag === 'I') {
      this.setState(
        { showFlockList: true, tpBreedRecvData: data },
        function () {
          console.log('saveDate=========', data, that.state);
        }
      );
    } else {
      this.fnInit();
    }
  };
  //fnSaveFromHatchery
  fnSavePoReceive = (data) => {
    console.log('saveDate', data);

    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    //data.receiveNo = '';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/savePoReceive.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('savePoReceive', response.data);
        //저장후 다시 입력화면으로
        that.setState({ addType: '7', poReceiveData: null }, function () {
          console.log(that.state);
        });
      })
      .catch(function (error) {
        return [];
      });
  };
  // fnSelectFlock = (data, tpBreedRecvData) => {
  //   console.log('saveDate', data);

  //   let that = this;
  //   data.farmCode = SessionUtil.getFarmCode();
  //   data.dateFormat = SessionUtil.getDateFormat();
  //   data.docReceiveNo = tpBreedRecvData.docReceiveNo;
  //   data.flockRdate = tpBreedRecvData.receiveDate;
  //   data.flockInQtyF = tpBreedRecvData.recvQtyF;
  //   data.flockInQtyM = tpBreedRecvData.recvQtyM;

  //   const url = Server.getRestAPI();
  //   axios({
  //     method: 'post',
  //     url: url + '/breed/receive/saveBreedRecvFlock.do',
  //     data: qs.stringify(data),
  //     withCredentials: true,
  //     credentials: 'same-origin'
  //   })
  //     .then(function(response) {
  //       that.props.alert.show(i18n.t('Has been saved.'));

  //       that.setState({ showFlockList: false, addType: '1' }, function() {
  //         that.fnSetAddType(this.state.addTypeP);
  //       });
  //       //that.fnCloseFlockList();
  //     })
  //     .catch(function(error) {
  //       return [];
  //     });
  // };
  fnCloseFlockList = (e) => {
    this.setState({ showFlockList: false }, function () {
      this.fnInit();
    });
  };
  fnSelectBreedReceive = (row) => {
    window.scrollTo(0, 0);
    let that = this;
    console.log(row);
    row.iuFlag = 'U';
    this.setState({ breedRecvFormView: false }, function () {
      that.setState(
        { breedRecvFormView: true, breedRecvForm: row },
        function () {}
      );
    });
  };
  render() {
    return (
      <Fragment>
        <h2>
          <Trans>Receive From Supplier</Trans>
        </h2>
        <div className="con_box">
          {this.state.breedRecvFormView ? (
            <div
              style={{ height: 'auto', width: '100%' }}
              className="board_list"
            >
              <BreedRecvForm
                ref={(section) => {
                  this.breedRecvFormView = section;
                }}
                breedRecvFormView={this.state.breedRecvFormView}
                addType={this.state.addType}
                breedRecvForm={this.state.breedRecvForm}
                fnSelectHatchery={this.fnSelectHatchery}
                fnInit={this.fnInit}
                fnCancelFromHatchery={this.fnCancelFromHatchery}
                fnSaveFromHatchery={this.fnSaveFromHatchery}
              ></BreedRecvForm>
            </div>
          ) : null}
          {this.state.breedRecvListView ? (
            <div className="both">
              <BreedRecvList
                rowData={this.state.breedRecvList}
                fnSelectBreedReceive={this.fnSelectBreedReceive}
                flockYn="No" //Flock Mapping 된 Receive 건은 나오지 않습니다. Receive 된 건은 무조건 다 나오게 해주세요.
                customer="Y"
                select={true}
              />
            </div>
          ) : null}

          {this.state.showFlockList ? (
            <FlockList
              tpBreedRecvData={this.state.tpBreedRecvData}
              fnCloseFlockList={this.fnCloseFlockList}
              fnSelectFlock={this.fnSelectFlock}
              fnSearch={this.fnInit} //선택-저장
            />
          ) : null}
        </div>
      </Fragment>
    );
  }
}
export default withAlert()(Supplier);
