import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, FormUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import { Radio } from 'antd';
import produce from 'immer';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { withAlert } from 'react-alert';

import CountryCode from 'containers/pages/common/system/CountryCode';
import HatcheryType from 'containers/pages/common/system/HatcheryType';

import '../Common.module.css';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class HatcheryRegist extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      oldParams: {},
      params: {
        // hatcheryId: SessionUtil.getHatcheryId(), // 자신의 HatcheryId를 사용하지 말것!! (확인 필요)
        // farmCode: SessionUtil.getFarmCode(),     // CJ측에서 사용여부 결론이 나지 않음. by 장태학 2020.03.27
        dateFormat: SessionUtil.getDateFormat()
      }
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.setState({ oldParams: this.state.params });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.rowNode !== this.props.rowNode) {
      let { rowNode } = this.props;
      this.setState(
        produce(draft => {
          draft.params['farmCode'] = rowNode['farmCode'];
          draft.params['hatcheryId'] = rowNode['hatcheryId'];
          draft.params['hatcheryName'] = rowNode['hatcheryName'];
          draft.params['hatcheryType'] = rowNode['hatcheryType'];
          draft.params['capacity'] = rowNode['capacity'];
          draft.params['settingPerWeek'] = rowNode['settingPerWeek'];
          draft.params['tSettingPerWeek'] = rowNode['tSettingPerWeek'];
          draft.params['remarks'] = rowNode['remarks'];
          draft.params['deleted'] = rowNode['deleted'];
          draft.oldParams = draft.params;
        }),
        function() {
          // console.log('changed params : ', this.state.params);
        }
      );
    }
  }

  setCode = (name, value) => {
    this.setState(
      produce(draft => {
        draft.params[name] = value;
      }),
      function() {}
    );
  };

  onChangeParam = e => {
    const { name, value } = e.target;
    this.setState(
      produce(draft => {
        draft.params[name] = name === 'mesgId' ? value.toUpperCase() : value;
      })
    );
  };

  fnSaveRowData = (params, insertCallback, updateCallback) => {
    const that = this;
    const url = Server.getRestAPI() + '/basis/checkTcHatcheryInfo.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        const { data } = response;
        if (data.error) {
          return this.props.alert.error(i18n.t(data.error));
        }
        const callback = Number(data) > 0 ? updateCallback : insertCallback;
        callback(params);
      })
      .catch(function(error) {
        return [];
      });
  };

  checkMandatoryFields = params => {
    let result = false;
    console.log(params);
    if (_.isEmpty(params.hatcheryId)) {
      this.props.alert.error(i18n.t('hatcheryId is empty'));
      return result;
    }
    result = true;
    return result;
  };

  fnRefreshRowData = () => {
    this.fnClearInput();
    this.props.onRowDeselect();
    this.props.fnSetRegDate();
  };

  fnInsertRowData = params => {
    const that = this;
    let url = Server.getRestAPI() + '/basis/insertTcHatcheryInfo.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        const { data } = response;
        if (data.error) {
          return this.props.alert.error(i18n.t(data.error));
        }
        that.fnRefreshRowData();
      })
      .catch(function(error) {
        return [];
      });
  };

  fnUpdateRowData = params => {
    const that = this;
    const url = Server.getRestAPI() + '/basis/updateTcHatcheryInfo.do';
    // ---------------------------------------------------------
    // (중요) await와 then 은 함께 사용해서는 안됩니다.
    // ---------------------------------------------------------
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        const { data } = response;
        if (data.error) {
          return this.props.alert.error(i18n.t(data.error));
        }
        that.fnRefreshRowData();
      })
      .catch(function(error) {
        return [];
      });
  };

  onBtSave = () => {
    const { params } = this.state;
    if (!this.checkMandatoryFields(params)) return;
    this.fnSaveRowData(params, this.fnInsertRowData, this.fnUpdateRowData);
  };

  onBtClear = () => {
    this.props.onRowDeselect();
  };

  fnClearInput = () => {
    this.setState(
      produce(draft => {
        draft.params = {};
        draft.params['dateFormat'] = SessionUtil.getDateFormat();
      }),
      function() {}
    );
  };

  // 파라미터의 변경여부 확인
  fnNotChanged = () => {
    return _.isEqual(this.state.params, this.state.oldParams);
  };

  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <div className="con_box">
          <h3>
            <Trans>Hatchery Info</Trans>
          </h3>

          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Hatchery Id</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'hatcheryId',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      value: this.state.params.hatcheryId,
                      maxLength: 10,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Hatchery Name</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'hatcheryName',
                      that: this,
                      form: this.state.params,
                      value: this.state.params.hatcheryName,
                      maxLength: 40,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Capacity</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'capacity',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      maxLength: 22,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Hatchery Type</Trans>
                  </th>
                  <td>
                    <HatcheryType
                      name="hatcheryType"
                      setCode={this.setCode}
                      code={this.state.params.hatcheryType}
                    />
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Setting Per Week</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'settingPerWeek',
                      type: 'N',
                      that: this,
                      form: this.state.params,
                      minVal: 0,
                      maxLength: 10,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>T Setting Per Week</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'tSettingPerWeek',
                      type: 'N',
                      that: this,
                      form: this.state.params,
                      minVal: 0,
                      maxLength: 10,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'remarks',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      maxLength: 1000,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Deleted</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'deleted',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="txt_center mT40">
            <button className="btn_gray" onClick={this.onBtClear}>
              <Trans>Clear</Trans>
            </button>
            <button
              className={this.fnNotChanged() ? 'btn_gray' : 'btn_save'}
              onClick={this.onBtSave}
              disabled={this.fnNotChanged()}
            >
              <Trans>Save</Trans>
            </button>
          </div>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}

export default withAlert()(HatcheryRegist);
