import React, { Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import i18n from 'i18next';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class Dashboard extends React.PureComponent {
  state = {
    rowIndexG2: 0,
    taskList: [],
    growingForm: [],
    rowData1: [],
    rowData2: [],
    rowData3: [],
    rowData4: [],
    rowData21: [],
    rowData22: [],
    rowData31: [],
    rowData32: [],
    columnDefs1: [
      {
        headerName: 'Receive',
        field: 'receive',
        width: 100,
        type: 'numericColumn',
        valueFormatter: function (params) {
          return GridUtil.formatNumber(params);
        },
        //precision: 0,
        cellStyle: { textAlign: 'right' },
        suppressSizeToFit: false,
      },
      {
        headerName: 'MoveIn',
        field: 'moveIn',
        width: 100,
        type: 'numericColumn',
        valueFormatter: function (params) {
          return GridUtil.formatNumber(params);
        },
        //precision: 0,
        cellStyle: { textAlign: 'right' },
        suppressSizeToFit: false,
      },
      {
        headerName: 'Move Out',
        field: 'moveOut',
        width: 100,
        type: 'numericColumn',
        valueFormatter: function (params) {
          return GridUtil.formatNumber(params);
        },
        //precision: 0,
        cellStyle: { textAlign: 'right' },
        suppressSizeToFit: false,
      },
    ],
    columnDefs2: [
      {
        headerName: 'HE',
        field: 'he',
        width: 100,
        type: 'numericColumn',
        valueFormatter: function (params) {
          return GridUtil.formatNumber(params);
        },
        //precision: 0,
        cellStyle: { textAlign: 'right' },
        suppressSizeToFit: false,
      },
      {
        headerName: 'By Prod',
        field: 'byProd',
        width: 100,
        type: 'numericColumn',
        valueFormatter: function (params) {
          return GridUtil.formatNumber(params);
        },
        //precision: 0,
        cellStyle: { textAlign: 'right' },
        suppressSizeToFit: false,
      },
    ],
    columnDefs3: [
      {
        headerName: 'HE',
        field: 'he',
        width: 100,
        type: 'numericColumn',
        valueFormatter: function (params) {
          return GridUtil.formatNumber(params);
        },
        //precision: 0,
        cellStyle: { textAlign: 'right' },
        suppressSizeToFit: false,
      },
      {
        headerName: 'By Prod',
        field: 'byProd',
        width: 100,
        type: 'numericColumn',
        valueFormatter: function (params) {
          return GridUtil.formatNumber(params);
        },
        //precision: 0,
        cellStyle: { textAlign: 'right' },
        suppressSizeToFit: false,
      },
    ],
    columnDefs4: [
      {
        headerName: 'HE',
        field: 'he',
        width: 100,
        type: 'numericColumn',
        valueFormatter: function (params) {
          return GridUtil.formatNumber(params);
        },
        //precision: 0,
        cellStyle: { textAlign: 'right' },
        suppressSizeToFit: false,
      },
      {
        headerName: 'SE',
        field: 'se',
        width: 100,
        type: 'numericColumn',
        valueFormatter: function (params) {
          return GridUtil.formatNumber(params);
        },
        //precision: 0,
        cellStyle: { textAlign: 'right' },
        suppressSizeToFit: false,
      },
      {
        headerName: 'FE',
        field: 'fe',
        width: 100,
        type: 'numericColumn',
        valueFormatter: function (params) {
          return GridUtil.formatNumber(params);
        },
        //precision: 0,
        cellStyle: { textAlign: 'right' },
        suppressSizeToFit: false,
      },
      {
        headerName: 'DOC',
        field: 'doc',
        width: 100,
        type: 'numericColumn',
        valueFormatter: function (params) {
          return GridUtil.formatNumber(params);
        },
        //precision: 0,
        cellStyle: { textAlign: 'right' },
        suppressSizeToFit: false,
      },
    ],
    columnDefs21: [
      {
        headerName: 'Setter',
        field: 'setterId',
        width: 90,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: false,
      },
      {
        headerName: 'Date',
        field: 'settingDate',
        width: 120,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: true,
      },
      {
        headerName: 'Qty',
        field: 'settingQty',
        width: 90,
        type: 'numericColumn',
        valueFormatter: function (params) {
          return GridUtil.formatNumber(params);
        },
        //precision: 0,
        cellStyle: { textAlign: 'right' },
        suppressSizeToFit: false,
      },
    ],
    columnDefs22: [
      {
        headerName: 'Hatcher',
        field: 'hatcherId',
        width: 90,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: false,
      },
      {
        headerName: 'Date',
        field: 'settingDate',
        width: 120,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: true,
      },
      {
        headerName: 'Qty',
        field: 'settingQty',
        width: 90,
        type: 'numericColumn',
        valueFormatter: function (params) {
          return GridUtil.formatNumber(params);
        },
        //precision: 0,
        cellStyle: { textAlign: 'right' },
        suppressSizeToFit: false,
      },
    ],
    columnDefs31: [
      {
        headerName: 'PS',
        children: [
          {
            headerName: 'Female',
            field: 'psdocFQty',
            width: 90,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: false,
          },
          {
            headerName: 'Male',
            field: 'psdocMQty',
            width: 90,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: false,
          },
        ],
      },
      {
        headerName: 'DOC',
        children: [
          {
            headerName: 'SP',
            field: 'spQty',
            width: 90,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: false,
          },
          {
            headerName: 'A2',
            field: 'a2Qty',
            width: 90,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: false,
          },
          {
            headerName: 'A1',
            field: 'a1Qty',
            width: 90,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: false,
          },
          {
            headerName: 'B',
            field: 'bQty',
            width: 90,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: false,
          },
          {
            headerName: 'BM',
            field: 'bmQty',
            width: 90,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: false,
          },
          {
            headerName: 'Polos',
            field: 'polosQty',
            width: 90,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: false,
          },
        ],
      },
    ],
    defaultColDef: {
      editable: false,
      width: 100,
      filter: true,
      resizable: true,
      sortable: true,
      suppressSizeToFit: false,
    },
    domLayout: 'print', // to use disable vertical scrollbar
  };

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnSearch();
  }

  fnSearch = async () => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    //data.sql = 'selectBreedingDashboard';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/dashboard/selectDashboard.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log(response.data);
        that.setState(
          {
            rowData1: response.data.rows1,
            rowData2: response.data.rows2,
            rowData3: response.data.rows3,
            rowData4: response.data.rows4,
            rowData21: response.data.rows21,
            rowData22: response.data.rows22,
            rowData31: response.data.rows31,
            rowData32: response.data.rows32,
          },
          function () {
            // let rowIndexG2 = 0;
            // if (
            //   that.state.workscheduleRowData &&
            //   that.state.workscheduleRowData.length > 0
            // ) {
            //   rowIndexG2 = that.state.rowIndexG2 ? that.state.rowIndexG2 : 0;
            //   let selectedRows = that['g2'].getRowNode(rowIndexG2);
            //   selectedRows.setSelected(true);
            //   that.fnSearchDtl(selectedRows.data);
            // }
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  onRowClicked = (e) => {
    let data = e.data;
    this.setState({ growingForm: [] }, function () {
      this.fnSearchDtl(data);
    });
  };
  fnSearchDtl = async (row) => {
    let data = row ? row : {};
    data.hhId = row ? row.hhId : this.state.hhId;
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.countryCode = SessionUtil.getCountryCode();
    data.userId = SessionUtil.getUserId();
    data.sql = 'selectTcTaskDtl'; //controller 없음

    const url = Server.getRestAPI();
    data.sex = 'M';
    await axios({
      method: 'post',
      url: url + '/breed/dashboard/selectSql.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            growingForm: {
              ...that.state.growingForm,
              ['flockId']: data.flockId,
              ['ageDay']: data.ageDay,
              ['feedCode1']: response.data.rows1[0]['feedCode1'],
              ['feedCode2']: response.data.rows1[0]['feedCode2'],
              ['feedCode3']: response.data.rows1[0]['feedCode3'],
              ['vaccineCode1']: response.data.rows1[0]['vaccineCode1'],
              ['vaccineCode2']: response.data.rows1[0]['vaccineCode2'],
              ['vaccineCode3']: response.data.rows1[0]['vaccineCode3'],
              ['medicineCode1']: response.data.rows1[0]['medicineCode1'],
              ['medicineCode2']: response.data.rows1[0]['medicineCode2'],
              ['medicineCode3']: response.data.rows1[0]['medicineCode3'],

              ['feedAmt1']: response.data.rows1[0]['feedAmt1'],
              ['feedAmt2']: response.data.rows1[0]['feedAmt2'],
              ['feedAmt3']: response.data.rows1[0]['feedAmt3'],
              ['vacAmt1']: response.data.rows1[0]['vacAmt1'],
              ['vacAmt2']: response.data.rows1[0]['vacAmt2'],
              ['vacAmt3']: response.data.rows1[0]['vacAmt3'],
              ['medAmt1']: response.data.rows1[0]['medAmt1'],
              ['medAmt2']: response.data.rows1[0]['medAmt2'],
              ['medAmt3']: response.data.rows1[0]['medAmt3'],

              ['feedUnit1']: response.data.rows1[0]['feedUnit1'],
              ['feedUnit2']: response.data.rows1[0]['feedUnit2'],
              ['feedUnit3']: response.data.rows1[0]['feedUnit3'],
              ['vaccineUnit1']: response.data.rows1[0]['vaccineUnit1'],
              ['vaccineUnit2']: response.data.rows1[0]['vaccineUnit2'],
              ['vaccineUnit3']: response.data.rows1[0]['vaccineUnit3'],
              ['medicineUnit1']: response.data.rows1[0]['medicineUnit1'],
              ['medicineUnit2']: response.data.rows1[0]['medicineUnit2'],
              ['medicineUnit3']: response.data.rows1[0]['medicineUnit3'],
            },
          },
          function () {
            console.log('%%%%%%%', that.state);
          }
        );
      })
      .catch(function (error) {
        return [];
      });
    data.sex = 'F';
    await axios({
      method: 'post',
      url: url + '/breed/dashboard/selectSql.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            growingForm: {
              ...that.state.growingForm,
              ['flockId']: data.flockId,
              ['ageDay']: data.ageDay,
              ['feedCodeM1']: response.data.rows1[0]['feedCode1'],
              ['feedCodeM2']: response.data.rows1[0]['feedCode2'],
              ['feedCodeM3']: response.data.rows1[0]['feedCode3'],
              ['vaccineCodeM1']: response.data.rows1[0]['vaccineCode1'],
              ['vaccineCodeM2']: response.data.rows1[0]['vaccineCode2'],
              ['vaccineCodeM3']: response.data.rows1[0]['vaccineCode3'],
              ['medicineCodeM1']: response.data.rows1[0]['medicineCode1'],
              ['medicineCodeM2']: response.data.rows1[0]['medicineCode2'],
              ['medicineCodeM3']: response.data.rows1[0]['medicineCode3'],

              ['feedAmtM1']: response.data.rows1[0]['feedAmt1'],
              ['feedAmtM2']: response.data.rows1[0]['feedAmt2'],
              ['feedAmtM3']: response.data.rows1[0]['feedAmt3'],
              ['vacAmtM1']: response.data.rows1[0]['vacAmt1'],
              ['vacAmtM2']: response.data.rows1[0]['vacAmt2'],
              ['vacAmtM3']: response.data.rows1[0]['vacAmt3'],
              ['medAmtM1']: response.data.rows1[0]['medAmt1'],
              ['medAmtM2']: response.data.rows1[0]['medAmt2'],
              ['medAmtM3']: response.data.rows1[0]['medAmt3'],

              ['feedUnitM1']: response.data.rows1[0]['feedUnit1'],
              ['feedUnitM2']: response.data.rows1[0]['feedUnit2'],
              ['feedUnitM3']: response.data.rows1[0]['feedUnit3'],
              ['vaccineUnitM1']: response.data.rows1[0]['vaccineUnit1'],
              ['vaccineUnitM2']: response.data.rows1[0]['vaccineUnit2'],
              ['vaccineUnitM3']: response.data.rows1[0]['vaccineUnit3'],
              ['medicineUnitM1']: response.data.rows1[0]['medicineUnit1'],
              ['medicineUnitM2']: response.data.rows1[0]['medicineUnit2'],
              ['medicineUnitM3']: response.data.rows1[0]['medicineUnit3'],
            },
          },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  lastRowBorder = (params) => {
    if (params.node.rowIndex == params.api.rowModel.rowsToDisplay.length - 1) {
      return { 'border-bottom': 'none' };
    } else {
      return {};
    }
  };
  render() {
    const dataArr = [1, 2, 3];
    let data = this.state.growingForm;
    return (
      <Fragment>
        <h2>
          <Trans>Dashboard</Trans>
        </h2>
        <div className="con_box">
          <ul className="divide">
            <li className="divide_left mR20" style={{ width: '310px' }}>
              <h3>
                <Trans>Receive(Monthly)</Trans>
              </h3>
              <div className="ag-theme-balham">
                <AgGridReact
                  onGridReady={(e) => this.onGridReady(e, 'g1')}
                  columnDefs={this.state.columnDefs1}
                  rowData={this.state.rowData1}
                  getRowStyle={this.lastRowBorder}
                  onCellFocused={(e) => {
                    e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
                  }}
                  rowSelection={'single'}
                  domLayout={this.state.domLayout}
                ></AgGridReact>
              </div>
            </li>
            <li className="mR20" style={{ width: '210px', marginRight: '5px' }}>
              <h3>
                <Trans>Grade Out(Monthly)</Trans>
              </h3>
              <div className="ag-theme-balham">
                <AgGridReact
                  onGridReady={(e) => this.onGridReady(e, 'g2')}
                  columnDefs={this.state.columnDefs2}
                  rowData={this.state.rowData2}
                  defaultColDef={this.state.defaultColDef}
                  getRowStyle={this.lastRowBorder}
                  onCellFocused={(e) => {
                    e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
                  }}
                  rowSelection={'single'}
                  domLayout={this.state.domLayout}
                ></AgGridReact>
              </div>
            </li>
            <li
              className="divide_left mR20"
              style={{ width: '210px', marginRight: '5px' }}
            >
              <h3>
                <Trans>Cooling Room(Stock)</Trans>
              </h3>
              <div className="ag-theme-balham">
                <AgGridReact
                  onGridReady={(e) => this.onGridReady(e, 'g3')}
                  columnDefs={this.state.columnDefs3}
                  rowData={this.state.rowData3}
                  defaultColDef={this.state.defaultColDef}
                  getRowStyle={this.lastRowBorder}
                  onCellFocused={(e) => {
                    e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
                  }}
                  rowSelection={'single'}
                  domLayout={this.state.domLayout}
                ></AgGridReact>
              </div>
            </li>
            <li
              className="divide_right"
              style={{ width: '410px', marginRight: '5px' }}
            >
              <h3>
                <Trans>Cutting(Monthly)</Trans>
              </h3>
              <div className="ag-theme-balham">
                <AgGridReact
                  onGridReady={(e) => this.onGridReady(e, 'g4')}
                  columnDefs={this.state.columnDefs4}
                  rowData={this.state.rowData4}
                  defaultColDef={this.state.defaultColDef}
                  getRowStyle={this.lastRowBorder}
                  onCellFocused={(e) => {
                    e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
                  }}
                  rowSelection={'single'}
                  domLayout={this.state.domLayout}
                ></AgGridReact>
              </div>
            </li>
          </ul>

          <ul className="divide" style={{ marginTop: '20px' }}>
            <li
              className="divide_left mR10"
              style={{ width: 'calc(50% - 8px)', marginRight: '5px' }}
            >
              <h3>
                <Trans>Setter(Stock)</Trans>
              </h3>
              <div
                style={{ height: '200px', width: '100%', marginBottom: '20px' }}
                className="board_list ag-theme-balham"
              >
                <AgGridReact
                  onGridReady={(e) => this.onGridReady(e, 'g21')}
                  columnDefs={this.state.columnDefs21}
                  rowData={this.state.rowData21}
                  defaultColDef={this.state.defaultColDef}
                  onCellFocused={(e) => {
                    e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
                  }}
                  rowSelection={'single'}
                ></AgGridReact>
              </div>
            </li>
            <li
              className="divide_right"
              style={{ width: 'calc(50% - 8px)', marginRight: '5px' }}
            >
              <h3>
                <Trans>Hatcher(Stock)</Trans>
              </h3>
              <div
                style={{ height: '200px', width: '100%', marginBottom: '20px' }}
                className="board_list ag-theme-balham"
              >
                <AgGridReact
                  onGridReady={(e) => this.onGridReady(e, 'g22')}
                  columnDefs={this.state.columnDefs22}
                  rowData={this.state.rowData22}
                  defaultColDef={this.state.defaultColDef}
                  onCellFocused={(e) => {
                    e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
                  }}
                  rowSelection={'single'}
                ></AgGridReact>
              </div>
            </li>
          </ul>

          <ul className="divide">
            <li
              className="divide_left mR10"
              style={{ width: 'calc(50% - 8px)', marginRight: '5px' }}
            >
              <h3>
                <Trans>DOC(Monthly)</Trans>
              </h3>
              <div
                style={{ height: '200px', width: '100%', marginBottom: '20px' }}
                className="board_list ag-theme-balham"
              >
                <AgGridReact
                  onGridReady={(e) => this.onGridReady(e, 'g1')}
                  columnDefs={this.state.columnDefs31}
                  rowData={this.state.rowData31}
                  defaultColDef={this.state.defaultColDef}
                  onCellFocused={(e) => {
                    e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
                  }}
                  rowSelection={'single'}
                ></AgGridReact>
              </div>
            </li>
            <li
              className="divide_right"
              style={{ width: 'calc(50% - 8px)', marginRight: '5px' }}
            >
              <h3>
                <Trans>Salable Chick(Monthly)</Trans>
              </h3>
              <div
                style={{ height: '200px', width: '100%', marginBottom: '20px' }}
                className="board_list ag-theme-balham"
              >
                <AgGridReact
                  onGridReady={(e) => this.onGridReady(e, 'g2')}
                  columnDefs={this.state.columnDefs31}
                  rowData={this.state.rowData32}
                  defaultColDef={this.state.defaultColDef}
                  onCellFocused={(e) => {
                    e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
                  }}
                  rowSelection={'single'}
                ></AgGridReact>
              </div>
            </li>
          </ul>
        </div>
      </Fragment>
    );
  }
}
