import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import NumberFormat from 'react-number-format';
import i18n from 'i18next';
import scrollToComponent from 'react-scroll-to-component';

import { Radio } from 'antd';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import PopUpDiscard from './PopUpDiscard';
import { message } from 'antd';

import '../Hatchery.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class GradingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      gridHeight: '250px',
      rowSelection: 'single',
      columnDefs: [
        {
          headerName: i18n.t('Supplier'),
          field: 'supplier',
          suppressSizeToFit: false,
          cellStyle: { textAlign: 'left' },
          width: 120,
        },
        {
          headerName: i18n.t('Farm'),
          field: 'farmName',
          suppressSizeToFit: false,
          cellStyle: { textAlign: 'left' },
          width: 120,
        },
        {
          headerName: i18n.t('Flock'),
          field: 'flockId',
          suppressSizeToFit: false,
          cellStyle: { textAlign: 'left' },
          width: 120,
        },
        {
          headerName: i18n.t('Hen House'),
          field: 'hhId',
          suppressSizeToFit: false,
          cellStyle: { textAlign: 'left' },
          width: 120,
        },
        {
          headerName: i18n.t('Strain'),
          field: 'strain',
          suppressSizeToFit: true,
          cellStyle: { textAlign: 'center' },
          width: 140,
        },
        {
          headerName: i18n.t('Egg Receive No'),
          field: 'eggReceiveNo',
          suppressSizeToFit: false,
          cellStyle: { textAlign: 'right' },
          width: 120,
        },
        {
          headerName: i18n.t('Receive Date'),
          field: 'receiveDate',
          suppressSizeToFit: true,
          cellStyle: { textAlign: 'center' },
          width: 120,
        },
        {
          headerName: i18n.t('Grade Date'),
          field: 'gradingDate',
          suppressSizeToFit: true,
          cellStyle: { textAlign: 'center' },
          width: 120,
        },
        {
          headerName: i18n.t('Hatching Egg'),
          field: 'hatchingEggQty',
          suppressSizeToFit: false,
          width: 100,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: i18n.t('Setting Eggs'),
          field: 'settingQty',
          suppressSizeToFit: false,
          width: 100,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: i18n.t('Cutting'),
          field: 'cuttingQty',
          suppressSizeToFit: false,
          width: 100,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: i18n.t('Unset Eggs'),
          field: 'noneSettingQty',
          suppressSizeToFit: false,
          width: 130,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        // {
        //   headerName: '',
        //   field: 'discard',
        //   suppressSizeToFit: false,
        //   width: 100,
        //   editable: false,
        //   cellStyle: { textAlign: 'left' },
        //   cellRenderer: (params) => {
        //     return '<button class="btn_sel">' + i18n.t('Discard') + '</button>';
        //   },
        // },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    this.fnSearch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.regDate !== this.props.regDate) {
      this.fnSearch();
    }
  }

  fnSearch = () => {
    let that = this;
    let data = {};
    data.farmCode = SessionUtil.getFarmCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.dateFormat = SessionUtil.getDateFormat();
    data.countryCode = SessionUtil.getCountryCode();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/setting/getGradingInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ rowData: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };

  onCellClicked = (e) => {
    if (e.column.colId === 'discard') {
      this.setState(
        { popUpDiscardView: true, discardInfo: e.data },
        function () {
          scrollToComponent(this['popUpDiscardView'], {
            offset: 0,
            align: 'top',
            duration: 1000,
          });
        }
      );
    }
    if (e.column.colId != 'discard') {
      this.props.fnClickSetting(e.data);
    }
  };

  fnCancelFromPupUpDiscard = (e) => {
    this.setState({ popUpDiscardView: false }, function () {});
  };

  fnSaveFromPupUpDiscard = (e) => {
    this.setState({ popUpDiscardView: false }, function () {});
    this.fnSearch();
  };

  fnRefresh = (data) => {
    this.fnSearch();
  };

  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();

    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      params.api.setRowData(data.slice(0, 100));
    };
  };

  render() {
    // return null 은 아무것도 표시하지 않는다는 의미입니다.
    // if (!this.props.localeListView) return null;
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <div className="con_box">
          <h3>
            <Trans>Grade List</Trans>
          </h3>
          <div
            style={{
              height: this.state.gridHeight,
              width: '100%',
            }}
            className="board_list ag-theme-balham"
          >
            <AgGridReact
              onGridReady={(e) => this.onGridReady(e, 'gridApiSettingInfoS')}
              columnDefs={this.state.columnDefs}
              rowData={this.state.rowData}
              onCellClicked={(e) => this.onCellClicked(e)}
              rowSelection={this.state.rowSelection}
              paginationAutoPageSize={true}
              pagination={true}
            ></AgGridReact>
          </div>

          <PopUpDiscard
            ref={(section) => {
              this.popUpDiscardView = section;
            }}
            popUpDiscardView={this.state.popUpDiscardView}
            fnCancelFromPupUpDiscard={this.fnCancelFromPupUpDiscard}
            fnSaveFromPupUpDiscard={this.fnSaveFromPupUpDiscard}
            discardInfo={this.state.discardInfo}
          />
        </div>
        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default GradingList;
