import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, ObjectUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker, Checkbox } from 'antd';
import { inject, observer } from 'mobx-react';

import SearchInput from 'utils/SearchInput';

import '../Report.module.css';

/*
 ** [공통 개발시 유의 사항 입니다]
 ** 1. 변수명은 camelCase 로 작성하시길 바랍니다.
 ** 2. 다국어 표현은 <Trans>글자</Trans> 또는 i18n.t('글자')로 부탁 드립니다.
 */
{
  /* Division, Region, Plazma, Date 검색 */
}
@inject((stores) => ({ mainStore: stores.MainStore }))
@observer
export default class AFDatePickerSearch extends React.PureComponent {
  constructor(props) {
    super(props);
    const { mainStore } = props;
    const { isAreaManager } = mainStore;
    const { groupId, userLevel, division } = mainStore.getUser();
    this.state = {
      groupId: groupId,
      userLevel: userLevel,
      disables: {
        datePicker: true,
        submit: true,
      },
      params: {
        type: isAreaManager() ? 'area' : 'farm', // 매니저만이 지역 검색을 할 수 있습니다.
        dateFormat: SessionUtil.getDateFormat(),
        searchDate: DateUtil.toDateFormat(new Date()),
        // REGION 권한 이하로는 자신의 DIVISION 값으로 고정 됩니다.
        // division:
        //   userLevel === Const.COUNTRY || userLevel === Const.DIVISION
        //     ? undefined
        //     : division,
      },
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    // 사용자의 farmCode, division 값으로 미리 제한된 경우
    if (
      !_.isEmpty(this.state.params.farmCode) ||
      !_.isEmpty(this.state.params.division)
    ) {
      const flag = false;
      this.setState(
        produce((draft) => {
          draft.disables['datePicker'] = flag;
          // 지표를 사용하지 않는 경우에는 검색버튼을 비활성화 시켜 준다.
          if (!this.props.index0 && !this.props.index1 && !this.props.index2)
            draft.disables['submit'] = flag;
        }),
        function () {}
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // 지표 정보가 설정이 되면, 검색 버튼이 활성화 됩니다.
    if (prevState.params.index !== this.state.params.index) {
      const flag = _.isEmpty(this.state.params.index);
      this.setState(
        produce((draft) => {
          draft.disables['submit'] = flag;
        }),
        function () {}
      );
    }

    // farmCode 또는 Area 정보가 설정이 되면, 날짜와 검색 버튼이 활성화 됩니다.
    if (
      prevState.params.farmCode !== this.state.params.farmCode ||
      prevState.params.division !== this.state.params.division
    ) {
      const flag =
        _.isEmpty(this.state.params.farmCode) &&
        _.isEmpty(this.state.params.division);
      console.log(flag, this.state.params);
      this.setState(
        produce((draft) => {
          draft.disables['datePicker'] = flag;
          // 지표를 사용하지 않는 경우에는 검색버튼을 비활성화 시켜 준다.
          if (!this.props.index0 && !this.props.index1 && !this.props.index2)
            draft.disables['submit'] = flag;
        }),
        function () {}
      );
    }
  }

  onBtSearch = () => {
    const { params } = this.state;
    this.props.onSearch(ObjectUtil.getClearnedObject(params));
  };

  onChangeRadio = (e) => {
    const that = this;
    const { name, value } = e.target;
    this.setState(
      produce((draft) => {
        if (value === 'area') {
          draft.params['farmCode'] = undefined;
        }
        if (value === 'farm') {
          draft.params['division'] = undefined;
          draft.params['region'] = undefined;
        }
        draft.params['type'] = value;
      }),
      function () {
        const { params } = this.state;
        console.log(params);
      }
    );
  };

  onChangeDatePicker = (date, dateString) => {
    this.setState(
      produce((draft) => {
        draft.params['searchDate'] = dateString;
      })
    );
  };

  onChangeIndex = (value) => {
    this.setState(
      produce((draft) => {
        draft.params['index'] = value;
      }),
      function () {}
    );
  };

  setCode = (name, value) => {
    this.setState(
      produce((draft) => {
        draft.params[name] = value;
      }),
      function () {}
    );
  };

  render() {
    const { groupId, userLevel } = this.state;
    const { isAreaManager, isDivisionManager } = this.props.mainStore;

    // 육성기 종합 리포트 2 (GrwTotalR2)용 지표 입니다.
    //  종계 6번 보고서의 select box 의 index 항목 중 아래 항목을 삭제 해 주세요.
    // 쿼리는 향후 Index 추가요청을 대비해서 현재 상태 그대로 유지해 주세요.
    // by 김기철 수석 2020.10.06
    // Uniformity Male
    // Depletion Male
    // Depletion Female Cum
    // Depletion Male Cum
    // Feed Mmale
    // Sexing Error

    const grwTotalR2Opts = [
      { key: 'pop', value: 'pop', name: i18n.t('Population') },
      { key: 'bwF', value: 'bwF', name: i18n.t('Body Weight Female') },
      { key: 'bwM', value: 'bwM', name: i18n.t('Body Weight Male') },
      { key: 'unifF', value: 'unifF', name: i18n.t('Uniformity Female') },
      // { key: 'unifM', value: 'unifM', name: i18n.t('Uniformity Male'), },
      { key: 'depF', value: 'depF', name: i18n.t('Depletion Female') },
      // { key: 'depM', value: 'depM', name: i18n.t('Depletion Male'), },
      // { key: 'depFCum', value: 'depFCum', name: i18n.t('Depletion Female Cum'), },
      // { key: 'depMCum', value: 'depMCum', name: i18n.t('Depletion Male Cum'), },
      { key: 'feedF', value: 'feedF', name: i18n.t('Feed Female') },
      // { key: 'feedM', value: 'feedM', name: i18n.t('Feed Mmale'), },
      // { key: 'se', value: 'se', name: i18n.t('Sexing Error'), },
    ];

    // 5. 지표별 사육 보고서 (ProjIdxAnlysR)용 지표
    const projIdxAnlysROpts = [
      { key: 'population', value: 'population', name: i18n.t('Population') },
      {
        key: 'depletion',
        value: 'depletion',
        name: i18n.t('Depletion (Cum.Dep)'),
      },
      {
        key: 'pointFeed',
        value: 'pointFeed',
        name: i18n.t('Point Feed (Cum.Intake)'),
      },
      { key: 'bodyWeight', value: 'bodyWeight', name: i18n.t('Body Weight') },
      { key: 'fcr', value: 'fcr', name: i18n.t('FCR') },
      { key: 'ip', value: 'ip', name: i18n.t('IP') },
    ];

    // 종계(산란) 종합 리포트 2 (LayTotalR2)용 지표 입니다.
    const layTotalR2Opts = [
      {
        key: 'pop',
        value: 'pop',
        name: i18n.t('PS Pop.'),
      },
      {
        key: 'depCum',
        value: 'depCum',
        name: i18n.t('Dep. Cum.'),
      },
      {
        key: 'ep',
        value: 'ep',
        name: i18n.t('EP'),
      },
      {
        key: 'heCum',
        value: 'heCum',
        name: i18n.t('HE Cum.'),
      },
      {
        key: 'bwF',
        value: 'bwF',
        name: i18n.t('BW Female'),
      },
      {
        key: 'feedF',
        value: 'feedF',
        name: i18n.t('Feed Female'),
      },
      {
        key: 'eggWeight',
        value: 'eggWeight',
        name: i18n.t('Egg Weight'),
      },
    ];

    const indexOptions0 = grwTotalR2Opts.map((row, i) => {
      return (
        <Select.Option key={row.key} value={row.value}>
          {row.name}
        </Select.Option>
      );
    });

    const indexOptions1 = projIdxAnlysROpts.map((row, i) => {
      return (
        <Select.Option key={row.key} value={row.value}>
          {row.name}
        </Select.Option>
      );
    });

    const indexOptions2 = layTotalR2Opts.map((row, i) => {
      return (
        <Select.Option key={row.key} value={row.value}>
          {row.name}
        </Select.Option>
      );
    });

    return (
      <React.Fragment>
        <div className="f_left langsel">
          {/* Division,Region,Plazma 선택 */}
          {isAreaManager() && (
            <Radio.Group
              name="type" // area, farm
              value={this.state.params.type}
              onChange={this.onChangeRadio}
            >
              <Radio value={'area'}>
                <Trans>Area</Trans>
              </Radio>
              <Radio value={'farm'}>
                <Trans>Farm</Trans>
              </Radio>
            </Radio.Group>
          )}

          {this.state.params.type === 'area' && (
            <React.Fragment>
              {isDivisionManager() && (
                <SearchInput
                  placeholder={i18n.t('divisionCode')}
                  style={{
                    width: 200,
                  }}
                  inputParam="division" // SelectInput의 분류
                  inputName="division" // member variable of this.state.params
                  inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
                  // farmClass="A"
                  defaultValue={this.state.params.division}
                  getValue={(value) => {
                    this.setCode('division', value);
                    this.setCode('region', undefined);
                  }} //***** */
                  that={this} //***** */
                />
              )}
              <SearchInput
                placeholder="regionCode"
                style={{
                  width: 200,
                  marginRight: 20,
                }}
                inputParam="region" // SelectInput의 분류
                inputName="region" // member variable of this.state.params
                inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
                // farmClass="A"
                pValue0={this.state.params.division} // division이 먼저 선택되어 있어야 활성화
                defaultValue={this.state.params.region}
                getValue={(value) => {
                  this.setCode('region', value);
                }} //***** */
                that={this} //***** */
              />
            </React.Fragment>
          )}

          {this.state.params.type === 'farm' && (
            <SearchInput
              placeholder={i18n.t('farmCode')}
              style={{
                width: 200,
                marginRight: 20,
                // textTransform: 'uppercase'
              }}
              inputParam="farmCode" // SelectInput의 분류
              inputName="farmCode" // member variable of this.state.params
              inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
              // farmClass="A"
              defaultValue={this.state.params.farmCode}
              getValue={(value) => this.setCode('farmCode', value)} //***** */
              that={this} //***** */
            />
          )}

          <DatePicker
            style={{
              marginRight: 20,
            }}
            defaultValue={DateUtil.now(this.state.params.searchDate)}
            format={this.state.params.dateFormat}
            disabled={this.state.disables.datePicker}
            onChange={this.onChangeDatePicker}
          />

          {this.props.index0 && (
            <Select
              style={{
                width: 200,
                marginRight: 20,
              }}
              placeholder={i18n.t('Index')}
              onChange={this.onChangeIndex}
              disabled={this.state.disables.datePicker} //
            >
              {indexOptions0}
            </Select>
          )}

          {this.props.index1 && (
            <Select
              style={{
                width: 200,
                marginRight: 20,
              }}
              placeholder={i18n.t('Index')}
              onChange={this.onChangeIndex}
              disabled={this.state.disables.datePicker} //
            >
              {indexOptions1}
            </Select>
          )}

          {this.props.index2 && (
            <Select
              style={{
                width: 200,
                marginRight: 20,
              }}
              placeholder={i18n.t('Index')}
              onChange={this.onChangeIndex}
              disabled={this.state.disables.datePicker} //
            >
              {indexOptions2}
            </Select>
          )}

          <button
            className={this.state.disables.submit ? 'btn_gray' : 'btn_green'}
            disabled={this.state.disables.submit}
            onClick={this.onBtSearch}
          >
            <Trans>Search</Trans>
          </button>
        </div>
      </React.Fragment>
    );
  }
}
