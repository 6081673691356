import React, { Fragment } from 'react';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import FlockList from './FlockList';
import HenHouseMoveList from './HenHouseMoveList';
import ChickinHhList from './ChickinHhList';
//import PopFlock from './ZPopFlock';
import PopMoveIn from './PopMoveIn'; //계군선택 - 계사선택 Move click
import PopChickinHh from './PopChickinHh'; //계군선택 - 계사선택 In button click
import MovingLogList from './MovingLogList'; //
import '../Breeding.module.css';
/*
 ** breeding/receive/HenHouse
 ** 계사(HenHouse-HH)입식
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class HenHouse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      prop: {},
      rowData: [],
      flockList: [],
      hhChickinList: [],
      movingLogList: [],
      hhMoveView: true,
      hhInView: false, //HenHouseFlockList 에서 Chick in HH click
      popMoveIn: false,
      popEmpty: false,
      popIn: false,
      popFlock: false,
      hhFlockId: '', //Flock click
      flockListFlockId: '', //
      popInRow: {},
      popMoveRow: {},
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnSearch();
  }
  fnSearch = () => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.flockStatus = 'Hen House';
    data.flockOrder = 'ORDER BY FLOCK_CDATE';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/selectHhChickinList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            hhChickinList: response.data.rows1,
            flockList: response.data.rows2,
            movingLogList: response.data.rows3,
            flockListFlockId: '',
            hhInView: false,
            hhMoveView: true,
          },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });
    // axios({
    //   method: 'post',
    //   url: url + '/breed/receive/selectFlockList.do',
    //   params: data
    // })
    //   .then(function(response) {
    //     _flockList = response.data.rows1;
    //   })
    //   .catch(function(error) {
    //     return [];
    //   });

    // axios({
    //   method: 'post',
    //   url: url + '/breed/receive/selectMovingLog.do',
    //   params: data
    // })
    //   .then(function(response) {
    //     _movingLogList = response.data.rows1;
    //   })
    //   .catch(function(error) {
    //     return [];
    //   });

    //   that.setState({hhChickinList: _hhChickinList,
    //     flockList: _flockList,
    //     movingLogList: _movingLogList},
    //     function() {}
    //   );
  };
  //Flock List click
  //넣을수 없는 계사 = 다른 계군이 들어있는 계사는 회색
  //넣을수 있는 계사 = 같은 계군이 들어있는 계사, 비어있는 계사 는 In
  //<Trans>Chick in HH</Trans>
  //hhFlockList - Chick in HH click
  fnClickFlock = (row) => {
    this.setState(
      {
        hhMoveView: false,
        hhInView: true, //HenHouseFlockList 에서 Chick in HH click
        flockListFlockId: row.flockId,
        hhFlockRow: row,
        hhId: '', //Flcok clcik
      },
      function () {}
    );
  };

  //HenHouseFlockList 에서 Chick in HH click
  //hhFlockList - Cancel click
  fnClickFlockCancel = (row) => {
    this.setState(
      {
        flockList: [],
        flockListFlockId: '',
        hhMoveView: true,
        hhInView: false,
        hhFlockId: '',
      },
      function () {
        this.fnSearch();
      }
    );
  };
  fnClickCancel = (row) => {
    this.setState({ hhFlockId: '' }, function () {});
  };
  fnClosePopFlock = (e, data) => {
    this.setState({ [data]: false }, function () {});
  };
  fnClosePop = (data) => {
    this.setState({ [data]: false }, function () {});
  };
  fnClickMoveIn = (row, hhId, hhFlockRow) => {
    //if (hhId === '') {
    //  //Flock click - In click
    //  this.setState({ popIn: true, popInRow: row }, function() {});
    //} else {
    //Move click - In click
    row.fromHhId = hhFlockRow.hhId;
    row.fromFlockId = hhFlockRow.flockId;
    row.fromChickinDate = hhFlockRow.chickinDate;
    row.fromChickinQtyF = hhFlockRow.chickinQtyF;
    row.fromChickinQtyM = hhFlockRow.chickinQtyM;
    row.fromFlockInQtyF = hhFlockRow.flockInQtyF;
    row.fromFlockInQtyM = hhFlockRow.flockInQtyM;

    row.fromCurrentQtyF = hhFlockRow.currentQtyF;
    row.fromCurrentQtyM = hhFlockRow.currentQtyM;
    row.fromCapacity = hhFlockRow.capacity;
    //console.log('fnClickMoveIn', row);
    this.setState({ popMoveIn: true, popMoveRow: row }, function () {});
    //}
  };
  //HenHouseFlockList 에서 Chick in HH click
  //click In
  fnClickIn = (row, hhFlockRow) => {
    row.fromHhId = hhFlockRow.hhId;
    row.fromFlockId = hhFlockRow.flockId;
    row.fromChickinDate = hhFlockRow.chickinDate;
    row.fromChickinQtyF = hhFlockRow.chickinQtyF;
    row.fromChickinQtyM = hhFlockRow.chickinQtyM;
    row.fromFlockInQtyF = hhFlockRow.flockInQtyF;
    row.fromFlockInQtyM = hhFlockRow.flockInQtyM;

    row.fromCapacity = hhFlockRow.capacity;

    this.setState({ popIn: true, popInRow: row }, function () {
      //console.log('fnClickIn', this.state);
    });
    //}
  };
  //넣을수 없는 계사 = 다른 계군이 들어있는 계사는 회색
  //넣을수 있는 계사 = 같은 계군이 들어있는 계사, 비어있는 계사 는 In
  fnClickMove = (row) => {
    //console.log('fnClickMove', row);
    this.setState(
      {
        hhId: row.hhId, //Hen House Move click
        hhFlockId: row.flockId,
        hhFlockRow: row,
      },
      function () {}
    );
  };
  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Chick In to Hen House</Trans>
        </h2>

        <div className="con_box">
          <ul className="divide">
            {/* Flock */}
            <FlockList
              flockList={this.state.flockList}
              flockButtonLabel={this.state.flockButtonLabel}
              flockMsg={this.state.flockMsg}
              fnClickFlock={this.fnClickFlock} //Chick in HH - click
              fnClickFlockCancel={this.fnClickFlockCancel} //Cancel - click
              flockListFlockId={this.state.flockListFlockId}
              fnSearch={this.fnSearch}
            />
            {/* Hen House - move 버튼 포함 */}
            {this.state.hhMoveView ? (
              <HenHouseMoveList
                hhMoveView={this.state.hhMoveView}
                hhChickinList={this.state.hhChickinList} //***** */
                hhFlockId={this.state.hhFlockId}
                hhId={this.state.hhId}
                hhFlockRow={this.state.hhFlockRow}
                fnClickMove={this.fnClickMove}
                fnClickMoveIn={this.fnClickMoveIn}
                fnClickCancel={this.fnClickCancel}
              />
            ) : null}
            {/* HenHouseFlockList 에서 Chick in HH click */}
            {this.state.hhInView ? (
              <ChickinHhList
                hhInView={this.state.hhInView}
                hhChickinList={this.state.hhChickinList}
                flockListFlockId={this.state.flockListFlockId}
                hhId={this.state.hhId}
                hhFlockRow={this.state.hhFlockRow}
                //fnClickMove={this.fnClickMove}
                fnClickIn={this.fnClickIn} //click In
                fnClickCancel={this.fnClickCancel}
              />
            ) : null}
          </ul>

          {this.state.movingLogList && this.state.movingLogList.length > 0 ? (
            <MovingLogList movingLogList={this.state.movingLogList} />
          ) : null}
        </div>

        {/* <PopFlock
          popFlock={this.state.popFlock}
          fnClosePopFlock={this.fnClosePopFlock}
        /> */}
        {/* //HenHouseFlockList 에서 Chick in HH click
        //click In */}
        {this.state.popIn ? (
          <PopChickinHh
            popIn={this.state.popIn}
            popInRow={this.state.popInRow}
            fnSearch={this.fnSearch}
            fnClosePop={this.fnClosePop}
          />
        ) : null}
        {this.state.popMoveIn ? (
          <PopMoveIn
            popMoveIn={this.state.popMoveIn}
            popMoveRow={this.state.popMoveRow}
            fnClickFlockCancel={this.fnClickFlockCancel}
            fnClosePop={this.fnClosePop}
          />
        ) : null}

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default HenHouse;
