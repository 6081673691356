import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import AFCheckboxSearch from '../utils/AFCheckboxSearch';
import AFDatePickerSearch from '../utils/AFDatePickerSearch';
import AFIndexSearch from '../utils/AFIndexSearch';
import ProjectDateRangeSearch from '../utils/ProjectDateRangeSearch';
import ReportUtil from '../utils/ReportUtil';

import '../Report.module.css';

// 1. 일일 사육 현황 (Project)
export default class DailyProjectR extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      excelName: i18n.t('Broiler Daily Breeding Report (Project)'),
      rowData: [],
      params: {},
      ColDefs: [
        {
          headerName: i18n.t('Date'),
          field: 'date',
          pinned: 'left',
        },
        {
          headerName: i18n.t('Chick In'),
          field: 'chickIn',
        },
        {
          headerName: i18n.t('Death'),
          field: 'death',
        },
        {
          headerName: i18n.t('Cull'),
          field: 'cull',
        },
        {
          headerName: i18n.t('Dep %'),
          field: 'depPercent',
        },
        {
          headerName: i18n.t('Culling Qty'),
          field: 'cullingQty',
        },
        {
          headerName: i18n.t('Culling Kg'),
          field: 'cullingKg',
        },
        {
          headerName: i18n.t('Harvest Qty'),
          field: 'harvestQty',
        },
        {
          headerName: i18n.t('Harvest Kg'),
          field: 'harvestKg',
        },
        {
          headerName: i18n.t('Population'),
          field: 'population',
        },
        {
          headerName: i18n.t('Feed'),
          field: 'feed',
        },
        {
          headerName: i18n.t('BW'),
          field: 'bw',
        },
      ],
      gridHeight: '640px',
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        width: 100,
        minWidth: 100,
        cellClass: 'text-center',
        suppressSizeToFit: false,
        valueFormatter: function (params) {
          return GridUtil.formatReport(params);
        },
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagenation: false,
      paginationPageSize: 10,
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    if (Const.RPT_TEST) {
      const params = ReportUtil.getDefaultParams(this.props);
      console.log('params:', params);
      this.fnGetRowData(params);
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  // -----------------------------------------------------------
  // 참고용 : ES/ETL 서버 주소(API)를 지정해야 합니다.
  // -----------------------------------------------------------
  fnGetRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/stat/broiler/dailyProjectR.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data.rows1 });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtExport = () => {
    const fileName =
      i18n.t(this.state.excelName) + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t(this.state.excelName);
    const params = {
      allColumns: false,
      columnGroups: true,
      fileName: fileName,
      sheetName: sheetName,
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onSearch = (params) => {
    console.log(params);
    this.fnGetRowData(params);
  };

  render() {
    return (
      <React.Fragment>
        <h3>
          {/* 1. 일일 사육 현황 (Project) */}
          <Trans>Title Daily Project R</Trans>
        </h3>

        <div className="con_box">
          <ProjectDateRangeSearch
            onSearch={this.onSearch}
          ></ProjectDateRangeSearch>

          <div className="f_right mB20">
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
          </div>
          <div className="board_list_s">
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  excelStyles={this.state.excelStyles}
                  onGridReady={this.onGridReady}
                  rowData={this.state.rowData}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// let i = 0;
// const sampleData = [
//   { date: '2019-08-27', chickIn: i++, death: i++, cull: i++, depPercent: i++, sales: i++, salesKg: i++, population: i++, feed: i++, bw: i++, },
//   { date: '2019-08-28', chickIn: i++, death: i++, cull: i++, depPercent: i++, sales: i++, salesKg: i++, population: i++, feed: i++, bw: i++, },
//   { date: '2019-08-29', chickIn: i++, death: i++, cull: i++, depPercent: i++, sales: i++, salesKg: i++, population: i++, feed: i++, bw: i++, },
// ];
