import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import { Radio } from 'antd';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { message } from 'antd';

import '../Hatchery.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class FarmTransferListMast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transferQty: '',
      rowData: [],
      rowSelection: 'single',
      gridHeight: '250px',
      columnDefs: [
        {
          headerName: i18n.t('Transfer No'),
          field: 'transferNo',
          editable: false,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
          width: 180,
        },
        {
          headerName: i18n.t('Transfer Date'),
          field: 'transferDate',
          editable: false,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
          width: 180,
        },
        {
          headerName: i18n.t('Farm'),
          field: 'farmName',
          editable: false,
          cellStyle: { textAlign: 'left' },
          width: 100,
          suppressSizeToFit: false,
        },
        {
          headerName: i18n.t('Transfer Qty'),
          field: 'transferQty',
          editable: false,
          type: 'numericColumn',
          width: 100,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          suppressSizeToFit: false,
        },
      ],
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnSearch();
    this.fnTest();
  }

  fnTest = () => {
    const that = this;
    const url = Server.getStatAPI('broiler', 'broiler-project-daily');

    console.log('url', url);

    return false;
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.regDate !== this.props.regDate) {
      this.fnSearch();
    }
  }

  fnSearch = () => {
    let that = this;
    let data = {};
    data.farmCode = SessionUtil.getFarmCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.dateFormat = SessionUtil.getDateFormat();
    data.countryCode = SessionUtil.getCountryCode();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/receive/selectTransferNo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ rowData: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };

  onCellClicked = (e) => {
    this.props.fnSelectTransferNo(e.data);
  };

  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();

    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      params.api.setRowData(data.slice(0, 100));
    };
  };

  render() {
    // return null 은 아무것도 표시하지 않는다는 의미입니다.
    // if (!this.props.localeListView) return null;
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <div className="con_box">
          <h3>
            <Trans>Transfer List</Trans>
          </h3>
          <div className="board_list2">
            <div
              id="myGrid"
              style={{
                height: this.state.gridHeight,
                width: '100%',
              }}
              className="ag-theme-balham"
            >
              <AgGridReact
                onGridReady={(e) => this.onGridReady(e, 'gridApiSettingInfoS')}
                columnDefs={this.state.columnDefs}
                rowData={this.state.rowData}
                onCellClicked={(e) => this.onCellClicked(e)}
                rowSelection={this.state.rowSelection}
                paginationAutoPageSize={true}
                pagination={true}
              ></AgGridReact>
            </div>
          </div>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
