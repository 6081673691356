import React, { Fragment, Component } from 'react';
import { SessionUtil } from 'utils';
import axios from 'axios';
import qs from 'qs';
import { moment } from 'components';
import { Server, Const } from 'components/Properties';
import MobileHeader from 'components/mobile/header/MobileHeader';
import MobileFooter from 'components/mobile/footer/MobileFooter';
import ProjectItemList from 'components/mobile/home/ProjectItemList';
import ModalContainer from 'containers/pages/mobile/common/ModalContainer';
import { observer, inject } from 'mobx-react';
import i18n from 'i18next';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 ** 4. 파일명과 클래스명을 동일하게 해 주세요.
 */
@inject((stores) => ({
  mStore: stores.MobileStore,
  mProjectStore: stores.MprojectStore,
  mRcvStore: stores.MRcvStore,
}))
@observer
class Home extends Component {
  componentDidMount() {
    moment.locale(SessionUtil.getLang());

    const { mStore, mProjectStore, mRcvStore } = this.props;
    mStore.setTitle(i18n.t('Home'));
    mStore.setTitleIcon('mdi mdi-home-outline');
    mStore.setUserId(sessionStorage.getItem('userId'));
    mStore.setFarmCode(sessionStorage.getItem('farmCode'));

    // 초기화 필요한 store 상태값 초기화
    mRcvStore.initStore();
    mProjectStore.initStore();
    mStore.initStore();

    if (mProjectStore.projectList.length == 0) {
      this.fnSelectProjectList();
    }
  }

  //footer navigation 메뉴 선택
  fnSelecetMenu = (name, icon, munuName) => {
    const { mStore, mProjectStore } = this.props;

    if (mProjectStore.selectedProject == '') {
      this.fnShowAlert(i18n.t('Please Select a Project'));
    } else {
      mStore.setTitle(name);
      mStore.setTitleIcon(icon);
      if (munuName == 'rcv') {
        // Receive
        mStore.setMenuName('rcv');
        if (
          mProjectStore.selectedProject.broReceiveNo == undefined ||
          mProjectStore.selectedProject.broReceiveNo == 0
        ) {
          this.fnShowSelectModal();
        } else {
          if (
            //입식정보가 있는경우
            mProjectStore.selectedProject.hatcheryId == undefined &&
            mProjectStore.selectedProject.customerCode != undefined
          ) {
            //Supplier  입고
            mStore.setIuFlagUpdate();
            this.props.history.push('/mobile/broiler/rcv/supplier');
          } else if (
            mProjectStore.selectedProject.hatcheryId != undefined &&
            mProjectStore.selectedProject.customerCode == undefined
          ) {
            //Hatchery 입고
            mStore.setIuFlagUpdate();
            this.props.history.push('/mobile/broiler/rcv/hatchery');
          }
        }
      } else if (munuName == 'del') {
        //Deliver
        mStore.setMenuName('del');
        if (
          mProjectStore.selectedProject.broReceiveNo == undefined ||
          mProjectStore.selectedProject.broReceiveNo == 0
        ) {
          this.fnShowAlert(i18n.t('Please use it after chick in.'));
        } else {
          this.props.history.push('/mobile/broiler/deliver');
        }
      } else if (munuName == 'grw') {
        //Growing Log
        mStore.setMenuName('grw');
        if (
          mProjectStore.selectedProject.broReceiveNo == undefined ||
          mProjectStore.selectedProject.broReceiveNo == 0
        ) {
          this.fnShowAlert(i18n.t('Please use it after chick in.'));
        } else {
          this.props.history.push('/mobile/broiler/grow');
        }
      } else if (munuName == 'mtr') {
        //Material
        mStore.setMenuName('mtr');
        this.props.history.push('/mobile/broiler/material');
      }
    }
  };

  //프로젝트 선택
  fnSetSelectedProject = (value) => {
    const { mProjectStore } = this.props;
    mProjectStore.setSelectedProject(value);
  };

  //입고 방식 선택 모달 열기
  fnShowSelectModal = () => {
    const { mStore } = this.props;
    mStore.setModalOpen('RCV', null, null);
  };

  //입고 방식 선택 모달 닫기
  fnCloseSelectModal = () => {
    const { mStore } = this.props;
    mStore.setModalClose();
  };

  //입고 방식 선택 모달 선택 함수
  fnSelectRcvType = (value) => {
    if (value == 'h') {
      // h ==> 해처리인 경우
      this.fnCloseSelectModal();
      this.props.history.push('/mobile/broiler/rcv/selectHatchery');
    } else {
      this.fnCloseSelectModal();
      this.props.history.push('/mobile/broiler/rcv/supplier');
    }
  };

  //공통 알림 모달 열기
  fnShowAlert = (msg) => {
    const { mStore } = this.props;
    mStore.setModalOpen('A', null, msg);
  };

  //프로젝트 리스트 찾기
  fnSelectProjectList = () => {
    const that = this;
    const { mStore, mProjectStore } = this.props;
    let url = '';
    var params = {};
    if (mStore.pplYn == true) {
      //PPL일 경우
      url = Server.getRestAPI() + '/mobile/broiler/mHomeProjectListForPPL.do';
      params = {
        ppl: sessionStorage.getItem('pplCode'),
        pageNo: mProjectStore.pageCount,
        pageSize: 30,
        total: 0,
        rowCnt: 0,
      };
    } else {
      //농장일 경우
      url = Server.getRestAPI() + '/mobile/broiler/mHomeProjectList.do';
      params = {
        farmCode: SessionUtil.getFarmCode(),
        pageNo: mProjectStore.pageCount,
        pageSize: 30,
        total: 0,
        rowCnt: 0,
      };
    }

    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then((response) => {
        console.log(response.data);
        const { data } = response;
        if (data) {
          //if (mProjectStore.projectList.length == 0) {
          mProjectStore.setProjectList(data);
          // } else if (mProjectStore.projectList.length > 0) {
          //   var preList = mProjectStore.projectList;
          //   var dataList = data.rows1;
          //   var newList = [...preList, dataList]
          //   mProjectStore.setProjectList(newList);
          // }
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  //로그아웃 요청
  fnMobileLogout = () => {
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/logout.do',
    })
      .then((response) => {
        const lang = SessionUtil.getLang();
        const systemCountryCode =
          localStorage.getItem('systemCountryCode') || '1';
        sessionStorage.clear();
        localStorage.clear();
        localStorage.setItem('lang', lang);
        localStorage.setItem('systemCountryCode', systemCountryCode);
        location.href = Const.MOBILE_LOGOUT_PAGE;
      })
      .catch(function (error) {
        return [];
      });
  };

  //로그아웃 모달 오픈
  fnLogoutModalOpen = (value, msg) => {
    const { mStore } = this.props;
    mStore.setModalOpen('C', value, msg);
  };

  fnLogOut = () => {
    const { mStore } = this.props;
    this.fnMobileLogout();
    mStore.setModalClose();
  };

  //프로젝트 닫기버튼
  fnOpenConfirmModal = (value, msg) => {
    console.log(value);
    const { mStore } = this.props;
    mStore.setModalOpen('C', value, msg);
  };

  //프로젝트 닫기 실행
  fnModalDelete = () => {
    const { mStore } = this.props;
    mStore.setModalClose();
    this.fnCloseProject();
  };

  fnCloseProject = () => {
    const { mProjectStore } = this.props;
    let params = {};
    console.log(mProjectStore.selectedProject);
    params.farmCode = SessionUtil.getFarmCode();
    params.dateFormat = SessionUtil.getDateFormat();
    params.updateId = SessionUtil.getUserId();
    params.projCode = mProjectStore.selectedProject.projCode;
    params.workDate = mProjectStore.selectedProject.workDate;

    const url = Server.getRestAPI();

    axios({
      method: 'post',
      url: url + '/mobile/broiler/mUpdateProjectStatus.do',
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then((response) => {
        this.fnSelectProjectList();
      })
      .catch(function (error) {
        return [];
      });
  };

  render() {
    const { mStore, mProjectStore } = this.props;

    return (
      <Fragment>
        <MobileHeader
          mStore={mStore}
          fnMobileLogout={this.fnMobileLogout}
          fnLogoutModalOpen={this.fnLogoutModalOpen}
          fnLogOut={this.fnLogOut}
        />
        <div className="mobile_content">
          <ProjectItemList
            projectList={mProjectStore.projectList}
            fnSetSelectedProject={this.fnSetSelectedProject}
            fnOpenConfirmModal={this.fnOpenConfirmModal}
          />
        </div>

        <MobileFooter fnSelecetMenu={this.fnSelecetMenu} />

        <ModalContainer
          confirm={this.fnLogOut}
          delete={this.fnModalDelete}
          fnSelectRcvType={this.fnSelectRcvType}
        />
      </Fragment>
    );
  }
}

export default Home;
