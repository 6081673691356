import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { Popconfirm, Switch, message, DatePicker } from 'antd';
import '../Breeding.module.css';

/*
 ** Breeding/Glowing Log/FlockList
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class FlockList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      prop: {},
      rowData: [],
      flockListView: true,
      visible: false,
      selectFlockId: ''
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    //this.fnSearch();
  }

  fnClickFlockClose = row => {
    let vi = row.flockId + 'View';

    this.setState({ [vi]: true, selectFlockId: row.flockId, rowData: row });

    //this.props.fnClickFlockClose(row);
  };
  //
  confirm = () => {
    //this.setState({ visible: false });

    let data = this.state.rowData;
    let that = this;
    let vi = data.flockId + 'View';

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.closedDate = data.closedDate ? data.closedDate : DateUtil.today();
    data.iuFlag = 'U';
    data.flockStatus = 'C'; //C:closed - S:closing

    console.log('saveFlockClose', data);
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/growing/saveFlockClose.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        console.log('saveChickMoveHh', response.data);

        //that.props.fnSearch();
        that.setState({ [vi]: false, selectFlockId: '', rowData: [] });
        that.props.fnSearch();
      })
      .catch(function(error) {
        return [];
      });
  };
  cancel = () => {
    let vi = this.state.selectFlockId + 'View';

    this.setState({ [vi]: false, selectFlockId: '' });
  };

  render() {
    let flockList = [];
    if (this.props.flockList && this.props.flockList.length > 0) {
      flockList = this.props.flockList.map((row, i) => {
        //console.log('flockList', row);
        return (
          <div key={i} className="full">
            <dl className="reinfo">
              <dt className="color1">{row.flockId}</dt>
              <dd className="f_left mL10">{row.strainName}</dd>
              <dd className="f_right mR10">{row.flockCdate}</dd>
              <dd>&nbsp; </dd>
              <dd className="txt_left mL10">
                <Trans>Age</Trans>
                {' : '}
                {row.days ? row.days.toLocaleString() : '0'}
                <Trans> Days</Trans>
                {' / '}
                {row.weeks ? row.weeks.toLocaleString() : '0'}
                <Trans> Weeks</Trans>
              </dd>

              <dd className="txt_left mL10">
                <Trans>Birds</Trans>
                {' ('}
                <Trans>Total</Trans> / <Trans>Chick-In</Trans> ){' '}
              </dd>
              <dd className="txt_left mL10">
                <Trans>F</Trans> :{' '}
                {row.flockInQtyF ? row.flockInQtyF.toLocaleString() : '0'}
                {' / '}
                {row.chickinQtyF ? row.chickinQtyF.toLocaleString() : '0'}
              </dd>
              <dd className="txt_left mL10">
                <Trans>M</Trans> :{' '}
                {row.flockInQtyM ? row.flockInQtyM.toLocaleString() : '0'}
                {' / '}
                {row.chickinQtyM ? row.chickinQtyM.toLocaleString() : '0'}
              </dd>
            </dl>
          </div>
        );
      });
    }
    return (
      <Fragment>
        {/* Flock */}
        <li className="divide_left">
          <div className="dititle">
            <i className="mdi mdi-cube-outline"></i> <Trans>Flock</Trans>
          </div>
          {flockList}
          {/* left list */}
        </li>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default FlockList;
