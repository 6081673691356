import React, { Fragment } from 'react';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { Row, Tooltip, Popconfirm } from 'antd';
import PopStatus from '../growing/PopStatus';
import '../Breeding.module.css';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class FlockList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      prop: {},
      rowData: [],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    //this.fnSearch();
  }

  fnClickFlock = (row) => {
    this.props.fnClickFlock(row);
  };
  fnClickFlockCancel = (row) => {
    this.props.fnClickFlockCancel(row);
  };
  fnClickChangeStatus = (row) => {
    //row.layingSdate = row.layingSdate ? row.layingSdate : DateUtil.today();
    this.setState({ popStatusView: true, rowData: row });
  };
  fnClickClosing = (row) => {
    //C:closed - S:closing
    let vi = row.flockId + 'View';
    this.setState({ [vi]: true, selectFlockId: row.flockId, rowData: row });
  };
  //Closing save
  confirm = () => {
    //this.setState({ visible: false });
    let data = this.state.rowData;
    let that = this;
    let vi = data.flockId + 'View';
    let hhList = [];

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.closingSdate = data.closingSdate
      ? data.closingSdate
      : DateUtil.today();
    data.iuFlag = 'U';
    data.flockStatus = 'S'; //C:closed - S:closing
    //C:closed 일 경우 아래 작업
    // that.props.hhChickinList.map(function(rowNode, index) {
    //   console.log('node ', rowNode);
    //   if (data.flockId === rowNode.flockId) {
    //     let flockInQtyF = rowNode.flockInQtyF ? Number(rowNode.flockInQtyF) : 0;
    //     let flockInQtyM = rowNode.flockInQtyM ? Number(rowNode.flockInQtyM) : 0;
    //     let dataDef = {};
    //     dataDef.farmCode = rowNode.farmCode;
    //     dataDef.flockId = rowNode.flockId;
    //     dataDef.hhId = rowNode.hhId;
    //     dataDef.chickoutQtyF = flockInQtyF;
    //     dataDef.chickoutQtyM = flockInQtyM;

    //     hhList.push(dataDef);
    //   }
    // });
    // data.hhList = hhList;
    data.hhListLength = 0;

    console.log('saveFlockClose', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/growing/saveFlockClose.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('saveChickMoveHh', response.data);

        //that.props.fnSearch();
        that.setState({ [vi]: false, selectFlockId: '', rowData: [] });
        that.props.fnSearch();
      })
      .catch(function (error) {
        return [];
      });
  };
  cancel = () => {
    let vi = this.state.selectFlockId + 'View';
    this.setState({ [vi]: false, selectFlockId: '' });
  };
  flockButton = (row) => {
    if (this.props.flockListFlockId === row.flockId) {
      return (
        <dd>
          <Trans>Select HH</Trans>
          <button
            className="btn_gray2"
            onClick={(e) => this.fnClickFlockCancel(row)}
          >
            <Trans>Cancel</Trans>
          </button>
        </dd>
      );
    } else if (this.props.flockListFlockId) {
      return null;
    } else if (
      Number(row.flockInQtyF) + Number(row.flockInQtyM) >
      Number(row.chickinQtyF) + Number(row.chickinQtyM)
    ) {
      if (!Const.TEST_MODE) {
        // 테스트 및 검수를 위해 입력날짜 제한을 해제 합니다.
        if (row.days >= 7) return null; // 7일 이상 경과한 경우 Chick in HH 비활성화
      }
      //2020-04-03 수정
      return (
        <dd className="txt_center">
          <button
            className="btn_orange"
            onClick={(e) => this.fnClickFlock(row)}
          >
            <Trans>Chick in HH</Trans>
          </button>
        </dd>
      );
    } else return null;
  };
  statusButton = (row) => {
    if (row.breedStatus === 'Growing' && Number(row.weeks) > 3) {
      let vi = row.flockId + 'View';
      return (
        <span
          className="btn_green btn_flock"
          onClick={(e) => this.fnClickChangeStatus(row)}
        >
          <Trans> Laying</Trans>
        </span>
      );
    } else return null;
  };
  closingButton = (row) => {
    if (row.breedStatus === 'Laying') {
      let vi = row.flockId + 'View';
      return (
        <dd className="txt_center">
          <Popconfirm
            title={i18n.t('Are you sure you want to close?')}
            visible={this.state[vi]}
            onVisibleChange={this.handleVisibleChange}
            onConfirm={this.confirm}
            onCancel={this.cancel}
            okText="OK"
            cancelText="Cancel"
          >
            <span
              className="btn_green btn_flock"
              onClick={(e) => this.fnClickClosing(row)}
            >
              <Trans>Closing</Trans>
            </span>
          </Popconfirm>
        </dd>
      );
    } else return null;
  };
  handleCancel = (e) => {
    this.setState({
      popStatusView: false,
    });
  };
  render() {
    let flockList = [];
    if (this.props.flockList && this.props.flockList.length > 0) {
      flockList = this.props.flockList.map((row, i) => {
        return (
          <div key={i} className="full">
            <dl className="reinfo">
              <dt className="color1">{row.flockId}</dt>
              <dd className="f_left mL10">{row.strainName}</dd>
              <dd className="f_right mR10">{row.chickinDate}</dd>
              <dd>&nbsp; </dd>
              <dd className="txt_left mL10">
                <Trans>Status : {row.breedStatus}</Trans>
                {this.statusButton(row)}
              </dd>
              <dd className="txt_left mL10">
                <Trans>Birds</Trans>
                {' ('}
                <Trans>Current</Trans>/<Trans>Chick-In</Trans>/
                <Trans>Total</Trans>
                {')'}
              </dd>
              <dd className="txt_left mL10">
                <Trans>F</Trans> :{' '}
                <Tooltip title="CurrentQty">
                  {row.currentQtyF ? row.currentQtyF.toLocaleString() : '0'}
                </Tooltip>
                {' / '}
                <Tooltip title="ChickinQty">
                  {row.chickinQtyF ? row.chickinQtyF.toLocaleString() : '0'}
                </Tooltip>
                {' / '}
                <Tooltip title="FlockInQty">
                  {row.flockInQtyF ? row.flockInQtyF.toLocaleString() : '0'}
                </Tooltip>
                <br></br>
                <Trans>M</Trans> :{' '}
                <Tooltip title="CurrentQty">
                  {row.currentQtyM ? row.currentQtyM.toLocaleString() : '0'}
                </Tooltip>
                {' / '}
                <Tooltip title="ChickinQty">
                  {row.chickinQtyM ? row.chickinQtyM.toLocaleString() : '0'}
                </Tooltip>
                {' / '}
                <Tooltip title="FlockInQty">
                  {row.flockInQtyM ? row.flockInQtyM.toLocaleString() : '0'}
                </Tooltip>
              </dd>

              {this.flockButton(row)}
              {/* {this.closingButton(row)} */}
            </dl>
          </div>
        );
      });
    }
    return (
      <Fragment>
        {/* Flock */}
        <li className="divide_left">
          <div className="dititle">
            <i className="mdi mdi-cube-outline"></i> <Trans>Flock</Trans>
          </div>
          {flockList}
          {/* left list */}
        </li>
        {this.state.popStatusView ? (
          <PopStatus
            popStatusView={this.state.popStatusView}
            popMoveRow={this.state.rowData}
            fnSearch={this.props.fnSearch}
            handleCancel={this.handleCancel}
          />
        ) : null}
      </Fragment>
    );
  }
}
export default FlockList;
