import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Const, Server } from 'components/Properties';
import { Popconfirm, Switch, message, DatePicker, Select } from 'antd';
import NumberFormat from 'react-number-format';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import NumericEditor from '../numericEditor_copy.jsx';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';

/*
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class EggDiscardForm extends React.Component {
  constructor(props) {
    super(props);
    this.deliverDates = [];
    this.minDeliverDate = undefined;
    this.state = {
      form: [],
      eggGoodRowData: [],
      eggGoodColumnDefs: [],
      culleggGoodRowData: [],
      culleggGoodColumnDefs: [],
      defaultColDef: {
        editable: false,
        width: 100,
      },
      frameworkComponents: {
        numericEditor: NumericEditor,
      },
      editType: 'fullRow',
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    //필수 항목 setFocus를 위한 정의
    this.transferdate = React.createRef();
    this.cullingQty = React.createRef();
    this.closingQty = React.createRef();
  }
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps.discardForm !== this.props.discardForm) {
  //     this.setState({ form: nextProps.discardForm }, function() {});
  //   }
  // }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.discardForm !== this.props.discardForm) {
      this.fnSearch();
      this.setState({ form: nextProps.discardForm }, function () {});
      return true;
    } else if (nextProps.discardFormView !== this.props.discardFormView) {
      return true;
    } else if (nextState !== this.state) {
      return true;
    } else return false;
  }

  fnSearch = () => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    //data.receiveNo = '';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/selectEggReleaseSales.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        let eggGoodColumnDefs = [
          {
            headerName: 'Flock',
            field: 'flockId',
            width: 120,
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: 'Strain',
            field: 'strainName',
            width: 150,
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: 'Hen House',
            field: 'hhId',
            width: 100,
            cellStyle: { textAlign: 'center' },
          },
        ];
        if (response.data.hhList) {
          let hhList = response.data.hhList.split(',');

          that.setState({
            form: that.props.discardForm,
          });

          hhList.forEach((row) => {
            that.deliverDates.push(row);
            let field = row; //.split(' ');
            let headDef = {};
            let childrenDef1 = {};
            let childrenDef2 = {};
            let childrenDef = [];

            childrenDef1.headerName = 'Qty';
            childrenDef1.field = field;
            childrenDef1.type = 'numericColumn';
            childrenDef1.width = 100;
            childrenDef1.valueFormatter = function (params) {
              return GridUtil.formatNumber(params);
            };
            childrenDef1.cellStyle = function (params) {
              return GridUtil.getEditCell('D', 100);
            };

            childrenDef1.valueGetter = function (params) {
              return Number(params.data[field] ? params.data[field] : 0);
            };
            childrenDef2.headerName = 'Discard';
            childrenDef2.field = field + 'SaleQty';
            childrenDef2.type = 'numericColumn';
            childrenDef2.width = 100;
            childrenDef2.editable = function (params) {
              if (!params.data[field]) return false;
              else return true;
            };
            childrenDef2.cellEditor = 'numericEditor';
            childrenDef2.cellEditorParams = function () {
              return {
                handleChange: (e) => that.fnGridHandleChange(e, 'EggGoodList'),
              };
            };
            childrenDef2.valueFormatter = function (params) {
              return GridUtil.formatNumber(params);
            };
            childrenDef2.cellStyle = function (params) {
              if (!params.data[field]) return GridUtil.getEditCell('P', 100);
              else return GridUtil.getEditCell('E', 100);
            };
            childrenDef2.valueGetter = function (params) {
              return Number(
                params.data[field + 'SaleQty']
                  ? params.data[field + 'SaleQty']
                  : 0
              );
            };

            headDef.headerName = field.replace(/'/gi, '');
            headDef.children = [childrenDef1, childrenDef2];
            eggGoodColumnDefs.push(headDef);
          });
        }

        let _culleggGoodRowData = response.data.rows2
          ? response.data.rows2
          : [];
        _culleggGoodRowData.forEach((row) => {
          // console.log(row.epDate);
          that.deliverDates.push(row.epDate);
        });
        that.minDeliverDate = _.min(that.deliverDates);
        console.log('minDeliverDate => ' + that.minDeliverDate);

        that.setState(
          {
            eggGoodColumnDefs: eggGoodColumnDefs,
            eggGoodRowData: response.data.rows1,
            culleggGoodRowData: response.data.rows2,
            hhList: response.data.hhList,
            culleggGoodColumnDefs: that.fnCulleggGoodColumnDefs(),
          },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  //Discard 저장
  fnSave = () => {
    let that = this;
    let data = this.state.form;
    let eggGoodList = [];
    let cullEggGoodList = [];
    let hhList = that.state.hhList ? that.state.hhList.split(',') : [];

    this.gridApi.stopEditing();
    this.gridApiCullegg.stopEditing();

    if (data.iuFlag === 'I' && !data.discardDate) {
      data.discardDate = DateUtil.today();
    }
    if (!data.discardDate) {
      that.props.alert.show(i18n.t('Please enter Discard Date.'));

      this.discardDate.current.focus();
      return false;
    }

    let goodEggSaleQty = 0;
    let crackEggSaleQty = 0;
    let dirtyEggSaleQty = 0;
    let jumboEggSaleQty = 0;
    let smallEggSaleQty = 0;
    let thinEggSaleQty = 0;
    let missShapeEggSaleQty = 0;

    this.gridApi.forEachNode(function (rowNode, index) {
      hhList.forEach((row) => {
        let field = row; //.split(' ');
        let col = field + 'SaleQty';
        if (Number(rowNode.data[col]) > 0) {
          let dataDef = {};
          dataDef.flockId = rowNode.data.flockId;
          dataDef.strainCode = rowNode.data.strainCode;
          dataDef.hhId = rowNode.data.hhId;
          dataDef.epDate = field.replace(/'/gi, '');
          dataDef.goodEggQty = Number(rowNode.data[col]);
          goodEggSaleQty += Number(rowNode.data[col]);
          //, GOOD_EGG_WEIGHT
          //console.log('node ' + headDef + ' is in the grid');
          eggGoodList.push(dataDef);
        }
      });
    });
    this.gridApiCullegg.forEachNode(function (rowNode, index) {
      console.log('rowNode', rowNode);
      let _crackEggSaleQty = rowNode.data.crackEggSaleQty
        ? Number(rowNode.data.crackEggSaleQty)
        : 0;
      let _dirtyEggSaleQty = rowNode.data.dirtyEggSaleQty
        ? Number(rowNode.data.dirtyEggSaleQty)
        : 0;
      let _jumboEggSaleQty = rowNode.data.jumboEggSaleQty
        ? Number(rowNode.data.jumboEggSaleQty)
        : 0;
      let _smallEggSaleQty = rowNode.data.smallEggSaleQty
        ? Number(rowNode.data.smallEggSaleQty)
        : 0;
      let _thinEggSaleQty = rowNode.data.thinEggSaleQty
        ? Number(rowNode.data.thinEggSaleQty)
        : 0;
      let _missShapeEggSaleQty = rowNode.data.missShapeEggSaleQty
        ? Number(rowNode.data.missShapeEggSaleQty)
        : 0;

      if (
        _crackEggSaleQty > 0 ||
        _dirtyEggSaleQty > 0 ||
        _jumboEggSaleQty > 0 ||
        _smallEggSaleQty > 0 ||
        _thinEggSaleQty > 0 ||
        _missShapeEggSaleQty > 0
      ) {
        console.log('node ', rowNode);
        let dataDef = {};
        dataDef.epDate = rowNode.data.epDate;
        dataDef.crackEggQty = _crackEggSaleQty;
        dataDef.dirtyEggQty = _dirtyEggSaleQty;
        dataDef.jumboEggQty = _jumboEggSaleQty;
        dataDef.smallEggQty = _smallEggSaleQty;
        dataDef.thinEggQty = _thinEggSaleQty;
        dataDef.missShapeEggQty = _missShapeEggSaleQty;

        crackEggSaleQty += _crackEggSaleQty;
        dirtyEggSaleQty += _dirtyEggSaleQty;
        jumboEggSaleQty += _jumboEggSaleQty;
        smallEggSaleQty += _smallEggSaleQty;
        thinEggSaleQty += _thinEggSaleQty;
        missShapeEggSaleQty += _missShapeEggSaleQty;

        cullEggGoodList.push(dataDef);
      }
    });

    data.eggGoodList = eggGoodList;
    data.eggGoodLength = eggGoodList.length;
    data.cullEggGoodList = cullEggGoodList;
    data.cullEggGoodLength = cullEggGoodList.length;

    for (let i = 0; i < data.eggGoodLength; i++) {
      console.log(data.eggGoodList);
      if (data.eggGoodList[i].epDate > data.discardDate) {
        that.props.alert.show(i18n.t('Please Check EP Date.'));
        return false;
      }
    }

    for (let i = 0; i < data.cullEggGoodLength; i++) {
      console.log(data.cullEggGoodList);
      if (data.cullEggGoodList[i].epDate > data.discardDate) {
        that.props.alert.show(i18n.t('Please Check EP Date.'));
        return false;
      }
    }

    if (
      goodEggSaleQty > 0 ||
      crackEggSaleQty > 0 ||
      dirtyEggSaleQty > 0 ||
      jumboEggSaleQty > 0 ||
      smallEggSaleQty > 0 ||
      thinEggSaleQty > 0 ||
      missShapeEggSaleQty > 0
    ) {
    } else {
      that.props.alert.show(i18n.t('No data entered.'));
      //discardDate;
      return false;
    }

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    that.setState(
      {
        form: {
          ...this.state.form,
          goodEggQty: goodEggSaleQty,
          crackEggQty: crackEggSaleQty,
          dirtyEggQty: dirtyEggSaleQty,
          jumboEggQty: jumboEggSaleQty,
          smallEggQty: smallEggSaleQty,
          thinEggQty: thinEggSaleQty,
          missShapeEggQty: missShapeEggSaleQty,
        },
      },
      function () {
        console.log('save', data);
        const url = Server.getRestAPI();
        axios({
          method: 'post',
          url: url + '/breed/release/saveEggDiscard.do',
          data: qs.stringify(data),
          withCredentials: true,
          credentials: 'same-origin',
        })
          .then(function (response) {
            that.props.fnSearch();
          })
          .catch(function (error) {
            return [];
          });
      }
    );
  };
  fnCulleggGoodColumnDefs = () => {
    let that = this;
    let culleggGoodColumnDefs = [
      {
        headerName: 'Ep Date',
        field: 'epDate',
        width: 100,
        cellStyle: { textAlign: 'center' },
      },
    ];
    let hhList = [
      'Crack,crack',
      'Dirty,dirty',
      'Jumbo,jumbo',
      'Small,small',
      'Thin,thin',
      'MissShape,missShape',
    ];

    hhList.forEach((row) => {
      let field = row.split(',');
      let headDef = {};
      let childrenDef1 = {};
      let childrenDef2 = {};
      let childrenDef = [];

      childrenDef1.headerName = 'Qty';
      childrenDef1.field = field[1] + 'EggQty';
      childrenDef1.type = 'numericColumn';
      (childrenDef1.valueFormatter = function (params) {
        return GridUtil.formatNumber(params);
      }),
        (childrenDef1.cellStyle = function (params) {
          return GridUtil.getEditCell('D', 100);
        });
      childrenDef2.headerName = 'Discard'; //            field: 'crackEggSaleQty',
      childrenDef2.field = field[1] + 'EggSaleQty';
      childrenDef2.type = 'numericColumn';
      childrenDef2.cellEditor = 'numericEditor';
      childrenDef2.cellEditorParams = function () {
        return {
          handleChange: (e) => that.fnGridHandleChange(e, 'CullingEggList'),
        };
      };
      childrenDef2.editable = function (params) {
        if (params.data[field[1] + 'EggQty'] === 0) return false;
        else return true;
      };
      (childrenDef2.valueFormatter = function (params) {
        return GridUtil.formatNumber(params);
      }),
        (childrenDef2.cellStyle = function (params) {
          if (params.data[field[1] + 'EggQty'] === 0)
            return GridUtil.getEditCell('P', 100);
          else return GridUtil.getEditCell('E', 100);
        });
      headDef.headerName = field[0];
      headDef.children = [childrenDef1, childrenDef2];
      culleggGoodColumnDefs.push(headDef);
    });

    return culleggGoodColumnDefs;
  };
  /*
   * Ag-Grid key Down 수량 체크 및 합계 처리
   */
  fnGridHandleChange = (params, op) => {
    let that = this;
    if (op === 'EggGoodList') {
      let col = params.column.colId.replace('SaleQty', '');
      let qty = Number(params[col] ? params[col] : 0);
      if (Number(params[params.column.colId]) > qty) {
        that.props.alert.show(i18n.t('The quantity is exceeded.'));
        that.gridApi.stopEditing();
        var rowNode = that.gridApi.getDisplayedRowAtIndex(params.rowIndex);
        rowNode.setDataValue(params.column.colId, 0);
        //that.gridApi.tabToPreviousCell();
        that.gridApi.setFocusedCell(params.rowIndex, params.column.colId);
      } else {
        that.printAllDisplayedRows(params.column.colId, params.value); //현재입력받는 숫자도 더함
      }
    } else if (op === 'CullingEggList') {
      //onCellValueChangedCullegg
      let col = params.column.colId.replace('SaleQty', 'Qty');
      let qty = Number(params[col] ? params[col] : 0);
      if (Number(params[params.column.colId]) > qty) {
        that.props.alert.show(i18n.t('The quantity is exceeded.'));
        that.gridApiCullegg.stopEditing();
        var rowNode = that.gridApiCullegg.getDisplayedRowAtIndex(
          params.rowIndex
        );
        rowNode.setDataValue(params.column.colId, 0);
        that.gridApiCullegg.setFocusedCell(
          params.rowIndex,
          params.column.colId
        );
        //that.gridApiCullegg.tabToPreviousCell();
      } else {
        that.printAllDisplayedRowsCullegg(params.column.colId, params.value); //현재입력받는 숫자도 더함
      }
    }
  };
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      params.api.setRowData(data.slice(0, 100));
    };
  };
  onGridReadyCullegg = (params) => {
    this.gridApiCullegg = params.api;
    this.gridColumnApiCullegg = params.columnApi;

    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      params.api.setRowData(data.slice(0, 100));
    };
  };
  onCellValueChanged = (params) => {
    let that = this;
    //params.data.~~~ : row 전체 data
    //params.column.colId : column 명
    //params.rowIndex : row index
    //params.oldValue: "55"
    //params.newValue: "33"
    //params.value: "33"
    console.log(params);
    //"hh04SaleQty"
    let col = params.column.colId.replace('SaleQty', '');
    var rowNode = this.gridApi.getDisplayedRowAtIndex(params.rowIndex);
    let qty = Number(rowNode.data[col] ? rowNode.data[col] : 0);
    if (Number(params.value) > qty) {
      //rowNode.setDataValue(params.column.colId, 0);
      //that.props.alert.show(i18n.t('The quantity is exceeded.'));

      that.gridApi.tabToPreviousCell();
    } else {
      console.log('onCellValueChanged', params);
      this.printAllDisplayedRows(params.column.colId);
    }
  };
  onCellValueChangedCullegg = (params) => {
    let that = this;
    //params.data.~~~ : row 전체 data
    //params.column.colId : column 명
    //params.rowIndex : row index
    //params.oldValue: "55"
    //params.newValue: "33"
    //params.value: "33"
    console.log('onCellValueChangedCullegg', params);
    let col = params.column.colId.replace('SaleQty', 'Qty');
    var rowNode = this.gridApiCullegg.getDisplayedRowAtIndex(params.rowIndex);
    let qty = Number(rowNode.data[col] ? rowNode.data[col] : 0);
    if (Number(params.value) > qty) {
      //rowNode.setDataValue(params.column.colId, 0);

      that.gridApiCullegg.tabToPreviousCell();
    } else {
      this.printAllDisplayedRowsCullegg(params.column.colId);
    }
  };

  //Egg Goog List edit
  printAllDisplayedRows = (col) => {
    let that = this;
    let count = this.gridApi.getDisplayedRowCount();
    console.log('## printAllDisplayedRows');
    console.log('=======', col);
    let tot = 0;
    let hhList = that.state.hhList ? that.state.hhList.split(',') : [];
    for (var i = 0; i < count; i++) {
      var rowNode = this.gridApi.getDisplayedRowAtIndex(i);
      //console.log('row ' + i + ' is ' + rowNode.data.epDate);
      hhList.forEach((row) => {
        let field = row; //.split(' ');
        col = field + 'SaleQty';
        tot += Number(rowNode.data[col] ? rowNode.data[col] : 0);
      });
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          ['goodEggQty']: tot,
        },
      },
      function () {}
    );
  };
  //Culling Egg List edit
  printAllDisplayedRowsCullegg = (col) => {
    var count = this.gridApiCullegg.getDisplayedRowCount();
    let qty = 0;
    let tot = 0;
    for (var i = 0; i < count; i++) {
      var rowNode = this.gridApiCullegg.getDisplayedRowAtIndex(i);
      tot += Number(rowNode.data[col] ? rowNode.data[col] : 0);
    }

    col = col.replace('EggSaleQty', '') + 'EggQty';
    console.log('=======', col);
    this.setState(
      {
        form: {
          ...this.state.form,
          [col]: tot,
        },
      },
      function () {}
    );
  };
  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  renderInput = (inputName, ty, dec, len) => {
    let cl = len ? 'input_type1 w' + len : 'input_type1';

    if (ty && ty === 'N') {
      cl = len ? 'input_base w' + len : 'input_base';
      return (
        <NumberFormat
          style={{ textAlign: 'right' }}
          className={cl}
          thousandSeparator={true}
          decimalScale={dec ? dec : 0}
          name={inputName}
          //ref={this[inputName]}
          getInputRef={(el) => (this[inputName] = el)}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : null
          }
          onChange={(e) => this.setOnChange(e, ty)}
        />
      );
    } else if (ty && ty === 'T') {
      return (
        <TimePicker
          //defaultValue={moment('12:08', 'HH:mm')}
          className={cl}
          format={'HH:mm'}
          ref={this[inputName]}
          value={
            this.state.form[inputName]
              ? moment(this.state.form[inputName], 'HH:mm') || ''
              : null
          }
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
        />
      );
    } else if (ty && ty === 'S') {
      return (
        <span>
          {this.state.form[inputName] ? this.state.form[inputName] || '' : ''}
        </span>
      );
      // } else if (ty && ty === 'D') {
      //   <DatePicker
      //     //name={inputName}
      //     //className={cl}
      //     //ref={this[inputName]}
      //     value={moment(this.state.form[inputName])}
      //     //onChange={(date, dateString) =>
      //     //  this.setOnChangeTime(date, dateString, inputName)
      //     //}
      //   />;
    } else {
      return (
        <input
          type="text"
          name={inputName}
          className={cl}
          ref={this[inputName]}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : ''
          }
          onChange={(e) => this.setOnChange(e)}
        />
      );
    }
  };

  setOnChange = (e, params) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
    }

    that.setState({ form: { ...this.state.form, [col]: val } }, function () {});
  };

  setOnChangeTime = (date, dateString, inputName) => {
    const col = inputName ? inputName : '';
    //this.setState({ form[col]: e.target.value }, function() {});

    this.setState(
      {
        form: {
          ...this.state.form,
          [col]: col,
          [col]: dateString,
        },
      },
      function () {}
    );
  };

  setOnChangeNumber = (value, inputName) => {
    const col = inputName ? inputName : '';
    //this.setState({ form[col]: e.target.value }, function() {});

    const re = /^[0-9\b]+$/;
    console.log('setOnChangeTime', re.test(value));
    if (value === '' || re.test(value)) {
      this.setState(
        {
          form: {
            ...this.state.form,
            [col]: col,
            [col]: value,
          },
        },
        function () {}
      );
    }
  };

  setDisabledDate = (current) => {
    return current < moment(this.minDeliverDate);
  };

  render() {
    if (!this.props.discardFormView) return null;
    const data = this.state.form;
    return (
      <Fragment>
        <h2 className="mT20">
          <Trans>Discard</Trans>
        </h2>

        <div className="board_list2">
          <table>
            <colgroup>
              <col style={{ width: '20%' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '20%' }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <Trans>Date</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'discardDate', //*** */
                    type: 'D', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    placeholder: 'discardDate',
                    //disabledDate: this.setDisabledDate,
                  })}
                </td>
                <th>
                  <Trans>Remarks</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'remarks', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    maxLength: 100,
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 className="mT20">
          <Trans>Good Egg List</Trans>
        </h3>
        <div
          className="board_list ag-theme-balham"
          style={{ height: '300px', width: '100%' }}
        >
          <AgGridReact
            columnDefs={this.state.eggGoodColumnDefs}
            rowData={
              this.state.eggGoodRowData.length > 0
                ? this.state.eggGoodRowData
                : []
            }
            defaultColDef={this.state.defaultColDef}
            enterMovesDownAfterEdit={true}
            enterMovesDown={true}
            frameworkComponents={this.state.frameworkComponents}
            //editType={this.state.editType}
            //onRowClicked={e => this.onRowClicked(e)}
            onCellValueChanged={(e) => this.onCellValueChanged(e)}
            //onCellClicked={e => this.onCellClicked(e)}
            onGridReady={this.onGridReady}
            //getRowHeight={e => GridUtil.getRowHeight(e)}
            singleClickEdit={true}
            onCellFocused={(e) => {
              e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
            }}
          ></AgGridReact>
        </div>
        <h3 style={{ marginTop: '20px' }}>
          <Trans>Culling Egg List</Trans>
        </h3>
        <div
          className="board_list ag-theme-balham"
          style={{ height: '300px', width: '100%' }}
        >
          <AgGridReact
            columnDefs={this.state.culleggGoodColumnDefs}
            rowData={
              this.state.culleggGoodRowData.length > 0
                ? this.state.culleggGoodRowData
                : []
            }
            defaultColDef={this.state.defaultColDef}
            enterMovesDownAfterEdit={true}
            enterMovesDown={true}
            frameworkComponents={this.state.frameworkComponents}
            //editType={this.state.editType}
            //onRowClicked={e => this.onRowClicked(e)}
            onCellValueChanged={(e) => this.onCellValueChangedCullegg(e)}
            //onCellClicked={e => this.onCellClicked(e)}
            onGridReady={this.onGridReadyCullegg}
            singleClickEdit={true}
            onCellFocused={(e) => {
              e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
            }}
          ></AgGridReact>
        </div>

        <div className="board_list mT20">
          <table>
            <colgroup>
              <col style={{ width: '14%' }} />
              <col style={{ width: '14%' }} />
              <col style={{ width: '14%' }} />
              <col style={{ width: '14%' }} />
              <col style={{ width: '14%' }} />
              <col style={{ width: '14%' }} />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <Trans>Crack</Trans>
                </th>
                <th>
                  <Trans>Dirty</Trans>
                </th>
                <th>
                  <Trans>Jumbo</Trans>
                </th>
                <th>
                  <Trans>Small</Trans>
                </th>
                <th>
                  <Trans>Thin</Trans>
                </th>
                <th>
                  <Trans>MissShape</Trans>
                </th>
                <th>
                  <Trans>HE</Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ height: '30px' }}>
                <td>
                  {data.crackEggQty ? data.crackEggQty.toLocaleString() : ''}
                </td>
                <td>
                  {data.dirtyEggQty ? data.dirtyEggQty.toLocaleString() : ''}
                </td>
                <td>
                  {data.jumboEggQty ? data.jumboEggQty.toLocaleString() : ''}
                </td>

                <td>
                  {data.smallEggQty ? data.smallEggQty.toLocaleString() : ''}
                </td>

                <td>
                  {data.thinEggQty ? data.thinEggQty.toLocaleString() : ''}
                </td>

                <td>
                  {data.missShapeEggQty
                    ? data.missShapeEggQty.toLocaleString()
                    : ''}
                </td>
                <td>
                  {data.goodEggQty ? data.goodEggQty.toLocaleString() : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="txt_center mT10 mB10">
          <button className="btn_gray" onClick={() => this.fnSearch()}>
            <Trans>Cancel</Trans>
          </button>
          <button className="btn_save" onClick={() => this.fnSave()}>
            <Trans>save</Trans>
          </button>
        </div>
      </Fragment>
    );
  }
}
export default withAlert()(EggDiscardForm);
