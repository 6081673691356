import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import i18n from 'i18n';
import { Trans } from 'react-i18next';
import { Const, Server } from 'components/Properties';
import MenuUtil from './MenuUtil';
import qs from 'qs';

import { inject, observer } from 'mobx-react';
import { Menu, Icon, Tooltip, Popover } from 'antd';
import './Layout.module.css';

@inject((stores) => ({ mainStore: stores.MainStore }))
@observer
class HatcheryMenu extends React.Component {
  constructor(props) {
    super(props);
    const { mainStore } = props;
    const { groupId, userLevel } = mainStore.getUser();
    this.state = {
      collapsed: false,
      target: '',
      openKeys: [],
      selectedKeys: [],
      groupId: groupId,
      userLevel: userLevel,
    };
  }

  UNSAFE_componentWillMount() {
    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    const selectedKey = MenuUtil.fnMenuSelectedKey();
    const openKey = MenuUtil.fnMenuOpenKey();
    this.setState({
      selectedKeys: [selectedKey],
      openKeys: [openKey],
    });
  }

  okPermission = (menuId) => {
    const { groupId, userLevel } = this.state;
    if (groupId === Const.ADMIN || groupId === Const.DEVEL) return true;
    switch (menuId) {
      case 'Dashboard':
      case 'Receive.Farm':
      case 'Receive.Supplier':
      case 'Move In/Out.Move In':
      case 'Move In/Out.Move Out':
      case 'Grade.Grade':
      case 'Grade.Discard':
      case 'Setting.Setting':
      case 'Setting.Discard':
      case 'Candling.Candling':
      case 'Candling.Discard':
      case 'Hatching.Hatching':
      case 'Hatching.Discard':
      case 'Deliver.Egg Sales':
      case 'Deliver.PS DOC':
      case 'Deliver.Broiler DOC Trans':
      case 'Deliver.Broiler DOC Sales':
      //case 'Deliver.DOC Discard': --메뉴 숨김 2020/11/11
      case 'Cutting.Cutting':
        if (groupId === Const.HATCHERY) {
          if (userLevel === Const.FARM) return true;
        }
        return false;
      case 'Cutting.CuttingList':
        if (groupId === Const.HATCHERY) {
          if (userLevel === Const.FARM) return true;
        }
        return false;

      case 'Report.Hat EP, Daily/HH R':
      case 'Report.Hat EP, Wkly/HH R':
      case 'Report.Hat EP, Term/HH R':
      case 'Report.Hat DOC, T/HH R':
      case 'Report.Hat Stk, T/HH R 1':
        if (groupId === Const.HATCHERY || groupId === Const.CONSULT) {
          if (
            // PPL만 제외 됩니다.
            userLevel === Const.FARM ||
            userLevel === Const.COUNTRY ||
            userLevel === Const.DIVISION ||
            userLevel === Const.REGION ||
            userLevel === Const.SUB_REGION
          )
            return true;
        }
        return false;
    }
    return false;
  };

  render() {
    if (this.props.menu !== Const.MENU_HATCHERY) return null;
    const { location } = this.props;
    let i = 0;
    return (
      <Menu
        onClick={(e) => MenuUtil.fnMenuClick(e, this.state, this.props)}
        theme="dark"
        // style={{ width: 256 }}
        defaultSelectedKeys={this.state.selectedKeys}
        defaultOpenKeys={this.state.openKeys}
        selectedKeys={[location.pathname]}
        mode="inline"
      >
        {this.okPermission('Dashboard') && (
          <Menu.Item key="/hatchery/dashboard">
            <Icon type="border-outer" />
            <span>
              <Trans>Dashboard</Trans>
            </span>
          </Menu.Item>
        )}
        {/*
        <Menu.SubMenu
          key="_user"
          title={
            <span>
              <Icon type="team" />
              <span>
                <Trans>MEMBER</Trans>
              </span>
            </span>
          }
        >
          <Menu.Item key="/hatchery/member/0">
            <Trans>ID</Trans>
          </Menu.Item>
          <Menu.Item key="/hatchery/group/0">
            <Trans>GROUP</Trans>
          </Menu.Item>
        </Menu.SubMenu>
      */}
        {(this.state.groupId === Const.ADMIN ||
          this.state.groupId === Const.DEVEL ||
          (this.state.groupId === Const.HATCHERY &&
            this.state.userLevel === Const.FARM)) && (
          <Menu.SubMenu
            key="_rcv"
            title={
              <span>
                <Icon type="snippets" />
                <span>
                  <Trans>Receive</Trans>
                </span>
              </span>
            }
          >
            {this.okPermission('Receive.Farm') && (
              <Menu.Item key="/hatchery/receive/rcvfarm/0">
                <Trans>Farm</Trans>
              </Menu.Item>
            )}
            {this.okPermission('Receive.Supplier') && (
              <Menu.Item key="/hatchery/receive/rcvpo">
                <Trans>Supplier</Trans>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {(this.state.groupId === Const.ADMIN ||
          this.state.groupId === Const.DEVEL ||
          (this.state.groupId === Const.HATCHERY &&
            this.state.userLevel === Const.FARM)) && (
          <Menu.SubMenu
            key="_move"
            title={
              <span>
                <Icon type="swap" />
                <span>
                  <Trans>Move In/Out</Trans>
                </span>
              </span>
            }
          >
            {this.okPermission('Move In/Out.Move In') && (
              <Menu.Item key="/hatchery/move/in/0">
                <Trans>Move In</Trans>
              </Menu.Item>
            )}
            {this.okPermission('Move In/Out.Move Out') && (
              <Menu.Item key="/hatchery/move/out/0">
                <Trans>Move Out</Trans>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {(this.state.groupId === Const.ADMIN ||
          this.state.groupId === Const.DEVEL ||
          (this.state.groupId === Const.HATCHERY &&
            this.state.userLevel === Const.FARM)) && (
          <Menu.SubMenu
            key="_grade"
            title={
              <span>
                <Icon type="file-search" />
                <span>
                  <Trans>Grade</Trans>
                </span>
              </span>
            }
          >
            {this.okPermission('Grade.Grade') && (
              <Menu.Item key="/hatchery/grade/grade/0">
                <span>
                  <Trans>Grade</Trans>
                </span>
              </Menu.Item>
            )}
            {this.okPermission('Grade.Discard') && (
              <Menu.Item key="/hatchery/grade/discard">
                <span>
                  <Trans>Discard</Trans>
                </span>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {(this.state.groupId === Const.ADMIN ||
          this.state.groupId === Const.DEVEL ||
          (this.state.groupId === Const.HATCHERY &&
            this.state.userLevel === Const.FARM)) && (
          <Menu.SubMenu
            key="_setting"
            title={
              <span>
                <Icon type="setting" />
                <span>
                  <Trans>Setting</Trans>
                </span>
              </span>
            }
          >
            {this.okPermission('Setting.Setting') && (
              <Menu.Item key="/hatchery/setting/0">
                <span>
                  <Trans>Setting</Trans>
                </span>
              </Menu.Item>
            )}

            {this.okPermission('Setting.Discard') && (
              <Menu.Item key="/hatchery/setting/discard">
                <span>
                  <Trans>Discard</Trans>
                </span>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {(this.state.groupId === Const.ADMIN ||
          this.state.groupId === Const.DEVEL ||
          (this.state.groupId === Const.HATCHERY &&
            this.state.userLevel === Const.FARM)) && (
          <Menu.SubMenu
            key="_candling"
            title={
              <span>
                <Icon type="reconciliation" />
                <span>
                  <Trans>Candling</Trans>
                </span>
              </span>
            }
          >
            {this.okPermission('Candling.Candling') && (
              <Menu.Item key="/hatchery/candling/candling">
                <span>
                  <Trans>Candling</Trans>
                </span>
              </Menu.Item>
            )}

            {this.okPermission('Candling.Discard') && (
              <Menu.Item key="/hatchery/candling/discard">
                <span>
                  <Trans>Discard</Trans>
                </span>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {(this.state.groupId === Const.ADMIN ||
          this.state.groupId === Const.DEVEL ||
          (this.state.groupId === Const.HATCHERY &&
            this.state.userLevel === Const.FARM)) && (
          <Menu.SubMenu
            key="_hatching"
            title={
              <span>
                <Icon type="file-done" />
                <span>
                  <Trans>Hatching</Trans>
                </span>
              </span>
            }
          >
            {this.okPermission('Hatching.Hatching') && (
              <Menu.Item key="/hatchery/hatching/hatcherGPS">
                <span>
                  <Trans>Hatching GPS</Trans>
                </span>
              </Menu.Item>
            )}

            {this.okPermission('Hatching.Hatching') && (
              <Menu.Item key="/hatchery/hatching/hatcherPS">
                <span>
                  <Trans>Hatching PS</Trans>
                </span>
              </Menu.Item>
            )}

            {this.okPermission('Hatching.Discard') && (
              <Menu.Item key="/hatchery/hatching/discard">
                <span>
                  <Trans>Discard</Trans>
                </span>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {(this.state.groupId === Const.ADMIN ||
          this.state.groupId === Const.DEVEL ||
          (this.state.groupId === Const.HATCHERY &&
            this.state.userLevel === Const.FARM)) && (
          <Menu.SubMenu
            key="_deli"
            title={
              <span>
                <Icon type="fullscreen" />
                <span>
                  <Trans>Deliver</Trans>
                </span>
              </span>
            }
          >
            {this.okPermission('Deliver.PS DOC') && (
              <Menu.Item key="/hatchery/deliver/psDoc">
                <Trans>PS DOC Trans.</Trans>
              </Menu.Item>
            )}
            {this.okPermission('Deliver.Broiler DOC Trans') && (
              <Menu.Item key="/hatchery/deliver/broilerDoc">
                <Trans>DOC Transfer</Trans>
              </Menu.Item>
            )}
            {this.okPermission('Deliver.Broiler DOC Sales') && (
              <Menu.Item key="/hatchery/deliver/broilerDocSales">
                <Trans>DOC Sales</Trans>
              </Menu.Item>
            )}
            {this.okPermission('Deliver.DOC Discard') && (
              <Menu.Item key="/hatchery/deliver/docDiscard">
                <Trans>DOC Discard</Trans>
              </Menu.Item>
            )}
            {this.okPermission('Deliver.Egg Sales') && (
              <Menu.Item key="/hatchery/deliver/eggSales">
                <Trans>Egg Sales</Trans>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {(this.state.groupId === Const.ADMIN ||
          this.state.groupId === Const.DEVEL ||
          (this.state.groupId === Const.HATCHERY &&
            this.state.userLevel === Const.FARM)) && (
          <Menu.SubMenu
            key="_cutting"
            title={
              <span>
                <Icon type="container" />
                <span>
                  <Trans>Cutting</Trans>
                </span>
              </span>
            }
          >
            {this.okPermission('Cutting.Cutting') && (
              <Menu.Item key="/hatchery/cutting/cutting">
                <span>
                  <Trans>Cutting</Trans>
                </span>
              </Menu.Item>
            )}
            {this.okPermission('Cutting.CuttingList') && (
              <Menu.Item key="/hatchery/cutting/cuttingLists">
                <span>
                  <Trans>Cutting List</Trans>
                </span>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        <Menu.SubMenu
          key="_rpt"
          title={
            <span>
              <Icon type="logout" />
              <span>
                <Trans>Report</Trans>
              </span>
            </span>
          }
        >
          {/* Breeding의 리포트를 공유합니다. */}
          {this.okPermission('Report.Hat EP, Daily/HH R') && (
            <Menu.Item key="/hatchery/report/hatEpDailyHhR">
              <Popover content={i18n.t('Hat EP, Daily/HH R')}></Popover>
              <span>
                {++i}.<Trans>Hat EP, Daily/HH R</Trans>
              </span>
            </Menu.Item>
          )}
          {/* Breeding의 리포트를 공유합니다. */}
          {this.okPermission('Report.Hat EP, Wkly/HH R') && (
            <Menu.Item key="/hatchery/report/hatEpWklyHhR">
              <Popover content={i18n.t('Hat EP, Wkly/HH R')}></Popover>
              <span>
                {++i}.<Trans>Hat EP, Wkly/HH R</Trans>
              </span>
            </Menu.Item>
          )}
          {/* Breeding의 리포트를 공유합니다. */}
          {this.okPermission('Report.Hat EP, Term/HH R') && (
            <Menu.Item key="/hatchery/report/hatEpTermHhR">
              <Popover content={i18n.t('Hat EP, Term/HH R')}></Popover>
              <span>
                {++i}.<Trans>Hat EP, Term/HH R</Trans>
              </span>
            </Menu.Item>
          )}
          {/* Breeding의 리포트를 공유합니다. */}
          {this.okPermission('Report.Hat DOC, T/HH R') && (
            <Menu.Item key="/hatchery/report/hatDocTermHhR">
              <Popover content={i18n.t('Hat DOC, T/HH R')}></Popover>
              <span>
                {++i}.<Trans>Hat DOC, T/HH R</Trans>
              </span>
            </Menu.Item>
          )}
          {/* Breeding의 리포트를 공유합니다. */}
          {this.okPermission('Report.Hat Stk, T/HH R 1') && (
            <Menu.Item key="/hatchery/report/hatStkTermHhR1">
              <Popover content={i18n.t('Hat Stk, T/HH R 1')}></Popover>
              <span>
                {++i}.<Trans>Hat Stk, T/HH R 1</Trans>
              </span>
            </Menu.Item>
          )}
        </Menu.SubMenu>
      </Menu>
    );
  }
}

export default withRouter(HatcheryMenu);
