import React, { Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil, NumericEditor } from 'utils';
import { Trans } from 'react-i18next';
import { Const, Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';
import { moment } from 'components';
import { AgGridReact } from 'components/commons/ag-grid/AgGridUtil';
import { DatePicker, Modal } from 'antd';
import i18n from 'i18next';
import HECutting from './HECutting';
import { withAlert } from 'react-alert';
import '../Hatchery.module.css';
const { confirm } = Modal;
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class CuttingLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFromWkDt: moment(DateUtil.today())
        .add('-1', 'M')
        .format('YYYY-MM-DD'),
      searchToWkDt: DateUtil.today(),
      heCutting: false,
      workDate: DateUtil.today(),
      selectHatchingEggCuttingList: [],
      selectSettingEggCuttingList: [],
      selectFertileEggCuttingList: [],
      selectDocCuttingList: [],
      columnDefsHatchingEggCuttingList: [
        {
          headerName: 'Supplier',
          field: 'customerName',
          width: 250,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Farm',
          field: 'farmInfo',
          width: 250,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Flock',
          field: 'flockId',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'HH',
          field: 'hhId',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Strain',
          field: 'strainInfo',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Egg Receive No',
          field: 'eggReceiveNo',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'CuttingDate',
          field: 'cuttingDate',
          width: 150,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Cutting Qty',
          field: 'cuttingQty',
          type: 'numericColumn',
          width: 150,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: '',
          field: 'delete',
          suppressSizeToFit: false,
          width: 70,
          cellStyle: { textAlign: 'center' },
          editable: false,
          cellRenderer: (params) => {
            const { data } = params;
            if (data.deleteButtonEnabled == 'Y')
              return (
                '<button class="btn_sel">' + i18n.t('Delete') + '</button>'
              );
          },
        },
      ],

      columnDefsSettingEggCuttingList: [
        {
          headerName: 'Supplier',
          field: 'customerName',
          width: 200,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Farm',
          field: 'farmInfo',
          width: 220,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Flock',
          field: 'flockId',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'HH',
          field: 'hhId',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Strain',
          field: 'strainInfo',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Egg Receive No',
          field: 'eggReceiveNo',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Cutting Date',
          field: 'cuttingDate',
          width: 150,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Setter ID',
          field: 'setterId',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Cutting Qty',
          field: 'cuttingQty',
          type: 'numericColumn',
          width: 150,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: '',
          field: 'delete',
          suppressSizeToFit: false,
          width: 70,
          cellStyle: { textAlign: 'center' },
          editable: false,
          cellRenderer: (params) => {
            const { data } = params;
            if (data.deleteButtonEnabled == 'Y')
              return (
                '<button class="btn_sel">' + i18n.t('Delete') + '</button>'
              );
          },
        },
      ],

      columnDefsFertileEggCuttingList: [
        {
          headerName: 'Supplier',
          field: 'customerName',
          width: 200,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Farm',
          field: 'farmInfo',
          width: 220,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Flock',
          field: 'flockId',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'HH',
          field: 'hhId',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Strain',
          field: 'strainInfo',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Egg Receive No',
          field: 'eggReceiveNo',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Candling Date',
          field: 'candlingDate',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Hatcher ID',
          field: 'hatcherId',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Cutting Qty',
          field: 'cuttingQty',
          type: 'numericColumn',
          width: 150,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: '',
          field: 'delete',
          suppressSizeToFit: false,
          width: 70,
          cellStyle: { textAlign: 'center' },
          editable: false,
          cellRenderer: (params) => {
            const { data } = params;
            if (data.deleteButtonEnabled == 'Y')
              return (
                '<button class="btn_sel">' + i18n.t('Delete') + '</button>'
              );
          },
        },
      ],

      columnDefsDocCuttingList: [
        {
          headerName: 'Supplier',
          field: 'customerName',
          width: 200,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Farm',
          field: 'farmInfo',
          width: 220,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Flock',
          field: 'flockId',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'HH',
          field: 'hhId',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Strain',
          field: 'strainInfo',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Setting Seq',
          field: 'settingSeq',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Hatch Date',
          field: 'hatchDate',
          width: 150,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'SP',
          field: 'spQty',
          type: 'numericColumn',
          width: 120,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'A2',
          field: 'a2Qty',
          type: 'numericColumn',
          width: 120,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'A1',
          field: 'a1Qty',
          type: 'numericColumn',
          width: 120,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'BM',
          field: 'bmQty',
          type: 'numericColumn',
          width: 120,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'B',
          field: 'bQty',
          type: 'numericColumn',
          width: 120,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'POLOS',
          field: 'polosQty',
          type: 'numericColumn',
          width: 120,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'SEX',
          field: 'sexQty',
          type: 'numericColumn',
          width: 120,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: '',
          field: 'delete',
          suppressSizeToFit: false,
          width: 70,
          cellStyle: { textAlign: 'center' },
          editable: false,
          cellRenderer: (params) => {
            const { data } = params;
            if (data.deleteButtonEnabled == 'Y')
              return (
                '<button class="btn_sel">' + i18n.t('Delete') + '</button>'
              );
          },
        },
      ],

      defaultColDef: {
        editable: false,
        width: 100,
        filter: true,
        resizable: true,
        sortable: true,
        suppressSizeToFit: false,
      },
      frameworkComponents: {
        numericEditor: NumericEditor,
      },
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnSearch();
  }

  fnSearch = () => {
    let that = this;
    window.scrollTo(0, 0);
    let data = {};
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.countryCode = SessionUtil.getCountryCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.searchFromWkDt = this.state.searchFromWkDt;
    data.searchToWkDt = this.state.searchToWkDt;
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/cutting/selectCuttingListHist.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log(response.data.rows1);
        console.log(response.data.rows2);
        console.log(response.data.rows3);
        that.setState(
          {
            selectHatchingEggCuttingList: response.data.rows1,
            selectSettingEggCuttingList: response.data.rows2,
            selectFertileEggCuttingList: response.data.rows3,
            selectDocCuttingList: response.data.rows4,
          },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });
  };

  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();

    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      params.api.setRowData(data.slice(0, 100));
    };
  };

  formatNumber = (params) => {
    if (params.value) {
      return Math.floor(params.value)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else return '';
  };

  onCellKeyDown(e) {
    var row = this['gridApiGradingInfo'].getRowNode(e.rowIndex);
  }

  onCellClicked = (e, type) => {
    if (e.column.colId !== 'delete') return;
    if (e.data.deleteButtonEnabled == 'N') return;
    console.log(e);
    let that = this;
    confirm({
      title: i18n.t('Are you sure delete this task?'),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        that.fnDelete(e.data, type);
      },
      onCancel() {},
    });
  };

  fnDelete = (params, type) => {
    let that = this;

    console.log(params);

    params.farmCode = SessionUtil.getFarmCode();
    params.dateFormat = SessionUtil.getDateFormat();
    params.updateId = SessionUtil.getUserId();
    params.hatcheryId = SessionUtil.getHatcheryId();

    const url = Server.getRestAPI();
    var action = '';
    if (type == 'hcl') {
      action = '/hatchery/cutting/deleteHatchingEggCuttingList.do';
    }
    if (type == 'scl' || type == 'fcl') {
      action = '/hatchery/cutting/deleteSettingEggCuttingList.do';
    }
    if (type == 'dcl') {
      action = '/hatchery/cutting/deleteDocCuttingList.do';
    }

    console.log(params);

    axios({
      method: 'post',
      url: url + action,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        that.fnInit();
        if (data === 0)
          return that.props.alert.error(
            i18n.t('The receipt is complete and cannot be deleted.')
          );
        return that.props.alert.show(i18n.t('Deleted'));
      })
      .catch(function (error) {
        return [];
      });
  };

  fnInit = () => {
    window.scrollTo(0, 0);
    this.fnSearch();
  };

  setOnChange = (e, params) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    that.setState({ [col]: val }, function () {});
  };

  render() {
    return (
      <Fragment>
        {/* 디자인 영역  */}
        <h2>
          <Trans>Cutting List</Trans>
        </h2>
        <div className="con_box">
          <div className="mT10">
            {GridUtil.renderInput({
              inputName: 'searchFromWkDt', //*** */
              type: 'D', //*** */
              that: this, //*** */
              statsForm: this.state, //*** */
              setOnChange: this.setOnChange, //*** */
              width: 120, // px제외 : 없으면 100% */
              placeholder: 'Date',
            })}
            &nbsp; ~ &nbsp;
            {GridUtil.renderInput({
              inputName: 'searchToWkDt', //*** */
              type: 'D', //*** */
              that: this, //*** */
              statsForm: this.state, //*** */
              setOnChange: this.setOnChange, //*** */
              width: 120, // px제외 : 없으면 100% */
              placeholder: 'Date',
            })}
            <button className="btn_gray" onClick={() => this.fnSearch()}>
              <Trans>Search</Trans>
            </button>
          </div>

          <div className="line"></div>

          <h3>
            <Trans>Hatching Egg Cutting List</Trans>
          </h3>
          <div
            className="board_list ag-theme-balham mT10"
            style={{ height: '300px', width: '100%' }}
          >
            <AgGridReact
              onGridReady={(e) => this.onGridReady(e, 'gridApiGradingInfo')}
              columnDefs={this.state.columnDefsHatchingEggCuttingList}
              rowData={
                this.state.selectHatchingEggCuttingList.length > 0
                  ? this.state.selectHatchingEggCuttingList
                  : []
              }
              defaultColDef={this.state.defaultColDef}
              enterMovesDownAfterEdit={true}
              enterMovesDown={true}
              frameworkComponents={this.state.frameworkComponents}
              getRowHeight={(e) => GridUtil.getRowHeight(e)}
              singleClickEdit={true}
              onCellKeyDown={(e) => this.onCellKeyDown(e)}
              onCellClicked={(e) => this.onCellClicked(e, 'hcl')}
            ></AgGridReact>
          </div>

          <div className="line"></div>

          <h3>
            <Trans>Setting Egg Cutting List</Trans>
          </h3>
          <div
            className="board_list ag-theme-balham mT10"
            style={{ height: '300px', width: '100%' }}
          >
            <AgGridReact
              onGridReady={(e) => this.onGridReady(e, 'gridApiGradingInfo')}
              columnDefs={this.state.columnDefsSettingEggCuttingList}
              rowData={
                this.state.selectSettingEggCuttingList.length > 0
                  ? this.state.selectSettingEggCuttingList
                  : []
              }
              defaultColDef={this.state.defaultColDef}
              enterMovesDownAfterEdit={true}
              enterMovesDown={true}
              frameworkComponents={this.state.frameworkComponents}
              getRowHeight={(e) => GridUtil.getRowHeight(e)}
              singleClickEdit={true}
              onCellKeyDown={(e) => this.onCellKeyDown(e)}
              onCellClicked={(e) => this.onCellClicked(e, 'scl')}
            ></AgGridReact>
          </div>

          <div className="line"></div>

          <h3>
            <Trans>Fertile Egg Cutting List</Trans>
          </h3>
          <div
            className="board_list ag-theme-balham mT10"
            style={{ height: '300px', width: '100%' }}
          >
            <AgGridReact
              onGridReady={(e) => this.onGridReady(e, 'gridApiGradingInfo')}
              columnDefs={this.state.columnDefsFertileEggCuttingList}
              rowData={
                this.state.selectFertileEggCuttingList.length > 0
                  ? this.state.selectFertileEggCuttingList
                  : []
              }
              defaultColDef={this.state.defaultColDef}
              enterMovesDownAfterEdit={true}
              enterMovesDown={true}
              frameworkComponents={this.state.frameworkComponents}
              getRowHeight={(e) => GridUtil.getRowHeight(e)}
              singleClickEdit={true}
              onCellKeyDown={(e) => this.onCellKeyDown(e)}
              onCellClicked={(e) => this.onCellClicked(e, 'fcl')}
            ></AgGridReact>
          </div>

          <div className="line"></div>

          <h3>
            <Trans>DOC Cutting List</Trans>
          </h3>
          <div
            className="board_list ag-theme-balham mT10"
            style={{ height: '300px', width: '100%' }}
          >
            <AgGridReact
              onGridReady={(e) => this.onGridReady(e, 'gridApiGradingInfo')}
              columnDefs={this.state.columnDefsDocCuttingList}
              rowData={
                this.state.selectDocCuttingList.length > 0
                  ? this.state.selectDocCuttingList
                  : []
              }
              defaultColDef={this.state.defaultColDef}
              enterMovesDownAfterEdit={true}
              enterMovesDown={true}
              frameworkComponents={this.state.frameworkComponents}
              getRowHeight={(e) => GridUtil.getRowHeight(e)}
              singleClickEdit={true}
              onCellKeyDown={(e) => this.onCellKeyDown(e)}
              onCellClicked={(e) => this.onCellClicked(e, 'dcl')}
            ></AgGridReact>
          </div>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default withAlert()(CuttingLists);
