import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { Const, Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';
import { moment } from 'components';
import i18n from 'i18next';
import { Popconfirm, Switch, message, DatePicker, Modal } from 'antd';
import NumberFormat from 'react-number-format';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';

const { confirm } = Modal;

/*
 ** Breed - Receive - Hen House - Move
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class PopMoveIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      popMoveIn: false,
      popMoveRow: [],
      chickinQty: 0,
      chickinQtyF: 0,
      chickinQtyM: 0,
      inQty: 0,
      inQtyF: 0,
      inQtyM: 0,
      chickinDate: '',
      moveDate: '',
      remarks: '',
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnInit(this.props.popMoveRow);
  }

  fnInit = (popMoveRow) => {
    let that = this;
    this.setState(popMoveRow, function () {
      // let _capa = Number(that.state.capacity);
      // _capa =
      //   _capa -
      //   (Number(that.state.chickinQtyF) + Number(that.state.chickinQtyM));

      that.setState(
        {
          moveDate: DateUtil.today(), //오늘날짜
          //capa: _capa,
          inQtyF: that.state.fromCurrentQtyF ? that.state.fromCurrentQtyF : 0,
          inQtyM: that.state.fromCurrentQtyM ? that.state.fromCurrentQtyM : 0,
          remarks: '',
        },
        function () {
          that.fnCalcQty(that.state, '');
        }
      );
    });
  };

  fnCalcQty = (data, col) => {
    //chickinQty
    if (col === '') {
      //처음
      let _chickinQty =
        Number(data.fromCurrentQtyF ? data.fromCurrentQtyF : 0) +
        Number(data.fromCurrentQtyM ? data.fromCurrentQtyM : 0);

      this.setState({ inQty: _chickinQty }, function () {});
    } else {
      let _inQty =
        Number(data.inQtyF ? data.inQtyF : 0) +
        Number(data.inQtyM ? data.inQtyM : 0);

      this.setState({ inQty: _inQty }, function () {});
    }
  };
  fnSave = () => {
    this.setState({ visible: true });
    if (
      Number(this.state.inQty) ==
      Number(this.state.fromCurrentQtyF) + Number(this.state.fromCurrentQtyM)
    ) {
      this.setState({ visible: false });
      this.confirmForHenHouse(); // 계사를 비우기 위한 컨펌
    } else {
      this.setState({ visible: false });
      this.fnInsertMoveHH();
    }
  };

  confirmForHenHouse = () => {
    Modal.confirm({
      content: 'This hen house will be empty. Are you sure?',
      visible: this.state.visible,
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: this.fnSelectCurrentFlockStock,
      onCancel: this.handleVisibleChange,
    });
  };

  fnSelectCurrentFlockStock = () => {
    this.props.fnClosePop('popMoveIn');
    this.fnInsertMoveHH();
  };

  fnInsertMoveHH = () => {
    this.setState({ visible: false });
    let data = this.state;
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.moveDate = data.moveDate ? data.moveDate : DateUtil.today();
    data.chickinQtyF = data.inQtyF; //입력받은 수량 ************
    data.chickinQtyM = data.inQtyM; //입력받은 수량 ************

    if (!data.flockId) {
      //Target 비어 있는 계사
      data.flockId = data.fromFlockId;
      data.chickinDate = data.fromChickinDate;
      data.empty = 'Yes';
    } else {
      data.empty = 'No';
    }
    //전부이동
    if (
      Number(data.fromCurrentQtyF) + Number(data.fromCurrentQtyM) ===
      Number(data.chickinQtyF) + Number(data.chickinQtyM)
    ) {
      data.allMove = 'Yes';
    } else {
      data.allMove = 'No';
    }
    if (Number(data.chickinQtyF) + Number(data.chickinQtyM) < 0) {
      that.props.alert.show(i18n.t('Please check quantity'));
      return false;
    }
    data.transferNo = 0; //*** 2020-04-13 추가 */
    console.log('saveChickMoveHh', data);
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/saveChickMoveHh.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if ('In Moveout' === data.message)
          that.props.alert.error(i18n.t('In Moveout'));
        that.props.fnClickFlockCancel();
        that.props.fnClosePop('popMoveIn');
      })
      .catch(function (error) {
        return [];
      });
  };

  handleVisibleChange = (visible) => {
    if (!visible) {
      this.setState({ visible });
      return;
    }
  };
  confirm = () => {};

  cancel = () => {
    this.setState({ visible: false });
  };
  onDateChange = (date, dateString, name) => {
    this.setState({ [name]: dateString }, function () {
      console.log(this.state);
    });
  };

  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  renderInput = (inputName, ty, dec, len, maxLength) => {
    let cl = len ? 'input_base w' + len : 'input_base w30';
    let ww = len ? len : '30';

    if (ty && ty === 'N') {
      cl = len ? 'input_base w' + len : 'input_base';

      return (
        <NumberFormat
          className={'input_base'}
          style={{ width: len ? len + 'px' : null }}
          thousandSeparator={true}
          decimalScale={dec ? dec : 0}
          name={inputName}
          getInputRef={(el) => (this[inputName] = el)}
          value={this.state[inputName] ? this.state[inputName] || '' : null}
          onChange={(e) => this.setOnChange(e, ty)}
        />
      );
    } else if (ty && ty === 'T') {
      return (
        <TimePicker
          //defaultValue={moment('12:08', 'HH:mm')}
          className={cl}
          style={{ width: len ? len : null }}
          format={'HH:mm'}
          ref={(input) => {
            this[inputName] = input;
          }}
          value={
            this.state.growingForm[inputName]
              ? moment(this.state.growingForm[inputName], 'HH:mm') || ''
              : null
          }
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
        />
      );
    } else if (ty && ty === 'D') {
      return (
        <DatePicker
          name={inputName}
          className={'input_base'}
          style={{ width: len }}
          ref={(input) => {
            this[inputName] = input;
          }}
          value={this.state[inputName] ? moment(this.state[inputName]) : ''}
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
          allowClear={false}
        />
      );
    } else {
      return (
        <input
          type="text"
          name={inputName}
          className={cl}
          //style={{ width: len ? len : null }}
          maxLength={maxLength ? maxLength : null}
          ref={this[inputName]}
          value={this.state[inputName] ? this.state[inputName] || '' : ''}
          onChange={(e) => this.setOnChange(e)}
        />
      );
    }
  };
  setOnChange = (e, params, dateString) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    this.setState({ [col]: val }, function () {
      if (params.type === 'N') {
        that.fnCalcQty(that.state, col);
      }
    });
  };

  render() {
    if (!this.props.popMoveIn) return null;
    let row = this.state;
    return (
      <Fragment>
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            margin: 'auto',
            zIndex: 1000,
            backgroundColor: 'rgba(0,0,0, 0.5)',
          }}
        >
          <div
            style={{
              position: 'absolute',
              width: '900px',
              height: 'auto',
              left: '50%',
              transform: 'translateX(-50%)',
              top: '100px',
              margin: 'auto',
              borderRadius: '10px',
              background: 'white',
            }}
          >
            <div className="pop_box">
              <h2>
                <Trans>Hen House Move</Trans>
              </h2>
              <ul className="divide">
                <ul className="layer_list">
                  <li style={{ width: '100%' }}>
                    <div className="f_left">
                      <span>
                        <Trans>{row.fromHhId}</Trans> {' => '}
                        <Trans>{row.hhId}</Trans>{' '}
                      </span>
                    </div>

                    <div className="board_list">
                      <table>
                        <colgroup>
                          <col style={{ width: '15%' }} />
                          <col style={{ width: '20%' }} />
                          <col style={{ width: '15%' }} />
                          <col style={{ width: '20%' }} />
                          <col />
                        </colgroup>
                        <thead>
                          <tr>
                            <th colSpan="2">Female</th>
                            <th colSpan="2">Male</th>
                            <th>Qty/Capa</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              {row.fromCurrentQtyF
                                ? row.fromCurrentQtyF.toLocaleString()
                                : 0}
                            </th>
                            <td style={{ textAlign: 'left' }}>
                              {GridUtil.renderInput({
                                inputName: 'inQtyF', //*** */
                                type: 'N', //*** */
                                that: this, //*** */
                                maxVal: row.fromCurrentQtyF
                                  ? row.fromCurrentQtyF
                                  : null,
                                statsForm: this.state, //*** */
                                setOnChange: this.setOnChange, //*** */
                                width: 120, // px제외 : 없으면 30 */
                                minVal: 0,
                              })}
                            </td>
                            <th>
                              {row.fromCurrentQtyM
                                ? row.fromCurrentQtyM.toLocaleString()
                                : 0}
                            </th>
                            <td style={{ textAlign: 'left' }}>
                              {GridUtil.renderInput({
                                inputName: 'inQtyM', //*** */
                                type: 'N', //*** */
                                that: this, //*** */
                                maxVal: row.fromCurrentQtyM
                                  ? row.fromCurrentQtyM
                                  : null,
                                statsForm: this.state, //*** */
                                setOnChange: this.setOnChange, //*** */
                                width: 120, // px제외 : 없으면 30 */
                                minVal: 0,
                              })}
                            </td>
                            <td>
                              <span>
                                {' '}
                                {(
                                  (row.inQty ? Number(row.inQty) : 0) +
                                  (row.chickinQty ? Number(row.chickinQty) : 0)
                                ).toLocaleString()}{' '}
                                /{' '}
                                {row.capacity
                                  ? row.capacity.toLocaleString()
                                  : '0'}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <Trans>Move Date</Trans>
                            </th>
                            <td colSpan="4" style={{ textAlign: 'left' }}>
                              {GridUtil.renderInput({
                                inputName: 'moveDate', //*** */
                                type: 'D', //*** */
                                that: this, //*** */
                                statsForm: this.state, //*** */
                                setOnChange: this.setOnChange, //*** */
                                width: 120, // px제외 : 없으면 30 */
                                placeholder: 'moveDate',
                              })}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <Trans>Remarks</Trans>
                            </th>
                            <td colSpan="4" style={{ textAlign: 'left' }}>
                              {GridUtil.renderInput({
                                inputName: 'moveReason', //*** */
                                type: '', //*** */
                                that: this, //*** */
                                statsForm: this.state, //*** */
                                setOnChange: this.setOnChange, //*** */
                                //width: 120, // px제외 : 없으면 100% */
                                maxLength: 1000,
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                </ul>
              </ul>
              <div className="f_right mT10 mB30">
                <Popconfirm
                  title={i18n.t(
                    'Over Capacity\nExceeded the appropriate number.\nWould you like to put it though?'
                  )}
                  visible={this.state.visible}
                  onVisibleChange={this.handleVisibleChange}
                  onConfirm={this.confirm}
                  onCancel={this.cancel}
                  okText={i18n.t('OK')}
                  cancelText={i18n.t('Cancel')}
                >
                  <button
                    className="btn_gray"
                    onClick={(e) => this.props.fnClosePop('popMoveIn')}
                  >
                    <Trans>Cancel</Trans>
                  </button>
                  <button
                    className="btn_save"
                    onClick={(e) => this.fnSave(row)}
                  >
                    <Trans>Save</Trans>
                  </button>
                </Popconfirm>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withAlert()(PopMoveIn);
