import * as React from 'react';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SessionUtil, CommonUtil } from 'utils';
import { moment } from 'components';

import produce from 'immer';

import axios from 'axios';
import qs from 'qs';
// import i18n from 'i18n';
import { Server } from 'components/Properties';
import { message, Select } from 'antd';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
const LangCode = props => {
  const [codes, setCodes] = useState([]);
  const [code, setCode] = useState(SessionUtil.getLang());
  const [disabled, setDisabled] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(
    code => {
      moment.locale(SessionUtil.getLang());
      fnGetRowData();
    },
    [code]
  );

  // 국가 코드 정보 가져오기
  const fnGetRowData = params => {
    const that = this;
    CommonUtil.selectCodes({ masterCodeId: 'EP34' })
      .then(function(response) {
        const { data } = response;
        const codes = [];
        if (data) {
          data.map((row, i) => {
            const obj = {
              codeValue: row.codeValue,
              codeText: row.codeText
            };
            codes.push(obj);
          });
          setCodes(codes);
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  const onChange = value => {
    setCode(value);
    props.setCode(props.name, value);
    switch (value) {
      case 'ko':
      case 'vn':
      case 'en':
      case 'id':
        if (props.changePossible) {
          i18n.changeLanguage(value);
          localStorage.setItem(props.name, value);
          console.log('18n.changeLanguage:', value);
        }
        break;
    }
  };

  return (
    <Select
      name="lang"
      className={props.className}
      defaultValue={props.code ? props.code.toLowerCase() : undefined}
      value={props.value ? props.value.toLowerCase() : code}
      onChange={onChange}
      placeholder={t('Select code')}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={props.disabled}
      style={props.style}
    >
      {codes.map((row, i) => {
        return (
          <Select.Option key={i} value={row.codeValue.toLowerCase()}>
            {row.codeText}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default LangCode;
