import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withAlert } from 'react-alert';
import { SessionUtil, DateUtil, GridUtil, PopSearchCust } from 'utils';
import { Trans } from 'react-i18next';

import { Const, Server } from 'components/Properties';
import { Modal, Button, Select, DatePicker, Input } from 'antd';
import axios from 'axios';
import qs from 'qs';

import { moment } from 'components';
import NumberFormat from 'react-number-format';

import i18n from 'i18next';

import '../Broiler.module.css';
const { Search } = Input;
/*
 ** breeding/receive/BreedRecvForm
 ** 종계입고 폼, 저장 후 계군에 합산
 ** ===============================
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class BreedRecvForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popSearchCustView: false,
      iuFlag: 'I',
      form: [],
      customerList: [],
      rcvDate: DateUtil.today(),
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    // strainCode 가져오기
    let param = {};
    param['masterCodeId'] = this.state.strainCode;
    this.fnSearchStrain();
    this.fnSearchCustomer();
    this.fnSearchProject();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let that = this;
    console.log('prevProps.formInfo', prevProps.formInfo);
    console.log('this.props.formInfo', this.props.formInfo);
    if (prevProps.formInfo !== this.props.formInfo) {
      console.log('chk1');
      this.setState(
        {
          form: this.props.formInfo,
          iuFlag: 'U',
        },

        function () {
          that.fnSearchProject();
        }
      );
    }
  }

  fnSearchStrain = () => {
    window.scrollTo(0, 0);

    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.farmClass = SessionUtil.getFarmClass();
    data.dateFormat = SessionUtil.getDateFormat();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.countryCode = SessionUtil.getCountryCode();
    data.hatchDate = DateUtil.today();
    data.codeId = 'EP26';
    //data.codeValue = "AND CODE_VALUE LIKE 'A%' ";

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/broiler/receive/selectCodeList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('response', response.data);
        that.setState(
          {
            strainCodeList: response.data.rows,
          },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });
  };

  fnSearchCustomer = () => {
    let that = this;
    window.scrollTo(0, 0);

    //거래처 리스트 불러오기
    this.selectCustomerList("'21'");

    let data = {};
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.countryCode = SessionUtil.getCountryCode();
    data.hatcheryId = SessionUtil.getHatcheryId();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/transfer/selectTransferEggkList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {})
      .catch(function (error) {
        return [];
      });
  };

  fnClickSaveBtn = (e) => {
    let that = this;
    let data = this.state.form;

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.iuFlag = this.state.iuFlag;

    if (data.farmCode === 'undefined') {
      data.farmCode = '';
    }

    if (!data.transferQty) data.transferQty = 0;
    if (!data.recvQty) data.recvQty = 0;
    if (!data.aQty) data.aQty = 0;
    if (!data.bQty) data.bQty = 0;
    if (!data.othersQty) data.othersQty = 0;
    if (!data.bonusQty) data.bonusQty = 0;

    if (this.props.iuFlag === 'U') {
      data.prevProjCode = this.props.formInfo.projCode;
    }

    if (!data.customerCode) {
      //Supplier
      that.props.alert.show(i18n.t('Please select Supplier.'));
      that.customerCode.focus();
      return false;
    }

    if (data.transferQty <= 0) {
      that.props.alert.show(i18n.t('Please check Transfer Qty.'));
      that.transferQty.focus();
      return false;
    }

    if (data.recvQty <= 0 || data.recvQty > data.transferQty) {
      that.props.alert.show(i18n.t('Please check Receive Qty.'));
      that.recvQty.focus();
      return false;
    }

    if (!data.projCode) {
      that.props.alert.show(i18n.t('Please select Project.'));
      that.projCode.focus();
      return false;
    }

    if (!data.strainCode) {
      that.props.alert.show(i18n.t('Please select Strain.'));
      that.strainCode.focus();
      return false;
    }

    if (!data.transferDate) {
      data.transferDate = this.state.rcvDate;
    }

    if (!data.receiveDate) {
      data.receiveDate = this.state.rcvDate;
    }

    if (
      Number(data.aQty) +
        Number(data.bQty) +
        Number(data.pQty) +
        Number(data.othersQty) >
      0
    ) {
      if (
        Number(data.aQty) +
          Number(data.bQty) +
          Number(data.pQty) +
          Number(data.othersQty) !=
        data.recvQty
      ) {
        that.props.alert.show(
          i18n.t('The sum of P, A, B, and Others must equal Receive Qty.')
        );
        return false;
      }
    }

    console.log('data', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/broiler/receive/saveSupplierReceiveInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.alert.show(i18n.t('Saved'));
        that.fnSearchProject();
        that.fnClickClearBtn();
        that.props.fnSetRegDate();
      })
      .catch(function (error) {
        that.props.alert.show(i18n.t('Save failed'));
        return [];
      });
  };

  fnClickClearBtn = (e) => {
    let that = this;
    this.setState(
      {
        form: [],
        iuFlag: 'I',
      },
      function () {
        that.fnSearchProject();
      }
    );
  };

  setOnChange = (e, params, dateString) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';

    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    this.setState(
      {
        form: {
          ...this.state.form,
          [col]: val,
        },
      },
      function () {}
    );
  };

  setOnChangeSelect = (value, col) => {
    this.setState(
      { form: { ...this.state.form, [col]: value } },
      function () {}
    );
  };

  onDateChange = (date, dateString, name) => {
    this.setState(
      { form: { ...this.state.form, [name]: dateString } },
      function () {}
    );
  };

  //거래처 찾기
  selectCustomerList = (value) => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.customerName = '';
    data.customerKind = value;

    if (data.farmCode === 'undefined') {
      data.farmCode = '';
    }

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/selectCustomerList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ customerList: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };

  fnSearchProject = () => {
    window.scrollTo(0, 0);

    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.hatchDate = DateUtil.today();

    if (data.farmCode === 'undefined') {
      data.farmCode = '';
    }

    if (this.state.iuFlag === 'I') {
      data.prevProjCode = '';
    }
    if (this.state.iuFlag === 'U') {
      data.prevProjCode = this.props.formInfo.projCode;
    }

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/broiler/receive/selectProjectListForUpdate.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        if (response.data.rows1.length > 0) {
          that.setState(
            {
              projectList: response.data.rows1,
            },
            function () {}
          );
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  fnSearchCust = (value) => {
    this.setState(
      {
        popSearchCustView: true,
        customerCode: value,
        customerKind: ['01', '02', '03', '04', '05', '06', '07'],
      },
      function () {}
    );
  };

  fnSelectCust = (data) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          customerCode: data.customerCode,
          customerName: data.customerName,
        },
      },
      function () {}
    );
  };

  fnClosePop = (data) => {
    this.setState({ [data]: false }, function () {});
  };

  setOnChangeCust = (e) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          customerCode: e.target.value,
          customerName: '',
        },
      },
      function () {}
    );
  };

  render() {
    const Option = Select.Option;
    const customerOptions = this.state.customerList.map((d, i) => (
      <Option key={i} value={d.customerCode}>
        {d.customerName}
      </Option>
    ));

    let strainOptions = [];
    if (this.state.strainCodeList && this.state.strainCodeList.length > 0) {
      strainOptions = this.state.strainCodeList.map((row, i) => {
        return (
          <Option key={i} value={row.codeValue}>
            {row.codeValue + ' - ' + row.codeText}
          </Option>
        );
      });
    }

    let projectOptions = [];
    if (this.state.projectList && this.state.projectList.length > 0) {
      projectOptions = this.state.projectList.map((row, i) => {
        return (
          <Option key={i} value={row.projCode}>
            {row.projName}
          </Option>
        );
      });
    }

    return (
      <Fragment>
        <div className="con_box">
          <h3>
            <Trans>Receive Info</Trans>
          </h3>

          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '30%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Supplier</Trans> <span className="red">*</span>{' '}
                  </th>
                  <td>
                    <Search
                      placeholder="Customer Code"
                      onSearch={(value) => this.fnSearchCust(value)}
                      enterButton
                      style={{ width: '300px' }}
                      ref={(input) => {
                        this['customerCode'] = input;
                      }}
                      value={this.state.form.customerCode}
                      onChange={(value) => this.setOnChangeCust(value)}
                    />
                  </td>

                  <th>
                    <Trans>Order No</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'orderNo', //*** */
                      type: '', //*** */
                      width: 120, // px제외 : 없으면 30 */
                      maxLength: 20,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Invoice Date</Trans>
                  </th>
                  <td style={{ textAlign: 'left' }}>
                    <DatePicker
                      name="transferDate"
                      style={{ width: 120 }}
                      value={
                        this.state.form.transferDate
                          ? moment(this.state.form.transferDate)
                          : moment(DateUtil.today())
                      }
                      onChange={(date, dateString) =>
                        this.onDateChange(date, dateString, 'transferDate')
                      }
                      allowClear={false}
                    />
                  </td>

                  <th>
                    <Trans>Invoice Qty</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'transferQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 120, // px제외 : 없으면 100% */
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Truck No</Trans>
                  </th>
                  <td colSpan="3">
                    {GridUtil.renderInput({
                      inputName: 'truckNo', //*** */
                      type: '', //*** */
                      that: this, //*** */
                      maxLength: 10,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 120, // px제외 : 없으면 100% */
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="board_list2" style={{ marginTop: '20px' }}>
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '30%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Project</Trans> <span className="red">*</span>{' '}
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'projCode', //*** */
                      type: 'SELECT', //*** */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      options: projectOptions, //*** */ type:'SELECT' options*/
                      placeholder: 'Project', //*** */
                      width: 210, // px제외 : 없으면 30 */
                    })}
                  </td>

                  <th>
                    <Trans>Receive Date</Trans>
                  </th>
                  <td colSpan="5" style={{ textAlign: 'left' }}>
                    <DatePicker
                      name="receiveDate"
                      style={{ width: 120 }}
                      value={
                        this.state.form.receiveDate
                          ? moment(this.state.form.receiveDate)
                          : moment(DateUtil.today())
                      }
                      onChange={(date, dateString) =>
                        this.onDateChange(date, dateString, 'receiveDate')
                      }
                      allowClear={false}
                    />
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Strain</Trans> <span className="red">*</span>{' '}
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'strainCode', //*** */
                      type: 'SELECT', //*** */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      options: strainOptions, //*** */ type:'SELECT' options*/
                      placeholder: 'Strain', //*** */
                      width: 210, // px제외 : 없으면 30 */
                    })}
                  </td>
                  <th>
                    <Trans>Receive Qty</Trans> <span className="red">*</span>{' '}
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'recvQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      //maxVal: this.props.transferInfo.transferQty,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 65, // px제외 : 없으면 100% */
                    })}
                  </td>
                  <td className="spac10_left label">
                    <Trans>P</Trans>{' '}
                    {GridUtil.renderInput({
                      inputName: 'pQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 53, // px제외 : 없으면 100% */
                      height: 25,
                    })}{' '}
                    <span className="mL20"></span>
                    <Trans>A</Trans>{' '}
                    {GridUtil.renderInput({
                      inputName: 'aQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 53, // px제외 : 없으면 100% */
                      height: 25,
                    })}{' '}
                    <span className="mL20"></span>
                    <Trans>B</Trans>{' '}
                    {GridUtil.renderInput({
                      inputName: 'bQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 53, // px제외 : 없으면 100% */
                      height: 25,
                    })}{' '}
                    <span className="mL10"></span>
                    <Trans>Others</Trans>{' '}
                    {GridUtil.renderInput({
                      inputName: 'othersQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 53, // px제외 : 없으면 100% */
                      height: 25,
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td colSpan="7">
                    {GridUtil.renderInput({
                      inputName: 'remarks', //*** */
                      type: '', //*** */
                      //dec: 2,
                      maxLength: 1000,
                      //maxVal: 999.99,
                      //width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="txt_center mT40">
            <button
              className="btn_sky"
              onClick={(e) => this.fnClickClearBtn(e)}
            >
              <Trans>Clear</Trans>
            </button>
            {(this.props.iuFlag === 'I' ||
              this.state.iuFlag === 'I' ||
              this.state.form.receiveYn === 0) && (
              <button
                className="btn_save"
                onClick={(e) => this.fnClickSaveBtn(e)}
              >
                <Trans>Save</Trans>
              </button>
            )}
          </div>
        </div>

        {this.state.popSearchCustView ? (
          <PopSearchCust
            popSearchCustView={this.state.popSearchCustView}
            customerCode={this.state.customerCode}
            customerKind={this.state.customerKind}
            fnClosePop={this.fnClosePop}
            fnSelectCust={this.fnSelectCust}
          />
        ) : null}
        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default withAlert()(BreedRecvForm);
