import React, { PureComponent, Fragment } from 'react';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import PopMoveOut from './PopMoveOut';
import HhMoveoutList from './HhMoveoutList';
import HenHouseFlockList from './HenHouseFlockList';
import HenHouseInList from './HenHouseInList';
import PopFlock from './PopFlock';
import MoveoutListGrid from './MoveoutListGrid';
import PopIn from './PopIn'; //계군선택 - 계사선택 In button click
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import { Modal, Button } from 'antd';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';
const { confirm } = Modal;

/*
 ** breeding/Move/MoveOut
 ** 계사에 입식되어 사육중인 일부 병아리를 타 농장으로 이동 시키는 기능
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class MoveOut extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      prop: {},
      flockList: [],
      hhChickinList: [],
      moveOutList: [],
      moveOutListView: true,
      hhMoveView: true,
      hhInView: false,
      popMoveOutView: false,
      popEmpty: false,
      popIn: false,
      popFlock: false,
      hhFlockId: '', //Flock click
      flockListFlockId: '', //
      popInRow: {},
      popMoveRow: {},
      rowData: [],
      columnDefs: [
        {
          headerName: 'Flcok',
          field: 'flockId',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Hen House',
          field: 'hhId',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Move Data',
          field: 'transferDate',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Move Farm',
          field: 'farmName',
          width: 150,
          cellStyle: { textAlign: 'left' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Move Out Farm',
          field: 'recvFarmName',
          width: 150,
          cellStyle: { textAlign: 'left' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Move Out(F)',
          field: 'moveQtyF',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Move Out(M)',
          field: 'moveQtyM',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Order No',
          field: 'orderNo',
          width: 120,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: 'Truck No',
          field: 'truckNo',
          width: 120,
          cellStyle: { textAlign: 'center' },
        },

        {
          headerName: 'Remarks',
          field: 'remarks',
          width: 200,
          cellStyle: { textAlign: 'left' },
          suppressSizeToFit: false,
        },
        {
          headerName: '',
          field: 'iuFlag',
          width: 80,
          cellStyle: { textAlign: 'center' },
          cellRenderer: (params) => {
            if (params.data.received === 'N')
              return (
                '<button class="btn_del">' + i18n.t('Delete') + '</button>'
              );
            else return null;
          },

          suppressSizeToFit: true,
        },
      ],
      defaultColDef: {
        editable: false,
        sortable: true,
        resizable: true,
        suppressSizeToFit: false,
      },
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnSearch();
  }
  fnInit = () => {
    this.setState({ moveOutList: [] }, function () {
      this.fnSearch();
    });
  };
  fnSearch = async () => {
    await this.setState({ moveOutListView: false });

    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.updateId = SessionUtil.getUserId();
    //data.receiveNo = '';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/selectHhMoveOutList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        //console.log('selectHhChickinList', response.data);
        that.setState(
          {
            moveOutListView: true,
            hhChickinList: response.data.rows1,
            moveOutList: response.data.rows2,
          },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  //Flock List click
  //넣을수 없는 계사 = 다른 계군이 들어있는 계사는 회색
  //넣을수 있는 계사 = 같은 계군이 들어있는 계사, 비어있는 계사 는 In
  //<Trans>Chick in HH</Trans>
  //hhFlockList - Chick in HH click
  fnClickFlock = (row) => {
    this.setState(
      {
        hhMoveView: false,
        hhInView: true,
        flockListFlockId: row.flockId,
        hhFlockRow: row,
        hhId: '', //Flcok clcik
      },
      function () {}
    );
  };
  //hhFlockList - Cancel click
  fnClickFlockCancel = (row) => {
    this.setState(
      { hhMoveView: true, hhInView: false, hhFlockId: '' },
      function () {}
    );
  };
  fnClickCancel = (row) => {
    this.setState({ hhFlockId: '' }, function () {});
  };
  fnClosePopFlock = (e, data) => {
    this.setState({ [data]: false }, function () {});
  };
  fnClosePop = (data) => {
    this.setState({ [data]: false }, function () {});
  };
  fnClickMoveIn = (row, hhId, hhFlockRow) => {
    //if (hhId === '') {
    //  //Flock click - In click
    //  this.setState({ popIn: true, popInRow: row }, function() {});
    //} else {
    //Move click - In click
    row.fromHhId = hhFlockRow.hhId;
    row.fromFlockId = hhFlockRow.flockId;
    row.fromChickinDate = hhFlockRow.chickinDate;
    row.fromChickinQtyF = hhFlockRow.chickinQtyF;
    row.fromChickinQtyM = hhFlockRow.chickinQtyM;
    row.fromCurrentQtyF = hhFlockRow.currentQtyF;
    row.fromCurrentQtyM = hhFlockRow.currentQtyM;
    row.fromCapacity = hhFlockRow.capacity;
    row.moveDate = DateUtil.today();
    console.log('fnClickMoveIn', row);
    this.setState({ popMoveOutView: true, popMoveRow: row }, function () {});
    //}
  };
  fnClickIn = (row, hhFlockRow) => {
    row.fromHhId = hhFlockRow.hhId;
    row.fromFlockId = hhFlockRow.flockId;
    row.fromChickinDate = hhFlockRow.chickinDate;
    row.fromChickinQtyF = hhFlockRow.chickinQtyF;
    row.fromChickinQtyM = hhFlockRow.chickinQtyM;
    row.fromCapacity = hhFlockRow.capacity;
    //row.chickinQtyF = 0;
    //row.chickinQtyM = 0;
    console.log('fnClickIn', this.state);
    this.setState({ popIn: true, popInRow: row }, function () {});
    //}
  };
  //
  //MoveOut Popup
  fnClickMoveOut = (row) => {
    console.log('fnClickMoveOut', row);
    row.moveDate = DateUtil.today();
    row.outQtyF = 0;
    row.outQtyM = 0;
    this.setState(
      {
        popMoveOutView: true,
        hhId: row.hhId, //Hen House Move click
        hhFlockId: row.flockId,
        popMoveRow: row,
      },
      function () {}
    );
  };
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  onCellClicked = (e) => {
    if (e.column.colId !== 'iuFlag') return false;
    if (e.data.seqNo === 0) return false;
    //삭제
    let that = this;

    console.log('delete', e.data);
    confirm({
      title: i18n.t('Are you sure delete this task?'),
      //icon: <ExclamationCircleOutlined />,
      //content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        that.fnDelete(e.data);
      },
      onCancel() {},
    });
  };
  fnDelete = (data) => {
    let that = this;

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/deleteMoveout.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.fnSearch();
        that.props.alert.show(i18n.t('Deleted.'));
        //that.setState({ breedRecvListView: false }, function () {
        //});
      })
      .catch(function (error) {
        return [];
      });
  };
  render() {
    return (
      <Fragment>
        <h2>
          <Trans>Move Out</Trans>
        </h2>

        <div className="con_box">
          <h3 className="">
            <Trans>Hen House</Trans>
          </h3>
          <div className="board_list">
            <HhMoveoutList
              hhMoveView={this.state.hhMoveView}
              hhChickinList={this.state.hhChickinList}
              hhFlockId={this.state.hhFlockId}
              hhId={this.state.hhId}
              hhFlockRow={this.state.hhFlockRow}
              fnClickMoveOut={this.fnClickMoveOut}
              fnClickCancel={this.fnClickCancel}
            />
          </div>

          <h3 className="mT20">
            <Trans>Move Out List</Trans>
          </h3>

          {this.state.moveOutListView ? (
            <MoveoutListGrid
              colDef={this.state.columnDefs}
              moveOutList={this.state.moveOutList}
              fnInit={this.fnSearch}
            />
          ) : null}
        </div>
        <PopMoveOut
          popMoveOutView={this.state.popMoveOutView}
          popMoveRow={this.state.popMoveRow}
          fnSearch={this.fnSearch}
          fnClosePop={this.fnClosePop}
        />
      </Fragment>
    );
  }
}
export default withAlert()(MoveOut);
