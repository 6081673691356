import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { DateUtil } from 'utils';

import '../Breeding.module.css';
/*
 ** breeding/receive/HenHouseList
 ** 계사(HenHouse-HH)
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class HenHouseList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      prop: {},
      rowData: [],
      taskList: ''
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  //Hen House Move click
  fnClickMove = row => {
    this.props.fnClickMove(row);
  };
  fnClickCancel = row => {
    this.props.fnClickCancel(row);
  };
  fnClickEmpty = row => {};
  fnClickMoveIn = (row, tp) => {
    //console.log('fnClickIn', row);
    if (tp === 'E') {
      //EMPTY
      row.flockInQtyF = 0;
      row.flockInQtyM = 0;
      //row.chickinQtyF = 0;
      //row.chickinQtyM = 0;
      row.currentQtyF = 0;
      row.currentQtyM = 0;
    }

    this.props.fnClickMoveIn(row, this.props.hhId, this.props.hhFlockRow);
  };
  //Write button Click
  fnClickWrite = row => {
    let data = {};
    data = row;
    data.populationF = data.flockInQtyF;
    data.populationM = data.flockInQtyM;
    data.stockF = data.flockInQtyF;
    data.stockM = data.flockInQtyM;

    this.props.fnClickWrite(data);
  };
  //View button Click
  fnClickView = row => {
    row.view = true;
    this.fnClickModify(row);
  };
  //Modify button Click
  fnClickModify = row => {
    let data = {};
    data = row;
    data.stockF =
      data.flockInQtyF + data.closeQtyF + data.cullingQtyF + data.deathQtyF;
    //data.seF +
    //data.bwF +
    //data.uniformityF

    data.stockM =
      data.flockInQtyM + data.closeQtyM + data.cullingQtyM + data.deathQtyM;
    //data.seM +
    //data.bwM +
    //data.uniformityM

    data.populationF = data.flockInQtyF;
    data.populationM = data.flockInQtyM;

    this.props.fnClickModify(data);
  };
  fnClickCancel = row => {
    this.props.fnClickCancel(row);
  };
  hhButton = row => {
    //console.log('hhButton', this.props, row, row.capa);
    //육성일자를 기재하지 않은 계사는 "Write" 버튼
    //육성일자를 기재한 계사는 "Repair" 버튼
    //비어있는 계사는 제외
    //console.log(this.props.workDate, DateUtil.today());

    let toDate = moment(DateUtil.today())
      .add('-6', 'days')
      .format('YYYY-MM-DD');
    if (
      this.props.workDate < toDate ||
      this.props.workDate > DateUtil.today()
    ) {
      if (row.workDate) {
        return (
          <dd>
            <button className="btn_gray2" onClick={e => this.fnClickView(row)}>
              <Trans>View</Trans>
            </button>
          </dd>
        );
      }
    } else {
      //if (this.props.workDate !== DateUtil.today()) return;

      if (row.iuFlag === 'I' || row.iuFlag === 'U') {
        return (
          <dd>
            <button
              className="btn_gray2"
              onClick={e => this.fnClickCancel(row)}
            >
              <Trans>Cancel</Trans>
            </button>
          </dd>
        );
      } else if (this.props.growingView) {
        return null;
      } else {
        if (row.workDate) {
          return (
            <dd>
              <button
                className="btn_gray2"
                onClick={e => this.fnClickModify(row)}
              >
                <Trans>Modify</Trans>
              </button>
            </dd>
          );
        } else {
          return (
            <dd>
              <button
                className="btn_blue"
                onClick={e => this.fnClickWrite(row)}
              >
                <Trans>Write</Trans>
              </button>
            </dd>
          );
        }
      }
    }
  };
  onMouseOver = row => {
    let that = this;
    let data = row;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/growing/selectTcTaskList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        //console.log('selectTcTaskList', response.data.rows1[0].feedCode1);

        let _taskList = response.data.rows1.map((row, i) => {
          let taskList = '';
          if (row.feedCode1)
            taskList += row.sex + ' - ' + row.feedCode1 + '<br>';
          if (row.feedCode2)
            taskList += row.sex + ' - ' + row.feedCode2 + '<br>';
          if (row.feedCode3)
            taskList += row.sex + ' - ' + row.feedCode3 + '<br>';
          if (row.vaccineCode1)
            taskList += row.sex + ' - ' + row.vaccineCode1 + '<br>';
          if (row.vaccineCode2)
            taskList += row.sex + ' - ' + row.vaccineCode2 + '<br>';
          if (row.vaccineCode3)
            taskList += row.sex + ' - ' + row.vaccineCode3 + '<br>';
          if (row.medicineCode1)
            taskList += row.sex + ' - ' + row.medicineCode1 + '<br>';
          if (row.medicineCode2)
            taskList += row.sex + ' - ' + row.medicineCode2 + '<br>';
          if (row.medicineCode3)
            taskList += row.sex + ' - ' + row.medicineCode3 + '<br>';

          return taskList;
        });
        that.setState({ taskList: _taskList });
      })
      .catch(function(error) {
        return [];
      });
  };
  handleMouseLeave = e => {
    this.setState({ taskList: null });
  };
  hhEmptyButton = row => {
    if (this.props.hhFlockId) {
      return (
        <dd>
          <span className="green">
            <Trans>Empty</Trans>
          </span>
        </dd>
      );
    } else {
      return null;
    }
  };
  render() {
    let that = this;
    let hhChickinList = [];
    let cc = 0;
    let cc10 = 0;
    const ccClass = 'full_01 h';
    const ccClass100 = 'full_03 h100';
    if (this.props.hhChickinList && this.props.hhChickinList.length > 0) {
      hhChickinList = this.props.hhChickinList.map((row, i) => {
        if (that.props.hhFlockRow) {
          row.flockInQtyF = that.props.hhFlockRow.flockInQtyF;
          row.flockInQtyM = that.props.hhFlockRow.flockInQtyM;
        }
        row.propsHhId = that.props.hhId;
        if (row.flockId) {
          cc = parseInt(
            ((row.chickinQtyF + row.chickinQtyM) / row.capacity) * 100,
            10
          );
          cc10 = parseInt(cc / 10, 10) * 10;
          row.capa = row.capacity - (row.chickinQtyF + row.chickinQtyM);
          //console.log('HenHouseList', row);
          return (
            <div key={i} className="full">
              <dl className="reinfo">
                <dt> {row.hhId} </dt>
                <dd className="f_left mL10">{row.flockId}</dd>
                <dd className="f_right mR10">{row.chickinDate}</dd>
                <dd>&nbsp; </dd>

                <dd className="txt_left mL10">
                  <Trans>Age</Trans>
                  {' : '}
                  {row.days ? row.days.toLocaleString() : '0'}
                  <Trans> Days</Trans>
                  {' / '}
                  {row.weeks ? row.weeks.toLocaleString() : '0'}
                  <Trans> Weeks</Trans>
                </dd>
                <dd className="txt_left mL10">
                  <Trans>Birds</Trans>
                  {' ('}
                  <Trans>Current</Trans> / <Trans>Chick-In</Trans> ){' '}
                </dd>
                <dd className="txt_left mL10">
                  <Trans>F</Trans> :{' '}
                  {row.flockInQtyF ? row.flockInQtyF.toLocaleString() : '0'} /{' '}
                  {row.chickinQtyF ? row.chickinQtyF.toLocaleString() : '0'}
                </dd>
                <dd className="txt_left mL10">
                  <Trans>M</Trans> :{' '}
                  {row.flockInQtyM ? row.flockInQtyM.toLocaleString() : '0'} /{' '}
                  {row.chickinQtyM ? row.chickinQtyM.toLocaleString() : '0'}
                </dd>
                {row.workDate ? (
                  <dd>
                    <Trans>Feed</Trans>{' '}
                    {row.feedSum ? row.feedSum.toLocaleString() : 0}
                  </dd>
                ) : null}
                {row.workDate ? (
                  <dd>
                    <Trans>Culling</Trans>{' '}
                    {row.cullingQtyF ? row.cullingQtyF.toLocaleString() : 0}
                    {' - '}
                    {row.cullingQtyM ? row.cullingQtyM.toLocaleString() : 0}
                  </dd>
                ) : null}
                {row.workDate ? (
                  <dd>
                    <Trans>Death</Trans>{' '}
                    {row.deathQtyF ? row.deathQtyF.toLocaleString() : 0}
                    {' - '}
                    {row.deathQtyM ? row.deathQtyM.toLocaleString() : 0}
                  </dd>
                ) : null}
                {row.taskCnt > 0 ? (
                  <dd
                    className="egg_tooltip"
                    // onClick={e => this.fnClickInfo(row)}
                  >
                    <i
                      className="mdi mdi-tooltip-text"
                      onMouseOver={e => this.onMouseOver(row)}
                      onMouseLeave={e => this.handleMouseLeave(e)}
                    ></i>
                    {this.state.taskList ? (
                      <div className="egg_tooltiptext">
                        <Trans>{this.state.taskList}</Trans>
                      </div>
                    ) : null}
                  </dd>
                ) : null}

                {/* 병아리가 들어있는 계사는 Move 버튼 활성화 */}
                {that.hhButton(row)}
              </dl>
              {/* <div
                className={cc10 === 100 ? ccClass100 : ccClass + cc10}
                title={cc}
              ></div> */}
            </div>
          );
        } else {
          return (
            <div key={i} className="full">
              <dl className="reinfo">
                <dt>
                  <Trans>{row.hhId}</Trans>
                </dt>
                <dd>
                  <span className="green">
                    <Trans>Empty</Trans>
                  </span>
                </dd>
                {/* {this.hhEmptyButton(row)} */}
              </dl>
            </div>
          );
        }
      });
    }
    return (
      <Fragment>
        {/* Hen House */}
        {hhChickinList}
      </Fragment>
    );
  }
}
export default HenHouseList;
