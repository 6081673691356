import React from 'react';

export default class StatusBar extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return <div className="ag-status-name-value">{children}</div>;
  }
}
