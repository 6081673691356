import React, { Fragment } from 'react';
import { SessionUtil, PopSearchCust, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { Input, Select, Radio } from 'antd';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import NumericEditor from '../numericEditor_copy.jsx';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';
const { Search } = Input;

/*
 **
 **
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class EggSalesForm extends React.Component {
  constructor(props) {
    super(props);
    this.deliverDates = [];
    this.minDeliverDate = undefined;
    this.state = {
      currentInstance: this,
      salesListView: true,
      epDate: DateUtil.today(),
      form: [{ transferType: 'S' }],
      customerList: [],
      customerCodeFocus: false,
      eggGoodRowData: [],
      eggGoodColumnDefs: [],
      culleggGoodRowData: [],
      culleggGoodColumnDefs: [],
      defaultColDef: {
        editable: false,
        width: 100,
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        suppressSizeToFit: true,
      },
      frameworkComponents: {
        numericEditor: NumericEditor,
      },
      editType: 'fullRow',
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    //필수 항목 setFocus를 위한 정의
    this.transferDate = React.createRef();
    this.cullingRelease = React.createRef();
    this.closingRelease = React.createRef();
  }
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps.salesForm !== this.props.salesForm) {
  //     this.setState({ form: nextProps.salesForm }, function() {});
  //   }
  // }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextProps.salesFormView !== this.props.salesFormView) {
  //     this.fnSearch();
  //     this.setState({ form: nextProps.salesForm }, function() {
  //       console.log('#######', this.state.form);
  //     });

  //     return true;
  //   } else if (nextState !== this.state) {
  //     return true;
  //   } else return false;
  // }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.salesFormView !== nextProps.salesFormView) {
      prevState.currentInstance.fnSearch();
      return {
        form: nextProps.salesForm,
        salesFormView: nextProps.salesFormView,
      };
    }
    return null;
  }

  fnSearch = () => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    //data.receiveNo = '';
    const url = Server.getRestAPI();
    //거래처 리스트 불러오기
    //this.selectCustomerList("'11','12','13','14'");

    axios({
      method: 'post',
      url: url + '/breed/release/selectEggReleaseSales.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log(response);
        let eggGoodColumnDefs = [
          {
            headerName: 'Flock',
            field: 'flockId',
            width: 120,
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: 'Hen House',
            field: 'hhId',
            width: 100,
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: 'Strain',
            field: 'strainName',
            width: 150,
            cellStyle: { textAlign: 'center' },
          },
        ];

        if (response.data.hhList) {
          let hhList = response.data.hhList.split(',');

          that.setState({
            form: that.props.salesForm,
          });

          // const epDate = response.data.rows1.reduce(
          //   (entryMap, e) =>
          //     entryMap.set(e.epDate, [...(entryMap.get(e.epDate) || []), e]),
          //   new Map()
          // );
          // let epDateList = [];
          // Array.from(epDate.entries()).forEach(row => {
          //   epDateList.push(row[0]);
          // });

          // epDateList.sort().forEach(row => {
          //   console.log(row);
          // });
          hhList.forEach((row) => {
            that.deliverDates.push(row);
            // console.log(row);
            let field = row; //.split(' ');
            let headDef = {};
            let childrenDef1 = {};
            let childrenDef2 = {};
            let childrenDef = [];

            childrenDef1.headerName = 'Qty';
            childrenDef1.field = field;
            childrenDef1.type = 'numericColumn';
            (childrenDef1.valueFormatter = function (params) {
              return GridUtil.formatNumber(params);
            }),
              (childrenDef1.cellStyle = function (params) {
                return GridUtil.getEditCell('D', 100);
              });
            childrenDef1.valueGetter = function (params) {
              return Number(params.data[field] ? params.data[field] : 0);
            };
            childrenDef2.headerName = 'Sales';
            childrenDef2.field = field + 'SaleQty';
            childrenDef2.type = 'numericColumn';

            childrenDef2.cellEditor = 'numericEditor';
            childrenDef2.cellEditorParams = function () {
              return {
                handleChange: (e) => that.fnGridHandleChange(e, 'EggGoodList'),
              };
            };
            childrenDef2.editable = function (params) {
              if (!params.data[field]) return false;
              else return true;
            };
            //          childrenDef2.cellEditor = 'numericEditor';
            (childrenDef2.valueFormatter = function (params) {
              return GridUtil.formatNumber(params);
            }),
              (childrenDef2.cellStyle = function (params) {
                if (!params.data[field]) return GridUtil.getEditCell('P', 100);
                else return GridUtil.getEditCell('E', 100);
              });
            childrenDef2.valueGetter = function (params) {
              return Number(
                params.data[field + 'SaleQty']
                  ? params.data[field + 'SaleQty']
                  : 0
              );
            };

            headDef.headerName = field.replace(/'/gi, '');
            headDef.children = [childrenDef1, childrenDef2];
            eggGoodColumnDefs.push(headDef);
          });
        }

        let _culleggGoodRowData = response.data.rows2
          ? response.data.rows2
          : [];
        _culleggGoodRowData.forEach((row) => {
          // console.log(row.epDate);
          that.deliverDates.push(row.epDate);
        });
        that.minDeliverDate = _.min(that.deliverDates);
        console.log('minDeliverDate => ' + that.minDeliverDate);

        that.setState(
          {
            eggGoodColumnDefs: eggGoodColumnDefs,
            eggGoodRowData: response.data.rows1 ? response.data.rows1 : [],
            culleggGoodRowData: response.data.rows2 ? response.data.rows2 : [],
            hhList: response.data.hhList,
            culleggGoodColumnDefs: that.fnCulleggGoodColumnDefs(),
          },
          function () {
            console.log(that.state);
          }
        );

        // let hhHeadList = [];

        // let hhList = response.data.hhList.split(',');
        // hhList.forEach(row => {
        //   //let field = row.split(' ');
        //   hhHeadList.push(row);
        // });

        // that.setState(
        //   {
        //     eggGoodRowData: response.data.rows1,
        //     culleggGoodRowData: response.data.rows2,
        //     hhHeadList: hhHeadList
        //     //hatcheryList: response.data.hatcheryList
        //   },
        //   function() {
        //     console.log('that_state', that.state);
        //   }
        // );
      })
      .catch(function (error) {
        return [];
      });
  };
  fnCulleggGoodColumnDefs = () => {
    let that = this;
    let culleggGoodColumnDefs = [
      {
        headerName: 'Ep Date',
        field: 'epDate',
        width: 100,
        cellStyle: { textAlign: 'center' },
      },
    ];
    let hhList = [
      'Crack,crack',
      'Dirty,dirty',
      'Jumbo,jumbo',
      'Small,small',
      'Thin,thin',
      'MissShape,missShape',
    ];

    hhList.forEach((row) => {
      let field = row.split(',');
      let headDef = {};
      let childrenDef1 = {};
      let childrenDef2 = {};
      let childrenDef = [];

      childrenDef1.headerName = 'Qty';
      childrenDef1.field = field[1] + 'EggQty';
      childrenDef1.type = 'numericColumn';
      (childrenDef1.valueFormatter = function (params) {
        return GridUtil.formatNumber(params);
      }),
        (childrenDef1.cellStyle = function (params) {
          return GridUtil.getEditCell('D', 100);
        });
      childrenDef2.headerName = 'Sales'; //            field: 'crackEggSaleQty',
      childrenDef2.field = field[1] + 'EggSaleQty';
      childrenDef2.type = 'numericColumn';
      childrenDef2.cellEditor = 'numericEditor';
      childrenDef2.cellEditorParams = function () {
        return {
          handleChange: (e) => that.fnGridHandleChange(e, 'CullingEggList'),
        };
      };
      childrenDef2.editable = function (params) {
        if (params.data[field[1] + 'EggQty'] === 0) return false;
        else return true;
      };
      childrenDef2.valueFormatter = function (params) {
        return GridUtil.formatNumber(params);
      };
      childrenDef2.cellStyle = function (params) {
        if (params.data[field[1] + 'EggQty'] === 0)
          return GridUtil.getEditCell('P', 100);
        else return GridUtil.getEditCell('E', 100);
      };
      headDef.headerName = field[0];
      headDef.children = [childrenDef1, childrenDef2];
      culleggGoodColumnDefs.push(headDef);
    });

    return culleggGoodColumnDefs;
  };
  /*
   * Ag-Grid key Down 수량 체크 및 합계 처리
   */
  fnGridHandleChange = (params, op) => {
    let that = this;
    if (op === 'EggGoodList') {
      let col = params.column.colId.replace('SaleQty', '');
      let qty = Number(params[col] ? params[col] : 0);
      if (Number(params[params.column.colId]) > qty) {
        that.props.alert.show(i18n.t('The quantity is exceeded.'));
        that.gridApi.stopEditing();
        var rowNode = that.gridApi.getDisplayedRowAtIndex(params.rowIndex);
        rowNode.setDataValue(params.column.colId, 0);
        //that.gridApi.tabToPreviousCell();
        that.gridApi.setFocusedCell(params.rowIndex, params.column.colId);
      } else {
        that.printAllDisplayedRows(params.column.colId, params.value); //현재입력받는 숫자도 더함
      }
    } else if (op === 'CullingEggList') {
      //onCellValueChangedCullegg
      let col = params.column.colId.replace('SaleQty', 'Qty');
      let qty = Number(params[col] ? params[col] : 0);
      if (Number(params[params.column.colId]) > qty) {
        that.props.alert.show(i18n.t('The quantity is exceeded.'));
        that.gridApiCullegg.stopEditing();
        var rowNode = that.gridApiCullegg.getDisplayedRowAtIndex(
          params.rowIndex
        );
        rowNode.setDataValue(params.column.colId, 0);
        that.gridApiCullegg.setFocusedCell(
          params.rowIndex,
          params.column.colId
        );
        //that.gridApiCullegg.tabToPreviousCell();
      } else {
        that.printAllDisplayedRowsCullegg(params.column.colId, params.value); //현재입력받는 숫자도 더함
      }
    }
  };
  //Sales 저장
  fnSave = () => {
    let that = this;
    let data = this.state.form;
    let eggGoodList = [];
    let cullEggGoodList = [];
    let hhList = that.state.hhList ? that.state.hhList.split(',') : [];

    this.gridApi.stopEditing();
    this.gridApiCullegg.stopEditing();

    if (!data.salesDate) {
      that.props.alert.show(i18n.t('Please enter Sales Date.'));

      that.salesDate.focus();
      return false;
    }

    if (!data.customerCode) {
      that.props.alert.show(i18n.t('Please enter CustomerCode.'));
      that.customerName.focus();
      return false;
    }

    let goodEggQty = 0;
    let crackEggQty = 0;
    let dirtyEggQty = 0;
    let jumboEggQty = 0;
    let smallEggQty = 0;
    let thinEggQty = 0;
    let missShapeEggQty = 0;

    this.gridApi.forEachNode(function (rowNode, index) {
      hhList.forEach((row) => {
        let field = row; //.split(' ');
        let col = field + 'SaleQty';
        if (Number(rowNode.data[col]) > 0) {
          let dataDef = {};
          dataDef.flockId = rowNode.data.flockId;
          dataDef.strainCode = rowNode.data.strainCode;
          dataDef.hhId = rowNode.data.hhId;
          dataDef.epDate = field.replace(/'/gi, '');
          dataDef.goodEggQty = Number(rowNode.data[col]);
          goodEggQty += Number(rowNode.data[col]);
          eggGoodList.push(dataDef);
        }
      });
    });

    this.gridApiCullegg.forEachNode(function (rowNode, index) {
      let crackEggSaleQty = rowNode.data.crackEggSaleQty
        ? Number(rowNode.data.crackEggSaleQty)
        : 0;
      let dirtyEggSaleQty = rowNode.data.dirtyEggSaleQty
        ? Number(rowNode.data.dirtyEggSaleQty)
        : 0;
      let jumboEggSaleQty = rowNode.data.jumboEggSaleQty
        ? Number(rowNode.data.jumboEggSaleQty)
        : 0;
      let smallEggSaleQty = rowNode.data.smallEggSaleQty
        ? Number(rowNode.data.smallEggSaleQty)
        : 0;
      let thinEggSaleQty = rowNode.data.thinEggSaleQty
        ? Number(rowNode.data.thinEggSaleQty)
        : 0;
      let missShapeEggSaleQty = rowNode.data.missShapeEggSaleQty
        ? Number(rowNode.data.missShapeEggSaleQty)
        : 0;
      if (
        crackEggSaleQty > 0 ||
        dirtyEggSaleQty > 0 ||
        jumboEggSaleQty > 0 ||
        smallEggSaleQty > 0 ||
        thinEggSaleQty > 0 ||
        missShapeEggSaleQty > 0
      ) {
        let dataDef = {};
        dataDef.epDate = rowNode.data.epDate;
        dataDef.crackEggQty = Number(crackEggSaleQty);
        dataDef.dirtyEggQty = Number(dirtyEggSaleQty);
        dataDef.jumboEggQty = Number(jumboEggSaleQty);
        dataDef.smallEggQty = Number(smallEggSaleQty);
        dataDef.thinEggQty = Number(thinEggSaleQty);
        dataDef.missShapeEggQty = Number(missShapeEggSaleQty);

        crackEggQty += Number(crackEggSaleQty);
        dirtyEggQty += Number(dirtyEggSaleQty);
        jumboEggQty += Number(jumboEggSaleQty);
        smallEggQty += Number(smallEggSaleQty);
        thinEggQty += Number(thinEggSaleQty);
        missShapeEggQty += Number(missShapeEggSaleQty);

        cullEggGoodList.push(dataDef);
      }
    });

    data.eggGoodList = eggGoodList;
    data.eggGoodLength = eggGoodList.length;
    data.cullEggGoodList = cullEggGoodList;
    data.cullEggGoodLength = cullEggGoodList.length;

    for (let i = 0; i < data.eggGoodLength; i++) {
      if (data.eggGoodList[i].epDate > data.salesDate) {
        that.props.alert.show(i18n.t('Please Check EP Date.'));
        return false;
      }
    }

    for (let i = 0; i < data.cullEggGoodLength; i++) {
      if (data.cullEggGoodList[i].epDate > data.salesDate) {
        that.props.alert.show(i18n.t('Please Check EP Date.'));
        return false;
      }
    }

    if (
      goodEggQty > 0 ||
      crackEggQty > 0 ||
      dirtyEggQty > 0 ||
      jumboEggQty > 0 ||
      smallEggQty > 0 ||
      thinEggQty > 0 ||
      missShapeEggQty > 0
    ) {
    } else {
      that.props.alert.show(i18n.t('No data entered.'));

      return false;
    }
    let goodEggWeight = data.goodEggWeight ? Number(data.goodEggWeight) : 0;
    let crackEggWeight = data.crackEggWeight ? Number(data.crackEggWeight) : 0;
    let dirtyEggWeight = data.dirtyEggWeight ? Number(data.dirtyEggWeight) : 0;
    let jumboEggWeight = data.jumboEggWeight ? Number(data.jumboEggWeight) : 0;
    let smallEggWeight = data.smallEggWeight ? Number(data.smallEggWeight) : 0;
    let thinEggWeight = data.thinEggWeight ? Number(data.thinEggWeight) : 0;
    let missShapeEggWeight = data.missShapeEggWeight
      ? Number(data.missShapeEggWeight)
      : 0;
    //TP_EGG_SALES_MAST.QTY
    data.qty =
      goodEggQty +
      crackEggQty +
      dirtyEggQty +
      jumboEggQty +
      smallEggQty +
      thinEggQty +
      missShapeEggQty;
    //TP_EGG_SALES_MAST.WEIGHT
    data.weight =
      goodEggWeight +
      crackEggWeight +
      dirtyEggWeight +
      jumboEggWeight +
      smallEggWeight +
      thinEggWeight +
      missShapeEggWeight;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.updateId = SessionUtil.getUserId();

    if (
      (goodEggQty > 0 && goodEggWeight == 0) ||
      (crackEggQty > 0 && crackEggWeight == 0) ||
      (dirtyEggQty > 0 && dirtyEggWeight == 0) ||
      (jumboEggQty > 0 && jumboEggWeight == 0) ||
      (smallEggQty > 0 && smallEggWeight == 0) ||
      (thinEggQty > 0 && thinEggWeight == 0) ||
      (missShapeEggQty > 0 && missShapeEggWeight == 0)
    ) {
      that.props.alert.show(i18n.t('Please Enter Weight.'));
      return false;
    }

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/saveEggSales.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.alert.show(i18n.t('Has been saved.'));

        that.props.fnSearch();
      })
      .catch(function (error) {
        return [];
      });
  };

  setOnChange = (e, params) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    that.setState({ form: { ...this.state.form, [col]: val } }, function () {});
  };

  //거래처 찾기 - XXXXXXXXXX
  selectCustomerList = (value) => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.customerName = '';
    data.customerKind = value;
    //customerKind="'11','12','13','14'"
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/selectCustomerList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ customerList: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };
  onChangeTransferType = (e, col) => {
    this.setState(
      { form: { ...this.state.form, [col]: e.target.value } },
      function () {
        console.log('onChangeTranferType', this.state);
      }
    );
  };

  onCellValueChanged = (params) => {
    let that = this;
    //params.data.~~~ : row 전체 data
    //params.column.colId : column 명
    //params.rowIndex : row index
    //params.oldValue: "55"
    //params.newValue: "33"
    //params.value: "33"
    //console.log(params);
    //"hh04SaleQty"
    let col = params.column.colId.replace('SaleQty', '');
    var rowNode = that.gridApi.getDisplayedRowAtIndex(params.rowIndex);
    let qty = Number(rowNode.data[col] ? rowNode.data[col] : 0);
    if (Number(params.value) > qty) {
      // that.gridApi.stopEditing();
      // rowNode.setDataValue(params.column.colId, 0);
      // //that.props.alert.show(i18n.t('The quantity is exceeded.'));
      that.gridApi.tabToPreviousCell();
      //that.gridApi.setFocusedCell(params.rowIndex, params.column.colId);
    } else {
      this.printAllDisplayedRows(params.column.colId);
    }
  };
  onCellValueChangedCullegg = (params) => {
    let that = this;
    //params.data.~~~ : row 전체 data
    //params.column.colId : column 명
    //params.rowIndex : row index
    //params.oldValue: "55"
    //params.newValue: "33"
    //params.value: "33"
    console.log('onCellValueChangedCullegg', params);
    let col = params.column.colId.replace('SaleQty', 'Qty');
    var rowNode = this.gridApiCullegg.getDisplayedRowAtIndex(params.rowIndex);
    let qty = Number(rowNode.data[col] ? rowNode.data[col] : 0);
    if (Number(params.value) > qty) {
      //rowNode.setDataValue(params.column.colId, 0);
      //that.props.alert.show(i18n.t('The quantity is exceeded.'));

      that.gridApiCullegg.tabToPreviousCell();
    } else {
      this.printAllDisplayedRowsCullegg(params.column.colId);
    }
  };
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();

    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      params.api.setRowData(data.slice(0, 100));
    };
  };
  onGridReadyCullegg = (params) => {
    this.gridApiCullegg = params.api;
    this.gridColumnApiCullegg = params.columnApi;
    params.api.sizeColumnsToFit();

    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      params.api.setRowData(data.slice(0, 100));
    };
  };
  //Egg Goog List edit
  printAllDisplayedRows = (col, val) => {
    let that = this;
    var count = this.gridApi.getDisplayedRowCount();
    let tot = 0;
    let hhList = that.state.hhList ? that.state.hhList.split(',') : [];
    for (var i = 0; i < count; i++) {
      var rowNode = this.gridApi.getDisplayedRowAtIndex(i);
      //console.log('row ' + i + ' is ' + rowNode.data.epDate);
      hhList.forEach((row) => {
        let field = row; //.split(' ');
        col = field + 'SaleQty';
        tot += Number(rowNode.data[col] ? rowNode.data[col] : 0);
      });
    }
    // if (Number(val)) {
    //   tot += Number(val);
    // }
    this.setState(
      {
        form: {
          ...this.state.form,
          ['goodEggQty']: tot,
        },
      },
      function () {}
    );
  };
  //Culling Egg List edit
  printAllDisplayedRowsCullegg = (col) => {
    var count = this.gridApiCullegg.getDisplayedRowCount();
    let qty = 0;
    let tot = 0;
    for (var i = 0; i < count; i++) {
      var rowNode = this.gridApiCullegg.getDisplayedRowAtIndex(i);
      tot += Number(rowNode.data[col] ? rowNode.data[col] : 0);
    }

    col = col.replace('EggSaleQty', '') + 'EggQty';
    this.setState(
      {
        form: {
          ...this.state.form,
          [col]: tot,
        },
      },
      function () {}
    );
  };

  onCellKeyDown = (e) => {
    var keyPressed = e.event.key;

    if (e.event.key === 'Enter') {
      this.gridApi.tabToNextCell();
      //   var currentCell = this.gridApi.getFocusedCell();
      //   var finalRowIndex = this.gridApi.paginationGetRowCount();
      //   //console.log('Key currentCell = ', currentCell);
      //   // If we are editing the last row in the grid, don't move to next line
      //   //   if (currentCell.rowIndex === finalRowIndex) {
      //   //     return;
      //   //   }
      //   this.gridApi.stopEditing();
      //   this.gridApi.clearFocusedCell();

      //   this.state.eggGoodColumnDefs.map((col, i) => {
      //     if ( currentCell.column.colId === col.)
      //     console.log('Key currentCell = ', col);
      //   });

      //   this.gridApi.startEditingCell({
      //     rowIndex: currentCell.rowIndex,
      //     colKey: currentCell.column.colId + 1
      //   });
    } else {
    }
  };
  onCellKeyPress = (e) => {
    console.log('onCellKeyPress', e);
    var keyPressed = e.event.key;
    console.log('Key Pressed = ' + keyPressed);
  };
  fnSearchCust = (value) => {
    this.setState(
      {
        popSearchCustView: true,
        customerCode: value,
        customerKind: [
          '11',
          '12',
          '13',
          '14',
          '21',
          '31',
          '41',
          '51',
          '98',
          '99',
        ],
      },
      function () {}
    );
  };
  fnSelectCust = (data) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          customerCode: data.customerCode,
          customerName: data.customerName,
        },
      },
      function () {}
    );
  };
  fnClosePop = (data) => {
    this.setState({ [data]: false }, function () {});
  };
  setOnChangeCust = (e) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          customerCode: e.target.value,
          customerName: '',
        },
      },
      function () {}
    );
  };
  setDisabledDate = (current) => {
    return current < moment(this.minDeliverDate);
  };
  render() {
    if (!this.props.salesFormView) return null;
    const data = this.state.form;
    const Option = Select.Option;
    const customerOptions = this.state.customerList.map((d, i) => (
      <Option key={i} value={d.customerCode}>
        {d.customerName}
      </Option>
    ));

    return (
      <Fragment>
        <h3>
          <Trans>Sales Info</Trans>
        </h3>
        <div className="board_list2">
          <table>
            <colgroup>
              <col style={{ width: '20%' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '20%' }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <Trans>Date</Trans> <span className="red">*</span>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'salesDate', //*** */
                    type: 'D', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    placeholder: 'salesDate',
                    //disabledDate: this.setDisabledDate,
                  })}
                </td>
                <th>
                  <Trans>Order No</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'sOrderNo', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 200, // px제외 : 없으면 100% */
                    maxLength: 20,
                    //placeholder: 'Date',
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Customer</Trans> <span className="red">*</span>
                </th>
                <td>
                  <Search
                    placeholder="Customer Code"
                    onSearch={(value) => this.fnSearchCust(value)}
                    enterButton
                    className="w100"
                    //style={{ width: '300px' }}
                    ref={(input) => {
                      this['customerCode'] = input;
                    }}
                    value={this.state.form.customerCode}
                    onChange={(value) => this.setOnChangeCust(value)}
                  />
                </td>
                <th>
                  <Trans>Truck No</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'truckNo', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 200, // px제외 : 없으면 100% */
                    maxLength: 10,
                    //placeholder: 'Date',
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Type</Trans> <span className="red">*</span>
                </th>
                <td className="spac50_right">
                  <Radio.Group
                    onChange={(e) =>
                      this.onChangeTransferType(e, 'transferType')
                    }
                    value={this.state.form.transferType}
                  >
                    <Radio value={'S'}>Sales</Radio>
                    <Radio value={'D'}>Donation</Radio>
                  </Radio.Group>
                </td>
                <th>
                  <Trans>Remarks</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'remarks', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    //width: 120, // px제외 : 없으면 100% */
                    maxLength: 1000,
                    //placeholder: 'Date',
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 className="mT20">
          <Trans>Good Egg List</Trans>
        </h3>
        <div
          className="board_list ag-theme-balham"
          style={{ height: '300px', width: '100%' }}
        >
          <AgGridReact
            columnDefs={this.state.eggGoodColumnDefs}
            rowData={
              this.state.eggGoodRowData.length > 0
                ? this.state.eggGoodRowData
                : []
            }
            defaultColDef={this.state.defaultColDef}
            enterMovesDownAfterEdit={true}
            //enterMovesDown={true}
            frameworkComponents={this.state.frameworkComponents}
            //editType={this.state.editType}
            //onRowClicked={e => this.onRowClicked(e)}
            onCellValueChanged={(e) => this.onCellValueChanged(e)}
            //onCellClicked={e => this.onCellClicked(e)}
            onGridReady={this.onGridReady}
            getRowHeight={(e) => GridUtil.getRowHeight(e)}
            //onCellKeyDown={e => this.onCellKeyDown(e)}
            //onCellKeyPress={e => this.onCellKeyPress(e)}
            suppressCellSelection={true}
            singleClickEdit={true}
          ></AgGridReact>
        </div>
        <h3 style={{ marginTop: '20px' }}>
          <Trans>Culling Egg List</Trans>
        </h3>
        <div
          className="board_list ag-theme-balham"
          style={{ height: '300px', width: '100%' }}
        >
          <AgGridReact
            columnDefs={this.state.culleggGoodColumnDefs}
            rowData={
              this.state.culleggGoodRowData.length > 0
                ? this.state.culleggGoodRowData
                : []
            }
            defaultColDef={this.state.defaultColDef}
            enterMovesDownAfterEdit={true}
            //enterMovesDown={true}
            frameworkComponents={this.state.frameworkComponents}
            //editType={this.state.editType}
            //onRowClicked={e => this.onRowClicked(e)}
            onCellValueChanged={(e) => this.onCellValueChangedCullegg(e)}
            //onCellClicked={e => this.onCellClicked(e)}
            onGridReady={this.onGridReadyCullegg}
            getRowHeight={(e) => GridUtil.getRowHeight(e)}
            suppressCellSelection={true}
            singleClickEdit={true}
          ></AgGridReact>
        </div>

        <div className="board_list2 mT20">
          <span className="f_right mR10">Weight : Kg</span>
          <table>
            <colgroup>
              <col style={{ width: '6%' }} />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th colSpan="2">
                  <Trans>Crack</Trans>
                </th>
                <th colSpan="2">
                  <Trans>Dirty</Trans>
                </th>
                <th colSpan="2">
                  <Trans>Jumbo</Trans>
                </th>
                <th colSpan="2">
                  <Trans>Small</Trans>
                </th>
                <th colSpan="2">
                  <Trans>Thin</Trans>
                </th>
                <th colSpan="2">
                  <Trans>MissShape</Trans>
                </th>
                <th colSpan="2">
                  <Trans>HE</Trans>
                </th>
              </tr>
              <tr>
                <th>
                  <Trans>Qty</Trans>
                </th>
                <th>
                  <Trans>Weight</Trans>
                </th>
                <th>
                  <Trans>Qty</Trans>
                </th>
                <th>
                  <Trans>Weight</Trans>
                </th>
                <th>
                  <Trans>Qty</Trans>
                </th>
                <th>
                  <Trans>Weight</Trans>
                </th>
                <th>
                  <Trans>Qty</Trans>
                </th>
                <th>
                  <Trans>Weight</Trans>
                </th>
                <th>
                  <Trans>Qty</Trans>
                </th>
                <th>
                  <Trans>Weight</Trans>
                </th>
                <th>
                  <Trans>Qty</Trans>
                </th>
                <th>
                  <Trans>Weight</Trans>
                </th>
                <th>
                  <Trans>Qty</Trans>
                </th>
                <th>
                  <Trans>Weight</Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: '100px' }}>
                  {GridUtil.renderInput({
                    inputName: 'crackEggQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    disabled: true,
                  })}
                </td>
                <td style={{ width: '100px' }}>
                  {GridUtil.renderInput({
                    inputName: 'crackEggWeight', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    dec: 1,
                    minVal: 0,
                    setOnChange: this.setOnChange, //*** */
                  })}
                </td>
                <td style={{ width: '100px' }}>
                  {GridUtil.renderInput({
                    inputName: 'dirtyEggQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    disabled: true,
                  })}
                </td>
                <td style={{ width: '100px' }}>
                  {GridUtil.renderInput({
                    inputName: 'dirtyEggWeight', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    dec: 1,
                    minVal: 0,
                    setOnChange: this.setOnChange, //*** */
                  })}
                </td>
                <td style={{ width: '100px' }}>
                  {GridUtil.renderInput({
                    inputName: 'jumboEggQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    disabled: true,
                  })}
                </td>
                <td style={{ width: '100px' }}>
                  {GridUtil.renderInput({
                    inputName: 'jumboEggWeight', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    dec: 1,
                    minVal: 0,
                    setOnChange: this.setOnChange, //*** */
                  })}
                </td>

                <td style={{ width: '100px' }}>
                  {GridUtil.renderInput({
                    inputName: 'smallEggQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    disabled: true,
                  })}
                </td>
                <td style={{ width: '100px' }}>
                  {GridUtil.renderInput({
                    inputName: 'smallEggWeight', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    dec: 1,
                    minVal: 0,
                    setOnChange: this.setOnChange, //*** */
                  })}
                </td>

                <td style={{ width: '100px' }}>
                  {GridUtil.renderInput({
                    inputName: 'thinEggQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    disabled: true,
                  })}
                </td>
                <td style={{ width: '100px' }}>
                  {GridUtil.renderInput({
                    inputName: 'thinEggWeight', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    dec: 1,
                    minVal: 0,
                    setOnChange: this.setOnChange, //*** */
                  })}
                </td>

                <td style={{ width: '100px' }}>
                  {GridUtil.renderInput({
                    inputName: 'missShapeEggQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    disabled: true,
                  })}
                </td>
                <td style={{ width: '100px' }}>
                  {GridUtil.renderInput({
                    inputName: 'missShapeEggWeight', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    dec: 1,
                    minVal: 0,
                    setOnChange: this.setOnChange, //*** */
                  })}
                </td>
                <td style={{ width: '100px' }}>
                  {GridUtil.renderInput({
                    inputName: 'goodEggQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    disabled: true,
                  })}
                </td>
                <td style={{ width: '100px' }}>
                  {GridUtil.renderInput({
                    inputName: 'goodEggWeight', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    dec: 1,
                    minVal: 0,
                    setOnChange: this.setOnChange, //*** */
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="txt_center mT10">
          <button className="btn_gray" onClick={() => this.fnSearch()}>
            <Trans>Cancel</Trans>
          </button>
          <button className="btn_save" onClick={() => this.fnSave()}>
            <Trans>save</Trans>
          </button>
        </div>
        {this.state.popSearchCustView ? (
          <PopSearchCust
            popSearchCustView={this.state.popSearchCustView}
            customerCode={this.state.customerCode}
            customerKind={this.state.customerKind}
            fnClosePop={this.fnClosePop}
            fnSelectCust={this.fnSelectCust}
          />
        ) : null}
      </Fragment>
    );
  }
}
export default withAlert()(EggSalesForm);
