import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, FormUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import { Radio } from 'antd';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { message, Select } from 'antd';
import CountryCode from 'containers/pages/common/system/CountryCode';

// import _ from 'lodash';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class CodeInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      gridHeight: '365px',
      defaultColDef: {
        filter: true,
        resizable: true,
        editable: false,
        sortable: true
        // suppressSizeToFit: true
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      paginationPageSize: 10,
      // editType: 'fullRow', // selectEditor와 함께 사용하지 말라고 하네요...

      mastSelected: false, // 마스터 코드가 선택되어야지만, 디테일 등록이 가능
      editingMast: false, // MasterCode InputBox Show
      editingDtl: false, // DtlCode InputBox Show
      countryCodes: [],
      codeMast: [],
      codeDtl: [],
      MastColDefs: [
        {
          headerName: i18n.t('Country Code'),
          field: 'countryCode',
          hide: true,
          getQuickFilterText: function(params) {
            return params.value;
          }
        },
        {
          headerName: i18n.t('Country Name'),
          field: 'countryName'
        },
        {
          headerName: i18n.t('Code Id'),
          field: 'codeId'
        },
        {
          headerName: i18n.t('Code Name'),
          field: 'codeName',
          editable: true,
          cellStyle: function(params) {
            const w = params.column.actualWidth;
            return FormUtil.getEditCell('E', w);
          }
        },
        {
          headerName: i18n.t('Remarks'),
          field: 'remarks',
          editable: true,
          cellStyle: function(params) {
            const w = params.column.actualWidth;
            return FormUtil.getEditCell('E', w);
          }
        }
      ],
      DtlColDefs: [
        {
          headerName: i18n.t('Country Code'),
          field: 'countryCode',
          editable: false,
          hide: true,
          getQuickFilterText: function(params) {
            return params.value.name;
          }
        },
        {
          headerName: i18n.t('Code Id'),
          field: 'codeId',
          editable: false,
          hide: true
        },
        {
          headerName: i18n.t('Value'),
          field: 'codeValue',
          editable: false
        },
        {
          headerName: i18n.t('Full Text'),
          field: 'codeText',
          editable: true,
          cellStyle: function(params) {
            const w = params.column.actualWidth;
            return FormUtil.getEditCell('E', w);
          }
        },
        {
          headerName: i18n.t('Short Text'),
          field: 'codeStext',
          editable: true,
          cellStyle: function(params) {
            const w = params.column.actualWidth;
            return FormUtil.getEditCell('E', w);
          }
        }
      ],
      paramsMast: {
        // Master 등록시 전달할 데이타
        countryCode: '',
        codeId: '',
        codeName: '',
        remarks: ''
      },
      paramsDtl: {
        // Detail 등록시 전달할 데이타
        countryCode: '',
        codeId: '',
        codeValue: '',
        codeText: '',
        codeStext: '',
        remarks: ''
      }
    };
  }

  onGridReadyMast = params => {
    this.gridApiMast = params.api;
    this.gridColumnApiMast = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  onGridReadyDtl = params => {
    this.gridApiDtl = params.api;
    this.gridColumnApiDtl = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnGetRowDataCountryCode();
    this.fnGetRowDataMast();
  }

  // 국가 코드 정보 가져오기
  fnGetRowDataCountryCode = params => {
    const that = this;
    let url = Server.getRestAPI() + '/system/selectCountryCode.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        const { data } = response;
        const countryCodes = [];
        if (data) {
          data.map((row, i) => {
            const obj = {
              codeValue: row.codeValue,
              codeText: row.codeText
            };
            countryCodes.push(obj);
          });
          that.setState({ countryCodes: countryCodes });
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  // 마스터 코드 정보 가져오기
  fnGetRowDataMast = params => {
    const that = this;
    let url = Server.getRestAPI() + '/system/selectTcCodeMast.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        const { data } = response;
        if (data) {
          that.setState({ codeMast: data });
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  // 디테일 코드 정보 가져오기
  fnGetRowDataDtl = params => {
    const that = this;
    let url = Server.getRestAPI() + '/system/selectTcCodeDtl.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        const { data } = response;
        if (data) {
          that.setState({ codeDtl: data });
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  fnClearParamsMast = () => {
    this.setState(
      produce(draft => {
        draft.paramsMast['countryCode'] = '';
        draft.paramsMast['codeId'] = '';
        draft.paramsMast['codeName'] = '';
        draft.paramsMast['remarks'] = '';
      }),
      function() {}
    );
  };

  fnClearParamsDtl = () => {
    this.setState(
      produce(draft => {
        // draft.paramsDtl['countryCode'] = ''; // 삭제해서는 않됩니다.
        // draft.paramsDtl['codeId'] = ''; // 삭제해서는 않됩니다.
        draft.paramsDtl['codeValue'] = '';
        draft.paramsDtl['codeText'] = '';
        draft.paramsDtl['codeStext'] = '';
      }),
      function() {}
    );
  };

  fnDeleteRowDataMast = params => {
    const url = Server.getRestAPI() + '/system/deleteTcCodeMast.do';
    // ---------------------------------------------------------
    // (중요) await와 then 은 함께 사용해서는 안됩니다.
    // ---------------------------------------------------------
    return axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    });
  };

  fnDeleteRowDataDtl = params => {
    const url = Server.getRestAPI() + '/system/deleteTcCodeDtl.do';
    // ---------------------------------------------------------
    // (중요) await와 then 은 함께 사용해서는 안됩니다.
    // ---------------------------------------------------------
    return axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    });
  };

  // Master 파라미터 변경시
  onChangeParamMast = e => {
    const { name, value } = e.target;
    this.setState(
      produce(draft => {
        draft.paramsMast[name] =
          name === 'codeId' ? value.toUpperCase() : value;
      })
    );
  };

  // Detail 파라미터 변경시
  onChangeParamDtl = e => {
    const { name, value } = e.target;
    this.setState(
      produce(draft => {
        draft.paramsDtl[name] =
          name === 'codeValue' ? value.toUpperCase() : value;
      })
    );
  };

  // Master의 Row가 선택되어졌을 경우
  onRowSelectedMast = params => {
    if (!isRowSelected(params)) return;

    const that = this;
    console.log('onRowSelected:', params);
    this.setState(
      { editingMast: false, mastSelected: true },
      that.setState(
        produce(draft => {
          draft.paramsDtl['countryCode'] = params.data['countryCode'];
          draft.paramsDtl['codeId'] = params.data['codeId'];
          draft.paramsDtl['codeValue'] = '';
          draft.paramsDtl['codeText'] = '';
          draft.paramsDtl['codeStext'] = '';
          draft.paramsDtl['remarks'] = '';
        }),
        function() {
          that.fnGetRowDataDtl(params.data);
          console.log('paramsDtl:', this.state.paramsDtl);
        }
      )
    );
  };

  setCodeMast = (name, value) => {
    this.setState(
      produce(draft => {
        draft.paramsMast[name] = value;
      }),
      function() {}
    );
  };

  onBtRegisterMast = () => {
    this.setState({ editingMast: true, editingDtl: false });
  };

  onBtMastSave = () => {
    console.log('paramsMast:', this.state.paramsMast);
    const that = this;
    const params = this.state.paramsMast;
    let url = Server.getRestAPI() + '/system/insertTcCodeMast.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        const { data } = response;
        console.log('data:', data);
        if (data.error) {
          return message.error(i18n.t(data.error));
        }
        if (data > 0) {
          that.fnGetRowDataMast();
          that.fnClearParamsMast();
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  onBtRegisterDtl = () => {
    this.setState({ editingMast: false, editingDtl: true });
  };

  onBtSaveDtl = () => {
    const that = this;
    const params = _.cloneDeep(this.state.paramsDtl); // cloneDeep 중요
    console.log('paramsDtl:', params);
    let url = Server.getRestAPI() + '/system/insertTcCodeDtl.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        const { data } = response;
        console.log('data:', data);
        if (data.error) {
          return message.error(i18n.t(data.error));
        }
        if (data > 0) {
          that.fnClearParamsDtl();

          params['codeValue'] = '';
          params['codeText'] = '';
          params['codeStext'] = '';
          that.fnGetRowDataDtl(params);
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  onBtCancel = () => {
    const that = this;
    this.setState({ editingMast: false, editingDtl: false }, function() {
      that.fnClearParamsMast();
    });
  };

  onBtDeleteMast = () => {
    const that = this;
    const selectedRows = this.gridApiMast.getSelectedNodes();
    if (!selectedRows || selectedRows.length === 0) {
      return message.error(i18n.t('Not selected row'));
    }

    const selectedRow = selectedRows[0];
    const { data } = selectedRow;

    let param = {};
    param['countryCode'] = data['countryCode'];
    param['codeId'] = data['codeId'];

    this.fnDeleteRowDataMast(param)
      .then(function(response) {
        const { data } = response;
        if (data > 0) {
          that.setState(
            {
              mastSelected: false,
              codeDtl: []
            },
            function() {
              that.fnGetRowDataMast();
            }
          );
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  onBtDeleteDtl = () => {
    const that = this;
    const selectedRows = this.gridApiDtl.getSelectedNodes();
    if (!selectedRows || selectedRows.length === 0) {
      return message.error(i18n.t('Not selected row'));
    }

    const selectedRow = selectedRows[0];
    const { data } = selectedRow;

    let param = {};
    param['countryCode'] = data['countryCode'];
    param['codeId'] = data['codeId'];
    param['codeValue'] = data['codeValue'];

    this.fnDeleteRowDataDtl(param)
      .then(function(response) {
        const { data } = response;
        if (data > 0) {
          param['codeValue'] = '';
          that.fnGetRowDataDtl(param);
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  onCellValueChangedMast = async params => {
    const fieldId = params.column.getId(); // 변경된 cell의 field 값이 전달된다.
    const that = this;

    // newValue의 경우 editType: 'fullRow'아닌 경우에만 의미가 있습니다.
    // fullRow 의 경우 data에 이미 변경된 값이 전달 됩니다.
    // 이 경우 data에 검증이 필요합니다. UNDO할 것 인가? APPLY할 것 인가?
    const { data, newValue } = params;
    let param = _.cloneDeep(data);

    // console.log('params:', params);
    // console.log('data:', data);
    param[fieldId] = newValue;

    console.log('newValue:', newValue);
    console.log('param:', param);

    this.fnUpdateRowDataMast(param);
    // this.fnGetRowData();
  };

  onCellValueChangedDtl = async params => {
    const fieldId = params.column.getId(); // 변경된 cell의 field 값이 전달된다.
    const that = this;

    // newValue의 경우 editType: 'fullRow'아닌 경우에만 의미가 있습니다.
    // fullRow 의 경우 data에 이미 변경된 값이 전달 됩니다.
    // 이 경우 data에 검증이 필요합니다. UNDO할 것 인가? APPLY할 것 인가?
    const { data, newValue } = params;
    let param = _.cloneDeep(data);

    // console.log('params:', params);
    // console.log('data:', data);
    param[fieldId] = newValue;

    console.log('newValue:', newValue);
    console.log('param:', param);

    this.fnUpdateRowDataDtl(param);
  };

  fnUpdateRowDataMast = params => {
    console.log('fnUpdateMastRowData:', params);
    const url = Server.getRestAPI() + '/system/updateTcCodeMast.do';
    // ---------------------------------------------------------
    // (중요) await와 then 은 함께 사용해서는 안됩니다.
    // ---------------------------------------------------------
    return axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    });
  };

  fnUpdateRowDataDtl = params => {
    console.log('fnUpdateDtlRowData:', params);
    const url = Server.getRestAPI() + '/system/updateTcCodeDtl.do';
    // ---------------------------------------------------------
    // (중요) await와 then 은 함께 사용해서는 안됩니다.
    // ---------------------------------------------------------
    return axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    });
  };

  onFilterTextBoxChangedMast = e => {
    const { value } = e.target;
    this.gridApiMast.setQuickFilter(value);
  };

  onFilterTextBoxChangedDtl = e => {
    const { value } = e.target;
    this.gridApiDtl.setQuickFilter(value);
  };

  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>System - Code</Trans>
        </h2>

        <div className="con_box">
          <div className="f_left mB20">
            <input
              type="text"
              className="input_type1"
              placeholder={i18n.t('Filter')}
              onChange={e => this.onFilterTextBoxChangedMast(e)}
            />
          </div>
          <div className="f_right mB20">
            {!this.state.editingMast && (
              <button className="btn_blue" onClick={this.onBtRegisterMast}>
                <Trans>Regist</Trans>
              </button>
            )}
            <button className="btn_gray" onClick={this.onBtDeleteMast}>
              <Trans>Delete</Trans>
            </button>
          </div>

          <div className="board_list_s">
            <div
              id="myGrid"
              style={{
                height: this.state.gridHeight,
                width: '100%'
              }}
              className="ag-theme-balham"
            >
              <AgGridReact
                modules={this.state.modules}
                columnDefs={this.state.MastColDefs}
                // components={this.state.components}
                // frameworkComponents={this.state.frameworkComponents}
                defaultColDef={this.state.defaultColDef}
                rowSelection={this.state.rowSelection}
                editType={this.state.editType}
                // enterMovesDownAfterEdit={true}
                // enterMovesDown={true}
                // rowModelType={this.state.rowModelType}

                onGridReady={this.onGridReadyMast}
                onCellEditingStarted={this.onCellEditingStarted}
                onCellEditingStopped={this.onCellEditingStopped}
                onRowClicked={this.onRowSelectedMast}
                onRowSelected={this.onRowSelectedMast}
                onRowEditingStarted={this.onRowEditingStarted}
                onRowEditingStopped={this.onRowEditingStopped}
                onCellValueChanged={this.onCellValueChangedMast}
                rowData={this.state.codeMast}
                pagination={true}
                paginationPageSize={this.state.paginationPageSize}
              />
            </div>
          </div>
        </div>

        {/* CodeInfo Add */}
        {this.state.editingMast && (
          <div className="con_box">
            <h3>
              <Trans>Code - Master</Trans>
            </h3>

            <div className="board_list2">
              <table>
                <colgroup>
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '24%' }} />

                  <col style={{ width: '10%' }} />
                  <col style={{ width: '23%' }} />

                  <col style={{ width: '10%' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <Trans>Country Code</Trans>
                      <span className="red">*</span>
                    </th>
                    <td>
                      <CountryCode
                        name="countryCode"
                        style={{ width: 250 }}
                        setCode={this.setCodeMast}
                        countryCode={this.state.paramsMast.countryCode}
                      />
                    </td>
                    <th>
                      <Trans>Code Id</Trans>
                      <span className="red">*</span>
                    </th>
                    <td>
                      <input
                        type="text"
                        name="codeId"
                        className="input_type1"
                        maxLength="4"
                        value={this.state.paramsMast.codeId}
                        onChange={e => this.onChangeParamMast(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Trans>Code Name</Trans> <span className="red">*</span>
                    </th>
                    <td>
                      <input
                        type="text"
                        name="codeName"
                        className="input_type1"
                        maxLength="20"
                        placeholder=""
                        value={this.state.paramsMast.codeName}
                        onChange={e => this.onChangeParamMast(e)}
                      />
                    </td>
                    <th>
                      <Trans>Remarks</Trans>
                    </th>
                    <td>
                      <input
                        type="text"
                        name="remarks"
                        className="input_type1"
                        maxLength="300"
                        placeholder=""
                        value={this.state.paramsMast.remarks}
                        onChange={e => this.onChangeParamMast(e)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="txt_center mT40">
              <button className="btn_gray" onClick={this.onBtCancel}>
                <Trans>Cancel</Trans>
              </button>
              <button className="btn_save" onClick={this.onBtMastSave}>
                <Trans>Save</Trans>
              </button>
            </div>
          </div>
        )}
        {/* ============================================================= */}
        {/* Detail */}
        {/* ============================================================= */}
        {this.state.mastSelected && (
          <div className="con_box">
            <div className="f_left mB20">
              <input
                type="text"
                className="input_type1"
                placeholder={i18n.t('Filter')}
                onChange={e => this.onFilterTextBoxChangedDtl(e)}
              />
            </div>
            <div className="f_right mB20">
              {this.state.mastSelected && (
                <button className="btn_blue" onClick={this.onBtRegisterDtl}>
                  <Trans>Regist</Trans>
                </button>
              )}
              <button className="btn_gray" onClick={this.onBtDeleteDtl}>
                <Trans>Delete</Trans>
              </button>
            </div>

            <div className="board_list_s">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%'
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  modules={this.state.modules}
                  columnDefs={this.state.DtlColDefs}
                  // components={this.state.components}
                  // frameworkComponents={this.state.frameworkComponents}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  editType={this.state.editType}
                  // enterMovesDownAfterEdit={true}
                  // enterMovesDown={true}
                  // rowModelType={this.state.rowModelType}

                  onGridReady={this.onGridReadyDtl}
                  onCellEditingStarted={this.onCellEditingStarted}
                  onCellEditingStopped={this.onCellEditingStopped}
                  // onRowClicked={this.onRowClicked}
                  // onRowSelected={this.onRowSelected} // Master Grid에서만 사용합시다.
                  onRowEditingStarted={this.onRowEditingStarted}
                  onRowEditingStopped={this.onRowEditingStopped}
                  onCellValueChanged={this.onCellValueChangedDtl}
                  rowData={this.state.codeDtl}
                  pagination={true}
                  paginationPageSize={this.state.paginationPageSize}
                />
              </div>
            </div>
          </div>
        )}
        {/* CodeInfo Add */}
        {this.state.editingDtl && (
          <div className="con_box">
            <h3>
              <Trans>Code - Detail</Trans>
            </h3>

            <div className="board_list2">
              <table>
                <colgroup>
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '23%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '23%' }} />
                  <col style={{ width: '10%' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <Trans>value</Trans>
                      <span className="red">*</span>
                    </th>
                    <td>
                      <input
                        type="text"
                        name="codeValue"
                        className="input_type1"
                        value={this.state.paramsDtl.codeValue}
                        onChange={e => this.onChangeParamDtl(e)}
                        maxLength="10"
                      />
                    </td>
                    <th>
                      <Trans>Full Text</Trans>
                      <span className="red">*</span>
                    </th>
                    <td>
                      <input
                        type="text"
                        name="codeText"
                        className="input_type1"
                        value={this.state.paramsDtl.codeText}
                        onChange={e => this.onChangeParamDtl(e)}
                        maxLength="30"
                      />
                    </td>
                    <th>
                      <Trans>Short Text</Trans>
                    </th>
                    <td>
                      <input
                        type="text"
                        name="codeStext"
                        className="input_type1"
                        placeholder=""
                        value={this.state.paramsDtl.codeStext}
                        onChange={e => this.onChangeParamDtl(e)}
                        maxLength="10"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="txt_center mT40">
              <button className="btn_gray" onClick={this.onBtCancel}>
                <Trans>Cancel</Trans>
              </button>
              <button className="btn_save" onClick={this.onBtSaveDtl}>
                <Trans>Save</Trans>
              </button>
            </div>
          </div>
        )}
        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
