import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import FHDateRangeSearch from '../utils/FHDateRangeSearch';
import AFDateRangeSearch from '../utils/AFDateRangeSearch';

import '../Report.module.css';

// 15. 종계 부화 보고서 (Stock 기준, 기간/HH별) 2
export default class HatStkTermHhR1 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      excelName: i18n.t('Hatching Report 2 (Stock, Term/HH)'),
      rowData: [],
      params: {},
      ColDefs: [
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Flock'),
              field: 'flock',
              pinned: 'left',
              width: 100,
              minWidth: 100,
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('HH'),
              field: 'hh',
              pinned: 'left',
              width: 100,
              minWidth: 100,
            },
          ],
        },
        {
          headerName: i18n.t('No. of birds'),
          children: [
            {
              headerName: i18n.t('Female'),
              field: 'nbFemale',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('Male'),
              field: 'nbMale',
              width: 100,
              minWidth: 100,
            },
          ],
        },
        {
          headerName: i18n.t('Cooling Room'),
          children: [
            {
              headerName: i18n.t('Receive'),
              field: 'crReceive',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('By Product'),
              field: 'crByProduct',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('Move In'),
              field: 'crMoveIn',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('Move Out'),
              field: 'crMoveOut',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('Cutting'),
              field: 'crCutting',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('HE'),
              field: 'crHE',
              width: 100,
              minWidth: 100,
            },
          ],
        },
        {
          headerName: i18n.t('Setter'),
          children: [
            {
              headerName: i18n.t('Cutting'),
              field: 'sCutting',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('Explode'),
              field: 'sExplode',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('Infertile'),
              field: 'sInfertile',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('Cutting'),
              field: 'sCutting',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('Fertile'),
              field: 'sFertile',
              width: 100,
              minWidth: 100,
            },
          ],
        },
        {
          headerName: i18n.t('Hatcher'),
          children: [
            {
              headerName: i18n.t('By Product'),
              field: 'hByProduct',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('Culling'),
              field: 'hCulling',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('Cutting'),
              field: 'hCutting',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('DOC'),
              field: 'hDOC',
              width: 100,
              minWidth: 100,
            },
          ],
        },
      ],
      gridHeight: '365px',
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        cellClass: 'text-center',
        suppressSizeToFit: false,
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagenation: false,
      paginationPageSize: 10,
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.setState({ rowData: sampleData });
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  // -----------------------------------------------------------
  // 참고용 : ES/ETL 서버 주소(API)를 지정해야 합니다.
  // -----------------------------------------------------------
  fnGetRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/work/selectTcTaskDtl.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtExport = () => {
    const fileName =
      i18n.t(this.state.excelName) + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t(this.state.excelName);
    const params = {
      allColumns: false,
      columnGroups: true,
      fileName: fileName,
      sheetName: sheetName,
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onSearch = (params) => {
    console.log(params);
    this.fnGetRowData(params);
  };

  render() {
    return (
      <React.Fragment>
        <h3>
          {/* 14. 종계 부화 보고서 (Stock 기준, 기간/HH별) 1 */}
          <Trans>Hat EP, Daily/HH R2</Trans>
        </h3>

        <div className="con_box">
          <AFDateRangeSearch onSearch={this.onSearch}></AFDateRangeSearch>

          <div className="f_right mB20">
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
          </div>
          <div className="board_list_s">
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  excelStyles={this.state.excelStyles}
                  onGridReady={this.onGridReady}
                  rowData={this.state.rowData}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const sampleData = [
  {
    flock: 0,
    hh: 1,
    nbFemale: 2,
    nbMale: 3,
    crReceive: 4,
    crByProduct: 5,
    crMoveIn: 6,
    crMoveOut: 7,
    crCutting: 8,
    crHE: 9,
    sCutting: 10,
    sExplode: 11,
    sInfertile: 12,
    sCutting: 13,
    sFertile: 14,
    hByProduct: 15,
    hCulling: 16,
    hCutting: 17,
    hDOC: 18,
  },
];
