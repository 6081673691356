import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import {
  AgGridReact,
  AllModules,
  isRowSelected
} from 'components/commons/ag-grid/AgGridUtil';

import { Const, Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';

import { moment } from 'components';

import i18n from 'i18next';

import '../Hatchery.module.css';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 ** 4. colSpan => conSpan, rowSpan => rowSpan 입니다.
 */
class PopUpSelectHatcherList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tranferNo: '',
      rowData: [],
      columnDefs: [
        {
          headerName: '',
          field: 'ck',
          width: 50,
          cellStyle: { textAlign: 'center' },
          editable: true,
          cellRenderer: params => {
            return '<button style="color: #000;width:60;">선택</button>';
          }
        },
        {
          headerName: 'Hatcher ID',
          field: 'hatcherId',
          width: 120,
          cellStyle: { textAlign: 'center' }
        },
        {
          headerName: 'Capacity',
          field: 'capacity',
          valueFormatter: this.formatNumber,
          width: 120,
          cellStyle: { textAlign: 'center' }
        },
        {
          headerName: 'Fertile Qty',
          field: 'fertileQty',
          valueFormatter: this.formatNumber,
          width: 130,
          cellStyle: { textAlign: 'right' }
        }
      ]
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  onCellClicked = e => {
    if (e.column.colId === 'ck') {
      //선택버튼 클릭
      this.props.fnGetHatcher(e.data);
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.popUpSelectHatcherListView !==
      this.props.popUpSelectHatcherListView
    ) {
      let that = this;
      let data = {};
      data.farmCode = SessionUtil.getFarmCode();
      data.hatcheryId = SessionUtil.getHatcheryId();
      data.dateFormat = SessionUtil.getDateFormat();

      const url = Server.getRestAPI();
      axios({
        method: 'post',
        url: url + '/hatchery/candling/selectHatcherInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin'
      })
        .then(function(response) {
          that.setState({ rowData: response.data.rows1 }, function() {});
        })
        .catch(function(error) {
          return [];
        });
    }
  }

  fnClickPopUpCancel = () => {
    this.props.fnClickPopUpCancel();
  };

  render() {
    if (!this.props.popUpSelectHatcherListView) return null;
    return (
      <Fragment>
        {/* 디자인 영역 - setting 메뉴 레이어 팝업 */}
        <div className="layer_popup">
          <div className="popup_type1">
            <p className="mB20">Select Hatcher</p>

            <div
              style={{ height: '300px', width: '100%' }}
              className="board_list ag-theme-balham"
            >
              <AgGridReact
                columnDefs={this.state.columnDefs}
                rowData={this.state.rowData}
                defaultColDef={this.state.defaultColDef}
                onCellClicked={e => this.onCellClicked(e)}
              ></AgGridReact>
            </div>

            <button
              className="btn_gray2"
              style={{ marginTop: '10px' }}
              onClick={e => this.fnClickPopUpCancel()}
            >
              <Trans>Cancel</Trans>
            </button>
          </div>
        </div>
        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default PopUpSelectHatcherList;
