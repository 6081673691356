/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { useEffect, useState } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import EggRoute from './route/Route';
import { SessionUtil } from 'utils';
// import { withAlert } from 'react-alert';
import './i18n';
// import i18n from 'i18n';

// =================================================================
// Dynamic Import 처리시 공통 CSS 처리 지연으로
// UI Loading시 CSS가 입혀지지 않은 상황이 잠시 노출 됩니다.
// 그래서 각각의 Routing Component로 App.css, mApp.css를 옮겼습니다.
// =================================================================
// import MenuUtil from 'layout/MenuUtil';
// if (MenuUtil.isMobile()) import('./mApp.css');
// else import('./App.css');
// =================================================================

const App = props => {
  // const [language, setLanguage] = useState('en');
  const { t, i18n } = useTranslation();

  // useEffect는 함수형 컴포넌트에서
  // componentDidMount & componentDidUpdate를 합친 기능으로 보시면 됩니다.
  // infinite loop 에 주의하세요,
  useEffect(() => {
    const lang = SessionUtil.getLang();
    if (lang) {
      switch (lang) {
        case 'en':
        case 'ko':
        case 'vn':
        case 'id':
          // console.log('i18n.lang:', lang);
          i18n.changeLanguage(lang);
          break;
      }
    }
  }, [i18n]);

  return <EggRoute {...props}></EggRoute>;
};

export default withTranslation(['en', 'ko', 'vn', 'id'])(App);
// const Extended = withTranslation()(App);
// Extended.static = App.static;
// export default Extended;
