import React, { Fragment } from 'react';
import { withAlert } from 'react-alert';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { Const, Server } from 'components/Properties';
import { Modal, Button, DatePicker } from 'antd';
import axios from 'axios';
import qs from 'qs';
import i18n from 'i18next';

import { moment } from 'components';
import NumberFormat from 'react-number-format';

import '../Hatchery.module.css';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 ** 4. colSpan => conSpan, rowSpan => rowSpan 입니다.
 */
class GradeInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: [],
      gradingDate: DateUtil.today(),
      eggQtySum: 0,
      gradingQty: 0,
      hatchingEggQty: 0,
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let that = this;
    if (prevProps.rowData !== this.props.rowData) {
      if (this.props.rowData.goodEggQty) {
        this.setState(
          {
            gradingQty: this.props.rowData.goodEggQty,
            eggQtySum: 0,
            form: {
              ...this.state.form,
              missShapeEggQty: '0',
              thinEggQty: '0',
              crackEggQty: '0',
              damageEggQty: '0',
              dirtyEggQty: '0',
              smallEggQty: '0',
              jumboEggQty: '0',
              hatchingEggQty: this.props.rowData.goodEggQty,
              gradingRem: '',
              gradingDate: '',
              receiveDate: this.props.rowData.receiveDate,
            },
          },
          function () {}
        );
      }
      if (this.props.rowData.hatchingEggQty) {
        this.setState(
          {
            gradingQty: this.props.rowData.gradingQty,
            eggQtySum:
              Number(this.props.rowData.missShapeEggQty) +
              Number(this.props.rowData.hatchingEggQty) +
              Number(this.props.rowData.thinEggQty) +
              Number(this.props.rowData.crackEggQty) +
              Number(this.props.rowData.damageEggQty) +
              Number(this.props.rowData.dirtyEggQty) +
              Number(this.props.rowData.jumboEggQty) +
              Number(this.props.rowData.smallEggQty),
            form: this.props.rowData,
          },
          function () {}
        );
      }
    }
  }

  fnClickSaveBtn = (e) => {
    let that = this;
    let data = this.state.form;

    data.eggReceiveNo = this.props.rowData.eggReceiveNo;
    data.strainCode = this.props.rowData.strainCode;
    data.gradingQty = this.props.rowData.goodEggQty;
    data.farmCode = SessionUtil.getFarmCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.dateFormat = SessionUtil.getDateFormat();
    data.iuFlag = this.props.iuFlag;
    data.hhId = this.props.rowData.hhId;

    if (data.farmCode === 'undefined') {
      data.farmCode = '';
    }

    if (this.state.form.hatchingEggQty < 0) {
      that.props.alert.show(i18n.t('Please check Hatching Egg Qty.'));
      return false;
    }

    if (!data.gradingDate) {
      data.gradingDate = this.state.gradingDate;
    }

    console.log('data', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/grade/saveGradingInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.alert.show(i18n.t('Saved'));
        that.props.fnSetRegDate();
        that.fnClear();
      })
      .catch(function (error) {
        that.props.alert.show(i18n.t('Save failed'));
        return [];
      });
  };

  fnClear = (e) => {
    if (this.props.iuFlag === 'I') {
      this.setState(
        {
          form: { hatchingEggQty: this.props.rowData.goodEggQty },
          eggQtySum: '',
        },
        function () {}
      );
    }
    if (this.props.iuFlag === 'U') {
      this.setState(
        {
          form: this.props.rowData,
        },
        function () {}
      );
    }
  };

  setOnChange = (e, params) => {
    const col = e.target.name ? e.target.name : '';
    let val = e.target.value;
    let tot = 0;
    let hatchingEggQty = 0;

    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
      if (params.minVal) {
        if (Number(val) < Number(params.minVal)) {
          val = 0;
        }
      }
    }

    this.setState(
      {
        form: {
          ...this.state.form,
          [col]: col,
          [col]: val,
        },
      },
      function () {
        if (this.state.form.missShapeEggQty)
          tot = tot + Number(this.state.form.missShapeEggQty);
        if (this.state.form.thinEggQty)
          tot = tot + Number(this.state.form.thinEggQty);
        if (this.state.form.crackEggQty)
          tot = tot + Number(this.state.form.crackEggQty);
        if (this.state.form.damageEggQty)
          tot = tot + Number(this.state.form.damageEggQty);
        if (this.state.form.dirtyEggQty)
          tot = tot + Number(this.state.form.dirtyEggQty);
        if (this.state.form.smallEggQty)
          tot = tot + Number(this.state.form.smallEggQty);
        if (this.state.form.jumboEggQty)
          tot = tot + Number(this.state.form.jumboEggQty);

        hatchingEggQty = Number(this.state.gradingQty) - tot;

        this.setState(
          {
            eggQtySum: tot,
            form: {
              ...this.state.form,
              hatchingEggQty: hatchingEggQty,
            },
          },
          function () {}
        );
      }
    );
  };

  fnClickCancel = (e) => {
    this.props.fnClickCancel();
    this.fnClear();
  };

  onDateChange = (date, dateString, name) => {
    let that = this;

    if (this.props.rowData.receiveDate <= dateString) {
      this.setState(
        { form: { ...this.state.form, [name]: dateString } },
        function () {}
      );
    } else {
      that.props.alert.show(i18n.t('Please Check Grade Date.'));
    }
  };

  disabledDate = () => {
    var date = new Date(this.props.rowData.receiveDate);
    return date < moment().endOf('day');
  };

  render() {
    if (!this.props.gradeInfoFormView) return null;
    let iuFlag = this.props.iuFlag;
    return (
      <Fragment>
        {/* 디자인 영역 - setting 메뉴 레이어 팝업 */}

        <div className="con_box">
          <h3>
            <Trans>Grading Info</Trans>
          </h3>

          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th style={{ height: '45px' }}>
                    <Trans>Receive Qty</Trans>
                  </th>
                  <td>
                    <span>{this.state.gradingQty.toLocaleString()}</span>
                  </td>
                  <th>
                    <Trans>Grade Date</Trans> <span className="red">*</span>{' '}
                  </th>
                  <td>
                    <DatePicker
                      name="gradingDate"
                      style={{ width: 120 }}
                      value={
                        this.state.form.gradingDate
                          ? moment(this.state.form.gradingDate)
                          : moment(DateUtil.today())
                      }
                      onChange={(date, dateString) =>
                        this.onDateChange(date, dateString, 'gradingDate')
                      }
                      //disabledDate={this.disabledDate}
                      allowClear={false}
                    />
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Miss Shape</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'missShapeEggQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 120, // px제외 : 없으면 100% */
                    })}
                  </td>

                  <th>
                    <Trans>Thin</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'thinEggQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 120, // px제외 : 없으면 100% */
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Crack</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'crackEggQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 120, // px제외 : 없으면 100% */
                    })}
                  </td>

                  <th>
                    <Trans>Damage</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'damageEggQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 120, // px제외 : 없으면 100% */
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Dirty</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'dirtyEggQty', //*** */
                      type: 'N', //*** */
                      minVal: 0,
                      that: this, //*** */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 120, // px제외 : 없으면 100% */
                    })}
                  </td>

                  <th>
                    <Trans>Small</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'smallEggQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 120, // px제외 : 없으면 100% */
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Jumbo</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'jumboEggQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 120, // px제외 : 없으면 100% */
                    })}
                  </td>

                  <th>
                    <Trans>Hatching Egg</Trans>
                  </th>
                  <td>
                    <span>
                      {this.state.form.hatchingEggQty
                        ? this.state.form.hatchingEggQty.toLocaleString() || ''
                        : 0}
                    </span>
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td colSpan="4">
                    {GridUtil.renderInput({
                      inputName: 'gradingRem', //*** */
                      type: '', //*** */
                      //dec: 2,
                      maxLength: 1000,
                      //maxVal: 999.99,
                      //width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Discard</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'discardQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 120, // px제외 : 없으면 100% */
                      disabled: true,
                    })}
                  </td>

                  <th>
                    <Trans>Discard Remarks</Trans>
                  </th>
                  <td colSpan="3">
                    {GridUtil.renderInput({
                      inputName: 'discardRem', //*** */
                      type: '', //*** */
                      //dec: 2,
                      maxLength: 1000,
                      //maxVal: 999.99,
                      //width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      disabled: true,
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="txt_center mT40">
            <button className="btn_gray" onClick={(e) => this.fnClickCancel()}>
              <Trans>Cancel</Trans>
            </button>
            {this.props.iuFlag === 'I' ? (
              <button
                className="btn_save"
                onClick={(e) => this.fnClickSaveBtn(e)}
              >
                <Trans>Save</Trans>
              </button>
            ) : null}
          </div>
        </div>
        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default withAlert()(GradeInfoForm);
