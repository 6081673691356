import React, { Fragment, Component } from 'react';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';
import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import ReceiveFromSupplier from 'components/mobile/receive/popup/ReceiveFromSupplier';
import MobileHeader from 'components/mobile/header/MobileHeader';
import { observer, inject } from 'mobx-react';
import ModalContainer from 'containers/pages/mobile/common/ModalContainer';
import i18n from 'i18next';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 ** 4. 파일명과 클래스명을 동일하게 해 주세요.
 */

@inject((stores) => ({
  mStore: stores.MobileStore,
  mRcvStore: stores.MRcvStore,
  mProjectStore: stores.MprojectStore,
}))
@observer
class BroilerRcvFromSupplier extends Component {
  componentDidMount() {
    const { mRcvStore } = this.props;
    mRcvStore.initStore();
    mRcvStore.setRcvDate(DateUtil.today());
    mRcvStore.setRcvTransferDate(DateUtil.today());
    this.fnSelectReceivedList();
    this.fnSelectGrowingLog();
  }

  componentWillUnmount() {
    const { mRcvStore } = this.props;
    mRcvStore.initStore();
  }

  //검색모달 on/off
  fnOpenModal = (value) => {
    const { mStore } = this.props;

    if (value == 'S') {
      this.fnSelectStrain();
    }
    if (value == 'C') {
      mStore.setSearchType('C');
      mStore.setModalOpen('S', null, null);
      this.fnSelectCustomer();
    }
  };

  fnOpenConfirmModal = (value, msg, params) => {
    const { mStore } = this.props;
    mStore.setModalOpen('C', value, msg, params);
  };

  fnModalPos = () => {
    this.fnInsertValidatioin();
  };

  fnModalWarning = () => {
    const { mStore } = this.props;
    this.fnInsertSupplierReceiveInfo(mStore.modalFlag.params);
  };

  // 입고수 유효성 체크
  fnValidationReceiveQty = () => {
    const { mStore, mRcvStore } = this.props;
    if (
      // 입고수가 입식수 보다 많은 경우
      mRcvStore.rcvSaveForm.recvQty >
        mRcvStore.rcvSelectedTransferList.transferQty ||
      mRcvStore.rcvSaveForm.recvQty > mRcvStore.rcvSaveForm.transferQty
    ) {
      mStore.setModalOpen(
        'A',
        null,
        i18n.t("ReceiveQty can't over TransferQty")
      );
      mRcvStore.setRecvQty(0);
    } else if (mRcvStore.rcvSaveForm.recvQty < 0) {
      // 입고수가 음수인 경우
      mStore.setModalOpen('A', null, i18n.t("ReceiveQty can't be minus."));
      mRcvStore.setRecvQty(0);
    }
  };

  fnInsertValidatioin = () => {
    const { mRcvStore, mProjectStore, mStore } = this.props;
    let that = this;
    let params = mRcvStore.rcvSaveForm;

    params.farmCode = SessionUtil.getFarmCode();
    params.dateFormat = SessionUtil.getDateFormat();
    params.hatcheryId = SessionUtil.getHatcheryId();
    params.projCode = SessionUtil.getProjCode();
    params.iuFlag = mStore.iuFlag;

    if (mStore.iuFlag == 'U') {
      // 수정인 경우 입고번호가 필요함
      params.broReceiveNo = mProjectStore.selectedProject.broReceiveNo;
    }

    if (mRcvStore.rcvSaveForm.recvQty == '') {
      // 입고수량이 공백인 경우 0이라도 넣어야함
      mStore.setModalOpen('A', null, i18n.t('Please insert Received Quantity'));
      return false;
    }

    if (mRcvStore.rcvSaveForm.transferQty == '') {
      // 입식수량이 공백인 경우 0이라도 넣어야함
      mStore.setModalOpen('A', null, i18n.t('Please insert Invoice Quantity'));

      return false;
    }

    if (mRcvStore.rcvSaveForm.customerCode == '') {
      //고객코드 값을 선택하지 않은경우
      mStore.setModalOpen('A', null, i18n.t('Please Select Custoemr Code'));
      return false;
    }

    if (mRcvStore.rcvSaveForm.recvQty > mRcvStore.rcvSaveForm.transferQty) {
      mStore.setModalOpen(
        'A',
        null,
        i18n.t("ReceiveQty can't over TransferQty")
      );
      mRcvStore.setRecvQty(0);
      return false;
    }

    let sum =
      Number(mRcvStore.rcvSaveForm.aQty) +
      Number(mRcvStore.rcvSaveForm.bQty) +
      Number(mRcvStore.rcvSaveForm.pQty) +
      Number(mRcvStore.rcvSaveForm.othersQty);

    if (sum != mRcvStore.rcvSaveForm.recvQty) {
      mStore.setModalOpen(
        'A',
        null,
        i18n.t('The sum of P, A, B, and Others must equal Receive Qty.')
      );
      return false;
    }
    this.fnInsertSupplierReceiveInfo(params);
  };

  fnInsertSupplierReceiveInfo = (params) => {
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/broiler/receive/saveSupplierReceiveInfo.do',
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then((response) => {
        this.fnInsertSuccess();
      })
      .catch(function (error) {
        return [];
      });
  };

  //저장 또는 수정 성공시
  fnInsertSuccess = () => {
    const { mStore } = this.props;
    mStore.setModalClose();
    this.props.history.push(Const.MOBILE_LOGINED_PAGE);
  };

  //Strain 코드 찾기
  fnSelectStrain = () => {
    const { mStore } = this.props;
    let params = {};
    let that = this;
    //params.codeValue = 'A';
    //params.masterCodeId = 'EP26';
    params.farmCode = SessionUtil.getFarmCode();
    params.farmClass = SessionUtil.getFarmClass();
    params.dateFormat = SessionUtil.getDateFormat();
    params.hatcheryId = SessionUtil.getHatcheryId();
    params.countryCode = SessionUtil.getCountryCode();
    params.hatchDate = DateUtil.today();
    params.codeId = 'EP26';

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/broiler/receive/selectCodeList.do',
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then((response) => {
        if (response.data.rows.length > 0) {
          mStore.setSearchResultList(response.data.rows);
          mStore.setSearchType('S');
          mStore.setModalOpen('S', null, null);
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  //Customer 코드 찾기
  fnSelectCustomer = (value) => {
    const { mStore } = this.props;

    let params = {};

    if (value != '') {
      mStore.setSearchKey(value);
    }

    params.searchKey = mStore.searchKey;
    params.pageNo = mStore.searchPageNo;
    params.pageSize = 10;

    const that = this;
    let url = Server.getRestAPI() + '/basis/selectTcCustomer.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          mStore.setSearchResultList(response.data);
          mStore.setSearchRowCnt(response.data.length);
          if (response.data.length > 0) {
            mStore.setSearchTotal(response.data[0].total);
          }
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  //입고 정보 가져오기
  fnSelectReceivedList = () => {
    const { mProjectStore, mRcvStore, mStore } = this.props;
    let that = this;
    let params = {};

    params.farmCode = SessionUtil.getFarmCode();
    params.dateFormat = SessionUtil.getDateFormat();
    params.projCode = SessionUtil.getProjCode();

    if (mProjectStore.selectedProject.broReceiveNo == undefined) {
      params.broReceiveNo = null;
    } else {
      params.broReceiveNo = mProjectStore.selectedProject.broReceiveNo;
    }

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/mobile/broiler/mSelectReceivedInfoSupplier.do',
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then((response) => {
        if (response.data.rows1.length == 1) {
          mRcvStore.setFormUpdateSupplier(response.data.rows1);
          mStore.setIuFlagUpdate(); // 입고정보가 있는경우 수정이기 때문에 iuFalg 수정으로 변경
        }
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  //차량번호 유효성 체크
  fnValidationTruckNo = (value) => {
    const { mStore, mRcvStore } = this.props;
    var stringByteLength = 0;
    stringByteLength = value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, '$&$1$2')
      .length;
    if (stringByteLength > 10) {
      mStore.setModalOpen(
        'A',
        null,
        i18n.t('TruckNo must be within 10 characters. ')
      );

      mRcvStore.setRcvTruckNo('');
    }
  };

  //비고 유효성 체크
  fnValidationRemark = (value) => {
    const { mStore, mRcvStore } = this.props;
    var stringByteLength = 0;
    stringByteLength = value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, '$&$1$2')
      .length;
    if (stringByteLength > 1000) {
      mStore.setModalOpen(
        'A',
        null,
        i18n.t('Remark must be within 1000 characters. ')
      );

      mRcvStore.setRcvRemarks('');
    }
  };

  //주문번호 유효성 체크
  fnValidationOrderNo = (value) => {
    const { mStore, mRcvStore } = this.props;
    var stringByteLength = 0;
    stringByteLength = value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, '$&$1$2')
      .length;
    if (stringByteLength > 20) {
      mStore.setModalOpen(
        'A',
        null,
        i18n.t('OrderNo must be within 20 characters. ')
      );

      mRcvStore.setRcvOrderNo('');
    }
  };

  //입식번호 유효성 체크
  fnValidationTransferQty = (value) => {
    const { mStore, mRcvStore } = this.props;
    if (value > 0) {
      document.getElementById('recvQty').disabled = false;
    } else if (value == 0) {
      document.getElementById('recvQty').disabled = true;
      mRcvStore.setRecvQty(0);
    }

    if (value < mRcvStore.rcvSaveForm.recvQty) {
      mStore.setModalOpen(
        'A',
        null,
        i18n.t("ReceiveQty can't over TransferQty")
      );
      mRcvStore.setRecvQty(0);
    }
    if (value > 99999999) {
      mStore.setModalOpen(
        'A',
        null,
        i18n.t("TransferQty can't be over 99999999.")
      );
      mRcvStore.setRecvQty(0);
    }
  };

  //입고수량 유효성 체크
  fnValidationQty = (value, type) => {
    const { mStore, mRcvStore } = this.props;

    if (value < 0) {
      mStore.setModalOpen('A', null, i18n.t('Qty can not be minus. '));
      if (type == 'P') {
        mRcvStore.setRcvPQty(0);
      }
      if (type == 'B') {
        mRcvStore.setRcvBQty(0);
      }
      if (type == 'A') {
        mRcvStore.setRcvAQty(0);
      }
      if (type == 'Others') {
        mRcvStore.setRcvOthersQty(0);
      }
    }
  };

  fnPushBack = () => {
    this.props.history.push(Const.MOBILE_LOGINED_PAGE);
  };

  //육성일 작성 여부 확인을 위한 육성일지 리스트 조회
  fnSelectGrowingLog = () => {
    const { mRcvStore } = this.props;
    let params = {};
    params.farmCode = SessionUtil.getFarmCode();
    params.projCode = SessionUtil.getProjCode();

    let url = Server.getRestAPI() + '/mobile/broiler/mSelectCountGrowingLog.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log(response.data.rows1[0].count);
        if (response.data.rows1[0].count > 0) {
          mRcvStore.setGrowingLogYn_Y();
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  //입고날짜 유효성 체크
  fnValidationDate = (value) => {
    const { mStore, mRcvStore } = this.props;
    if (value < mRcvStore.rcvSaveForm.transferDate) {
      mStore.setModalOpen(
        'A',
        null,
        i18n.t('Receive Date can not be before Trnasfer Date. ')
      );

      mRcvStore.setRcvDate(DateUtil.today());
    }
    if (value > DateUtil.today()) {
      mStore.setModalOpen(
        'A',
        null,
        i18n.t('Receive Date can not be after today. ')
      );

      mRcvStore.setRcvDate(DateUtil.today());
    }
  };

  fnIsNumber = (e) => {
    let code = e.keyCode;
    if (code > 47 && code < 58) {
      return;
    }

    if (code == 8 || code == 9) {
      return;
    }
    e.preventDefault();
  };

  render() {
    const { mStore, mRcvStore } = this.props;
    return (
      <Fragment>
        {mStore.headerYn == false ? '' : <MobileHeader mStore={mStore} />}
        <div className="pop_header">
          <div className="mback" onClick={(e) => this.fnPushBack()}>
            <Trans>back</Trans>
          </div>
          [{sessionStorage.getItem('projCode')}]
          <div
            className="mobile_header_confirm"
            onClick={
              mStore.iuFlag == 'I' ||
              (mStore.iuFlag == 'U' &&
                sessionStorage.getItem('growingLogYn') == 'N')
                ? (e) =>
                    this.fnOpenConfirmModal(
                      'S',
                      i18n.t('Are you sure you want to save it?')
                    )
                : null
            }
            style={{
              color:
                mStore.iuFlag == 'I' ||
                (mStore.iuFlag == 'U' &&
                  sessionStorage.getItem('growingLogYn') == 'N')
                  ? '#2e3f4c'
                  : '#a5a5a5',
            }}
          >
            <Trans>Save</Trans>
          </div>
        </div>

        <div className="mobile_content_nofooter">
          <ReceiveFromSupplier
            fnOpenModal={this.fnOpenModal}
            mRcvStore={mRcvStore}
            fnValidationTruckNo={this.fnValidationTruckNo}
            fnValidationRemark={this.fnValidationRemark}
            fnValidationOrderNo={this.fnValidationOrderNo}
            fnValidationTransferQty={this.fnValidationTransferQty}
            fnValidationQty={this.fnValidationQty}
            fnValidationDate={this.fnValidationDate}
            fnValidationReceiveQty={this.fnValidationReceiveQty}
            fnIsNumber={this.fnIsNumber}
          />
        </div>

        <ModalContainer
          confirm={this.fnModalPos}
          warning={this.fnModalWarning}
          fnSelectCustomer={this.fnSelectCustomer}
        />
      </Fragment>
    );
  }
}

export default BroilerRcvFromSupplier;
