import * as React from 'react';
import { Trans } from 'react-i18next';
import i18n from 'i18next';
import { SessionUtil, CommonUtil } from 'utils';
import { moment } from 'components';
import produce from 'immer';

import axios from 'axios';
import qs from 'qs';
// import i18n from 'i18n';
import { Server } from 'components/Properties';
import { Select } from 'antd';

import '../Common.module.css';

/*
 ** 1. PPL만 보여집니다.
 ** 2. farmCode를 선택합니다. (farmCode가 세션에 저장됩니다.)
 ** 3. farmCode (projectCode)로 Options를 표현 합니다.
 */
class PplFarmProjectCode extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      codes: [],
      farmCode: undefined,
    };
  }

  componentDidMount() {
    moment.locale(SessionUtil.getLang());
    const params = {
      ppl: sessionStorage.getItem('pplCode'),
      pageNo: 1,
      pageSize: 30,
      total: 0,
      rowCnt: 0,
    };
    this.fnGetRowData(params);
  }

  fnGetRowData = (params) => {
    if (!SessionUtil.isPpl()) return; // PPL만 이 컴포넌트를 쓸 수 있습니다.
    const that = this;
    const url =
      Server.getRestAPI() + '/mobile/broiler/mHomeProjectListForPPL.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        const codes = [];
        if (data) {
          data.map((row, i) => {
            const obj = {
              farmCode: row.farmCode,
              projCode: row.projCode,
              farmProjCode: row.farmCode + ',' + row.projCode, // 동일한 farmCode가 많아, 유일한 key를 만들기 위해 사용합니다.
            };
            codes.push(obj);
          });
          that.setState({ codes: codes }, function () {
            if (codes.length === 0) return;
            const firstFarmCode = codes[0].farmCode;
            sessionStorage.setItem('farmCode', firstFarmCode);
            that.setState({ farmCode: firstFarmCode });
            that.props.onChange(firstFarmCode);
            console.log('firstFarmCode', firstFarmCode);
          });
          console.log(codes);
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  // PPL의 경우 Session의 farmCode 변경기능 요청 : by 김기철 2020.04.29
  onChange = (farmProjCode) => {
    const that = this;
    const farmCode = _.split(farmProjCode, ',', 1)[0];
    const oldFarmCode = sessionStorage.getItem('farmCode');
    if (oldFarmCode === farmCode) return;

    sessionStorage.setItem('farmCode', farmCode);
    that.setState({ farmCode: farmCode });
    that.props.onChange(farmCode);
    console.log(oldFarmCode, ' => ', farmCode);
  };

  render() {
    if (!SessionUtil.isPpl()) return null; // PPL만 이 컴포넌트를 쓸 수 있습니다.
    // if (this.state.codes.length === 0) return null; // PPL이지만 농장이 매핑되어 있지 않으므로 랜더링 하지 않습니다.
    return (
      <Select
        name="farmCode"
        className={this.props.className}
        value={
          this.state.codes.length === 0
            ? i18n.t('Farm.None(Project.None)')
            : this.state.farmCode
        }
        onChange={this.onChange}
        placeholder={i18n.t('Select code')}
        // showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={this.props.disabled}
        style={this.props.style}
      >
        {this.state.codes.length === 0 && (
          <Select.Option key="0" value={i18n.t('Farm.None(Project.None)')}>
            <Trans>Farm.None(Project.None)</Trans>
          </Select.Option>
        )}
        {this.state.codes.map((row, i) => {
          return (
            <Select.Option key={i} value={row.farmProjCode}>
              {row.farmCode}({row.projCode})
            </Select.Option>
          );
        })}
      </Select>
    );
  }
}
export default PplFarmProjectCode;
