import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, FormUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import CountryCode from 'containers/pages/common/system/CountryCode';

import { Radio } from 'antd';
import produce from 'immer';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { message, Input } from 'antd';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class ProjectView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      params: {}
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.rowNode !== this.props.rowNode) {
      let { rowNode } = this.props;
      this.setState(
        produce(draft => {
          draft.params['farmCode'] = rowNode['farmCode'];
          draft.params['projCode'] = rowNode['projCode'];
          draft.params['projName'] = rowNode['projName'];
          draft.params['projClass'] = rowNode['projClass'];
          draft.params['strainName'] = rowNode['strainName'];
          draft.params['startDate'] = rowNode['startDate'];
          draft.params['endDate'] = rowNode['endDate'];
          draft.params['deleted'] = rowNode['deleted'];
          draft.params['remarks'] = rowNode['remarks'];
        }),
        function() {
          // console.log('changed params : ', this.state.params);
        }
      );
    }
  }

  render() {
    if (!this.props.visable) return null;
    console.log(this.props.params);
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <div className="con_box">
          <h3>
            <Trans>Project Info</Trans>
          </h3>

          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Project Code</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'projCode',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Project Name</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'projName',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Project Class</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'projClass',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Strain</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'strainCodeName',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Start Date</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'startDate',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>End Date</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'endDate',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Deleted</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'deleted',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'remarks',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
