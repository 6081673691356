import * as React from 'react';
import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import { SessionUtil, ObjectUtil } from 'utils';
import i18n from 'i18n';

import {
  LineChart,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  Area,
  Bar,
} from 'recharts';

const LayTotalR1Rechart = (props) => {
  const [width, setWidth] = useState(0);
  const [type, setType] = useState('monotone');
  const [dashArray, setDashArray] = useState('5 5');
  const [currentChart, setCurrentChart] = useState();
  const [rowData, setRowData] = useState(props.rowData);
  const [maxHeight, setMaxHeight] = useState(0);
  const Color = {
    EPS: '#849C69', // (실선) - EP
    EPA: '#8BAACC', // (점선) - EP
  };

  // const rand = (base) => {
  //   let i = 20;
  //   if (!base) base = 0;
  //   const v = (base * Math.random() + i++).toFixed(2);
  //   if (v > maxHeight) setMaxHeight(v);
  //   return v;
  // };

  // const data = [];
  // for (let i = 0; i < 12; i++) {
  //   data.push({
  //     week: 'W' + (i + 7),
  //     PS_Population: rand(100),
  //     EP_Act: rand(180),
  //     EP_Std: rand(150),
  //   });
  // }

  useEffect(() => {
    const conBoxWidth = ObjectUtil.getScrollWidth('con_box');
    setWidth(conBoxWidth);
    setRowData(props.rowData);
    // setRowData(data);
    // console.log(props.rowData);
  }, [props.rowData]);

  // return null;

  return (
    <React.Fragment>
      <ComposedChart
        ref={(chart) => setCurrentChart(chart)}
        // onClick={() => {
        //   const chartSVG = ReactDOM.findDOMNode(currentChart).children[0];
        // }}
        width={width}
        height={175}
        data={rowData}
        margin={{ top: 20, right: 60, left: 0, bottom: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="week" />
        <YAxis type="number" domain={[0, 'dataMax + 100']} />
        <Tooltip />
        <Legend />
        <Bar dataKey="PS_Population" barSize={20} fill="#413ea0" />
        {/*
          2020.09.24 김기홍 삭제 요청
        <Line
          type={type}
          dataKey="EP_Act"
          stroke={Color.EPA}
          strokeDasharray={dashArray}
          dot={false}
        />
        <Line type={type} dataKey="EP_Std" stroke={Color.EPS} dot={false} />
        */}
      </ComposedChart>
    </React.Fragment>
  );
};

export default LayTotalR1Rechart;
