import * as React from 'react';
import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import { SessionUtil, ObjectUtil } from 'utils';
import { Trans } from 'react-i18next';
import i18n from 'i18n';
import { Tag, Divider, Popover, Button } from 'antd';

import {
  LineChart,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  Area,
  Bar,
} from 'recharts';

const GrwIdxAnlysRechart = (props) => {
  const [width, setWidth] = useState(0);
  // 'basis','basisClosed','basisOpen','linear','linearClosed','natural',
  // 'monotoneX','monotoneY','monotone','step','stepBefore','stepAfter' |
  const [type, setType] = useState('monotone');
  const [dashArray, setDashArray] = useState('5 5');
  const [currentChart, setCurrentChart] = useState();
  const [rowData, setRowData] = useState(props.rowData);
  const [maxHeight, setMaxHeight] = useState(0);
  const [visible, setVisible] = useState(false);
  const Color = {
    DFA_BAR: '#626467', // (바차트) - Dep.F
    DMA_BAR: '#9D7102', // (바차트) - Dep.M
    SEA_BAR: '#6490CA', // (바차트) - Sexing Error
    DFS: '#7495B3', // (실선) - Dep.F
    DMS: '#C38A62', // (실선) - Dep.M
    BFS: '#A5A5A5', // (실선) - BodyWeight Female
    BMS: '#DDBF5F', // (실선) - BodyWeight Male
    FFS: '#5A709B', // (실선) - Feed.F
    FMS: '#7D9F6A', // (실선) - Feed.M
    DFA: '#2E59A0', // (점선) - Dep.F
    DMA: '#AE4600', // (점선) - Dep.M
    BFA: '#2D4670', // (점선) - BodyWeight Female
    BMA: '#515D47', // (점선) - BodyWeight Male
    FFA: '#8AAEBF', // (점선) - Feed.F
    FMA: '#F09862', // (점선) - Feed.M
    UFA: '#B8B8B8', // (점선) - Unif.F
    UMA: '#FFCF2E', // (점선) - Unif.M
  };

  useEffect(() => {
    const conBoxWidth = ObjectUtil.getScrollWidth('con_box');
    setWidth(conBoxWidth);
    setRowData(props.rowData);
    // console.log(props.rowData);
    // setRowData(data);
  }, [props.rowData]);

  const weightedInfo = (
    <div>
      <Trans>Dep.Cum Female * 10 + 1000</Trans>
      <br />
      <Trans>Dep.Cum Male * 10 + 1000</Trans>
      <br />
      <Trans>Body Weight Female / 10</Trans>
      <br />
      <Trans>Body Weight Male / 10</Trans>
      <br />
      <Trans>Feed Female * 10</Trans>
      <br />
      <Trans>Feed Male * 10</Trans>
      <br />
      <Trans>Uniformity Female * 10</Trans>
      <br />
      <Trans>Uniformity Male * 10</Trans>
    </div>
  );

  return (
    <React.Fragment>
      <div style={{ textAlign: 'right', marginRight: '62px' }}>
        <Popover
          title={i18n.t('Weighted Information')}
          content={
            <div>
              {weightedInfo}
              <div style={{ textAlign: 'center' }}>
                <button className="btn_gray" onClick={() => setVisible(false)}>
                  Close
                </button>
              </div>
            </div>
          }
          trigger="click"
          visible={visible}
          onVisibleChange={setVisible}
        >
          <Tag color="magenta" style={{ cursor: 'pointer' }}>
            <Trans>The figures on the graph are weighted.</Trans>
          </Tag>
        </Popover>
      </div>
      <ComposedChart
        ref={(chart) => setCurrentChart(chart)}
        // onClick={() => {
        //   const chartSVG = ReactDOM.findDOMNode(currentChart).children[0];
        // }}
        width={width}
        height={700}
        data={rowData}
        margin={{ top: 20, right: 60, left: 0, bottom: 20 }}
        style={{ zIndex: 1 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="week" />
        <YAxis type="number" domain={[0, 'dataMax + 100']} />
        <Tooltip />
        <Legend
          width={width - 120}
          wrapperStyle={{
            marginLeft: '60px',
            backgroundColor: '#ffffff',
            border: '1px solid #ffffff',
            borderRadius: 3,
            lineHeight: '30px',
          }}
        />
        <Bar dataKey="Dep.F.Act" barSize={5} fill={Color.DFA_BAR} />
        <Bar dataKey="Dep.M.Act" barSize={5} fill={Color.DMA_BAR} />
        <Bar dataKey="SE" barSize={5} fill={Color.SEA_BAR} />
        <Line
          type={type}
          dataKey="Dep.F.Cum.Std"
          stroke={Color.DFS}
          dot={false}
        />
        <Line
          type={type}
          dataKey="Dep.F.Cum.Act"
          stroke={Color.DFA}
          strokeDasharray={dashArray}
          dot={false}
        />
        <Line
          type={type}
          dataKey="Dep.M.Cum.Std"
          stroke={Color.DMS}
          dot={false}
        />
        <Line
          type={type}
          dataKey="Dep.M.Cum.Act"
          stroke={Color.DMA}
          strokeDasharray={dashArray}
          dot={false}
        />
        {/*
        <Line type={type} dataKey="Dep.F.Std" stroke={Color.DFS} dot={false} />
        <Line
          type={type}
          dataKey="Dep.F.Act"
          stroke={Color.DFA}
          strokeDasharray={dashArray}
          dot={false}
        />
        <Line type={type} dataKey="Dep.M.Std" stroke={Color.DMS} dot={false} />
        <Line
          type={type}
          dataKey="Dep.M.Act"
          stroke={Color.DMA}
          strokeDasharray={dashArray}
          dot={false}
        />
        */}
        <Line type={type} dataKey="Feed.F.Std" stroke={Color.FFS} dot={false} />
        <Line
          type={type}
          dataKey="Feed.F.Act"
          stroke={Color.FFA}
          strokeDasharray={dashArray}
          dot={false}
        />
        <Line type={type} dataKey="Feed.M.Std" stroke={Color.FMS} dot={false} />
        <Line
          type={type}
          dataKey="Feed.M.Act"
          stroke={Color.FMA}
          strokeDasharray={dashArray}
          dot={false}
        />
        <Line type={type} dataKey="BW.F.Std" stroke={Color.BFS} dot={false} />
        <Line
          type={type}
          dataKey="BW.F.Act"
          stroke={Color.BFA}
          strokeDasharray={dashArray}
          dot={false}
        />
        <Line type={type} dataKey="BW.M.Std" stroke={Color.BMS} dot={false} />
        <Line
          type={type}
          dataKey="BW.M.Act"
          stroke={Color.BMA}
          strokeDasharray={dashArray}
          dot={false}
        />
        <Line
          type={type}
          dataKey="Unif.F"
          stroke={Color.UFA}
          strokeDasharray={dashArray}
          dot={false}
        />
        <Line
          type={type}
          dataKey="Unif.M"
          stroke={Color.UMA}
          strokeDasharray={dashArray}
          dot={false}
        />
      </ComposedChart>
    </React.Fragment>
  );
};

export default GrwIdxAnlysRechart;
