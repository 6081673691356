import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import axios from 'axios';
import qs from 'qs';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Const, Server } from 'components/Properties';
import i18n from 'i18next';
import { TimePicker, InputNumber } from 'antd';
import NumberFormat from 'react-number-format';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';

/*
 ** Breeding > Egg production Log > EggProductionForm
 ** Egg transfer 입력 Form
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class EggProductionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eggProductionForm: [],
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.totalEggQty.focus();
  }
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps.eggProductionForm !== this.props.eggProductionForm) {
  //     this.setState(
  //       { eggProductionForm: nextProps.eggProductionForm },
  //       function() {}
  //     );
  //   }
  // }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.eggProductionFormView !== nextProps.eggProductionFormView) {
      return {
        eggProductionFormView: nextProps.eggProductionFormView,
        eggProductionForm: nextProps.eggProductionForm,
      };
    }
    return null;
  }

  fnSave = () => {
    let that = this;
    let params = this.state.eggProductionForm;

    params.totalEggQty = params.totalEggQty ? params.totalEggQty : 0;
    params.crackEggQty = params.crackEggQty ? params.crackEggQty : 0;
    params.damageEggQty = params.damageEggQty ? params.damageEggQty : 0;
    params.dirtyEggQty = params.dirtyEggQty ? params.dirtyEggQty : 0;
    params.missShapeEggQty = params.missShapeEggQty
      ? params.missShapeEggQty
      : 0;
    params.jumboEggQty = params.jumboEggQty ? params.jumboEggQty : 0;
    params.thinEggQty = params.thinEggQty ? params.thinEggQty : 0;
    params.smallEggQty = params.smallEggQty ? params.smallEggQty : 0;
    params.floorEggQty = params.floorEggQty ? params.floorEggQty : 0;
    params.goodEggWeight = params.goodEggWeight ? params.goodEggWeight : 0;

    if (!params.totalEggQty || params.totalEggQty === 0) {
      this.props.alert.show(i18n.t('Please enter TotalEggQty.'));
      this.totalEggQty.focus(); //NumberFormat은 focus만
      return false;
    }

    if (Number(params.floorEggQty) > Number(params.totalEggQty)) {
      this.props.alert.show(i18n.t('The quantity is exceeded.'));
      this.floorEggQty.focus();
      return false;
    }

    params.farmCode = SessionUtil.getFarmCode();
    params.dateFormat = SessionUtil.getDateFormat();
    //if (data.iuflag === 'I') {
    params.ageDay = params.days ? params.days : 0;
    //}

    params.crackEggQtyP = 0;
    params.dirtyEggQtyP = 0;
    params.jumboEggQtyP = 0;
    params.smallEggQtyP = 0;
    params.thinEggQtyP = 0;
    params.missShapeEggQtyP = 0;

    let goodEggQty = params.goodEggQty ? String(params.goodEggQty) : '0';
    params.goodEggQty = goodEggQty.replace(/,/gi, '');
    params.goodEggStock = params.goodEggQty;

    console.log('saveEggProductionLog', params);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/egg/saveEggProductionLog.do',
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.fnSearch();
      })
      .catch(function (error) {
        return [];
      });
  };
  fnClickFormCancel = (e) => {
    this.props.fnClickCancel('eggProductionFormView');
  };
  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  renderInput = (inputName, ty, dec, len) => {
    let cl = len ? 'input_base w' + len : 'input_base';

    if (ty && ty === 'N') {
      return (
        <NumberFormat
          className={cl}
          thousandSeparator={true}
          decimalScale={dec ? dec : 0}
          name={inputName}
          //ref={this[inputName]}
          getInputRef={(el) => (this[inputName] = el)}
          value={
            this.state.eggProductionForm[inputName]
              ? this.state.eggProductionForm[inputName] || ''
              : null
          }
          onChange={(e) => this.setOnChange(e, ty)}
        />
      );
    } else if (ty && ty === 'T') {
      return (
        <TimePicker
          //defaultValue={moment('12:08', 'HH:mm')}
          className={cl}
          format={'HH:mm'}
          ref={this[inputName]}
          value={
            this.state.eggProductionForm[inputName]
              ? moment(this.state.eggProductionForm[inputName], 'HH:mm') || ''
              : null
          }
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
        />
      );
    } else {
      return (
        <input
          type="text"
          name={inputName}
          className={cl}
          ref={this[inputName]}
          value={
            this.state.eggProductionForm[inputName]
              ? this.state.eggProductionForm[inputName] || ''
              : ''
          }
          onChange={(e) => this.setOnChange(e)}
        />
      );
    }
  };

  setOnChange = (e, params) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    that.setState(
      { eggProductionForm: { ...this.state.eggProductionForm, [col]: val } },
      function () {
        if (params.type === 'N') {
          that.fnCalcEggGood();
        }
      }
    );
  };

  //EggGood 계산
  fnCalcEggGood = () => {
    let totalEggQty = this.state.eggProductionForm.totalEggQty;
    let damageEggQty = this.state.eggProductionForm.damageEggQty;
    let crackEggQty = this.state.eggProductionForm.crackEggQty;
    let dirtyEggQty = this.state.eggProductionForm.dirtyEggQty;
    let missShapeEggQty = this.state.eggProductionForm.missShapeEggQty;
    let jumboEggQty = this.state.eggProductionForm.jumboEggQty;
    let thinEggQty = this.state.eggProductionForm.thinEggQty;
    let smallEggQty = this.state.eggProductionForm.smallEggQty;

    totalEggQty = totalEggQty ? Number(totalEggQty) : 0;
    damageEggQty = damageEggQty ? Number(damageEggQty) : 0;
    crackEggQty = crackEggQty ? Number(crackEggQty) : 0;
    dirtyEggQty = dirtyEggQty ? Number(dirtyEggQty) : 0;
    missShapeEggQty = missShapeEggQty ? Number(missShapeEggQty) : 0;
    jumboEggQty = jumboEggQty ? Number(jumboEggQty) : 0;
    thinEggQty = thinEggQty ? Number(thinEggQty) : 0;
    smallEggQty = smallEggQty ? Number(smallEggQty) : 0;

    let goodEggQty =
      totalEggQty -
      damageEggQty -
      crackEggQty -
      dirtyEggQty -
      missShapeEggQty -
      jumboEggQty -
      thinEggQty -
      smallEggQty;
    this.setState(
      {
        eggProductionForm: {
          ...this.state.eggProductionForm,
          ['goodEggQty']: goodEggQty.toLocaleString(),
        },
      },
      function () {}
    );

    document.getElementById('totalEggWeight').value = Number(
      goodEggQty * this.state.eggProductionForm.goodEggWeight * 0.001
    ).toFixed(3);
  };
  render() {
    if (!this.props.eggProductionFormView) return null;
    let data = this.state.eggProductionForm;

    console.log('aaabbbb', data);
    console.log('aaabbbb', data.iuflag);
    return (
      <Fragment>
        {/* write&view */}
        <div className="layer_list writelist">
          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '30%' }} />
                <col style={{ width: '20%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Flock</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'flockId', //*** */
                      type: '', //*** */
                      that: this, //*** */
                      statsForm: this.state.eggProductionForm, //*** */
                      disabled: true,
                    })}
                  </td>
                  <th>
                    <Trans>Hen House</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'hhId', //*** */
                      type: '', //*** */
                      that: this, //*** */
                      statsForm: this.state.eggProductionForm, //*** */
                      disabled: true,
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="board_list2 mT10">
            <table>
              <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '30%' }} />
                <col style={{ width: '20%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Ep Date</Trans> <span className="red">*</span>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'epDate', //*** */
                      type: '', //*** */
                      width: '120',
                      that: this, //*** */
                      statsForm: this.state.eggProductionForm, //*** */
                      disabled: true,
                      setOnChange: this.setOnChange, //*** */
                      disabled: true,
                    })}
                  </td>
                  <th>
                    <Trans>Good Egg</Trans>
                  </th>
                  <td style={{ textAlign: 'right' }}>
                    {GridUtil.renderInput({
                      inputName: 'goodEggQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      statsForm: this.state.eggProductionForm, //*** */
                      disabled: true,
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuflag ? false : true,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Total Egg</Trans> <span className="red">*</span>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'totalEggQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      statsForm: this.state.eggProductionForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuflag ? false : true,
                    })}
                  </td>
                  <th>
                    <Trans>Good Egg WT(kg)</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'goodEggWeight', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      statsForm: this.state.eggProductionForm, //*** */
                      dec: 0,
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuflag ? false : true,
                      width: '43%',
                    })}
                    <span style={{ margin: '0 3% 0 1%' }}>g</span>

                    <span>
                      <input
                        type="number"
                        class="input_base"
                        style={{
                          width: '43%',
                          background: '#f5f5f5',
                          textAlign: 'right',
                        }}
                        disabled
                        id="totalEggWeight"
                      ></input>
                      <span style={{ margin: '0 3% 0 1%' }}>Kg</span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Damage</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'damageEggQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      statsForm: this.state.eggProductionForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuflag ? false : true,
                    })}
                  </td>
                  <th>
                    <Trans>Crack</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'crackEggQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      statsForm: this.state.eggProductionForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuflag ? false : true,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Dirty</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'dirtyEggQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      statsForm: this.state.eggProductionForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuflag ? false : true,
                    })}
                  </td>
                  <th>
                    <Trans>Miss Shape</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'missShapeEggQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      statsForm: this.state.eggProductionForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuflag ? false : true,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Jumbo</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'jumboEggQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      statsForm: this.state.eggProductionForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuflag ? false : true,
                    })}
                  </td>
                  <th>
                    <Trans>Thin</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'thinEggQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      statsForm: this.state.eggProductionForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuflag ? false : true,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Small</Trans>
                  </th>
                  <td className="spac50_left">
                    {GridUtil.renderInput({
                      inputName: 'smallEggQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      statsForm: this.state.eggProductionForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuflag ? false : true,
                    })}
                  </td>
                  <th>
                    <Trans>Floor</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'floorEggQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      statsForm: this.state.eggProductionForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuflag != undefined ? false : true,
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="txt_center mT10">
            <button
              className="btn_gray"
              onClick={(e) => this.fnClickFormCancel()}
            >
              <Trans>Clear</Trans>
            </button>
            {data.iuflag ? (
              <button className="btn_save" onClick={(e) => this.fnSave()}>
                <Trans>save</Trans>
              </button>
            ) : null}
          </div>
        </div>
        {/* write&view end*/}
      </Fragment>
    );
  }
}
export default withAlert()(EggProductionForm);
