import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import FHDateRangeSearch from '../utils/FHDateRangeSearch';
import AFDateRangeSearch from '../utils/AFDateRangeSearch';
import DateSearch from '../utils/DateSearch';

import '../Report.module.css';
import AFDatePickerSearch from '../utils/AFDatePickerSearch';
import ReportUtil from '../utils/ReportUtil';

import LayTotalR1Rechart from './LayTotalR1Rechart';

// 17. 종계(산란) 종합 리포트 1 (LayTotalR1)
export default class LayTotalR1 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      excelName: i18n.t('Laying Total Report 1'),
      rowData: [],
      rowData2: [],
      params: {},
      ColDefs: [
        {
          headerName: '',
          field: 'kind',
          pinned: 'left',
          width: 160,
          minWidth: 160,
        },
        {
          headerName: 'Unit',
          field: 'unit',
          width: 100,
          minWidth: 100,
          pinned: 'left',
        },
        {
          headerName: 'Act/Std',
          field: 'actStd',
          pinned: 'left',
        },
        { headerName: 'Tot/Avg', field: 'totAvg', pinned: 'left' },
        { headerName: 'W24', field: 'W24' },
        { headerName: 'W25', field: 'W25' },
        { headerName: 'W26', field: 'W26' },
        { headerName: 'W27', field: 'W27' },
        { headerName: 'W28', field: 'W28' },
        { headerName: 'W29', field: 'W29' },
        { headerName: 'W30', field: 'W30' },
        { headerName: 'W31', field: 'W31' },
        { headerName: 'W32', field: 'W32' },
        { headerName: 'W33', field: 'W33' },
        { headerName: 'W34', field: 'W34' },
        { headerName: 'W35', field: 'W35' },
        { headerName: 'W36', field: 'W36' },
        { headerName: 'W37', field: 'W37' },
        { headerName: 'W38', field: 'W38' },
        { headerName: 'W39', field: 'W39' },
        { headerName: 'W40', field: 'W40' },
        { headerName: 'W41', field: 'W41' },
        { headerName: 'W42', field: 'W42' },
        { headerName: 'W43', field: 'W43' },
        { headerName: 'W44', field: 'W44' },
        { headerName: 'W45', field: 'W45' },
        { headerName: 'W46', field: 'W46' },
        { headerName: 'W47', field: 'W47' },
        { headerName: 'W48', field: 'W48' },
        { headerName: 'W49', field: 'W49' },
        { headerName: 'W50', field: 'W50' },
        { headerName: 'W51', field: 'W51' },
        { headerName: 'W52', field: 'W52' },
        { headerName: 'W53', field: 'W53' },
        { headerName: 'W54', field: 'W54' },
        { headerName: 'W55', field: 'W55' },
        { headerName: 'W56', field: 'W56' },
        { headerName: 'W57', field: 'W57' },
        { headerName: 'W58', field: 'W58' },
        { headerName: 'W59', field: 'W59' },
        { headerName: 'W60', field: 'W60' },
        { headerName: 'W61', field: 'W61' },
        { headerName: 'W62', field: 'W62' },
        { headerName: 'W63', field: 'W63' },
        { headerName: 'W64', field: 'W64' },
        { headerName: 'W65', field: 'W65' },
      ],
      gridHeight: '640px',
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        width: 80,
        minWidth: 80,
        cellClass: 'text-center',
        suppressSizeToFit: false,
        valueFormatter: function (params) {
          return GridUtil.formatReport(params);
        },
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagenation: false,
      paginationPageSize: 10,
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    if (Const.RPT_TEST) {
      const params = ReportUtil.getDefaultParams(this.props);
      console.log('params:', params);
      this.fnGetRowData(params);
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  // -----------------------------------------------------------
  // 참고용 : ES/ETL 서버 주소(API)를 지정해야 합니다.
  // -----------------------------------------------------------
  fnGetRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/stat/breeding/layTotalR1.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data.rows1, rowData2: data.rows2 });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtExport = () => {
    const fileName =
      i18n.t(this.state.excelName) + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t(this.state.excelName);
    const params = {
      allColumns: false,
      columnGroups: true,
      fileName: fileName,
      sheetName: sheetName,
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onSearch = (params) => {
    console.log(params);
    this.fnGetRowData(params);
  };

  render() {
    return (
      <React.Fragment>
        <h3>
          {/* 산란 종합 리포트 */}
          <Trans>Title Lay Total R 1</Trans>
        </h3>

        <div className="con_box">
          <AFDatePickerSearch onSearch={this.onSearch}></AFDatePickerSearch>

          <div className="f_right mB20">
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
          </div>
          <div className="board_list_s">
            <div style={{ width: '100%' }}>
              <LayTotalR1Rechart rowData={this.state.rowData2} />
            </div>
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  excelStyles={this.state.excelStyles}
                  onGridReady={this.onGridReady}
                  rowData={this.state.rowData}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// let i = 0;
// const sampleData = [ { kind: 'PS Population', totAvg: 'Sum', w24: i++, w25: i++, w26: i++, w27: i++, w28: i++, w29: i++, w30: i++, w31: i++, w32: i++, w33: i++, w34: i++, w35: i++, w36: i++, w37: i++, w38: i++, w39: i++, w40: i++, w41: i++, w42: i++, w43: i++, w44: i++, w45: i++, w46: i++, w47: i++, w48: i++, w49: i++, w50: i++, w51: i++, w52: i++, w53: i++, w54: i++, w55: i++, w56: i++, w57: i++, w58: i++, w59: i++, w60: i++, w61: i++, w62: i++, w63: i++, w64: i++, w65: i++, }, { kind: 'PS Population', totAvg: 'Sum' }, { kind: 'Dep. Cum' }, { kind: 'HE Cum' }, { kind: 'Egg Weight' }, { kind: 'Body Weight, Female' }, { kind: 'Point Feed, Female' }, ];
