import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import FHDateRangeSearch from '../utils/FHDateRangeSearch';
import AFDateRangeSearch from '../utils/AFDateRangeSearch';
import ReportUtil from '../utils/ReportUtil';

import '../Report.module.css';

// 14. 종계 부화 보고서 (Stock 기준, 기간/HH별) 1
export default class HatStkTermHhR1 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      excelName: i18n.t('Hatching Report 1 (Stock, Term/HH)'),
      rowData: [],
      params: {},
      ColDefs: [
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Flock'),
              field: 'flock',
              minWidth: 120,
              pinned: 'left',
              colSpan: function (params) {
                const { flock } = params.data;
                switch (flock) {
                  case 'Farm Total':
                  case 'Region Total':
                  case 'Division Total':
                    return 2;
                }
                return 1;
              },
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('HH'),
              field: 'hh',
              pinned: 'left',
            },
          ],
        },
        {
          headerName: i18n.t('No. of birds'),
          children: [
            {
              headerName: i18n.t('Female'),
              field: 'popsFemale',
            },
            {
              headerName: i18n.t('Male'),
              field: 'popsMale',
            },
          ],
        },
        {
          headerName: i18n.t('Egg Prod'),
          children: [
            {
              headerName: i18n.t('Qty'),
              field: 'epQty',
            },
            {
              headerName: i18n.t('HD %'),
              field: 'epHD',
            },
          ],
        },
        {
          headerName: i18n.t('Cooling Room'),
          children: [
            {
              headerName: i18n.t('Receive'),
              field: 'crReceive',
            },
            {
              headerName: i18n.t('Cutting'),
              field: 'crCutting',
            },
            {
              headerName: i18n.t('Move In'),
              field: 'crMoveIn',
            },
            {
              headerName: i18n.t('Move Out'),
              field: 'crMoveOut',
            },
            {
              headerName: i18n.t('By Product'),
              field: 'crByProduct',
            },
            {
              headerName: i18n.t('HE'),
              field: 'crHE',
            },
          ],
        },
        {
          headerName: i18n.t('Setter'),
          children: [
            {
              headerName: i18n.t('HE Setting'),
              field: 'sHESetting',
            },
            {
              headerName: i18n.t('Cutting'),
              field: 'sCutting',
            },
            {
              headerName: i18n.t('By Product'),
              field: 'sByProduct',
            },
            {
              headerName: i18n.t('Fertile'),
              field: 'sFertile',
            },
            {
              headerName: i18n.t('Cutting'),
              field: 'sfCutting',
            },
            {
              headerName: i18n.t('Fertile (%)'),
              field: 'sfRatio',
            },
          ],
        },
        {
          headerName: i18n.t('Hatcher'),
          children: [
            {
              headerName: i18n.t('Fertile'),
              field: 'hFertile',
            },
            {
              headerName: i18n.t('By Product'),
              field: 'hByProduct',
            },
            {
              headerName: i18n.t('DOC'),
              field: 'hDOC',
            },
            {
              headerName: i18n.t('Cutting'),
              field: 'hCutting',
            },
            {
              headerName: i18n.t('Hatch (%)'),
              field: 'hHatchRatio',
            },
          ],
        },
      ],
      gridHeight: '640px',
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        width: 100,
        minWidth: 100,
        cellClass: 'text-center',
        suppressSizeToFit: false,
        cellStyle: function (params) {
          const { flock } = params.node.data;
          switch (flock) {
            case 'Farm Total':
            case 'Region Total':
            case 'Division Total':
              return { 'background-color': Const.AGGR_COLOR };
          }
        },
        valueFormatter: function (params) {
          return GridUtil.formatReport(params);
        },
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagenation: false,
      paginationPageSize: 10,
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    if (Const.RPT_TEST) {
      const params = ReportUtil.getDefaultParams(this.props);
      console.log('params:', params);
      this.fnGetRowData(params);
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  // -----------------------------------------------------------
  // 참고용 : ES/ETL 서버 주소(API)를 지정해야 합니다.
  // -----------------------------------------------------------
  fnGetRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/stat/breeding/hatStkTermHhR1.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data.rows1 });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtExport = () => {
    const fileName =
      i18n.t(this.state.excelName) + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t(this.state.excelName);
    const params = {
      allColumns: false,
      columnGroups: true,
      fileName: fileName,
      sheetName: sheetName,
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onSearch = (params) => {
    console.log(params);
    this.fnGetRowData(params);
  };

  render() {
    return (
      <React.Fragment>
        <h3>
          {/* 14. 부화 보고서(계사/재고) */}
          <Trans>Title Hat Stk, T/HH R 1</Trans>
        </h3>

        <div className="con_box">
          <AFDateRangeSearch onSearch={this.onSearch}></AFDateRangeSearch>

          <div className="f_right mB20">
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
          </div>
          <div className="board_list_s">
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  excelStyles={this.state.excelStyles}
                  onGridReady={this.onGridReady}
                  rowData={this.state.rowData}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// let i = 0;
// const sampleData = [ { farm: 'ES', flock: 'Query', nFemale: '만', nMale: '언른', eQty: '나오길', eHD: '기다리고', cReceive: '있습니다.', cCutting: '언제나', cMoveIn: '나올수', cMoveOut: '있을까요?', cByProduct: '많이', cHE: '기다렸습니다.', sHESetting: '하아~', sCutting: i++, sByProduct: i++, sFertile: i++, sCutting: i++, sFertilePercent: i++, hFertile: i++, hByProduct: i++, hDOC: i++, hCutting: i++, hHatchPercent: i++, }, ];
