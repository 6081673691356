import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';

// For Antd ==================================
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Icon,
  Button,
  Checkbox
} from 'antd';
import { Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';
import { remove } from 'lodash';
import InsertGroup from './InsertGroup';

// For Design
import '../Common.module.css';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */

const EditableContext = React.createContext();

class EditableCell extends React.PureComponent {
  fnGetInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`
                }
              ],
              initialValue: record[dataIndex]
            })(this.fnGetInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

const InsertForm = Form.create({ name: 'group_regist' })(InsertGroup);
class EditableTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { data: [], editingKey: '' };
    this.columns = [
      {
        title: i18n.t('GROUP ID'),
        dataIndex: 'group_id',
        width: '35%',
        editable: false
      },
      {
        title: i18n.t('GROUP NAME'),
        dataIndex: 'group_nm',
        width: '35%',
        editable: true
      },
      {
        title: 'ACTION',
        dataIndex: 'action',
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.fnIsEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <Link
                    to="#"
                    onClick={() => this.fnSave(form, record.key)}
                    style={{ marginRight: 8 }}
                  >
                    <Trans>Save</Trans>
                  </Link>
                )}
              </EditableContext.Consumer>
              <EditableContext.Consumer>
                {form => (
                  <Link
                    to="#"
                    onClick={() => this.fnRemove(form, record.key)}
                    style={{ marginRight: 8 }}
                  >
                    <Trans>Remove</Trans>
                  </Link>
                )}
              </EditableContext.Consumer>
              <EditableContext.Consumer>
                {form => (
                  <Link
                    to="#"
                    onClick={() => this.fnCancel(record.key)}
                    style={{ marginRight: 8 }}
                  >
                    <Trans>Cancel</Trans>
                  </Link>
                )}
              </EditableContext.Consumer>
              {/*
              <Popconfirm
                title="Sure to cancel?"
                onConfirm={() => this.cancel(record.key)}
              >
                <Link to="#">
                  <Trans>Cancel</Trans>
                </Link>
              </Popconfirm>
              */}
            </span>
          ) : (
            <Link
              to="#"
              disabled={editingKey !== ''}
              onClick={() => this.fnEdit(record.key)}
            >
              <Trans>Edit</Trans>
            </Link>
          );
        }
      }
    ];
  }

  fnIsEditing = record => record.key === this.state.editingKey;

  fnCancel = () => {
    this.setState({ editingKey: '' });
  };

  fnUpdateGroup = data => {
    const url = Server.getRestAPI() + '/users/updateGroup.do';
    return axios({
	    method: 'post',
      url: url,
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin'
    });
  };

  fnSave(form, key) {
    const that = this;
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.data];
      const index = newData.findIndex(item => key === item.key);
      if (index > -1) {
        const item = newData[index];
        // 이전 (item)을 row로 교체 합니다.
        newData.splice(index, 1, {
          ...item,
          ...row
        });
        this.fnUpdateGroup(newData[index])
          .then(function(response) {
            // DB Update 성공 이후,
            if (response.data) {
              that.setState({ data: newData, editingKey: '' });
            }
          })
          .catch(function(error) {
            return [];
          });
      } else {
        newData.push(row);
        console.log('newData2 : ', newData);
        this.setState({ data: newData, editingKey: '' });
      }
    });
  }

  fnRemove(form, key) {
    const that = this;
    form.validateFields((error, row) => {
      const newData = [...this.state.data];
      const index = newData.findIndex(item => key === item.key);
      if (index > -1) {
        const item = newData[index];
        // 플래그만 "Y"로 처리합니다.
        row['deleted'] = 'Y';
        // 이전 (item)을 row로 교체 합니다.
        newData.splice(index, 1, {
          ...item,
          ...row
        });
        this.fnUpdateGroup(newData[index])
          .then(function(response) {
            // DB Update 성공 이후,
            console.log('response.data : ', response.data);
            if (response.data) {
              // 이전 (Item)을 제거합니다.
              const remData = remove(newData, function(o, i) {
                return i !== index;
              });
              // 편집 Input을 닫습니다.
              that.setState({ data: remData, editingKey: '' });
            }
          })
          .catch(function(error) {
            return [];
          });
      }
    });
  }

  fnEdit(key) {
    console.log('edit : ', this.state.editingKey);
    this.setState({ editingKey: key });
  }

  fnSelectGroup = data => {
    const that = this;
    const url = Server.getRestAPI() + '/users/selectGroup.do';
    axios({
	    method:'post',
      url: url,
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        if (response.data) {
          let result = [];
          response.data.map((row, i) => {
            let obj = {};
            obj['key'] = i;
            obj['group_id'] = row['user_group_id'];
            obj['group_nm'] = row['user_group_name'];
            result.push(obj);
          });
          // console.log('result : ', result);
          that.setState({ data: result }, function() {
            // console.log('state.data : ', this.state.data);
          });
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  // 사용자 그룹정보를 생성/조회/폼리셋 합니다.
  fnInsertGroup = data => {
    const that = this;
    let url = Server.getRestAPI() + '/users/insertGroup.do';
    axios({
	    method: 'post',
      url: url,
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        console.log(response.data);
        if (response.data) {
          that.fnSelectGroup();
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  handleSubmit = e => {
    e.preventDefault();
    console.log(this.props.form.values);
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.fnInsertGroup(values);
      }
    });
  };

  // rowSelection object indicates the need for row selection
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows
      );
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name
    })
  };

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    // To disabled submit button at the beginning.
    // this.props.form.validateFields();
    this.fnSelectGroup();
  }

  render() {
    const components = {
      body: {
        cell: EditableCell
      }
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.fnIsEditing(record)
        })
      };
    });

    return (
      <React.Fragment>
        <InsertForm fnSelectGroup={this.fnSelectGroup} />
        <EditableContext.Provider value={this.props.form}>
          <Table
            components={components}
            bordered
            dataSource={this.state.data}
            columns={columns}
            rowClassName="editable-row"
            pagination={{
              onChange: this.fnCancel
            }}
          />
        </EditableContext.Provider>
      </React.Fragment>
    );
  }
}

const UserGroup = Form.create()(EditableTable);
export default UserGroup;
