import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { DateUtil } from 'utils';
import { Const, Server } from 'components/Properties';
import { Popconfirm, Switch, message, DatePicker, Select } from 'antd';
import NumberFormat from 'react-number-format';
import SearchInput from './SearchInput';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import { withAlert } from 'react-alert';

import '../Breeding.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class PsMoveoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: { transferDate: DateUtil.today() },
      toFarmCodeFocus: false,
      rowData: [],
      columnDefs: [
        {
          headerName: '',
          field: 'ck',
          width: 50,
          cellStyle: { textAlign: 'center' },
          editable: true,
          cellRenderer: (params) => {
            return '<button class="selbg">&nbsp;&nbsp;Select&nbsp;&nbsp;</button>';
          },
        },
        {
          headerName: 'FLOCK_ID',
          field: 'flockId',
          width: 100,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: 'FLOCK_NAME',
          field: 'flockName',
          width: 100,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: 'FLOCK_CDATE',
          field: 'flockCdate',
          width: 100,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: 'STRAIN_CODE',
          field: 'stainCode',
          width: 100,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: 'Female Qty',
          field: 'flockInQtyF',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Male Qty',
          field: 'flockInQtyM',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'REMARKS',
          field: 'remarks',
          width: 150,
          cellStyle: { textAlign: 'left' },
        },
      ],
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    //필수 항목 setFocus를 위한 정의
    this.transferDate = React.createRef();
    this.flockId = React.createRef();
    this.cullingRelease = React.createRef();
    this.closingRelease = React.createRef();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.moveoutForm !== this.props.moveoutForm) {
      this.fnSearch();
      this.setState({ form: nextProps.moveoutForm }, function () {});
    }
  }
  fnSearch = () => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    //data.receiveNo = '';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/selectBreedReceiveFlockList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('searchFlockList', response.data);

        that.setState({ rowData: response.data.rows1 }, function () {
          console.log(that.state);
        });
      })
      .catch(function (error) {
        return [];
      });
  };
  //Sales 저장
  fnSave = () => {
    let that = this;
    let data = this.state.form;
    console.log(data);

    if (data.iuFlag === 'I' && !data.transferDate) {
      data.transferDate = DateUtil.today();
    }
    if (!data.transferDate) {
      that.props.alert.show(i18n.t('Please enter TransferDate.'));
      this.transferDate.current.focus();
      return false;
    }
    if (!data.flockId) {
      that.props.alert.show(i18n.t('Please enter FlockId.'));
      this.flockId.current.focus();
      return false;
    }

    if (!data.toFarmCode) {
      that.props.alert.show(i18n.t('Please enter FarmCode.'));
      this.setState({ toFarmCodeFocus: true }, function () {});
      //this.customerCode.focus();
      return false;
    }

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    if (data.iuFlag === 'I') {
      //Type
    }

    console.log('save', data);
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/savePsMoveout.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('savePsMoveout', response.data);
        that.props.fnSearch();
      })
      .catch(function (error) {
        return [];
      });
  };
  //선택
  onCellClicked = (e) => {
    console.log('onCellClicked', e);
    if (e.column.colId === 'ck') {
      //선택버튼 클릭

      //this.props.fnSelectFlock(e.data, this.state.tpBreedRecvData);

      this.setState(
        {
          form: {
            ...this.state.form,
            flockId: e.data.flockId,
            strainCode: e.data.strainCode,
          },
        },
        function () {}
      );
    }
  };
  //Write Qty & Press Send
  fnSend = () => {
    let that = this;
    let cullingRelease = Number(this.state.form.cullingRelease);
    let closingRelease = Number(this.state.form.closingRelease);

    let cullingStock = Number(this.state.form.cullingStock); //재고
    let closingStock = Number(this.state.form.closingStock); //재고

    if (cullingRelease > cullingStock) {
      that.props.alert.show(i18n.t('The quantity is exceeded.'));
      this.cullingRelease.focus(); //NumberFormat은 focus만
      return false;
    }
    if (closingRelease > closingStock) {
      that.props.alert.show(i18n.t('The quantity is exceeded.'));
      this.closingRelease.focus(); //NumberFormat은 focus만
      return false;
    }

    this.setState(
      {
        form: {
          ...this.state.form,
          cullingQty: Number(this.state.form.cullingRelease),
          closingQty: Number(this.state.form.closingRelease),
        },
      },
      function () {}
    );
  };
  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  renderInput = (inputName, ty, dec, len) => {
    let cl = len ? 'input_type1 w' + len : 'input_type1';

    if (ty && ty === 'N') {
      cl = len ? 'input_base w' + len : 'input_base';
      return (
        <NumberFormat
          style={{ textAlign: 'right' }}
          className={cl}
          thousandSeparator={true}
          decimalScale={dec ? dec : 0}
          name={inputName}
          //ref={this[inputName]}
          getInputRef={(el) => (this[inputName] = el)}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : null
          }
          onChange={(e) => this.setOnChange(e, ty)}
        />
      );
    } else if (ty && ty === 'T') {
      return (
        <TimePicker
          //defaultValue={moment('12:08', 'HH:mm')}
          className={cl}
          format={'HH:mm'}
          ref={this[inputName]}
          value={
            this.state.form[inputName]
              ? moment(this.state.form[inputName], 'HH:mm') || ''
              : null
          }
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
        />
      );
    } else if (ty && ty === 'S') {
      return (
        <span>
          {this.state.form[inputName] ? this.state.form[inputName] || '' : ''}
        </span>
      );
    } else if (ty && ty === 'D') {
      return (
        <DatePicker
          name={inputName}
          //className={cl}
          ref={this[inputName]}
          value={moment(this.state.form[inputName])}
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
          allowClear={false}
        />
      );
    } else {
      return (
        <input
          type="text"
          name={inputName}
          className={cl}
          ref={this[inputName]}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : ''
          }
          onChange={(e) => this.setOnChange(e)}
        />
      );
    }
  };
  setOnChange = (e, ty) => {
    const col = e.target.name ? e.target.name : '';
    //console.log('setOnChangeTime', e);
    //console.log('setOnChangeTime', e.target.value);
    //this.setState({ form[col]: e.target.value }, function() {});
    let val = e.target.value;
    if (ty === 'N') {
      val = val.replace(/,/gi, '');
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          [col]: val,
        },
      },
      function () {
        // if (ty === 'N') {
        //   this.fnCalcEggGood();
        // }
      }
    );
  };
  setOnChangeTime = (date, dateString, inputName) => {
    const col = inputName ? inputName : '';
    //this.setState({ form[col]: e.target.value }, function() {});

    this.setState(
      {
        form: {
          ...this.state.form,
          [col]: dateString,
        },
      },
      function () {}
    );
  };
  setOnChangeSelect = (value, col) => {
    this.setState(
      { form: { ...this.state.form, [col]: value } },
      function () {}
    );
  };
  render() {
    if (!this.props.moveoutFormView) return null;
    const data = this.state.form;

    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Move Out</Trans>
        </h2>

        <div className="con_box">
          <h3>
            <Trans>Flock Select</Trans>
          </h3>
          <div className="board_list">
            <div
              className="f_right ag-theme-balham"
              style={{ height: '200px', width: '100%' }}
            >
              <AgGridReact
                columnDefs={this.state.columnDefs}
                rowData={
                  this.state.rowData.length > 0 ? this.state.rowData : []
                }
                //onRowClicked={e => this.onRowClicked(e)}
                onCellClicked={(e) => this.onCellClicked(e)}
              ></AgGridReact>
            </div>
          </div>

          <h3 className="mT40">
            <Trans>Move Out</Trans>
          </h3>
          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '30%' }} />
                <col style={{ width: '20%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Flock</Trans>
                  </th>
                  <td>{this.renderInput('flockId', '', 0)}</td>
                  <th>
                    <Trans>Strain</Trans>
                  </th>
                  <td>{this.renderInput('strainCode', '', 0)}</td>
                </tr>
                <tr>
                  <th>
                    <Trans>Date</Trans>
                  </th>
                  <td>{this.renderInput('transferDate', 'D', 0)}</td>
                  <th>
                    <Trans>Order No.</Trans>
                  </th>
                  <td>{this.renderInput('orderNo', '', 0)}</td>
                </tr>
                <tr>
                  <th>
                    <Trans>Farm</Trans>
                  </th>
                  <td>
                    <SearchInput
                      placeholder="input search text"
                      style={{ width: 200 }}
                      //ref={this['customerCode']}
                      inputName="farmCode"
                      focus={this.state.toFarmCodeFocus}
                      getValue={(value) =>
                        this.setOnChangeSelect(value, 'toFarmCode')
                      }
                    />
                  </td>
                  <th>
                    <Trans>Truck No.</Trans>
                  </th>
                  <td>{this.renderInput('truckNo', '', 0)}</td>
                </tr>
                <tr>
                  <th>
                    <Trans>Remark</Trans>
                  </th>
                  <td colSpan="3">{this.renderInput('remarks', '', 0)}</td>
                </tr>
                <tr>
                  <th>
                    <Trans>Female</Trans>
                  </th>
                  <td>{this.renderInput('moveQtyF', 'N', 0)}</td>
                  <th>
                    <Trans>Male</Trans>
                  </th>
                  <td>{this.renderInput('moveQtyM', 'N', 0)}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="txt_center mT40 mB40">
            <Link to="#">
              <button
                className="btn_gray"
                onClick={() => this.props.fnClose('moveoutFormView')}
              >
                <Trans>Cancel</Trans>
              </button>
            </Link>
            <Link to="#">
              <button className="btn_save" onClick={() => this.fnSave()}>
                <Trans>save</Trans>
              </button>
            </Link>
          </div>

          <div className="f_right mB10">
            <span className="mR20">Write Qty & Press Send</span>
            <button className="btn_blue" onClick={() => this.fnSend()}>
              <i className="mdi mdi-arrow-up"></i>
              <Trans>Send</Trans>
            </button>
          </div>

          <div className="board_list">
            <table>
              <colgroup>
                <col style={{ width: '20%' }} />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <Trans>Hen House</Trans>
                  </th>
                  <th>
                    <Trans>Female Qty</Trans>
                  </th>
                  <th>
                    <Trans>Move Out</Trans>
                  </th>
                  <th>
                    <Trans>Male Qty</Trans>
                  </th>
                  <th>
                    <Trans>Move Out</Trans>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>HH01</th>
                  <td>000</td>
                  <td>000</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>HH01</th>
                  <td>000</td>
                  <td>000</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>HH01</th>
                  <td>000</td>
                  <td>000</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default withAlert()(PsMoveoutForm);
