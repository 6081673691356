import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';
import { Const, Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';

import { moment } from 'components';
import { DateUtil } from 'utils';

import i18n from 'i18next';
import { Popconfirm, Switch, message, DatePicker } from 'antd';
import '../Breeding.module.css';
/*
 ** Breed - Receive - Hen House - In
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class PopIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      chickinQty: 0,
      chickinQtyF: 0,
      chickinQtyM: 0,
      inQty: 0, //입력받는 field 합계
      inQtyF: 0, //입력받는 field
      inQtyM: 0, //입력받는 field
      chickinDate: '',
      moveDate: '',
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log('UNSAFE_componentWillReceiveProps', nextProps.popInRow);
    this.setState(nextProps.popInRow, function () {
      let _capa = Number(this.state.capacity);
      _capa =
        _capa -
        (Number(this.state.chickinQtyF) + Number(this.state.chickinQtyM));

      this.setState(
        {
          capa: _capa,
          inQtyF: 0,
          inQtyM: 0,
        },
        function () {
          this.fnCalcQty(this.state, '');
          console.log('UNSAFE_componentWillReceiveProps', this.state);
        }
      );
    });
  }
  setOnChange = (e) => {
    const that = this;
    const col = e.target.name ? e.target.name : '';
    this.setState({ [col]: e.target.value }, function () {
      that.fnCalcQty(this.state, col);
    });
  };
  fnCalcQty = (data, col) => {
    if (col === '') {
      //처음
      let _chickinQty =
        Number(data.chickinQtyF ? data.chickinQtyF : 0) +
        Number(data.chickinQtyM ? data.chickinQtyM : 0);

      this.setState({ chickinQty: _chickinQty }, function () {});
    } else {
      let _inQty =
        Number(data.inQtyF ? data.inQtyF : 0) +
        Number(data.inQtyM ? data.inQtyM : 0);

      this.setState({ inQty: _inQty }, function () {});
    }
  };
  fnSave = () => {
    console.log('fnSave', this.state);
    if (this.state.inQty > this.state.capa) {
      this.setState({ visible: true });
    } else {
      this.setState({ visible: false });
      this.confirm(); //수량 정상이면 저장
    }
  };
  handleVisibleChange = (visible) => {
    if (!visible) {
      this.setState({ visible });
      return;
    }
    // Determining condition before show the popconfirm.
    // console.log(this.state.condition);
    // if (this.state.condition) {
    //   this.confirm(); // next step
    // } else {
    //   this.setState({ visible }); // show the popconfirm
    // }
  };
  confirm = () => {
    this.setState({ visible: false });

    let data = this.state;
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.moveDate = data.moveDate ? data.moveDate : DateUtil.today();
    data.flockId = data.fromFlockId;
    data.chickinQtyF = data.inQtyF; //입력받은 수량 ************
    data.chickinQtyM = data.inQtyM; //입력받은 수량 ************

    console.log('saveChickInHh', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/saveChickInHh.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('saveChickInHh', response.data);
        that.setState(
          {
            inQty: 0,
            inQtyF: 0,
            inQtyM: 0,
            chickinDate: '',
            moveDate: '',
          },
          function () {}
        );

        that.props.fnSearch();
        that.props.fnClosePop('popIn');
      })
      .catch(function (error) {
        return [];
      });
  };

  cancel = () => {
    this.setState({ visible: false });
  };
  onDateChange = (date, dateString, name) => {
    this.setState({ [name]: dateString }, function () {
      console.log(this.state);
    });
  };
  render() {
    if (!this.props.popIn) return null;
    let row = this.state;
    return (
      <Fragment>
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            margin: 'auto',
            zIndex: 1000,
            backgroundColor: 'rgba(0,0,0, 0.5)',
          }}
        >
          <div
            style={{
              position: 'absolute',
              width: '900px',
              height: 'auto',
              left: '50%',
              transform: 'translateX(-50%)',
              top: '15%',
              bottom: '15%',
              margin: 'auto',
              borderRadius: '10px',
              background: 'white',
            }}
          >
            {/* 디자인 영역 */}
            <h2>
              <Trans>Receive - HH Chick In</Trans>
            </h2>

            <div className="con_box">
              <ul className="divide">
                <ul className="layer_list">
                  <li>
                    <span>
                      {' '}
                      <Trans>Available Count</Trans>{' '}
                    </span>
                    <div className="board_list">
                      <table>
                        <colgroup>
                          <col style={{ width: '20%' }} />
                          <col style={{ width: '30%' }} />
                          <col style={{ width: '20%' }} />
                          <col />
                        </colgroup>
                        <thead>
                          <tr>
                            <th colSpan="2">Female</th>
                            <th colSpan="2">Male</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              {row.flockInQtyF
                                ? row.flockInQtyF.toLocaleString()
                                : 0}
                            </th>
                            <td>
                              <input
                                type="text"
                                name="inQtyF"
                                className="input_type2 w90"
                                value={this.state.inQtyF || ''}
                                onChange={(e, date, dateString) =>
                                  this.setOnChange(e, date, dateString)
                                }
                              />
                            </td>
                            <th>
                              {row.flockInQtyM
                                ? row.flockInQtyM.toLocaleString()
                                : 0}
                            </th>
                            <td>
                              <input
                                type="text"
                                name="inQtyM"
                                className="input_type2 w90"
                                value={this.state.inQtyM || ''}
                                onChange={(e) => this.setOnChange(e)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                  <li>
                    <button className="btn_blue">
                      <Trans>Send</Trans>
                      <i className="mdi mdi-arrow-right"></i>
                    </button>
                    <span style={{ width: '150px' }}>
                      {' '}
                      {this.state.inQty
                        ? this.state.inQty.toLocaleString()
                        : 0}{' '}
                      / {this.state.capa.toLocaleString()}
                    </span>
                  </li>
                  <li>
                    <span>
                      {' '}
                      <Trans>{row.hhId} 계사</Trans>{' '}
                    </span>
                  </li>
                  <li>
                    <Trans>Date : </Trans>
                    <DatePicker
                      name="moveDate"
                      value={
                        this.state.moveDate
                          ? moment(this.state.moveDate)
                          : moment(DateUtil.today())
                      }
                      onChange={(date, dateString) =>
                        this.onDateChange(date, dateString, 'moveDate')
                      }
                      allowClear={false}
                    />
                  </li>
                </ul>
              </ul>
              <div className="f_right mB10">
                <Popconfirm
                  title={i18n.t(
                    'Over Capacity\nExceeded the appropriate number.\nWould you like to put it though?'
                  )}
                  visible={this.state.visible}
                  onVisibleChange={this.handleVisibleChange}
                  onConfirm={this.confirm}
                  onCancel={this.cancel}
                  okText="OK"
                  cancelText="Cancel"
                >
                  <button
                    className="btn_save"
                    onClick={(e) => this.fnSave(row)}
                  >
                    <Trans>Save</Trans>
                  </button>
                  <button
                    className="btn_gray"
                    onClick={(e) => this.props.fnClosePop('popIn')}
                  >
                    <Trans>Cancel</Trans>
                  </button>
                </Popconfirm>
              </div>
            </div>
            {/* 디자인 영역 */}
          </div>
        </div>
      </Fragment>
    );
  }
}
export default PopIn;
