import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import FHDateRangeSearch from '../utils/FHDateRangeSearch';
import AFDateRangeSearch from '../utils/AFDateRangeSearch';
import ReportUtil from '../utils/ReportUtil';

import '../Report.module.css';

// 9. 산란기  기간/HH 별 보고서
export default class LayFlockHhR extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      excelName: 'Lay Term/HH Report',
      rowData: [],
      params: {},
      ColDefs: [
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Farm'),
              field: 'farm',
              pinned: 'left',
              suppressSizeToFit: true,
              colSpan: function (params) {
                const { farm } = params.data;
                switch (farm) {
                  case 'Farm Total':
                  case 'Region Total':
                  case 'Division Total':
                    return 4;
                }
                return 1;
              },
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Flock'),
              field: 'flock',
              pinned: 'left',
              suppressSizeToFit: true,
              minWidth: 120,
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('HH'),
              field: 'hh',
              pinned: 'left',
              suppressSizeToFit: true,
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('Chick - in'),
              children: [
                {
                  headerName: i18n.t('Date'),
                  field: 'ciDate',
                },
                {
                  headerName: i18n.t('Female'),
                  field: 'ciFemale',
                },
                {
                  headerName: i18n.t('Male'),
                  field: 'ciMale',
                },
              ],
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('No. of birds'),
              children: [
                {
                  headerName: i18n.t('Female'),
                  field: 'popsFemale',
                },
                {
                  headerName: i18n.t('Male'),
                  field: 'popsMale',
                },
                {
                  headerName: i18n.t('Ratio'),
                  field: 'popsRatio',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Depletion'),
          children: [
            {
              headerName: i18n.t('Female'),
              children: [
                {
                  headerName: i18n.t('Death'),
                  field: 'dfDeath',
                },
                {
                  headerName: i18n.t('Cull'),
                  field: 'dfCull',
                },
                {
                  headerName: i18n.t('%'),
                  field: 'dfRatio',
                },
              ],
            },
            {
              headerName: i18n.t('Male'),
              children: [
                {
                  headerName: i18n.t('Death'),
                  field: 'dmDeath',
                },
                {
                  headerName: i18n.t('Cull'),
                  field: 'dmCull',
                },
                {
                  headerName: i18n.t('%'),
                  field: 'dmRatio',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Feed'),
          children: [
            {
              headerName: i18n.t('Female'),
              children: [
                {
                  headerName: i18n.t('Total'),
                  field: 'ffTotal',
                },
                {
                  headerName: i18n.t('/Bird'),
                  field: 'ffBird',
                },
              ],
            },
            {
              headerName: i18n.t('Male'),
              children: [
                {
                  headerName: i18n.t('Total'),
                  field: 'fmTotal',
                },
                {
                  headerName: i18n.t('/Bird'),
                  field: 'fmBird',
                },
              ],
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('Egg Product'),
              children: [
                {
                  headerName: i18n.t('Qty'),
                  field: 'epQty',
                },
                {
                  headerName: i18n.t('HD %'),
                  field: 'epHD',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('culling'),
              field: 'culling',
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Good Egg'),
              field: 'goodEgg',
            },
          ],
        },
      ],
      gridHeight: '640px',
      defaultColDef: {
        width: 100,
        minWidth: 100,
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        cellClass: 'text-center',
        suppressSizeToFit: false,
        cellStyle: function (params) {
          const { farm } = params.node.data;
          switch (farm) {
            case 'Farm Total':
            case 'Region Total':
            case 'Division Total':
              return { 'background-color': Const.AGGR_COLOR };
          }
        },
        valueFormatter: function (params) {
          return GridUtil.formatReport(params);
        },
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagenation: false,
      paginationPageSize: 10,
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    if (Const.RPT_TEST) {
      const params = ReportUtil.getDefaultParams(this.props);
      console.log('params:', params);
      this.fnGetRowData(params);
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  // -----------------------------------------------------------
  // 참고용 : ES/ETL 서버 주소(API)를 지정해야 합니다.
  // -----------------------------------------------------------
  fnGetRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/stat/breeding/layTermHhR.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data.rows1 });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtExport = () => {
    const fileName =
      i18n.t(this.state.excelName) + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t(this.state.excelName);
    const params = {
      allColumns: false,
      columnGroups: true,
      fileName: fileName,
      sheetName: sheetName,
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onSearch = (params) => {
    console.log(params);
    this.fnGetRowData(params);
  };

  render() {
    return (
      <React.Fragment>
        <h3>
          {/* 산란기 계군/계사별 보고서 */}
          <Trans>Title Lay Flock/HH R</Trans>
        </h3>

        <div className="con_box">
          <AFDateRangeSearch onSearch={this.onSearch}></AFDateRangeSearch>

          <div className="f_right mB20">
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
          </div>
          <div className="board_list_s">
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  excelStyles={this.state.excelStyles}
                  onGridReady={this.onGridReady}
                  rowData={this.state.rowData}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

let i = 0;
const sampleData = [
  {
    farm: i++,
    flock: i++,
    hh: i++,
    ciDate: i++,
    ciFemale: i++,
    ciMale: i++,
    nbFemale: i++,
    nbMale: i++,
    nbRadio: i++,
    dfDeath: i++,
    dfCull: i++,
    dfPercent: i++,
    dmDeath: i++,
    dmCull: i++,
    dmPercent: i++,
    ffTotal: i++,
    ffBird: i++,
    fmTotal: i++,
    fmBird: i++,
    epQty: i++,
    epHD: i++,
    culling: i++,
    goodEgg: i++,
  },
];
