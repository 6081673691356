import React, { Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import i18n from 'i18next';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class Dashboard extends React.PureComponent {
  state = {
    rowIndexG2: 0,
    taskList: [],
    growingForm: [],
    breedingDashboardRowData: [],
    breedingDashboardColumnDefs: [
      {
        headerName: 'Flock',
        field: 'flockId',
        width: 120,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: true,
      },
      {
        headerName: 'Hen House',
        field: 'hhId',
        width: 100,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: true,
      },

      {
        headerName: 'Chick In',
        children: [
          {
            headerName: 'Date',
            field: 'chickinDate',
            width: 100,
            cellStyle: { textAlign: 'center' },
            suppressSizeToFit: true,
          },
          {
            headerName: 'F',
            field: 'chickinQtyF',
            type: 'numericColumn',
            width: 80,
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: true,
          },
          {
            headerName: 'M',
            field: 'chickinQtyM',
            type: 'numericColumn',
            width: 80,
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: true,
          },
        ],
      },
      {
        headerName: 'Age',
        field: 'days',
        width: 50,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: true,
      },
      {
        headerName: 'Pop',
        children: [
          {
            headerName: 'F',
            field: 'flockInQtyF',
            width: 80,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: true,
          },
          {
            headerName: 'M',
            field: 'flockInQtyM',
            width: 80,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: true,
          },
        ],
      },
      {
        headerName: 'Dep',
        children: [
          {
            headerName: 'F',
            field: 'breedQtyF',
            width: 80,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: true,
          },
          {
            headerName: 'M',
            field: 'breedQtyM',
            width: 80,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: true,
          },
        ],
      },
      {
        headerName: 'Fed',
        children: [
          {
            headerName: 'F',
            field: 'feedQtyF',
            width: 80,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: true,
          },
          {
            headerName: 'M',
            field: 'feedQtyM',
            width: 80,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: true,
          },
        ],
      },
      {
        headerName: 'Egg',
        children: [
          {
            headerName: 'Total',
            field: 'totalEggQty',
            width: 80,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: true,
          },
          {
            headerName: 'Cull',
            field: 'culling',
            width: 80,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: true,
          },
          {
            headerName: 'Good',
            field: 'goodEggQty',
            width: 80,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: true,
          },
          {
            headerName: 'Transfer',
            field: 'transferQty',
            width: 80,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: true,
          },
          {
            headerName: 'Good Stock',
            field: 'goodEggStock',
            width: 80,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: true,
          },
        ],
      },
    ],

    workscheduleRowData: [],
    workscheduleColumnDefs: [
      {
        headerName: 'Flock',
        field: 'flockId',
        width: 150,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: true,
      },
      {
        headerName: 'Age',
        field: 'ageDay',
        width: 50,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: true,
      },
      {
        headerName: 'Feed',
        field: 'feedCnt',
        width: 100,
        type: 'numericColumn',
        valueFormatter: function (params) {
          return GridUtil.formatNumber(params);
        },
        //precision: 0,
        cellStyle: { textAlign: 'right' },
      },
      {
        headerName: 'Vaccine',
        field: 'vaccineCnt',
        width: 100,
        type: 'numericColumn',
        valueFormatter: function (params) {
          return GridUtil.formatNumber(params);
        },
        //precision: 0,
        cellStyle: { textAlign: 'right' },
      },
      {
        headerName: 'Medicine',
        field: 'medicineCnt',
        width: 100,
        type: 'numericColumn',
        valueFormatter: function (params) {
          return GridUtil.formatNumber(params);
        },
        //precision: 0,
        cellStyle: { textAlign: 'right' },
      },
    ],
    defaultColDef: {
      editable: false,
      width: 100,
      filter: true,
      resizable: true,
      sortable: true,
      suppressSizeToFit: false,
    },
  };

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnSearch();
  }

  fnSearch = async () => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    //data.sql = 'selectBreedingDashboard';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/dashboard/selectDashboard.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            breedingDashboardRowData: response.data.rows1,
            workscheduleRowData: response.data.rows2,
          },
          function () {
            let rowIndexG2 = 0;
            if (
              that.state.workscheduleRowData &&
              that.state.workscheduleRowData.length > 0
            ) {
              rowIndexG2 = that.state.rowIndexG2 ? that.state.rowIndexG2 : 0;
              let selectedRows = that['g2'].getRowNode(rowIndexG2);
              selectedRows.setSelected(true);

              that.fnSearchDtl(selectedRows.data);
            }
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  onRowClicked = (e) => {
    let data = e.data;
    this.setState({ growingForm: [] }, function () {
      this.fnSearchDtl(data);
    });
  };
  fnSearchDtl = async (row) => {
    let data = row ? row : {};
    data.hhId = row ? row.hhId : this.state.hhId;
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.countryCode = SessionUtil.getCountryCode();
    data.userId = SessionUtil.getUserId();
    data.sql = 'selectTcTaskDtl'; //controller 없음

    const url = Server.getRestAPI();
    data.sex = 'M';
    await axios({
      method: 'post',
      url: url + '/breed/dashboard/selectSql.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            growingForm: {
              ...that.state.growingForm,
              ['flockId']: data.flockId,
              ['ageDay']: data.ageDay,
              ['feedCode1']: response.data.rows1[0]['feedCode1'],
              ['feedCode2']: response.data.rows1[0]['feedCode2'],
              ['feedCode3']: response.data.rows1[0]['feedCode3'],
              ['vaccineCode1']: response.data.rows1[0]['vaccineCode1'],
              ['vaccineCode2']: response.data.rows1[0]['vaccineCode2'],
              ['vaccineCode3']: response.data.rows1[0]['vaccineCode3'],
              ['medicineCode1']: response.data.rows1[0]['medicineCode1'],
              ['medicineCode2']: response.data.rows1[0]['medicineCode2'],
              ['medicineCode3']: response.data.rows1[0]['medicineCode3'],

              ['feedAmt1']: response.data.rows1[0]['feedAmt1'],
              ['feedAmt2']: response.data.rows1[0]['feedAmt2'],
              ['feedAmt3']: response.data.rows1[0]['feedAmt3'],
              ['vacAmt1']: response.data.rows1[0]['vacAmt1'],
              ['vacAmt2']: response.data.rows1[0]['vacAmt2'],
              ['vacAmt3']: response.data.rows1[0]['vacAmt3'],
              ['medAmt1']: response.data.rows1[0]['medAmt1'],
              ['medAmt2']: response.data.rows1[0]['medAmt2'],
              ['medAmt3']: response.data.rows1[0]['medAmt3'],

              ['feedUnit1']: response.data.rows1[0]['feedUnit1'],
              ['feedUnit2']: response.data.rows1[0]['feedUnit2'],
              ['feedUnit3']: response.data.rows1[0]['feedUnit3'],
              ['vaccineUnit1']: response.data.rows1[0]['vaccineUnit1'],
              ['vaccineUnit2']: response.data.rows1[0]['vaccineUnit2'],
              ['vaccineUnit3']: response.data.rows1[0]['vaccineUnit3'],
              ['medicineUnit1']: response.data.rows1[0]['medicineUnit1'],
              ['medicineUnit2']: response.data.rows1[0]['medicineUnit2'],
              ['medicineUnit3']: response.data.rows1[0]['medicineUnit3'],
            },
          },
          function () {
            console.log('%%%%%%%', that.state);
          }
        );
      })
      .catch(function (error) {
        return [];
      });
    data.sex = 'F';
    await axios({
      method: 'post',
      url: url + '/breed/dashboard/selectSql.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            growingForm: {
              ...that.state.growingForm,
              ['flockId']: data.flockId,
              ['ageDay']: data.ageDay,
              ['feedCodeM1']: response.data.rows1[0]['feedCode1'],
              ['feedCodeM2']: response.data.rows1[0]['feedCode2'],
              ['feedCodeM3']: response.data.rows1[0]['feedCode3'],
              ['vaccineCodeM1']: response.data.rows1[0]['vaccineCode1'],
              ['vaccineCodeM2']: response.data.rows1[0]['vaccineCode2'],
              ['vaccineCodeM3']: response.data.rows1[0]['vaccineCode3'],
              ['medicineCodeM1']: response.data.rows1[0]['medicineCode1'],
              ['medicineCodeM2']: response.data.rows1[0]['medicineCode2'],
              ['medicineCodeM3']: response.data.rows1[0]['medicineCode3'],

              ['feedAmtM1']: response.data.rows1[0]['feedAmt1'],
              ['feedAmtM2']: response.data.rows1[0]['feedAmt2'],
              ['feedAmtM3']: response.data.rows1[0]['feedAmt3'],
              ['vacAmtM1']: response.data.rows1[0]['vacAmt1'],
              ['vacAmtM2']: response.data.rows1[0]['vacAmt2'],
              ['vacAmtM3']: response.data.rows1[0]['vacAmt3'],
              ['medAmtM1']: response.data.rows1[0]['medAmt1'],
              ['medAmtM2']: response.data.rows1[0]['medAmt2'],
              ['medAmtM3']: response.data.rows1[0]['medAmt3'],

              ['feedUnitM1']: response.data.rows1[0]['feedUnit1'],
              ['feedUnitM2']: response.data.rows1[0]['feedUnit2'],
              ['feedUnitM3']: response.data.rows1[0]['feedUnit3'],
              ['vaccineUnitM1']: response.data.rows1[0]['vaccineUnit1'],
              ['vaccineUnitM2']: response.data.rows1[0]['vaccineUnit2'],
              ['vaccineUnitM3']: response.data.rows1[0]['vaccineUnit3'],
              ['medicineUnitM1']: response.data.rows1[0]['medicineUnit1'],
              ['medicineUnitM2']: response.data.rows1[0]['medicineUnit2'],
              ['medicineUnitM3']: response.data.rows1[0]['medicineUnit3'],
            },
          },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  render() {
    const dataArr = [1, 2, 3];
    let data = this.state.growingForm;
    return (
      <Fragment>
        <h2>
          <Trans>Dashboard</Trans>
        </h2>
        <div className="con_box">
          <h3>
            <Trans>Current State</Trans>
          </h3>
          <div
            style={{ height: '250px', width: '100%', marginBottom: '20px' }}
            className="board_list ag-theme-balham"
          >
            <AgGridReact
              onGridReady={(e) => this.onGridReady(e, 'g1')}
              columnDefs={this.state.breedingDashboardColumnDefs}
              rowData={this.state.breedingDashboardRowData}
              defaultColDef={this.state.defaultColDef}
              onCellFocused={(e) => {
                e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
              }}
              rowSelection={'single'}
            ></AgGridReact>
          </div>
          <div className="board_list">
            <ul className="divide">
              <li
                className="divide_left mR10"
                style={{ width: 'calc(41% - 8px)', marginRight: '5px' }}
              >
                <h3>
                  <Trans>Work Schedule</Trans>
                </h3>
                <div
                  style={{
                    height: '372px',
                    width: '100%',
                  }}
                  className="board_list ag-theme-balham"
                >
                  <AgGridReact
                    onGridReady={(e) => this.onGridReady(e, 'g2')}
                    columnDefs={this.state.workscheduleColumnDefs}
                    rowData={this.state.workscheduleRowData}
                    defaultColDef={this.state.defaultColDef}
                    onRowClicked={(e) => this.onRowClicked(e)}
                    onCellFocused={(e) => {
                      e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
                    }}
                    rowSelection={'single'}
                  ></AgGridReact>
                </div>
              </li>
              <li
                className="divide_right"
                style={{ width: 'calc(59% - 8px)', marginLeft: '5px' }}
              >
                <h3>
                  <Trans>Task</Trans>
                  {data.flockId ? '(' + data.flockId + ')' : ''}
                </h3>
                <div className="board_list2">
                  <table>
                    <colgroup>
                      <col style={{ width: '86px' }} />
                      <col style={{ width: '320px' }} />
                      <col />
                    </colgroup>
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          <Trans>Female</Trans>
                        </th>
                        <th>
                          <Trans>Male</Trans>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>
                          <Trans>Feed</Trans>
                        </th>
                        <td className="spac10_left label">
                          {dataArr.map((ix) => (
                            <div key={ix}>
                              {GridUtil.renderInput({
                                inputName: 'feedCodeM' + ix, //*** */
                                type: '', //*** */
                                width: 180, // px제외 : 없으면 30 */
                                statsForm: this.state.growingForm, //*** */
                                setOnChange: this.setOnChange, //*** */
                                disabled: true,
                                that: this, //*** */
                              })}{' '}
                              {GridUtil.renderInput({
                                inputName: 'feedAmtM' + ix, //*** */
                                type: 'N', //*** */
                                width: 70, // px제외 : 없으면 30 */
                                minVal: 0,
                                statsForm: this.state.growingForm, //*** */
                                setOnChange: this.setOnChange, //*** */
                                disabled: true,
                                that: this, //*** */
                                dec: 2,
                              })}{' '}
                              <span
                                style={{
                                  width: '30px',
                                  display: 'inline-block',
                                }}
                              >
                                {data['feedUnitM' + ix]}
                              </span>
                            </div>
                          ))}
                        </td>
                        <td className="spac10_left label">
                          {dataArr.map((ix) => (
                            <div key={ix}>
                              {GridUtil.renderInput({
                                inputName: 'feedCode' + ix, //*** */
                                type: '', //*** */
                                width: 180, // px제외 : 없으면 30 */
                                statsForm: this.state.growingForm, //*** */
                                setOnChange: this.setOnChange, //*** */
                                disabled: true,
                                that: this, //*** */
                              })}{' '}
                              {GridUtil.renderInput({
                                inputName: 'feedAmt' + ix, //*** */
                                type: 'N', //*** */
                                width: 70, // px제외 : 없으면 30 */
                                minVal: 0,
                                statsForm: this.state.growingForm, //*** */
                                setOnChange: this.setOnChange, //*** */
                                disabled: true,
                                that: this, //*** */
                                dec: 2,
                              })}{' '}
                            </div>
                          ))}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Trans>Vaccine</Trans>
                        </th>
                        <td className="spac10_left label">
                          {dataArr.map((ix) => (
                            <div key={ix}>
                              {GridUtil.renderInput({
                                inputName: 'vaccineCodeM' + ix, //*** */
                                type: '', //*** */
                                width: 180, // px제외 : 없으면 30 */
                                statsForm: this.state.growingForm, //*** */
                                setOnChange: this.setOnChange, //*** */
                                disabled: true,
                                that: this, //*** */
                              })}{' '}
                              {GridUtil.renderInput({
                                inputName: 'vacAmtM' + ix, //*** */
                                type: 'N', //*** */
                                width: 70, // px제외 : 없으면 30 */
                                statsForm: this.state.growingForm, //*** */
                                setOnChange: this.setOnChange, //*** */
                                that: this, //*** */
                                disabled: true,
                                dec: 2,
                              })}{' '}
                              {this.state.growingForm['vaccineUnitM' + ix]}
                            </div>
                          ))}
                        </td>
                        <td className="spac10_left label">
                          {dataArr.map((ix) => (
                            <div key={ix}>
                              {GridUtil.renderInput({
                                inputName: 'vaccineCode' + ix, //*** */
                                type: '', //*** */
                                width: 180, // px제외 : 없으면 30 */
                                statsForm: this.state.growingForm, //*** */
                                setOnChange: this.setOnChange, //*** */
                                disabled: true,
                                that: this, //*** */
                              })}{' '}
                              {GridUtil.renderInput({
                                inputName: 'vacAmt' + ix, //*** */
                                type: 'N', //*** */
                                width: 70, // px제외 : 없으면 30 */
                                statsForm: this.state.growingForm, //*** */
                                setOnChange: this.setOnChange, //*** */
                                that: this, //*** */
                                disabled: true,
                                dec: 2,
                              })}{' '}
                              {this.state.growingForm['vaccineUnit' + ix]}
                            </div>
                          ))}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Trans>Medicine</Trans>
                        </th>
                        <td className="spac10_left label">
                          {dataArr.map((ix) => (
                            <div key={ix}>
                              {GridUtil.renderInput({
                                inputName: 'medicineCodeM' + ix, //*** */
                                type: '', //*** */
                                width: 180, // px제외 : 없으면 30 */
                                statsForm: this.state.growingForm, //*** */
                                setOnChange: this.setOnChange, //*** */
                                disabled: true,
                                that: this, //*** */
                              })}{' '}
                              {GridUtil.renderInput({
                                inputName: 'medAmtM' + ix, //*** */
                                type: 'N', //*** */
                                width: 70, // px제외 : 없으면 30 */
                                statsForm: this.state.growingForm, //*** */
                                setOnChange: this.setOnChange, //*** */
                                that: this, //*** */
                                disabled: true,
                                dec: 2,
                              })}{' '}
                              {this.state.growingForm['medicineUnitM' + ix]}
                            </div>
                          ))}
                        </td>
                        <td className="spac10_left label">
                          {dataArr.map((ix) => (
                            <div key={ix}>
                              {GridUtil.renderInput({
                                inputName: 'medicineCode' + ix, //*** */
                                type: '', //*** */
                                width: 180, // px제외 : 없으면 30 */
                                statsForm: this.state.growingForm, //*** */
                                setOnChange: this.setOnChange, //*** */
                                disabled: true,
                                that: this, //*** */
                              })}{' '}
                              {GridUtil.renderInput({
                                inputName: 'medAmt' + ix, //*** */
                                type: 'N', //*** */
                                width: 70, // px제외 : 없으면 30 */
                                statsForm: this.state.growingForm, //*** */
                                setOnChange: this.setOnChange, //*** */
                                that: this, //*** */
                                disabled: true,
                                dec: 2,
                              })}{' '}
                              {this.state.growingForm['medicineUnit' + ix]}
                            </div>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}
