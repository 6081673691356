import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { Const, Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import 'moment/locale/ko.js';
import 'moment/locale/vi.js';
import { DateUtil } from 'utils';
import i18n from 'i18next';
import { Switch, message, DatePicker, Select, Popconfirm } from 'antd';
import NumberFormat from 'react-number-format';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';
/*
 ** Breed - Receive - Hen House - Move
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class PopStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      popStatusView: false,
      form: [],
      currentQty: 0,
      currentQtyF: 0,
      currentQtyM: 0,
      inQty: 0,
      inQtyF: 0,
      inQtyM: 0,
      chickinDate: '',
      moveDate: '',
      remarks: '',
      farmCodeList: [],
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.props.popMoveRow.layingSdate = DateUtil.today();
    this.setState({ form: this.props.popMoveRow });
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (prevState.popStatusView !== nextProps.popStatusView) {
  //     console.log('@@@@@@@@form', prevState, nextProps);
  //     return {
  //       form: nextProps.popMoveRow,
  //       popStatusView: nextProps.popStatusView
  //     };
  //   }
  //   return null;
  // }

  fnInit = () => {
    let _capa = Number(this.state.capacity);
    _capa =
      _capa - (Number(this.state.chickinQtyF) + Number(this.state.chickinQtyM));

    this.setState(
      {
        moveDate: this.state.fromChickinDate,
        capa: _capa,
        inQtyF: 0,
        inQtyM: 0,
        remarks: '',
      },
      function () {}
    );
  };

  fnSave = () => {
    let that = this;
    let data = this.state.form;

    if (!this.state.form.layingSdate) {
      this.props.alert.show(i18n.t('Please enter LayingS Date.'));
      this.layingSdate.focus();
      return false;
    }

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.updateId = SessionUtil.getUserId();

    console.log('saveMoveout', data);
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/growing/saveLayingSdate.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.fnSearch();
        that.props.handleCancel();
      })
      .catch(function (error) {
        return [];
      });
  };
  handleVisibleChange = (visible) => {
    if (!visible) {
      this.setState({ visible });
      return;
    }
  };
  confirm = () => {
    let that = this;
    let data = this.state.form;
    let moveQtyF = Number(data.moveQtyF ? data.moveQtyF : 0);
    let moveQtyM = Number(data.moveQtyM ? data.moveQtyM : 0);

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.moveDate = data.moveDate ? data.moveDate : DateUtil.today();
    data.moveQtyF = moveQtyF;
    data.moveQtyM = moveQtyM;
    data.iuFlag = 'I';
    //empty check
    if (!data.flockId) {
      data.flockId = data.fromFlockId;
      //data.hhId = data.fromHhId;
    }

    //data.receiveNo = '';
    console.log('saveMoveout', data);
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/saveMovein.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.fnInit();
        that.props.fnClosePop('popMoveInView');
        // that.setState(
        //   { showFlockList: true, tpBreedRecvData: response.data.rows1[0] },
        //   function() {
        //     console.log(that.state);
        //   }
        // );
      })
      .catch(function (error) {
        return [];
      });
  };
  cancel = () => {
    this.setState({ visible: false });
  };
  onDateChange = (date, dateString, name) => {
    this.setState({ [name]: dateString }, function () {
      console.log(this.state);
    });
  };

  setOnChange = (e, params) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }

    that.setState({ form: { ...this.state.form, [col]: val } }, function () {});
  };

  render() {
    if (!this.props.popStatusView) return null;

    let row = this.state.form;

    return (
      <Fragment>
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            margin: 'auto',
            zIndex: 1000,
            backgroundColor: 'rgba(0,0,0, 0.5)',
          }}
        >
          <div
            style={{
              position: 'absolute',
              width: '350px',
              height: '210px',
              left: '50%',
              transform: 'translateX(-50%)',
              top: '100px',
              //bottom: '15%',
              margin: 'auto',
              borderRadius: '10px',
              background: 'white',
            }}
          >
            <div className="pop_box">
              <h3>
                <Trans>Laying Start Date</Trans>
              </h3>
              <ul className="divide mT10">
                <ul className="layer_list">
                  <li style={{ width: '100%' }}>
                    <span className="f_left">
                      <Trans>Date : </Trans>{' '}
                      {GridUtil.renderInput({
                        inputName: 'layingSdate', //*** */
                        type: 'D', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                        placeholder: 'startDate',
                      })}
                    </span>
                  </li>
                </ul>
              </ul>
              <div className="f_right mT10 mB30">
                <button className="btn_save" onClick={(e) => this.fnSave(row)}>
                  <Trans>Save</Trans>
                </button>
                <button
                  className="btn_gray"
                  onClick={(e) => this.props.handleCancel()}
                >
                  <Trans>Cancel</Trans>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withAlert()(PopStatus);
