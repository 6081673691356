import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, FormUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import '../Common.module.css';

import CustomerName from 'containers/pages/common/basic/CustomerName';
import MaterialType from 'containers/pages/common/system/MaterialType';
import MaterialName from './MaterialName';

import { Input, Radio, DatePicker } from 'antd';
import produce from 'immer';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { message } from 'antd';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class MaterialInfoView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rowNode: {},
      params: {
        materialCode: undefined,
        materialName: undefined,
        materialType: undefined,
        usingClass: undefined,
        msize: undefined,
        unit: undefined,
        spec: undefined,
        deliveryDay: undefined,
        remarks: undefined
      }
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.rowNode !== this.props.rowNode) {
      let { rowNode } = this.props;
      console.log(rowNode);
      this.setState(
        produce(draft => {
          draft.params['materialCode'] = rowNode['materialCode'];
          draft.params['materialName'] = rowNode['materialName'];
          draft.params['materialTypeName'] = rowNode['materialTypeName'];
          draft.params['usingClassName'] = rowNode['usingClassName'];
          draft.params['msize'] = rowNode['msize'];
          draft.params['unit'] = rowNode['unit'];
          draft.params['spec'] = rowNode['spec'];
          draft.params['deliveryDay'] = rowNode['deliveryDay'];
          draft.params['remarks'] = rowNode['remarks'];
        }),
        function() {
          // console.log('changed params : ', this.state.params);
        }
      );
    }
  }

  render() {
    if (!this.props.visable) return null;
    return (
      <Fragment>
        {/* Feed Stocking */}
        <div className="con_box">
          <h3>
            <Trans>Material Info</Trans>
          </h3>

          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Material Code</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'materialCode',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Material Name</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'materialName',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Material Type</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'materialTypeName',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Using Class</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'usingClassName',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Size</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'msize',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Unit</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'unit',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Spec</Trans>
                  </th>
                  <td colSpan="3">
                    {FormUtil.renderInput({
                      inputName: 'spec',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      className: 'w-90'
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Delivery Day</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'deliveryDay',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'remarks',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    );
  }
}
