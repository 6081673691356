import React, { Fragment, Component } from 'react';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';
import axios from 'axios';
import qs from 'qs';
import { moment } from 'components';
import { Server } from 'components/Properties';
import { Select } from 'antd';
import { observer, inject } from 'mobx-react';
import i18n from 'i18next';
import DeliverInputForm from 'components/mobile/deliver/DeliverInputForm';
import ModalContainer from 'containers/pages/mobile/common/ModalContainer';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 ** 4. 파일명과 클래스명을 동일하게 해 주세요.
 */

@inject((stores) => ({
  mStore: stores.MobileStore,
  mProjectStore: stores.MprojectStore,
  mDeliverStore: stores.MDeliverStore,
}))
@observer
class DeliverForm extends Component {
  componentDidMount() {
    this.fnSelectGrowingList();
  }

  componentWillUnmount() {
    const { mDeliverStore, mStore } = this.props;
    mDeliverStore.initSotre();
    mStore.setIuFlagInsert();
  }

  // 육성일지 목록을 불러옵니다. ----> 최근 1주일 중 육성일지가 작성된 날에만 출고가 가능합니다.
  fnSelectGrowingList = () => {
    const { mProjectStore, mStore } = this.props;

    let params = {};
    params.farmCode = SessionUtil.getFarmCode();
    params.dateFormat = SessionUtil.getDateFormat();
    params.countryCode = SessionUtil.getCountryCode();
    params.hatcheryId = SessionUtil.getHatcheryId();
    params.userId = SessionUtil.getUserId();
    params.projCode = SessionUtil.getProjCode();
    params.startDate = DateUtil.toDateFormat(
      sessionStorage.getItem('startDate')
    );

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/mobile/broiler/mselectGlowingLogListForDeliver.do',
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then((response) => {
        this.fnSelectSuccess(response.data);
      })
      .catch(function (error) {
        return [];
      });
  };

  //해당 날의 육성일지 정보 가공 --> 날짜 선택에 따라 해당일자의 정보가 나와야 합니다.
  fnSelectSuccess = (value) => {
    if (sessionStorage.getItem('iuFlag') == 'U') {
      this.fnSelectDeliverInfo();
    }
    const { mDeliverStore } = this.props;
    var logListOrigin = value.rows1;
    var logList = [];

    for (var j = 0; j < logListOrigin.length; j++) {
      if (logListOrigin[j].wdate != undefined) {
        logList.push(logListOrigin[j]);
      }
    }
    //logList.reverse();
    var stock = Number(value.stock[0].stock ? value.stock[0].stock : 0);
    logList[0].prevPop = logList[0].receiveQty;
    logList[0].population =
      Number(logList[0].receiveQty) -
      Number(logList[0].deathQty) -
      Number(logList[0].cullingQty) -
      Number(logList[0].harvestSale);
    logList.forEach((row, i) => {
      if (i === 0) {
        row.iuFlag = row.workDate == undefined ? 'I' : 'U';
        return;
      }
      row.iuFlag = row.workDate == undefined ? 'I' : 'U';
      row.prevPop = logList[i - 1].population;
      row.population =
        row.prevPop -
        Number(row.deathQty) -
        Number(row.cullingQty) -
        Number(row.harvestSale);
    });
    mDeliverStore.setGrowLogListForDel(logList);
  };

  fnOpenSearchModal = (value) => {
    const { mStore } = this.props;
    mStore.setSearchType(value);
    mStore.setModalOpen('S', null, null);
    this.fnSelectCustomer();
  };

  fnOpenConfirmModal = (value, msg) => {
    const { mStore } = this.props;
    mStore.setModalOpen('C', value, msg);
  };

  //출고 정보 저장 이벤트
  fnModalPos = () => {
    const { mStore } = this.props;
    mStore.setModalClose();
    this.fnInsertDeliver();
  };

  fnModalWarning = () => {
    const { mStore } = this.props;
    mStore.setModalClose();
    this.props.history.push('/mobile/broiler/deliver');
  };

  //Customer 코드 찾기
  fnSelectCustomer = (value) => {
    const { mStore } = this.props;

    let params = {};

    if (value != '') {
      mStore.setSearchKey(value);
    }

    params.searchKey = mStore.searchKey;
    params.pageNo = mStore.searchPageNo;
    params.pageSize = 10;

    const that = this;
    let url = Server.getRestAPI() + '/basis/selectTcCustomer.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          mStore.setSearchResultList(response.data);
          mStore.setSearchRowCnt(response.data.length);
          if (response.data.length > 0) {
            mStore.setSearchTotal(response.data[0].total);
          }
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  // 날짜 선택 이벤트
  fnSetLogDate = (value) => {
    const { mDeliverStore } = this.props;
    mDeliverStore.setSelectedGrowLogForDel(
      mDeliverStore.growLogListForDel[value]
    );
    mDeliverStore.setWorkDate(mDeliverStore.growLogListForDel[value].wdate);
  };

  //출고 정보 저장 API
  fnInsertDeliver = () => {
    const { mDeliverStore } = this.props;
    let params = mDeliverStore.delSaveForm;

    params.farmCode = SessionUtil.getFarmCode();
    params.dateFormat = SessionUtil.getDateFormat();
    params.updateId = SessionUtil.getUserId();
    params.projCode = SessionUtil.getProjCode();
    params.iuFlag = sessionStorage.getItem('iuFlag');

    params.transferQty =
      Number(mDeliverStore.delSaveForm.cullingQty) +
      Number(mDeliverStore.delSaveForm.harvestQty);
    params.transferWeight =
      Number(mDeliverStore.delSaveForm.cullingWeight) +
      Number(mDeliverStore.delSaveForm.harvestWeight);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/mobile/broiler/mInsertTbTransferDtl.do',
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then((response) => {
        this.props.history.push('/mobile/broiler/deliver');
      })
      .catch(function (error) {
        return [];
      });
  };

  //Dliver 단건 내용 조회(for Update)
  fnSelectDeliverInfo = () => {
    const { mDeliverStore } = this.props;
    let params = {};

    params.farmCode = SessionUtil.getFarmCode();
    params.dateFormat = SessionUtil.getDateFormat();
    params.projCode = SessionUtil.getProjCode();
    params.seqNo = sessionStorage.getItem('deliverSeqNo');

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/mobile/broiler/mSelectTbTransferDtl.do',
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then((response) => {
        mDeliverStore.setFormDeliverUpdate(response.data.rows1);

        mDeliverStore.setPrevHarvestQty(
          Number(response.data.rows1[0].harvestQty)
        );
        mDeliverStore.setPrevHarvestWeight(
          Number(response.data.rows1[0].harvestWeight)
        );

        if (sessionStorage.getItem('iuFlag') == 'U') {
          for (var i = 0; i < mDeliverStore.growLogListForDel.length; i++) {
            if (
              mDeliverStore.growLogListForDel[i].workDate ==
              response.data.rows1[0].workDate
            ) {
              mDeliverStore.setSelectedGrowLogForDel(
                mDeliverStore.growLogListForDel[i]
              );
            }
          }
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  fnPushBack = () => {
    this.props.history.push('/mobile/broiler/deliver');
  };

  //Harvest 유효성검사
  fnValidationHarvestQty = (value) => {
    const { mStore, mDeliverStore } = this.props;
    if (value < 0) {
      mStore.setModalOpen('A', null, i18n.t('Qty can not be minus.'));
      mDeliverStore.setHarvestQty(0);
    }

    if (sessionStorage.getItem('iuFlag') == 'I') {
      if (value > mDeliverStore.selectedGrowLogForDel.population) {
        mStore.setModalOpen(
          'A',
          null,
          i18n.t('Harvest can not be over Population.')
        );
        mDeliverStore.setHarvestQty(0);
      }
    }
  };

  //Culling 유효성검사
  fnValidationCullingQty = (value) => {
    const { mStore, mDeliverStore } = this.props;
    if (value < 0) {
      mStore.setModalOpen('A', null, i18n.t('Qty can not be minus.'));
      mDeliverStore.setCullingQty(0);
    }

    if (value > mDeliverStore.selectedGrowLogForDel.cullingQty) {
      mStore.setModalOpen(
        'A',
        null,
        i18n.t('Culling can not be over Culling of GrowingInfo. ')
      );

      mDeliverStore.setCullingQty(0);
    }
  };

  //Weight 유효성검사
  fnValidationWeight = (value, type) => {
    const { mStore, mDeliverStore } = this.props;
    if (value < 0) {
      mStore.setModalOpen('A', null, i18n.t('Weight can not be minus.'));
      if (type == 'C') {
        mDeliverStore.setCullingWeight(0);
      }
      if (type == 'H') {
        mDeliverStore.setHarvestWeight(0);
      }
    }
  };

  render() {
    const { mStore, mDeliverStore } = this.props;
    return (
      <Fragment>
        {mStore.headerYn == false ? '' : <MobileHeader mStore={mStore} />}

        <div className="pop_header">
          <div className="mback" onClick={(e) => this.fnPushBack()}>
            <Trans>back</Trans>
          </div>
          [{sessionStorage.getItem('projCode')}]
          <div
            className="mobile_header_confirm"
            onClick={(e) =>
              this.fnOpenConfirmModal(
                'S',
                i18n.t('Are you sure you want to save it?')
              )
            }
          >
            <Trans>Save</Trans>
          </div>
        </div>
        <div className="mobile_content_nofooter">
          <p className="mobile_deliverform_title_sub">
            <Trans>Growing Info</Trans>
          </p>

          <div
            className="recebox2"
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            <ul>
              <li>
                <span style={{ width: '50%' }}>
                  <Trans>Date</Trans>
                  <span className="mobile_form_necs">*</span>
                </span>
                <Select
                  className="mobile_select"
                  name="mobile_select"
                  style={{ width: '50%', height: 40 }}
                  placeholder={i18n.t('Please Select Date')}
                  onChange={(e) => {
                    this.fnSetLogDate(e);
                  }}
                  value={mDeliverStore.delSaveForm.workDate}
                  disabled={
                    sessionStorage.getItem('iuFlag') == 'U' ? true : false
                  }
                >
                  {mDeliverStore.growLogListForDel.map((row, i) => {
                    return (
                      <Select.Option key={i} value={i}>
                        {row.wdate}
                      </Select.Option>
                    );
                  })}
                </Select>
              </li>
              <li>
                <span
                  style={{
                    width:
                      localStorage.getItem('i18nextLng') == 'ko'
                        ? '15%'
                        : '24%',
                  }}
                >
                  <Trans>workDate</Trans>
                </span>
                <span style={{ width: '2%' }}>(</span>
                <span style={{ width: '9%' }}>
                  <Trans>Age</Trans>
                </span>
                <span
                  style={{
                    width: '2%',
                    marginRight:
                      localStorage.getItem('i18nextLng') == 'ko'
                        ? '32%'
                        : '15%',
                  }}
                >
                  )
                </span>
                {moment(mDeliverStore.selectedGrowLogForDel.workDate).format(
                  'YYYY[-]MM[-]DD'
                )}
                (
                {mDeliverStore.selectedGrowLogForDel.ageDay == undefined
                  ? 0
                  : mDeliverStore.selectedGrowLogForDel.ageDay}
                )
              </li>
              <li>
                <span style={{ width: '52%' }}>
                  <Trans>Population</Trans>
                </span>
                {sessionStorage.getItem('iuFlag') == 'I' &&
                mDeliverStore.delSaveForm.workDate == ''
                  ? ''
                  : sessionStorage.getItem('iuFlag') == 'I' &&
                    mDeliverStore.delSaveForm.workDate != ''
                  ? Number(mDeliverStore.selectedGrowLogForDel.population)
                  : sessionStorage.getItem('iuFlag') == 'U'
                  ? Number(mDeliverStore.selectedGrowLogForDel.prevPop) -
                    Number(mDeliverStore.selectedGrowLogForDel.cullingQty) -
                    Number(mDeliverStore.selectedGrowLogForDel.deathQty)
                  : ''}
              </li>
              <li>
                <span style={{ width: '52%' }}>
                  <Trans>Culling</Trans>
                </span>
                {mDeliverStore.selectedGrowLogForDel.cullingQty}
              </li>
            </ul>
          </div>

          <p className="mobile_deliverform_title">
            <Trans>Input Deliver Info</Trans>
          </p>
          <DeliverInputForm
            mStore={mStore}
            mDeliverStore={mDeliverStore}
            fnOpenSearchModal={this.fnOpenSearchModal}
            growLogListForDel={mDeliverStore.growLogListForDel}
            fnValidationHarvestQty={this.fnValidationHarvestQty}
            fnValidationCullingQty={this.fnValidationCullingQty}
            fnValidationWeight={this.fnValidationWeight}
          />
        </div>
        <ModalContainer
          confirm={this.fnModalPos}
          warning={this.fnModalWarning}
          fnSelectCustomer={this.fnSelectCustomer}
        />
      </Fragment>
    );
  }
}
2;

export default DeliverForm;
