import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import scrollToComponent from 'react-scroll-to-component';
import i18n from 'i18next';

import GradingList from './GradingList';
import SettingList from './SettingList';
import SettingInfoForm from './SettingInfoForm';
import { Const, Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';

import '../Hatchery.module.css';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 ** 4. colSpan => conSpan, rowSpan => rowSpan 입니다.
 */
export default class SettingPage0 extends React.Component {
  state = {
    settingInfoFormView: false,
    fnSettingInfo: [],
    refresh: false,
    regDate: DateUtil.toDateTimeString()
  };

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  fnClickSetting = data => {
    const that = this;
    let data1 = data;

    data1.farmCode = SessionUtil.getFarmCode();
    data1.hatcheryId = SessionUtil.getHatcheryId();
    data1.dateFormat = SessionUtil.getDateFormat();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/setting/getCuttingEggQty.do',
      data: qs.stringify(data1),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        that.setState(
          { cuttingEggQty: response.data.rows1[0].cuttingEggQty, iuFlag: 'I' },
          function() {
            this.fnGetSetterInfo();
          }
        );
      })
      .catch(function(error) {});

    this.setState(
      { fnGradingInfo: data, settingInfoFormView: true },
      function() {
        this.fnGetSetterInfo();
        scrollToComponent(this['settingInfoFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000
        });
      }
    );
  };

  fnSetRegDate = () => {
    this.setState({
      regDate: DateUtil.toDateTimeString(),
      settingInfoFormView: false
    });
  };

  fnGetSetterInfo = () => {
    let that = this;
    let data = {};
    data.farmCode = SessionUtil.getFarmCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.dateFormat = SessionUtil.getDateFormat();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/setting/getSetterInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        that.setState({ setterInfo: response.data.rows1 }, function() {});
      })
      .catch(function(error) {
        return [];
      });
  };

  fnClickModify = data => {
    let that = this;
    const url = Server.getRestAPI();

    data.farmCode = SessionUtil.getFarmCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.dateFormat = SessionUtil.getDateFormat();

    axios({
      method: 'post',
      url: url + '/hatchery/setting/getModifyInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        console.log('response', response.data);
        that.setState(
          {
            fnGradingInfo: response.data.rows1[0],
            fnSettingInfo: data,
            setterInfo: response.data.rows3,
            cuttingEggQty: response.data.rows4[0].cuttingEggQty,
            settingInfoFormView: true,
            iuFlag: 'U'
          },
          function() {
            scrollToComponent(this['settingInfoFormView'], {
              offset: 0,
              align: 'top',
              duration: 1000
            });
          }
        );
      })
      .catch(function(error) {
        return [];
      });
  };

  fnClickCancel = () => {
    this.setState({ settingInfoFormView: false }, function() {});
  };

  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Setting</Trans>
        </h2>

        <GradingList
          fnClickSetting={this.fnClickSetting}
          regDate={this.state.regDate}
        />

        <SettingInfoForm
          ref={section => {
            this.settingInfoFormView = section;
          }}
          settingInfoFormView={this.state.settingInfoFormView}
          fnSettingInfo={this.state.fnSettingInfo}
          fnGradingInfo={this.state.fnGradingInfo}
          setterInfo={this.state.setterInfo}
          cuttingEggQty={this.state.cuttingEggQty}
          fnSetRegDate={this.fnSetRegDate}
          iuFlag={this.state.iuFlag}
          fnClickCancel={this.fnClickCancel}
        />

        <SettingList
          regDate={this.state.regDate}
          fnClickModify={this.fnClickModify}
          fnSetRegDate={this.fnSetRegDate}
        />

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
