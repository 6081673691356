import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import ScheduleRegist from './ScheduleRegist';
import ScheduleModify from './ScheduleModify';

import { Radio } from 'antd';
import produce from 'immer';
import ScheduleDtlExcel from './ScheduleDtlExcel';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import { Upload, message, Button, Icon } from 'antd';
import ExcelUpload from './ExcelUpload';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class ScheduleDtl extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      // gridApi: null,
      gridHeight: '365px',
      regDate: DateUtil.toDateTimeString(),
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        suppressSizeToFit: true,
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      paginationPageSize: 10,
      // editType: 'fullRow', // selectEditor와 함께 사용하지 말라고 하네요...
      ColDefs: [
        {
          headerName: i18n.t('Age of Day'),
          // 데이타 로우의 rowSpan
          // rowSpan: function(params) {
          //  return params.data.country==='Russia' ? 2 : 1;
          // },
          field: 'ageDay',
          width: 100,
          minWidth: 100,
          cellClass: 'textFormat',
          getQuickFilterText: function (params) {
            return params.value;
          },
        },
        { field: 'farmCode', hide: true },
        { field: 'strainCode', hide: true },
        { field: 'sex', hide: true },
        {
          headerName: i18n.t('Feed'),
          // headerGroupComponent: FeedGroupComponent,
          children: [
            {
              headerName: i18n.t('Name'),
              field: 'feedName1',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Amount'),
              field: 'feedAmt1',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Name'),
              field: 'feedName2',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Amount'),
              field: 'feedAmt2',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Name'),
              field: 'feedName3',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Amount'),
              field: 'feedAmt3',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
          ],
          editable: false,
        },
        {
          headerName: i18n.t('Vaccine'),
          // headerGroupComponent: VaccineGroupComponent,
          children: [
            {
              headerName: i18n.t('Name'),
              field: 'vaccineName1',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Amount'),
              field: 'vacAmt1',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Name'),
              field: 'vaccineName2',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Amount'),
              field: 'vacAmt2',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Name'),
              field: 'vaccineName3',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Amount'),
              field: 'vacAmt3',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
          ],
          editable: false,
        },
        {
          headerName: i18n.t('Medicine'),
          // headerGroupComponent: FeedGroupComponent,
          children: [
            {
              headerName: i18n.t('Name'),
              field: 'medicineName1',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Amount'),
              field: 'medAmt1',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Name'),
              field: 'medicineName2',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Amount'),
              field: 'medAmt2',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Name'),
              field: 'medicineName3',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Amount'),
              field: 'medAmt3',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
          ],
          editable: false,
        },
        {
          headerName: i18n.t('Remarks'),
          field: 'remarks',
          cellClass: 'textFormat',
        },
      ],
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
      scheduleDtlExcelView: false,
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  // deplicated : componentWillReceiveProps(nextProps)
  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log(prevProps.mastParams, this.props.mastParams);
    if (prevProps.mastParams !== this.props.mastParams) {
      return this.fnGetRowData(this.props.mastParams);
    }

    console.log(prevState.regDate, this.state.regDate);
    if (prevState.regDate !== this.state.regDate) {
      return this.fnGetRowData(this.props.mastParams);
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    // this.setState({ gridApi: params.api });
  };

  onChange = (info) => {
    console.log(info);
  };

  onBtExcelUpload = (e) => {
    this.setState({ scheduleDtlExcelView: true });
  };

  onBtExport = () => {
    const fileName =
      i18n.t('Work Schedule') + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t('Detail');
    const params = {
      columnWidth: 120,
      fileName: fileName,
      sheetName: sheetName,
      // exportMode: 'xml',
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onBtClose = (val) => {
    this.setState({ [val]: false });
  };

  fnGetRowData = (params) => {
    const that = this;
    let url = Server.getRestAPI() + '/work/selectTcTaskDtl.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  fnSetRegDate = () => {
    const that = this;
    this.setState({ regDate: DateUtil.toDateTimeString() }, function () {});
  };

  render() {
    if (!this.props.mastSelected) return null;
    return (
      <Fragment>
        <div className="con_box">
          <div className="f_left mB20">
            <h3>Detail</h3>
          </div>
          <div className="f_right mB20">
            <button
              className="btn_sky"
              onClick={() => {
                location.href =
                  Server.getRestAPI() +
                  '/dl.do' +
                  '?fid=3&key=' +
                  Const.APP_DOWNLOAD_KEY;
              }}
            >
              <Trans>Excel Form Download</Trans>
            </button>
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
            <button className="btn_sky" onClick={this.onBtExcelUpload}>
              <Trans>Excel Upload</Trans>
            </button>
          </div>

          <div className="board_list_s">
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs}
                  // components={this.state.components}
                  // frameworkComponents={this.state.frameworkComponents}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  editType={this.state.editType}
                  // enterMovesDownAfterEdit={true}
                  // enterMovesDown={true}
                  // rowModelType={this.state.rowModelType}

                  excelStyles={this.state.excelStyles}
                  onGridReady={this.onGridReady}
                  onCellEditingStarted={this.onCellEditingStarted}
                  onCellEditingStopped={this.onCellEditingStopped}
                  onRowClicked={this.onRowSelected}
                  onRowSelected={this.onRowSelected}
                  onRowEditingStarted={this.onRowEditingStarted}
                  onRowEditingStopped={this.onRowEditingStopped}
                  onCellValueChanged={this.onCellValueChanged}
                  rowData={this.state.rowData}
                  pagination={true}
                  paginationPageSize={this.state.paginationPageSize}
                />
              </div>
            </div>
          </div>
        </div>

        <ScheduleDtlExcel
          mastParams={this.props.mastParams}
          scheduleDtlExcelView={this.state.scheduleDtlExcelView}
          setRegDate={this.fnSetRegDate}
          fnClose={this.onBtClose}
        />

        {/*
          2020.02.11 by 장태학 설계 변경
          프로그램 설계변경 - 등록/수정 없음.
          엑셀업로드와 마스터 삭제 기능으로 수정시는 재업로드 필요.
        <ScheduleRegist />
        <ScheduleModify />
        */}
      </Fragment>
    );
  }
}
