import React, { Fragment } from 'react';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import ReceiveInfoForm from './ReceiveInfoForm';
import FarmTransferListMast from './FarmTransferListMast';
import FarmTransferListDtl from './FarmTransferListDtl';
import FarmReceivedList from './FarmReceivedList';
import scrollToComponent from 'react-scroll-to-component';

import '../Hatchery.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class RcvFarm extends React.Component {
  state = {
    receivingFormView: false,
    regDate: DateUtil.toDateTimeString(),
    transferInfo: [],
    transferQty: '',
    iuFlag: '',
  };
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  fnSelectTransferNo = (data) => {
    this.setState(
      {
        transferInfo: data,
        iuFlag: 'I',
        transferQty: data.transferQty,
        receivingFormView: true,
      },
      function () {
        scrollToComponent(this['receivingFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: this.getFieldValue(event.target),
    });
  }

  fnSetRegDate = () => {
    this.setState({
      regDate: DateUtil.toDateTimeString(),
      receivingFormView: false,
    });
  };

  fnClickModify = (data) => {
    console.log('asdcad', data);
    this.setState(
      {
        transferInfo: data,
        receiveQty: data.receiveQty,
        transferQty: data.transferQty,
        iuFlag: 'U',
        receivingFormView: true,
      },
      function () {
        scrollToComponent(this['receivingFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };

  fnClickCancel = () => {
    this.setState({ receivingFormView: false }, function () {});
  };

  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Receive - Farm</Trans>
        </h2>

        <FarmTransferListMast
          fnSelectTransferNo={this.fnSelectTransferNo}
          regDate={this.state.regDate}
        />

        <FarmTransferListDtl
          ref={(section) => {
            this.receivingFormView = section;
          }}
          receivingFormView={this.state.receivingFormView}
          transferInfo={this.state.transferInfo}
          transferQty={this.state.transferQty}
          receiveQty={this.state.receiveQty}
          fnClickReceive={this.fnClickReceive}
          fnSetRegDate={this.fnSetRegDate}
          fnClickCancel={this.fnClickCancel}
          iuFlag={this.state.iuFlag}
        />

        <FarmReceivedList
          regDate={this.state.regDate}
          fnSetRegDate={this.fnSetRegDate}
          fnClickModify={this.fnClickModify}
        />

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
