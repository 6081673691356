/* eslint-disable no-undef */
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil, PagingUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import { Input, Popconfirm, Switch, message, DatePicker, Select } from 'antd';
import NumberFormat from 'react-number-format';
import { withAlert } from 'react-alert';
import '../Common.module.css';

/*
 ** Flock 관리
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class FlockInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInstance: this,
      form: [{ iuFlag: 'I', flockCdate: DateUtil.today() }],
      tpBreedRecvData: {},
      rowData: [],
      strainList: [],
      formView: true,
      flockView: false,
      delView: false,
      columnDefs: [
        // {
        //   headerName: '',
        //   field: 'ck',
        //   width: 70,
        //   cellStyle: { textAlign: 'center' },
        //   editable: true,
        //   cellRenderer: params => {
        //     return '<button class="btn_sel">' + i18n.t('선택') + '</button>';
        //   },
        //   suppressSizeToFit: true
        // },
        {
          headerName: 'Flock',
          field: 'flockId',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Flock Date',
          field: 'flockCdate',
          width: 110,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Strain',
          field: 'strainName',
          width: 80,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Chickin Date',
          field: 'chickinDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Status',
          field: 'flockStatusName',
          width: 80,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Laying Start Date',
          field: 'layingSdate',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Closing Start Date',
          field: 'closingSdate',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Closed Date',
          field: 'closedDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Remarks',
          field: 'remarks',
          width: 150,
          cellStyle: { textAlign: 'left' },
        },
        {
          headerName: 'Total',
          field: 'totalCnt',
          width: 100,
          cellStyle: { textAlign: 'right' },
        },
      ],
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        suppressSizeToFit: false,
      },
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    this.setState(
      {
        tpBreedRecvData: this.props.tpBreedRecvData,
      },
      function () {}
    );
    this.fnSearch();
    this.fnNewFlock();
    //this.fnNewFlock();
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (prevState.flockView !== nextProps.flockView) {
  //     console.log('getDerivedStateFromProps', prevState, nextProps);
  //     prevState.currentInstance.fnSearch('F');
  //     return {
  //       flockView: nextProps.flockView
  //     };
  //   }
  //   return null;
  // }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  fnSearch = (val) => {
    let data = {};
    let that = this;

    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    //data.receiveNo = '';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/selectFlockListInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            rowData: response.data.rows1,
            strainList: response.data.rows2,
            delView: false,
          },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  fnSave = (e) => {
    //validate
    let that = this;
    let data = this.state.form;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();

    if (!data.iuFlag) {
      data.iuFlag = 'I';
    }
    if (data.iuFlag === 'I') {
      data.flockStatus = 'A';
    }

    if (!data.flockCdate) {
      that.props.alert.show(
        i18n.t('flockCdate') + i18n.t('을(를) 입력하세요.')
      );
      this.flockCdate.focus();
      return false;
    }

    if (!data.strainCode) {
      that.props.alert.show(
        i18n.t('strainCode') + i18n.t('을(를) 입력하세요.')
      );
      this.strainCode.focus();
      return false;
    }

    if (!data.flockInQtyF) data.flockInQtyF = 0;
    if (!data.flockInQtyM) data.flockInQtyM = 0;

    this.fnSaveAction(data);
  };
  fnDelete = (e) => {
    let that = this;
    let data = this.state.form;
    if (!data.flockId) {
      that.props.alert.show(i18n.t('flockId') + i18n.t('을(를) 입력하세요.'));
      return false;
    }
    if (Number(data.totalCnt) > 0) {
      that.props.alert.show(i18n.t('flock') + i18n.t('DATA가 있습니다.'));
      return false;
    }

    this.setState({ delView: true });
  };

  confirm = () => {
    let data = this.state.form;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.iuFlag = 'D';
    this.fnSaveAction(data);
  };
  fnSaveAction = (data) => {
    let that = this;

    console.log('save**', data);
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/saveFlockInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.fnNewFlock();
        that.fnSearch();
        //this.setState({ form: {} }, function() {});
      })
      .catch(function (error) {
        return [];
      });
  };
  fnCancel = (e) => {
    this.props.fnCancelFlockList(this.state.form);
  };
  onCellClicked = (e) => {
    e.data.iuFlag = 'U';
    this.setState({ form: e.data }, function () {});
  };

  // setOnChange = e => {
  //   const col = e.target.name ? e.target.name : '';
  //   this.setState(
  //     {
  //       form: { ...this.state.form, [col]: col, [col]: e.target.value }
  //     },
  //     function() {
  //       //console.log('=====>', this.state);
  //     }
  //   );
  // };
  setChangeSelect = (e) => {
    const col = e.target.name ? e.target.name : '';
    //console.log("setChange", e.target);
    const txt = e.target.options[e.target.selectedIndex].text;
    this.setState(
      {
        form: { ...this.state.form, [col]: txt, [col]: e.target.value },
      },
      function () {}
    );
  };
  fnNewFlock = (e) => {
    let data = [];
    data.iuFlag = 'I';
    data.flockCdate = DateUtil.today();
    data.flockInQtyF = 0;
    data.flockInQtyM = 0;
    data.remarks = '';

    this.setState({ form: data }, function () {});
  };

  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  renderInput = (inputName, ty, dec, len) => {
    let cl = len ? 'input_type1 w' + len : 'input_type1';

    if (ty && ty === 'N') {
      cl = len ? 'input_type2 w' + len : 'input_type2';
      return (
        <NumberFormat
          style={{ textAlign: 'right' }}
          className={cl}
          thousandSeparator={true}
          decimalScale={dec ? dec : 0}
          name={inputName}
          //ref={this[inputName]}
          getInputRef={(el) => (this[inputName] = el)}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : null
          }
          onChange={(e) => this.setOnChange(e, ty)}
        />
      );
    } else if (ty && ty === 'T') {
      return (
        <TimePicker
          //defaultValue={moment('12:08', 'HH:mm')}
          className={cl}
          format={'HH:mm'}
          ref={this[inputName]}
          value={
            this.state.form[inputName]
              ? moment(this.state.form[inputName], 'HH:mm') || ''
              : null
          }
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
        />
      );
    } else if (ty && ty === 'S') {
      return (
        <span>
          {this.state.form[inputName] ? this.state.form[inputName] || '' : ''}
        </span>
      );
    } else if (ty && ty === 'D') {
      return (
        <DatePicker
          name={inputName}
          //className={cl}
          ref={this[inputName]}
          value={moment(this.state.form[inputName])}
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
          allowClear={false}
        />
      );
    } else {
      return (
        <input
          type="text"
          name={inputName}
          className={cl}
          ref={this[inputName]}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : ''
          }
          onChange={(e) => this.setOnChange(e)}
        />
      );
    }
  };
  setOnChange = (e, params, dateString) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';

    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }
    this.setState({ form: { ...that.state.form, [col]: val } }, function () {
      if (params.type === 'N') {
        that.setState({ form: that.fnCalcQty(that.state.form, col) });
      }
    });
  };

  getNewFlockId = (data) => {
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();

    if (this.state.form.strainCode && this.state.form.flockCdate) {
      const url = Server.getRestAPI();
      axios({
        method: 'post',
        url: url + '/breed/receive/getNewFlockId.do',
        data: qs.stringify(data),
        withCredentials: true,
        credentials: 'same-origin',
      })
        .then(function (response) {
          that.setState(
            { form: { ...that.state.form, flockId: response.data.flockId } },
            function () {
              console.log(that.state.form);
            }
          );
        })
        .catch(function (error) {
          return [];
        });
    }
  };

  formatNumber = (params) => {
    if (!params.value) {
      return '';
    } else {
      return Math.floor(params.value)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  };
  render() {
    //if (!this.props.flockView) return null;
    let strainOptions = [];
    const Option = Select.Option;
    if (this.state.strainList) {
      strainOptions = this.state.strainList.map((row, i) => {
        return (
          <Option key={i} value={row.codeValue}>
            {row.codeText}
          </Option>
        );
      });
    }
    return (
      <Fragment>
        {/* <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            margin: 'auto',
            backgroundColor: 'rgba(0,0,0, 0.5)'
          }}
        >
          <div
            style={{
              height: 'auto',
              position: 'absolute',
              left: '15%',
              right: '15%',
              top: '15%',
              //bottom: '15%',
              //margin: 'auto',
              borderRadius: '10px',
              background: 'white'
            }}
          > */}
        <h2>
          <Trans>Flock Info</Trans>
        </h2>
        <div className="con_box">
          <div
            className="f_right mB20 ag-theme-balham"
            style={{ height: '300px', width: '100%' }}
          >
            <AgGridReact
              onGridReady={this.onGridReady}
              defaultColDef={this.state.defaultColDef}
              columnDefs={this.state.columnDefs}
              rowData={this.state.rowData.length > 0 ? this.state.rowData : []}
              //onRowClicked={e => this.onRowClicked(e)}
              onCellClicked={(e) => this.onCellClicked(e)}
              onCellFocused={(e) => {
                e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
              }}
              rowSelection={'single'}
            />
          </div>
          {this.state.formView ? (
            <div className="board_list2 mB10">
              <h3>{this.state.form.flockId}</h3>
              <table>
                <colgroup>
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '35%' }} />
                  <col style={{ width: '15%' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <Trans>Create Date</Trans>
                    </th>
                    <td>
                      {GridUtil.renderInput({
                        inputName: 'flockCdate', //*** */
                        type: 'D', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 30 */
                        placeholder: 'Date',
                      })}
                    </td>
                    <th>
                      <Trans>Strain</Trans>
                    </th>
                    <td>
                      {GridUtil.renderInput({
                        inputName: 'strainCode', //*** */
                        type: 'SELECT', //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        that: this, //*** */
                        options: strainOptions, //*** */ type:'SELECT' options*/
                        placeholder: 'Strain', //*** */
                        width: 210, // px제외 : 없으면 30 */
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Trans>Remarks</Trans>
                    </th>
                    <td colSpan="3">
                      {GridUtil.renderInput({
                        inputName: 'remarks', //*** */
                        type: '', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        //width: 120, // px제외 : 없으면 30 */
                        placeholder: 'Date',
                        maxLength: 100,
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : null}

          <div className="txt_center mB10">
            <button className="btn_blue" onClick={(e) => this.fnNewFlock()}>
              <Trans>New Flock</Trans>
            </button>
            {this.state.form.flockStatus == 'C' ? null : (
              <button className="btn_save" onClick={(e) => this.fnSave()}>
                <Trans>Save</Trans>
              </button>
            )}

            <Popconfirm
              title={i18n.t('Are you sure delete this task?')}
              visible={this.state.delView}
              onVisibleChange={this.handleVisibleChange}
              onConfirm={this.confirm}
              onCancel={this.cancel}
              okText="OK"
              cancelText="Cancel"
            >
              {this.state.form.flockStatus == 'O' ? (
                <button className="btn_gray" onClick={(e) => this.fnDelete()}>
                  <Trans>Delete</Trans>
                </button>
              ) : null}
            </Popconfirm>
            {/* <button
              className="btn_gray"
              onClick={(e) => this.props.fnCloseFlock()}
            >
              <Trans>Cancel</Trans>
            </button> */}
          </div>
        </div>
        {/* </div>
        </div> */}
      </Fragment>
    );
  }
}
export default withAlert()(FlockInfo);
