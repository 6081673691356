import React, { Fragment, Component } from 'react';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';
import axios from 'axios';
import { Const, Server } from 'components/Properties';
import qs from 'qs';
import MaterialItemList from '../../../../components/mobile/material/MaterialItemList';
import MobileHeader from '../../../../components/mobile/header/MobileHeader';
import { observer, inject } from 'mobx-react';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 ** 4. 파일명과 클래스명을 동일하게 해 주세요.
 */

@inject((stores) => ({
  mStore: stores.MobileStore,
  mMaterialStore: stores.MMaterialStore,
  mProjectStore: stores.MprojectStore,
}))
@observer
class Material extends Component {
  componentDidMount() {
    this.fnSearchMaterialIoInfo();
  }

  //수정 시 입력된 정보 셋팅
  fnSetMaterialList = (value) => {
    const { mMaterialStore, mStore } = this.props;
    mMaterialStore.setSelectedMaterialList(value);
    mStore.setIuFlagUpdate();
    this.props.history.push('/mobile/broiler/materialForm');
  };

  //자재 정보 리스트
  fnSearchMaterialIoInfo = () => {
    const { mMaterialStore } = this.props;
    const that = this;
    let params = {};
    params.farmCode = SessionUtil.getFarmCode();
    params.projCode = SessionUtil.getProjCode();
    params.dateFormat = SessionUtil.getDateFormat();
    let url = Server.getRestAPI() + '/mobile/broiler/mSelectMaterialList.do';

    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log(response.data.rows1);
        mMaterialStore.setMaterialList(response.data.rows1);
      })
      .catch(function (error) {
        return [];
      });
  };

  fnPushBack = () => {
    this.props.history.push(Const.MOBILE_LOGINED_PAGE);
  };

  render() {
    const { mStore, mMaterialStore } = this.props;

    return (
      <Fragment>
        {mStore.headerYn == false ? '' : <MobileHeader mStore={mStore} />}

        <div className="pop_header">
          <div className="mback" onClick={(e) => this.fnPushBack()}>
            <Trans>back</Trans>
          </div>
          [{sessionStorage.getItem('projCode')}]
        </div>

        <div className="mobile_content_nofooter">
          <MaterialItemList
            materialList={mMaterialStore.materialList}
            fnSetMaterialList={this.fnSetMaterialList}
          />
        </div>
      </Fragment>
    );
  }
}

export default Material;
