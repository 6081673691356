import React, { Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import { Const, Server } from 'components/Properties';
import { DatePicker, Modal } from 'antd';
import axios from 'axios';
import qs from 'qs';
import { withAlert } from 'react-alert';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import PsDocForm from './PsDocForm';
import '../Hatchery.module.css';
const { confirm } = Modal;

/*
 ** Hatchery - Deliver - PS Doc(Trans)
 ** =============================
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class PsDoc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInstance: this,
      searchFromWkDt: moment(DateUtil.today())
        .add('-1', 'M')
        .format('YYYY-MM-DD'),
      searchToWkDt: DateUtil.today(),
      form: [],
      psDocFormView: false, //******** 최초 */
      dataListView: false, //******** 최초 */
      hatchingList: [],
      psDocForm: {},
      tlDocTransferForm: [],
      rowData: [],
      columnDefs: [
        {
          headerName: i18n.t('No'),
          field: 'transferNo',
          width: 60,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('Transfer Date'),
          field: 'transferDate',
          width: 130,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('Customer'),
          field: 'customerName',
          width: 200,
          cellStyle: { textAlign: 'left' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('F Qty'),
          field: 'psdocFQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          valueGetter: function (params) {
            let qty =
              Number(params.data.psdocFQty ? params.data.psdocFQty : 0) +
              Number(params.data.psdocFBonus ? params.data.psdocFBonus : 0);
            console.log(params.data, qty);
            return qty === 0 ? null : qty.toLocaleString();
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('M Qty'),
          field: 'psdocMQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          valueGetter: function (params) {
            let qty =
              Number(params.data.psdocMQty ? params.data.psdocMQty : 0) +
              Number(params.data.psdocMBonus ? params.data.psdocMBonus : 0);
            return qty === 0 ? null : qty.toLocaleString();
          },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('Hatch Date'),
          field: 'hatchDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: i18n.t('Order No'),
          field: 'orderNo',
          width: 120,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: i18n.t('Truck No'),
          field: 'truckNo',
          width: 120,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: i18n.t('Received'),
          field: 'received',
          width: 70,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: '',
          field: 'delete',
          suppressSizeToFit: false,
          width: 70,
          cellStyle: { textAlign: 'center' },
          editable: false,
          cellRenderer: (params) => {
            const { data } = params;
            if (data.received === 'N')
              return (
                '<button class="btn_sel">' + i18n.t('Delete') + '</button>'
              );
          },
        },
      ],

      defaultColDef: {
        editable: true,
        sortable: true,
        resizable: true,
        filter: true,
        suppressSizeToFit: false,
      },
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnSearch();
    //this.fnClickPsDoc();
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (prevState !== nextProps) {
  //     prevState.currentInstance.fnSearch();
  //     return { nextProps };
  //   }
  //   return null;
  // }
  fnInit = () => {
    window.scrollTo(0, 0);
    this.setState({ psDocFormView: false, dataListView: false }, function () {
      this.fnSearch();
    });
  };
  fnSearch = async () => {
    window.scrollTo(0, 0);
    await this.setState(
      {
        rowData: [],
        psDocFormView: true,
        dataListView: true,
        psDocForm: {
          iuFlag: 'I',
          hatchDate: DateUtil.today(),
          transferDate: DateUtil.today(),
        },
      },
      function () {}
    );
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.countryCode = SessionUtil.getCountryCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.searchFromWkDt = this.state.searchFromWkDt;
    data.searchToWkDt = this.state.searchToWkDt;
    data.docRelType = 'P';

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/transfer/selectTransferChickList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        //console.log('selectGlowingLogList', response.data);
        that.setState(
          {
            rowData: response.data.rows1,
          },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };
  fnClickPsDoc = (e) => {
    this.setState(
      {
        psDocFormView: true,
        psDocForm: {
          iuFlag: 'I',
          hatchDate: DateUtil.today(),
          transferDate: DateUtil.today(),
        },
      },
      function () {
        // scrollToComponent(this['psDocFormView'], {
        //   offset: 0,
        //   align: 'top',
        //   duration: 1000
        // });
      }
    );
  };

  fnClosePopup = (val) => {
    window.scrollTo(0, 0);
    this.setState({ [val]: false }, function () {});
  };
  // //Grid row 선택
  // onCellClicked = (e) => {
  //   console.log('onCellClicked', e);

  //   this.setState({ tlDocTransferForm: e.data }, function () {});

  //   //선택버튼 클릭
  // };

  onCellClicked = (e) => {
    if (e.column.colId !== 'delete') return;

    const { data } = e;
    if (data.received === 'Y') return;

    let that = this;

    confirm({
      title: i18n.t('Are you sure delete this task?'),
      //icon: <ExclamationCircleOutlined />,
      //content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        that.fnDelete(e.data);
      },
      onCancel() {},
    });
  };

  fnDelete = (params) => {
    let that = this;

    params.farmCode = SessionUtil.getFarmCode();
    params.dateFormat = SessionUtil.getDateFormat();
    params.updateId = SessionUtil.getUserId();
    params.hatcheryId = SessionUtil.getHatcheryId();
    params.iuFlag = 'D';
    //console.log('data', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/transfer/savePsDoc.do',
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        that.fnInit();
        if (data === 0)
          return that.props.alert.error(
            i18n.t('The receipt is complete and cannot be deleted.')
          );
        return that.props.alert.show(i18n.t('Deleted'));
      })
      .catch(function (error) {
        return [];
      });
  };
  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  renderInput = (inputName, ty, dec, len) => {
    let cl = len ? 'input_type1 w' + len : 'input_type1';

    if (ty && ty === 'N') {
      cl = len ? 'input_base w' + len : 'input_base';
      return (
        <NumberFormat
          style={{ textAlign: 'right' }}
          className={cl}
          thousandSeparator={true}
          decimalScale={dec ? dec : 0}
          name={inputName}
          //ref={this[inputName]}
          getInputRef={(el) => (this[inputName] = el)}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : null
          }
          onChange={(e) => this.setOnChange(e, ty)}
        />
      );
    } else if (ty && ty === 'T') {
      return (
        <TimePicker
          //defaultValue={moment('12:08', 'HH:mm')}
          className={cl}
          format={'HH:mm'}
          ref={this[inputName]}
          value={
            this.state.form[inputName]
              ? moment(this.state.form[inputName], 'HH:mm') || ''
              : null
          }
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
        />
      );
    } else if (ty && ty === 'S') {
      return (
        <span>
          {this.state.form[inputName] ? this.state.form[inputName] || '' : ''}
        </span>
      );
    } else if (ty && ty === 'D') {
      return (
        <DatePicker
          name={inputName}
          //className={cl}
          style={{ width: len }}
          ref={this[inputName]}
          value={this.state[inputName] ? moment(this.state[inputName]) : ''}
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
          allowClear={false}
        />
      );
    } else {
      return (
        <input
          type="text"
          name={inputName}
          className={cl}
          ref={this[inputName]}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : ''
          }
          onChange={(e) => this.setOnChange(e)}
        />
      );
    }
  };
  setOnChange = (e, ty) => {
    const col = e.target.name ? e.target.name : '';
    //console.log('setOnChangeTime', e);
    //console.log('setOnChangeTime', e.target.value);
    //this.setState({ form[col]: e.target.value }, function() {});
    let val = e.target.value;
    if (ty === 'N') {
      val = val.replace(/,/gi, '');
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          [col]: val,
        },
      },
      function () {
        if (ty === 'N') {
          this.fnCalcEggGood();
        }
      }
    );
  };
  setOnChangeTime = (date, dateString, inputName) => {
    const col = inputName ? inputName : '';
    this.setState({ [col]: dateString }, function () {});
  };
  setOnChangeSelect = (value, col) => {
    this.setState(
      { form: { ...this.state.form, [col]: value } },
      function () {}
    );
  };
  render() {
    return (
      <Fragment>
        <h2>
          <Trans>Deliver - PS DOC</Trans>
        </h2>
        <div className="con_box">
          <h3>
            <Trans>PS DOC Transfer List</Trans>
          </h3>

          {this.state.dataListView ? (
            <div
              className="board_list ag-theme-balham"
              style={{ height: '300px', width: '100%' }}
            >
              <AgGridReact
                modules={this.state.modules}
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                onGridReady={this.onGridReady}
                rowData={this.state.rowData}
                //pagination={true}
                //paginationPageSize={this.state.paginationPageSize}
                onCellClicked={(e) => this.onCellClicked(e)}
                rowSelection={'single'}
                onCellFocused={(e) => {
                  e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
                }}
              />
            </div>
          ) : null}
          <PsDocForm
            ref={(section) => {
              this.psDocFormView = section;
            }}
            psDocFormView={this.state.psDocFormView}
            psDocForm={this.state.psDocForm}
            fnClose={this.fnClosePopup}
            fnInit={this.fnInit}
          />
        </div>
      </Fragment>
    );
  }
}
export default withAlert()(PsDoc);
