import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { DateUtil } from 'utils';

import '../Breeding.module.css';
/*
 ** breeding/receive/HenHouseList
 ** 계사(HenHouse-HH)
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class HenHouseList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      prop: {},
      rowData: [],
      taskList: '',
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  //Hen House Move click
  fnClickMove = (row) => {
    this.props.fnClickMove(row);
  };
  fnClickCancel = (row) => {
    this.props.fnClickCancel(row);
  };
  fnClickEmpty = (row) => {};
  fnClickMoveIn = (row, tp) => {
    //console.log('fnClickIn', row);
    if (tp === 'E') {
      //EMPTY
      row.flockInQtyF = 0;
      row.flockInQtyM = 0;
      //row.chickinQtyF = 0;
      //row.chickinQtyM = 0;
      row.currentQtyF = 0;
      row.currentQtyM = 0;
    }

    this.props.fnClickMoveIn(row, this.props.hhId, this.props.hhFlockRow);
  };
  //Write button Click
  fnClickWrite = (row) => {
    let data = {};
    data = row;
    this.props.fnClickWrite(data);
  };
  //Modify button Click
  fnClickModify = (row) => {
    this.props.fnClickModify(row);
  };
  fnClickCancel = (row) => {
    this.props.fnClickCancel(row);
  };
  hhButton = (row) => {
    //console.log('hhButton', this.props, row, row.capa);
    //육성일자를 기재하지 않은 계사는 "Write" 버튼
    //육성일자를 기재한 계사는 "Repair" 버튼
    //비어있는 계사는 제외
    //console.log(this.props.epDate, DateUtil.today());
    if (this.props.epDate !== DateUtil.today()) return;

    if (row.iuFlag === 'I' || row.iuFlag === 'U') {
      return (
        <dd>
          <button
            className="btn_gray2"
            onClick={(e) => this.fnClickCancel(row)}
          >
            <Trans>Cancel</Trans>
          </button>
        </dd>
      );
    } else if (this.props.eggProductionFormView) {
      return null;
    } else {
      if (row.epDate) {
        return (
          <dd>
            <button
              className="btn_green"
              onClick={(e) => this.fnClickModify(row)}
            >
              <Trans>Modify</Trans>
            </button>
          </dd>
        );
      } else {
        return (
          <dd>
            <button
              className="btn_blue"
              onClick={(e) => this.fnClickWrite(row)}
            >
              <Trans>Write</Trans>
            </button>
          </dd>
        );
      }
    }
  };

  render() {
    let that = this;
    let hhChickinList = [];
    let cc = 0;
    let cc10 = 0;
    const ccClass = 'full_01 h';
    const ccClass100 = 'full_03 h100';
    if (this.props.hhChickinList && this.props.hhChickinList.length > 0) {
      hhChickinList = this.props.hhChickinList.map((row, i) => {
        if (that.props.hhFlockRow) {
          row.flockInQtyF = that.props.hhFlockRow.flockInQtyF;
          row.flockInQtyM = that.props.hhFlockRow.flockInQtyM;
        }
        row.propsHhId = that.props.hhId;
        if (row.flockId) {
          cc = parseInt(
            ((row.chickinQtyF + row.chickinQtyM) / row.capacity) * 100,
            10
          );
          cc10 = parseInt(cc / 10, 10) * 10;
          row.capa = row.capacity - (row.chickinQtyF + row.chickinQtyM);
          return (
            <div key={i} className="full">
              <dl className="reinfo">
                <dt>
                  <Trans>{row.hhId}</Trans>
                </dt>
                <dd>
                  <Trans>Days</Trans> :{' '}
                  {row.days ? row.days.toLocaleString() : '0'}
                </dd>
                <dd>
                  <Trans>Week</Trans> :{' '}
                  {row.weeks ? row.weeks.toLocaleString() : '0'}
                </dd>
                <dd>
                  <Trans>F</Trans> :{' '}
                  {row.chickinQtyF ? row.chickinQtyF.toLocaleString() : '0'} /{' '}
                  {row.chickinQtyF ? row.chickinQtyF.toLocaleString() : '0'}
                </dd>
                <dd>
                  <Trans>M</Trans> :{' '}
                  {row.flockInQtyM ? row.flockInQtyM.toLocaleString() : '0'} /{' '}
                  {row.chickinQtyM ? row.chickinQtyM.toLocaleString() : '0'}
                </dd>
                {row.epDate ? (
                  <dd>
                    <Trans>Total</Trans>
                    {' : '}
                    {row.totalEggQty ? row.totalEggQty.toLocaleString() : 0}
                  </dd>
                ) : null}
                {row.epDate ? (
                  <dd>
                    <Trans>Good Egg</Trans>
                    {' : '}
                    {row.goodEggQty ? row.goodEggQty.toLocaleString() : 0}
                  </dd>
                ) : null}
                {row.epDate ? (
                  <dd>
                    <Trans>Crack</Trans>
                    {' : '}
                    {row.crackEggQty ? row.crackEggQty.toLocaleString() : 0}
                  </dd>
                ) : null}

                {/* 병아리가 들어있는 계사는 Move 버튼 활성화 */}
                {that.hhButton(row)}
              </dl>
              {/* <div
                className={cc10 === 100 ? ccClass100 : ccClass + cc10}
                title={cc}
              ></div> */}
            </div>
          );
        } else {
          return (
            <div key={i} className="full">
              <dl className="reinfo">
                <dt>
                  <Trans>{row.hhId}</Trans>
                </dt>
                <dd>
                  <Trans>EMPTY</Trans>
                </dd>
                {this.hhEmptyButton(row)}
                {/* 
                <dd>
                  <button
                    className="btn_gray2"
                    onClick={e => this.fnClickEmpty(row)}
                  >
                    <Trans>EMPTY</Trans>
                  </button>
                </dd> */}
              </dl>
            </div>
          );
        }
      });
    }
    return (
      <Fragment>
        {/* Hen House */}
        {hhChickinList}
      </Fragment>
    );
  }
}
export default HenHouseList;
