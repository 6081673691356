import React, { Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil, NumericEditor } from 'utils';
import { Trans } from 'react-i18next';
import { Const, Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';
import { moment } from 'components';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import { DatePicker } from 'antd';
import i18n from 'i18next';
import HECutting from './HECutting';
import { withAlert } from 'react-alert';
import '../Hatchery.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class Cutting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heCutting: false,
      workDate: DateUtil.today(),
      selectTlGradingInfoList: [],
      selectTlSettingInfoListS: [],
      selectTlSettingInfoListH: [],
      selectTlHatchingInfoList: [],
      gradingInfoList: [],
      settingInfoListS: [],
      settingInfoListH: [],
      hatchingInfoList: [],
      columnDefsGradingInfo: [
        {
          headerName: 'Supplier',
          field: 'customerName',
          width: 250,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Farm',
          field: 'farmInfo',
          width: 250,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Flock',
          field: 'flockId',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'HH',
          field: 'hhId',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Strain',
          field: 'strainInfo',
          width: 150,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Egg Receive No',
          field: 'eggReceiveNo',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Receive Date',
          field: 'receiveDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Grade Date',
          field: 'gradingDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Hatching Qty',
          // field: 'noneSettingQty',
          field: 'hatchingEggQty', // noneSettingQty -> hatchingEggQty 요청 by 김기철 2020.08.04
          type: 'numericColumn',
          width: 120,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: function (params) {
            return GridUtil.getEditCell('D', 120);
          },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Cutting',
          field: 'hatchingCuttingEggQty',
          type: 'numericColumn',
          width: 120,
          editable: function (params) {
            if (params.data.hatchingEggQty === 0) return false;
            else return true;
          },
          cellEditor: 'numericEditor',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: function (params) {
            if (params.data.hatchingEggQty === 0)
              return GridUtil.getEditCell('P', 120);
            else return GridUtil.getEditCell('E', 120);
          },
          suppressSizeToFit: true,
        },
      ],
      columnDefsSettingInfoS: [
        {
          headerName: 'Supplier',
          field: 'customerName',
          width: 250,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Farm',
          field: 'farmInfo',
          width: 250,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Flock',
          field: 'flockId',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'HH',
          field: 'hhId',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Strain',
          field: 'strainInfo',
          width: 150,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Egg Receive No',
          field: 'eggReceiveNo',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Receive Date',
          field: 'receiveDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Setting Date',
          field: 'settingDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Setter ID',
          field: 'setterId',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Setting Qty',
          field: 'settingQty',
          type: 'numericColumn',
          width: 120,
          suppressSizeToFit: true,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: function (params) {
            return GridUtil.getEditCell('D', 120);
          },
        },
        {
          headerName: 'Cutting',
          field: 'settingCuttingQty',
          type: 'numericColumn',
          width: 120,
          suppressSizeToFit: true,
          editable: function (params) {
            if (params.data.settingQty === 0) return false;
            else return true;
          },
          cellEditor: 'numericEditor',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: function (params) {
            if (params.data.settingQty === 0)
              return GridUtil.getEditCell('P', 120);
            else return GridUtil.getEditCell('E', 120);
          },
        },
      ],
      columnDefsSettingInfoH: [
        {
          headerName: 'Supplier',
          field: 'customerName',
          width: 250,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Farm',
          field: 'farmInfo',
          width: 250,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Flock',
          field: 'flockId',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'HH',
          field: 'hhId',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Strain',
          field: 'strainInfo',
          width: 150,
          cellStyle: { textAlign: 'center' },
          //suppressSizeToFit: true
        },
        {
          headerName: 'Egg Receive No',
          field: 'eggReceiveNo',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Receive Date',
          field: 'receiveDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Candling Date',
          field: 'settingDate',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Hatcher ID',
          field: 'hatcherId',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Fertile Qty',
          field: 'fertileQty',
          width: 120,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: function (params) {
            return GridUtil.getEditCell('D', 120);
          },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Cutting',
          field: 'fertileCuttingQty',
          width: 120,
          type: 'numericColumn',
          editable: function (params) {
            if (params.data.fertileQty === 0) return false;
            else return true;
          },
          cellEditor: 'numericEditor',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: function (params) {
            if (params.data.fertileQty === 0)
              return GridUtil.getEditCell('P', 120);
            else return GridUtil.getEditCell('E', 120);
          },
          suppressSizeToFit: true,
        },
      ],
      columnDefsHatchingInfo: [
        {
          headerName: 'Supplier',
          field: 'customerName',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Farm',
          field: 'farmInfo',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Flock',
          field: 'flockId',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Hen House',
          field: 'hhId',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Strain',
          field: 'strainInfo',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Hatch Date',
          field: 'hatchDate',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'SP',
          children: [
            {
              headerName: 'Qty',
              field: 'spQty',
              type: 'numericColumn',
              width: 100,
              suppressSizeToFit: true,
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              cellStyle: function (params) {
                return GridUtil.getEditCell('D', 100);
              },
            },
            {
              headerName: 'Cutting',
              field: 'spCuttingQty',
              type: 'numericColumn',
              width: 100,
              suppressSizeToFit: true,
              editable: function (params) {
                if (params.data.spQty === 0) return false;
                else return true;
              },
              cellEditor: 'numericEditor',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              cellStyle: function (params) {
                if (params.data.spQty === 0)
                  return GridUtil.getEditCell('P', 100);
                else return GridUtil.getEditCell('E', 100);
              },
            },
          ],
        },
        {
          headerName: 'A2',
          children: [
            {
              headerName: 'Qty',
              field: 'a2Qty',
              type: 'numericColumn',
              width: 100,
              suppressSizeToFit: true,
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              cellStyle: function (params) {
                return GridUtil.getEditCell('D', 100);
              },
            },
            {
              headerName: 'Cutting',
              field: 'a2CuttingQty',
              type: 'numericColumn',
              width: 100,
              suppressSizeToFit: true,
              editable: function (params) {
                if (params.data.a2Qty === 0) return false;
                else return true;
              },
              cellEditor: 'numericEditor',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              cellStyle: function (params) {
                if (params.data.a2Qty === 0)
                  return GridUtil.getEditCell('P', 100);
                else return GridUtil.getEditCell('E', 100);
              },
            },
          ],
        },
        {
          headerName: 'A1',
          children: [
            {
              headerName: 'Qty',
              field: 'a1Qty',
              type: 'numericColumn',
              width: 100,
              suppressSizeToFit: true,
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
            },
            {
              headerName: 'Cutting',
              field: 'a1CuttingQty',
              type: 'numericColumn',
              width: 100,
              suppressSizeToFit: true,
              editable: function (params) {
                if (params.data.a1Qty === 0) return false;
                else return true;
              },
              cellEditor: 'numericEditor',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              cellStyle: function (params) {
                if (params.data.a1Qty === 0)
                  return GridUtil.getEditCell('P', 100);
                else return GridUtil.getEditCell('E', 100);
              },
            },
          ],
        },
        {
          headerName: 'BM',
          children: [
            {
              headerName: 'Qty',
              field: 'bmQty',
              type: 'numericColumn',
              width: 100,
              suppressSizeToFit: true,
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              cellStyle: function (params) {
                return GridUtil.getEditCell('D', 100);
              },
            },
            {
              headerName: 'Cutting',
              field: 'bmCuttingQty',
              type: 'numericColumn',
              width: 100,
              suppressSizeToFit: true,
              editable: function (params) {
                if (params.data.bmQty === 0) return false;
                else return true;
              },
              cellEditor: 'numericEditor',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              cellStyle: function (params) {
                if (params.data.bmQty === 0)
                  return GridUtil.getEditCell('P', 100);
                else return GridUtil.getEditCell('E', 100);
              },
            },
          ],
        },
        {
          headerName: 'B',
          children: [
            {
              headerName: 'Qty',
              field: 'bQty',
              type: 'numericColumn',
              width: 100,
              suppressSizeToFit: true,
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              cellStyle: function (params) {
                return GridUtil.getEditCell('D', 100);
              },
            },
            {
              headerName: 'Cutting',
              field: 'bCuttingQty',
              type: 'numericColumn',
              width: 100,
              suppressSizeToFit: true,
              editable: function (params) {
                if (params.data.bQty === 0) return false;
                else return true;
              },
              cellEditor: 'numericEditor',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              cellStyle: function (params) {
                if (params.data.bQty === 0)
                  return GridUtil.getEditCell('P', 100);
                else return GridUtil.getEditCell('E', 100);
              },
            },
          ],
        },
        {
          headerName: 'POLOS',
          children: [
            {
              headerName: 'Qty',
              field: 'polosQty',
              type: 'numericColumn',
              width: 100,
              suppressSizeToFit: true,
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              cellStyle: function (params) {
                return GridUtil.getEditCell('D', 100);
              },
            },
            {
              headerName: 'Cutting',
              field: 'polosCuttingQty',
              type: 'numericColumn',
              width: 100,
              suppressSizeToFit: true,
              editable: function (params) {
                if (params.data.polosQty === 0) return false;
                else return true;
              },
              cellEditor: 'numericEditor',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              cellStyle: function (params) {
                if (params.data.polosQty === 0)
                  return GridUtil.getEditCell('P', 100);
                else return GridUtil.getEditCell('E', 100);
              },
            },
          ],
        },
        {
          headerName: 'SEX',
          children: [
            {
              headerName: 'Qty',
              field: 'sexQty',
              type: 'numericColumn',
              width: 100,
              suppressSizeToFit: true,
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              cellStyle: function (params) {
                return GridUtil.getEditCell('D', 100);
              },
            },
            {
              headerName: 'Cutting',
              field: 'sexCuttingQty',
              type: 'numericColumn',
              width: 100,
              suppressSizeToFit: true,
              editable: function (params) {
                if (params.data.sexQty === 0) return false;
                else return true;
              },
              cellEditor: 'numericEditor',
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              cellStyle: function (params) {
                if (params.data.sexQty === 0)
                  return GridUtil.getEditCell('P', 100);
                else return GridUtil.getEditCell('E', 100);
              },
            },
          ],
        },
      ],
      defaultColDef: {
        editable: false,
        width: 100,
        filter: true,
        resizable: true,
        sortable: true,
        suppressSizeToFit: false,
      },
      frameworkComponents: {
        numericEditor: NumericEditor,
      },
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnSearch();
  }

  fnSearch = () => {
    let that = this;
    window.scrollTo(0, 0);

    let data = {};
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.countryCode = SessionUtil.getCountryCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    //data.searchFromWkDt = this.state.form.searchFromWkDt;
    //data.searchToWkDt = this.state.form.searchToWkDt;

    //data.receiveNo = '';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/cutting/selectCuttingList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            selectTlGradingInfoList: response.data.rows1,
            selectTlSettingInfoListS: response.data.rows2,
            selectTlSettingInfoListH: response.data.rows3,
            selectTlHatchingInfoList: response.data.rows4,
          },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  //Sales 저장
  fnSave = () => {
    let that = this;
    let data = {};
    data.iuFlag = 'I';

    if (!this.state.workDate) {
      that.props.alert.show(i18n.t('Please enter WorkDate.'));
      this.workDate.focus();
      return false;
    }
    this.gridApiGradingInfo.stopEditing();
    this.gridApiSettingInfoS.stopEditing();
    this.gridApiSettingInfoH.stopEditing();
    this.gridApiHatchingInfo.stopEditing();

    let gradingInfoList = [];
    let settingInfoListS = [];
    let settingInfoListH = [];
    let hatchingInfoList = [];

    this.gridApiGradingInfo.forEachNode(function (rowNode, index) {
      let hatchingEggQty = rowNode.data.hatchingEggQty
        ? Number(rowNode.data.hatchingEggQty)
        : 0;
      let hatchingCuttingEggQty = rowNode.data.hatchingCuttingEggQty
        ? Number(rowNode.data.hatchingCuttingEggQty)
        : 0;
      if (hatchingCuttingEggQty > 0) {
        let dataDef = {};
        dataDef.eggReceiveNo = rowNode.data.eggReceiveNo;
        dataDef.receiveDate = rowNode.data.receiveDate;
        dataDef.hatchingEggQty = Number(hatchingCuttingEggQty);

        if (hatchingEggQty < hatchingCuttingEggQty) {
          that.props.alert.show(i18n.t('Please check quantity.'));
          return false;
        }

        gradingInfoList.push(dataDef);
      }
    });
    this.gridApiSettingInfoS.forEachNode(function (rowNode, index) {
      let settingQty = rowNode.data.settingQty
        ? Number(rowNode.data.settingQty)
        : 0;
      let settingCuttingQty = rowNode.data.settingCuttingQty
        ? Number(rowNode.data.settingCuttingQty)
        : 0;
      if (settingCuttingQty > 0) {
        let dataDef = {};
        if (rowNode.data.settingQty === Number(rowNode.data.val)) {
          dataDef.hatcherStatus = 'C';
        } else {
          dataDef.hatcherStatus = 'S';
        }
        dataDef.eggReceiveNo = rowNode.data.eggReceiveNo;
        dataDef.settingSeq = rowNode.data.settingSeq;
        dataDef.settingQty = Number(settingCuttingQty);

        if (settingQty < settingCuttingQty) {
          that.props.alert.show(i18n.t('Please check quantity.'));
          return false;
        }

        settingInfoListS.push(dataDef);
      }
    });
    this.gridApiSettingInfoH.forEachNode(function (rowNode, index) {
      let fertileQty = rowNode.data.fertileQty
        ? Number(rowNode.data.fertileQty)
        : 0;
      let fertileCuttingQty = rowNode.data.fertileCuttingQty
        ? Number(rowNode.data.fertileCuttingQty)
        : 0;
      if (fertileCuttingQty > 0) {
        console.log('rowNode.data', rowNode.data);
        let dataDef = {};
        if (
          rowNode.data.fertileQty === Number(rowNode.data.fertileCuttingQty)
        ) {
          dataDef.hatcherStatus = 'C';
        } else {
          dataDef.hatcherStatus = 'H';
        }
        dataDef.eggReceiveNo = rowNode.data.eggReceiveNo;
        dataDef.settingSeq = rowNode.data.settingSeq;
        dataDef.fertileQty = Number(fertileCuttingQty);

        if (fertileQty < fertileCuttingQty) {
          that.props.alert.show(i18n.t('Please check quantity.'));
          return false;
        }

        settingInfoListH.push(dataDef);
      }
    });
    this.gridApiHatchingInfo.forEachNode(function (rowNode, index) {
      let spQtyStock = rowNode.data.spQty ? Number(rowNode.data.spQty) : 0;
      let a2QtyStock = rowNode.data.a2Qty ? Number(rowNode.data.a2Qty) : 0;
      let a1QtyStock = rowNode.data.a1Qty ? Number(rowNode.data.a1Qty) : 0;
      let bmQtyStock = rowNode.data.bmQty ? Number(rowNode.data.bmQty) : 0;
      let bQtyStock = rowNode.data.bQty ? Number(rowNode.data.bQty) : 0;
      let polosQtyStock = rowNode.data.polosQty
        ? Number(rowNode.data.polosQty)
        : 0;
      let sexQtyStock = rowNode.data.sexQty ? Number(rowNode.data.sexQty) : 0;

      let seQty = rowNode.data.seCuttingQty
        ? Number(rowNode.data.seCuttingQty)
        : 0;
      let spQty = rowNode.data.spCuttingQty
        ? Number(rowNode.data.spCuttingQty)
        : 0;
      let a2Qty = rowNode.data.a2CuttingQty
        ? Number(rowNode.data.a2CuttingQty)
        : 0;
      let a1Qty = rowNode.data.a1CuttingQty
        ? Number(rowNode.data.a1CuttingQty)
        : 0;
      let bmQty = rowNode.data.bmCuttingQty
        ? Number(rowNode.data.bmCuttingQty)
        : 0;
      let bQty = rowNode.data.bCuttingQty
        ? Number(rowNode.data.bCuttingQty)
        : 0;
      let polosQty = rowNode.data.polosCuttingQty
        ? Number(rowNode.data.polosCuttingQty)
        : 0;
      let sexQty = rowNode.data.sexCuttingQty
        ? Number(rowNode.data.sexCuttingQty)
        : 0;
      if (
        seQty > 0 ||
        spQty > 0 ||
        a2Qty > 0 ||
        a1Qty > 0 ||
        bmQty > 0 ||
        bQty > 0 ||
        polosQty > 0 ||
        sexQty > 0
      ) {
        let dataDef = {};
        dataDef.hhId = rowNode.data.hhId;
        dataDef.hatchDate = rowNode.data.hatchDate;
        dataDef.segQty = Number(seQty);
        dataDef.spQty = Number(spQty);
        dataDef.a2Qty = Number(a2Qty);
        dataDef.a1Qty = Number(a1Qty);
        dataDef.bmQty = Number(bmQty);
        dataDef.bQty = Number(bQty);
        dataDef.polosQty = Number(polosQty);
        dataDef.sexQty = Number(sexQty);

        if (spQtyStock < spQty) {
          that.props.alert.show(i18n.t('Please check quantity.'));
          return false;
        }

        if (a2QtyStock < a2Qty) {
          that.props.alert.show(i18n.t('Please check quantity.'));
          return false;
        }

        if (a1QtyStock < a1Qty) {
          that.props.alert.show(i18n.t('Please check quantity.'));
          return false;
        }

        if (bmQtyStock < bmQty) {
          that.props.alert.show(i18n.t('Please check quantity.'));
          return false;
        }

        if (bQtyStock < bQty) {
          that.props.alert.show(i18n.t('Please check quantity.'));
          return false;
        }

        if (polosQtyStock < polosQty) {
          that.props.alert.show(i18n.t('Please check quantity.'));
          return false;
        }

        if (sexQtyStock < sexQty) {
          that.props.alert.show(i18n.t('Please check quantity.'));
          return false;
        }

        hatchingInfoList.push(dataDef);
      }
    });

    if (
      gradingInfoList.length > 0 ||
      settingInfoListS.length > 0 ||
      settingInfoListH.length > 0 ||
      hatchingInfoList.length > 0
    ) {
    } else {
      return false;
    }

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.countryCode = SessionUtil.getCountryCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.workDate = this.state.workDate;
    data.updateId = SessionUtil.getUserId();

    data.gradingInfoList = gradingInfoList;
    data.gradingInfoLength = gradingInfoList.length;
    data.settingInfoListS = settingInfoListS;
    data.settingInfoListSLength = settingInfoListS.length;
    data.settingInfoListH = settingInfoListH;
    data.settingInfoListHLength = settingInfoListH.length;
    data.hatchingInfoList = hatchingInfoList;
    data.hatchingInfoListLength = hatchingInfoList.length;
    console.log('save', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/cutting/saveCuttingEgg.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.alert.show(i18n.t('Has been saved.'));
        //that.fnInit();
        that.fnSearch();
      })
      .catch(function (error) {
        return [];
      });
  };
  fnTestClick = (e, val) => {
    if (val === 1) {
      this.setState({ heCutting: true }, function () {});
    }
  };
  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();

    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      params.api.setRowData(data.slice(0, 100));
    };
  };
  onGridReadyGradingInfo = (params) => {
    this.gridApiGradingInfo = params.api;
    //this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();

    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      params.api.setRowData(data.slice(0, 100));
    };
  };
  onGridReadySettingInfoS = (params) => {
    this.gridApiSettingInfoS = params.api;
    //this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();

    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      params.api.setRowData(data.slice(0, 100));
    };
  };
  onGridReadySettingInfoH = (params) => {
    this.gridApiSettingInfoH = params.api;
    //this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();

    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      params.api.setRowData(data.slice(0, 100));
    };
  };
  onGridReadyHatchingInfo = (params) => {
    this.gridApiHatchingInfo = params.api;
    //this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();

    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      params.api.setRowData(data.slice(0, 100));
    };
  };
  setOnChangeTime = (date, dateString, inputName) => {
    const col = inputName ? inputName : '';
    this.setState({ [col]: dateString }, function () {});
  };
  formatNumber = (params) => {
    if (params.value) {
      return Math.floor(params.value)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else return '';
  };
  onCellValueChanged = (params, grid) => {
    let that = this;
    //params.data.~~~ : row 전체 data
    //params.column.colId : column 명
    //params.rowIndex : row index
    //params.oldValue: "55"
    //params.newValue: "33"
    //params.value: "33"
    //console.log('onCellValueChanged', params);
    //let col = params.column.colId.replace('SaleQty', 'Qty');
    let col = params.column.colId.replace('Cutting', '');
    var rowNode = this[grid].getDisplayedRowAtIndex(params.rowIndex);
    let qty = Number(rowNode.data[col] ? rowNode.data[col] : 0);
    if (Number(params.value) > qty) {
      rowNode.setDataValue(params.column.colId, 0);
      that.props.alert.show(i18n.t('The quantity is exceeded.'));

      that[grid].tabToPreviousCell();
      return false;
    } else {
      //this.printAllDisplayedRows(params.column.colId, grid);
    }
  };
  //Egg Goog List edit
  printAllDisplayedRows = (col, grid) => {
    var count = this[grid].getDisplayedRowCount();
    let qty = 0;
    let tot = 0;
    for (var i = 0; i < count; i++) {
      var rowNode = this[grid].getDisplayedRowAtIndex(i);
      tot += Number(rowNode.data[col] ? rowNode.data[col] : 0);
    }

    col = col.replace('EggSaleQty', '') + 'EggQty';
    console.log('=======', col);
    this.setState(
      {
        form: {
          ...this.state.form,
          [col]: tot,
        },
      },
      function () {}
    );
  };
  onCellKeyDown(e, gridName) {
    var row = this[gridName].getRowNode(e.rowIndex);
    console.log('onCellKeyDown====>', e);

    let col = e.column.colId.replace('Cutting', '');
    let qty = Number(row.data[col] ? row.data[col] : 0);
    if (Number(e.data.val) > qty) {
      if (gridName == 'gridApiGradingInfo') {
        this.gridApiGradingInfo.stopEditing();
      }
      if (gridName == 'gridApiSettingInfoS') {
        this.gridApiSettingInfoS.stopEditing();
      }
      if (gridName == 'gridApiSettingInfoH') {
        this.gridApiSettingInfoH.stopEditing();
      }
      if (gridName == 'gridApiHatchingInfo') {
        this.gridApiHatchingInfo.stopEditing();
      }
      row.setDataValue(e.column.colId, 0);
      this.props.alert.show(i18n.t('The quantity is exceeded.'));
    }
  }
  render() {
    return (
      <Fragment>
        {/* 디자인 영역  */}
        <h2>
          <Trans>Cutting</Trans>
        </h2>
        <div className="con_box">
          <div className="mT10">
            <label htmlFor="">
              <Trans>Date</Trans>
            </label>
            <DatePicker
              name={'workDate'}
              style={{ width: '120px' }}
              ref={(input) => {
                this.workDate = input;
              }}
              value={this.state.workDate ? moment(this.state.workDate) : null}
              onChange={(date, dateString) =>
                this.setOnChangeTime(date, dateString, 'workDate')
              }
              allowClear={false}
            />
            <button className="btn_save" onClick={(e) => this.fnSave()}>
              <Trans>Save</Trans>
            </button>
          </div>

          <HECutting heCutting={this.state.heCutting} />

          <div className="line"></div>
          <h3>
            <Trans>Hatching Egg Cutting</Trans>
          </h3>
          <div
            className="board_list ag-theme-balham mT10"
            style={{ height: '300px', width: '100%' }}
          >
            <AgGridReact
              //onGridReady={this.onGridReadyGradingInfo}
              onGridReady={(e) => this.onGridReady(e, 'gridApiGradingInfo')}
              columnDefs={this.state.columnDefsGradingInfo}
              rowData={
                this.state.selectTlGradingInfoList.length > 0
                  ? this.state.selectTlGradingInfoList
                  : []
              }
              defaultColDef={this.state.defaultColDef}
              enterMovesDownAfterEdit={true}
              enterMovesDown={true}
              frameworkComponents={this.state.frameworkComponents}
              //editType={this.state.editType}
              //onRowClicked={e => this.onRowClicked(e)}
              // onCellValueChanged={(e) =>
              //   this.onCellValueChanged(e, 'gridApiGradingInfo')
              // }
              //onCellClicked={e => this.onCellClicked(e)}
              getRowHeight={(e) => GridUtil.getRowHeight(e)}
              singleClickEdit={true}
              onCellKeyDown={(e) => this.onCellKeyDown(e, 'gridApiGradingInfo')}
            ></AgGridReact>
          </div>

          <div className="line"></div>
          <h3>
            <Trans>Setting Egg Cutting</Trans>
          </h3>
          <div
            className="board_list ag-theme-balham mT10"
            style={{ height: '300px', width: '100%' }}
          >
            <AgGridReact
              //onGridReady={this.onGridReadySettingInfoS}
              onGridReady={(e) => this.onGridReady(e, 'gridApiSettingInfoS')}
              columnDefs={this.state.columnDefsSettingInfoS}
              rowData={
                this.state.selectTlSettingInfoListS.length > 0
                  ? this.state.selectTlSettingInfoListS
                  : []
              }
              defaultColDef={this.state.defaultColDef}
              enterMovesDownAfterEdit={true}
              enterMovesDown={true}
              frameworkComponents={this.state.frameworkComponents}
              //editType={this.state.editType}
              //onRowClicked={e => this.onRowClicked(e)}
              // onCellValueChanged={(e) =>
              //   this.onCellValueChanged(e, 'gridApiSettingInfoS')
              // }
              //onCellClicked={e => this.onCellClicked(e)}
              getRowHeight={(e) => GridUtil.getRowHeight(e)}
              singleClickEdit={true}
              onCellKeyDown={(e) =>
                this.onCellKeyDown(e, 'gridApiSettingInfoS')
              }
            ></AgGridReact>
          </div>

          <div className="line"></div>
          <h3>
            <Trans>Fertile Egg Cutting</Trans>
          </h3>
          <div
            className="board_list ag-theme-balham mT10"
            style={{ height: '300px', width: '100%' }}
          >
            <AgGridReact
              //onGridReady={this.onGridReadySettingInfoH}
              onGridReady={(e) => this.onGridReady(e, 'gridApiSettingInfoH')}
              columnDefs={this.state.columnDefsSettingInfoH}
              rowData={
                this.state.selectTlSettingInfoListH.length > 0
                  ? this.state.selectTlSettingInfoListH
                  : []
              }
              defaultColDef={this.state.defaultColDef}
              enterMovesDownAfterEdit={true}
              enterMovesDown={true}
              frameworkComponents={this.state.frameworkComponents}
              //editType={this.state.editType}
              //onRowClicked={e => this.onRowClicked(e)}
              // onCellValueChanged={(e) =>
              //   this.onCellValueChanged(e, 'gridApiSettingInfoH')
              // }
              //onCellClicked={e => this.onCellClicked(e)}
              getRowHeight={(e) => GridUtil.getRowHeight(e)}
              singleClickEdit={true}
              onCellKeyDown={(e) =>
                this.onCellKeyDown(e, 'gridApiSettingInfoH')
              }
            ></AgGridReact>
          </div>

          <div className="line"></div>
          <h3>
            <Trans>DOC Cutting</Trans>
          </h3>
          <div
            className="board_list ag-theme-balham mT10"
            style={{ height: '300px', width: '100%' }}
          >
            <AgGridReact
              //onGridReady={this.onGridReadyHatchingInfo}
              onGridReady={(e) => this.onGridReady(e, 'gridApiHatchingInfo')}
              columnDefs={this.state.columnDefsHatchingInfo}
              rowData={
                this.state.selectTlHatchingInfoList.length > 0
                  ? this.state.selectTlHatchingInfoList
                  : []
              }
              defaultColDef={this.state.defaultColDef}
              enterMovesDownAfterEdit={true}
              enterMovesDown={true}
              frameworkComponents={this.state.frameworkComponents}
              //editType={this.state.editType}
              //onRowClicked={e => this.onRowClicked(e)}
              // onCellValueChanged={(e) =>
              //   this.onCellValueChanged(e, 'gridApiHatchingInfo')
              // }
              //onCellClicked={e => this.onCellClicked(e)}
              getRowHeight={(e) => GridUtil.getRowHeight(e)}
              singleClickEdit={true}
              onCellKeyDown={(e) =>
                this.onCellKeyDown(e, 'gridApiHatchingInfo')
              }
            ></AgGridReact>
          </div>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default withAlert()(Cutting);
