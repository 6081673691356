import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import AFCheckboxSearch from '../utils/AFCheckboxSearch';
import AFDatePickerSearch from '../utils/AFDatePickerSearch';
import AFIndexSearch from '../utils/AFIndexSearch';
import ProjectDateRangeSearch from '../utils/ProjectDateRangeSearch';
import ReportUtil from '../utils/ReportUtil';

import '../Report.module.css';

// 2. 일일 사육 현황 (지역/Project별)
export default class DailyAreaR extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      excelName: i18n.t('Broiler Daily Breeding Report (Area/Project)'),
      rowData: [],
      params: {},
      ColDefs: [
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Division'),
              field: 'division',
              pinned: 'left',
              minWidth: 100,
              colSpan: function (params) {
                const { division } = params.data;
                switch (division) {
                  case 'Farm Total':
                  case 'Region Total':
                  case 'Division Total':
                    return 3;
                }
                return 1;
              },
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Region'),
              field: 'region',
              pinned: 'left',
              minWidth: 100,
              valueFormatter: function (params) {
                // 숫자형이지만, 쉼표가 표기되면 안됨
              },
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Project'),
              field: 'project',
              pinned: 'left',
              minWidth: 140,
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Chick in Qty'),
              field: 'chickInQty',
              pinned: 'left',
              minWidth: 100,
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Age'),
              field: 'age',
              pinned: 'left',
            },
          ],
        },
        {
          headerName: i18n.t('Chick out'),
          children: [
            // { headerName: i18n.t('Qty'), field: 'cQty', },
            // { headerName: i18n.t('Kg'), field: 'cKg', },
            {
              headerName: i18n.t('Culling Qty'),
              field: 'cQty',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('Culling Kg'),
              field: 'cKg',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('Harvest Qty'),
              field: 'hQty',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('Harvest Kg'),
              field: 'hKg',
              width: 100,
              minWidth: 100,
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Population'),
              field: 'population',
              width: 100,
              minWidth: 100,
            },
          ],
        },
        {
          headerName: i18n.t('Depletion'),
          children: [
            {
              headerName: i18n.t('Act'),
              field: 'dAct',
            },
            {
              headerName: i18n.t('Std'),
              field: 'dStd',
              width: 80,
              minWidth: 80,
            },
          ],
        },
        {
          headerName: i18n.t('Point Feed'),
          children: [
            {
              headerName: i18n.t('Act'),
              field: 'pAct',
            },
            {
              headerName: i18n.t('Std'),
              field: 'pStd',
            },
          ],
        },
        {
          headerName: i18n.t('Body Weight'),
          children: [
            {
              headerName: i18n.t('Act'),
              field: 'bAct',
            },
            {
              headerName: i18n.t('Std'),
              field: 'bStd',
            },
          ],
        },
        {
          headerName: i18n.t('FCR'),
          children: [
            {
              headerName: i18n.t('Act'),
              field: 'fAct',
            },
            {
              headerName: i18n.t('Std'),
              field: 'fStd',
            },
          ],
        },
        {
          headerName: i18n.t('IP'),
          children: [
            {
              headerName: i18n.t('Act'),
              field: 'iAct',
            },
            {
              headerName: i18n.t('Std'),
              field: 'iStd',
            },
          ],
        },
      ],
      gridHeight: '640px',
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        width: 80,
        minWidth: 80,
        cellClass: 'text-center',
        suppressSizeToFit: false,
        cellStyle: function (params) {
          const { division } = params.node.data;
          switch (division) {
            case 'Farm Total':
            case 'Region Total':
            case 'Division Total':
              return { 'background-color': Const.AGGR_COLOR };
          }
        },
        valueFormatter: function (params) {
          return GridUtil.formatReport(params);
        },
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagenation: false,
      paginationPageSize: 10,
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    if (Const.RPT_TEST) {
      const params = ReportUtil.getDefaultParams(this.props);
      console.log('params:', params);
      this.fnGetRowData(params);
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  // -----------------------------------------------------------
  // 참고용 : ES/ETL 서버 주소(API)를 지정해야 합니다.
  // -----------------------------------------------------------
  fnGetRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/stat/broiler/dailyAreaR.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data.rows1 });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtExport = () => {
    const fileName =
      i18n.t(this.state.excelName) + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t(this.state.excelName);
    const params = {
      allColumns: false,
      columnGroups: true,
      fileName: fileName,
      sheetName: sheetName,
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onSearch = (params) => {
    console.log(params);
    this.fnGetRowData(params);
  };

  render() {
    return (
      <React.Fragment>
        <h3>
          {/* 2. 일일 사육 현황 (지역/Project별) */}
          <Trans>Title Daily Area R</Trans>
        </h3>

        <div className="con_box">
          <AFDatePickerSearch onSearch={this.onSearch}></AFDatePickerSearch>

          <div className="f_right mB20">
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
          </div>
          <div className="board_list_s">
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  excelStyles={this.state.excelStyles}
                  onGridReady={this.onGridReady}
                  rowData={this.state.rowData}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const sampleData = [
  { project: 'PJ19001' },
  { project: 'PJ19002' },
  { project: 'PJ19003' },
  { project: 'Area1 Sub tot' },
  { project: 'PJ19004' },
  { project: 'PJ19005' },
  { project: 'Area2 Sub tot' },
  { project: 'Total' },
];
