import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import '../Breeding.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class RelEgg extends React.Component {
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Release - Egg Release</Trans>
        </h2>

        <div className="con_box">
          <div className="txt_center mB20">
            <Link to="/breeding/release/1">
              <button className="btn_green2">
                <i className="mdi mdi-logout-variant"></i>
                <Trans>Sales</Trans>
              </button>
            </Link>
            <Link to="/breeding/release/2">
              <button className="btn_green2">
                <i className="mdi mdi-truck"></i>
                <Trans>Transfer</Trans>
              </button>
            </Link>
            <Link to="/breeding/release/3">
              <button className="btn_green2">
                <i className="mdi mdi-layers-off"></i>
                <Trans>Discard</Trans>
              </button>
            </Link>
          </div>

          <h3>
            <Trans>Egg Good List</Trans>
          </h3>
          <div className="board_list">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '12%' }} />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <Trans>Product Date</Trans>
                  </th>
                  <th>HH01</th>
                  <th>HH02</th>
                  <th>HH03</th>
                  <th>HH04</th>
                  <th>HH05</th>
                  <th>HH06</th>
                  <th>HH07</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>2019-10-28</th>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                </tr>
                <tr>
                  <th>2019-10-28</th>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                </tr>
                <tr>
                  <th>2019-10-28</th>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                </tr>
                <tr>
                  <th>2019-10-28</th>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                </tr>
                <tr>
                  <th>2019-10-28</th>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="page_num">
            <div className="inner">
              <Link to="#">
                <i className="mdi mdi-skip-previous"></i>
              </Link>
              <Link to="#">
                <i className="mdi mdi-chevron-left"></i>
              </Link>
              <Link to="#" className="current">
                1
              </Link>
              <Link to="#">2</Link>
              <Link to="#">3</Link>
              <Link to="#">4</Link>
              <Link to="#">5</Link>
              <Link to="#">6</Link>
              <Link to="#">7</Link>
              <Link to="#">8</Link>
              <Link to="#">9</Link>
              <Link to="#">9</Link>
              <Link to="#">
                <i className="mdi mdi-chevron-right"></i>
              </Link>
              <Link to="#">
                <i className="mdi mdi-skip-next"></i>
              </Link>
            </div>
          </div>

          <h3 className="mT40">
            <Trans>Culling Egg List</Trans>
          </h3>
          <div className="board_list">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '12%' }} />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <Trans>Product Date</Trans>
                  </th>
                  <th>
                    <Trans>Damage</Trans>
                  </th>
                  <th>
                    <Trans>Crack</Trans>
                  </th>
                  <th>
                    <Trans>Dirty</Trans>
                  </th>
                  <th>
                    <Trans>MissShape</Trans>
                  </th>
                  <th>
                    <Trans>Jumbo</Trans>
                  </th>
                  <th>
                    <Trans>Thin</Trans>
                  </th>
                  <th>
                    <Trans>Small</Trans>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>2019-10-28</th>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                </tr>
                <tr>
                  <th>2019-10-28</th>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                </tr>
                <tr>
                  <th>2019-10-28</th>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                </tr>
                <tr>
                  <th>2019-10-28</th>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                  <td>0000</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="page_num">
            <div className="inner">
              <Link to="#">
                <i className="mdi mdi-skip-previous"></i>
              </Link>
              <Link to="#">
                <i className="mdi mdi-chevron-left"></i>
              </Link>
              <Link to="#" className="current">
                1
              </Link>
              <Link to="#">2</Link>
              <Link to="#">3</Link>
              <Link to="#">4</Link>
              <Link to="#">5</Link>
              <Link to="#">6</Link>
              <Link to="#">7</Link>
              <Link to="#">8</Link>
              <Link to="#">9</Link>
              <Link to="#">9</Link>
              <Link to="#">
                <i className="mdi mdi-chevron-right"></i>
              </Link>
              <Link to="#">
                <i className="mdi mdi-skip-next"></i>
              </Link>
            </div>
          </div>
        </div>

        <div className="con_box">
          <h3>
            <Trans>Sales / Transfer / Discard List</Trans>
          </h3>

          <div className="border mB20 txt_center">
            <p className="spac50_right">
              <input type="checkbox" name="" id="1" />
              <label htmlFor="1">
                <Trans>Sales</Trans>
              </label>

              <input type="checkbox" name="" id="2" />
              <label htmlFor="2">
                <Trans>Transfer</Trans>
              </label>

              <input type="checkbox" name="" id="3" />
              <label htmlFor="3">
                <Trans>Discard</Trans>
              </label>
            </p>

            <p className="mT10">
              <label htmlFor="">
                <Trans>Date</Trans>
              </label>
              <input type="text" name="" className="input_type1" />
              &nbsp; ~ &nbsp;
              <input type="text" name="" className="input_type1" />
              <Link to="#">
                <button className="btn_gray">
                  <Trans>Search</Trans>
                </button>
              </Link>
            </p>
          </div>

          <div className="board_list">
            <table>
              <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <Trans>Date</Trans>
                  </th>
                  <th>
                    <Trans>Type</Trans>
                  </th>
                  <th>
                    <Trans>Customer</Trans>
                  </th>
                  <th>
                    <Trans>Qty</Trans>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>2019-10-28</th>
                  <td>
                    <Trans>Transfer</Trans>
                  </td>
                  <td>K-Mart</td>
                  <td>4,000</td>
                  <td>
                    <Link to="#">
                      <button className="btn_gray2">
                        <Trans>Detail</Trans>
                      </button>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <th>2019-10-28</th>
                  <td>
                    <Trans>Sales</Trans>
                  </td>
                  <td>K-Mart</td>
                  <td>400</td>
                  <td>
                    <Link to="#">
                      <button className="btn_gray2">
                        <Trans>Detail</Trans>
                      </button>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <th>2019-10-28</th>
                  <td>
                    <Trans>Transfer</Trans>
                  </td>
                  <td>K-Mart</td>
                  <td>4,000</td>
                  <td>
                    <Link to="#">
                      <button className="btn_gray2">
                        <Trans>Detail</Trans>
                      </button>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <th>2019-10-28</th>
                  <td>
                    <Trans>Sales</Trans>
                  </td>
                  <td>K-Mart</td>
                  <td>400</td>
                  <td>
                    <Link to="#">
                      <button className="btn_gray2">
                        <Trans>Detail</Trans>
                      </button>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="layer_list w100">
            <h4>
              2019-09-02 <Trans>B상회 거래내역</Trans>
            </h4>
            <div className="f_right mB10">
              <Link to="#">
                <button className="btn_gray">
                  <Trans>Delete</Trans>
                </button>
              </Link>
              <Link to="#">
                <button className="btn_blue">
                  <Trans>Modify</Trans>
                </button>
              </Link>
            </div>

            <div className="board_list">
              <table>
                <colgroup>
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '12%' }} />
                  <col style={{ width: '12%' }} />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <Trans>Product Date</Trans>
                    </th>
                    <th>Damage</th>
                    <th>Crack</th>
                    <th>Dirty</th>
                    <th>MissShape</th>
                    <th>Jumbo</th>
                    <th>Tin</th>
                    <th>Small</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>2019-10-28</th>
                    <td>0000</td>
                    <td>0000</td>
                    <td>0000</td>
                    <td>0000</td>
                    <td>0000</td>
                    <td>0000</td>
                    <td>0000</td>
                  </tr>
                  <tr>
                    <th>2019-10-28</th>
                    <td>0000</td>
                    <td>0000</td>
                    <td>0000</td>
                    <td>0000</td>
                    <td>0000</td>
                    <td>0000</td>
                    <td>0000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
