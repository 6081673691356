import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { DateUtil } from 'utils';
import { Const, Server } from 'components/Properties';

import EggList from '../deliver/EggList';
import EggSalesForm from '../deliver/EggSalesForm';
import EggTransferForm from '../deliver/EggTransferForm';
import EggDiscardForm from '../deliver/EggDiscardForm';
import EggSalesList from '../deliver/EggSalesList';

import '../Breeding.module.css';
import scrollToComponent from 'react-scroll-to-component';

/*
 ** Breeding > Release > Egg : Egg Release
 ** 출고
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class Egg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eggListView: false,
      eggSalesListView: false,
      transferFormView: false,
      salesFormView: false,
      discardFormView: false,
      epDate: DateUtil.today(),
      form: {},
      salesForm: [],
      transferForm: []
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnSearch();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.fnSearch();
    }
  }
  fnSearch = () => {
    window.scrollTo(0, 0);
    this.setState(
      {
        transferFormView: false,
        salesFormView: false,
        discardFormView: false,
        eggSalesListView: false,
        eggListView: false
      },
      function() {
        this.setState(
          {
            transferFormView: false,
            salesFormView: false,
            discardFormView: false,
            eggSalesListView: true,
            eggListView: true
          },
          function() {}
        );
      }
    );
  };
  fnClickSales = e => {
    this.setState(
      {
        transferFormView: false,
        discardFormView: false,
        salesFormView: true,
        salesForm: {
          ...this.state.salesForm,
          iuFlag: 'I',
          transferType: 'S'
        }
      },
      function() {
        scrollToComponent(this['salesFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000
        });
      }
    );
  };
  fnClickTransfer = e => {
    this.setState(
      {
        discardFormView: false,
        salesFormView: false,
        transferFormView: true,
        transferForm: {
          ...this.state.transferForm,
          iuFlag: 'I',
          transferType: 'T'
        }
      },
      function() {
        scrollToComponent(this['transferFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000
        });
      }
    );
  };
  fnClickDiscard = e => {
    this.setState(
      {
        transferFormView: false,
        salesFormView: false,
        discardFormView: true,
        discardForm: {
          ...this.state.discardForm,
          iuFlag: 'I',
          transferType: 'D'
        }
      },
      function() {
        scrollToComponent(this['discardFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000
        });
      }
    );
  };
  fnClosePopup = val => {
    window.scrollTo(0, 0);
    this.setState({ [val]: false }, function() {
      console.log('fnClosePopup===>', this.state);
    });
  };
  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Release - Egg Release</Trans>
        </h2>

        <div className="con_box">
          <div className="txt_center mB20">
            <button className="btn_green2" onClick={e => this.fnClickSales()}>
              <i className="mdi mdi-logout-variant"></i>
              <Trans>Sales</Trans>
            </button>
            <button
              className="btn_green2"
              onClick={e => this.fnClickTransfer()}
            >
              <i className="mdi mdi-truck"></i>
              <Trans>Transfer</Trans>
            </button>
            <button className="btn_green2" onClick={e => this.fnClickDiscard()}>
              <i className="mdi mdi-layers-off"></i>
              <Trans>Discard</Trans>
            </button>
          </div>

          <EggList eggListView={this.state.eggListView} />
        </div>
        {/* Sales / Transfer / Discard List */}
        <EggSalesList
          eggSalesListView={this.state.eggSalesListView}
          fnSearch={this.fnSearch} //삭제기능 후 다시 조회
        />

        <EggSalesForm
          ref={section => {
            this.salesFormView = section;
          }}
          salesFormView={this.state.salesFormView}
          salesForm={this.state.salesForm}
          fnClose={this.fnClosePopup}
          fnSearch={this.fnSearch}
        />
        <EggTransferForm
          ref={section => {
            this.transferFormView = section;
          }}
          transferFormView={this.state.transferFormView}
          transferForm={this.state.transferForm}
          fnClose={this.fnClosePopup}
          fnSearch={this.fnSearch}
        />
        <EggDiscardForm
          ref={section => {
            this.discardFormView = section;
          }}
          discardFormView={this.state.discardFormView}
          discardForm={this.state.discardForm}
          fnClose={this.fnClosePopup}
          fnSearch={this.fnSearch}
        />

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
