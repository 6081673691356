import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import { Radio } from 'antd';
import produce from 'immer';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { message, Select } from 'antd';

// import _ from 'lodash';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class CustomerName extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        farmCode: SessionUtil.getFarmCode()
      },
      codes: [],
      code: '',
      disabled: false
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnGetRowData(this.state.params);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.code !== this.props.code) {
      const { code } = this.props;
      if (code === '') this.setState({ codes: [] });
    }
    if (prevProps.disabled !== this.props.disabled) {
      this.setState({ code: '', disabled: this.props.disabled });
    }
  }

  // 코드 정보 가져오기
  fnGetRowData = params => {
    const that = this;
    let url = Server.getRestAPI() + '/basis/selectCustomerName.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        const { data } = response;
        const codes = [];
        if (data) {
          data.map((row, i) => {
            const obj = {
              codeValue: row.customerCode,
              codeText: row.customerName
            };
            codes.push(obj);
          });
          that.setState({ codes: codes });
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  onChange = value => {
    const that = this;
    // Q. 여기서 draft의 원형이 되는 값은 어디서 오는거에요??
    // A. this.setState가 원래 prevState를 인자로 주는 함수이기때문에
    //    prevState가 앞에 들어가서 고차함수로 빠지게됩니다.
    this.setState(
      produce(draft => {
        draft.code = value;
      }),
      function() {
        that.props.setCode(this.props.name, value);
      }
    );
  };

  render() {
    return (
      <Select
        type="text"
        name="code"
        // className="input_type1"
        style={{ width: 250 }}
        // value={this.state.code}
        onChange={this.onChange}
        placeholder={i18n.t('Select code')}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={this.props.disabled}
      >
        {this.state.codes.map((row, i) => {
          return (
            <option key={i} value={row.codeValue}>
              {row.codeText}
            </option>
          );
        })}
      </Select>
    );
  }
}
