import React, { Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { Popconfirm, Switch, message, DatePicker, Select, Modal } from 'antd';
import NumberFormat from 'react-number-format';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import '../Breeding.module.css';
const { confirm } = Modal;

/*
 ** Breeding > Release > Ps : Ps Release
 ** PsSalesList
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class PsSalesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInstance: this,
      epDate: DateUtil.today(),
      searchFromWkDt: moment(DateUtil.today())
        .add('-1', 'M')
        .format('YYYY-MM-DD'),
      searchToWkDt: DateUtil.today(),
      transferTypeS: true,
      transferTypeD: true,
      form: {},
      prop: {},
      rowData: [],
      columnDefs: [
        {
          headerName: '',
          field: 'delete',
          width: 110,
          cellRenderer: function (params) {
            if (params.data.transferDate === DateUtil.today()) {
              return '<button class="btn_del">Delete</button>';
            } else return null;
          },
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Date',
          field: 'transferDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: 'Type',
          field: 'transferType',
          width: 100,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: 'Customer',
          field: 'customer',
          width: 200,
          cellStyle: { textAlign: 'center' },
          hide: true,
        },
        {
          headerName: 'Customer',
          field: 'customerName',
          width: 200,
          cellStyle: { textAlign: 'left' },
        },
        {
          headerName: 'Culling Qty',
          field: 'cullingQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Culling Weight',
          field: 'cullingWeight',
          type: 'numericColumn',
          width: 120,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },

          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Close Qty',
          field: 'closeQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Close Weight',
          field: 'closeWeight',
          type: 'numericColumn',
          width: 150,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
      ],
      flockList: [],
      hhChickinList: [],
      eggProductionForm: [],
      eggProductionFormView: false,
      growingData: {},
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    // this.setState(
    //   { form: { ...this.state.form, searchFromWkDt: DateUtil.today() } },
    //   function() {}
    // );
    this.searchFromWkDt = React.createRef();
  }
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps.salesListView !== this.props.salesListView) {
  //     this.fnSearch('F');
  //     this.setState({ form: nextProps.transferForm }, function() {});
  //   }
  // }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.salesListView !== nextProps.salesListView) {
      //console.log('getDerivedStateFromProps', prevState, nextProps);
      prevState.currentInstance.fnSearch('F');
      return {
        form: nextProps.transferForm,
        salesListView: nextProps.salesListView,
      };
    }
    return null;
  }

  fnSearch = (t) => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.searchFromWkDt = this.state.searchFromWkDt;
    data.searchToWkDt = this.state.searchToWkDt;

    if (t !== 'F') {
      if (!data.searchFromWkDt) {
        this.searchFromWkDt.current.focus();
        return false;
      }
    }

    if (
      (this.state.transferTypeS ? "'S'" : '') !== '' &&
      (this.state.transferTypeD ? "'D'" : '') !== ''
    ) {
      data.transferType = '';
    } else if ((this.state.transferTypeS ? "'S'" : '') !== '') {
      data.transferType = 'Sales';
    } else if ((this.state.transferTypeD ? "'D'" : '') !== '') {
      data.transferType = 'Discard';
    } else return;

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/selectTpPsTransferList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ rowData: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };
  //Grid row 선택
  onCellClicked = (e) => {
    console.log('onCellClicked', e);
    //선택버튼 클릭
    //this.props.fnSelectFlock(e.data, this.state.tpBreedRecvData);

    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.transferNo = e.data.transferNo;
    data.discardNo = e.data.discardNo;

    console.log(data);
    if (e.column.colId === 'delete') {
      if (e.data.transferDate === DateUtil.today()) {
        confirm({
          //title: 'Do you Want to delete these items?',
          //content: '아래 거래내역도 삭제됩니다',
          title: i18n.t('Are you sure delete this task?'),
          onOk() {
            that.fnDelete(e.data);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }
    }
  };
  fnDelete = (data) => {
    let that = this;

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.iuFlag = 'D';

    data.closingQty = data.closeQty; //TP_PS_TRANSFER.CLOSE_QTY => TP_PS_STOCK.CLOSING_STOCK 값이 NULL이 됨
    data.closingWeight = data.closeWeight;

    console.log('delete', data);
    let url = Server.getRestAPI();

    if (data.transferNo !== 0) {
      url += '/breed/release/savePsSales.do';
    } else if (data.discardNo !== 0) {
      url += '/breed/release/savePsDiscard.do';
    } else return false;

    axios({
      method: 'post',
      url: url,
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.fnSearch();
        that.props.fnInit();
      })
      .catch(function (error) {
        return [];
      });
  };

  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  renderInput = (inputName, ty, dec, len) => {
    let cl = len ? 'input_type1 w' + len : 'input_type1';

    if (ty && ty === 'N') {
      cl = len ? 'input_base w' + len : 'input_base';
      return (
        <NumberFormat
          style={{ textAlign: 'right' }}
          className={cl}
          thousandSeparator={true}
          decimalScale={dec ? dec : 0}
          name={inputName}
          //ref={this[inputName]}
          getInputRef={(el) => (this[inputName] = el)}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : null
          }
          onChange={(e) => this.setOnChange(e, ty)}
        />
      );
    } else if (ty && ty === 'T') {
      return (
        <TimePicker
          //defaultValue={moment('12:08', 'HH:mm')}
          className={cl}
          format={'HH:mm'}
          ref={this[inputName]}
          value={
            this.state.form[inputName]
              ? moment(this.state.form[inputName], 'HH:mm') || ''
              : null
          }
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
        />
      );
    } else if (ty && ty === 'S') {
      return (
        <span>
          {this.state.form[inputName] ? this.state.form[inputName] || '' : ''}
        </span>
      );
    } else if (ty && ty === 'D') {
      return (
        <DatePicker
          name={inputName}
          //className={cl}
          ref={this[inputName]}
          value={this.state[inputName] ? moment(this.state[inputName]) : ''}
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
          allowClear={false}
        />
      );
    } else {
      return (
        <input
          type="text"
          name={inputName}
          className={cl}
          ref={this[inputName]}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : ''
          }
          onChange={(e) => this.setOnChange(e)}
        />
      );
    }
  };

  setOnChange = (e, params) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    that.setState({ [col]: val }, function () {});
  };
  //Salse - Transfer - Discard 선택 checkbox
  onCheckboxChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.checked }, function () {});
  };
  render() {
    if (!this.props.salesListView) return null;
    return (
      <Fragment>
        <div className="border mB20 txt_center">
          <p className="spac50_right">
            <input
              type="checkbox"
              name="transferTypeS"
              id="1"
              checked={this.state.transferTypeS}
              onChange={this.onCheckboxChangeHandler}
            />
            <label htmlFor="1">
              <Trans>Sales</Trans>
            </label>
            <input
              type="checkbox"
              name="transferTypeD"
              id="3"
              checked={this.state.transferTypeD}
              onChange={this.onCheckboxChangeHandler}
            />
            <label htmlFor="3">
              <Trans>Discard</Trans>
            </label>
          </p>
          <label htmlFor="">
            <Trans>Date</Trans>
          </label>
          {GridUtil.renderInput({
            inputName: 'searchFromWkDt', //*** */
            type: 'D', //*** */
            that: this, //*** */
            statsForm: this.state, //*** */
            setOnChange: this.setOnChange, //*** */
            width: 120, // px제외 : 없으면 100% */
            placeholder: 'Date',
          })}
          &nbsp; ~ &nbsp;
          {GridUtil.renderInput({
            inputName: 'searchToWkDt', //*** */
            type: 'D', //*** */
            that: this, //*** */
            statsForm: this.state, //*** */
            setOnChange: this.setOnChange, //*** */
            width: 120, // px제외 : 없으면 100% */
            placeholder: 'Date',
          })}
          <button className="btn_gray" onClick={() => this.fnSearch()}>
            <Trans>Search</Trans>
          </button>
        </div>

        <div
          className="board_list ag-theme-balham"
          style={{ height: '200px', width: '100%', marginBottom: '20px' }}
        >
          <AgGridReact
            columnDefs={this.state.columnDefs}
            rowData={this.state.rowData.length > 0 ? this.state.rowData : []}
            //onRowClicked={e => this.onRowClicked(e)}
            onCellClicked={(e) => this.onCellClicked(e)}
            onCellFocused={(e) => {
              e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
            }}
          ></AgGridReact>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
