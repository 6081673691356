import React, { Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { Const, Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';
import { moment } from 'components';
import { Select, Input } from 'antd';
import NumberFormat from 'react-number-format';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
  GridOptions,
} from 'components/commons/ag-grid/AgGridUtil';
import i18n from 'i18next';
import { withAlert } from 'react-alert';

import '../Hatchery.module.css';

/*
 ** Hatchery - Deliver - PsDiscardDoc - PsDiscardDocForm
 ** =============================
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class DiscardDocForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFromWkDt: moment(DateUtil.today())
        .add('-1', 'M')
        .format('YYYY-MM-DD'),
      searchToWkDt: DateUtil.today(),
      currentInstance: this,
      form: [],
      discardDocFormView: false,
      hatchingInfoList: [],
      hatchingData: [],
      strainCodeList: [],
      columnDefs: [
        {
          headerName: 'Hen House',
          field: 'hhId',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'StrainCode',
          field: 'strainCode',
          hide: true,
        },
        {
          headerName: 'Hatch Date',
          field: 'hatchDate',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'PS DOC(F)',
          field: 'psdocFQty',
          type: 'numericColumn',
          width: 80,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'PS DOC(M)',
          field: 'psdocMQty',
          type: 'numericColumn',
          width: 80,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'S/P',
          field: 'spQty',
          width: 80,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'A2',
          field: 'a2Qty',
          type: 'numericColumn',
          width: 80,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'A1',
          field: 'a1Qty',
          type: 'numericColumn',
          width: 80,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'BM',
          field: 'bmQty',
          type: 'numericColumn',
          width: 80,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'B',
          field: 'bQty',
          type: 'numericColumn',
          width: 80,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Polos',
          field: 'polosQty',
          type: 'numericColumn',
          width: 80,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Sex',
          field: 'sexQty',
          type: 'numericColumn',
          width: 80,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
      ],
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.discardDocFormView !== nextProps.discardDocFormView) {
      if (nextProps.discardDocFormView) prevState.currentInstance.fnSearch();
      return {
        discardDocFormView: nextProps.discardDocFormView,
        form: nextProps.discardDocForm,
      };
    }
    return null;
  }
  fnSearch = () => {
    window.scrollTo(0, 0);
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.searchFromWkDt = this.state.searchFromWkDt;
    data.searchToWkDt = this.state.searchToWkDt;
    data.hatchDate = DateUtil.today();
    data.codeId = 'EP26';
    data.calType = 'docDiscard';

    if (data.searchFromWkDt > data.searchToWkDt) {
      that.props.alert.show(i18n.t('Please check the date.'));
      that.searchFromWkDt.focus();
      return false;
    }

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/transfer/selectBroilerDocSalesInfoList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('selectGlowingLogList', response.data);
        //if (response.data.rows1.length > 0) {

        that.setState(
          {
            hatchingDataList: response.data.rows1,
            strainCodeList: response.data.rows2,
          },
          function () {}
        );
        //}
      })
      .catch(function (error) {
        return [];
      });

    this.fnSelectHatchingList('hatchDate');
  };
  //Sales 저장
  fnSave = () => {
    let that = this;
    let data = this.state.form;

    if (!data.hatchDate) {
      that.props.alert.show(i18n.t('Please enter Hatch Date'));
      this.hatchDate.focus();
      return false;
    }
    if (!data.discardDate) {
      that.props.alert.show(i18n.t('Please enter Transfer Date'));
      this.transferDate.focus();
      return false;
    }

    if (!checkQty(that)) {
      return false;
    }
    console.log('data', data);
    data.psdocFQty = Number(data.psdocFQty ? data.psdocFQty : 0);
    data.psdocMQty = Number(data.psdocMQty ? data.psdocMQty : 0);
    data.spQty = Number(data.spQty ? data.spQty : 0);
    data.a2Qty = Number(data.a2Qty ? data.a2Qty : 0);
    data.a1Qty = Number(data.a1Qty ? data.a1Qty : 0);
    data.bmQty = Number(data.bmQty ? data.bmQty : 0);
    data.bQty = Number(data.bQty ? data.bQty : 0);
    data.polosQty = Number(data.polosQty ? data.polosQty : 0);
    data.sexQty = Number(data.sexQty ? data.sexQty : 0);

    let qty =
      data.psdocFQty +
      data.psdocMQty +
      data.spQty +
      data.a2Qty +
      data.a1Qty +
      data.bmQty +
      data.bQty +
      data.polosQty;

    if (!qty || qty === 0) {
      that.props.alert.show(i18n.t('Please enter quantity.'));
      this.psdocFQty.focus();
      return false;
    }

    if (!checkQty(that)) {
      return false;
    }

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.updateId = SessionUtil.getUserId();
    data.hatcheryId = SessionUtil.getHatcheryId();

    data.strainCode = data.strainCode;

    if (data.iuFlag === 'I') {
      //Type
    }

    console.log('save', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/transfer/savePsDiscardDoc.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('savePsDoc', response.data);
        that.props.fnInit();
      })
      .catch(function (error) {
        return [];
      });

    //수량체크
    function checkQty(that) {
      let clos = [
        'psdocFQty',
        'psdocMQty',
        'spQty',
        'a2Qty',
        'a1Qty',
        'bmQty',
        'bQty',
        'polosQty',
        'sexQty',
      ];
      let chk = true;
      let hhId = that.state.form.hhId;
      clos.some((col) => {
        let co = col.replace('Box', '').replace('Qty', '').replace('Bonus', '');
        let hatchingQty = 0;
        let hatching = that.state.hatchingDataList.find(
          (row) => row.hhId === hhId
        );
        if (hatching) {
          //hatchingQty = hatching ? Number(hatching[co + 'Qty']) : 0;

          hatchingQty = that.state.clickedForm[co + 'Qty'];

          let qty = Number(
            that.state.form[co + 'Qty'] ? that.state.form[co + 'Qty'] : 0
          );

          if (qty > hatchingQty) {
            that.props.alert.show(i18n.t('The quantity is exceeded.'));
            that[col].focus();
            chk = false;
            return true;
          }
        } else {
          chk = false;
          that.props.alert.show(i18n.t('Please check Strain.'));
          // that.strainCode.focus();
          return true;
        }
      });
      return chk;
    }
  };

  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  renderInput = (inputName, ty, dec, len) => {
    let cl = len ? 'input_type1 w' + len : 'input_type1';

    if (ty && ty === 'N') {
      cl = len ? 'input_type2 w' + len : 'input_type2';
      return (
        <NumberFormat
          style={{ textAlign: 'right' }}
          className={cl}
          thousandSeparator={true}
          decimalScale={dec ? dec : 0}
          name={inputName}
          //ref={this[inputName]}
          getInputRef={(el) => (this[inputName] = el)}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : null
          }
          onChange={(e) => this.setOnChange(e, ty)}
        />
      );
    } else if (ty && ty === 'T') {
      return (
        <TimePicker
          //defaultValue={moment('12:08', 'HH:mm')}
          className={cl}
          format={'HH:mm'}
          ref={this[inputName]}
          value={
            this.state.form[inputName]
              ? moment(this.state.form[inputName], 'HH:mm') || ''
              : null
          }
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
        />
      );
    } else if (ty && ty === 'S') {
      return (
        <span>
          {this.state.form[inputName] ? this.state.form[inputName] || '' : ''}
        </span>
      );
    } else {
      return (
        <Input
          type="text"
          name={inputName}
          className={cl}
          maxLength={dec}
          ref={this[inputName]}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : ''
          }
          onChange={(e) => this.setOnChange(e)}
        />
      );
    }
  };
  setOnChange = (e, params) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }

    let colBox = col.substring(col.length - 3, col.length);
    let colBonus = col.substring(col.length - 5, col.length);

    let qtys = 0;
    let bonus = 0;
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
      if (colBox === 'Box') {
        qtys = val * 100;
        bonus = val * 2;
      }
    }
    that.setState(
      {
        [col]: val,
        form: {
          ...this.state.form,
          [col]: val,
          [colBox === 'Box' ? col.replace('Box', 'Qty') : null]: qtys,
          [colBox === 'Box' ? col.replace('Box', 'Bonus') : null]: bonus,
        },
      },
      function () {
        that.fnSelectHatchingList(col);
      }
    );
  };
  fnSelectHatchingList = (col) => {
    let data = {};
    let that = this;

    if (col === 'hatchDate' || col === 'strainCode') {
      let hatchDate = that.state.form.hatchDate
        ? that.state.form.hatchDate
        : DateUtil.today();
      if (hatchDate) {
        data.farmCode = SessionUtil.getFarmCode();
        data.dateFormat = SessionUtil.getDateFormat();
        data.hatcheryId = SessionUtil.getHatcheryId();
        data.hatchDate = hatchDate;

        console.log('fnSelectHatchingList', data);
        //data.receiveNo = '';
        const url = Server.getRestAPI();
        axios({
          method: 'post',
          url: url + '/hatchery/transfer/selectHatchingList.do',
          data: qs.stringify(data),
          withCredentials: true,
          credentials: 'same-origin',
        })
          .then(function (response) {
            that.setState(
              {
                hatchingData: response.data.rows1 ? response.data.rows1 : [],
              },
              function () {
                console.log('hatchingData', that.state.hatchingData);
              }
            );
          })
          .catch(function (error) {
            return [];
          });
      }
    }
  };
  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  onCellClicked = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        strainCodeNm: e.data.strainCodeNm,
        strainCode: e.data.strainCode,
        hatchDate: e.data.hatchDate,
        psdocFQty: e.data.psdocFQty,
        psdocMQty: e.data.psdocMQty,
        spQty: e.data.spQty,
        a1Qty: e.data.a1Qty,
        a2Qty: e.data.a2Qty,
        bQty: e.data.bQty,
        bmQty: e.data.bmQty,
        polosQty: e.data.polosQty,
        hhId: e.data.hhId,
        sexQty: e.data.sexQty,
        eggReceiveNo: e.data.eggReceiveNo,
        settingSeq: e.data.settingSeq,
      },
      clickedForm: {
        ...this.state.clickedForm,
        strainCodeNm: e.data.strainCodeNm,
        strainCode: e.data.strainCode,
        hatchDate: e.data.hatchDate,
        psdocFQty: e.data.psdocFQty,
        psdocMQty: e.data.psdocMQty,
        spQty: e.data.spQty,
        a1Qty: e.data.a1Qty,
        a2Qty: e.data.a2Qty,
        bQty: e.data.bQty,
        bmQty: e.data.bmQty,
        polosQty: e.data.polosQty,
        hhId: e.data.hhId,
        sexQty: e.data.sexQty,
        eggReceiveNo: e.data.eggReceiveNo,
        settingSeq: e.data.settingSeq,
      },
      iuFlag: 'I',
    });
  };
  render() {
    if (!this.props.discardDocFormView) return null;
    let strainOptions = [];
    const Option = Select.Option;
    if (this.state.strainCodeList && this.state.strainCodeList.length > 0) {
      strainOptions = this.state.strainCodeList.map((row, i) => {
        return (
          <Option key={i} value={row.strainCode}>
            {row.strainCodeNm}
          </Option>
        );
      });
    }
    return (
      <Fragment>
        <h3 className="mT20">
          <Trans>Discard DOC</Trans>
        </h3>
        <div className="board_list2">
          <table>
            <colgroup>
              <col style={{ width: '15%' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '15%' }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <Trans>Hatch Date</Trans>
                </th>
                <td>{this.state.form.hatchDate}</td>
                <th>
                  <Trans>Hen House</Trans> <span className="red">*</span>
                </th>
                <td>{this.state.form.hhId}</td>
              </tr>
              <tr>
                <th>
                  <Trans>Discard Date</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'discardDate', //*** */
                    type: 'D', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    placeholder: 'discardDate',
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Remarks</Trans>
                </th>
                <td colSpan="5">
                  {GridUtil.renderInput({
                    inputName: 'remarks', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    //width: 120, // px제외 : 없으면 100% */
                    maxLength: 1000,
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Discard DOC</Trans>
                </th>
                <td colSpan="3">
                  <font className="wtitle">
                    <Trans>PS Female</Trans>
                  </font>
                  {/* 김기철 수석 삭제 요청
                    {this.renderInput('psdocFBox', 'N', 0, 'w30')}
                    <font className="mR20"> Box</font>
                    */}

                  {GridUtil.renderInput({
                    inputName: 'psdocFQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}

                  <font className="mR10"> Qty</font>
                  {/* 김기철 수석 삭제 요청
                    <font className="mL10 mR10">+</font>
                    {this.renderInput('psdocFBonus', 'N', 0, 'w30')}
                    <font className="mR10"> Bonus</font>
                    */}
                  <br />
                  <font className="wtitle">
                    <Trans>PS Male</Trans>
                  </font>
                  {/* 김기철 수석 삭제 요청 2020.02.18
                    {this.renderInput('psdocMBox', 'N', 0, 'w30')}
                    <font className="mR20"> Box</font>
                    */}
                  {GridUtil.renderInput({
                    inputName: 'psdocMQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Qty</font>
                  {/* 김기철 수석 삭제 요청 2020.02.18
                    <font className="mL10 mR10">+</font>
                    {this.renderInput('psdocMBonus', 'N', 0, 'w30')}
                    <font className="mR10"> Bonus</font>
                    */}
                  <br />

                  <font className="wtitle">
                    <Trans>S/P</Trans>
                  </font>
                  {/* 김기철 수석 삭제 요청 2020.02.18
                    {this.renderInput('spBox', 'N', 0, 'w30')}
                    <font className="mR20"> Box</font>
                    */}
                  {GridUtil.renderInput({
                    inputName: 'spQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Qty</font>
                  {/* 김기철 수석 삭제 요청 2020.02.18
                    <font className="mL10 mR10">+</font>
                    {this.renderInput('spBonus', 'N', 0, 'w30')}
                    <font className="mR10"> Bonus</font>
                    */}
                  <br />
                  <font className="wtitle">
                    <Trans>A2</Trans>
                  </font>
                  {/* 김기철 수석 삭제 요청 2020.02.18
                    {this.renderInput('a2Box', 'N', 0, 'w30')}
                    <font className="mR20"> Box</font>
                    */}
                  {GridUtil.renderInput({
                    inputName: 'a2Qty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Qty</font>
                  {/* 김기철 수석 삭제 요청 2020.02.18
                    <font className="mL10 mR10">+</font>
                    {this.renderInput('a2Bonus', 'N', 0, 'w30')}
                    <font className="mR10"> Bonus</font>
                    */}
                  <br />
                  <font className="wtitle">
                    <Trans>A1</Trans>
                  </font>
                  {/* 김기철 수석 삭제 요청 2020.02.18
                    {this.renderInput('a1Box', 'N', 0, 'w30')}
                    <font className="mR20"> Box</font>
                    */}
                  {GridUtil.renderInput({
                    inputName: 'a1Qty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Qty</font>
                  {/* 김기철 수석 삭제 요청 2020.02.18
                    <font className="mL10 mR10">+</font>
                    {this.renderInput('a1Bonus', 'N', 0, 'w30')}
                    <font className="mR10"> Bonus</font>
                    */}
                  <br />
                  <font className="wtitle">
                    <Trans>BM</Trans>
                  </font>
                  {/* 김기철 수석 삭제 요청 2020.02.18
                    {this.renderInput('bmBox', 'N', 0, 'w30')}
                    <font className="mR20"> Box</font>
                    */}
                  {GridUtil.renderInput({
                    inputName: 'bmQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Qty</font>
                  {/* 김기철 수석 삭제 요청 2020.02.18
                    <font className="mL10 mR10">+</font>
                    {this.renderInput('bmBonus', 'N', 0, 'w30')}
                    <font className="mR10"> Bonus</font>
                    */}
                  <br />
                  <font className="wtitle">
                    <Trans>B</Trans>
                  </font>
                  {/* 김기철 수석 삭제 요청 2020.02.18
                    {this.renderInput('bBox', 'N', 0, 'w30')}
                    <font className="mR20"> Box</font>
                    */}
                  {GridUtil.renderInput({
                    inputName: 'bQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Qty</font>
                  {/* 김기철 수석 삭제 요청 2020.02.18
                    <font className="mL10 mR10">+</font>
                    {this.renderInput('bBonus', 'N', 0, 'w30')}
                    <font className="mR10"> Bonus</font>
                    */}
                  <br />
                  <font className="wtitle">
                    <Trans>Polos</Trans>
                  </font>
                  {/* 김기철 수석 삭제 요청 2020.02.18
                    {this.renderInput('polosBox', 'N', 0, 'w30')}
                    <font className="mR20"> Box</font>
                    */}
                  {GridUtil.renderInput({
                    inputName: 'polosQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Qty</font>
                  {/* 김기철 수석 삭제 요청 2020.02.18
                    <font className="mL10 mR10">+</font>
                    {this.renderInput('polosBonus', 'N', 0, 'w30')}
                    <font className="mR10"> Bonus</font>
                    */}
                  <br />
                  <font className="wtitle">
                    <Trans>Sex</Trans>
                  </font>
                  {/* 김기철 수석 삭제 요청 2020.02.18
                    {this.renderInput('polosBox', 'N', 0, 'w30')}
                    <font className="mR20"> Box</font>
                    */}
                  {GridUtil.renderInput({
                    inputName: 'sexQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}
                  <font className="mR10"> Qty</font>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="txt_center mT10 mB10">
          <button className="btn_gray" onClick={() => this.props.fnInit()}>
            <Trans>Clear</Trans>
          </button>
          <button className="btn_save" onClick={() => this.fnSave()}>
            <Trans>save</Trans>
          </button>
        </div>

        <div
          className="board_list ag-theme-balham"
          style={{ height: '300px', width: '100%' }}
        >
          {GridUtil.renderInput({
            inputName: 'searchFromWkDt', //*** */
            type: 'D', //*** */
            that: this, //*** */
            statsForm: this.state, //*** */
            setOnChange: this.setOnChange, //*** */
            width: 120, // px제외 : 없으면 100% */
            placeholder: 'Date',
          })}
          &nbsp; ~ &nbsp;
          {GridUtil.renderInput({
            inputName: 'searchToWkDt', //*** */
            type: 'D', //*** */
            that: this, //*** */
            statsForm: this.state, //*** */
            setOnChange: this.setOnChange, //*** */
            width: 120, // px제외 : 없으면 100% */
            placeholder: 'Date',
          })}
          <button className="btn_gray" onClick={() => this.fnSearch()}>
            <Trans>Search</Trans>
          </button>
          <AgGridReact
            onGridReady={(e) => this.onGridReady(e, 'g2')}
            columnDefs={this.state.columnDefs}
            rowData={
              this.state.hatchingDataList ? this.state.hatchingDataList : []
            }
            defaultColDef={this.state.defaultColDef}
            onCellClicked={(e) => this.onCellClicked(e)}
            rowSelection="single"
            onCellFocused={(e) => {
              e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
            }}
          ></AgGridReact>
        </div>
      </Fragment>
    );
  }
}
export default withAlert()(DiscardDocForm);
