import React, { Fragment, Component } from 'react';
import { Button } from 'reactstrap';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';
import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import i18n from 'i18next';
import { observer, inject } from 'mobx-react';
import MaterialInputForm from 'components/mobile/material/MaterialInputForm';
import MobileHeader from 'components/mobile/header/MobileHeader';
import ModalContainer from 'containers/pages/mobile/common/ModalContainer';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 ** 4. 파일명과 클래스명을 동일하게 해 주세요.
 */

@inject((stores) => ({
  mStore: stores.MobileStore,
  mProjectStore: stores.MprojectStore,
  mMaterialStore: stores.MMaterialStore,
}))
@observer
class MaterialForm extends Component {
  componentDidMount() {
    const { mMaterialStore } = this.props;
    mMaterialStore.initSaveForm();

    if (sessionStorage.getItem('iuFlag') == 'I') {
      mMaterialStore.setStockDate(DateUtil.today());
    }
    if (sessionStorage.getItem('iuFlag') == 'U') {
      //수정의 경우 기존정보 가져오기
      this.fnSelectMaterialIoInfo();
    }
  }

  componentWillUnmount() {
    const { mStore } = this.props;
    mStore.setIuFlagInsert();
  }

  fnOpenSearchModal = (value) => {
    const { mStore } = this.props;
    mStore.setSearchType(value);
    if (value == 'TM') {
      this.fnSelectMaterialInfoTotal();
    }
    if (value == 'C') {
      this.fnSelectCustomer();
    }
    mStore.setModalOpen('S', null, null);
  };

  fnOpenConfirmModal = (value, msg) => {
    const { mStore } = this.props;
    mStore.setModalOpen('C', value, msg);
  };

  //자재 정보 저장이벤트
  fnModalPos = () => {
    const { mStore } = this.props;
    mStore.setModalClose();
    this.fnInsertMaterialIO();
  };

  //자재 정보 삭제
  fnModalDelete = () => {
    const { mStore } = this.props;
    mStore.setModalClose();
    this.fnDeleteMaterialIO();
  };

  //자재 전체 정보 검색
  fnSelectMaterialInfoTotal = (value) => {
    const { mStore } = this.props;
    let params = {};

    if (value != '') {
      mStore.setSearchKey(value);
    }

    params.countryCode = SessionUtil.getCountryCode();
    params.dateFormat = SessionUtil.getDateFormat();
    params.materialType = mStore.searchType;
    params.searchKey = mStore.searchKey;
    params.pageNo = mStore.searchPageNo;
    params.pageSize = 10;

    let url = Server.getRestAPI() + '/basis/selectTcMaterialInfo.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        mStore.setSearchResultList(response.data);
        mStore.setSearchRowCnt(response.data.length);
        if (response.data.length > 0) {
          mStore.setSearchTotal(response.data[0].total);
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  //Customer 코드 찾기
  fnSelectCustomer = (value) => {
    const { mStore } = this.props;

    let params = {};

    if (value != '') {
      mStore.setSearchKey(value);
    }

    params.searchKey = mStore.searchKey;
    params.pageNo = mStore.searchPageNo;
    params.pageSize = 10;

    const that = this;
    let url = Server.getRestAPI() + '/basis/selectTcCustomer.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          mStore.setSearchResultList(response.data);
          mStore.setSearchRowCnt(response.data.length);
          if (response.data.length > 0) {
            mStore.setSearchTotal(response.data[0].total);
          }
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  //자재 정보 저장API
  fnInsertMaterialIO = () => {
    const { mMaterialStore, mStore } = this.props;
    let params = mMaterialStore.saveForm;

    params.farmCode = SessionUtil.getFarmCode();
    params.dateFormat = SessionUtil.getDateFormat();
    params.updateId = SessionUtil.getUserId();
    params.projCode = SessionUtil.getProjCode();
    const url = Server.getRestAPI() + '/basis/insertTcMaterialIO.do';

    if (mMaterialStore.saveForm.materialCode == '') {
      // 입고수량이 0인 경우
      mStore.setModalOpen('A', null, i18n.t('Please insert Materials'));
      return false;
    }

    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then((response) => {
        this.props.history.push('/mobile/broiler/material');
      })
      .catch(function (error) {
        return [];
      });
  };

  //입력된 자재 정보 취득 API
  fnSelectMaterialIoInfo = () => {
    const { mMaterialStore } = this.props;
    const that = this;
    let params = {};
    params.farmCode = SessionUtil.getFarmCode();
    params.projCode = SessionUtil.getProjCode();
    params.dateFormat = SessionUtil.getDateFormat();
    params.stockSeq = sessionStorage.getItem('stockSeq');
    let url = Server.getRestAPI() + '/mobile/broiler/mSelectMaterialList.do';

    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then((response) => {
        this.fnSetMaterialInfo(response.data.rows1[0]);
      })
      .catch(function (error) {
        return [];
      });
  };

  fnSetMaterialInfo = (data) => {
    const { mMaterialStore } = this.props;
    mMaterialStore.setSaveFormForUpdate(data);

    if (data.materialType == 'F') {
      mMaterialStore.setMaterialType('Feed');
    }
    if (data.materialType == 'V') {
      mMaterialStore.setMaterialType('Vaccine');
    }
    if (data.materialType == 'M') {
      mMaterialStore.setMaterialType('Medicine');
    }
    if (data.ioFlag == 'I') {
      document.getElementById('radio_in').checked = true;
    }
    if (data.ioFlag == 'O') {
      document.getElementById('radio_out').checked = true;
    }
  };

  //자재정보 삭제 API
  fnDeleteMaterialIO = () => {
    const { mMaterialStore } = this.props;
    let params = {};
    params.farmCode = SessionUtil.getFarmCode();
    params.stockSeq = mMaterialStore.selectedMaterialList.stockSeq;
    params.materialCode = mMaterialStore.selectedMaterialList.materialCode;

    const url = Server.getRestAPI() + '/basis/deleteTcMaterialIO.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then((response) => {
        this.props.history.push('/mobile/broiler/material');
      })
      .catch(function (error) {
        return [];
      });
  };

  fnPushBack = () => {
    this.props.history.push('/mobile/broiler/material');
  };

  render() {
    const { mStore, mMaterialStore } = this.props;

    return (
      <Fragment>
        {mStore.headerYn == false ? '' : <MobileHeader mStore={mStore} />}

        <div className="pop_header">
          <div className="mback" onClick={(e) => this.fnPushBack()}>
            <Trans>back</Trans>
          </div>
          [{sessionStorage.getItem('projCode')}]
          <div
            className="mobile_header_confirm"
            onClick={(e) =>
              mStore.iuFlag == 'U'
                ? alert('업데이트 프로세스 확정시 구현')
                : this.fnOpenConfirmModal(
                    'S',
                    i18n.t('Are you sure you want to save it?')
                  )
            }
          >
            <Trans>Save</Trans>
          </div>
        </div>
        <div className="mobile_content_nofooter">
          <p className="mobile_materialform_title">
            <Trans>Input Meterial Receive Info</Trans>
          </p>
          <MaterialInputForm
            mMaterialStore={mMaterialStore}
            fnOpenSearchModal={this.fnOpenSearchModal}
          />
          {mStore.iuFlag == 'I' ? null : (
            <div
              style={{ marginTop: '10px', textAlign: 'right' }}
              onClick={(e) =>
                this.fnOpenConfirmModal(
                  'D',
                  i18n.t('Are you sure you want to delete it?')
                )
              }
            >
              <Button>
                <Trans>Delete</Trans>
              </Button>
            </div>
          )}
        </div>
        <ModalContainer
          confirm={this.fnModalPos}
          delete={this.fnModalDelete}
          fnSelectMaterialInfoTotal={this.fnSelectMaterialInfoTotal}
          fnSelectCustomer={this.fnSelectCustomer}
        />
      </Fragment>
    );
  }
}

export default MaterialForm;
