import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, ObjectUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';
import { inject, observer } from 'mobx-react';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker, Checkbox } from 'antd';

import SearchInput from 'utils/SearchInput';

import '../Report.module.css';

/*
 ** [공통 개발시 유의 사항 입니다]
 ** 1. 변수명은 camelCase 로 작성하시길 바랍니다.
 ** 2. 다국어 표현은 <Trans>글자</Trans> 또는 i18n.t('글자')로 부탁 드립니다.
 */
{
  /* Division, Region, Plazma, Index(지표) 검색 */
}
@inject((stores) => ({ mainStore: stores.MainStore }))
@observer
export default class AFDatePickerSearch extends React.PureComponent {
  constructor(props) {
    super(props);
    const { mainStore } = props;
    const { isAreaManager } = mainStore;
    const { groupId, userLevel, division } = mainStore.getUser();
    this.state = {
      groupId: groupId,
      userLevel: userLevel,
      disables: {
        select: true,
        submit: true,
      },
      params: {
        type: isAreaManager() ? 'area' : 'farm', // 매니저만이 지역 검색을 할 수 있습니다.
        // division:
        //   userLevel === Const.COUNTRY || userLevel === Const.DIVISION
        //     ? undefined
        //     : division,
      },
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // farmCode 또는 Area 정보가 설정이 되면, 날짜와 검색 버튼이 활성화 됩니다.
    if (
      prevState.params.farmCode !== this.state.params.farmCode ||
      prevState.params.division !== this.state.params.division
    ) {
      const flag =
        _.isEmpty(this.state.params.farmCode) &&
        _.isEmpty(this.state.params.division);
      console.log(flag, this.state.params);
      this.setState(
        produce((draft) => {
          draft.disables['select'] = flag;
          draft.disables['submit'] = flag;
        }),
        function () {}
      );
    }
  }

  onBtSearch = () => {
    const { params } = this.state;
    this.props.onSearch(ObjectUtil.getClearnedObject(params));
  };

  onChangeRadio = (e) => {
    const that = this;
    const { name, value } = e.target;
    console.log(name, value);
    this.setState(
      produce((draft) => {
        draft.params['type'] = value;
        draft.params['farmCode'] = undefined; // area, farm 변경시마다 초기화 되어야 합니다.
        draft.params['division'] = undefined;
        draft.params['region'] = undefined;
      }),
      function () {
        const { params } = this.state;
        console.log(params);
      }
    );
  };

  onChangeCheckbox = (values) => {
    const that = this;
    // const { name, value } = e.target;
    this.setState(
      produce((draft) => {
        draft.params['checkBox'] = values;
      }),
      function () {
        const { params } = this.state;
        console.log(params);
      }
    );
  };

  setCode = (name, value) => {
    this.setState(
      produce((draft) => {
        draft.params[name] = value;
      }),
      function () {}
    );
  };

  render() {
    const { groupId, userLevel } = this.state;
    const { isAreaManager, isDivisionManager } = this.props.mainStore;
    const options = [
      { key: 'bodyWeight', value: 'bodyWeight', name: 'Body Weight' },
      { key: 'depCum', value: 'depCum', name: 'Depletion Cum' },
      { key: 'feedCum', value: 'feedCum', name: 'Feed Cum' },
      { key: 'fcr', value: 'fcr', name: 'FCR' },
      { key: 'ip', value: 'ip', name: 'IP' },
    ];
    const indexOptions = options.map((row, i) => {
      return (
        <Select.Option key={row.key} value={row.value}>
          {row.name}
        </Select.Option>
      );
    });
    return (
      <React.Fragment>
        <div className="f_left langsel">
          {/* Division,Region,Plazma 선택 */}
          {isAreaManager() && (
            <Radio.Group
              name="type" // area, farm
              value={this.state.params.type}
              onChange={this.onChangeRadio}
            >
              <Radio value={'area'}>
                <Trans>Area</Trans>
              </Radio>
              <Radio value={'farm'}>
                <Trans>Farm</Trans>
              </Radio>
            </Radio.Group>
          )}

          {this.state.params.type === 'area' && (
            <React.Fragment>
              {isDivisionManager() && (
                <SearchInput
                  placeholder={i18n.t('divisionCode')}
                  style={{
                    width: 200,
                  }}
                  inputParam="division" // SelectInput의 분류
                  inputName="division" // member variable of this.state.params
                  inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
                  // farmClass="A"
                  defaultValue={this.state.params.division}
                  getValue={(value) => {
                    this.setCode('division', value);
                    this.setCode('region', undefined);
                  }} //***** */
                  that={this} //***** */
                />
              )}
              <SearchInput
                placeholder="regionCode"
                style={{
                  width: 200,
                  marginRight: 20,
                }}
                inputParam="region" // SelectInput의 분류
                inputName="region" // member variable of this.state.params
                inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
                // farmClass="A"
                pValue0={this.state.params.division} // division이 먼저 선택되어 있어야 활성화
                defaultValue={this.state.params.region}
                getValue={(value) => {
                  this.setCode('region', value);
                }} //***** */
                that={this} //***** */
              />
            </React.Fragment>
          )}

          {this.state.params.type === 'farm' && (
            <SearchInput
              placeholder={i18n.t('farmCode')}
              style={{
                width: 200,
                marginRight: 20,
                // textTransform: 'uppercase'
              }}
              inputParam="farmCode" // SelectInput의 분류
              inputName="farmCode" // member variable of this.state.params
              inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
              // farmClass="A"
              defaultValue={this.state.params.farmCode}
              getValue={(value) => this.setCode('farmCode', value)} //***** */
              that={this} //***** */
            />
          )}

          {this.props.index && (
            <Select
              style={{
                width: 200,
                marginRight: 20,
              }}
              placeholder={i18n.t('Index')}
              onChange={this.onChangeIndex}
              disabled={this.state.disables.select} //
            >
              {indexOptions}
            </Select>
          )}

          <button
            className={this.state.disables.submit ? 'btn_gray' : 'btn_green'}
            disabled={this.state.disables.submit}
            onClick={this.onBtSearch}
          >
            <Trans>Search</Trans>
          </button>
        </div>
      </React.Fragment>
    );
  }
}
