import * as React from 'react';
import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import { SessionUtil, ObjectUtil } from 'utils';
import { Trans } from 'react-i18next';
import i18n from 'i18n';
import { Tag, Divider, Popover, Button } from 'antd';

import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from 'recharts';
import './Broiler.module.css';

const ProjGphAnlysRechart = (props) => {
  const [width, setWidth] = useState(0);
  const [type, setType] = useState('monotone');
  const [dashArray, setDashArray] = useState('5 5');
  const [currentChart, setCurrentChart] = useState();
  const [rowData, setRowData] = useState(props.rowData);
  const [visible, setVisible] = useState(false);
  const Color = {
    BWS: '#5B99DA', // (실선) - Body Weight
    DCS: '#E78136', // (실선) - Dep.Cum
    FCS: '#A4A4A4', // (실선) - PointFeed
    FCRS: '#FDC104', // (실선) - FCR
    IPS: '#4673C0', // (실선) - IP
    BWA: '#809F40', // (점선) - Body Weight
    DCA: '#295C8C', // (점선) - Dep.Cum
    FCA: '#8C491A', // (점선) - PointFeed
    FCRA: '#616161', // (점선) - FCR
    IPA: '#8A7511', // (점선) - IP
  };

  useEffect(() => {
    const conBoxWidth = ObjectUtil.getScrollWidth('con_box');
    setWidth(conBoxWidth);
    setRowData(props.rowData);
    // console.log(props.rowData);
  }, [props.rowData]);

  const weightedInfo = (
    <div>
      <Trans>IP / 100 </Trans>
      <br />
      <Trans>0 ~ 3 Age, Value of IP is 0</Trans>
      <br />
    </div>
  );

  return (
    <React.Fragment>
      <div style={{ textAlign: 'right', marginRight: '62px' }}>
        <Popover
          title={i18n.t('Weighted Information')}
          content={
            <div>
              {weightedInfo}
              <div style={{ textAlign: 'center' }}>
                <button className="btn_gray" onClick={() => setVisible(false)}>
                  Close
                </button>
              </div>
            </div>
          }
          trigger="click"
          visible={visible}
          onVisibleChange={setVisible}
        >
          <Tag color="magenta" style={{ cursor: 'pointer' }}>
            <Trans>The figures on the graph are weighted.</Trans>
          </Tag>
        </Popover>
      </div>
      <LineChart
        ref={(chart) => setCurrentChart(chart)}
        // onClick={() => {
        //   const chartSVG = ReactDOM.findDOMNode(currentChart).children[0];
        // }}
        width={width}
        height={700}
        data={rowData}
        margin={{ top: 20, right: 60, left: 0, bottom: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="day" />
        <YAxis type="number" domain={[0, 'dataMax']} />
        <Tooltip />
        <Legend />
        <Line type={type} dataKey="Population" stroke="#f04e98" dot={false} />
        <Line
          type={type}
          dataKey="Depletion_Act"
          stroke={Color.DCA}
          strokeDasharray={dashArray}
          dot={false}
        />
        <Line
          type={type}
          dataKey="Depletion_Std"
          stroke={Color.DCS}
          dot={false}
        />
        <Line
          type={type}
          dataKey="PointFeed_Act"
          stroke={Color.FCA}
          strokeDasharray={dashArray}
          dot={false}
        />
        <Line
          type={type}
          dataKey="PointFeed_Std"
          stroke={Color.FCS}
          dot={false}
        />
        <Line
          type={type}
          dataKey="BodyWeight_Act"
          stroke={Color.BWA}
          strokeDasharray={dashArray}
          dot={false}
        />
        <Line
          type={type}
          dataKey="BodyWeight_Std"
          stroke={Color.BWS}
          dot={false}
        />
        <Line
          type={type}
          dataKey="FCR_Act"
          stroke={Color.FCRA}
          strokeDasharray={dashArray}
          dot={false}
        />
        <Line type={type} dataKey="FCR_Std" stroke={Color.FCRS} dot={false} />
        <Line
          type={type}
          dataKey="IP_Act"
          stroke={Color.IPA}
          strokeDasharray={dashArray}
          dot={false}
        />
        <Line type={type} dataKey="IP_Std" stroke={Color.IPS} dot={false} />
      </LineChart>
    </React.Fragment>
  );
};

export default ProjGphAnlysRechart;
