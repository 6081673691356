import React, { Fragment } from 'react';
import { withAlert } from 'react-alert';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { Const, Server } from 'components/Properties';
import { Modal, Button, DatePicker } from 'antd';
import axios from 'axios';
import qs from 'qs';
import i18n from 'i18next';

import { moment } from 'components';
import NumberFormat from 'react-number-format';

import '../Hatchery.module.css';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 ** 4. colSpan => conSpan, rowSpan => rowSpan 입니다.
 */
class GradeDiscardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: [],
      discardDate: DateUtil.today(),
      eggQtySum: 0,
      gradingQty: 0,
      hatchingEggQty: 0,
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let that = this;
    if (prevProps.rowData !== this.props.rowData) {
      if (this.props.rowData.hatchingEggQty) {
        this.setState(
          {
            gradingQty: this.props.rowData.hatchingEggQty,
            eggQtySum: 0,
            form: {
              ...this.state.form,
              missShapeEggQty: '0',
              thinEggQty: '0',
              crackEggQty: '0',
              damageEggQty: '0',
              dirtyEggQty: '0',
              smallEggQty: '0',
              jumboEggQty: '0',
              hatchingEggQty: this.props.rowData.hatchingEggQty,
              gradingRem: '',
              discardDate: '',
              receiveDate: this.props.rowData.receiveDate,
              discardQtyInput: '0',
            },
          },
          function () {}
        );
      }
      if (this.props.rowData.hatchingEggQty) {
        this.setState(
          {
            gradingQty: this.props.rowData.gradingQty,

            form: this.props.rowData,
          },
          function () {}
        );
      }
    }
  }

  fnClickSaveBtn = () => {
    let that = this;
    let data = this.state.form;

    data.iuFlag = 'I';
    data.dateFormat = SessionUtil.getDateFormat();

    if (!data.discardQtyInput || data.discardQtyInput === '0') {
      that.props.alert.show(
        i18n.t('Please enter Discard Qty.') // 폐기 수량을 입력하세요
      );
      that.discardQtyInput.focus();
      return false;
    }

    if (data.discardQtyInput > data.hatchingEggQty) {
      that.props.alert.show(
        i18n.t('The number of possible discard quantity has been exceeded.')
      ); // 폐기 가능한 수량을 초과하였습니다
      that.discardQtyInput.focus();
      return false;
    }

    if (!data.discardRem) {
      that.props.alert.show(
        i18n.t('Please enter Discard Rem.') // 폐기 이유를 입력하세요
      );
      that.discardRem.focus();
      return false;
    }

    if (!data.discardDate) {
      data.discardDate = this.state.discardDate;
    }

    if (data.discardDate < this.state.form.gradingDate) {
      that.props.alert.show(
        i18n.t('Please chechk Discard Date.') // 폐기 날짜를 확인하세요
      );
      return false;
    }

    data.hatchingEggQty =
      Number(this.state.form.hatchingEggQty) -
      Number(this.state.form.discardQtyInput);

    data.noneSettingQty =
      Number(this.state.form.noneSettingQty) -
      Number(this.state.form.discardQtyInput);

    console.log('data', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/grade/saveGradingDiscard.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.alert.show(i18n.t('Discarded')); // 폐기 완료
        that.props.fnSetRegDate();
        that.fnClear();
      })
      .catch(function (error) {
        that.props.alert.show(i18n.t('Discard failed')); // 폐기 실패
        return [];
      });
  };

  fnClear = (e) => {
    if (this.props.iuFlag === 'I') {
      this.setState(
        {
          form: { hatchingEggQty: this.props.rowData.hatchingEggQty },
        },
        function () {}
      );
    }
    if (this.props.iuFlag === 'U') {
      this.setState(
        {
          form: this.props.rowData,
        },
        function () {}
      );
    }
  };

  setOnChange = (e, params) => {
    const col = e.target.name ? e.target.name : '';
    let val = e.target.value;
    let tot = 0;

    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
      if (params.minVal) {
        if (Number(val) < Number(params.minVal)) {
          val = 0;
        }
      }
    }

    this.setState({
      form: {
        ...this.state.form,
        [col]: col,
        [col]: val,
      },
    });
  };

  fnClickCancel = (e) => {
    this.props.fnClickCancel();
    this.fnClear();
  };

  onDateChange = (date, dateString, name) => {
    let that = this;

    if (this.props.rowData.receiveDate <= dateString) {
      this.setState(
        { form: { ...this.state.form, [name]: dateString } },
        function () {}
      );
    } else {
      that.props.alert.show(i18n.t('Please Check Grade Date.'));
    }
  };

  disabledDate = () => {
    var date = new Date(this.props.rowData.receiveDate);
    return date < moment().endOf('day');
  };

  render() {
    if (!this.props.gradeDiscardFormView) return null;
    let iuFlag = this.props.iuFlag;
    return (
      <Fragment>
        {/* 디자인 영역 - setting 메뉴 레이어 팝업 */}

        <div className="con_box">
          <h3>
            <Trans>Hatching Egg Discard</Trans>
          </h3>

          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th style={{ height: '45px' }}>
                    <Trans>Hatching Egg Qty</Trans>
                  </th>
                  <td>
                    <span>
                      {this.state.form.discardQtyInput == undefined
                        ? this.state.form.hatchingEggQty
                        : Number(this.state.form.hatchingEggQty) -
                          Number(this.state.form.discardQtyInput)}
                    </span>
                  </td>
                  <th>
                    <Trans>Grade Date</Trans>
                  </th>
                  <td>
                    <span>{this.state.form.gradingDate}</span>
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Discard Qty</Trans>
                    <span className="red">*</span>{' '}
                  </th>
                  {this.props.iuFlag == 'I' ? (
                    <td>
                      {GridUtil.renderInput({
                        inputName: 'discardQtyInput', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        minVal: 0,
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                      })}
                    </td>
                  ) : (
                    <td>{this.state.form.discardQtyInput}</td>
                  )}

                  <th>
                    <Trans>Discard Date</Trans>
                    <span className="red">*</span>{' '}
                  </th>
                  <td>
                    <DatePicker
                      name="discardDate"
                      style={{ width: 120 }}
                      value={
                        this.state.form.discardDate
                          ? moment(this.state.form.discardDate)
                          : moment(DateUtil.today())
                      }
                      onChange={(date, dateString) =>
                        this.onDateChange(date, dateString, 'discardDate')
                      }
                      allowClear={false}
                    />
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Remarks</Trans>
                    <span className="red">*</span>{' '}
                  </th>
                  <td colSpan="4">
                    {GridUtil.renderInput({
                      inputName: 'discardRem', //*** */
                      type: '', //*** */
                      //dec: 2,
                      maxLength: 1000,
                      //maxVal: 999.99,
                      //width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p style={{ color: 'red' }}>
            <span>*</span>
            <Trans>Hatching Egg Qty</Trans> = <Trans>Hatching Egg Qty</Trans> -
            <Trans> Cutting Egg Qty</Trans>
          </p>

          <div className="txt_center mT40">
            <button className="btn_gray" onClick={(e) => this.fnClickCancel()}>
              <Trans>Cancel</Trans>
            </button>
            {this.props.iuFlag === 'I' ? (
              <button
                className="btn_save"
                onClick={(e) => this.fnClickSaveBtn(e)}
              >
                <Trans>Save</Trans>
              </button>
            ) : null}
          </div>
        </div>
        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default withAlert()(GradeDiscardForm);
