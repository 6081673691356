import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, FormUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import {
  AgGridReact,
  AllModules,
  isRowSelected
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import produce from 'immer';
import { message } from 'antd';
import GroupRegist from './GroupRegist';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class GroupInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      regDate: DateUtil.toDateTimeString(),
      rowNode: {}, // child에 전달할 데이타
      rowData: [],
      // params: { groupId: undefined, groupNm: undefined }, // 등록시 전달할 데이타
      // editing: false, // 등록 Box True:Show / False:Hide
      gridHeight: '365px',
      modules: AllModules,
      columnDefs: [
        {
          headerName: i18n.t('Id'),
          field: 'userGroupId',
          editable: false,
          getQuickFilterText: function(params) {
            return params.value;
          }
        },
        {
          headerName: i18n.t('Name'),
          field: 'userGroupName'
          // cellStyle: function(params) {
          //   const w = params.column.actualWidth;
          //   return FormUtil.getEditCell('E', w);
          // }
          //
          // =======================================================
          //  (중요) constructor에서 로직 하는건 좋지않아요.
          // =======================================================
          // valueSetter: async function(params) {
          //   let { data, newValue } = params;
          //   let param = { groupId: '', groupNm: '' };
          //   param['groupId'] = data['userGroupId'];
          //   param['groupNm'] = newValue;
          //   // const response = await that.fnUpdateRowData(param);
          //   // if (response.data > 0) {
          //   // that.fnGetRowData();
          //   data.userGroupName = newValue;
          //   return true;
          //   // }
          //   // return true;
          // }
        },
        {
          headerName: i18n.t('Update Time'),
          field: 'updateTime'
        }
      ],
      defaultColDef: {
        filter: true,
        resizable: true,
        editable: false,
        sortable: true
        // suppressSizeToFit: true
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      paginationPageSize: 10
      // editType: 'fullRow',
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnGetRowData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.regDate !== this.state.regDate) {
      this.fnGetRowData();
    }
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  fnGetRowData = params => {
    const that = this;
    let url = Server.getRestAPI() + '/users/selectGroup.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        // console.log('[response.data]:', response.data);
        const { data } = response;
        if (data) {
          // console.log('data:', data, 'data.length:', data.length);
          that.setState({
            rowNode: {},
            rowData: data
          });
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  fnDeleteRowData = params => {
    const url = Server.getRestAPI() + '/users/deleteGroup.do';

    // ---------------------------------------------------------
    // (중요) await와 then 은 함께 사용해서는 안됩니다.
    // ---------------------------------------------------------
    return axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    });
  };

  fnSetRegDate = () => {
    this.setState({
      regDate: DateUtil.toDateTimeString()
    });
  };

  onBtDelete = () => {
    const that = this;
    const selectedRows = this.gridApi.getSelectedNodes();
    if (!selectedRows || selectedRows.length === 0) {
      return message.error(i18n.t('Not selected row'));
    }

    const selectedRow = selectedRows[0];
    const { data } = selectedRow;

    let param = {};
    param['groupId'] = data['userGroupId'];
    param['groupNm'] = data['userGroupName'];

    this.fnDeleteRowData(param)
      .then(function(response) {
        const { data } = response;
        if (data > 0) {
          // console.log('갱신을 하시오');
          that.fnGetRowData();
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  onFilterTextBoxChanged = e => {
    const { value } = e.target;
    this.gridApi.setQuickFilter(value);
  };

  onRowSelected = params => {
    const that = this;
    if (!isRowSelected(params)) return;
    this.setState(
      produce(draft => {
        draft.rowNode = params.data;
      }),
      function() {}
    );
  };

  // ------------------------------------------
  // 강제로 선택된 AgGrid의 Row선택을 해제한다.
  // ------------------------------------------
  onRowDeselect = () => {
    this.gridApi.deselectAll();
    this.setState({ rowNode: {} }, function() {});
  };

  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Member - Group</Trans>
        </h2>

        <div className="con_box">
          <div className="f_left mB20">
            <input
              type="text"
              className="input_type1"
              placeholder={i18n.t('Filter')}
              onChange={e => this.onFilterTextBoxChanged(e)}
            />
          </div>
          <div className="f_right mB20">
            <button className="btn_gray" onClick={this.onBtDelete}>
              <Trans>Delete</Trans>
            </button>
          </div>

          {/* ================================= */}
          <div className="board_list_s">
            <div
              id="myGrid"
              style={{
                height: this.state.gridHeight,
                width: '100%'
              }}
              className="ag-theme-balham"
            >
              <AgGridReact
                modules={this.state.modules}
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                rowData={this.state.rowData}
                rowSelection={this.state.rowSelection}
                // rowModelType={this.state.rowModelType}
                onGridReady={this.onGridReady}
                onCellValueChanged={this.onCellValueChanged}
                editType={this.state.editType}
                // components={this.state.components}
                // frameworkComponents={this.state.frameworkComponents}
                // enterMovesDownAfterEdit={true}
                // enterMovesDown={true}
                // rowModelType={this.state.rowModelType}
                // onCellEditingStarted={this.onCellEditingStarted}
                // onCellEditingStopped={this.onCellEditingStopped}
                // onRowClicked={this.onRowClicked}
                onRowSelected={this.onRowSelected}
                pagination={true}
                paginationPageSize={this.state.paginationPageSize}
              />
            </div>
          </div>
          {/* ================================= */}
        </div>

        <GroupRegist
          rowNode={this.state.rowNode}
          onRowDeselect={this.onRowDeselect}
          regDate={this.state.regDate}
          fnSetRegDate={this.fnSetRegDate}
        ></GroupRegist>
      </Fragment>
    );
  }
}
