import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, PagingUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import { Input, Radio } from 'antd';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';

import MaterialIORegist from './MaterialIORegist';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class MaterialIOInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      regDate: DateUtil.toDateTimeString(),
      rowNode: {},
      rowData: [],
      params: {
        farmCode: SessionUtil.getFarmCode(),
        dateFormat: SessionUtil.getDateFormat(),
        pageNo: 1,
        pageSize: 10,
        total: 0,
        rowCnt: 0,
      },
      ColDefs: [
        {
          headerName: i18n.t('Farm Code'),
          field: 'farmCode',
          hide: true,
        },
        {
          headerName: i18n.t('Material Code'),
          field: 'materialCode',
        },
        {
          headerName: i18n.t('Material Name'),
          field: 'materialName',
        },
        {
          headerName: i18n.t('Material Type'),
          field: 'materialType',
          hide: true,
        },
        {
          headerName: i18n.t('Material Type'),
          field: 'materialTypeName',
        },
        {
          headerName: i18n.t('Project Code'),
          field: 'projCode',
          hide: true,
        },
        {
          headerName: i18n.t('Project'),
          field: 'projInfo',
        },
        {
          headerName: i18n.t('Stock Seq'),
          field: 'stockSeq',
          hide: true,
        },
        {
          headerName: i18n.t('Invoice No'),
          field: 'invoiceNo',
          hide: true,
        },
        {
          headerName: i18n.t('IO Flag'),
          field: 'ioFlag',
          hide: true,
        },
        {
          headerName: i18n.t('In/Out'),
          field: 'ioFlagName',
        },
        {
          headerName: i18n.t('Qty'),
          field: 'qty',
        },
        {
          headerName: i18n.t('Unit'),
          field: 'unit',
        },
        {
          headerName: i18n.t('Size'),
          field: 'mSize',
          hide: true,
        },
        {
          headerName: i18n.t('Spec'),
          field: 'spec',
          hide: true,
        },
        {
          headerName: i18n.t('Stock Date'),
          field: 'stockDate',
        },
        {
          headerName: i18n.t('Customer Code'),
          field: 'customerCode',
        },
        {
          headerName: i18n.t('Customer Name'),
          field: 'customerName',
        },
      ],
      gridHeight: '365px',
      defaultColDef: {
        filter: true,
        resizable: true,
        editable: false,
        sortable: true,
        suppressSizeToFit: true,
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagination: false,
      paginationPageSize: 10,
      // editType: 'fullRow', // selectEditor와 함께 사용하지 말라고 하네요...
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnGetRowData(this.state.params);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.regDate !== this.state.regDate) {
      this.fnGetRowData(this.state.params);
    }
  }

  fnSetRegDate = () => {
    this.setState({
      regDate: DateUtil.toDateTimeString(),
    });
  };

  fnGetRowData = (params) => {
    const that = this;
    let url = Server.getRestAPI() + '/basis/selectTcMaterialIO.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowNode: {}, rowData: data });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  onFilterTextBoxChanged = (e) => {
    const { value } = e.target;
    this.gridApi.setQuickFilter(value);
  };

  onRowSelected = (params) => {
    const that = this;
    if (!isRowSelected(params)) return;
    this.setState(
      produce((draft) => {
        draft.rowNode = params.data;
        draft.rowNode.dateFormat = SessionUtil.getDateFormat();
      }),
      function () {
        console.log('rowNode:', that.state.rowNode);
      }
    );
  };

  // ------------------------------------------
  // 강제로 선택된 AgGrid의 Row선택을 해제한다.
  // ------------------------------------------
  onRowDeselect = () => {
    this.gridApi.deselectAll();
    this.setState({ rowNode: {} }, function () {});
  };

  onClick = (pageNo) => {
    const that = this;
    console.log('pageNo:', pageNo);
    this.setState(
      produce((draft) => {
        draft.params['pageNo'] = pageNo;
      }),
      function () {
        const { params } = this.state;
        this.fnGetRowData(params);
      }
    );
  };

  onSearch = (value) => {
    this.setState(
      produce((draft) => {
        draft.visable = false;
        draft.params['pageNo'] = 1;
        draft.params['searchKey'] = value;
      }),
      function () {
        const { params } = this.state;
        this.onRowDeselect();
        this.fnGetRowData(params);
      }
    );
  };

  render() {
    // return null 은 아무것도 표시하지 않는다는 의미입니다.
    // if (!this.props.localeListView) return null;
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Materials - Receive</Trans>
        </h2>

        <div className="con_box">
          <div className="f_left mB20">
            {/*
            <input
              type="text"
              className="input_type1"
              placeholder={i18n.t('Filter')}
              onChange={e => this.onFilterTextBoxChanged(e)}
            />
          */}
            <Input.Search
              name="searchKey"
              placeholder={i18n.t('Search')}
              onSearch={this.onSearch}
            />
          </div>
          <div className="f_right mB20">
            {/*
              입고 자재에 대한 삭제에 대한 운영 규칙이 정의되지 않았습니다.
              운영으로 풀어야 합니다. by 장태학. 2020.02.05

            <button className="btn_gray" onClick={this.onBtDelete}>
              <Trans>Delete</Trans>
            </button>
            */}
          </div>
          <div className="board_list_s">
            <div
              id="myGrid"
              style={{
                height: this.state.gridHeight,
                width: '100%',
              }}
              className="ag-theme-balham"
            >
              <AgGridReact
                modules={this.state.modules}
                columnDefs={this.state.ColDefs}
                // components={this.state.components}
                // frameworkComponents={this.state.frameworkComponents}
                defaultColDef={this.state.defaultColDef}
                rowSelection={this.state.rowSelection}
                editType={this.state.editType}
                // enterMovesDownAfterEdit={true}
                // enterMovesDown={true}
                // rowModelType={this.state.rowModelType}

                onGridReady={this.onGridReady}
                onCellEditingStarted={this.onCellEditingStarted}
                onCellEditingStopped={this.onCellEditingStopped}
                onRowClicked={this.onRowSelected}
                onRowSelected={this.onRowSelected}
                onRowEditingStarted={this.onRowEditingStarted}
                onRowEditingStopped={this.onRowEditingStopped}
                onCellValueChanged={this.onCellValueChanged}
                rowData={this.state.rowData}
                pagination={this.state.pagination}
                paginationPageSize={this.state.paginationPageSize}
              />
              <PagingUtil
                total={this.state.params.total}
                rowCnt={this.state.rowData.length}
                pageSize={this.state.params.pageSize}
                pageNo={this.state.params.pageNo}
                onClick={this.onClick}
              />
            </div>
          </div>
        </div>

        <MaterialIORegist
          rowNode={this.state.rowNode}
          onRowDeselect={this.onRowDeselect}
          regDate={this.state.regDate}
          fnSetRegDate={this.fnSetRegDate}
        ></MaterialIORegist>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
