import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, FormUtil, DateUtil, PagingUtil } from 'utils';
import { Trans } from 'react-i18next';
import { withAlert } from 'react-alert';

import { moment } from 'components';

import i18n from 'i18next';

// import PasswordEditor from './PasswordEditor';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import produce from 'immer';
import { Input, Select } from 'antd';
import { cloneDeep, remove } from 'lodash';

import UserRegist from './UserRegist';
import CountryCode from 'containers/pages/common/system/CountryCode';
import SearchInput from 'utils/SearchInput';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 **
 ** CellEditing 기능 : https://www.ag-grid.com/javascript-grid-cell-editing
 */
class CustomCellRenderer extends React.PureComponent {
  render() {
    const value = this.props.value;
    return <React.Fragment>{value}</React.Fragment>;
  }
}

class UserInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      regDate: DateUtil.toDateTimeString(),
      rowNode: {}, // child에 전달할 데이타
      rowData: [], // 전체 목록
      params: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        rowCnt: 0,
      },
      modules: AllModules,
      gridHeight: '365px',
      frameworkComponents: {
        customCellRenderer: CustomCellRenderer,
        // , passwordEditor: PasswordEditor
      },
      components: {
        fnMaskedPassword: this.fnMaskedPassword,
      },
      columnDefs: [
        {
          headerName: i18n.t('User Id'),
          field: 'userId',
          getQuickFilterText: function (params) {
            return params.value;
          },
        },
        {
          headerName: i18n.t('User Name'),
          field: 'userNm',
          // editable: true,
          // cellStyle: function(params) {
          //   const w = params.column.actualWidth;
          //   return FormUtil.getEditCell('E', w);
          // }
        },
        {
          headerName: i18n.t('Country Code'),
          field: 'countryCode',
          hide: true,
        },
        {
          headerName: i18n.t('Country'),
          field: 'countryCodeName',
        },
        {
          headerName: i18n.t('Group Id'),
          field: 'groupId',
          hide: true,
        },
        {
          headerName: i18n.t('Group'),
          field: 'groupNm',
        },
        {
          headerName: i18n.t('User Level'),
          field: 'userLevel',
          hide: true,
        },
        {
          headerName: i18n.t('User Level'),
          field: 'userLevelName',
        },
        {
          headerName: i18n.t('Farm Code'),
          field: 'farmCode',
          hide: true,
        },
        {
          headerName: i18n.t('Farm'),
          field: 'farmInfo',
        },
        {
          headerName: i18n.t('Hatchery Id'),
          field: 'hatcheryId',
          hide: true,
        },
        {
          headerName: i18n.t('Hatchery'),
          field: 'hatcheryInfo',
        },
        {
          headerName: i18n.t('New Password'),
          field: 'userNewPw',
          hide: true,
        },
        {
          headerName: i18n.t('Division'),
          field: 'division',
          hide: true,
        },
        {
          headerName: i18n.t('Division'),
          field: 'divisionInfo',
        },
        {
          headerName: i18n.t('Region'),
          field: 'region',
          hide: true,
        },
        {
          headerName: i18n.t('Region'),
          field: 'regionInfo',
        },
        {
          headerName: i18n.t('Sub Region'),
          field: 'subRegion',
          hide: true,
        },
        {
          headerName: i18n.t('Sub Region'),
          field: 'subRegionInfo',
        },
        {
          headerName: i18n.t('PW Changed'),
          field: 'pwChanged',
        },
        {
          headerName: i18n.t('Disabled'),
          field: 'disabled',
        },
        {
          headerName: i18n.t('Blocked'),
          field: 'blocked',
        },
        {
          headerName: i18n.t('Deleted'),
          field: 'deleted',
        },
      ],
      defaultColDef: {
        filter: true,
        resizable: true,
        editable: false,
        sortable: true,
        suppressSizeToFit: true,
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagination: false,
      paginationPageSize: 10,
      // editType: 'fullRow', // selectEditor와 함께 사용하지 말라고 하네요...
    };
  }

  onGridReady = (params) => {
    // console.log('onGridReady:', params);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    // this.fnGetGroupRowData();
    // this.fnGetFarmCodeRowData(); // 대량의 데이타로 성능 이슈 있음.
    const { params } = this.state;
    this.fnGetRowData(params);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.regDate !== this.state.regDate) {
      const { params } = this.state;
      this.fnGetRowData(params);
    }
  }

  // 사용자 정보 가져오기
  fnGetRowData = (params) => {
    const that = this;
    let url = Server.getRestAPI() + '/users/selectMember.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        // console.log('[response.data]:', response.data);
        const { data } = response;
        if (data) {
          // console.log('data:', data, 'data.length:', data.length);
          that.setState(
            produce((draft) => {
              draft.rowNode = {};
              draft.rowData = data;
              draft.params['rowCnt'] = data.length;
              draft.params['total'] = data.length > 0 ? data[0].total : 0;
            }),
            function () {
              console.log(this.state);
            }
          );
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  fnDeleteRowData = (params) => {
    const url = Server.getRestAPI() + '/users/deleteMember.do';

    // ---------------------------------------------------------
    // (중요) await와 then 은 함께 사용해서는 안됩니다.
    // ---------------------------------------------------------
    return axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    });
  };

  fnSetRegDate = () => {
    this.setState({
      regDate: DateUtil.toDateTimeString(),
    });
  };

  onBtDelete = () => {
    const that = this;
    const selectedRows = this.gridApi.getSelectedNodes();
    if (!selectedRows || selectedRows.length === 0) {
      return this.props.alert.error(i18n.t('Not selected row'));
    }

    const selectedRow = selectedRows[0];
    const { data } = selectedRow;

    let params = {};
    params['userId'] = data['userId'];

    this.fnDeleteRowData(params)
      .then(function (response) {
        const { data } = response;
        console.log(data);
        if (Number(data) > 0) {
          // console.log('갱신을 하시오');
          that.fnGetRowData();
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  // 저장
  onFilterTextBoxChanged = (e) => {
    const { value } = e.target;
    this.gridApi.setQuickFilter(value);
  };

  onRowSelected = (params) => {
    const that = this;
    if (!isRowSelected(params)) return;
    this.setState(
      produce((draft) => {
        draft.rowNode = params.data;
      }),
      function () {}
    );
  };

  // ------------------------------------------
  // 강제로 선택된 AgGrid의 Row선택을 해제한다.
  // ------------------------------------------
  onRowDeselect = () => {
    this.gridApi.deselectAll();
    this.setState({ rowNode: {} }, function () {});
  };

  onClick = (pageNo) => {
    const that = this;
    console.log('pageNo:', pageNo);
    this.setState(
      produce((draft) => {
        draft.params['pageNo'] = pageNo;
      }),
      function () {
        const { params } = this.state;
        this.fnGetRowData(params);
      }
    );
  };

  onSearch = (value) => {
    this.setState(
      produce((draft) => {
        draft.visable = false;
        draft.params['pageNo'] = 1;
        draft.params['searchKey'] = value;
      }),
      function () {
        const { params } = this.state;
        this.onRowDeselect();
        this.fnGetRowData(params);
      }
    );
  };

  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Member - User</Trans>
        </h2>

        <div className="con_box">
          <div className="f_left mB20">
            {/*
            <input
              type="text"
              className="input_type1"
              placeholder={i18n.t('Filter')}
              onChange={e => this.onFilterTextBoxChanged(e)}
            />
          */}
            <Input.Search
              name="searchKey"
              placeholder={i18n.t('Search')}
              onSearch={this.onSearch}
            />
          </div>

          <div className="board_list_s">
            <div
              id="myGrid"
              style={{
                height: this.state.gridHeight,
                width: '100%',
              }}
              className="ag-theme-balham"
            >
              <AgGridReact
                modules={this.state.modules}
                columnDefs={this.state.columnDefs}
                components={this.state.components}
                frameworkComponents={this.state.frameworkComponents}
                defaultColDef={this.state.defaultColDef}
                rowSelection={this.state.rowSelection}
                editType={this.state.editType}
                // enterMovesDownAfterEdit={true}
                // enterMovesDown={true}
                // rowModelType={this.state.rowModelType}

                onGridReady={this.onGridReady}
                onCellEditingStarted={this.onCellEditingStarted}
                onCellEditingStopped={this.onCellEditingStopped}
                onRowClicked={this.onRowClicked}
                onRowSelected={this.onRowSelected}
                onRowEditingStarted={this.onRowEditingStarted}
                onRowEditingStopped={this.onRowEditingStopped}
                onCellValueChanged={this.onCellValueChanged}
                rowData={this.state.rowData}
                pagination={this.state.pagination}
                paginationPageSize={this.state.paginationPageSize}
              />
              <PagingUtil
                total={this.state.params.total}
                rowCnt={this.state.rowData.length}
                pageSize={this.state.params.pageSize}
                pageNo={this.state.params.pageNo}
                onClick={this.onClick}
              />
            </div>
          </div>
        </div>

        {/* UserInfo 생성/수정 */}
        <UserRegist
          rowNode={this.state.rowNode}
          onRowDeselect={this.onRowDeselect}
          regDate={this.state.regDate}
          fnSetRegDate={this.fnSetRegDate}
        ></UserRegist>
        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default withAlert()(UserInfo);
