import React, { Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { Calendar, Popover } from 'antd';
import i18n from 'i18next';
import GrowingForm from './GrowingForm';
import { withAlert } from 'react-alert';
import scrollToComponent from 'react-scroll-to-component';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
  GridOptions,
} from 'components/commons/ag-grid/AgGridUtil';
import { Select } from 'antd';
import '../Breeding.module.css';

/*
 ** Broiler > Glowing Log
 ** =======================
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class GrowLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInstance: this,
      workDate: DateUtil.today(),
      form: {
        searchFromWkDt: moment(DateUtil.today())
          .add('-1', 'M')
          .format('YYYY-MM-DD'),
        searchToWkDt: DateUtil.today(),
        projStatus: 'A',
      },
      prop: {},
      rowData: [],
      flockList: [],
      projectList: [],
      breedLogList: [],
      growingForm: [],
      growingView: false,
      openDatePicker: false,
      growingData: {},
      columnDefsProjectInfo: [
        {
          headerName: 'Flock',
          field: 'flockId',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Hen House',
          field: 'hhId',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Strain',
          field: 'strainName',
          width: 250,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Age of Day',
          field: 'days',
          width: 90,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Week',
          field: 'weeks',
          width: 100,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Last Work Date',
          field: 'lastWorkDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Current(F)',
          field: 'flockInQtyF',
          width: 90,
          //type: 'numericColumn',
          valueFormatter: function (params) {
            return params.data.flockInQtyF.toLocaleString();
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Current(M)',
          field: 'flockInQtyM',
          width: 90,
          //type: 'numericColumn',
          valueFormatter: function (params) {
            return params.data.flockInQtyM.toLocaleString();
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
      ],
      columnDefsBreedLog: [
        {
          headerName: '',
          field: 'iuFlag',
          width: 110,
          cellStyle: { textAlign: 'center' },
          cellRenderer: (params) => {
            if (
              this.state.flockStatus === 'A' ||
              this.state.flockStatus === 'S'
            ) {
              if (params.data.iuFlag === 'I')
                return (
                  '<button class="btn_sel">' +
                  i18n.t('Registration') +
                  '</button>'
                );
              else if (params.data.iuFlag === 'U')
                return (
                  '<button class="btn_sel_green">' +
                  i18n.t('Modify') +
                  '</button>'
                );
              else return null;
            }
          },

          suppressSizeToFit: true,
        },
        {
          headerName: 'Work Date',
          field: 'wdate',
          width: 110,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Age',
          field: 'ageDay',
          width: 50,
          cellStyle: { textAlign: 'right' },
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'Culling(F)',
          field: 'cullingQtyF',
          width: 80,
          cellStyle: { textAlign: 'right' },
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'Culling(M)',
          field: 'cullingQtyM',
          width: 80,
          cellStyle: { textAlign: 'right' },
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'Death(F)',
          field: 'deathQtyF',
          width: 80,
          cellStyle: { textAlign: 'right' },
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'Death(M)',
          field: 'deathQtyM',
          width: 80,
          cellStyle: { textAlign: 'right' },
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'SE(F)',
          field: 'seF',
          width: 80,
          cellStyle: { textAlign: 'right' },
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'SE(M)',
          field: 'seM',
          width: 80,
          cellStyle: { textAlign: 'right' },
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'Move In',
          field: 'moveQtyIn',
          width: 80,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Move Out',
          field: 'moveQtyOut',
          width: 80,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Population(F)',
          field: 'populationF',
          width: 100,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Population(M)',
          field: 'populationM',
          width: 100,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Feed(kg)',
          field: 'feed',
          width: 100,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
      ],
      defaultColDef: {
        editable: false,
        width: 100,
        filter: true,
        resizable: true,
        sortable: true,
        suppressSizeToFit: false,
      },
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.setState(
      {
        searchFromWkDt: moment(DateUtil.today())
          .add('-1', 'M')
          .format('YYYY-MM-DD'),
        searchToWkDt: DateUtil.today(),
      },
      function () {
        this.fnSearch();
      }
    );
  }

  fnSearch = () => {
    let data = this.state.form;
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.workDate = this.state.workDate;

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/growing/selectGlowingLogList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            hhChickinList: response.data.rows1,
            flockList: response.data.rows2,
            growingView: false,
            growingForm: [],
          },
          function () {
            if (response.data.rows2.length > 0) {
              let rowIndexG1 = 0;
              if (
                that.state.hhChickinList &&
                that.state.hhChickinList.length > 0
              ) {
                //rowIndexG1 = that.state.rowIndexG1 ? that.state.rowIndexG1 : 0; // 무조건 첫번째 선택을 위해 0 으로 셋팅
                let selectedRows = that['g1'].getRowNode(rowIndexG1);
                selectedRows.setSelected(true);

                that.setState(
                  {
                    rowIndexG1: rowIndexG1,
                    hhId: selectedRows ? selectedRows.data.hhId : '',
                    flockId: selectedRows ? selectedRows.data.flockId : '',
                    chickinDate: selectedRows
                      ? selectedRows.data.chickinDate
                      : '',
                    days: selectedRows ? selectedRows.data.days : 0,
                    flockStatus: selectedRows
                      ? selectedRows.data.flockStatus
                      : 0,
                  },
                  function () {
                    that.fnSearchDtl(selectedRows.data);
                  }
                );
              } else {
              }
            }
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  fnClickDate = (e, val) => {
    this.setState(
      {
        workDate: DateUtil.toFormat(
          moment(this.state.workDate).add(val, 'days')
        ),
      },
      function () {
        this.fnSearch();
      }
    );
  };
  //Write button Click
  fnClickWrite = (row) => {
    let that = this;

    let data = Object.assign({}, row);

    row.iuFlag = 'I';
    data.iuFlag = 'I';
    data.workDate = this.state.workDate;
    this.setState(
      {
        growingView: true,
        growingForm: data,
      },
      function () {
        scrollToComponent(this['growingForm'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };

  fnClickCancel = (val) => {
    this.setState({ [val]: false }, function () {});
  };
  //Modify button Click
  fnClickModify = (row) => {
    let data = Object.assign({}, row);
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/growing/selectTpBreedLog3.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        response.data.rows1.map((row, i) => {
          if (row.seqNo) {
            data['seqNo' + (i + 1)] = row.seqNo;
            data['feedCode' + (i + 1)] = row.feedCode;
            data['feedName' + (i + 1)] = row.materialName;
            data['feedUnit' + (i + 1)] = row.unit;
            data['feedUseAmtF' + (i + 1)] = row.useAmtF;
            data['feedUseAmtM' + (i + 1)] = row.useAmtM;
            data['feedView' + (i + 1)] = true;
          }
        });
        response.data.rows2.map((row, i) => {
          if (row.seqNo) {
            data['seqNo' + (i + 1)] = row.seqNo;
            data['medicineCode' + (i + 1)] = row.medicineCode;
            data['medicineName' + (i + 1)] = row.materialName;
            data['medicineUnit' + (i + 1)] = row.unit;
            data['medicineUseAmt' + (i + 1)] = row.useAmt;
            data['medicineView' + (i + 1)] = true;
          }
        });
        response.data.rows3.map((row, i) => {
          if (row.seqNo) {
            data['seqNo' + (i + 1)] = row.seqNo;
            data['vaccineCode' + (i + 1)] = row.vaccineCode;
            data['vaccineName' + (i + 1)] = row.materialName;
            data['vaccineUnit' + (i + 1)] = row.unit;
            data['vaccineUseAmt' + (i + 1)] = row.useAmt;
            data['vaccineView' + (i + 1)] = true;
          }
        });
        that.setState(
          {
            growingView: true,
            growingForm: data,
          },
          function () {
            scrollToComponent(this['growingForm'], {
              offset: 0,
              align: 'top',
              duration: 1000,
            });
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  fnClickCancel = (row) => {
    if (row.iuFlag === 'I') {
      row.workDate = '';
      row.iuFlag = '';
    } else if (row.iuFlag === 'U') {
      row.iuFlag = '';
    }
    this.setState(
      {
        growingView: false,
        growingData: row,
      },
      function () {}
    );
  };
  fnCalendarChange = (value) => {
    this.setState(
      { workDate: moment(value).format('YYYY-MM-DD') },
      function () {}
    );
  };
  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  //Grid row 선택
  onCellClickedGrading = async (e) => {
    if (e.column.colId !== 'iuFlag') return false;
    let that = this;
    const selectedRows = that['g2'].getRowNode(e.rowIndex);
    let data = Object.assign({}, e.data);

    data.stockF = data.populationF;
    data.stockM = data.populationM;
    data.chickinDate = this.state.chickinDate; //AgeDay 계산
    data.outQty = 0;

    this.setState({ growingView: false, growingForm: [] }, function () {
      if (data.iuFlag === 'U') {
        that.fnClickModify(data);
      } else if (
        (data.iuFlag === 'I' && data.chk === 1) ||
        e.data.wdate === that.state.chickinDate
      ) {
        data.iuFlag = 'I';
        data.workDate = data.wdate;
        data.flockId = this.state.flockId;
        data.flockStatus = this.state.flockStatus;
        data.hhId = this.state.hhId;
        data.days = this.state.days;
        data.bwF = data.prevBwF;
        data.bwM = data.prevBwM;
        data.uniformityF = data.prevUnfF;
        data.uniformityM = data.prevUnfM;
        that.setState(
          {
            growingView: true,
            growingForm: data,
          },
          function () {
            scrollToComponent(this['growingForm'], {
              offset: 0,
              align: 'top',
              duration: 1000,
            });
          }
        );
      } else {
        that.props.alert.show(
          i18n.t('Please enter previous growing log first.')
        );
      }
    });
  };
  onRowClicked = (e) => {
    let { data } = e;
    this.setState(
      {
        breedLogList: [],
        stock: 0,
        growingForm: [],
        growingView: false,
        flockId: data.flockId,
        flockStatus: data.flockStatus,
        chickinDate: data.chickinDate,
        days: data.days,
        rowIndexG1: e.rowIndex,
        hhId: data ? data.hhId : '',
      },
      function () {
        this.fnSearchDtl(data);
      }
    );
  };

  fnSearchDtl = async (data) => {
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.countryCode = SessionUtil.getCountryCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.userId = SessionUtil.getUserId();
    data.sql = 'selectTpBreedLog';

    if (data.chickinDate) {
      if (data.chickinDate < data.searchFromWkDt) {
        data.chickinDate = data.searchFromWkDt;
      } else {
        data.searchFromWkDt = data.chickinDate;
        await that.setState({
          form: { ...that.state.form, searchFromWkDt: data.chickinDate },
        });
      }
    }

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/growing/selectSql.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            breedLogList: response.data.rows1,
            growingForm: [],
            growingView: false,
          },
          function () {
            console.log('dddd', response.data.rows1);

            const fromDay = '-6';
            let toDate = DateUtil.today();
            let fromDate = moment(DateUtil.today())
              .add(fromDay, 'days')
              .format('YYYY-MM-DD');
            let population = Number(data.population ? data.population : 0);
            let populationF = Number(data.populationF ? data.populationF : 0);
            let populationM = Number(data.populationM ? data.populationM : 0);
            let closeQtyF = 0; //prev
            let closeQtyM = 0; //prev
            let deathQtyF = 0; //prev
            let deathQtyM = 0; //prev
            let cullingQtyF = 0; //prev
            let cullingQtyM = 0; //prev
            let moveQtyF = 0; //prev
            let moveQtyM = 0; //prev
            that.state.breedLogList.map(function (row, i) {
              row.prevPop = population; //전일재고

              if (!row.workDate) {
                if (row.wdate >= fromDate && row.wdate <= toDate) {
                  row.iuFlag = 'I';
                }
                if (Const.TEST_MODE) row.iuFlag = 'I'; // 테스트 및 검수를 위해 입력날짜 제한을 해제 합니다.
              } else if (row.workDate) {
                if (row.wdate >= fromDate && row.wdate <= toDate) {
                  row.iuFlag = 'U';
                }
                if (Const.TEST_MODE) row.iuFlag = 'U'; // 테스트 및 검수를 위해 입력날짜 제한을 해제 합니다.
              }

              populationF = row.popF;
              populationM = row.popM;
              row.populationF = populationF;
              row.populationM = populationM;
              row.population = populationF + populationM;
              closeQtyF = Number(row.closeQtyF);
              closeQtyM = Number(row.closeQtyM);
              cullingQtyF = Number(row.cullingQtyF);
              cullingQtyM = Number(row.cullingQtyM);
              deathQtyF = Number(row.deathQtyF);
              deathQtyM = Number(row.deathQtyM);
              moveQtyF = Number(row.moveQtyF);
              moveQtyM = Number(row.moveQtyM);
            });
            var params = { force: true };
            that['g2'].refreshCells(params);
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  setOnChange = (e, params, dateString) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';

    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }
    this.setState({ form: { ...that.state.form, [col]: val } }, function () {});
  };
  render() {
    const Option = Select.Option;
    let options = [
      <Option key="o" value="O">
        {'O(생성)'}
      </Option>,
      <Option key="a" value="A">
        {'A(육성 단계)'}
      </Option>,
      <Option key="c" value="C">
        {'C(출고 완료)'}
      </Option>,
    ];

    return (
      <Fragment>
        <h2>
          <Trans>Growing Log</Trans>
        </h2>

        <div className="con_box">
          <h3>
            <Trans>Hen House List</Trans>
          </h3>
          <div className="board_list2">
            <div
              className="board_list ag-theme-balham"
              style={{ height: '220px', width: '100%' }}
            >
              <AgGridReact
                onGridReady={(e) => this.onGridReady(e, 'g1')}
                columnDefs={this.state.columnDefsProjectInfo}
                rowData={
                  this.state.hhChickinList ? this.state.hhChickinList : []
                }
                defaultColDef={this.state.defaultColDef}
                onRowClicked={(e) => this.onRowClicked(e)}
                rowSelection="single"
                onCellFocused={(e) => {
                  e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
                }}
              ></AgGridReact>
            </div>
          </div>

          <div
            className="mT20"
            style={{
              width: '100%',
            }}
          >
            <h3>
              <Trans>Growing Log List</Trans>
            </h3>
            <div className="f_right">
              {GridUtil.renderInput({
                inputName: 'searchFromWkDt', //*** */
                type: 'D', //*** */
                that: this, //*** */
                statsForm: this.state.form, //*** */
                setOnChange: this.setOnChange, //*** */
                width: 120, // px제외 : 없으면 100% */
                placeholder: 'Date',
              })}

              <button className="btn_gray" onClick={() => this.fnSearch()}>
                <Trans>Search</Trans>
              </button>
            </div>
            <h2
              style={{
                textAlign: 'center',
                width: '60%',
                position: 'absolute',
                left: '20%',
              }}
            >
              {this.state.hhId ? this.state.hhId : ''}
            </h2>
          </div>

          <div className="board_list">
            <div
              className="board_list ag-theme-balham"
              style={{ height: '300px', width: '100%' }}
            >
              <AgGridReact
                onGridReady={(e) => this.onGridReady(e, 'g2')}
                columnDefs={this.state.columnDefsBreedLog}
                rowData={this.state.breedLogList ? this.state.breedLogList : []}
                defaultColDef={this.state.defaultColDef}
                onCellClicked={(e) => this.onCellClickedGrading(e, 'g2')}
                rowSelection="single"
                onCellFocused={(e) => {
                  e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
                }}
              ></AgGridReact>
            </div>
            {this.state.growingView ? (
              <GrowingForm
                ref={(section) => {
                  this.growingForm = section;
                }}
                growingView={this.state.growingView} //Hen House view 여부
                growingForm={this.state.growingForm}
                fnClickCancel={this.fnClickCancel}
                fnSearch={this.fnSearch}
              />
            ) : null}
          </div>
        </div>
        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default withAlert()(GrowLog);
