import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import FHDateRangeSearch from '../utils/FHDateRangeSearch';
import ReportUtil from '../utils/ReportUtil';

import '../Report.module.css';

// 2. 육성기 주령별 보고서
export default class GrwWeeklyR extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      excelName: 'Grow Weekly Report',
      rowData: [],
      params: {},
      ColDefs: [
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('End Weeks'),
              children: [
                {
                  headerName: i18n.t('Week'),
                  field: 'weekOfLastDay',
                  pinned: 'left',
                  width: 160,
                  minWidth: 160,
                },
              ],
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('Age in Weeks'),
              children: [
                {
                  headerName: i18n.t('Age'),
                  field: 'ageWeek',
                  pinned: 'left',
                  width: 140,
                  minWidth: 140,
                },
              ],
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('No. of birds at End Weeks'),
              children: [
                {
                  headerName: i18n.t('Female'),
                  field: 'popsFemale',
                  width: 160,
                  minWidth: 160,
                },
                {
                  headerName: i18n.t('Male'),
                  field: 'popsMale',
                },
                {
                  headerName: i18n.t('Ratio'),
                  field: 'popsRatio',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Depletion'),
          children: [
            {
              headerName: i18n.t('Female'),
              children: [
                {
                  headerName: i18n.t('Death'),
                  field: 'dfDeath',
                },
                {
                  headerName: i18n.t('Cull'),
                  field: 'dfCull',
                },
                {
                  headerName: i18n.t('%'),
                  field: 'dfRatio',
                },
                {
                  headerName: i18n.t('Cumm'),
                  field: 'dfCumm',
                },
                {
                  headerName: i18n.t('SE'),
                  field: 'dfSe',
                },
                {
                  headerName: i18n.t('%'),
                  field: 'dfsRatio',
                },
                {
                  headerName: i18n.t('Cumm SE'),
                  field: 'dfCummSE',
                },
              ],
            },
            {
              headerName: i18n.t('Male'),
              children: [
                {
                  headerName: i18n.t('Death'),
                  field: 'dmDeath',
                },
                {
                  headerName: i18n.t('Cull'),
                  field: 'dmCull',
                },
                {
                  headerName: i18n.t('%'),
                  field: 'dmRatio',
                },
                {
                  headerName: i18n.t('Cumm'),
                  field: 'dmCumm',
                },
                {
                  headerName: i18n.t('SE'),
                  field: 'dmSe',
                },
                {
                  headerName: i18n.t('%'),
                  field: 'dmSeRatio',
                },
                {
                  headerName: i18n.t('Cumm SE'),
                  field: 'dmCummSE',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Moving'),
          children: [
            {
              headerName: i18n.t('Female'),
              children: [
                {
                  headerName: i18n.t('In'),
                  field: 'mfIn',
                },
                {
                  headerName: i18n.t('Out'),
                  field: 'mfOut',
                },
              ],
            },
            {
              headerName: i18n.t('Male'),
              children: [
                {
                  headerName: i18n.t('In'),
                  field: 'mmIn',
                },
                {
                  headerName: i18n.t('Out'),
                  field: 'mmOut',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Feed'),
          children: [
            {
              headerName: i18n.t('Female'),
              children: [
                {
                  headerName: i18n.t('Total'),
                  field: 'ffTotal',
                },
                {
                  headerName: i18n.t('Bird'),
                  field: 'ffBird',
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'ffStd',
                },
              ],
            },
            {
              headerName: i18n.t('Male'),
              children: [
                {
                  headerName: i18n.t('Total'),
                  field: 'fmTotal',
                },
                {
                  headerName: i18n.t('Bird'),
                  field: 'fmBird',
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'fmStd',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Body Weight'),
          children: [
            {
              headerName: i18n.t('Female'),
              children: [
                {
                  headerName: i18n.t('Act'),
                  field: 'bfAct',
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'bfStd',
                },
                {
                  headerName: i18n.t('Uniformity'),
                  field: 'bfUniformity',
                },
              ],
            },
            {
              headerName: i18n.t('Male'),
              children: [
                {
                  headerName: i18n.t('Act'),
                  field: 'bmAct',
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'bmStd',
                },
                {
                  headerName: i18n.t('Uniformity'),
                  field: 'bmUniformity',
                },
              ],
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('Remarks'),
              children: [
                {
                  headerName: i18n.t('Veterinarian'),
                  field: 'vetRemark',
                },
              ],
            },
          ],
        },
      ],
      gridHeight: '640px',
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        width: 100,
        minWidth: 100,
        cellClass: 'text-center',
        suppressSizeToFit: false,
        valueFormatter: function (params) {
          return GridUtil.formatReport(params);
        },
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagenation: false,
      paginationPageSize: 10,
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    if (Const.RPT_TEST) {
      const params = ReportUtil.getDefaultParams(this.props);
      console.log('params:', params);
      this.fnGetRowData(params);
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  // -----------------------------------------------------------
  // 참고용 : ES/ETL 서버 주소(API)를 지정해야 합니다.
  // -----------------------------------------------------------
  fnGetRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/stat/breeding/grwWeeklyR.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data.rows1 });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtExport = () => {
    const fileName =
      i18n.t(this.state.excelName) + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t(this.state.excelName);
    const params = {
      allColumns: false,
      columnGroups: true,
      fileName: fileName,
      sheetName: sheetName,
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onSearch = (params) => {
    console.log(params);
    this.fnGetRowData(params);
  };

  render() {
    return (
      <React.Fragment>
        <h3>
          {/* 육성기 주령별 보고서 */}
          <Trans>Title Grw Weekly R</Trans>
        </h3>

        <div className="con_box">
          <FHDateRangeSearch onSearch={this.onSearch}></FHDateRangeSearch>

          <div className="f_right mB20">
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
          </div>
          <div className="board_list_s">
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  excelStyles={this.state.excelStyles}
                  onGridReady={this.onGridReady}
                  rowData={this.state.rowData}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
