import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import { Radio, Input } from 'antd';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import axios from 'axios';
import qs from 'qs';
import { withAlert } from 'react-alert';
import { Server } from 'components/Properties';

import SexCode from 'containers/pages/common/system/SexCode';
import StrainCode from 'containers/pages/common/system/StrainCode';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class ScheduleMastRegist extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        farmCode: SessionUtil.getFarmCode(),
        strainCode: '',
        sexCode: '',
        taskName: '',
      },
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  onChangeParam = (e) => {
    const { name, value } = e.target;
    this.setState(
      produce((draft) => {
        draft.params[name] = name === 'mesgId' ? value.toUpperCase() : value;
      })
    );
  };

  onBtCancel = () => {
    console.log('onBtCancel');
    this.props.setEditing(false);
  };

  onBtSave = () => {
    const that = this;
    const params = _.cloneDeep(this.state.params); // cloneDeep 중요
    console.log('onBtSave:', this.state.params);
    let url = Server.getRestAPI() + '/work/insertTcTaskMast.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        console.log('onBtSave response.data:', data);
        if (data.error) {
          return that.props.alert.error(i18n.t(data.error));
        }
        if (data > 0) {
          that.fnClearParams();
          that.props.setRegDate();
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  fnClearParams = () => {
    const that = this;
    this.setState(
      produce((draft) => {
        Object.keys(that.state.params).forEach((key) => {
          if (key !== 'farmCode') {
            draft.params[`${key}`] = '';
          }
        });
      }),
      function () {
        console.log('state.params:', that.state.params);
      }
    );
  };

  setCode = (name, value) => {
    console.log(name, value);
    this.setState(
      produce((draft) => {
        draft.params[name] = value;
      }),
      function () {}
    );
  };

  render() {
    if (!this.props.editing) return null;
    return (
      <Fragment>
        {/* Maser 등록 */}
        <div className="con_box">
          <h3>
            <Trans>New Master Register</Trans>
          </h3>

          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Farm Code</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    <Input
                      type="text"
                      name="farmCode"
                      className="input_type1"
                      style={{ width: 150 }}
                      maxLength="9"
                      placeholder=""
                      value={this.state.params.farmCode}
                      onChange={(e) => this.onChangeParam(e)}
                      disabled={true}
                    />
                  </td>
                  <th>
                    <Trans>Strain Code</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    <StrainCode
                      name="strainCode"
                      setCode={this.setCode}
                      code={this.state.params.strainCode}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Sex</Trans> <span className="red">*</span>
                  </th>
                  <td>
                    <SexCode
                      name="sexCode"
                      setCode={this.setCode}
                      code={this.state.params.sex}
                    />
                  </td>
                  <th>
                    <Trans>Task Name</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="taskName"
                      className="input_type1"
                      maxLength="30"
                      placeholder=""
                      value={this.state.params.taskName}
                      onChange={(e) => this.onChangeParam(e)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="txt_center mT40">
            <button className="btn_gray" onClick={this.onBtCancel}>
              <Trans>Cancel</Trans>
            </button>
            <button className="btn_save" onClick={this.onBtSave}>
              <Trans>Save</Trans>
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withAlert()(ScheduleMastRegist);
