import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, ObjectUtil } from 'utils';
import { moment } from 'components';
import axios from 'axios';
import qs from 'qs';
import { Env, Server, Const } from 'components/Properties';

export default class ReportUtil {
  // 개발용(dev), CJ용(cjprod) 구분용
  static getEnv = () => {
    return Env;
  };

  static getPathObj = (props) => {
    const { match } = props;
    const path = match.path;

    let pathObj = {};
    if (path) {
      const info = path.split('/'); // breeding, broiler
      pathObj['cate'] = info[1];
      pathObj['type'] = info[2];
      pathObj['page'] = info[3];
    }
    return pathObj;
  };

  static getCate = (pathObj) => {
    return pathObj.cate;
  };

  static getPage = (pathObj) => {
    return pathObj.page;
  };

  static getDefaultParams = (props) => {
    const pathObj = this.getPathObj(props);
    let params = {};

    if (this.getEnv() === 'dev') {
      params = this.getDevParams(pathObj);
      return params;
    }

    if (this.getEnv() === 'cjprod') {
      params = this.getCJParams(pathObj);
      return params;
    }

    return params;
  };

  static getCJParams = (pathObj) => {
    let params = {};
    switch (this.getPage(pathObj)) {
      case 'grwDailyR': // 1.육성기 일령별
        params = {
          farmCode: '39',
          flockId: 'C119LP1F00001',
          // hhId: 'H39H001',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'grwWeeklyR': // 2.육성기 보고서
        params = {
          farmCode: '39',
          flockId: 'C119LP1F00001',
          // hhId: 'H39H001',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'grwFlockHhR': // 3.육성기 계군/계사별
        params = {
          farmCode: '39',
          // division: '000B',
          // region: '0008',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'grwIdxAnlysR': // 4.육성기 지표별 분석
        params = {
          checkBox: [
            'Dep.F.Cum',
            'BW.F',
            'BW.M',
            'Dep.M.Cum',
            'Dep.F',
            'Unif.F',
            'Dep.M',
            'Unif.M',
            'Feed.F',
            'SE.Cum',
            'Feed.M',
          ],
          farmCode: '39',
          flockId: 'C119LP1F00001',
          // hhId: 'H39H001',
        };
        return params;

      case 'grwTotalR1': // 5.육성기 종합 1
        params = {
          farmCode: '39',
          // division: '000B',
          // region: '0008',
          dateFormat: SessionUtil.getDateFormat(),
          searchDate: '2019-12-23',
        };
        return params;

      case 'grwTotalR2': // 6.육성기 종합 2
        params = {
          farmCode: '39',
          // division: '000B',
          // region: '0008',
          index: 'pop',
          dateFormat: SessionUtil.getDateFormat(),
          searchDate: '2019-12-23',
        };
        return params;

      case 'layDailyR': // 7.산란기 일령별
        params = {
          farmCode: '39',
          flockId: 'C119LP1F00001',
          // hhId: 'H39H001',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'layWeeklyR': // 8.산란기 주령별
        params = {
          farmCode: '39',
          flockId: 'C119LP1F00001',
          // hhId: 'H39H001',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'layFlockHhR': // 9.산란 계군/계사별
        params = {
          farmCode: '39',
          // division: '000B',
          // region: '0008',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'hatEpDailyHhR': // 10.부화 일/계란생산
        params = {
          farmCode: '39',
          flockId: 'C119LP1F00001',
          // hhId: 'H39H001',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'hatEpWklyHhR': // 11.부화 주/계란생산
        params = {
          farmCode: '39',
          flockId: 'C119LP1F00001',
          // hhId: 'H39H001',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'hatEpTermHhR': // 12.종계 부화 보고서
        params = {
          farmCode: '39',
          // division: '000B',
          // region: '0008',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'hatDocTermHhR': // 13.부화 계사/DOC별
        params = {
          farmCode: '39',
          // division: '000B',
          // region: '0008',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'hatStkTermHhR1': // 14.부화 계사/재고별
        params = {
          farmCode: '39',
          // division: '000B',
          // region: '0008',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'clsFlkAnlysFH': // 15.완료계군 분석
        params = {
          farmCode: '39',
          // division: '000B',
          // region: '0008',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'layIdxAnlysR': // 16.산란 지표별 분석
        params = {
          checkBox: [
            'Dep.Cum',
            'EP',
            'HE.Cum',
            'SC.Cum',
            'BW.F',
            'BW.M',
            'Feed.F',
            'Feed.M',
            'Egg.Weight',
            'Hatch',
          ],
          farmCode: '39',
          flockId: 'C119LP1F00001',
          // hhId: 'H39H001',
        };
        return params;

      case 'layTotalR1': // 17.산란 종합 1
        params = {
          farmCode: '39',
          // division: '000B',
          // region: '0008',
          dateFormat: SessionUtil.getDateFormat(),
          searchDate: '2019-12-23',
        };
        return params;

      case 'layTotalR2': // 18.산란 종합 2
        params = {
          farmCode: '39',
          // division: '000B',
          // region: '0008',
          index: 'ep',
          dateFormat: SessionUtil.getDateFormat(),
          searchDate: '2019-12-23',
        };
        return params;

      case 'dailyProjectR': // 1.일일 보고서
        params = {
          projCode: '20191216PJ0044',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-12-16',
          endDate: '2020-01-26',
        };
        return params;

      case 'dailyAreaR': // 2.프로젝트별 현황
        params = {
          farmCode: 'F97RA600',
          // division: '000B',
          // region: '0008',
          dateFormat: SessionUtil.getDateFormat(),
          searchDate: '2019-12-23',
        };
        return params;

      case 'salesR': // 3.출하실적 보고서
        params = {
          farmCode: 'F97RA600',
          // division: '000B',
          // region: '0008',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-12-16',
          endDate: '2020-01-26',
        };
        return params;

      case 'projGphAnlysR': // 4.프로젝트 분석
        params = {
          checkBox: [
            'depAct',
            'depStd',
            'pfAct',
            'pfStd',
            'bwAct',
            'bwStd',
            'fcrAct',
            'fcrStd',
            'ipAct',
            'ipStd',
          ],
          farmCode: 'F97RA600',
          // projCode: '20191216PJ0044',
          // division: '000B',
          // region: '0008',
        };
        return params;

      case 'projIdxAnlysR': // 5.지표별 Proj 분석
        params = {
          farmCode: 'F97RA600',
          // division: '000B',
          // region: '0008',
          index: 'pointFeed',
          dateFormat: SessionUtil.getDateFormat(),
          searchDate: '2020-07-01',
        };
        return params;
    }

    return {};
  };

  static getDevParams(pathObj) {
    let params = {};
    switch (this.getPage(pathObj)) {
      case 'grwDailyR': // 1.육성기 일령별
        params = {
          farmCode: '01',
          flockId: 'C119JAKQ00001',
          hhId: 'H01H001',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'grwWeeklyR': // 2.육성기 보고서
        params = {
          farmCode: '01',
          flockId: 'C119JAKQ00001',
          hhId: 'H01H001',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'grwFlockHhR': // 3.육성기 계군/계사별
        params = {
          farmCode: '39',
          // division: '000B',
          // region: '0008',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'grwIdxAnlysR': // 4.육성기 지표별 분석
        params = {
          checkBox: [
            'Dep.F.Cum',
            'BW.F',
            'BW.M',
            'Dep.M.Cum',
            'Dep.F',
            'Unif.F',
            'Dep.M',
            'Unif.M',
            'Feed.F',
            'SE.Cum',
            'Feed.M',
          ],
          farmCode: '39',
          flockId: 'C119LP1F00001',
          // hhId: 'H39H001',
        };
        return params;

      case 'grwTotalR1': // 5.육성기 종합 1
        params = {
          farmCode: '01',
          // division: '000B',
          // region: '0008',
          dateFormat: SessionUtil.getDateFormat(),
          searchDate: '2019-08-15',
        };
        return params;

      case 'grwTotalR2': // 6.육성기 종합 2
        params = {
          farmCode: '01',
          // division: '000B',
          // region: '0008',
          index: 'bwF', // pop, bwF, bwM, unifF, depF, feedF
          dateFormat: SessionUtil.getDateFormat(),
          searchDate: '2019-08-15',
        };
        return params;

      case 'layDailyR': // 7.산란기 일령별
        params = {
          farmCode: '39',
          flockId: 'C119LP1F00001',
          // hhId: 'H39H001',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'layWeeklyR': // 8.산란기 주령별
        params = {
          farmCode: '39',
          flockId: 'C119LP1F00001',
          // hhId: 'H39H001',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'layFlockHhR': // 9.산란 계군/계사별
        params = {
          farmCode: '39',
          // division: '000B',
          // region: '0008',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'hatEpDailyHhR': // 10.부화 일/계란생산
        params = {
          farmCode: '39',
          flockId: 'C119LP1F00001',
          // hhId: 'H39H001',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'hatEpWklyHhR': // 11.부화 주/계란생산
        params = {
          farmCode: '39',
          flockId: 'C119LP1F00001',
          // hhId: 'H39H001',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'hatEpTermHhR': // 12.종계 부화 보고서
        params = {
          farmCode: '39',
          // division: '000B',
          // region: '0008',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'hatDocTermHhR': // 13.부화 계사/DOC별
        params = {
          farmCode: '39',
          // division: '000B',
          // region: '0008',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'hatStkTermHhR1': // 14.부화 계사/재고별
        params = {
          farmCode: '39',
          // division: '000B',
          // region: '0008',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'clsFlkAnlysFH': // 15.완료계군 분석
        params = {
          farmCode: '39',
          // division: '000B',
          // region: '0008',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2019-05-01',
          endDate: DateUtil.toDateFormat(new Date()),
        };
        return params;

      case 'layIdxAnlysR': // 16.산란 지표별 분석
        params = {
          checkBox: [
            'Dep.Cum',
            'EP',
            'HE.Cum',
            'SC.Cum',
            'BW.F',
            'BW.M',
            'Feed.F',
            'Feed.M',
            'Egg.Weight',
            'Hatch',
          ],
          farmCode: '39',
          flockId: 'C119LP1F00001',
          // hhId: 'H39H001',
        };
        return params;

      case 'layTotalR1': // 17.산란 종합 1
        params = {
          farmCode: '39',
          // division: '000B',
          // region: '0008',
          dateFormat: SessionUtil.getDateFormat(),
          searchDate: '2019-12-23',
        };
        return params;

      case 'layTotalR2': // 18.산란 종합 2
        params = {
          farmCode: '39',
          // division: '000B',
          // region: '0008',
          index: 'ep',
          dateFormat: SessionUtil.getDateFormat(),
          searchDate: '2019-12-23',
        };
        return params;

      case 'dailyProjectR': // 1.일일 보고서
        params = {
          projCode: '20191202PJ0001',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2018-01-01',
          endDate: '2020-12-01',
        };
        return params;

      case 'dailyAreaR': // 2.프로젝트별 현황
        params = {
          farmCode: '',
          division: '000F',
          region: '0031',
          dateFormat: SessionUtil.getDateFormat(),
          searchDate: '2019-12-26',
        };
        return params;

      case 'salesR': // 3.출하실적 보고서
        params = {
          farmCode: '',
          division: '000F',
          region: '',
          dateFormat: SessionUtil.getDateFormat(),
          startDate: '2018-01-01',
          endDate: '2020-12-01',
        };
        return params;

      case 'projGphAnlysR': // 4.프로젝트 분석
        params = {
          checkBox: [
            'depAct',
            'depStd',
            'pfAct',
            'pfStd',
            'bwAct',
            'bwStd',
            'fcrAct',
            'fcrStd',
            'ipAct',
            'ipStd',
          ],
          farmCode: '',
          projCode: '20191216PJ0044',
          division: '',
          region: '',
        };
        return params;

      case 'projIdxAnlysR': // 5.지표별 Proj 분석
        params = {
          farmCode: 'F97RA600',
          division: '000C',
          region: '0027',
          index: 'pointFeed',
          dateFormat: SessionUtil.getDateFormat(),
          searchDate: '2020-06-30',
        };
        return params;
    }

    return {};
  }
}
