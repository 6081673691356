import React, { Fragment } from 'react';
import {
  SessionUtil,
  DateUtil,
  GridUtil,
  PopSearchCust,
  NumericEditor,
  PopSearchMaterial,
} from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { Input, Modal, Popconfirm, Switch } from 'antd';
import i18n from 'i18next';
import NumberFormat from 'react-number-format';
import FlockList from './FlockList';
import HenHouseList from './HenHouseList';
import { withAlert } from 'react-alert';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import '../Broiler.module.css';
const { Search } = Input;
const { confirm } = Modal;

var newCount = 1;
/*
 ** Breeding/Glowing Log
 ** saveGrowingLog
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class GrowingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramData: '',
      visible: false,
      growingForm: [],
      matrialList: [],
      feedList: [],
      vaccineList: [],
      medicineList: [],
      customerList: [],
      delView: false,
      customerName: '',
      salesList: [],
      curParams: [],
      modules: AllModules,
      columnDefs: [
        {
          headerName: 'Sales',
          children: [
            {
              headerName: '',
              field: 'customer',
              width: 96,
              editable: false,
              cellRenderer: function (params) {
                return (
                  '<button class="btn_sel">' + i18n.t('Customer') + '</button>'
                );
              },
              suppressSizeToFit: true,
            },
            {
              headerName: 'Customer',
              field: 'customerCode',
              width: 200,
              editable: false,
              hide: true,
              cellStyle: { textAlign: 'left' },
              suppressSizeToFit: true,
            },
            {
              headerName: 'Customer',
              field: 'customerName',
              width: 200,
              editable: false,
              cellStyle: { textAlign: 'left' },
              suppressSizeToFit: true,
            },
            {
              headerName: 'Order No',
              field: 'sOrderNo',
              width: 120,
              editable: true,
              cellStyle: { textAlign: 'center' },
              suppressSizeToFit: true,
            },
          ],
        },
      ],
      defaultColDef: {
        editable: true,
        width: 100,
        filter: true,
        resizable: true,
        sortable: true,
        suppressSizeToFit: false,
      },
      frameworkComponents: {
        numericEditor: NumericEditor,
      },
      popSearchMaterialView: false,
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.growingView !== nextProps.growingView) {
      return {
        delView: false,
        growingForm: nextProps.growingForm,
        growingView: nextProps.growingView,
      };
    }
    return null;
  }
  fnSearch = () => {
    const that = this;
    let params = {};
    let farmCodeList = [];
    let feedList = [];
    let vaccineList = [];
    let medicineList = [];

    //params.farmCode = SessionUtil.getFarmCode();
    //params.materialType = 'F';

    let url = Server.getRestAPI() + '/basis/selectTcMaterialInfo.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;

        if (data) {
          data.map((row, i) => {
            if (row.materialType === 'F') {
              feedList.push({
                materialCode: row.materialCode,
                materialName: row.materialName,
              });
            } else if (row.materialType === 'V') {
              vaccineList.push({
                materialCode: row.materialCode,
                materialName: row.materialName,
              });
            } else if (row.materialType === 'M') {
              medicineList.push({
                materialCode: row.materialCode,
                materialName: row.materialName,
              });
            }
          });
          that.setState(
            {
              feedList: feedList,
              vaccineList: vaccineList,
              medicineList: medicineList,
            },
            function () {
              //거래처 리스트 불러오기
              that.selectCustomerList("'11','12','13','14'");
            }
          );
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  fnClickCancel = (e) => {
    this.props.fnClickCancel('growingView');
  };
  fnClickCancel = (e) => {
    this.setState({ [e]: false });
  };

  fnDelete = () => {};
  cancel = () => {
    this.setState({ delView: false });
  };
  confirm = () => {
    this.fnSave();
  };

  fnSave = () => {
    let that = this;
    let data = this.state.growingForm;

    let salesList = [];
    let salesChk = true;

    if (!data.workDate) {
      that.props.alert.show(i18n.t('Please enter Work Date.'));
      that.workDate.focus();
      return false;
    }
    data.prevPop = data.prevPop ? Number(data.prevPop) : 0;
    data.deathQty = data.deathQty ? Number(data.deathQty) : 0;
    data.cullingQty = data.cullingQty ? Number(data.cullingQty) : 0;
    data.transferQty = data.transferQty ? Number(data.transferQty) : 0;

    if (
      data.prevPop - data.deathQty - data.cullingQty - data.transferQty ===
      0
    ) {
      data.endDate = data.workDate;
    }

    if (!data.feedCode1) {
      that.props.alert.show(i18n.t('Please enter FeedCode1.'));
      that.feedName1.focus();
      return false;
    }
    if (!data.feedUseAmt1 || data.feedUseAmt1 === 0) {
      that.props.alert.show(i18n.t('Please enter FeedUseAmt1.'));
      that.feedUseAmt1.focus();
      return false;
    }

    //Sales
    that.gridApi.stopEditing();
    that.gridApi.forEachNode(function (rowNode, index) {
      console.log('node ', rowNode.data);
      if (rowNode.data.customerCode === ' ') {
        that.props.alert.show(i18n.t('Please enter Customer.'));
        that.gridApi.setFocusedCell(index, 'customerCode');
        salesChk = false;
        return false;
      }
      let def = {};
      def.customerCode = rowNode.data.customerCode;
      def.customerName = rowNode.data.customerName;
      def.sOrderNo = rowNode.data.sOrderNo;
      def.transferQty = rowNode.data.transferQty ? rowNode.data.transferQty : 0;
      def.transferWeight = rowNode.data.transferWeight
        ? rowNode.data.transferWeight
        : 0;
      def.cullingQty = rowNode.data.cullingQty ? rowNode.data.cullingQty : 0;
      def.cullingWeight = rowNode.data.cullingWeight
        ? rowNode.data.cullingWeight
        : 0;
      def.harvestQty = rowNode.data.harvestQty ? rowNode.data.harvestQty : 0;
      def.harvestWeight = rowNode.data.harvestWeight
        ? rowNode.data.harvestWeight
        : 0;
      def.truckNo = rowNode.data.truckNo;
      def.remarks = rowNode.data.remarks;

      salesList.push(def);
    });
    if (!salesChk) return false;

    data.salesList = salesList;
    data.salesListlength = salesList.length;

    let transferQtySum = 0;
    let transferWeightSum = 0;
    let harvestQtySum = 0;

    for (var i = 0; i < salesList.length; i++) {
      transferQtySum =
        Number(salesList[i].cullingQty) + Number(salesList[i].harvestQty);
      transferWeightSum =
        Number(salesList[i].cullingWeight) + Number(salesList[i].harvestWeight);
      harvestQtySum = harvestQtySum + Number(salesList[i].harvestQty);
    }

    data.transferQty = transferQtySum;
    data.transferWeight = transferWeightSum;

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.updateId = SessionUtil.getUserId();

    that.setState({
      growingForm: data,
    });

    if (data.prevPop < data.deathQty + data.cullingQty + harvestQtySum) {
      that.props.alert.show(i18n.t('Please check Quantity'));
      return false;
    }

    if (data.prevPop == data.deathQty + data.cullingQty + harvestQtySum) {
      this.confirm_for_project();
    }

    if (data.prevPop > data.deathQty + data.cullingQty + harvestQtySum) {
      this.fnInsertGrowinLog();
    }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState(
      {
        visible: false,
      },
      function () {}
    );
  };

  confirm_for_project = (data) => {
    Modal.confirm({
      content: 'Project Will be cloesd. Are you sure?',
      visible: this.state.visible,
      okText: 'Save',
      cancelText: 'Cancel',
      onOk: this.fnInsertGrowinLog,
      onCancel: this.hideModal,
    });
  };

  fnInsertGrowinLog = () => {
    let that = this;
    let data = this.state.growingForm;
    console.log('saveGrowingLog', data);
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/broiler/saveGrowingLog.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.fnSearch();
      })
      .catch(function (error) {
        return [];
      });
  };

  setOnChange = (e, params, dateString) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';

    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (
      params.type === 'D' ||
      params.type === 'T' ||
      params.type === 'TM'
    ) {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }
    this.setState(
      {
        growingForm: {
          ...this.state.growingForm,
          [col]: val,
        },
      },
      function () {
        if (
          params.type === 'N' &&
          (params.inputName === 'deathQty' ||
            //params.inputName === 'cullingQty' ||
            params.inputName === 'transferQty' ||
            params.inputName === 'ompalQty' ||
            params.inputName === 'weakQty' ||
            params.inputName === 'abnormalQty' ||
            params.inputName === 'claimQty' ||
            params.inputName === 'dryLegQty')
        )
          that.fnCalcPopulation();
      }
    );
  };
  fnCalcPopulation = (params) => {
    let that = this;
    let data = this.state.growingForm;
    console.log('fnCalcPopulation===>', data);
    let prevPop = data.prevPop ? Number(data.prevPop) : 0;
    let deathQty = data.deathQty ? Number(data.deathQty) : 0;
    let transferQty = data.transferQty ? Number(data.transferQty) : 0;

    let ompalQty = data.ompalQty ? Number(data.ompalQty) : 0;
    let weakQty = data.weakQty ? Number(data.weakQty) : 0;
    let abnormalQty = data.abnormalQty ? Number(data.abnormalQty) : 0;
    let claimQty = data.claimQty ? Number(data.claimQty) : 0;
    let dryLegQty = data.dryLegQty ? Number(data.dryLegQty) : 0;

    let cullingQty = ompalQty + weakQty + abnormalQty + claimQty + dryLegQty;

    let population = prevPop - deathQty - cullingQty - transferQty;

    if (population < 0) {
      this.props.alert.show(i18n.t('The quantity is exceeded.'));
      //return false;
    }
    that.setState(
      {
        growingForm: {
          ...that.state.growingForm,
          population: population,
          cullingQty: cullingQty,
        },
      },
      function () {}
    );
  };
  //거래처 찾기
  selectCustomerList = (value) => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.customerName = '';
    data.customerKind = value;
    //customerKind="'11','12','13','14'"
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/selectCustomerList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ customerList: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };
  fnSearchCust = (value) => {
    this.setState(
      {
        popSearchCustView: true,
        customerCode: value,
        customerKind: [
          '11',
          '12',
          '13',
          '14',
          '21',
          '31',
          '41',
          '51',
          '98',
          '99',
        ],
      },
      function () {}
    );
  };
  //Customer 선택 후
  fnSelectCust = (data) => {
    let that = this;
    var rowNode = that.gridApi.getDisplayedRowAtIndex(
      this.state.curParams.rowIndex
    );
    rowNode.setDataValue('customerCode', data.customerCode);
    rowNode.setDataValue('customerName', data.customerName);

    that.gridApi.setFocusedCell(this.state.curParams.rowIndex, 'sOrderNo');
  };
  fnClosePop = (data) => {
    this.setState({ [data]: false }, function () {});
  };
  setOnChangeCust = (e) => {
    this.setState(
      {
        growingForm: {
          ...this.state.growingForm,
          customerName: e.target.value,
          customerCode: '',
        },
      },
      function () {}
    );
  };
  setOnChangeMaterial = (e) => {
    this.setState({ fieldName: e.target.value, fieldCode: '' }, function () {});
  };
  fnSelectMaterial = (data, fieldCode, fieldName, fieldUnit) => {
    let col = fieldCode.replace('Code', 'UseAmt');
    let colView = fieldCode.replace('Code', 'View');
    this[col].focus();

    //console.log('$$$$$$$$$$$$', data, fieldCode, fieldName, fieldUnit);

    this.setState(
      {
        growingForm: {
          ...this.state.growingForm,
          [fieldCode]: data.materialCode,
          [fieldName]: data.materialName,
          [fieldUnit]: data.unit,
          [colView]: true,
        },
      },
      function () {}
    );
  };
  fnAddSales = (val) => {
    let that = this;
    if (val === 'A') {
      var newItem = this.createNewRowData();
      var res = this.gridApi.updateRowData({ add: [newItem] });
      this.printResult(res);
    } else if (val === 'D') {
      var selectedData = this.gridApi.getSelectedRows();
      if (selectedData.length > 0) {
        confirm({
          title: i18n.t('Are you sure delete this task?'),
          //icon: <ExclamationCircleOutlined />,
          content: i18n.t('Delete and Save.'),
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk() {
            that.fnDelete(selectedData);
          },
          onCancel() {},
        });
      }
    }
  };

  fnDelete = (data) => {
    var res = this.gridApi.updateRowData({ remove: data });
    this.printResult(res);
  };
  createNewRowData = () => {
    var newData = {
      customerCode: ' ',
      customerName: ' ',
      orderNo: ' ',
      salesQty: 0,
      salesWeight: 0,
    };
    newCount++;
    return newData;
  };
  printResult = (res) => {
    if (res.add) {
      res.add.forEach(function (rowNode) {
        //console.log('Added Row Node', rowNode);
      });
    }
    if (res.remove) {
      res.remove.forEach(function (rowNode) {
        //console.log('Removed Row Node', rowNode);
      });
    }
    if (res.update) {
      res.update.forEach(function (rowNode) {
        //console.log('Updated Row Node', rowNode);
      });
    }
  };
  onGridReadyEdit = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();

    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      params.api.setRowData(data.slice(0, 100));
    };
  };
  fnSearchMatrial = (value, materialType, fieldCode, fieldName, fieldUnit) => {
    console.log(
      '###########',
      value,
      materialType,
      fieldCode,
      fieldName,
      fieldUnit
    );
    this.setState(
      {
        popSearchMaterialView: true,
        materialName: value,
        fieldCode: fieldCode, //return field
        fieldName: fieldName, //return field
        fieldUnit: fieldUnit, //return field
        materialType: materialType,
      },
      function () {}
    );
  };
  onCellValueChanged = (params) => {
    let that = this;

    var rowNode = that.gridApi.getDisplayedRowAtIndex(params.rowIndex);
    let qty = Number(rowNode.data[col] ? rowNode.data[col] : 0);
    if (Number(params.value) > qty) {
      // that.gridApi.stopEditing();
      // rowNode.setDataValue(params.column.colId, 0);
      // //that.props.alert.show(i18n.t('The quantity is exceeded.'));
      that.gridApi.tabToPreviousCell();
      //that.gridApi.setFocusedCell(params.rowIndex, params.column.colId);
    } else {
      this.printAllDisplayedRows(params.column.colId);
    }
  };
  onCellClicked = (params) => {
    if (params.column.colId === 'customer') {
      //선택버튼 클릭
      console.log('onCellClicked', params);
      this.setState({ curParams: params });
      this.fnSearchCust('');
      //this.props.fnSelectHatchery(e.data);
    }
  };
  handleChange = (params, op) => {};
  fnSetFields = (val, e, co) => {
    let ix = co.slice(-1);
    let col = co.replace(ix, '');
    console.log(val, col, col.slice(-1));
    this.setState(
      {
        growingForm: {
          ...this.state.growingForm,
          [col + 'Code' + ix]: '',
          [col + 'Name' + ix]: '',
          [col + 'Unit' + ix]: '',
          [col + 'UseAmt' + ix]: 0,
          [col + 'View' + ix]: val,
        },
      },
      function () {
        console.log(this.state);
      }
    );
  };
  render() {
    //if (!this.props.growingView) return null;
    let data = this.state.growingForm;
    const dataArr = [1, 2, 3];
    this.state.columnDefs.push({
      headerName: 'Culling Qty',
      field: 'cullingQty',
      type: 'numericColumn',
      width: 120,
      cellEditor: 'numericEditor',
      cellEditorParams: function () {
        return { dec: 0, minVal: 0 };
      },
      valueFormatter: function (params) {
        return GridUtil.formatNumberDec(params);
      },
      suppressSizeToFit: true,
    });
    this.state.columnDefs.push({
      headerName: 'Culling Weight(Kg)',
      field: 'cullingWeight',
      type: 'numericColumn',
      width: 120,
      cellEditor: 'numericEditor',
      cellEditorParams: function () {
        return { dec: 3, minVal: 0 };
      },
      valueFormatter: function (params) {
        return GridUtil.formatNumberDec(params);
      },
      suppressSizeToFit: true,
    });
    this.state.columnDefs.push({
      headerName: 'Harvest Qty',
      field: 'harvestQty',
      type: 'numericColumn',
      width: 120,
      cellEditor: 'numericEditor',
      cellEditorParams: function () {
        return { dec: 0, minVal: 0 };
      },
      valueFormatter: function (params) {
        return GridUtil.formatNumberDec(params);
      },
      suppressSizeToFit: true,
    });
    this.state.columnDefs.push({
      headerName: 'Harvest Weight(Kg)',
      field: 'harvestWeight',
      type: 'numericColumn',
      width: 120,
      cellEditor: 'numericEditor',
      cellEditorParams: function () {
        return { dec: 3, minVal: 0 };
      },
      valueFormatter: function (params) {
        return GridUtil.formatNumberDec(params);
      },
      suppressSizeToFit: true,
    });

    this.state.columnDefs.push({
      headerName: 'Truck No',
      field: 'truckNo',
      width: 120,
      editable: true,
      cellStyle: { textAlign: 'left' },
      suppressSizeToFit: true,
    });
    this.state.columnDefs.push({
      headerName: 'Remarks',
      field: 'remarks',
      width: 300,
      editable: true,
      cellStyle: { textAlign: 'left' },
      suppressSizeToFit: false,
    });

    // const Option = Select.Option;
    // const customerOptions = this.state.customerList.map((d, i) => (
    //   <Option key={i} value={d.customerCode}>
    //     {d.customerName}
    //   </Option>
    // ));
    // const feedOptions = this.state.feedList.map((d, i) => (
    //   <Option key={i} value={d.materialCode}>
    //     {d.materialName}
    //   </Option>
    // ));
    // const vaccineOptions = this.state.vaccineList.map((d, i) => (
    //   <Option key={i} value={d.materialCode}>
    //     {d.materialName}
    //   </Option>
    // ));
    // const medicineOptions = this.state.medicineList.map((d, i) => (
    //   <Option key={i} value={d.materialCode}>
    //     {d.materialName}
    //   </Option>
    // ));
    return (
      <Fragment>
        <div className="layer_list writelist">
          <div className="board_list2 mT0">
            <table>
              <colgroup>
                <col style={{ width: '170px' }} />
                <col style={{ width: '200px' }} />
                <col style={{ width: '150px' }} />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th colSpan="4">
                    <span className="f_left">{data.workDate}</span>
                    <Trans>Growing Log</Trans>(
                    {data.projCode ? data.projName : ''})
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <Trans>Work Date</Trans>
                  </th>
                  <td className="spac10_left label">
                    <span>{this.state.growingForm.workDate}</span>
                  </td>
                  <td className="spac10_left label" colSpan="3">
                    <Trans>Prev Pop</Trans>{' '}
                    {GridUtil.renderInput({
                      inputName: 'prevPop', //*** */
                      type: 'N', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      statsForm: this.state.growingForm, //*** */
                      disabled: true,
                      that: this, //*** */
                    })}
                    <span className="mL20"></span>
                    <Trans>Population</Trans>{' '}
                    {GridUtil.renderInput({
                      inputName: 'population', //*** */
                      type: 'N', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      statsForm: this.state.growingForm, //*** */
                      disabled: true,
                      that: this, //*** */
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Death</Trans>
                  </th>
                  <td className="spac10_left label">
                    {GridUtil.renderInput({
                      inputName: 'deathQty', //*** */
                      type: 'N', //*** */
                      //width: 120, // px제외 : 없으면 30 */
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      minVal: 0,
                    })}
                  </td>
                  <th></th>
                  <td className="spac10_left label"></td>
                </tr>
                <tr>
                  <th>
                    <Trans>Culling</Trans>
                  </th>
                  <td className="spac10_left label">
                    {GridUtil.renderInput({
                      inputName: 'cullingQty', //*** */
                      type: 'N', //*** */
                      //width: 150, // px제외 : 없으면 30 */
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      disabled: true,
                    })}
                  </td>
                  <td className="spac10_left label" colSpan="2">
                    <Trans>Ompal</Trans>{' '}
                    {GridUtil.renderInput({
                      inputName: 'ompalQty', //*** */
                      type: 'N', //*** */
                      width: 80, // px제외 : 없으면 30 */
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      minVal: 0,
                    })}{' '}
                    <span className="mL20"></span>
                    <Trans>Weak</Trans>{' '}
                    {GridUtil.renderInput({
                      inputName: 'weakQty', //*** */
                      type: 'N', //*** */
                      width: 80, // px제외 : 없으면 30 */
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      minVal: 0,
                    })}
                    <span className="mL20"></span>
                    <Trans>Abnormal</Trans>{' '}
                    {GridUtil.renderInput({
                      inputName: 'abnormalQty', //*** */
                      type: 'N', //*** */
                      width: 80, // px제외 : 없으면 30 */
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      minVal: 0,
                    })}
                    <span className="mL20"></span>
                    <Trans>Claim</Trans>{' '}
                    {GridUtil.renderInput({
                      inputName: 'claimQty', //*** */
                      type: 'N', //*** */
                      width: 80, // px제외 : 없으면 30 */
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      minVal: 0,
                    })}
                    <span className="mL20"></span>
                    <Trans>Dry leg</Trans>{' '}
                    {GridUtil.renderInput({
                      inputName: 'dryLegQty', //*** */
                      type: 'N', //*** */
                      width: 80, // px제외 : 없으면 30 */
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      minVal: 0,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>B.W(g)</Trans> <span className="red">*</span>
                  </th>
                  <td className="spac10_left label">
                    {GridUtil.renderInput({
                      inputName: 'bw', //*** */
                      type: 'N', //*** */
                      //width: 120, // px제외 : 없으면 30 */
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      minVal: 0,
                      that: this, //*** */
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td className="spac10_left label" colSpan="3">
                    {GridUtil.renderInput({
                      inputName: 'growingRem', //*** */
                      type: '', //*** */
                      //width: 120, // px제외 : 없으면 30 */
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      maxLength: 1000,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Feed</Trans> <br></br>
                    {dataArr.map((ix) => (
                      <Switch
                        style={{ marginRight: '3px' }}
                        key={ix}
                        checkedChildren={ix}
                        unCheckedChildren={ix}
                        checked={
                          data['feedView' + ix] || ix === 1 ? true : false
                        }
                        onChange={(c, e) => this.fnSetFields(c, e, 'feed' + ix)}
                      />
                    ))}
                  </th>
                  <td className="spac10_left label" colSpan="3">
                    {dataArr.map((ix) => (
                      <div
                        key={ix}
                        style={{
                          display:
                            data['feedView' + ix] || ix === 1
                              ? 'block'
                              : 'none',
                        }}
                      >
                        <label htmlFor="" style={{ width: 60 }}>
                          <Trans>Name</Trans>
                          {ix}{' '}
                          {ix === 1 ? <span className="red">*</span> : null}
                        </label>
                        <Search
                          placeholder="Feed Name"
                          onSearch={(value) =>
                            this.fnSearchMatrial(
                              value,
                              'F',
                              'feedCode' + ix,
                              'feedName' + ix,
                              'feedUnit' + ix
                            )
                          }
                          enterButton
                          style={{ width: '250px' }}
                          className=""
                          ref={(input) => {
                            this['feedName' + ix] = input;
                          }}
                          value={this.state.growingForm['feedName' + ix]}
                          onChange={(value) => this.setOnChangeMaterial(value)}
                        />
                        <label htmlFor="" style={{ width: 50 }}>
                          <Trans>Qty</Trans>
                        </label>
                        {GridUtil.renderInput({
                          inputName: 'feedUseAmt' + ix, //*** */
                          type: 'N', //*** */
                          width: 100, // px제외 : 없으면 30 */
                          statsForm: this.state.growingForm, //*** */
                          setOnChange: this.setOnChange, //*** */
                          that: this, //*** */
                          dec: 2,
                        })}{' '}
                        {this.state.growingForm['feedUnit' + ix]
                          ? this.state.growingForm['feedUnit' + ix]
                          : ''}
                      </div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Vaccine</Trans>
                    <br></br>
                    {dataArr.map((ix) => (
                      <Switch
                        style={{ marginRight: '3px' }}
                        key={ix}
                        checkedChildren={ix}
                        unCheckedChildren={ix}
                        checked={
                          data['vaccineView' + ix] || ix === 1 ? true : false
                        }
                        onChange={(c, e) =>
                          this.fnSetFields(c, e, 'vaccine' + ix)
                        }
                      />
                    ))}
                  </th>
                  <td className="spac10_left label" colSpan="3">
                    {dataArr.map((ix) => (
                      <div
                        key={ix}
                        style={{
                          display:
                            data['vaccineView' + ix] || ix === 1
                              ? 'block'
                              : 'none',
                        }}
                      >
                        <label htmlFor="" style={{ width: 60 }}>
                          <Trans>Name</Trans>
                          {ix}
                        </label>
                        <Search
                          placeholder="Vaccine Name"
                          onSearch={(value) =>
                            this.fnSearchMatrial(
                              value,
                              'V',
                              'vaccineCode' + ix,
                              'vaccineName' + ix,
                              'vaccineUnit' + ix
                            )
                          }
                          enterButton
                          style={{ width: '250px' }}
                          className=""
                          ref={(input) => {
                            this['vaccineName' + ix] = input;
                          }}
                          value={data['vaccineName' + ix]}
                          onChange={(value) => this.setOnChangeCust(value)}
                        />
                        <label htmlFor="" style={{ width: 50 }}>
                          <Trans>Qty</Trans>
                        </label>
                        {GridUtil.renderInput({
                          inputName: 'vaccineUseAmt' + ix, //*** */
                          type: 'N', //*** */
                          width: 100, // px제외 : 없으면 30 */
                          statsForm: this.state.growingForm, //*** */
                          setOnChange: this.setOnChange, //*** */
                          that: this, //*** */
                          dec: 2,
                        })}{' '}
                        {this.state.growingForm['vaccineUnit' + ix]}
                      </div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Medicine</Trans>
                    <br></br>
                    {dataArr.map((ix) => (
                      <Switch
                        style={{ marginRight: '3px' }}
                        key={ix}
                        checkedChildren={ix}
                        unCheckedChildren={ix}
                        checked={
                          data['medicineView' + ix] || ix === 1 ? true : false
                        }
                        onChange={(c, e) =>
                          this.fnSetFields(c, e, 'medicine' + ix)
                        }
                      />
                    ))}
                  </th>
                  <td className="spac10_left label" colSpan="3">
                    {dataArr.map((ix) => (
                      <div
                        key={ix}
                        style={{
                          display:
                            data['medicineView' + ix] || ix === 1
                              ? 'block'
                              : 'none',
                        }}
                      >
                        <label htmlFor="" style={{ width: 60 }}>
                          <Trans>Name</Trans>
                          {ix}
                        </label>
                        <Search
                          placeholder="Medicine Name"
                          onSearch={(value) =>
                            this.fnSearchMatrial(
                              value,
                              'M',
                              'medicineCode' + ix,
                              'medicineName' + ix,
                              'medicineUnit' + ix
                            )
                          }
                          enterButton
                          style={{ width: '250px' }}
                          className=""
                          ref={(input) => {
                            this['medicineName' + ix] = input;
                          }}
                          value={this.state.growingForm['medicineName' + ix]}
                          onChange={(value) => this.setOnChangeCust(value)}
                        />
                        <label htmlFor="" style={{ width: 50 }}>
                          <Trans>Qty</Trans>
                        </label>
                        {GridUtil.renderInput({
                          inputName: 'medicineUseAmt' + ix, //*** */
                          type: 'N', //*** */
                          width: 100, // px제외 : 없으면 30 */
                          statsForm: this.state.growingForm, //*** */
                          setOnChange: this.setOnChange, //*** */
                          that: this, //*** */
                          dec: 2,
                        })}{' '}
                        {this.state.growingForm['medicineUnit' + ix]}
                      </div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Veterinarian Log</Trans>
                  </th>
                  <td className="spac10_left label" colSpan="3">
                    <label htmlFor="" style={{ width: 60 }}></label>
                    {GridUtil.renderInput({
                      inputName: 'veterinarian', //*** */
                      type: '', //*** */
                      width: 500, // px제외 : 없으면 30 */
                      maxLength: 100,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            className="board_list ag-theme-balham mT10"
            style={{ height: '200px', width: '100%' }}
          >
            <AgGridReact
              modules={this.state.modules}
              onGridReady={this.onGridReadyEdit}
              columnDefs={this.state.columnDefs}
              rowData={
                this.state.growingForm.salesList
                  ? this.state.growingForm.salesList
                  : []
              }
              defaultColDef={this.state.defaultColDef}
              enterMovesDownAfterEdit={true}
              enterMovesDown={true}
              singleClickEdit={true}
              frameworkComponents={this.state.frameworkComponents}
              rowSelection="single"
              //editType={this.state.editType}
              //onRowClicked={e => this.onRowClicked(e)}
              //onCellValueChanged={e => this.onCellValueChanged(e)}
              onCellClicked={(e) => this.onCellClicked(e)}
              onCellFocused={(e) => {
                e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
              }}
            ></AgGridReact>
          </div>

          <div className="txt_left mT5">
            <button className="btn_blue" onClick={(e) => this.fnAddSales('A')}>
              <Trans>Add</Trans>
            </button>
            <button className="btn_gray" onClick={(e) => this.fnAddSales('D')}>
              <Trans>Delete</Trans>
            </button>
          </div>
          <div className="txt_center">
            <button className="btn_gray" onClick={(e) => this.fnClickCancel()}>
              <Trans>Cancel</Trans>
            </button>

            {this.state.growingForm.iuFlag === 'D' ? (
              <Popconfirm
                title={i18n.t('Are you sure delete this task?')}
                visible={this.state.delView}
                onVisibleChange={this.handleVisibleChange}
                onConfirm={this.confirm}
                onCancel={this.cancel}
                okText="OK"
                cancelText="Cancel"
              >
                <button className="btn_green" onClick={(e) => this.fnDelete()}>
                  <Trans>Delete</Trans>
                </button>
              </Popconfirm>
            ) : null}
            <button className="btn_save" onClick={(e) => this.fnSave()}>
              <Trans>save</Trans>
            </button>
          </div>
        </div>
        {this.state.popSearchCustView ? (
          <PopSearchCust
            popSearchCustView={this.state.popSearchCustView}
            customerCode={this.state.customerCode}
            customerKind={this.state.customerKind}
            fnClosePop={this.fnClosePop}
            fnSelectCust={this.fnSelectCust}
          />
        ) : null}
        {this.state.popSearchMaterialView ? (
          <PopSearchMaterial
            popSearchMaterialView={this.state.popSearchMaterialView}
            fieldCode={this.state.fieldCode} //return
            fieldName={this.state.fieldName} //return
            fieldUnit={this.state.fieldUnit} //return
            materialCode={this.state.materialCode}
            materialName={this.state.materialName}
            materialType={this.state.materialType}
            fnClosePop={this.fnClosePop}
            fnSelectMaterial={this.fnSelectMaterial}
          />
        ) : null}
      </Fragment>
    );
  }
}
export default withAlert()(GrowingForm);
