import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import FHCheckboxSearch from '../utils/FHCheckboxSearch';
import AFCheckboxSearch2 from '../utils/AFCheckboxSearch2';
import GrwIdxAnlysRechart from './GrwIdxAnlysRechart';
import ReportUtil from '../utils/ReportUtil';

import '../Report.module.css';

// 4. 육성기 지표별 분석 보고서
export default class GrwIdxAnlysR extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      excelName: 'Grow Index Analysis Report',
      rowData: [],
      rowData2: [],
      params: {},
      ColDefs: [
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Age'),
              field: 'week',
              pinned: 'left',
            },
          ],
        },
        {
          headerName: i18n.t('Dep. Cumm'),
          children: [
            {
              headerName: i18n.t('Female'),
              field: 'dcFemale',
            },
            {
              headerName: i18n.t('Male'),
              field: 'dcMale',
            },
          ],
        },
        {
          headerName: i18n.t('Dep. bird'),
          children: [
            {
              headerName: i18n.t('Female'),
              field: 'dbFemale',
            },
            {
              headerName: i18n.t('Male'),
              field: 'dbMale',
            },
          ],
        },
        {
          headerName: i18n.t('Body Weight (g/bird)'),
          children: [
            {
              headerName: i18n.t('Female'),
              field: 'bwFemale',
            },
            {
              headerName: i18n.t('Male'),
              field: 'bwMale',
            },
          ],
        },
        {
          headerName: i18n.t('Feed (g/bird)'),
          children: [
            {
              headerName: i18n.t('Female'),
              field: 'fkFemale',
            },
            {
              headerName: i18n.t('Male'),
              field: 'fkMale',
            },
          ],
        },
        {
          headerName: i18n.t('Uniformity (%)'),
          children: [
            {
              headerName: i18n.t('Female'),
              field: 'upFemale',
            },
            {
              headerName: i18n.t('Male'),
              field: 'upMale',
            },
          ],
        },
        {
          headerName: i18n.t('Sexing Error'),
          children: [
            {
              headerName: i18n.t('SE Bird'),
              field: 'se',
            },
          ],
        },
      ],
      gridHeight: '640px',
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        width: 100,
        minWidth: 100,
        cellClass: 'text-center',
        suppressSizeToFit: false,
        valueFormatter: function (params) {
          return GridUtil.formatReport(params);
        },
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagenation: false,
      paginationPageSize: 10,
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    // this.setState({ rowData: sampleData });
    if (Const.RPT_TEST) {
      const params = ReportUtil.getDefaultParams(this.props);
      console.log('params:', params);
      this.fnGetRowData(params);
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  // -----------------------------------------------------------
  // 참고용 : ES/ETL 서버 주소(API)를 지정해야 합니다.
  // -----------------------------------------------------------
  fnGetRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/stat/breeding/grwIdxAnlysR.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data.rows1, rowData2: data.rows2 });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtExport = () => {
    const fileName =
      i18n.t(this.state.excelName) + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t(this.state.excelName);
    const params = {
      allColumns: false,
      columnGroups: true,
      fileName: fileName,
      sheetName: sheetName,
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onSearch = (params) => {
    // console.log(params);
    this.fnGetRowData(params);
  };

  render() {
    return (
      <React.Fragment>
        <h3>
          {/* 육성기 지표별 분석 보고서 */}
          <Trans>Title Grw Idx Anlys R</Trans>
        </h3>

        <div className="con_box">
          <AFCheckboxSearch2 onSearch={this.onSearch}></AFCheckboxSearch2>

          <div className="f_right mB20">
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
          </div>
          <div className="board_list_s">
            <div style={{ width: '100%' }}>
              <GrwIdxAnlysRechart rowData={this.state.rowData2} />
            </div>
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  excelStyles={this.state.excelStyles}
                  onGridReady={this.onGridReady}
                  rowData={this.state.rowData}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

let i = 0;
const sampleData = [
  {
    age: i++,
    dcFemale: i++,
    dcMale: i++,
    dbFemale: i++,
    dbMale: i++,
    bwFemale: i++,
    bwMale: i++,
    fkFemale: i++,
    fkMale: i++,
    upFemale: i++,
    upMale: i++,
    se: i++,
  },
];
