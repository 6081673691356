import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, PagingUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected
} from 'components/commons/ag-grid/AgGridUtil';
import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { Input, Radio } from 'antd';

import ProjectView from './ProjectView';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class ProjectInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowNode: {},
      rowData: [],
      params: {
        farmCode: SessionUtil.getFarmCode(), // 자신의 Farm Code 에 대해서만 조회 (프로그램 설계서)
        pageNo: 1,
        pageSize: 10,
        total: 0,
        rowCnt: 0
      },
      ColDefs: [
        {
          headerName: i18n.t('Farm Code'),
          field: 'farmCode',
          getQuickFilterText: function(params) {
            return params.value;
          }
        },
        {
          headerName: i18n.t('Project Code'),
          field: 'projCode'
        },
        {
          headerName: i18n.t('Project Name'),
          field: 'projName'
        },
        {
          headerName: i18n.t('projClass'),
          field: 'projClass'
        },
        {
          headerName: i18n.t('projClassName'),
          field: 'projClassName',
          hide: true
        },
        {
          headerName: i18n.t('projStatus'),
          field: 'projStatus'
        },
        {
          headerName: i18n.t('projStatusName'),
          field: 'projStatusName',
          hide: true
        },
        {
          headerName: i18n.t('Strain Code'),
          field: 'strainCode',
          hide: true
        },
        {
          headerName: i18n.t('Strain Name'),
          field: 'strainCodeName'
        },
        {
          headerName: i18n.t('Start Date'),
          field: 'startDate',
          hide: true
        },
        {
          headerName: i18n.t('End Date'),
          field: 'endDate'
        },
        {
          headerName: i18n.t('Deleted'),
          field: 'deleted'
        },
        {
          headerName: i18n.t('Remarks'),
          field: 'remarks',
          hide: true
        }
      ],
      visable: false, // 상세 보기
      gridHeight: '365px',
      defaultColDef: {
        filter: true,
        resizable: true,
        editable: false,
        sortable: true
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagination: false,
      paginationPageSize: 10
      // editType: 'fullRow', // selectEditor와 함께 사용하지 말라고 하네요...
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    const { params } = this.state;
    this.fnGetRowData(params);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.rowData !== this.state.rowData) {
      this.setState({ visable: false, rowNode: {} });
    }
  }

  fnGetRowData = params => {
    const that = this;
    let url = Server.getRestAPI() + '/basis/selectTbProjectInfo.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        const { data } = response;
        if (data) {
          that.setState(
            produce(draft => {
              draft.rowData = data;
              draft.params['rowCnt'] = data.length;
              draft.params['total'] = data.length > 0 ? data[0].total : 0;
            }),
            function() {
              console.log(this.state);
            }
          );
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  onRowSelected = params => {
    const that = this;
    if (!isRowSelected(params)) return;
    this.setState(
      produce(draft => {
        draft.visable = true;
        draft.rowNode = params.data;
        draft.params = params.data;
      }),
      function() {}
    );
  };

  onFilterTextBoxChanged = e => {
    const { value } = e.target;
    this.gridApi.setQuickFilter(value);
  };

  onClick = pageNo => {
    const that = this;
    console.log('pageNo:', pageNo);
    this.setState(
      produce(draft => {
        draft.params['pageNo'] = pageNo;
      }),
      function() {
        const { params } = this.state;
        this.fnGetRowData(params);
      }
    );
  };

  onSearch = value => {
    this.setState(
      produce(draft => {
        draft.visable = false;
        draft.params['pageNo'] = 1;
        draft.params['searchKey'] = value;
      }),
      function() {
        const { params } = this.state;
        this.fnGetRowData(params);
      }
    );
  };

  render() {
    // return null 은 아무것도 표시하지 않는다는 의미입니다.
    // if (!this.props.localeListView) return null;
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Basic - Project Info</Trans>
        </h2>

        <div className="con_box">
          <div className="f_left mB20">
            {/*
            <input
              type="text"
              className="input_type1"
              placeholder={i18n.t('Filter')}
              onChange={e => this.onFilterTextBoxChanged(e)}
            />
            */}
            <Input.Search
              name="searchKey"
              placeholder={i18n.t('Search')}
              onSearch={this.onSearch}
            />
          </div>

          <div className="board_list_s">
            <div
              id="myGrid"
              style={{
                height: this.state.gridHeight,
                width: '100%'
              }}
              className="ag-theme-balham"
            >
              <AgGridReact
                modules={this.state.modules}
                columnDefs={this.state.ColDefs}
                // components={this.state.components}
                // frameworkComponents={this.state.frameworkComponents}
                defaultColDef={this.state.defaultColDef}
                rowSelection={this.state.rowSelection}
                editType={this.state.editType}
                // enterMovesDownAfterEdit={true}
                // enterMovesDown={true}
                // rowModelType={this.state.rowModelType}

                onGridReady={this.onGridReady}
                onCellEditingStarted={this.onCellEditingStarted}
                onCellEditingStopped={this.onCellEditingStopped}
                onRowClicked={this.onRowSelected}
                onRowSelected={this.onRowSelected}
                onRowEditingStarted={this.onRowEditingStarted}
                onRowEditingStopped={this.onRowEditingStopped}
                onCellValueChanged={this.onCellValueChanged}
                rowData={this.state.rowData}
                pagination={this.state.pagination}
                paginationPageSize={this.state.paginationPageSize}
              />

              <PagingUtil
                total={this.state.params.total}
                rowCnt={this.state.rowData.length}
                pageSize={this.state.params.pageSize}
                pageNo={this.state.params.pageNo}
                onClick={this.onClick}
              />
            </div>
          </div>
        </div>

        <ProjectView
          visable={this.state.visable}
          rowNode={this.state.rowNode}
        ></ProjectView>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
