import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, FormUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import '../Common.module.css';

import CountryCode from 'containers/pages/common/system/CountryCode';

import { Radio } from 'antd';
import produce from 'immer';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { message } from 'antd';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class BreedHhView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        // farmCode: SessionUtil.getFarmCode(),
      }
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.rowNode !== this.props.rowNode) {
      let { rowNode } = this.props;
      console.log(rowNode);
      this.setState(
        produce(draft => {
          draft.params['hhId'] = rowNode['hhId'];
          draft.params['hhType'] = rowNode['hhType'];
          draft.params['capacity'] = rowNode['capacity'];
          draft.params['hhFloorType'] = rowNode['hhFloorType'];
          draft.params['width'] = rowNode['width'];
          draft.params['length'] = rowNode['length'];
          draft.params['nestType'] = rowNode['nestType'];
          draft.params['nestQty'] = rowNode['nestQty'];
          draft.params['fanExist'] = rowNode['fanExist'];
          draft.params['coolingPadExist'] = rowNode['coolingPadExist'];
          draft.params['feederType'] = rowNode['feederType'];
          draft.params['drinkerType'] = rowNode['drinkerType'];
          draft.params['inuse'] = rowNode['inuse'];
          draft.params['flockId'] = rowNode['flockId'];
          draft.params['deleted'] = rowNode['deleted'];
        }),
        function() {
          // console.log('changed params : ', this.state.params);
        }
      );
    }
  }

  onChangeParam = e => {
    const { name, value } = e.target;
    this.setState(
      produce(draft => {
        draft.params[name] = value;
      })
    );
  };

  render() {
    if (!this.props.visable) return null;
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <div className="con_box">
          <h3>
            <Trans>Breeding HenHouse Info</Trans>
          </h3>

          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>HenHouse Id</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'hhId',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>HenHouse Type</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'hhType',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Capacity</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'capacity',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>HenHouse Floor Type</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'hhFloorType',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Width</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'width',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Length</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'length',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Nest Type</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'nestType',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Nest Qty</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'customerCode',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Fan Exist</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'fanExist',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Cooling Pad Exist</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'coolingPadExist',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Feeder Type</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'feederType',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Drinker Type</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'drinkerType',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                {/*
                <tr>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td colSpan="3">
                    {FormUtil.renderInput({
                      inputName: 'remarks',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      className: 'w-80'
                    })}
                  </td>
                </tr>
                */}
                <tr>
                  {/* 2020.03.25 by 장태학에 의해 컬럼 삭제됨
                  <th>
                    <Trans>In Use</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'inuse',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                */}
                  <th>
                    <Trans>Deleted</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'deleted',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
