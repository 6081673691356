import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import {
  AgGridReact,
  AllModules,
  isRowSelected
} from 'components/commons/ag-grid/AgGridUtil';

import { moment } from 'components';

import i18n from 'i18next';

import '../Hatchery.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class HECutting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          rowData: [],
          columnDefs: [
            {
              headerName: 'Recieve Date',
              field: 'recieveDate',
              width: 150,
              cellStyle: { textAlign: 'center' }
            },
            {
              headerName: 'Recieve No',
              field: 'recieveNo',
              width: 120,
              cellStyle: { textAlign: 'center' }
            },
            {
              headerName: 'Flock',
              field: 'flock',
              width: 120,
              cellStyle: { textAlign: 'center' }
            },
            {
              headerName: 'HH',
              field: 'hh',
              width: 120,
              cellStyle: { textAlign: 'center' }
            },
            {
              headerName: 'Strain',
              field: 'strain',
              width: 120,
              cellStyle: { textAlign: 'center' }
            },
            {
              headerName: 'HE',
              field: 'he',
              type: 'numericColumn',
              width: 100,
              valueFormatter: this.formatNumber,
              cellStyle: { textAlign: 'center' }
            },
            {
              headerName: 'Cutting',
              field: 'cutting',
              type: 'numericColumn',
              width: 100,
              editable: true,
              valueFormatter: this.formatNumber,
              cellStyle: { textAlign: 'center' },
              cellRenderer: params => {
                return '<text style="color: #000;width:60;"></text>';
              }
            }
          ],
          defaultColDef: {
            editable: true,
            sortable: true,
            resizable: true,
            headerComponentParams: {
              menuIcon: 'fa-bars',
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
                '  </div>' +
                '</div>'
            }
          }
        };
      }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  render() {
    if ( !this.props.heCutting) return null;
    return (
      <Fragment>
        {/* 디자인 영역  */}
        <div
          style={{ height: '200px', width: '100%' }}
          className="board_list ag-theme-balham"
        >
          <AgGridReact
            columnDefs={this.state.columnDefs}
            rowData={this.props.rowData}
            defaultColDef={this.state.defaultColDef}
          ></AgGridReact>
        </div>    
        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default HECutting
