import React, { Fragment } from 'react';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';
import scrollToComponent from 'react-scroll-to-component';

import { moment } from 'components';
import HatchingDiscardForm from './HatchingDiscardForm';
import HatchingListForDiscard from './HatchingListForDiscard';
import HatchingDiscardHistLIst from './HatchingDiscardHistLIst';
import '../Hatchery.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class HatchingDiscard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      HatchingDiscardFormView: false,
      rowData: [],
      regDate: DateUtil.toDateTimeString(),
      iuFlag: 'I',
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  fnClickGrade = (data) => {
    console.log(data);
    this.setState(
      { HatchingDiscardFormView: true, rowData: data, iuFlag: 'I' },
      function () {
        scrollToComponent(this['HatchingDiscardFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };

  fnSetRegDate = () => {
    this.setState({
      regDate: DateUtil.toDateTimeString(),
      HatchingDiscardFormView: false,
    });
  };

  fnClickModify = (data) => {
    this.setState(
      {
        rowData: data,
        HatchingDiscardFormView: true,
        iuFlag: 'U',
      },
      function () {
        scrollToComponent(this['HatchingDiscardFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );

    if (data.spQty) {
      this.setState({
        rowData: {
          ...this.state.rowData,
          psdocFQty: data.psdocFQty,
          psdocMQty: data.psdocMQty,
          spQty: data.spQty,
          a2Qty: data.a2Qty,
          a1Qty: data.a1Qty,
          bQty: data.bQty,
          bmQty: data.bmQty,
          polosQty: data.polosQty,
          sexQty: data.sexQty,
          discardQtyInput: data.total,
        },
      });
    }
  };

  fnClickCancel = () => {
    this.setState({ HatchingDiscardFormView: false }, function () {});
  };

  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Hatching Discard</Trans>
        </h2>

        <HatchingListForDiscard
          fnClickGrade={this.fnClickGrade}
          regDate={this.state.regDate}
        />

        <HatchingDiscardForm
          ref={(section) => {
            this.HatchingDiscardFormView = section;
          }}
          HatchingDiscardFormView={this.state.HatchingDiscardFormView}
          rowData={this.state.rowData}
          fnSetRegDate={this.fnSetRegDate}
          iuFlag={this.state.iuFlag}
          fnClickCancel={this.fnClickCancel}
        />

        <HatchingDiscardHistLIst
          regDate={this.state.regDate}
          fnClickModify={this.fnClickModify}
          fnSetRegDate={this.fnSetRegDate}
        />

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
