import React, { Fragment } from 'react';
import { withAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import { SessionUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { Const, Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';
import NumberFormat from 'react-number-format';
import { moment } from 'components';

import i18n from 'i18next';

import '../Hatchery.module.css';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 ** 4. colSpan => conSpan, rowSpan => rowSpan 입니다.
 */
class PopUpDiscard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: [],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let that = this;
    if (prevProps.discardInfo !== this.props.discardInfo) {
      this.setState(
        {
          form: [],
          tot: 0,
        },
        function () {}
      );
    }
  }

  fnClickSave = () => {
    let that = this;
    let data = this.state.form;

    console.log(this.props.discardInfo);

    data.hatcheryId = this.props.discardInfo.hatcheryId;
    data.eggReceiveNo = this.props.discardInfo.eggReceiveNo;

    console.log('qty', data.discardQty);
    console.log('rem', data.discardRem);
    console.log('state', this.state);

    if (!data.discardQty || data.discardQty === '0') {
      that.props.alert.show(
        i18n.t('Please enter Discard Qty.') // 폐기 수량을 입력하세요
      );
      that.discardQty.focus();
      return false;
    }

    if (!data.discardRem) {
      that.props.alert.show(
        i18n.t('Please enter Discard Rem.') // 폐기 이유를 입력하세요
      );
      that.discardRem.focus();
      return false;
    }

    if (data.discardQty > this.props.discardInfo.noneSettingQty) {
      that.props.alert.show(
        i18n.t('The number of possible discard quantity has been exceeded.')
      ); // 폐기 가능한 수량을 초과하였습니다
      that.discardQty.focus();
      return false;
    }

    data.hatchingEggQty =
      Number(this.props.discardInfo.hatchingEggQty) -
      Number(this.state.form.discardQty);

    data.noneSettingQty =
      Number(this.props.discardInfo.noneSettingQty) -
      Number(this.state.form.discardQty);

    console.log('data', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/setting/discardNoneSettingEgg.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.fnSaveFromPupUpDiscard();
        that.props.alert.show(i18n.t('Discarded')); // 폐기 완료
      })
      .catch(function (error) {
        that.props.alert.show(i18n.t('Discard failed')); // 폐기 실패
        return [];
      });
  };

  fnCancel = () => {
    this.props.fnCancelFromPupUpDiscard();
  };

  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  renderInput = (inputName, ty, dec, len) => {
    let cl = len ? 'input_type1 w' + len : 'input_type1';
    let that = this;

    if (ty && ty === 'N') {
      cl = len ? 'input_base w' + len : 'input_base';
      return (
        <NumberFormat
          style={{ textAlign: 'right' }}
          className="input_type1"
          thousandSeparator={true}
          decimalScale={dec ? dec : 0}
          name={inputName}
          //ref={this[inputName]}
          getInputRef={(el) => (this[inputName] = el)}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : 0
          }
          onChange={(e) => this.setOnChange(e, ty)}
        />
      );
    } else {
      return (
        <input
          type="text"
          name={inputName}
          className="input_type1"
          ref={this[inputName]}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : ''
          }
          onChange={(e) => this.setOnChange(e)}
        />
      );
    }
  };

  setOnChange = (e, params, dateString) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';

    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    this.setState(
      {
        form: {
          ...this.state.form,
          [col]: val,
        },
      },
      function () {}
    );
  };

  render() {
    if (!this.props.popUpDiscardView) return null;
    return (
      <Fragment>
        {/* 디자인 영역 - setting 메뉴 레이어 팝업 */}
        <div className="layer_popup">
          <div className="popup_type1">
            <div className="board_list2">
              <table>
                <colgroup>
                  <col style={{ width: '30%' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <Trans>Discard Qty</Trans> <span className="red">*</span>{' '}
                    </th>
                    <td>
                      {GridUtil.renderInput({
                        inputName: 'discardQty', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        minVal: 0,
                        maxVal: this.props.discardInfo.noneSettingQty,
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Trans>Discard Reason</Trans>{' '}
                      <span className="red">*</span>{' '}
                    </th>
                    <td>
                      {GridUtil.renderInput({
                        inputName: 'discardRem', //*** */
                        type: '', //*** */
                        //dec: 2,
                        maxLength: 1000,
                        //maxVal: 999.99,
                        //width: 200, // px제외 : 없으면 30 */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        that: this, //*** */
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="txt_center mT40">
                <Link to="#">
                  <button className="btn_gray" onClick={(e) => this.fnCancel()}>
                    <Trans>Cancel</Trans>
                  </button>
                </Link>
                <Link to="#">
                  <button
                    className="btn_save"
                    onClick={(e) => this.fnClickSave()}
                  >
                    <Trans>Save</Trans>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default withAlert()(PopUpDiscard);
