import React from 'react';
import { AgGridReact } from 'ag-grid-react';
// import { AllModules } from '@ag-grid-enterprise/all-modules';
import 'ag-grid-enterprise';

export default class AgGridTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      excelName: 'AgGridTest',
      ColDefs: [
        {
          headerName: '',
          children: [
            {
              headerName: 'Division',
              field: 'division',
              pinned: 'left',
              minWidth: 100,
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: 'Region',
              field: 'region',
              pinned: 'left',
              minWidth: 100,
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: 'Project',
              field: 'project',
              pinned: 'left',
              minWidth: 240,
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: 'Chick in Qty',
              field: 'chickInQty',
              pinned: 'left',
              minWidth: 100,
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: 'Age',
              field: 'age',
              pinned: 'left',
            },
          ],
        },
        {
          headerName: 'Chick out',
          children: [
            {
              headerName: 'Qty',
              field: 'cQty',
            },
            {
              headerName: 'Kg',
              field: 'cKg',
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: 'Population',
              field: 'population',
            },
          ],
        },
        {
          headerName: 'Depletion',
          children: [
            {
              headerName: 'Act',
              field: 'dAct',
            },
            {
              headerName: 'Std',
              field: 'dStd',
              width: 80,
              minWidth: 80,
            },
          ],
        },
        {
          headerName: 'Point Feed',
          children: [
            {
              headerName: 'Act',
              field: 'pAct',
            },
            {
              headerName: 'Std',
              field: 'pStd',
            },
          ],
        },
        {
          headerName: 'Body Weight',
          children: [
            {
              headerName: 'Act',
              field: 'bAct',
            },
            {
              headerName: 'Std',
              field: 'bStd',
            },
          ],
        },
        {
          headerName: 'FCR',
          children: [
            {
              headerName: 'Act',
              field: 'fAct',
            },
            {
              headerName: 'Std',
              field: 'fStd',
            },
          ],
        },
        {
          headerName: 'IP',
          children: [
            {
              headerName: 'Act',
              field: 'iAct',
            },
            {
              headerName: 'Std',
              field: 'iStd',
            },
          ],
        },
      ],
      gridHeight: '365px',
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        width: 80,
        minWidth: 80,
        cellClass: 'text-center',
        suppressSizeToFit: false,
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      // enableCellChangeFlash: true,
      rowSelection: 'single',
      pagenation: false,
      paginationPageSize: 10,
      // excelStyles: [ { id: 'textFormat', font: { size: 12 }, }, ],
    };
  }

  componentDidMount() {}

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  onBtExport = () => {
    const fileName = 'AgGridTest';
    const sheetName = 'AgGridTestSheet';
    const params = {
      allColumns: false,
      columnGroups: true,
      fileName: fileName,
      sheetName: sheetName,
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  render() {
    return (
      <React.Fragment>
        <h3>AgGridTest</h3>
        <div>
          <button className="primary" onClick={this.onBtExport}>
            Excel Export
          </button>
        </div>
        <div
          id="myGrid"
          style={{
            height: '350px',
            width: '100%',
          }}
          className="ag-theme-balham"
        >
          <AgGridReact
            // modules={AllModules}
            columnDefs={this.state.ColDefs}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            // excelStyles={this.state.excelStyles}
            onGridReady={this.onGridReady}
            pagination={false}
          />
        </div>
      </React.Fragment>
    );
  }
}
