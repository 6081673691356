import React, { Fragment, Component } from 'react';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';
import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import ReceiveHatcheryItemList from 'components/mobile/receive/popup/ReceiveHatcheryItemList';
import MobileHeader from 'components/mobile/header/MobileHeader';
import { observer, inject } from 'mobx-react';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 ** 4. 파일명과 클래스명을 동일하게 해 주세요.
 */

@inject((stores) => ({
  mStore: stores.MobileStore,
  mRcvStore: stores.MRcvStore,
  mProjectStore: stores.MprojectStore,
}))
@observer
class BroilerRcvSelectHatchery extends Component {
  componentDidMount() {
    this.fnSelectTransferList();
  }

  /*
    입력단계 상태값 관리
    입력폼의 헤더 구분을 위한 상태관리
  */
  setPopupStepNextTrue = () => {
    const { mStore } = this.props;
    mStore.setPopupStepNextTrue();
  };

  //입식정보 찾기
  fnSelectTransferList = () => {
    const { mRcvStore } = this.props;
    let params = {};
    params.farmCode = SessionUtil.getFarmCode();
    params.dateFormat = SessionUtil.getDateFormat();
    params.received = 'N';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/mobile/broiler/mSelectTransferList.do',
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log(response.data.rows1);
        mRcvStore.setRcvTransferList(response.data.rows1);
      })
      .catch(function (error) {
        return [];
      });
  };

  // 입식 정보 선택 및 다음 단계 진행
  fnSetTransferList = (value) => {
    const { mRcvStore } = this.props;
    sessionStorage.setItem('SelectedTransferList', JSON.stringify(value));
    mRcvStore.setSelectedTransferList(value);
    mRcvStore.setRecvQty(value.transferQty);
    this.props.history.push('/mobile/broiler/rcv/hatchery');
  };

  fnPushBack = () => {
    this.props.history.push(Const.MOBILE_LOGINED_PAGE);
  };

  render() {
    const { mStore, mRcvStore } = this.props;
    return (
      <Fragment>
        {mStore.headerYn == false ? '' : <MobileHeader mStore={mStore} />}
        <div className="pop_header">
          <div className="mback" onClick={(e) => this.fnPushBack()}>
            <Trans>back</Trans>
          </div>
          [{sessionStorage.getItem('projCode')}]
        </div>

        <div className="mobile_content_nofooter">
          <ReceiveHatcheryItemList
            hatcheryList={mRcvStore.rcvTransferList}
            setPopupStepNextTrue={this.setPopupStepNextTrue}
            fnSetTransferList={this.fnSetTransferList}
          />
        </div>
      </Fragment>
    );
  }
}

export default BroilerRcvSelectHatchery;
