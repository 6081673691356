import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import EggTransferForm from './EggTransferForm';
import EggTransferList from './EggTransferList';
import scrollToComponent from 'react-scroll-to-component';
import '../Breeding.module.css';

/*
 ** Breeding > Deliver > Egg Transfer
 **
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class DeliverEggTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInstance: this,
      eggSalesListView: false,
      transferFormView: true,
      epDate: DateUtil.today(),
      form: {},
      salesForm: [],
      transferForm: [],
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnClickTransfer();
  }
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps !== this.props) {
  //     this.fnSearch();
  //   }
  // }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   console.log('getDerivedStateFromProps', prevState, nextProps);
  //   if (this.props !== nextProps) {
  //     prevState.currentInstance.fnSearch();
  //     return null;
  //   }
  //   return null;
  // }
  fnSearch = () => {
    const that = this;
    //window.scrollTo(0, 0);
    this.setState(
      {
        transferFormView: false,
        //transferForm: [],
        eggSalesListView: false,
      },
      function () {
        that.fnClickTransfer();
      }
    );
  };

  fnClickTransfer = (e) => {
    this.setState(
      {
        eggSalesListView: true,
        transferFormView: true,
        // transferForm: {
        //   //...this.state.transferForm,
        //   iuFlag: 'I',
        //   transferType: 'T',
        //   transferDate: DateUtil.today()
        // }
      },
      function () {
        scrollToComponent(this['transferFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };

  fnClosePopup = (val) => {
    window.scrollTo(0, 0);
    this.setState({ [val]: false }, function () {});
  };
  render() {
    return (
      <Fragment>
        <h2>
          <Trans>Deliver - Egg Transfer</Trans>
        </h2>

        <div className="con_box">
          {this.state.transferFormView ? (
            <EggTransferForm
              ref={(section) => {
                this.transferFormView = section;
              }}
              fnSearch={this.fnSearch}
            />
          ) : null}
          {this.state.eggSalesListView ? (
            <EggTransferList
              eggSalesListView={this.state.eggSalesListView}
              fnSearch={this.fnSearch} //삭제기능 후 다시 조회
            />
          ) : null}
        </div>
      </Fragment>
    );
  }
}
