import React, { Fragment } from 'react';
import { SessionUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import '../Breeding.module.css';
/*
 ** Breeding > Move > Move In : Move out List
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class MoveoutList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      prop: {},
      rowData: [],
      moveoutList: [],
      columnDefs: [
        {
          headerName: '',
          field: 'ck',
          width: 90,
          cellStyle: { textAlign: 'center' },
          cellRenderer: (params) => {
            return (
              '<button class="btn_sel_orange">' +
              i18n.t('Move In') +
              '</button>'
            );
          },

          suppressSizeToFit: true,
        },
        {
          headerName: 'Farm',
          field: 'farmName',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Move Out No',
          field: 'transferNo',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Move Out Date',
          field: 'transferDate',
          width: 130,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Move Out(F)',
          field: 'moveQtyF',
          width: 120,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          //precision: 0,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Move Out(M)',
          field: 'moveQtyM',
          width: 120,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          //precision: 0,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Strain',
          field: 'strainName',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Chick In Date',
          field: 'chickinDate',
          width: 130,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Order No',
          field: 'orderNo',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Truck No',
          field: 'TruckNo',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: false,
        },
      ],
      defaultColDef: {
        editable: true,
        sortable: true,
        resizable: true,
        suppressSizeToFit: false,
      },
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    //this.fnSearch();
  }

  onCellClicked = (e) => {
    if (e.column.colId !== 'ck') return false;

    this.props.fnClickListMovein(e.data);
  };

  // fnClickListMovein = (row) => {
  //   this.props.fnClickListMovein(row, row);
  // };
  fnClickListCancel = (row) => {
    //this.props.fnClickListCancel(row);
    this.props.fnInit();
  };
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  flockButton = (row) => {
    //console.log('flockButton===>', row);
    //if (this.props.flockListFlockId === row.flockId) {
    if (this.props.flockListTransferNo === row.transferNo) {
      return (
        <dd>
          <button
            className="btn_gray2"
            onClick={(e) => this.fnClickListCancel(row)}
          >
            <Trans>Cancel</Trans>
          </button>
        </dd>
      );
    } else if (this.props.flockListTransferNo) {
      return;
    } else {
      return (
        <dd>
          <button
            className="btn_orange"
            onClick={(e) => this.fnClickListMovein(row)}
          >
            <Trans>Move In</Trans>
          </button>
        </dd>
      );
    }
  };
  render() {
    // let moveoutList = [];
    // if (this.props.moveoutList && this.props.moveoutList.length > 0) {
    //   moveoutList = this.props.moveoutList.map((row, i) => {
    //     return (
    //       <div key={i} className="full">
    //         <dl className="reinfo">
    //           <dt className="color1">{row.flockId}</dt>
    //           <dd>
    //             <Trans>Transfer : </Trans>
    //             {row.transferDate}
    //           </dd>
    //           <dd>
    //             <Trans>Strain : {row.strainName}</Trans>
    //           </dd>
    //           <dd>
    //             <Trans>F</Trans> :{' '}
    //             {row.moveQtyF ? row.moveQtyF.toLocaleString() : '0'}
    //             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    //             <Trans>M</Trans> :{' '}
    //             {row.moveQtyM ? row.moveQtyM.toLocaleString() : '0'}
    //           </dd>
    //           {this.flockButton(row)}
    //         </dl>
    //       </div>
    //     );
    //   });
    // }
    return (
      <Fragment>
        <div
          style={{ height: '250px', width: '100%' }}
          className="board_list ag-theme-balham mT10 mB50"
        >
          <h3>
            <Trans>Receive from another Farm</Trans>
          </h3>
          <AgGridReact
            onGridReady={this.onGridReady}
            columnDefs={this.state.columnDefs}
            rowData={this.props.moveoutList}
            defaultColDef={this.state.defaultColDef}
            onCellClicked={(e) => this.onCellClicked(e)}
            onCellFocused={(e) => {
              e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
            }}
          ></AgGridReact>
        </div>
      </Fragment>
    );
  }
}
export default MoveoutList;
