import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';

// For Design
import '../Common.module.css';

// For Antd ==================================
import { Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';
import { remove } from 'lodash';
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Icon,
  Button,
  Checkbox,
  Select,
} from 'antd';
const { Option } = Select;

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class RegisterMemberForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      group: [],
    };
  }

  componentDidMount() {
    this.fnSelectGroup();
  }

  // 사용자 그룹정보를 생성/조회/폼리셋 합니다.
  fnInsertMember = (data) => {
    const that = this;
    let url = Server.getRestAPI() + '/users/insertMember.do';
    axios({
      // headers: { Pragma: 'no-cache' },
      method: 'post',
      url: url,
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        if (response.data) {
          console.log('call: that.props.fnSelectMember()');
          that.props.fnSelectMember();
        }
      })
      .catch(function (error) {
        console.error('#ERROR# ', error);
        return [];
      });
  };

  // 사용자 그룹 조회
  fnSelectGroup = (data) => {
    const that = this;
    let url = Server.getRestAPI() + '/users/selectGroup.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        if (response.data) {
          let result = [];
          response.data.map((row, i) => {
            let item = {};
            item['group_id'] = row['user_group_id'];
            item['group_nm'] = row['user_group_name'];
            result.push(item);
          });
          that.setState({ group: result }, function () {
            console.log('group : ', this.state.group);
          });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.props.form.values);
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.fnInsertMember(values, this);
      }
    });
  };

  // rowSelection object indicates the need for row selection
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return (
      <React.Fragment>
        {/* 디자인 영역 */}
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          className="group-form"
        >
          <Form.Item label="Id" hasFeedback>
            {getFieldDecorator('member_id', {
              rules: [
                { required: true, message: i18n.t('Please input user id!') },
              ],
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder={i18n.t('USER ID')}
              />
            )}
          </Form.Item>
          <Form.Item label="Name" hasFeedback>
            {getFieldDecorator('member_nm', {
              rules: [
                {
                  required: true,
                  message: i18n.t('Please input user name!'),
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder={i18n.t('USER NAME')}
              />
            )}
          </Form.Item>
          <Form.Item label="Password" hasFeedback>
            {getFieldDecorator('member_pw', {
              rules: [
                {
                  required: true,
                  message: 'Please input user password!',
                },
              ],
            })(
              <Input.Password
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder={i18n.t('Password')}
              />
            )}
          </Form.Item>
          <Form.Item label="Group" hasFeedback>
            {getFieldDecorator('group_id', {
              rules: [{ required: true, message: 'Please select your group!' }],
            })(
              <Select placeholder="Please select user group">
                <Option key="" value=""></Option>
                {this.state.group.map((row, i) => (
                  <Option key={i} value={row.group_id}>
                    {row.group_nm}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 8 },
            }}
          >
            <Button type="primary" htmlType="submit" className="reg-button">
              <Trans>REGISTER</Trans>
            </Button>
          </Form.Item>
        </Form>
        {/* 디자인 영역 */}
      </React.Fragment>
    );
  }
}

// 호출하는 쪽에서 HOC로 정의합니다.
// const RegisterForm = Form.create({ name: 'member_regist' })(RegisterMemberForm);
