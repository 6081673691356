import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, GridUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';
import { withAlert } from 'react-alert';

import { moment } from 'components';

import i18n from 'i18next';

import { Radio } from 'antd';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { message } from 'antd';

import '../Hatchery.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class GradeDiscardHistList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFromWkDt: moment(DateUtil.today())
        .add('-1', 'M')
        .format('YYYY-MM-DD'),
      searchToWkDt: DateUtil.today(),
      rowData: [],
      gridHeight: '250px',
      rowSelection: 'single',
      columnDefs: [
        {
          headerName: i18n.t('Farm'),
          field: 'farmName',
          suppressSizeToFit: false,
          width: 220,
          cellStyle: { textAlign: 'left' },
        },
        {
          headerName: i18n.t('Flock'),
          field: 'flockId',
          suppressSizeToFit: false,
          width: 200,
          cellStyle: { textAlign: 'left' },
          editable: false,
        },
        {
          headerName: i18n.t('Hen House'),
          field: 'hhId',
          suppressSizeToFit: false,
          width: 200,
          cellStyle: { textAlign: 'center' },
          editable: false,
        },
        {
          headerName: i18n.t('Strain'),
          field: 'strain',
          suppressSizeToFit: true,
          width: 200,
          cellStyle: { textAlign: 'center' },
          editable: false,
        },
        {
          headerName: i18n.t('Egg Receive No'),
          field: 'eggReceiveNo',
          suppressSizeToFit: false,
          width: 200,
          editable: false,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: i18n.t('Receive Date'),
          field: 'receiveDate',
          suppressSizeToFit: false,
          width: 240,
          editable: false,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: i18n.t('Grade Date'),
          field: 'gradingDate',
          suppressSizeToFit: true,
          width: 220,
          cellStyle: { textAlign: 'center' },
          editable: false,
        },
        {
          headerName: i18n.t('Discard Date'),
          field: 'discardDate',
          suppressSizeToFit: true,
          width: 200,
          editable: false,
          cellStyle: { textAlign: 'center' },
        },

        {
          headerName: i18n.t('Discard Qty'),
          field: 'hatchingEggQty',
          suppressSizeToFit: false,
          width: 200,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          editable: false,
        },

        {
          headerName: '',
          field: 'delete',
          suppressSizeToFit: false,
          width: 130,
          cellStyle: { textAlign: 'center' },
          editable: false,
          cellRenderer: (params) => {
            return '<button class="btn_sel">' + i18n.t('Delete') + '</button>';
          },
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    this.fnSearch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.regDate !== this.props.regDate) {
      this.fnSearch();
    }
  }

  fnSearch = () => {
    let that = this;
    let data = {};

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.countryCode = SessionUtil.getCountryCode();
    data.searchFromWkDt = this.state.searchFromWkDt;
    data.searchToWkDt = this.state.searchToWkDt;

    if (data.searchFromWkDt > data.searchToWkDt) {
      that.props.alert.show(i18n.t('Please check the date.'));
      that.searchFromWkDt.focus();
      return false;
    }

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/grade/selectGradingDiscardHist.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('res', response.data.rows1);
        that.setState({ rowData: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  onCellClicked = (e) => {
    if (e.column.colId != 'delete') {
      this.props.fnClickModify(e.data);
    }
    if (e.column.colId === 'delete') {
      let that = this;
      let data = e.data;

      data.farmCode = SessionUtil.getFarmCode();
      data.dateFormat = SessionUtil.getDateFormat();
      data.hatcheryId = SessionUtil.getHatcheryId();

      console.log('data', data);

      const url = Server.getRestAPI();
      axios({
        method: 'post',
        url: url + '/hatchery/grade/deleteGradeDiscard.do',
        data: qs.stringify(data),
        withCredentials: true,
        credentials: 'same-origin',
      })
        .then(function (response) {
          that.props.alert.show(i18n.t('Deleted'));
          that.props.fnSetRegDate();
          that.fnSearch();
        })
        .catch(function (error) {
          that.props.alert.show(i18n.t('Delete failed'));
          console.log(error);
          return [];
        });
    }
  };

  setOnChange = (e, params) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    that.setState({ [col]: val }, function () {});
  };

  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();

    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      params.api.setRowData(data.slice(0, 100));
    };
  };

  render() {
    // return null 은 아무것도 표시하지 않는다는 의미입니다.
    // if (!this.props.localeListView) return null;
    return (
      <Fragment>
        {/* 디자인 영역 */}

        <div className="con_box">
          <h3>
            <Trans>Hatching Egg Discard List</Trans>
          </h3>
          <div
            id="myGrid"
            style={{
              height: this.state.gridHeight,
              width: '100%',
              marginBottom: '30px',
            }}
            className="ag-theme-balham"
          >
            {GridUtil.renderInput({
              inputName: 'searchFromWkDt', //*** */
              type: 'D', //*** */
              that: this, //*** */
              statsForm: this.state, //*** */
              setOnChange: this.setOnChange, //*** */
              width: 120, // px제외 : 없으면 100% */
              placeholder: 'Date',
            })}
            &nbsp; ~ &nbsp;
            {GridUtil.renderInput({
              inputName: 'searchToWkDt', //*** */
              type: 'D', //*** */
              that: this, //*** */
              statsForm: this.state, //*** */
              setOnChange: this.setOnChange, //*** */
              width: 120, // px제외 : 없으면 100% */
              placeholder: 'Date',
            })}
            <button className="btn_gray" onClick={() => this.fnSearch()}>
              <Trans>Search</Trans>
            </button>
            <AgGridReact
              //onGridReady={e => this.onGridReady(e, 'gridApiSettingInfoS')}
              columnDefs={this.state.columnDefs}
              rowData={this.state.rowData}
              rowSelection={this.state.rowSelection}
              onCellClicked={(e) => this.onCellClicked(e)}
              paginationAutoPageSize={true}
              pagination={true}
            ></AgGridReact>
          </div>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default withAlert()(GradeDiscardHistList);
