import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, ObjectUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import {
  Select,
  Radio,
  Button,
  Icon,
  DatePicker,
  Checkbox,
  Row,
  Col,
} from 'antd';
import SearchInput from 'utils/SearchInput';

import '../Report.module.css';

/*
 ** [공통 개발시 유의 사항 입니다]
 ** 1. 변수명은 camelCase 로 작성하시길 바랍니다.
 ** 2. 다국어 표현은 <Trans>글자</Trans> 또는 i18n.t('글자')로 부탁 드립니다.
 */
export default class FHCheckboxTotalSearch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      disables: {
        flockId: true,
        hhId: true,
        checkBox: true,
        submit: true,
      },
      params: {
        farmCode: undefined,
        flockId: undefined,
        hhId: undefined,
        checkBox: [
          'Dep.Cum',
          'EP',
          'HE.Cum',
          'SC.Cum',
          'BW.F',
          'BW.M',
          'Feed.F',
          'Feed.M',
          'Egg.Weight',
          'Hatch',
        ],
      },
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // 1. farmCode는 필수로 선택되어야 합니다.
    // - session의 farmCode를 사용하지 않습니다.
    if (prevState.params.farmCode !== this.state.params.farmCode) {
      const flag = _.isEmpty(this.state.params.farmCode);
      this.setState(
        produce((draft) => {
          draft.disables['flockId'] = flag;
          draft.disables['checkBox'] = true;
          draft.disables['submit'] = true;
        }),
        function () {
          // that.props.setCode(that.props.name, value);
          const { params } = this.state;
          console.log(params);
        }
      );
    }

    // 2. flockId or hhId를 선택하여야만 날짜와 등록버튼이 활성화 됩니다.
    if (prevState.params.flockId !== this.state.params.flockId) {
      const flag = _.isEmpty(this.state.params.flockId);
      this.setState(
        produce((draft) => {
          draft.disables['hhId'] = flag;
          draft.disables['checkBox'] = flag;
          draft.disables['submit'] = flag;
        }),
        function () {}
      );
    }
  }

  // 보고서 검색 파라미터 전달 방식 통일 (농장/계군/계사) 선택시
  onBtSearch = () => {
    const { params } = this.state;
    let parameters = _.cloneDeep(params);
    // console.log(params, ObjectUtil.getClearnedObject(parameters));
    this.props.onSearch(ObjectUtil.getClearnedObject(parameters));
  };

  onChangeRadio = (e) => {
    const that = this;
    const { name, value } = e.target;
    console.log(name, value);
    this.setState(
      produce((draft) => {
        draft.params['type'] = value;
        draft.params['value'] = undefined; // flock, henHouse 변경시마다 초기화 되어야 합니다.
      }),
      function () {}
    );
  };

  onChangeSelect = (value) => {
    console.log(value);
    this.setState(
      produce((draft) => {
        draft.params['value'] = value;
      }),
      function () {
        // const { params } = this.state;
        // console.log(params);
      }
    );
  };

  onChangeCheckbox = (values) => {
    const that = this;
    // const { name, value } = e.target;
    this.setState(
      produce((draft) => {
        draft.params['checkBox'] = values;
      }),
      function () {
        const { params } = this.state;
        console.log(params);
      }
    );
  };

  setCode = (name, value) => {
    this.setState(
      produce((draft) => {
        draft.params[name] = value;
      }),
      function () {}
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="f_left langsel">
          <SearchInput
            placeholder={i18n.t('farmCode')}
            style={{
              width: 200,
              marginRight: 20,
              // textTransform: 'uppercase'
            }}
            inputParam="farmCode" // SelectInput의 분류
            inputName="farmCode" // member variable of this.state.params
            inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
            // farmClass="A"
            defaultValue={this.state.params.farmCode}
            getValue={(value) => this.setCode('farmCode', value)} //***** */
            that={this} //***** */
          />
          <SearchInput
            placeholder={i18n.t('flockId')}
            style={{
              width: 200,
              marginRight: 0,
              // textTransform: 'uppercase'
            }}
            inputParam="flockId" // SelectInput의 분류
            inputName="flockId" // member variable of this.state.params
            inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
            // farmClass="A"
            pValue0={this.state.params.farmCode}
            defaultValue={this.state.params.flockId}
            getValue={(value) => this.setCode('flockId', value)} //***** */
            that={this} //***** */
            isDisabled={this.state.disables.flockId}
          />
          <SearchInput
            placeholder={i18n.t('hhId')}
            style={{
              width: 200,
              marginRight: 20,
              // textTransform: 'uppercase'
            }}
            inputParam="hhId" // SelectInput의 분류
            inputName="hhId" // member variable of this.state.params
            inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
            // farmClass="A"
            pValue0={this.state.params.farmCode}
            pValue1={this.state.params.flockId}
            defaultValue={this.state.params.hhId}
            getValue={(value) => this.setCode('hhId', value)} //***** */
            that={this} //***** */
            isDisabled={this.state.disables.hhId}
          />
        </div>
        <div className="f_left mT10 mB10 langsel both">
          <Checkbox.Group
            disabled={this.state.disables.checkBox}
            onChange={this.onChangeCheckbox}
            value={this.state.params.checkBox}
          >
            <Row>
              <Checkbox value="Dep.Cum">Dep.Cum</Checkbox>
              <Checkbox value="EP">EP</Checkbox>
              <Checkbox value="HE.Cum">HE.Cum</Checkbox>
              <Checkbox value="SC.Cum">SC.Cum</Checkbox>
              <Checkbox value="BW.F">BW.F</Checkbox>
              <Checkbox value="BW.M">BW.M</Checkbox>
              <Checkbox value="Feed.F">Feed.F</Checkbox>
              <Checkbox value="Feed.M">Feed.M</Checkbox>
              <Checkbox value="Egg.Weight">Egg.Weight</Checkbox>
              <Checkbox value="Hatch">Hatch</Checkbox>
            </Row>
          </Checkbox.Group>
        </div>
        <div className="f_left mT0 mB10 langsel">
          <button
            className={this.state.disables.submit ? 'btn_gray' : 'btn_green'}
            disabled={this.state.disables.submit}
            onClick={this.onBtSearch}
          >
            <Trans>Search</Trans>
          </button>
        </div>
      </React.Fragment>
    );
  }
}
