import React, { Component, Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import { moment } from 'components';
import i18n from 'i18next';
import { Modal, Button } from 'antd';
import { withAlert } from 'react-alert';

import '../Breeding.module.css';
const { confirm } = Modal;

/*
 ** breeding/receive/MovingLog
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class MoveinListGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      columnDefs: [
        {
          headerName: 'No',
          field: 'transferNo',
          width: 60,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Move Data',
          field: 'moveDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Flcok Id',
          field: 'flockId',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Hen House',
          field: 'hhId',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Move Qty(F)',
          field: 'moveQtyF',
          type: 'numericColumn',
          width: 120,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Move Qty(M)',
          field: 'moveQtyM',
          type: 'numericColumn',
          width: 120,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Remarks',
          field: 'remarks',
          width: 200,
          cellStyle: { textAlign: 'left' },
        },

        // {
        //   headerName: 'seq',
        //   field: 'seqNo',
        //   width: 20,
        //   cellStyle: { textAlign: 'center' },
        //   suppressSizeToFit: true,
        // },
        // {
        //   headerName: 'Cnt',
        //   field: 'histCnt',
        //   width: 20,
        //   cellStyle: { textAlign: 'center' },
        //   suppressSizeToFit: true,
        // },
        // {
        //   headerName: 'chickinQtyF',
        //   field: 'chickinQtyF',
        //   width: 100,
        //   cellStyle: { textAlign: 'center' },
        //   suppressSizeToFit: true,
        // },
        // {
        //   headerName: 'chickinQtyM',
        //   field: 'chickinQtyM',
        //   width: 100,
        //   cellStyle: { textAlign: 'center' },
        //   suppressSizeToFit: true,
        // },
        {
          headerName: '',
          field: 'iuFlag',
          width: 80,
          cellStyle: { textAlign: 'center' },
          cellRenderer: (params) => {
            console.log(params.data);
            if (
              params.data.histCnt === 1 &&
              params.data.chickinQtyF === 0 &&
              params.data.chickinQtyM === 0
            )
              return (
                '<button class="btn_del">' + i18n.t('Delete') + '</button>'
              );
            else return null;
          },

          suppressSizeToFit: true,
        },
      ],
      defaultColDef: {
        editable: false,
        sortable: true,
        resizable: true,
        suppressSizeToFit: false,
      },
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  onCellClicked = (e) => {
    if (e.column.colId !== 'iuFlag') return false;
    if (e.data.seqNo === 0) return false;
    //삭제
    let that = this;

    console.log('delete', e.data);

    confirm({
      title: i18n.t('Are you sure delete this task?'),
      //icon: <ExclamationCircleOutlined />,
      //content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        console.log('OK');
        that.fnDelete(e.data);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  fnDelete = (data) => {
    let that = this;

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/deleteMovein.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.alert.show(i18n.t('Has been saved.'));
        that.setState({ breedRecvListView: false }, function () {
          that.props.fnInit();
        });
      })
      .catch(function (error) {
        return [];
      });
  };
  render() {
    // if (this.props.moveinList && this.props.moveinList.length > 0) {
    // } else return null;
    return (
      <Fragment>
        <div
          style={{ height: '300px', width: '100%' }}
          className="board_list ag-theme-balham mB50"
        >
          <AgGridReact
            onGridReady={this.gridApi ? null : this.onGridReady}
            columnDefs={this.state.columnDefs}
            rowData={this.props.moveinList}
            defaultColDef={this.state.defaultColDef}
            //onRowClicked={e => this.onRowClicked(e)}
            onCellClicked={(e) => this.onCellClicked(e)}
            onCellFocused={(e) => {
              e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
            }}
          ></AgGridReact>
        </div>
      </Fragment>
    );
  }
}
export default withAlert()(MoveinListGrid);
