import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import produce from 'immer';

import ScheduleMast from './ScheduleMast';
import ScheduleDtl from './ScheduleDtl';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class ScheduleInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      mastSelected: false,
      params: {
        farmCode: '',
        strainCode: '',
        sex: ''
      },
      regDate: DateUtil.toDateTimeString()
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  setMastParams = params => {
    this.setState(
      produce(draft => {
        draft.params['farmCode'] = params.data['farmCode'];
        draft.params['strainCode'] = params.data['strainCode'];
        draft.params['sex'] = params.data['sex'];
      }),
      this.setState({ mastSelected: true })
    );
  };

  setRegDate = () => {
    this.setState({ regDate: DateUtil.toDateTimeString() });
  };

  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Work Schedule</Trans>
        </h2>

        <ScheduleMast
          setMastParams={this.setMastParams}
          setRegDate={this.setRegDate}
        />

        <ScheduleDtl
          mastSelected={this.state.mastSelected}
          mastParams={this.state.params}
          regDate={this.state.regDate}
        />

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
