import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withAlert } from 'react-alert';
import { SessionUtil, DateUtil, GridUtil, PopSearchCust } from 'utils';
import { Trans } from 'react-i18next';

import { Const, Server } from 'components/Properties';
import { Modal, Button, Select, DatePicker, Input } from 'antd';
import axios from 'axios';
import qs from 'qs';

import { moment } from 'components';
import NumberFormat from 'react-number-format';

import i18n from 'i18next';

import '../Hatchery.module.css';
const { Search } = Input;
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 ** 4. colSpan => conSpan, rowSpan => rowSpan 입니다.
 */
class RegisterInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chk: 'N',
      iuFlag: 'I',
      popSearchCustView: false,
      form: [],
      customerList: [],
      rcvDate: DateUtil.today(),
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    // strainCode 가져오기
    let param = {};
    param['masterCodeId'] = this.state.strainCode;
    this.fnSearchStrain();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('regDate1', prevProps.regDate);
    console.log('regDate2', this.props.regDate);
    if (prevProps.formInfo !== this.props.formInfo) {
      this.setState(
        {
          form: this.props.formInfo,
          iuFlag: 'U',
        },

        function () {}
      );
    }
    if (prevProps.regDate !== this.props.regDate) {
      console.log('aa');
      this.fnClickClearBtn();
    }
  }

  fnSearchStrain = () => {
    window.scrollTo(0, 0);

    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.hatchDate = DateUtil.today();
    data.countryCode = SessionUtil.getCountryCode();
    data.codeId = 'EP26';
    //data.codeValue = "AND REGEXP_LIKE (CODE_VALUE, 'A|B')";

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/broiler/receive/selectCodeList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            strainCodeList: response.data.rows,
          },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });
  };

  fnClickSaveBtn = (e) => {
    let that = this;
    let data = this.state.form;

    data.hatcheryId = SessionUtil.getHatcheryId();
    data.dateFormat = SessionUtil.getDateFormat();
    data.farmPoFlag = 'P';
    data.iuFlag = this.state.iuFlag;

    console.log('data', data);

    if (!data.customerCode) {
      that.props.alert.show(
        i18n.t('Customer Code') + i18n.t('Please select Supplier.')
      );
      that.customerCode.focus();
      return false;
    }

    if (!data.receiveQty || data.receiveQty <= 0) {
      that.props.alert.show(i18n.t('Please check Receive Qty.'));
      that.receiveQty.focus();
      return false;
    }

    if (!data.strainCode) {
      that.props.alert.show(i18n.t('Please check Strain.'));
      that.strainCode.focus();
      return false;
    }

    if (!data.receiveDate) {
      data.receiveDate = this.state.rcvDate;
    }

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/receive/saveReceiveInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.alert.show(i18n.t('Saved'));
        that.props.fnSetRegDate();
        that.fnClickClearBtn();
      })
      .catch(function (error) {
        that.props.alert.show(i18n.t('Save failed'));
        return [];
      });
  };

  fnClickClearBtn = (e) => {
    this.setState(
      {
        form: [],
        iuFlag: 'I',
      },
      function () {}
    );
  };

  onDateChange = (date, dateString, name) => {
    this.setState(
      { form: { ...this.state.form, [name]: dateString } },
      function () {}
    );
  };

  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  renderInput = (inputName, ty, dec, len) => {
    let cl = len ? 'input_type1 w' + len : 'input_type1';

    if (ty && ty === 'N') {
      cl = len ? 'input_base w' + len : 'input_base';
      return (
        <NumberFormat
          style={{ textAlign: 'right' }}
          className={cl}
          thousandSeparator={true}
          decimalScale={dec ? dec : 0}
          name={inputName}
          getInputRef={(el) => (this[inputName] = el)}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : ''
          }
          onChange={(e) => this.setOnChange(e, ty)}
        />
      );
    } else if (ty && ty === 'T') {
      return (
        <TimePicker
          className={cl}
          format={'HH:mm'}
          ref={this[inputName]}
          value={
            this.state.form[inputName]
              ? moment(this.state.form[inputName], 'HH:mm') || ''
              : null
          }
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
        />
      );
    } else if (ty && ty === 'S') {
      return (
        <span>
          {this.state.form[inputName] ? this.state.form[inputName] || '' : ''}
        </span>
      );
    } else {
      return (
        <input
          type="text"
          name={inputName}
          className={cl}
          ref={this[inputName]}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : ''
          }
          onChange={(e) => this.setOnChange(e)}
        />
      );
    }
  };

  setOnChange = (e, params, dateString) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';

    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    this.setState(
      {
        form: {
          ...this.state.form,
          [col]: val,
        },
      },
      function () {}
    );
  };

  setOnChangeSelect = (value, col) => {
    this.setState(
      { form: { ...this.state.form, [col]: value } },
      function () {}
    );
  };

  fnSearchCust = (value) => {
    this.setState(
      {
        popSearchCustView: true,
        customerCode: value,
        customerKind: ['01', '02', '03', '04', '05', '06', '07'],
      },
      function () {}
    );
  };

  fnSelectCust = (data) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          customerCode: data.customerCode,
          customerName: data.customerName,
        },
      },
      function () {}
    );
  };

  fnClosePop = (data) => {
    this.setState({ [data]: false }, function () {});
  };

  setOnChangeCust = (e) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          customerCode: e.target.value,
          customerName: '',
        },
      },
      function () {}
    );
  };

  render() {
    let strainOptions = [];
    if (this.state.strainCodeList && this.state.strainCodeList.length > 0) {
      strainOptions = this.state.strainCodeList.map((row, i) => {
        return (
          <Option key={i} value={row.codeValue}>
            {row.codeValue + ' - ' + row.codeText}
          </Option>
        );
      });
    }

    const customerOptions = this.state.customerList.map((d, i) => (
      <Option key={i} value={d.customerCode}>
        {d.customerName}
      </Option>
    ));

    return (
      <Fragment>
        {/* 디자인 영역 - setting 메뉴 레이어 팝업 */}
        <div className="con_box">
          <h3>
            <Trans>Receive Info</Trans>
          </h3>

          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Supplier</Trans> <span className="red">*</span>{' '}
                  </th>
                  <td>
                    <Search
                      placeholder="Customer Code"
                      onSearch={(value) => this.fnSearchCust(value)}
                      enterButton
                      style={{ width: '300px' }}
                      ref={(input) => {
                        this['customerCode'] = input;
                      }}
                      value={this.state.form.customerCode}
                      onChange={(value) => this.setOnChangeCust(value)}
                    />
                  </td>

                  <th>
                    <Trans>Receive Date</Trans>
                  </th>
                  <td>
                    <DatePicker
                      name="receiveDate"
                      style={{ width: 120 }}
                      value={
                        this.state.form.receiveDate
                          ? moment(this.state.form.receiveDate)
                          : moment(DateUtil.today())
                      }
                      onChange={(date, dateString) =>
                        this.onDateChange(date, dateString, 'receiveDate')
                      }
                      allowClear={false}
                    />
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Receive Qty</Trans> <span className="red">*</span>{' '}
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'receiveQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 120, // px제외 : 없으면 100% */
                    })}
                  </td>

                  <th>
                    <Trans>Order No</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'pOrderNo', //*** */
                      type: '', //*** */
                      //dec: 2,
                      maxLength: 20,
                      //maxVal: 999.99,
                      //width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Strain</Trans> <span className="red">*</span>{' '}
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'strainCode', //*** */
                      type: 'SELECT', //*** */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      options: strainOptions, //*** */ type:'SELECT' options*/
                      placeholder: 'Strain', //*** */
                      width: 210, // px제외 : 없으면 30 */
                    })}
                  </td>

                  <th>
                    <Trans>Truck No</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'truckNo', //*** */
                      type: '', //*** */
                      //dec: 2,
                      maxLength: 10,
                      //maxVal: 999.99,
                      //width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td colSpan="4">
                    {GridUtil.renderInput({
                      inputName: 'remarks', //*** */
                      type: '', //*** */
                      //dec: 2,
                      maxLength: 1000,
                      //maxVal: 999.99,
                      //width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="txt_center mT40">
            <button
              className="btn_sky"
              onClick={(e) => this.fnClickClearBtn(e)}
            >
              <Trans>Clear</Trans>
            </button>
            {this.state.form.gradeYn != 0 && (
              <button
                className="btn_save"
                onClick={(e) => this.fnClickSaveBtn(e)}
              >
                <Trans>Save</Trans>
              </button>
            )}
          </div>
        </div>

        {this.state.popSearchCustView ? (
          <PopSearchCust
            popSearchCustView={this.state.popSearchCustView}
            customerCode={this.state.customerCode}
            customerKind={this.state.customerKind}
            fnClosePop={this.fnClosePop}
            fnSelectCust={this.fnSelectCust}
          />
        ) : null}
        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default withAlert()(RegisterInfoForm);
