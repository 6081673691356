import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import { Radio } from 'antd';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { message } from 'antd';

import '../Hatchery.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class SettingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      gridHeight: '250px',
      rowSelection: 'single',
      columnDefs: [
        {
          headerName: i18n.t('Supplier'),
          field: 'customerName',
          suppressSizeToFit: false,
          cellStyle: { textAlign: 'left' },
          width: 90,
          editable: false,
        },
        {
          headerName: i18n.t('Farm'),
          field: 'farmName',
          suppressSizeToFit: false,
          cellStyle: { textAlign: 'left' },
          width: 90,
          editable: false,
        },
        {
          headerName: i18n.t('Flock'),
          field: 'flockId',
          suppressSizeToFit: false,
          cellStyle: { textAlign: 'left' },
          width: 90,
          editable: false,
        },
        {
          headerName: i18n.t('Hen House'),
          field: 'hhId',
          suppressSizeToFit: false,
          cellStyle: { textAlign: 'left' },
          width: 90,
          editable: false,
        },
        {
          headerName: i18n.t('Strain'),
          field: 'strain',
          suppressSizeToFit: false,
          cellStyle: { textAlign: 'left' },
          width: 90,
          editable: false,
        },
        {
          headerName: i18n.t('Egg Receive No'),
          field: 'eggReceiveNo',
          suppressSizeToFit: false,
          width: 100,
          cellStyle: { textAlign: 'right' },
          editable: false,
        },
        {
          headerName: i18n.t('Receive Date'),
          field: 'receiveDate',
          suppressSizeToFit: true,
          width: 110,
          editable: false,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: i18n.t('Candling Date'),
          field: 'candlingDate',
          suppressSizeToFit: true,
          width: 110,
          cellStyle: { textAlign: 'center' },
          editable: false,
        },
        {
          headerName: i18n.t('Hatcher'),
          field: 'hatcherId',
          suppressSizeToFit: false,
          cellStyle: { textAlign: 'left' },
          width: 90,
          editable: false,
        },
        {
          headerName: i18n.t('Fertile Qty'),
          field: 'fertileQty',
          suppressSizeToFit: false,
          width: 90,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          editable: false,
        },
        {
          headerName: i18n.t('Cutting'),
          field: 'cuttingQty',
          suppressSizeToFit: false,
          width: 90,
          type: 'numericColumn',
          valueFormatter: function (params) {
            if (params.data.cuttingQty) {
              return GridUtil.formatNumber(params);
            } else {
              return '0';
            }
          },
          editable: false,
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    this.fnSearch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.regDate !== this.props.regDate) {
      this.fnSearch();
    }
  }

  fnSearch = () => {
    let that = this;
    let data = {};

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.countryCode = SessionUtil.getCountryCode();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/hatching/selectSettingInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ rowData: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  onCellClicked = (e) => {
    this.props.fnClickHatching(e.data);
  };

  setOnChange = (e) => {
    const that = this;
    const col = e.target.name ? e.target.name : '';

    this.setState(
      {
        [col]: e.target.value,
      },
      function () {}
    );
  };

  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();

    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      params.api.setRowData(data.slice(0, 100));
    };
  };

  render() {
    // return null 은 아무것도 표시하지 않는다는 의미입니다.
    // if (!this.props.localeListView) return null;
    return (
      <Fragment>
        {/* 디자인 영역 */}

        <div className="con_box">
          <h3>
            <Trans>Candling List</Trans>
          </h3>
          <div
            id="myGrid"
            style={{
              height: this.state.gridHeight,
              width: '100%',
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
              onGridReady={(e) => this.onGridReady(e, 'gridApiSettingInfoS')}
              rowSelection={this.state.rowSelection}
              columnDefs={this.state.columnDefs}
              rowData={this.state.rowData}
              //defaultColDef={this.state.defaultColDef}
              onCellClicked={(e) => this.onCellClicked(e)}
              paginationAutoPageSize={true}
              pagination={true}
            ></AgGridReact>
          </div>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default SettingList;
