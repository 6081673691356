import React from 'react';
import * as XLSX from 'xlsx';
import { Upload, Button, Icon, message } from 'antd';
// import "./styles.css";

export default class ExcelUpload extends React.PureComponent {
  onChange = info => {
    this.onImportExcel(info.file);
  };

  onImportExcel = (file, addItem) => {
    const files = file;
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = [];
        for (const Sheet in workbook.Sheets) {
          // var XL_row_object =
          XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
          if (workbook.Sheets.hasOwnProperty(Sheet)) {
            data = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[Sheet]);
            data.forEach(x => {
              addItem(
                x.sku,
                x.description,
                parseInt(x.quantity),
                parseFloat(x.cost)
              );
            });
          }
        }
        message.success('upload success!');
        console.log(data);
      } catch (e) {
        message = message.error('file type is incorrect!');
      }
    };
    fileReader.readAsBinaryString(files[0]);
  };

  render() {
    return (
      <Upload
        onChange={this.onChange}
        accept=".xlsx, .xls"
        showUploadList={false}
      >
        <Button>
          <Icon type="upload" /> Click to Upload
        </Button>
      </Upload>
    );
  }
}
