import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import AFDatePickerSearch from '../utils/AFDatePickerSearch';
import GrwTotalR1Rechart from './GrwTotalR1Rechart';
import ReportUtil from '../utils/ReportUtil';

import '../Report.module.css';

// 5. 육성기 종합 리포트 1
export default class GrwTotalR1 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      excelName: 'Grow Total Report1',
      rowData: [],
      rowData2: [],
      params: {},
      ColDefs: [
        {
          headerName: 'Kind',
          field: 'kind',
          pinned: 'left',
          width: 180,
          minWidth: 180,
        },
        {
          headerName: 'Unit',
          field: 'unit',
          pinned: 'left',
        },
        {
          headerName: 'Act/Std',
          field: 'actStd',
          pinned: 'left',
        },
        {
          headerName: 'W1',
          field: 'W1',
        },
        {
          headerName: 'W2',
          field: 'W2',
        },
        {
          headerName: 'W3',
          field: 'W3',
        },
        {
          headerName: 'W4',
          field: 'W4',
        },
        {
          headerName: 'W5',
          field: 'W5',
        },
        {
          headerName: 'W6',
          field: 'W6',
        },
        {
          headerName: 'W7',
          field: 'W7',
        },
        {
          headerName: 'W8',
          field: 'W8',
        },
        {
          headerName: 'W9',
          field: 'W9',
        },
        {
          headerName: 'W10',
          field: 'W10',
        },
        {
          headerName: 'W11',
          field: 'W11',
        },
        {
          headerName: 'W12',
          field: 'W12',
        },
        {
          headerName: 'W13',
          field: 'W13',
        },
        {
          headerName: 'W14',
          field: 'W14',
        },
        {
          headerName: 'W15',
          field: 'W15',
        },
        {
          headerName: 'W16',
          field: 'W16',
        },
        {
          headerName: 'W17',
          field: 'W17',
        },
        {
          headerName: 'W18',
          field: 'W18',
        },
        {
          headerName: 'W19',
          field: 'W19',
        },
        {
          headerName: 'W20',
          field: 'W20',
        },
        {
          headerName: 'W21',
          field: 'W21',
        },
        {
          headerName: 'W22',
          field: 'W22',
        },
        {
          headerName: 'W23',
          field: 'W23',
        },
        {
          headerName: 'W24',
          field: 'W24',
        },
        {
          headerName: 'Tot/Avg',
          field: 'totAvg',
        },
      ],
      gridHeight: '480px',
      defaultColDef: {
        flex: 1,
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        width: 80,
        minWidth: 80,
        cellClass: 'text-center',
        suppressSizeToFit: false,
        valueFormatter: function (params) {
          return GridUtil.formatReport(params);
        },
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagenation: false,
      paginationPageSize: 10,
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    if (Const.RPT_TEST) {
      const params = ReportUtil.getDefaultParams(this.props);
      console.log('params:', params);
      this.fnGetRowData(params);
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  // -----------------------------------------------------------
  // 참고용 : ES/ETL 서버 주소(API)를 지정해야 합니다.
  // -----------------------------------------------------------
  fnGetRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/stat/breeding/grwTotalR1.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data.rows1, rowData2: data.rows2 });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtExport = () => {
    const fileName =
      i18n.t(this.state.excelName) + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t(this.state.excelName);
    const params = {
      allColumns: false,
      columnGroups: true,
      fileName: fileName,
      sheetName: sheetName,
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onSearch = (params) => {
    console.log(params);
    this.fnGetRowData(params);
  };

  render() {
    return (
      <React.Fragment>
        <h3>
          {/* 육성기 종합 리포트 1 */}
          <Trans>Title Grw Total R 1</Trans>
        </h3>

        <div className="con_box">
          <AFDatePickerSearch onSearch={this.onSearch}></AFDatePickerSearch>

          <div className="f_right mB20">
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
          </div>
          <div className="board_list_s">
            <div style={{ width: '100%' }}>
              <GrwTotalR1Rechart rowData={this.state.rowData2} />
            </div>
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  excelStyles={this.state.excelStyles}
                  onGridReady={this.onGridReady}
                  // pivotMode={true}
                  rowData={this.state.rowData}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// let i = 0;
// const sampleData = [
//   { kind: 'PS Population', unit: 'Bird', actStd: '', w1: i++, w2: i++, w3: i++, w4: i++, w5: i++, w6: i++, w7: i++, w8: i++, w9: i++, w10: i++, w11: i++, w12: i++, w13: i++, w14: i++, w15: i++, w16: i++, w17: i++, w18: i++, w19: i++, w20: i++, w21: i++, w22: i++, w23: i++, w24: i++, totAvg: 'Sum', },
//   { kind: 'PS Population', unit: '%', actStd: '', w1: i++, w2: i++, w3: i++, w4: i++, w5: i++, w6: i++, w7: i++, w8: i++, w9: i++, w10: i++, w11: i++, w12: i++, w13: i++, w14: i++, w15: i++, w16: i++, w17: i++, w18: i++, w19: i++, w20: i++, w21: i++, w22: i++, w23: i++, w24: i++, totAvg: 'Sum', },
//   { kind: 'Body Weight, Male', unit: 'Kg/bird', actStd: 'Act', w1: i++, w2: i++, w3: i++, w4: i++, w5: i++, w6: i++, w7: i++, w8: i++, w9: i++, w10: i++, w11: i++, w12: i++, w13: i++, w14: i++, w15: i++, w16: i++, w17: i++, w18: i++, w19: i++, w20: i++, w21: i++, w22: i++, w23: i++, w24: i++, totAvg: 'Avg', },
//   { kind: 'Body Weight, Male', unit: 'Kg/bird', actStd: 'Std', w1: i++, w2: i++, w3: i++, w4: i++, w5: i++, w6: i++, w7: i++, w8: i++, w9: i++, w10: i++, w11: i++, w12: i++, w13: i++, w14: i++, w15: i++, w16: i++, w17: i++, w18: i++, w19: i++, w20: i++, w21: i++, w22: i++, w23: i++, w24: i++, totAvg: 'Avg', },
//   { kind: 'Body Weight, Female', unit: 'Kg/bird', actStd: 'Act', w1: i++, w2: i++, w3: i++, w4: i++, w5: i++, w6: i++, w7: i++, w8: i++, w9: i++, w10: i++, w11: i++, w12: i++, w13: i++, w14: i++, w15: i++, w16: i++, w17: i++, w18: i++, w19: i++, w20: i++, w21: i++, w22: i++, w23: i++, w24: i++, totAvg: 'Avg', },
//   { kind: 'Body Weight, Female', unit: 'Kg/bird', actStd: 'Std', w1: i++, w2: i++, w3: i++, w4: i++, w5: i++, w6: i++, w7: i++, w8: i++, w9: i++, w10: i++, w11: i++, w12: i++, w13: i++, w14: i++, w15: i++, w16: i++, w17: i++, w18: i++, w19: i++, w20: i++, w21: i++, w22: i++, w23: i++, w24: i++, totAvg: 'Avg', },
//   { kind: 'Uniformity, Female', unit: '%', actStd: 'Act', w1: i++, w2: i++, w3: i++, w4: i++, w5: i++, w6: i++, w7: i++, w8: i++, w9: i++, w10: i++, w11: i++, w12: i++, w13: i++, w14: i++, w15: i++, w16: i++, w17: i++, w18: i++, w19: i++, w20: i++, w21: i++, w22: i++, w23: i++, w24: i++, totAvg: 'Avg', },
//   { kind: 'Uniformity, Male', unit: '%', actStd: 'Act', w1: i++, w2: i++, w3: i++, w4: i++, w5: i++, w6: i++, w7: i++, w8: i++, w9: i++, w10: i++, w11: i++, w12: i++, w13: i++, w14: i++, w15: i++, w16: i++, w17: i++, w18: i++, w19: i++, w20: i++, w21: i++, w22: i++, w23: i++, w24: i++, totAvg: 'Avg', },
//   { kind: 'Point Feed, Female', unit: 'Kg/bird', actStd: 'Act', w1: i++, w2: i++, w3: i++, w4: i++, w5: i++, w6: i++, w7: i++, w8: i++, w9: i++, w10: i++, w11: i++, w12: i++, w13: i++, w14: i++, w15: i++, w16: i++, w17: i++, w18: i++, w19: i++, w20: i++, w21: i++, w22: i++, w23: i++, w24: i++, totAvg: 'Avg', },
//   { kind: 'Point Feed, Female', unit: 'Kg/bird', actStd: 'Std', w1: i++, w2: i++, w3: i++, w4: i++, w5: i++, w6: i++, w7: i++, w8: i++, w9: i++, w10: i++, w11: i++, w12: i++, w13: i++, w14: i++, w15: i++, w16: i++, w17: i++, w18: i++, w19: i++, w20: i++, w21: i++, w22: i++, w23: i++, w24: i++, totAvg: 'Avg', },
// ];
