import * as Breeding from 'containers/pages/breeding';
import * as Common from 'containers/pages/common';
import * as Report from 'containers/pages/report';
import i18n from 'i18next';

const BreedingRouteList = [
  {
    uri: '/breeding/dashboard',
    page: Breeding.Dashboard.Dashboard,
  },
  {
    uri: '/breeding/receive/hatchery',
    page: Breeding.Receive.Hatchery,
  },
  {
    uri: '/breeding/receive/supplier',
    page: Breeding.Receive.Supplier,
  },

  {
    uri: '/breeding/receive/regflock',
    page: Breeding.Receive.RegFlock,
  },

  {
    uri: '/breeding/chickin/henhouse',
    page: Breeding.Chickin.HenHouse,
  },
  {
    uri: '/breeding/chickin/flock',
    page: Breeding.Chickin.Flock,
  },
  {
    uri: '/breeding/receive/newflock',
    page: Breeding.Receive.NewFlock,
  },
  {
    uri: '/breeding/receive/add2flock',
    page: Breeding.Receive.AddToFlock,
  },

  // { uri: '/breeding/receive/0', page: Breeding.Receive.RcvPage0 },
  // { uri: '/breeding/receive/1', page: Breeding.Receive.RcvPage1 },
  // { uri: '/breeding/receive/2', page: Breeding.Receive.RcvPage2 },
  // { uri: '/breeding/receive/3', page: Breeding.Receive.RcvPage3 },
  // { uri: '/breeding/receive/4', page: Breeding.Receive.RcvPage4 },
  // { uri: '/breeding/receive/5', page: Breeding.Receive.RcvPage5 },
  // { uri: '/breeding/receive/6', page: Breeding.Receive.RcvPage6 },
  // { uri: '/breeding/receive/7', page: Breeding.Receive.RcvPage7 },
  {
    uri: '/breeding/move/in',
    page: Breeding.Move.MoveIn,
  },
  {
    uri: '/breeding/move/out',
    page: Breeding.Move.MoveOut,
  },

  {
    uri: '/breeding/growing/growlog',
    page: Breeding.Growing.GrowLog,
  },
  {
    uri: '/breeding/growing/0',
    page: Breeding.Growing.GrowPage0,
  },
  {
    uri: '/breeding/growing/1',
    page: Breeding.Growing.GrowPage1,
  },

  {
    uri: '/breeding/egg/egglog',
    page: Breeding.Egg.EggLog,
  },
  {
    uri: '/breeding/egg/0',
    page: Breeding.Egg.EggPage0,
  },
  {
    uri: '/breeding/egg/1',
    page: Breeding.Egg.EggPage1,
  },

  {
    uri: '/breeding/deliver/deliverEggList',
    page: Breeding.Deliver.DeliverEggList,
  },
  {
    uri: '/breeding/deliver/deliverEggSales',
    page: Breeding.Deliver.DeliverEggSales,
  },
  {
    uri: '/breeding/deliver/deliverEggTransfer',
    page: Breeding.Deliver.DeliverEggTransfer,
  },
  {
    uri: '/breeding/deliver/deliverEggDiscard',
    page: Breeding.Deliver.DeliverEggDiscard,
  },

  {
    uri: '/breeding/deliver/deliverPsList',
    page: Breeding.Deliver.DeliverPsList,
  },
  {
    uri: '/breeding/deliver/deliverPsSales',
    page: Breeding.Deliver.DeliverPsSales,
  },
  {
    uri: '/breeding/deliver/deliverPsDiscard',
    page: Breeding.Deliver.DeliverPsDiscard,
  },

  {
    uri: '/breeding/release/0',
    page: Breeding.Release.RelPage0,
  },
  {
    uri: '/breeding/release/1',
    page: Breeding.Release.RelPage1,
  },
  {
    uri: '/breeding/release/2',
    page: Breeding.Release.RelPage2,
  },
  {
    uri: '/breeding/release/3',
    page: Breeding.Release.RelPage3,
  },
  {
    uri: '/breeding/release/4',
    page: Breeding.Release.RelPage4,
  },
  {
    uri: '/breeding/release/5',
    page: Breeding.Release.RelPage5,
  },
  {
    uri: '/breeding/release/6',
    page: Breeding.Release.RelPage6,
  },
  {
    uri: '/breeding/release/7',
    page: Breeding.Release.RelPage7,
  },

  {
    uri: '/breeding/report/clsFlkAnlysDF',
    page: Report.Breeding.ClsFlkAnlysDF,
  },
  {
    uri: '/breeding/report/clsFlkAnlysFH',
    page: Report.Breeding.ClsFlkAnlysFH,
  },
  {
    uri: '/breeding/report/grwDailyR',
    page: Report.Breeding.GrwDailyR,
  },
  {
    uri: '/breeding/report/grwFlockHhR',
    page: Report.Breeding.GrwFlockHhR,
  },
  {
    uri: '/breeding/report/grwIdxAnlysR',
    page: Report.Breeding.GrwIdxAnlysR,
  },
  {
    uri: '/breeding/report/grwTotalR1',
    page: Report.Breeding.GrwTotalR1,
  },
  {
    uri: '/breeding/report/grwTotalR2',
    page: Report.Breeding.GrwTotalR2,
  },
  {
    uri: '/breeding/report/grwWeeklyR',
    page: Report.Breeding.GrwWeeklyR,
  },
  {
    uri: '/breeding/report/hatDocTermHhR',
    page: Report.Breeding.HatDocTermHhR,
  },
  {
    uri: '/breeding/report/hatEpDailyHhR',
    page: Report.Breeding.HatEpDailyHhR,
  },
  {
    uri: '/breeding/report/hatEpTermHhR',
    page: Report.Breeding.HatEpTermHhR,
  },
  {
    uri: '/breeding/report/hatEpWklyHhR',
    page: Report.Breeding.HatEpWklyHhR,
  },
  {
    uri: '/breeding/report/hatStkTermHhR1',
    page: Report.Breeding.HatStkTermHhR1,
  },
  {
    uri: '/breeding/report/hatStkTermHhR2',
    page: Report.Breeding.HatStkTermHhR2,
  },
  {
    uri: '/breeding/report/layDailyR',
    page: Report.Breeding.LayDailyR,
  },
  {
    uri: '/breeding/report/layIdxAnlysR',
    page: Report.Breeding.LayIdxAnlysR,
  },
  {
    uri: '/breeding/report/layFlockHhR',
    page: Report.Breeding.LayFlockHhR,
  },
  {
    uri: '/breeding/report/layTotalR1',
    page: Report.Breeding.LayTotalR1,
  },
  {
    uri: '/breeding/report/layTotalR2',
    page: Report.Breeding.LayTotalR2,
  },
  {
    uri: '/breeding/report/layWeeklyR',
    page: Report.Breeding.LayWeeklyR,
  },
];

export default BreedingRouteList;
