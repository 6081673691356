import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import AFDatePickerSearch from '../utils/AFDatePickerSearch';
import ReportUtil from '../utils/ReportUtil';

import '../Report.module.css';

// 6. 육성기 종합 리포트 2
export default class GrwTotalR2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      excelName: 'Grow Total Report2',
      rowData: [],
      rowData2: [],
      params: {},
      ColDefs0: [
        {
          headerName: 'Region',
          field: 'region',
          pinned: 'left',
          minWidth: 100,
          colSpan: function (params) {
            const { region } = params.data;
            switch (region) {
              case 'Standard':
              case 'Sum/Avg':
                return 3;
            }
            return 1;
          },
          valueFormatter: function (params) {}, // 숫자형태 표기 방지
        },
        {
          headerName: 'Farm',
          field: 'farm',
          pinned: 'left',
          minWidth: 100,
        },
        {
          headerName: 'Flock',
          field: 'flock',
          pinned: 'left',
          minWidth: 120,
        },
        {
          headerName: 'W1',
          field: 'W1',
        },
        {
          headerName: 'W2',
          field: 'W2',
        },
        {
          headerName: 'W3',
          field: 'W3',
        },
        {
          headerName: 'W4',
          field: 'W4',
        },
        {
          headerName: 'W5',
          field: 'W5',
        },
        {
          headerName: 'W6',
          field: 'W6',
        },
        {
          headerName: 'W7',
          field: 'W7',
        },
        {
          headerName: 'W8',
          field: 'W8',
        },
        {
          headerName: 'W9',
          field: 'W9',
        },
        {
          headerName: 'W10',
          field: 'W10',
        },
        {
          headerName: 'W11',
          field: 'W11',
        },
        {
          headerName: 'W12',
          field: 'W12',
        },
        {
          headerName: 'W13',
          field: 'W13',
        },
        {
          headerName: 'W14',
          field: 'W14',
        },
        {
          headerName: 'W15',
          field: 'W15',
        },
        {
          headerName: 'W16',
          field: 'W16',
        },
        {
          headerName: 'W17',
          field: 'W17',
        },
        {
          headerName: 'W18',
          field: 'W18',
        },
        {
          headerName: 'W19',
          field: 'W19',
        },
        {
          headerName: 'W20',
          field: 'W20',
        },
        {
          headerName: 'W21',
          field: 'W21',
        },
        {
          headerName: 'W22',
          field: 'W22',
        },
        {
          headerName: 'W23',
          field: 'W23',
        },
        {
          headerName: 'W24',
          field: 'W24',
        },
      ],
      gridHeight: '640px',
      defaultColDef: {
        flex: 1,
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        width: 80,
        minWidth: 80,
        cellClass: 'text-center',
        suppressSizeToFit: false,
        cellStyle: function (params) {
          const { region } = params.node.data;
          switch (region) {
            case 'Standard':
            case 'Sum/Avg':
              return { 'background-color': Const.AGGR_COLOR };
          }
        },
        valueFormatter: function (params) {
          return GridUtil.formatReport(params);
        },
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagenation: false,
      paginationPageSize: 10,
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    if (Const.RPT_TEST) {
      const params = ReportUtil.getDefaultParams(this.props);
      console.log('params:', params);
      this.fnGetRowData(params);
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  // -----------------------------------------------------------
  // 참고용 : ES/ETL 서버 주소(API)를 지정해야 합니다.
  // -----------------------------------------------------------
  fnGetRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/stat/breeding/grwTotalR2.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data.rows1 });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtExport = () => {
    const fileName =
      i18n.t(this.state.excelName) + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t(this.state.excelName);
    const params = {
      allColumns: false,
      columnGroups: true,
      fileName: fileName,
      sheetName: sheetName,
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onSearch = (params) => {
    console.log(params);
    this.fnGetRowData(params);
  };

  render() {
    return (
      <React.Fragment>
        <h3>
          {/* 육성기 종합 리포트 2 */}
          <Trans>Title Grw Total R 2</Trans>
        </h3>

        {/* Grid#0 */}
        <div className="con_box">
          <AFDatePickerSearch
            index0={true}
            onSearch={this.onSearch}
          ></AFDatePickerSearch>

          <div className="f_right mB20">
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
          </div>
          {/*
            검색키워드와 AgGrid에 해당 내용이 중복 출력 됩니다.
          <div className="f_left mB10 both">
            Farm : {SessionUtil.getFarmInfo()}
          </div>
          <div className="f_right mB10">Index : PS Population</div>
          */}
          <div className="board_list_s">
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs0}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  excelStyles={this.state.excelStyles}
                  onGridReady={this.onGridReady}
                  // pivotMode={true}
                  rowData={this.state.rowData}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
