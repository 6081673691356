import * as Standard from 'containers/pages/common/standard';
import * as Work from 'containers/pages/common/work';
import * as Materials from 'containers/pages/common/materials';

import * as Member from 'containers/pages/common/member';
import * as Group from 'containers/pages/common/group';
import * as Menu from 'containers/pages/common/menu';
import * as System from 'containers/pages/common/system';
import * as Basic from 'containers/pages/common/basic';
import * as Test from 'containers/pages/common/test';
import * as Download from 'containers/pages/common/download';
import * as Log from 'containers/pages/common/log';

import EggError from 'containers/pages/common/error/EggError';
import { Login, Logout, Privacy } from 'containers/pages/common/login';

export {
Standard, Work, Materials, Member, Group, Menu, System, Basic, Test, Login, Logout, Privacy, EggError, Download, Log
}
