import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import i18n from 'i18n';
import { Trans } from 'react-i18next';
import { Const, Server } from 'components/Properties';
import qs from 'qs';

import { inject, observer } from 'mobx-react';
import { Menu, Icon, Tooltip, Popover } from 'antd';
import MenuUtil from './MenuUtil';

import './Layout.module.css';

@inject((stores) => ({ mainStore: stores.MainStore }))
@observer
class BroilerMenu extends React.Component {
  constructor(props) {
    super(props);
    const { mainStore } = props;
    const { groupId, userLevel } = mainStore.getUser();
    this.state = {
      collapsed: false,
      target: '',
      openKeys: [],
      selectedKeys: [],
      groupId: groupId,
      userLevel: userLevel,
    };
  }

  UNSAFE_componentWillMount() {
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const selectedKey = MenuUtil.fnMenuSelectedKey();
    const openKey = MenuUtil.fnMenuOpenKey();
    this.setState({
      selectedKeys: [selectedKey],
      openKeys: [openKey],
    });
  }

  okPermission = (menuId) => {
    const { groupId, userLevel } = this.state;
    if (groupId === Const.ADMIN || groupId === Const.DEVEL) return true;
    switch (menuId) {
      case 'Dashboard':
      case 'Receive.Hatchery':
      case 'Receive.Supplier':
      case 'Growing Log':
        if (groupId === Const.BROILER) {
          if (userLevel === Const.FARM || userLevel === Const.PPL) return true;
        }
        return false;

      case 'Report.Daily Project R':
      case 'Report.Daily Area R':
      case 'Report.Sales R':
      case 'Report.Proj Anlys R(Gph)':
      case 'Report.Proj Anlys R(Idx)':
        if (groupId === Const.BROILER || groupId === Const.CONSULT) {
          if (
            userLevel === Const.FARM ||
            userLevel === Const.PPL ||
            userLevel === Const.COUNTRY ||
            userLevel === Const.DIVISION ||
            userLevel === Const.REGION ||
            userLevel === Const.SUB_REGION
          )
            return true;
        }
        return false;
    }
    return false;
  };

  render() {
    if (this.props.menu !== Const.MENU_BROILER) return null;
    const { location } = this.props;
    let i = 0;
    return (
      <Menu
        onClick={(e) => MenuUtil.fnMenuClick(e, this.state, this.props)}
        theme="dark"
        // style={{ width: 256 }}
        defaultSelectedKeys={this.state.selectedKeys}
        defaultOpenKeys={this.state.openKeys}
        selectedKeys={[location.pathname]}
        mode="inline"
      >
        {this.okPermission('Dashboard') && (
          <Menu.Item key="/broiler/dashboard">
            <Icon type="border-outer" />
            <span>
              <Trans>Dashboard</Trans>
            </span>
          </Menu.Item>
        )}
        {/* 또 변경해달라고 하네 ... => to Common
          * 메뉴 이동 요청 by 김기철 2020.03.09
        <Menu.Item key="/broiler/henhouse/info">
          <Icon type="file-done" />
          <span>
            <Trans>Broiler HH</Trans>
          </span>
        </Menu.Item>

        <Menu.Item key="/broiler/project/info">
          <Icon type="profile" />
          <span>
            <Trans>Project</Trans>
          </span>
        </Menu.Item>
      */}

        {/*
        종계의 Receive 체계로 육계구성 요청
        <Menu.Item key="/broiler/receive/info">
          <Icon type="snippets" />
          <span>
            <Trans>Receive</Trans>
          </span>
        </Menu.Item>
      */}

        {(this.state.groupId === Const.ADMIN ||
          this.state.groupId === Const.DEVEL ||
          (this.state.groupId === Const.BROILER &&
            (this.state.userLevel === Const.FARM ||
              this.state.userLevel === Const.PPL))) && (
          <Menu.SubMenu
            key="_rcv"
            title={
              <span>
                <Icon type="snippets" />
                <span>
                  <Trans>Receive</Trans>
                </span>
              </span>
            }
          >
            {this.okPermission('Receive.Hatchery') && (
              <Menu.Item key="/broiler/receive/hatchery">
                <span>
                  <Trans>Hatchery</Trans>
                </span>
              </Menu.Item>
            )}

            {/* PO Receive 버튼 메뉴 */}
            {this.okPermission('Receive.Supplier') && (
              <Menu.Item key="/broiler/receive/supplier">
                <span>
                  <Trans>Supplier</Trans>
                </span>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {this.okPermission('Growing Log') && (
          <Menu.Item key="/broiler/growing/growlog">
            <Icon type="border-outer" />
            <span>
              <Trans>Growing Log</Trans>
            </span>
          </Menu.Item>
        )}

        {/* 메뉴 삭제 요청 by 김기철 2020.03.09
        <Menu.Item key="/broiler/transfer/info">
          <Icon type="fullscreen" />
          <span>
            <Trans>Transfer</Trans>
          </span>
        </Menu.Item>
        */}

        <Menu.SubMenu
          key="_rpt"
          title={
            <span>
              <Icon type="logout" />
              <span>
                <Trans>Report</Trans>
              </span>
            </span>
          }
        >
          {this.okPermission('Report.Daily Project R') && (
            <Menu.Item key="/broiler/report/dailyProjectR">
              <Popover content={i18n.t('Daily Project R')}></Popover>
              <span>
                {++i}.<Trans>Daily Project R</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Daily Area R') && (
            <Menu.Item key="/broiler/report/dailyAreaR">
              <Popover content={i18n.t('Division,Region,Farm R')}></Popover>
              <span>
                {++i}.<Trans>Division,Region,Farm R</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Sales R') && (
            <Menu.Item key="/broiler/report/salesR">
              <Popover content={i18n.t('Sales R')}></Popover>
              <span>
                {++i}.<Trans>Sales R</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Proj Anlys R(Gph)') && (
            <Menu.Item key="/broiler/report/projGphAnlysR">
              <Popover content={i18n.t('Proj Anlys R(Gph)')}></Popover>
              <span>
                {++i}.<Trans>Proj Anlys R(Gph)</Trans>
              </span>
            </Menu.Item>
          )}
          {this.okPermission('Report.Proj Anlys R(Idx)') && (
            <Menu.Item key="/broiler/report/projIdxAnlysR">
              <Popover content={i18n.t('Proj Anlys R(Idx)')}></Popover>
              <span>
                {++i}.<Trans>Proj Anlys R(Idx)</Trans>
              </span>
            </Menu.Item>
          )}
        </Menu.SubMenu>
      </Menu>
    );
  }
}

export default withRouter(BroilerMenu);
