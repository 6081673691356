import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, FormUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import CountryCode from './CountryCode';

import { Radio } from 'antd';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { message } from 'antd';

import LangCode from 'containers/pages/common/system/LangCode';
import LangRegist from './LangRegist';

import '../Common.module.css';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class LangInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      regDate: DateUtil.toDateTimeString(),
      rowNode: {},
      rowData: [],
      params: {
        lang: SessionUtil.getLang(),
        // mesgId: '',
        // mesgText: ''
      },
      ColDefs: [
        {
          headerName: i18n.t('Lang'),
          field: 'lang',
          editable: false,
          hide: true,
          getQuickFilterText: function (params) {
            return params.value;
          },
        },
        {
          headerName: i18n.t('Lang Name'),
          field: 'langName',
          hide: true,
        },
        {
          headerName: i18n.t('Mesg Id'),
          field: 'mesgId',
        },
        {
          headerName: i18n.t('Mesg Text'),
          field: 'mesgText',
          // cellStyle: function(params) {
          //   const w = params.column.actualWidth;
          //   return FormUtil.getEditCell('E', w);
          // },
          // editable: true
        },
        {
          headerName: i18n.t('Update Id'),
          field: 'updateId',
        },
        {
          headerName: i18n.t('Update Time'),
          field: 'updateTime',
        },
      ],
      gridHeight: '365px',
      defaultColDef: {
        filter: true,
        resizable: true,
        editable: false,
        sortable: true,
        // suppressSizeToFit: true
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      paginationPageSize: 10,
      // editType: 'fullRow', // selectEditor와 함께 사용하지 말라고 하네요...
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnGetRowData(this.state.params);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log(prevProps.regDate, prevState.regDate, this.props.regDate);
    if (
      prevState.regDate !== this.state.regDate ||
      prevState.params.lang !== this.state.params.lang
    ) {
      const { params } = this.state;
      this.fnGetRowData(params);
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  fnSetRegDate = () => {
    this.setState({
      regDate: DateUtil.toDateTimeString(),
    });
  };

  fnGetRowData = async (params) => {
    const that = this;
    let url = Server.getRestAPI() + '/system/selectTcLocaleMesg.do';
    await axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowNode: {}, rowData: data });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  fnDeleteRowData = (params) => {
    const url = Server.getRestAPI() + '/system/deleteTcLocaleMesg.do';
    // ---------------------------------------------------------
    // (중요) await와 then 은 함께 사용해서는 안됩니다.
    // ---------------------------------------------------------
    return axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    });
  };

  onBtApply = () => {
    const params = {};
    const url = Server.getRestAPI() + '/system/makeTcLocaleMesg.do';
    // ---------------------------------------------------------
    // (중요) await와 then 은 함께 사용해서는 안됩니다.
    // ---------------------------------------------------------
    return axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    });
  };

  onBtDelete = () => {
    const that = this;
    const selectedRows = this.gridApi.getSelectedNodes();
    if (!selectedRows || selectedRows.length === 0) {
      return message.error(i18n.t('Not selected row'));
    }

    const selectedRow = selectedRows[0];
    const { data } = selectedRow;

    let param = {};
    param['lang'] = data['lang'];
    param['mesgId'] = data['mesgId'];

    this.fnDeleteRowData(param)
      .then(function (response) {
        const { data } = response;
        if (data > 0) {
          const { params: param } = that.state; // 주의: param변수가 교체됨.
          that.fnGetRowData(param);
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onFilterTextBoxChanged = (e) => {
    const { value } = e.target;
    this.gridApi.setQuickFilter(value);
  };

  setCode = (name, value) => {
    this.setState(
      produce((draft) => {
        draft.params[name] = value;
      }),
      function () {
        console.log(this.state.params);
      }
    );
  };

  onRowSelected = (params) => {
    const that = this;
    if (!isRowSelected(params)) return;
    this.setState(
      produce((draft) => {
        draft.rowNode = params.data;
      }),
      function () {}
    );
  };

  // ------------------------------------------
  // 강제로 선택된 AgGrid의 Row선택을 해제한다.
  // ------------------------------------------
  onRowDeselect = () => {
    this.gridApi.deselectAll();
    this.setState({ rowNode: {} }, function () {});
  };

  render() {
    // return null 은 아무것도 표시하지 않는다는 의미입니다.
    // if (!this.props.localeListView) return null;
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>System - Language</Trans>
        </h2>

        <div className="con_box">
          <div className="f_left langsel">
            <LangCode
              name="lang"
              className="mR20"
              setCode={this.setCode}
              code={SessionUtil.getLang()}
              changePossible={false}
              disabled={false}
            />
            <input
              type="text"
              className="input_type1"
              placeholder={i18n.t('Filter')}
              onChange={(e) => this.onFilterTextBoxChanged(e)}
            />
          </div>
          <div className="f_right mB20">
            <button className="btn_sky" onClick={this.onBtApply}>
              <Trans>Apply</Trans>
            </button>
            <button className="btn_gray" onClick={this.onBtDelete}>
              <Trans>Delete</Trans>
            </button>
          </div>

          <div className="board_list_s">
            <div
              id="myGrid"
              style={{
                height: this.state.gridHeight,
                width: '100%',
              }}
              className="ag-theme-balham"
            >
              <AgGridReact
                modules={this.state.modules}
                columnDefs={this.state.ColDefs}
                // components={this.state.components}
                // frameworkComponents={this.state.frameworkComponents}
                defaultColDef={this.state.defaultColDef}
                rowSelection={this.state.rowSelection}
                editType={this.state.editType}
                // enterMovesDownAfterEdit={true}
                // enterMovesDown={true}
                // rowModelType={this.state.rowModelType}

                onGridReady={this.onGridReady}
                onCellEditingStarted={this.onCellEditingStarted}
                onCellEditingStopped={this.onCellEditingStopped}
                onRowClicked={this.onRowSelected}
                onRowSelected={this.onRowSelected}
                onRowEditingStarted={this.onRowEditingStarted}
                onRowEditingStopped={this.onRowEditingStopped}
                onCellValueChanged={this.onCellValueChanged}
                rowData={this.state.rowData}
                pagination={true}
                paginationPageSize={this.state.paginationPageSize}
              />
            </div>
          </div>
        </div>

        <LangRegist
          rowNode={this.state.rowNode}
          onRowDeselect={this.onRowDeselect}
          regDate={this.state.regDate}
          fnSetRegDate={this.fnSetRegDate}
        ></LangRegist>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
