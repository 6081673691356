import React, { Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { Select } from 'antd';
import NumberFormat from 'react-number-format';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import NumericEditor from '../numericEditor_copy.jsx';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class EggTransferForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInstance: this,
      transferFormView: false,
      today: DateUtil.today(),
      form: [{ transferType: 'T', transferDate: DateUtil.today() }],
      epDateList: [],
      hatcheryIdFocus: false,
      cullingStockList: [],
      hhHeadList: [],
      eggGoodRowData: [],
      eggGoodColumnDefs: [],
      defaultColDef: {
        editable: false,
        width: 100,
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        suppressSizeToFit: false,
      },
      frameworkComponents: {
        numericEditor: NumericEditor,
      },
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.setState(
      {
        form: {
          iuFlag: 'I',
          transferType: 'T',
          transferDate: DateUtil.today(),
        },
      },
      function () {}
    );
    this.fnSearch();
  }

  fnSearch = (nextProps) => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/selectEggReleaseTransfer.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        let eggGoodColumnDefs = [
          {
            headerName: 'Flock',
            field: 'flockId',
            width: 150,
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: 'Hen House',
            field: 'hhId',
            width: 100,
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: 'Strain',
            field: 'strainName',
            width: 150,
            cellStyle: { textAlign: 'center' },
          },
        ];
        if (response.data.hhList) {
          let hhList = response.data.hhList.split(',');

          console.log('hhlist', response.data.hhList);

          console.log('hhlist.0', hhList[0]);

          that.setState(
            {
              eggProductionDate: hhList[0],
            },
            function () {}
          );

          hhList.forEach((row) => {
            let field = row; //.split(' ');
            let headDef = {};
            let childrenDef1 = {};
            let childrenDef2 = {};
            let childrenDef = [];

            childrenDef1.headerName = 'Qty';
            childrenDef1.field = field;
            childrenDef1.type = 'numericColumn';
            (childrenDef1.valueFormatter = function (params) {
              return GridUtil.formatNumber(params);
            }),
              (childrenDef1.cellStyle = function (params) {
                return GridUtil.getEditCell('D', 100);
              });
            childrenDef1.valueGetter = function (params) {
              return Number(params.data[field] ? params.data[field] : 0);
            };
            childrenDef2.headerName = 'Transfer';
            childrenDef2.field = field + 'SalesQty';
            childrenDef2.type = 'numericColumn';

            childrenDef2.cellEditor = 'numericEditor';
            childrenDef2.cellEditorParams = function () {
              return {
                handleChange: (e) => that.fnGridHandleChange(e, 'EggGoodList'),
              };
            };
            childrenDef2.editable = function (params) {
              if (!params.data[field]) return false;
              else return true;
            };
            (childrenDef2.valueFormatter = function (params) {
              return GridUtil.formatNumber(params);
            }),
              (childrenDef2.cellStyle = function (params) {
                if (!params.data[field]) return GridUtil.getEditCell('P', 100);
                else return GridUtil.getEditCell('E', 100);
              });
            childrenDef2.valueGetter = function (params) {
              return Number(
                params.data[field + 'SalesQty']
                  ? params.data[field + 'SalesQty']
                  : 0
              );
            };

            headDef.headerName = field.replace(/'/gi, '');
            headDef.children = [childrenDef1, childrenDef2];
            eggGoodColumnDefs.push(headDef);
          });
        }

        that.setState(
          {
            eggGoodColumnDefs: eggGoodColumnDefs,
            eggGoodRowData: response.data.rows1,
            hhList: response.data.hhList,
            hatcheryList: response.data.hatcheryList,
            form: {
              iuFlag: 'I',
              transferType: 'T',
              transferDate: DateUtil.today(),
            },
          },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  //Sales 저장
  fnSave = () => {
    let that = this;
    let data = this.state.form;
    let epDateList = [];
    let hhList = that.state.hhList ? that.state.hhList.split(',') : [];

    if (!data.transferQty || data.transferQty <= 0) {
      that.props.alert.error(i18n.t('Qty is None'));
      return false;
    }

    if (this.state.today < data.transferDate) {
      that.props.alert.error(i18n.t('Transfer date must earlier than today'));
      that.transferDate.focus();
      return false;
    }

    let eggPDate = this.state.eggProductionDate.replace("'", '');

    if (eggPDate.replace("'", '') > data.transferDate) {
      console.log('!!!');
      that.props.alert.error(
        i18n.t('Transfer date must later than egg production date')
      );
      that.transferDate.focus();
      return false;
    }

    if (!data.transferDate) {
      that.props.alert.error(i18n.t('Please enter Transfer Date'));
      that.transferDate.focus();
      return false;
    }
    if (!data.hatcheryId) {
      that.props.alert.error(i18n.t('Please enter HatcheryId.'));
      that.hatcheryId.focus();
      return false;
    }

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.updateId = SessionUtil.getUserId();
    data.received = 'N';
    if (data.iuFlag === 'I') {
      //Type
    }

    this.gridApi.forEachNode(function (rowNode, index) {
      hhList.forEach((row) => {
        console.log('row', row);
        console.log('rowNode', rowNode);
        let field = row; //.split(' ');
        let col = field + 'SalesQty';
        if (Number(rowNode.data[col]) > 0) {
          let dataDef = {};
          dataDef.flockId = rowNode.data.flockId;
          dataDef.strainCode = rowNode.data.strainCode;
          dataDef.hhId = rowNode.data.hhId;
          dataDef.epDate = field.replace(/'/gi, '');
          dataDef.goodEggQty = Number(rowNode.data[col]);
          epDateList.push(dataDef);
        }
      });
    });

    data.epDateList = epDateList;
    data.epDateLength = epDateList.length;

    for (let i = 0; i < data.epDateLength; i++) {
      console.log(data.epDateList);
      if (data.epDateList[i].epDate > data.transferDate) {
        that.props.alert.show(i18n.t('Please Check EP Date.'));
        return false;
      }
    }

    console.log('save', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/saveEggTransfer.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.alert.show(i18n.t('Has been saved.'));
        that.props.fnSearch();
      })
      .catch(function (error) {
        return [];
      });
  };
  /*
   * Ag-Grid key Down 수량 체크 및 합계 처리
   */
  fnGridHandleChange = (params, op) => {
    let that = this;
    console.log('params', params);
    let col = params.column.colId.replace('SalesQty', '');
    let qty = Number(params[col] ? params[col] : 0);
    if (Number(params.val) > qty) {
      that.props.alert.show(i18n.t('The quantity is exceeded.'));
      that.gridApi.stopEditing();
      var rowNode = that.gridApi.getDisplayedRowAtIndex(params.rowIndex);
      rowNode.setDataValue(params.column.colId, 0);
      //that.gridApi.tabToPreviousCell();
      that.gridApi.setFocusedCell(params.rowIndex, params.column.colId);
      that.gridApi.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: params.column.colId,
      });
      //} else {
    }
    that.printAllDisplayedRows(params.column.colId); //현재입력받는 숫자도 더함
  };
  fnClose = () => {
    this.props.fnClose('transerFormView');
  };
  //선택
  onCellClicked = (e) => {
    console.log('onCellClicked', e);
    if (e.column.colId === 'ck') {
      //선택버튼 클릭

      //this.props.fnSelectFlock(e.data, this.state.tpBreedRecvData);

      this.setState(
        {
          form: {
            ...this.state.form,
            flockId: e.data.flockId,
            strainCode: e.data.strainCode,
          },
        },
        function () {}
      );
    }
  };
  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  setOnChange = (e, params) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    that.setState({ form: { ...this.state.form, [col]: val } }, function () {
      if (params.type === 'N') {
        that.fnCalcEggGood();
      }
    });
  };

  fnCalcEggGood = () => {
    //this.fnSend();
  };
  /*
   * 수량계산 - 합계
   */
  setOnChangeCalc = (e, row, col) => {
    let that = this;
    let stockQty = Number(row[col]);
    let saleQty = Number(e.target.value.replace(/,/gi, ''));
    if (saleQty > stockQty) {
      that.props.alert.show(i18n.t('The quantity is exceeded.'));

      saleQty = 0;
    }
    this.setState(
      {
        epDateList: {
          ...this.state.epDateList,
          [e.target.name]: saleQty,
        },
      },
      function () {
        let totSaleQty = 0;
        this.state.cullingStockList.map((rr, i) => {
          that.state.hhHeadList.map((hh, ii) => {
            let field = hh.split(' ');
            let col = field[2].toLowerCase();
            let colName = (
              rr.flockId +
              '|' +
              rr.epDate +
              '|' +
              col +
              '|' +
              ii
            ).replace(/'/gi, '');

            totSaleQty += that.state.epDateList[colName]
              ? Number(that.state.epDateList[colName])
              : 0;
          });
        });
        that.setState({
          form: {
            ...this.state.form,
            transferQty: totSaleQty,
          },
        });
      }
    );
  };
  onCellValueChanged = (params) => {
    let that = this;
    //params.data.~~~ : row 전체 data
    //params.column.colId : column 명
    //params.rowIndex : row index
    //params.oldValue: "55"
    //params.newValue: "33"
    //params.value: "33"
    //"hh04SaleQty"
    let col = params.column.colId.replace('SalesQty', '');
    var rowNode = this.gridApi.getDisplayedRowAtIndex(params.rowIndex);
    let qty = Number(rowNode.data[col] ? rowNode.data[col] : 0);
    if (Number(params.value) > qty) {
      //rowNode.setDataValue(params.column.colId, 0);
      //that.props.alert.show(i18n.t('The quantity is exceeded.'));
      that.gridApi.tabToPreviousCell();
    } else {
      this.printAllDisplayedRows();
    }
  };
  //Egg Goog List edit
  printAllDisplayedRows = () => {
    let that = this;
    let col;
    var count = this.gridApi.getDisplayedRowCount();
    let tot = 0;
    let hhList = that.state.hhList ? that.state.hhList.split(',') : [];
    for (var i = 0; i < count; i++) {
      var rowNode = this.gridApi.getDisplayedRowAtIndex(i);
      //console.log('row ' + i + ' is ' + rowNode.data.epDate);
      tot += Number(rowNode.data.transferQty ? rowNode.data.transferQty : 0);
      hhList.forEach((row) => {
        let field = row; //.split(' ');
        col = field.toLowerCase() + 'SalesQty';
        tot += Number(rowNode.data[col] ? rowNode.data[col] : 0);
      });
    }
    this.setState(
      { form: { ...this.state.form, transferQty: tot } },
      function () {
        console.log(this.state);
      }
    );
  };
  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  render() {
    //if (!this.props.transferFormView) return null;
    let cullingStock = [];
    let col = [];
    let hhHead = [];
    let ddHead = [];
    let hatcheryOptions = [];
    const Option = Select.Option;
    if (this.state.hhHeadList && this.state.hhHeadList.length > 0) {
      col = this.state.hhHeadList.map((row, i) => {
        return <col key={i} style={{ width: '100px' }} />;
      });
      hhHead = this.state.hhHeadList.map((row, i) => {
        let field = row.split(' ');
        return (
          <th key={i} colSpan="2">
            <Trans>{field[2]}</Trans>
          </th>
        );
      });
      ddHead = this.state.hhHeadList.map((row, i) => {
        return (
          <React.Fragment key={i}>
            <th>
              <Trans>Qty</Trans>
            </th>
            <th>
              <Trans>Sale</Trans>
            </th>
          </React.Fragment>
        );
      });
    }
    if (this.state.cullingStockList && this.state.cullingStockList.length > 0) {
      cullingStock = this.state.cullingStockList.map((row, i) => {
        //console.log(row);
        return (
          <tr key={i}>
            <td>{row.flockId}</td>
            <td>{row.strainCode}</td>
            <td>{row.epDate}</td>

            {this.state.hhHeadList.map((hh, ii) => {
              let field = hh.split(' ');
              let col = field[2].toLowerCase();
              let colName = (
                row.flockId +
                '|' +
                row.epDate +
                '|' +
                col +
                '|' +
                ii
              ).replace(/'/gi, '');
              return (
                <React.Fragment key={ii}>
                  <td>{row[col] ? row[col].toLocaleString() : ''}</td>
                  <td>
                    {row[col] ? (
                      <NumberFormat
                        style={{ width: '100px', textAlign: 'right' }}
                        className={'input_base'}
                        thousandSeparator={true}
                        decimalScale={0}
                        name={colName}
                        //ref={this[inputName]}
                        //getInputRef={el => (this[inputName] = el)}
                        value={
                          this.state.epDateList[colName]
                            ? this.state.epDateList[colName] || ''
                            : null
                        }
                        onChange={(e) => this.setOnChangeCalc(e, row, col)}
                      />
                    ) : (
                      ''
                    )}
                  </td>
                </React.Fragment>
              );
            })}
          </tr>
        );
      });
    }

    if (this.state.hatcheryList && this.state.hatcheryList.length > 0) {
      hatcheryOptions = this.state.hatcheryList.map((row, i) => {
        return (
          <Option key={i} value={row.hatcheryId}>
            {row.hatcheryId}
          </Option>
        );
      });
    }
    return (
      <Fragment>
        <h2>
          <Trans>Transfer Info</Trans>
        </h2>
        <div className="board_list2">
          <table>
            <colgroup>
              <col style={{ width: '20%' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '20%' }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <Trans>Date</Trans> <span className="red">*</span>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'transferDate', //*** */
                    type: 'D', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    placeholder: 'transferDate',
                  })}
                </td>
                <th>
                  <Trans>Order No</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'pOrderNo', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    maxLength: 20,
                    //placeholder: 'Date',
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Hatchery</Trans> <span className="red">*</span>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'hatcheryId', //*** */
                    type: 'SELECT', //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    that: this, //*** */
                    options: hatcheryOptions, //*** */ type:'SELECT' options*/
                    placeholder: 'Hatchery', //*** */
                    width: 210, // px제외 : 없으면 100% */
                  })}
                </td>
                <th>
                  <Trans>Truck No</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'truckNo', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    maxLength: 10,
                    //placeholder: 'Date',
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Remark</Trans>
                </th>
                <td colSpan="3">
                  {GridUtil.renderInput({
                    inputName: 'remarks', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    maxLength: 1000,
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Qty</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'transferQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    disabled: true,
                    width: 100, // px제외 : 없으면 100% */
                  })}
                </td>
                <th>{/* <Trans>Weight</Trans> */}</th>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="txt_center mT10 mB10">
          <button className="btn_gray" onClick={() => this.fnSearch()}>
            <Trans>Cancel</Trans>
          </button>
          <button className="btn_save" onClick={() => this.fnSave()}>
            <Trans>save</Trans>
          </button>
        </div>

        <div
          className="board_list ag-theme-balham"
          style={{ height: '300px', width: '100%' }}
        >
          <AgGridReact
            columnDefs={this.state.eggGoodColumnDefs}
            rowData={
              this.state.eggGoodRowData.length > 0
                ? this.state.eggGoodRowData
                : []
            }
            defaultColDef={this.state.defaultColDef}
            enterMovesDownAfterEdit={true}
            enterMovesDown={true}
            frameworkComponents={this.state.frameworkComponents}
            onGridReady={(e) => this.onGridReady(e, 'gridApi')}
            singleClickEdit={true}
            rowSelection={'single'}
            onCellFocused={(e) => {
              e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
            }}
          ></AgGridReact>
        </div>
      </Fragment>
    );
  }
}
export default withAlert()(EggTransferForm);
