import * as Common from 'containers/pages/common';
import i18n from 'i18next';

const CommonRouteList = [
  { uri: '/common/member/1', page: Common.Member.Page1 },
  { uri: '/common/member/member', page: Common.Member.Member },
  { uri: '/common/member/userinfo', page: Common.Member.UserInfo },
  { uri: '/common/member/useraccess', page: Common.Member.UserAccess },
  { uri: '/common/group/1', page: Common.Group.Page1 },
  { uri: '/common/group/usergroup', page: Common.Group.UserGroup },
  { uri: '/common/group/groupinfo', page: Common.Group.GroupInfo },
  { uri: '/common/menu/menu', page: Common.Menu.Menu },

  { uri: '/common/system/code', page: Common.System.Code },
  { uri: '/common/system/codeinfo', page: Common.System.CodeInfo },
  { uri: '/common/system/language', page: Common.System.Language },
  { uri: '/common/system/langinfo', page: Common.System.LangInfo },

  { uri: '/common/basic/farm', page: Common.Basic.Farm },
  { uri: '/common/basic/farminfo', page: Common.Basic.FarmInfo },
  { uri: '/common/basic/breeding_hh', page: Common.Basic.BreedHhInfo },
  { uri: '/common/basic/hatcheryinfo', page: Common.Basic.HatcheryInfo },
  { uri: '/common/basic/plazma', page: Common.Basic.Plazma },
  { uri: '/common/basic/customerinfo', page: Common.Basic.CustomerInfo },
  { uri: '/common/basic/material', page: Common.Basic.Material },
  { uri: '/common/basic/broiler_hh', page: Common.Basic.BroilerHhInfo },
  { uri: '/common/basic/projectinfo', page: Common.Basic.ProjectInfo },
  { uri: '/common/basic/setterinfo', page: Common.Basic.SetterInfo },
  { uri: '/common/basic/hatcherinfo', page: Common.Basic.HatcherInfo },
  { uri: '/common/basic/flockinfo', page: Common.Basic.FlockInfo },

  { uri: '/common/standard/score', page: Common.Standard.ScoreInfo },
  { uri: '/common/standard/breed', page: Common.Standard.BreedStandardInfo },
  {
    uri: '/common/standard/broiler',
    page: Common.Standard.BroilerStandardInfo,
  },
  { uri: '/common/standard/0', page: Common.Standard.StandardPage0 },

  { uri: '/common/work/schedule', page: Common.Work.ScheduleInfo },
  { uri: '/common/work/0', page: Common.Work.WorkPage0 },

  { uri: '/common/materials/info', page: Common.Materials.MaterialInfo },
  { uri: '/common/materials/io', page: Common.Materials.MaterialIOInfo },

  { uri: '/common/test/0', page: Common.Test.Test01 },
  { uri: '/common/download/app', page: Common.Download.DownloadApp },
  { uri: '/common/log', page: Common.Log.Log },
];

export default CommonRouteList;
