import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class ScheduleModify extends React.PureComponent {
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  render() {
    return (
      <Fragment>
        <div className="con_box">
          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Work Name</Trans>
                  </th>
                  <td>
                    <input type="text" name="" className="input_type1" />
                  </td>
                  <th>
                    <Trans>Age of Day</Trans>
                  </th>
                  <td>
                    <input type="text" name="" className="input_type1" />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Deleted</Trans>
                  </th>
                  <td colSpan="3">
                    <ul className="checklist_type1">
                      <li>
                        <input type="radio" name="1" id="1" />
                        <label htmlFor="1">
                          <Trans>Alive</Trans>
                        </label>
                      </li>
                      <li>
                        <input type="radio" name="1" id="2" />
                        <label htmlFor="2">
                          <Trans>Deleted</Trans>
                        </label>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Farm Name</Trans>
                  </th>
                  <td>
                    <input type="text" name="" className="input_type1" />
                  </td>
                  <th>
                    <Trans>FarmClass</Trans>
                  </th>
                  <td>
                    <input type="text" name="" className="input_type1" />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Sex</Trans>
                  </th>
                  <td colSpan="3">
                    <ul className="checklist_type1">
                      <li>
                        <input type="radio" name="1" id="3" />
                        <label htmlFor="3">
                          <Trans>Female</Trans>
                        </label>
                      </li>
                      <li>
                        <input type="radio" name="1" id="4" />
                        <label htmlFor="4">
                          <Trans>Male</Trans>
                        </label>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Feed</Trans>
                  </th>
                  <td colSpan="3">
                    <label htmlFor="">
                      <Trans>Name1</Trans>
                    </label>
                    <input type="text" name="" className="input_type1 mR100" />

                    <label htmlFor="">
                      <Trans>Qty</Trans>
                    </label>
                    <input type="text" name="" className="input_type1" />

                    <br />

                    <label htmlFor="">
                      <Trans>Name2</Trans>
                    </label>
                    <input type="text" name="" className="input_type1 mR100" />

                    <label htmlFor="">
                      <Trans>Qty</Trans>
                    </label>
                    <input type="text" name="" className="input_type1" />

                    <br />

                    <label htmlFor="">
                      <Trans>Name3</Trans>
                    </label>
                    <input type="text" name="" className="input_type1 mR100" />

                    <label htmlFor="">
                      <Trans>Qty</Trans>
                    </label>
                    <input type="text" name="" className="input_type1" />

                    <br />

                    <label htmlFor="" style={{ padding: '0 21px 0 0' }}>
                      <Trans>Remarks</Trans>
                    </label>
                    <input type="text" name="" className="input_type1 w70" />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Vaccine</Trans>
                  </th>
                  <td colSpan="3">
                    <label htmlFor="">
                      <Trans>Name1</Trans>
                    </label>
                    <input type="text" name="" className="input_type1 mR100" />

                    <label htmlFor="">
                      <Trans>Qty</Trans>
                    </label>
                    <input type="text" name="" className="input_type1" />

                    <br />

                    <label htmlFor="">
                      <Trans>Name2</Trans>
                    </label>
                    <input type="text" name="" className="input_type1 mR100" />

                    <label htmlFor="">
                      <Trans>Qty</Trans>
                    </label>
                    <input type="text" name="" className="input_type1" />

                    <br />

                    <label htmlFor="">
                      <Trans>Name3</Trans>
                    </label>
                    <input type="text" name="" className="input_type1 mR100" />

                    <label htmlFor="">
                      <Trans>Qty</Trans>
                    </label>
                    <input type="text" name="" className="input_type1" />

                    <br />

                    <label htmlFor="" style={{ padding: '0 21px 0 0' }}>
                      <Trans>Remarks</Trans>
                    </label>
                    <input type="text" name="" className="input_type1 w70" />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Medicine</Trans>
                  </th>
                  <td colSpan="3">
                    <label htmlFor="">
                      <Trans>Name1</Trans>
                    </label>
                    <input type="text" name="" className="input_type1 mR100" />

                    <label htmlFor="">
                      <Trans>Qty</Trans>
                    </label>
                    <input type="text" name="" className="input_type1" />

                    <br />

                    <label htmlFor="">
                      <Trans>Name2</Trans>
                    </label>
                    <input type="text" name="" className="input_type1 mR100" />

                    <label htmlFor="">
                      <Trans>Qty</Trans>
                    </label>
                    <input type="text" name="" className="input_type1" />

                    <br />

                    <label htmlFor="">
                      <Trans>Name3</Trans>
                    </label>
                    <input type="text" name="" className="input_type1 mR100" />

                    <label htmlFor="">
                      <Trans>Qty</Trans>
                    </label>
                    <input type="text" name="" className="input_type1" />

                    <br />

                    <label htmlFor="" style={{ padding: '0 21px 0 0' }}>
                      <Trans>Remarks</Trans>
                    </label>
                    <input type="text" name="" className="input_type1 w70" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="txt_center mT40">
            <Link to="#">
              <button className="btn_blue">
                <Trans>Modify</Trans>
              </button>
            </Link>
          </div>
        </div>
      </Fragment>
    );
  }
}
