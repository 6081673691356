import React, { Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { moment } from 'components';
import i18n from 'i18next';
import BreedRecvFormHatchery from './BreedRecvFormHatchery'; //입력Form
//import PoReceive from './PoReceive';
import MovedIn from './MovedIn'; //MovedIn List => 선택
import FlockList from './FlockList'; //입력 후 Flock(계군) 선택, Flock 추가
import scrollToComponent from 'react-scroll-to-component';
import { withAlert } from 'react-alert';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import '../Breeding.module.css';

/*
 ** breeding/receive/Flock
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class Hatchery extends React.Component {
  state = {
    target: '',
    rowData: [],
    breedRecvList: [],
    form: {},
    searchFromWkDt: moment(DateUtil.today())
      .add('-1', 'M')
      .format('YYYY-MM-DD'),
    searchToWkDt: DateUtil.today(),
    breedRecvForm: [],
    poReceiveData: [],
    movedInData: [],
    tpBreedRecvData: {},
    showSelectFlock: false,
    showFlockList: false,
    fromHatcheryView: true,
    breedRecvFormView: false,
    deselectAllBreedRecvList: false,

    hatcheryRowData: [],
    hatcheryColumnDefs: [
      {
        headerName: 'Hatchery',
        field: 'hatcheryId',
        width: 100,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: true,
      },
      {
        headerName: 'Transfer Date',
        field: 'transferDate',
        width: 120,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: true,
      },
      {
        headerName: 'Strain',
        field: 'strainName',
        width: 200,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: true,
      },
      {
        headerName: 'Female',
        children: [
          {
            headerName: 'Qty',
            field: 'psdocFQty',
            type: 'numericColumn',
            width: 100,
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: true,
          },
          {
            headerName: 'Bonus',
            field: 'psdocFBonus',
            type: 'numericColumn',
            width: 100,
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: true,
          },
        ],
      },
      {
        headerName: 'Male',
        children: [
          {
            headerName: 'Qty',
            field: 'psdocMQty',
            width: 100,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: true,
          },
          {
            headerName: 'Bonus',
            field: 'psdocMBonus',
            width: 100,
            type: 'numericColumn',
            valueFormatter: function (params) {
              return GridUtil.formatNumber(params);
            },
            //precision: 0,
            cellStyle: { textAlign: 'right' },
            suppressSizeToFit: true,
          },
        ],
      },
      {
        headerName: 'Truck No',
        field: 'truckNo',
        width: 150,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: false,
      },
      {
        headerName: 'PO No',
        field: 'orderNo',
        width: 150,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: false,
      },
    ],

    breedRecvRowData: [],
    breedRecvDefs: [
      {
        headerName: 'Receive No',
        field: 'docReceiveNo',
        width: 100,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: true,
      },
      {
        headerName: 'Hatchery',
        field: 'hatcheryId',
        width: 100,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: true,
      },
      {
        headerName: 'Recv Date',
        field: 'receiveDate',
        width: 100,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: true,
      },
      {
        headerName: 'Strain',
        field: 'strainName',
        width: 150,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: true,
      },

      {
        headerName: 'Female',
        field: 'totalRecvQtyF',
        width: 100,
        type: 'numericColumn',
        valueFormatter: function (params) {
          return GridUtil.formatNumber(params);
        },
        //precision: 0,
        cellStyle: { textAlign: 'right' },
        suppressSizeToFit: true,
      },
      {
        headerName: 'Male',
        field: 'totalRecvQtyM',
        width: 100,
        type: 'numericColumn',
        valueFormatter: function (params) {
          return GridUtil.formatNumber(params);
        },
        //precision: 0,
        cellStyle: { textAlign: 'right' },
        suppressSizeToFit: true,
      },

      {
        headerName: 'Flock',
        field: 'flockId',
        width: 150,
        cellStyle: { textAlign: 'center' },
        suppressSizeToFit: true,
      },

      {
        headerName: 'Remarks',
        field: 'remarks',
        width: 300,
        cellStyle: { textAlign: 'left' },
        suppressSizeToFit: false,
      },
    ],
    pinnedBottomRowData: [],
    defaultColDef: {
      editable: false,
      width: 100,
      filter: true,
      resizable: true,
      sortable: true,
      suppressSizeToFit: false,
    },
  };
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnSearch();
    let params = [];
    this.fnSelectHatchery(params);
  }
  fnInit = () => {
    let that = this;
    window.scrollTo(0, 0);
    this.setState(
      {
        breedRecvFormView: false,
        showFlockList: false,
        breedRecvForm: [],
      },
      function () {
        that.fnSearch();
      }
    );
  };
  fnSearch = () => {
    window.scrollTo(0, 0);

    let that = this;
    let data = {};
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.hatchery = 'Y';
    data.flockYn = 'No';

    data.searchFromWkDt = this.state.searchFromWkDt;
    data.searchToWkDt = this.state.searchToWkDt;

    if (data.searchFromWkDt > data.searchToWkDt) {
      that.props.alert.show(i18n.t('Please enter Date.'));
      that.searchFromWkDt.focus();
      return false;
    }

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/selectDocTransfer.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            breedRecvFormView: true,
            fromHatcheryView: true,
            hatcheryRowData: response.data.rows1,
            breedRecvRowData: response.data.rows3,
          },
          function () {
            that.fnTotal();
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  fnSetAddType = (param) => {
    window.scrollTo(0, 0);
    this.setState({ addType: param, addTypeP: param }, function () {
      if (param === 'fromHatchery') {
        this.fnSearch(); //breed/receive/selectDocTransfer.do
      } else if (param === 'po') {
        let data = {};
        data.iuFlag = 'I';
        data.receiveDate = DateUtil.today();
        this.setState(
          {
            fromHatcheryView: false,
            breedRecvForm: data,
          },
          function () {}
        );
      } else if (param === 'movedIn') {
        this.fnMovedIn(); //breed/receive/selectMovedIn.do
      }
      //console.log(this.state);
    });
  };
  //From Hatchery 선택
  fnSelectHatchery = (param) => {
    let that = this;
    param.iuFlag = 'I';
    param.receiveDate = DateUtil.today();

    param.recvQtyF = param.psdocFQty;
    param.bonusQtyF = param.psdocFBonus;
    param.recvQtyM = param.psdocMQty;
    param.bonusQtyM = param.psdocMBonus;
    param.pOrderNo = param.orderNo;

    //************** 2020-03-27 */
    param.customerCode = '';

    this.fnReceiveInfo(param);
    this.setState({ deselectAllBreedRecvList: true });
  };
  fnSelectBreedReceive = (param) => {
    console.log(param);
    param.iuFlag = 'U';
    this.fnReceiveInfo(param);
  };
  fnReceiveInfo = (param) => {
    let that = this;
    this.setState({ breedRecvFormView: false, breedRecvForm: [] }, function () {
      that.setState(
        { breedRecvFormView: true, breedRecvForm: param },
        function () {
          scrollToComponent(that['breedRecvFormView'], {
            offset: 0,
            align: 'top',
            duration: 1000,
          });
        }
      );
    });
  };
  //fnSelectMovedIn 선택
  fnSelectMovedIn = (param) => {
    param.iuFlag = 'I';
    param.receiveDate = DateUtil.today();
    param.recvQtyF = param.moveQtyF;
    param.recvQtyM = param.moveQtyM;

    console.log('fnSelectMovedIn', param);
    this.setState(
      { fromHatcheryView: false, breedRecvForm: param },
      function () {
        scrollToComponent(this['breedRecvFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };

  fnMovedIn = () => {
    let that = this;
    let data = {};
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    //data.receiveNo = '';
    that.setState({ fromHatcheryView: false }, function () {});

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/selectMovedIn.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('fnMovedIn response', response);
        that.setState({ movedInData: response.data.rows1 }, function () {
          console.log('fnMovedIn', that.state);
        });
      })
      .catch(function (error) {
        return [];
      });
  };

  fnCancelFromHatchery = (data) => {
    this.fnInit();

    // this.setState(
    //   {
    //     fromHatcheryView: _fromHatcheryView,
    //     breedRecvForm: null
    //   },
    //   function() {}
    // );
  };
  fnSaveFromHatchery = (data) => {
    if (data.iuFlag === 'I') {
      this.setState(
        { showFlockList: true, tpBreedRecvData: data },
        function () {}
      );
    } else {
      this.fnInit();
    }
  };
  //fnSaveFromHatchery
  fnSavePoReceive = (data) => {
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    //data.receiveNo = '';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/savePoReceive.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        //저장후 다시 입력화면으로
        that.setState({ poReceiveData: null }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };

  fnCloseFlockList = (e) => {
    this.setState({ showFlockList: false }, function () {
      this.fnInit();
    });
  };
  setOnChange = (e, params, dateString) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';

    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
      if (params.minVal) {
        if (Number(val) < Number(params.minVal)) {
          val = Number(params.minVal);
        }
      }
    }
    this.setState({ [col]: val }, function () {});
  };
  onRowClickedHatcher = (e) => {
    this.fnSelectHatchery(e.data);
    this.gridApiBreedRecv.deselectAll();
  };
  onGridReadyHatcher = (params) => {
    this.gridApiHatcher = params.api;
    this.gridColumnApiHatcher = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  onRowClickedBreedRecv = (e) => {
    this.fnSelectBreedReceive(e.data);
    this.gridApiHatcher.deselectAll();
  };
  onGridReadyBreedRecv = (params) => {
    let that = this;
    this.gridApiBreedRecv = params.api;
    this.gridColumnApiBreedRecv = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  //합계구하기 - Test
  fnTotal = async (params) => {
    const columns = ['totalRecvQtyF', 'totalRecvQtyM'];
    let result = [];
    if (this.state.breedRecvRowData && this.state.breedRecvRowData.length > 0) {
      columns.forEach((col) => {
        result[col] = !result[col] ? 0 : result[col];
        this.gridApiBreedRecv.forEachNode(function (rowNode, index) {
          if (rowNode.data[col])
            result[col] += Number(rowNode.data[col].toFixed(2));
        });
      });
      this.gridApiBreedRecv.setPinnedBottomRowData(new Array(result));
    }
  };
  render() {
    return (
      <Fragment>
        <h2>
          <Trans>Receive From Hatchery</Trans>
        </h2>

        <div className="con_box">
          <h3>Hatchery Transfer List</h3>
          <div
            style={{ height: '250px', width: '100%', marginBottom: '20px' }}
            className="board_list ag-theme-balham"
          >
            <AgGridReact
              onGridReady={this.onGridReadyHatcher}
              columnDefs={this.state.hatcheryColumnDefs}
              rowData={this.state.hatcheryRowData}
              defaultColDef={this.state.defaultColDef}
              onRowClicked={(e) => this.onRowClickedHatcher(e)}
              onCellFocused={(e) => {
                e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
              }}
              rowSelection={'single'}
            ></AgGridReact>
          </div>
          {this.state.breedRecvFormView ? (
            <div
              style={{ height: 'auto', width: '100%' }}
              className="board_list"
            >
              <BreedRecvFormHatchery
                breedRecvFormView={this.state.breedRecvFormView}
                ref={(section) => {
                  this.breedRecvFormView = section;
                }}
                breedRecvForm={this.state.breedRecvForm}
                fnSaveFromHatchery={this.fnSaveFromHatchery} //Save
                fnInit={this.fnInit}
                //fnCancelFromHatchery={this.fnCancelFromHatchery}
              ></BreedRecvFormHatchery>
            </div>
          ) : null}

          <h3>Receive List</h3>
          <div className="f_right">
            <label htmlFor="">
              <Trans>Date</Trans>
            </label>
            {GridUtil.renderInput({
              inputName: 'searchFromWkDt', //*** */
              type: 'D', //*** */
              that: this, //*** */
              statsForm: this.state, //*** */
              setOnChange: this.setOnChange, //*** */
              width: 120, // px제외 : 없으면 100% */
              placeholder: 'Date',
            })}
            &nbsp; ~ &nbsp;
            {GridUtil.renderInput({
              inputName: 'searchToWkDt', //*** */
              type: 'D', //*** */
              that: this, //*** */
              statsForm: this.state, //*** */
              setOnChange: this.setOnChange, //*** */
              width: 120, // px제외 : 없으면 100% */
              placeholder: 'Date',
            })}
            <button className="btn_gray" onClick={() => this.fnSearch()}>
              <Trans>Search</Trans>
            </button>
          </div>
          <div className="both">
            <div
              style={{
                height: '350px',
                width: '100%',
                marginBottom: '10px',
              }}
              className="board_list ag-theme-balham"
            >
              <AgGridReact
                modules={AllModules}
                onGridReady={this.onGridReadyBreedRecv}
                columnDefs={this.state.breedRecvDefs}
                rowData={this.state.breedRecvRowData}
                //pinnedBottomRowData={this.state.pinnedBottomRowData}
                defaultColDef={this.state.defaultColDef}
                onRowClicked={(e) => this.onRowClickedBreedRecv(e)}
                rowSelection={'single'}
                onCellFocused={(e) => {
                  e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
                }}
              ></AgGridReact>
            </div>
          </div>
          {/* Flock 선택 popup */}
          {this.state.showFlockList ? (
            <FlockList
              tpBreedRecvData={this.state.tpBreedRecvData}
              fnCloseFlockList={this.fnCloseFlockList}
              fnSearch={this.fnInit} //선택-저장
            />
          ) : null}
        </div>
      </Fragment>
    );
  }
}
export default withAlert()(Hatchery);
