import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, FormUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import '../Common.module.css';

import CustomerName from 'containers/pages/common/basic/CustomerName';
import MaterialType from 'containers/pages/common/system/MaterialType';
import MaterialIO from 'containers/pages/common/system/MaterialIO';
import MaterialName from './MaterialName';

import { Input, Radio, DatePicker, Button, Popconfirm } from 'antd';
import produce from 'immer';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { withAlert } from 'react-alert';

import SearchInput from 'utils/SearchInput';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class MaterialIORegist extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      oldParams: {},
      params: {
        farmCode: SessionUtil.getFarmCode(),
        dateFormat: SessionUtil.getDateFormat(),
        stockDate: DateUtil.today(),
      },
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.rowNode !== this.props.rowNode) {
      let { rowNode } = this.props;
      this.setState(
        produce((draft) => {
          draft.params = rowNode;
          if (_.isEmpty(rowNode['farmCode']))
            draft.params['farmCode'] = SessionUtil.getFarmCode();
          if (_.isEmpty(rowNode['stockDate']))
            draft.params['stockDate'] = DateUtil.today();
          if (_.isEmpty(rowNode['dateFormat']))
            draft.params['dateFormat'] = SessionUtil.getDateFormat();
          draft.oldParams = draft.params;
          draft.rowNode = rowNode;
        }),
        function () {
          console.log('changed params : ', this.state.params);
        }
      );
    }
  }

  setCode = (name, value) => {
    this.setState(
      produce((draft) => {
        draft.params[name] = value;
      }),
      function () {}
    );
  };

  onChangeParam = (e) => {
    const { name, value } = e.target;
    this.setState(
      produce((draft) => {
        draft.params[name] = value;
      })
    );
  };

  onChangeDatePicker = (date, dateString) => {
    this.setState(
      produce((draft) => {
        draft.params['stockDate'] = dateString;
      })
    );
  };

  fnSaveRowData = (params, insertCallback, updateCallback) => {
    const that = this;
    const url = Server.getRestAPI() + '/basis/checkTcMaterialIO.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data.error) {
          return this.props.alert.error(i18n.t(data.error));
        }
        const callback = Number(data) > 0 ? updateCallback : insertCallback;
        callback(params);
      })
      .catch(function (error) {
        return [];
      });
  };

  checkMandatoryFields = (params) => {
    let result = false;
    console.log(params);
    if (_.isEmpty(params.customerCode)) {
      this.props.alert.error(i18n.t('customerCode is empty'));
      return result;
    }
    if (_.isEmpty(params.stockDate)) {
      this.props.alert.error(i18n.t('stockDate is empty'));
      return result;
    }
    if (_.isEmpty(params.materialType)) {
      this.props.alert.error(i18n.t('materialType is empty'));
      return result;
    }
    if (_.isEmpty(params.materialCode)) {
      this.props.alert.error(i18n.t('materialCode is empty'));
      return result;
    }
    if (_.isEmpty(params.ioFlag)) {
      this.props.alert.error(i18n.t('ioFlag is empty'));
      return result;
    }
    if (Number(params.qty) <= 0) {
      this.props.alert.error(i18n.t('qty is empty'));
      return result;
    }
    if (SessionUtil.isBroiler() || SessionUtil.isPpl()) {
      if (_.isEmpty(params.projCode)) {
        this.props.alert.error(i18n.t('projCode is empty'));
        return result;
      }
    }
    result = true;
    return result;
  };

  fnRefreshRowData = () => {
    this.fnClearParams();
    this.props.onRowDeselect();
    this.props.fnSetRegDate();
  };

  fnInsertRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/basis/insertTcMaterialIO.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data.error) {
          return this.props.alert.error(i18n.t(data.error));
        }
        that.fnRefreshRowData();
      })
      .catch(function (error) {
        return [];
      });
  };

  fnUpdateRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/basis/updateTcMaterialIO.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data.error) {
          return this.props.alert.error(i18n.t(data.error));
        }
        that.fnRefreshRowData();
      })
      .catch(function (error) {
        return [];
      });
  };

  fnDeleteRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/basis/deleteTcMaterialIO.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data.error) {
          return this.props.alert.error(i18n.t(data.error));
        }
        that.fnRefreshRowData();
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtSave = () => {
    const { params } = this.state;
    console.log(params);
    if (!this.checkMandatoryFields(params)) return;
    this.fnSaveRowData(params, this.fnInsertRowData, this.fnUpdateRowData);
  };

  onBtDelete = () => {
    const { params } = this.state;
    console.log('onBtDelete:', params);
    this.fnDeleteRowData(params);
  };

  onBtClear = () => {
    this.props.onRowDeselect();
  };

  fnClearInput = () => {
    this.setState(
      produce((draft) => {
        draft.params = {};
        draft.params['farmCode'] = SessionUtil.getFarmCode();
        draft.params['dateFormat'] = SessionUtil.getDateFormat();
        draft.params['stockDate'] = DateUtil.today();
      }),
      function () {}
    );
  };

  fnClearParams = () => {
    const that = this;
    this.setState(
      produce((draft) => {
        Object.keys(that.state.params).forEach((key) => {
          if (key === 'farmCode' || key === 'stockDate' || key == 'dateFormat')
            return;
          draft.params[`${key}`] = undefined;
        });
      }),
      function () {
        console.log('this.state.params:', that.state.params);
      }
    );
  };

  // 파라미터의 변경여부 확인
  fnNotChanged = () => {
    return _.isEqual(this.state.params, this.state.oldParams);
  };

  fnIsNewNode = () => {
    const isNew = _.isEmpty(this.props.rowNode);
    console.log('isNew : ', isNew, this.props.rowNode);
    return isNew;
  };

  render() {
    const isPpl = SessionUtil.isPpl();
    const isBroiler = SessionUtil.isBroiler();
    const isProjDisabled = !isPpl; // 지금은 PPL필드만 테스트 가능합니다.
    // console.log( 'isPpl:', isPpl, 'isBroiler:', isBroiler, 'isProjDisabled:', isProjDisabled);
    return (
      <Fragment>
        {/* Feed Stocking */}
        <div className="con_box">
          <h3>
            <Trans>Receive Info</Trans>
          </h3>

          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Customer Code</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    <SearchInput
                      placeholder={i18n.t('Minimum 2 chars or digits')}
                      style={{
                        width: 250,
                        // textTransform: 'uppercase'
                      }}
                      inputParam="customerCode" // SelectInput의 분류
                      inputName="customerCode" // member variable of this.state.params
                      inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
                      // farmClass="A"
                      defaultValue={this.state.params.customerCode}
                      getValue={(value) => this.setCode('customerCode', value)} //***** */
                      that={this} //***** */
                    />
                  </td>
                  <th>
                    <Trans>Stock Date</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    {/*
                      Stock Date의 경우 일일마감 기능으로 인해,
                      등록자가 선택할 수 없습니다.
                      => DatePicker에는 readOnly 기능이 없습니다.
                      => 수정가능으로 변경합니다. by 장태학 2020.03.26
                    */}
                    <DatePicker
                      allowClear={false}
                      defaultValue={DateUtil.now(this.state.params.stockDate)}
                      style={{ width: 250 }}
                      onChange={this.onChangeDatePicker}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Material Type</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    <MaterialType
                      name="materialType"
                      setCode={this.setCode}
                      code={this.state.params.materialType}
                    />
                  </td>
                  <th>
                    <Trans>Material Code</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    <SearchInput
                      placeholder={i18n.t('Minimum 2 chars or digits')}
                      style={{
                        width: 250,
                        // textTransform: 'uppercase'
                      }}
                      inputParam="materialCode" // SelectInput의 분류
                      inputName="materialCode" // member variable of this.state.params
                      inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
                      // farmClass="A"
                      defaultValue={this.state.params.materialCode}
                      pValue0={this.state.params.materialType}
                      getValue={(value) => this.setCode('materialCode', value)} //***** */
                      that={this} //***** */
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Qty</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'qty',
                      type: 'N',
                      that: this,
                      form: this.state.params,
                      minVal: 0,
                      maxLength: 22,
                      value: this.state.params.qty,
                      onChange: this.onChangeParam,
                      width: 250,
                    })}
                  </td>
                  <th>
                    <Trans>In/Out</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    <MaterialIO
                      name="ioFlag"
                      setCode={this.setCode}
                      code={this.state.params.ioFlag}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Invoice No</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'invoiceNo',
                      that: this,
                      form: this.state.params,
                      value: this.state.params.invoiceNo,
                      maxLength: 20,
                      onChange: this.onChangeParam,
                      width: 250,
                    })}
                  </td>
                  <th>
                    <Trans>PO No</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'pOrderNo',
                      that: this,
                      form: this.state.params,
                      value: this.state.params.pOrderNo,
                      maxLength: 20,
                      onChange: this.onChangeParam,
                      width: 250,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Project Code</Trans>
                    {!isProjDisabled && <span className="red">*</span>}
                  </th>
                  <td>
                    <SearchInput
                      placeholder={i18n.t('Minimum 2 chars or digits')}
                      style={{
                        width: 250,
                        // textTransform: 'uppercase'
                      }}
                      inputParam="projectCode" // SelectInput의 분류
                      inputName="projectCode" // member variable of this.state.params
                      inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
                      // farmClass="A"
                      defaultValue={this.state.params.projCode}
                      getValue={(value) => this.setCode('projCode', value)} //***** */
                      that={this} //***** */
                      isDisabled={isProjDisabled}
                    />
                  </td>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'remarks',
                      that: this,
                      form: this.state.params,
                      value: this.state.params.remarks,
                      maxLength: 1000,
                      onChange: this.onChangeParam,
                      width: 250,
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="txt_center mT40">
            <button className="btn_gray" onClick={this.onBtClear}>
              <Trans>Clear</Trans>
            </button>
            {!_.isEmpty(this.state.params.materialCode) && (
              <Popconfirm
                placement="top"
                title={i18n.t('Are you sure you want to delete it?')}
                onConfirm={this.onBtDelete}
                okText={i18n.t('OK')}
                cancelText={i18n.t('Cancel')}
              >
                <button className="btn_gray">
                  <Trans>Delete</Trans>
                </button>
              </Popconfirm>
            )}
            <button
              className={this.fnNotChanged() ? 'btn_gray' : 'btn_save'}
              onClick={this.onBtSave}
              disabled={this.fnNotChanged()}
            >
              <Trans>Save</Trans>
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withAlert()(MaterialIORegist);
