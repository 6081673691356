import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import EggList from './EggList';
import EggDiscardForm from './EggDiscardForm';
import scrollToComponent from 'react-scroll-to-component';
import EggSalesList from './EggSalesList';
import '../Breeding.module.css';

/*
 ** Breeding > Release > Egg : Egg Release
 ** 출고
 **
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class DeliverEggDiscard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eggListView: false,
      eggSalesListView: false,
      discardFormView: false,
      epDate: DateUtil.today(),
      form: {},
      salesForm: [],
      transferForm: [],
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnSearch();
  }
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps !== this.props) {
  //     this.fnSearch();
  //   }
  // }
  fnSearch = () => {
    const that = this;
    window.scrollTo(0, 0);
    this.setState(
      {
        discardFormView: false,
        eggSalesListView: false,
        discardFormView: [],
        eggListView: false,
      },
      function () {
        that.fnClickDiscard();

        // this.setState(
        //   {
        //     discardFormView: false,
        //     eggSalesListView: true,
        //     eggListView: true
        //   },
        //   function() {
        //     that.fnClickDiscard();
        //   }
        // );
      }
    );
  };

  fnClickDiscard = (e) => {
    this.setState(
      {
        discardFormView: true,
        discardForm: {
          ...this.state.discardForm,
          iuFlag: 'I',
          transferType: 'D',
          discardDate: DateUtil.today(),
        },
        eggSalesListView: true,
        eggListView: true,
      },
      function () {
        scrollToComponent(this['discardFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };
  fnClosePopup = (val) => {
    window.scrollTo(0, 0);
    this.setState({ [val]: false }, function () {
      console.log('fnClosePopup===>', this.state);
    });
  };
  render() {
    return (
      <Fragment>
        <h2>
          <Trans>Deliver - Egg Discard</Trans>
        </h2>

        <div className="con_box">
          {/* <EggList eggListView={this.state.eggListView} /> */}
          {/* Sales / Transfer / Discard List */}
          <EggSalesList
            eggSalesListView={this.state.eggSalesListView}
            fnSearch={this.fnSearch} //삭제기능 후 다시 조회
          />
          <EggDiscardForm
            ref={(section) => {
              this.discardFormView = section;
            }}
            discardFormView={this.state.discardFormView}
            discardForm={this.state.discardForm}
            fnClose={this.fnClosePopup}
            fnSearch={this.fnSearch}
          />
        </div>
      </Fragment>
    );
  }
}
