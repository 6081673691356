import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import i18n from 'i18next';
import { moment } from 'components';
import produce from 'immer';

// import STD_LIST_IMAGE from 'images/imsi/std_list.png';
import ScoreMast from './ScoreMast';
import ScoreDtl from './ScoreDtl';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class BroilerStandardInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      mastSelected: false,
      params: {
        countryCode: '',
        standardId: '',
        farmClass: 'B',
      },
      regDate: DateUtil.toDateTimeString(),
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    // console.log('moment.locales() : ', moment.locales());
  }

  setMastParams = (params) => {
    this.setState(
      produce((draft) => {
        draft.params['countryCode'] = params.data['countryCode'];
        draft.params['standardId'] = params.data['standardId'];
      }),
      this.setState({ mastSelected: true })
    );
  };

  setRegDate = () => {
    this.setState({ regDate: DateUtil.toDateTimeString() });
  };

  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Standard - Score</Trans>
        </h2>

        <ScoreMast
          setMastParams={this.setMastParams}
          setRegDate={this.setRegDate}
          farmClass={this.state.params.farmClass}
        />

        <ScoreDtl
          mastSelected={this.state.mastSelected}
          mastParams={this.state.params}
          regDate={this.state.regDate}
        />

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
