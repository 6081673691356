import React, { Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil, PopSearchFarm } from 'utils';
import { Trans } from 'react-i18next';
import { Const, Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';
import { moment } from 'components';
import { Select, Input } from 'antd';
import i18n from 'i18next';
import { withAlert } from 'react-alert';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
  GridOptions,
} from 'components/commons/ag-grid/AgGridUtil';
import '../Hatchery.module.css';
const { Search } = Input;
/*
 ** Hatchery - Transfer - Chick - PsDoc
 ** =============================
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class PsDocForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInstance: this,
      form: [],
      psDocFormView: false,
      broilerDocFormView: false,
      discardFormView: false,
      popSearchFarmView: false,
      searchFromWkDt: moment(DateUtil.today())
        .add('-1', 'M')
        .format('YYYY-MM-DD'),
      searchToWkDt: DateUtil.today(),
      psDocForm: [],
      broilerDocForm: [],
      discardForm: [],
      hatchingInfoList: [],
      hatchingData: {},
      hatchingDataList: [],
      strainCodeList: [],
      customerList: [],
      farmName: '',
      columnDefs: [
        {
          headerName: 'Hen House',
          field: 'hhId',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: false,
        },
        {
          field: 'strainCodeNm',
          hide: true,
        },
        {
          headerName: 'Hatch Date',
          field: 'hatchDate',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Pop(F)',
          field: 'psdocFQty',
          width: 80,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Pop(M)',
          field: 'psdocMQty',
          type: 'numericColumn',
          width: 80,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
      ],
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.psDocFormView !== nextProps.psDocFormView) {
      if (nextProps.psDocFormView) prevState.currentInstance.fnSearch();
      //console.log('*********getDerivedStateFromProps', prevState, nextProps);
      return {
        psDocFormView: nextProps.psDocFormView,
        form: nextProps.psDocForm,
      };
    }
    return null;
  }

  fnSearch = () => {
    window.scrollTo(0, 0);

    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.hatchDate = DateUtil.today();
    data.searchFromWkDt = this.state.searchFromWkDt;
    data.searchToWkDt = this.state.searchToWkDt;
    data.codeId = 'EP26';
    data.calType = 'psDoc';

    if (data.searchFromWkDt > data.searchToWkDt) {
      that.props.alert.show(i18n.t('Please check the date.'));
      that.searchFromWkDt.focus();
      return false;
    }

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/transfer/selectTlHatchingInfoList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('selectGlowingLogList', response.data);
        //if (response.data.rows1.length > 0) {
        that.setState(
          {
            hatchingInfoList: response.data.rows1 ? response.data.rows1 : [],
            strainCodeList: response.data.rows2 ? response.data.rows2 : [],
            //hatchingData: response.data.rows3 ? response.data.rows3[0] : {},
            hatchingDataList: response.data.rows3 ? response.data.rows3 : [],
          },
          function () {
            //console.log(that.state);
          }
        );
        //}
      })
      .catch(function (error) {
        return [];
      });
  };
  //Sales 저장
  fnSave = () => {
    let that = this;
    let data = this.state.form;

    if (!data.hatchDate) {
      that.props.alert.show(i18n.t('Please enter Hatch Date.'));
      this.hatchDate.focus();
      return false;
    }
    if (!data.transferDate) {
      that.props.alert.show(i18n.t('Please enter Transfer Date'));
      this.transferDate.focus();
      return false;
    }

    if (!data.customerCode) {
      that.props.alert.show(i18n.t('Please enter Farm.'));
      that.customerCode.focus();
      return false;
    }
    data.psdocFBox = Number(data.psdocFBox ? data.psdocFBox : 0);
    data.psdocFQty = Number(data.psdocFQty ? data.psdocFQty : 0);
    data.psdocFBonus = Number(data.psdocFBonus ? data.psdocFBonus : 0);

    data.psdocMBox = Number(data.psdocMBox ? data.psdocMBox : 0);
    data.psdocMQty = Number(data.psdocMQty ? data.psdocMQty : 0);
    data.psdocMBonus = Number(data.psdocMBonus ? data.psdocMBonus : 0);

    data.spBox = 0;
    data.spQty = 0;
    data.spBonus = 0;

    data.a2Box = 0;
    data.a2Qty = 0;
    data.a2Bonus = 0;

    data.a1Box = 0;
    data.a1Qty = 0;
    data.a1Bonus = 0;

    data.bmBox = 0;
    data.bmQty = 0;
    data.bmBonus = 0;

    data.bBox = 0;
    data.bQty = 0;
    data.bBonus = 0;

    data.polosBox = 0;
    data.polosQty = 0;
    data.polosBonus = 0;

    data.sexBox = 0;
    data.sexQty = 0;
    data.sexBonus = 0;

    if (!checkQty(that)) {
      return false;
    }
    console.log('save', data);

    let qty =
      data.psdocFQty + data.psdocFBonus + data.psdocMQty + data.psdocMBonus;
    if (!qty || qty === 0) {
      that.props.alert.show(i18n.t('Please enter quantity'));
      this.psdocFQty.focus();
      return false;
    }

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.updateId = SessionUtil.getUserId();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.docRelType = 'P';

    // data.strainCode = data.strainCode;

    console.log('save', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/transfer/savePsDoc.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.fnInit();
      })
      .catch(function (error) {
        return [];
      });

    function checkQty(that) {
      let clos = ['psdocFQty', 'psdocMQty'];
      let chk = true;
      clos.some((col) => {
        let co = col.replace('Box', '').replace('Qty', '').replace('Bonus', '');
        let hatchingQty = 0;
        let hhId = that.state.form.hhId;
        let hatching = that.state.hatchingDataList.find(
          (row) => row.hhId === hhId
        );
        hatchingQty = hatching ? Number(hatching[co + 'Qty']) : 0;
        let qty =
          Number(
            that.state.form[co + 'Qty'] ? that.state.form[co + 'Qty'] : 0
          ) +
          Number(
            that.state.form[co + 'Bonus'] ? that.state.form[co + 'Bonus'] : 0
          );

        if (qty > hatchingQty) {
          that.props.alert.show(i18n.t('The quantity is exceeded.'));
          //that[col].focus();
          chk = false;
          return true;
        }
      });
      return chk;
    }
  };

  //Input field 만들기 - text, Number, Time, Data
  setOnChange = (e, params) => {
    let that = this;
    let col = '';
    let val = '';
    let qtys = 0;
    let bonus = 0;

    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
      if (col === 'psdocFBox' || col === 'psdocMBox') {
        qtys = val * 70;
        bonus = val * 3;
      }
    }

    this.setState(
      {
        [col]: val,
        form: {
          ...that.state.form,
          [col]: val,
          [col === 'psdocFBox' || col === 'psdocMBox'
            ? col.replace('Box', 'Qty')
            : null]: qtys,
          [col === 'psdocFBox' || col === 'psdocMBox'
            ? col.replace('Box', 'Bonus')
            : null]: bonus,
        },
      },
      function () {
        that.fnSelectHatchingList(col);
      }
    );
  };

  fnSelectHatchingList = (col) => {
    console.log('*****', col);
    let data = {};
    let that = this;

    //if (col === 'hatchDate' || col === 'strainCode') {
    if (col === 'hatchDate') {
      let hatchDate = that.state.form.hatchDate;
      let strainCode = that.state.form.strainCode;
      //if (hatchDate && strainCode) {
      if (hatchDate) {
        data.farmCode = SessionUtil.getFarmCode();
        data.countryCode = SessionUtil.getCountryCode();
        data.dateFormat = SessionUtil.getDateFormat();
        data.hatcheryId = SessionUtil.getHatcheryId();
        data.hatchDate = that.state.form.hatchDate;
        data.strainCode = that.state.form.strainCode;
        data.sql = 'selectPsDocHatchingList';
        console.log(data);
        //data.receiveNo = '';
        const url = Server.getRestAPI();
        axios({
          method: 'post',
          url: url + '/hatchery/transfer/selectSql.do',
          data: qs.stringify(data),
          withCredentials: true,
          credentials: 'same-origin',
        })
          .then(function (response) {
            if (response.data.rows1.length > 0) {
              that.setState(
                {
                  //hatchingData: response.data.rows1[0],
                  hatchingDataList: response.data.rows1,
                },
                function () {
                  console.log(that.state.hatchingData);
                }
              );
            }
          })
          .catch(function (error) {
            return [];
          });
      }
    }
  };

  setOnChangeSelect = (value, col) => {
    let that = this;
    this.setState({ form: { ...this.state.form, [col]: value } }, function () {
      that.fnSelectHatchingList(col);
    });
  };
  fnSearchFarm = (value) => {
    this.setState(
      { popSearchFarmView: true, farmCode: value, farmClass: '' },
      function () {}
    );
  };
  fnClosePop = (data) => {
    this.setState({ [data]: false }, function () {});
  };
  fnSelectFarm = (data) => {
    console.log(data);
    this.setState(
      {
        form: {
          ...this.state.form,
          customerCode: data.farmCode,
          customerName: data.farmName,
        },
      },
      function () {
        console.log(this.state);
      }
    );
  };
  fnCancel = () => {
    this.setState(
      {
        form: {
          iuFlag: 'I',
          hatchDate: DateUtil.today(),
          transferDate: DateUtil.today(),
        },
      },
      function () {}
    );
  };
  setOnChangeFarm = (e) => {
    console.log(e);
    this.setState(
      {
        form: { ...this.state.form, customerCode: e.target.value },
      },
      function () {}
    );
  };
  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  onCellClicked = (e) => {
    console.log(e.data);
    const { data } = e;
    this.setState({
      form: {
        ...this.state.form,
        strainCodeNm: data.strainCodeNm,
        strainCode: data.strainCode,
        hatchDate: data.hatchDate,
        hatcheryId: data.hatcheryId,
        psdocFQty: data.psdocFQty,
        psdocMQty: data.psdocMQty,
        hhId: data.hhId,
        eggReceiveNo: data.eggReceiveNo,
        settingSeq: data.settingSeq,
      },
      iuFlag: 'I',
    });
  };
  render() {
    if (!this.props.psDocFormView) return null;
    return (
      <Fragment>
        <h3 className="mT20">
          <Trans>PS DOC</Trans>
        </h3>

        <div className="board_list2">
          <table>
            <colgroup>
              <col style={{ width: '15%' }} />
              <col />
              <col style={{ width: '15%' }} />
              <col style={{ width: '30%' }} />
            </colgroup>
            <tbody>
              <tr style={{ height: '40px' }}>
                <th>
                  <Trans>Hatch Date</Trans>
                </th>
                <td>{this.state.form.hatchDate}</td>
                <th>
                  <Trans>Hen House</Trans> <span className="red">*</span>
                </th>
                <td>{this.state.form.hhId}</td>
              </tr>
              <tr>
                <th>
                  <Trans>Transfer Date</Trans> <span className="red">*</span>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'transferDate', //*** */
                    type: 'D', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    placeholder: 'transferDate',
                  })}
                </td>
                <th>
                  <Trans>Order No</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'orderNo', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 150, // px제외 : 없으면 100% */
                    maxLength: 20,
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Farm</Trans> <span className="red">*</span>
                </th>
                <td>
                  <Search
                    placeholder="Farm Code"
                    onSearch={(value) => this.fnSearchFarm(value)}
                    enterButton
                    style={{ width: '300px' }}
                    ref={(input) => {
                      this['customerCode'] = input;
                    }}
                    value={this.state.form.customerCode}
                    onChange={(value) => this.setOnChangeFarm(value)}
                  />
                </td>
                <th>
                  <Trans>Truck No.</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'truckNo', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    maxLength: 10,
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Remarks</Trans>
                </th>
                <td colSpan="3">
                  {GridUtil.renderInput({
                    inputName: 'remarks', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    //width: 120, // px제외 : 없으면 100% */
                    maxLength: 1000,
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>PS DOC</Trans>
                </th>
                <td colSpan="3">
                  <font className="wtitle">
                    <Trans>PS Female</Trans>
                  </font>
                  {GridUtil.renderInput({
                    inputName: 'psdocFBox', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}

                  <font className="mR20"> Box</font>
                  {GridUtil.renderInput({
                    inputName: 'psdocFQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}

                  <font className="mR10"> Qty</font>
                  <font className="mR10">+</font>
                  {GridUtil.renderInput({
                    inputName: 'psdocFBonus', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}

                  <font className="mR10"> Bonus</font>

                  <br />
                  <font className="wtitle">
                    <Trans>PS Male</Trans>
                  </font>
                  {GridUtil.renderInput({
                    inputName: 'psdocMBox', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}

                  <font className="mR20"> Box</font>
                  {GridUtil.renderInput({
                    inputName: 'psdocMQty', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}

                  <font className="mR10"> Qty</font>
                  <font className="mR10">+</font>
                  {GridUtil.renderInput({
                    inputName: 'psdocMBonus', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 70, // px제외 : 없으면 100% */
                    minVal: 0,
                  })}

                  <font className="mR10"> Bonus</font>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="txt_center mT10 mB10">
          <button className="btn_gray" onClick={() => this.fnCancel()}>
            <Trans>Cancel</Trans>
          </button>
          <button className="btn_save" onClick={() => this.fnSave()}>
            <Trans>save</Trans>
          </button>
        </div>

        <div
          className="board_list ag-theme-balham"
          style={{ height: '300px', width: '100%' }}
        >
          {GridUtil.renderInput({
            inputName: 'searchFromWkDt', //*** */
            type: 'D', //*** */
            that: this, //*** */
            statsForm: this.state, //*** */
            setOnChange: this.setOnChange, //*** */
            width: 120, // px제외 : 없으면 100% */
            placeholder: 'Date',
          })}
          &nbsp; ~ &nbsp;
          {GridUtil.renderInput({
            inputName: 'searchToWkDt', //*** */
            type: 'D', //*** */
            that: this, //*** */
            statsForm: this.state, //*** */
            setOnChange: this.setOnChange, //*** */
            width: 120, // px제외 : 없으면 100% */
            placeholder: 'Date',
          })}
          <button className="btn_gray" onClick={() => this.fnSearch()}>
            <Trans>Search</Trans>
          </button>
          <AgGridReact
            onGridReady={(e) => this.onGridReady(e, 'g2')}
            columnDefs={this.state.columnDefs}
            rowData={
              this.state.hatchingDataList ? this.state.hatchingDataList : []
            }
            defaultColDef={this.state.defaultColDef}
            rowSelection="single"
            onCellClicked={(e) => this.onCellClicked(e)}
            onCellFocused={(e) => {
              e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
            }}
          ></AgGridReact>
        </div>

        {this.state.popSearchFarmView ? (
          <PopSearchFarm
            popSearchFarmView={this.state.popSearchFarmView}
            farmCode={this.state.farmCode}
            farmClass={this.state.farmClass}
            fnClosePop={this.fnClosePop}
            fnSelectFarm={this.fnSelectFarm}
          />
        ) : null}
      </Fragment>
    );
  }
}
export default withAlert()(PsDocForm);
