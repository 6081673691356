import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { Const, Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import 'moment/locale/ko.js';
import 'moment/locale/vi.js';
import { DateUtil } from 'utils';
import i18n from 'i18next';
import { Switch, message, DatePicker, Select, Popconfirm } from 'antd';
import NumberFormat from 'react-number-format';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';
/*
 ** Breed - Receive - Hen House - Move
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class PopMoveIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      popMoveInView: false,
      form: [],
      currentQty: 0,
      currentQtyF: 0,
      currentQtyM: 0,
      inQty: 0,
      inQtyF: 0,
      inQtyM: 0,
      chickinDate: '',
      moveDate: '',
      remarks: '',
      farmCodeList: [],
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.popMoveInView !== nextProps.popMoveInView) {
      console.log('@@@@@@@@form', prevState, nextProps);
      return {
        form: nextProps.popMoveRow,
        popMoveInView: nextProps.popMoveInView,
      };
    }
    return null;
  }

  //UNSAFE_componentWillReceiveProps(nextProps) {
  //console.log('UNSAFE_componentWillReceiveProps', nextProps.form);
  //this.setState(nextProps.popMoveOutView, function() {
  // let _capa = Number(this.state.capacity);
  // _capa =
  //   _capa -
  //   (Number(this.state.chickinQtyF) + Number(this.state.chickinQtyM));
  // this.setState(
  //   {
  //     moveDate: this.state.fromChickinDate,
  //     capa: _capa,
  //     inQtyF: 0,
  //     inQtyM: 0,
  //     remarks: ''
  //   },
  //   function() {
  //     this.fnCalcQty(this.state, '');
  //   }
  // );
  //});
  //}

  fnInit = () => {
    let _capa = Number(this.state.capacity);
    _capa =
      _capa - (Number(this.state.chickinQtyF) + Number(this.state.chickinQtyM));

    this.setState(
      {
        moveDate: this.state.fromChickinDate,
        capa: _capa,
        inQtyF: 0,
        inQtyM: 0,
        remarks: '',
      },
      function () {
        this.fnCalcQty(this.state.form, '');
      }
    );
  };
  // setOnChange = e => {
  //   const that = this;
  //   const col = e.target.name ? e.target.name : '';
  //   this.setState({ [col]: e.target.value }, function() {
  //     that.fnCalcQty(this.state, col);
  //   });
  // };
  // FarmCode 정보 가져오기

  fnCalcQty = (data, col) => {
    //chickinQty
    if (col === '') {
      //처음
      // let _chickinQty =
      //   Number(data.currentQtyF ? data.currentQtyF : 0) +
      //   Number(data.currentQtyM ? data.currentQtyM : 0);
      // this.setState({ currentQty: _chickinQty }, function() {});
    } else {
      let _inQty =
        Number(data.moveQtyF ? data.moveQtyF : 0) +
        Number(data.moveQtyM ? data.moveQtyM : 0);

      if (
        _inQty >
        Number(data.fromMoveQtyF ? data.fromMoveQtyF : 0) +
          Number(data.fromMoveQtyM ? data.fromMoveQtyM : 0)
      ) {
        this.props.alert.show(i18n.t('The quantity is exceeded.'));
        //this.setState({ form: { ...this.state.form, [col]: 0 } });

        this[col].focus();
        //return false;
      }

      this.setState(
        {
          form: {
            ...this.state.form,
            moveQty: _inQty,
          },
        },
        function () {}
      );
    }
  };
  fnSave = () => {
    let that = this;
    let data = this.state.form;

    let moveQtyF = Number(data.moveQtyF ? data.moveQtyF : 0);
    let moveQtyM = Number(data.moveQtyM ? data.moveQtyM : 0);

    if (moveQtyF === 0 && moveQtyM === 0) {
      this.props.alert.show(i18n.t('Please enter quantity.'));
      this.moveQtyF.focus();
      return false;
    }

    //   this.props.alert.show(i18n.t('The quantity is exceeded.'));
    //   this.outQty.focus();
    //   return false;
    // }

    if (moveQtyF + moveQtyM > Number(this.state.form.capa)) {
      this.setState({ visible: true });
    } else {
      this.setState({ visible: false });
      this.confirm(); //수량 정상이면 저장
    }
  };
  handleVisibleChange = (visible) => {
    if (!visible) {
      this.setState({ visible });
      return;
    }
  };
  confirm = () => {
    let that = this;
    let data = this.state.form;
    let moveQtyF = Number(data.moveQtyF ? data.moveQtyF : 0);
    let moveQtyM = Number(data.moveQtyM ? data.moveQtyM : 0);

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.moveDate = data.moveDate ? data.moveDate : DateUtil.today();
    data.moveQtyF = moveQtyF;
    data.moveQtyM = moveQtyM;
    data.iuFlag = 'I';
    //empty check
    if (!data.flockId) {
      data.flockId = data.fromFlockId;
      //data.hhId = data.fromHhId;
    }

    //data.receiveNo = '';
    console.log('saveMoveout', data);
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/saveMovein.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.fnInit();
        that.props.fnClosePop('popMoveInView');
        // that.setState(
        //   { showFlockList: true, tpBreedRecvData: response.data.rows1[0] },
        //   function() {
        //     console.log(that.state);
        //   }
        // );
      })
      .catch(function (error) {
        return [];
      });
  };
  cancel = () => {
    this.setState({ visible: false });
  };
  onDateChange = (date, dateString, name) => {
    this.setState({ [name]: dateString }, function () {
      console.log(this.state);
    });
  };

  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  renderInput = (inputName, ty, dec, len, maxLength) => {
    let cl = len ? 'input_base w' + len : 'input_base w30';
    let ww = len ? len : '30';

    if (ty && ty === 'N') {
      cl = len ? 'input_base w' + len : 'input_base';

      return (
        <NumberFormat
          className={cl}
          style={{ textAlign: 'right' }}
          //style={{ width: len ? len + 'px' : null }}
          thousandSeparator={true}
          decimalScale={dec ? dec : 0}
          name={inputName}
          getInputRef={(el) => (this[inputName] = el)}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || 0 : 0
          }
          onChange={(e) => this.setOnChange(e, ty)}
        />
      );
    } else if (ty && ty === 'D') {
      return (
        <DatePicker
          name={inputName}
          //className={cl}
          style={{ width: len + '%' }}
          ref={(input) => {
            this[inputName] = input;
          }}
          value={
            this.state.form[inputName]
              ? moment(this.state.form[inputName])
              : null
          }
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
          allowClear={false}
        />
      );
    } else {
      return (
        <input
          type="text"
          name={inputName}
          className={cl}
          //style={{ width: len ? len : null }}
          maxLength={maxLength ? maxLength : null}
          ref={this[inputName]}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : ''
          }
          onChange={(e) => this.setOnChange(e)}
        />
      );
    }
  };

  setOnChange = (e, params) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    that.setState({ form: { ...this.state.form, [col]: val } }, function () {
      that.fnCalcQty(this.state.form, col);
    });
  };

  render() {
    if (!this.props.popMoveInView) return null;

    let row = this.state.form;
    const Option = Select.Option;
    const farmOptions = this.state.farmCodeList.map((d, i) => (
      <Option key={i} value={d.farmCode}>
        {d.farmName}
      </Option>
    ));
    return (
      <Fragment>
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            margin: 'auto',
            zIndex: 1000,
            backgroundColor: 'rgba(0,0,0, 0.5)',
          }}
        >
          <div
            style={{
              position: 'absolute',
              width: '900px',
              height: '380px',
              left: '50%',
              transform: 'translateX(-50%)',
              top: '100px',
              //bottom: '15%',
              margin: 'auto',
              borderRadius: '10px',
              background: 'white',
            }}
          >
            <div className="pop_box">
              <h3>
                <Trans>Move In</Trans>
              </h3>
              <ul className="divide mT10">
                <ul className="layer_list">
                  <li style={{ width: '100%' }}>
                    <span className="f_left">
                      <Trans>Flock : </Trans> {row.flockId}
                    </span>
                    <span className="f_left mL20">
                      <Trans>HH : </Trans>
                      {row.hhId}
                    </span>

                    <div className="board_list">
                      <table>
                        <colgroup>
                          <col style={{ width: '15%' }} />
                          <col style={{ width: '20%' }} />
                          <col style={{ width: '15%' }} />
                          <col style={{ width: '20%' }} />
                          <col />
                        </colgroup>
                        <thead>
                          <tr>
                            <th colSpan="2">Female</th>
                            <th colSpan="2">Male</th>
                            <th>Qty/Capa</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              {row.fromMoveQtyF
                                ? row.fromMoveQtyF.toLocaleString()
                                : 0}
                            </th>
                            <td style={{ textAlign: 'left' }}>
                              {GridUtil.renderInput({
                                inputName: 'moveQtyF', //*** */
                                type: 'N', //*** */
                                that: this, //*** */
                                statsForm: this.state.form, //*** */
                                disabled: true,
                                setOnChange: this.setOnChange, //*** */
                                //width: 70, // px제외 : 없으면 100% */
                                //dec: 2,
                                //maxLength: 6,
                                //maxVal: 999.99,
                              })}
                            </td>
                            <th>
                              {row.fromMoveQtyM
                                ? row.fromMoveQtyM.toLocaleString()
                                : 0}
                            </th>
                            <td style={{ textAlign: 'left' }}>
                              {GridUtil.renderInput({
                                inputName: 'moveQtyM', //*** */
                                type: 'N', //*** */
                                that: this, //*** */
                                statsForm: this.state.form, //*** */
                                disabled: true,
                                setOnChange: this.setOnChange, //*** */
                                //width: 70, // px제외 : 없으면 100% */
                                //dec: 2,
                                //maxLength: 6,
                                //maxVal: 999.99,
                              })}
                            </td>
                            <td>
                              <span>
                                {row.moveQty ? row.moveQty : 0}
                                {' / '}
                                {row.capa ? row.capa.toLocaleString() : '0'}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <Trans>Move Date</Trans>
                            </th>
                            <td colSpan="4" style={{ textAlign: 'left' }}>
                              {GridUtil.renderInput({
                                inputName: 'moveDate', //*** */
                                type: 'D', //*** */
                                that: this, //*** */
                                statsForm: this.state.form, //*** */
                                setOnChange: this.setOnChange, //*** */
                                width: 120, // px제외 : 없으면 100% */
                                placeholder: 'moveDate',
                              })}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <Trans>Remarks</Trans>
                            </th>
                            <td colSpan="4" style={{ textAlign: 'left' }}>
                              {GridUtil.renderInput({
                                inputName: 'moveReason', //*** */
                                type: '', //*** */
                                that: this, //*** */
                                statsForm: this.state.form, //*** */
                                setOnChange: this.setOnChange, //*** */
                                //width: 120, // px제외 : 없으면 100% */
                                maxLength: 100,
                                //placeholder: 'Date',
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                </ul>
              </ul>
              <div className="f_right mT10 mB30">
                <Popconfirm
                  title={i18n.t(
                    'Over Capacity\nExceeded the appropriate number.\nWould you like to put it though?'
                  )}
                  visible={this.state.visible}
                  onVisibleChange={this.handleVisibleChange}
                  onConfirm={this.confirm} //실행
                  onCancel={this.cancel} //this.state.visible:false
                  okText={i18n.t('OK')}
                  cancelText={i18n.t('Cancel')}
                >
                  <button
                    className="btn_save"
                    onClick={(e) => this.fnSave(row)}
                  >
                    <Trans>Save</Trans>
                  </button>
                  <button
                    className="btn_gray"
                    onClick={(e) => this.props.fnClosePop('popMoveInView')}
                  >
                    <Trans>Cancel</Trans>
                  </button>
                </Popconfirm>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withAlert()(PopMoveIn);
