import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, FormUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import '../Common.module.css';

import CountryCode from 'containers/pages/common/system/CountryCode';

import { Radio } from 'antd';
import produce from 'immer';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { withAlert } from 'react-alert';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class SetterRegist extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      oldParams: {},
      params: {
        hatcheryId: SessionUtil.getHatcheryId(),
        inuse: 'N',
      },
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    const { params } = this.state;
    this.setState({ oldParams: params });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.rowNode !== this.props.rowNode) {
      const { rowNode } = this.props;
      this.setState(
        produce((draft) => {
          draft.params = _.cloneDeep(rowNode);
          if (this.fnIsNewNode())
            draft.params['hatcheryId'] = SessionUtil.getHatcheryId();
          draft.oldParams = draft.params;
        }),
        function () {
          // console.log('changed params : ', this.state.params);
        }
      );
    }
  }

  onChangeParam = (e) => {
    const { name, value } = e.target;
    this.setState(
      produce((draft) => {
        draft.params[name] = value;
      })
    );
  };

  fnRefreshRowData = () => {
    this.fnClearInput();
    this.props.onRowDeselect();
    this.props.fnSetRegDate();
  };

  fnInsertRowData = (params) => {
    const that = this;
    let url = Server.getRestAPI() + '/basis/insertTlSetterInfo.do';
    console.log(params);
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data.error) {
          return that.props.alert.error(i18n.t(data.error));
        }
        that.fnRefreshRowData();
      })
      .catch(function (error) {
        return [];
      });
  };

  fnUpdateRowData = (params) => {
    const that = this;
    console.log('fnUpdateRowData:', params);
    const url = Server.getRestAPI() + '/basis/updateTlSetterInfo.do';
    // ---------------------------------------------------------
    // (중요) await와 then 은 함께 사용해서는 안됩니다.
    // ---------------------------------------------------------
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data.error) {
          return that.props.alert.error(i18n.t(data.error));
        }
        that.fnRefreshRowData();
      })
      .catch(function (error) {
        return [];
      });
  };

  fnSaveRowData = (params, insertCallback, updateCallback) => {
    const that = this;
    const url = Server.getRestAPI() + '/basis/checkTlSetterInfo.do';
    console.log(params);
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data.error) {
          return this.props.alert.error(i18n.t(data.error));
        }
        const callback = Number(data) > 0 ? updateCallback : insertCallback;
        callback(params);
      })
      .catch(function (error) {
        return [];
      });
  };

  checkMandatoryFields = (params) => {
    let result = false;
    console.log(params);
    if (_.isEmpty(params.hatcheryId)) {
      this.props.alert.error(i18n.t('hatcheryId is empty'));
      return result;
    }
    if (_.isEmpty(params.setterId)) {
      this.props.alert.error(i18n.t('setterId is empty'));
      return result;
    }
    if (
      !params.capacity ||
      params.capacity === '' ||
      Number(params.capacity) <= 0
    ) {
      this.props.alert.error(i18n.t('capacity is empty'));
      return result;
    }
    result = true;
    return result;
  };

  onBtSave = () => {
    const { params } = this.state;
    if (!this.checkMandatoryFields(params)) return;
    this.fnSaveRowData(params, this.fnInsertRowData, this.fnUpdateRowData);
  };

  onBtClear = () => {
    this.props.onRowDeselect();
  };

  fnClearInput = () => {
    this.setState(
      produce((draft) => {
        draft.params = {};
      }),
      function () {}
    );
  };

  // 파라미터의 변경여부 확인
  fnNotChanged = () => {
    return _.isEqual(this.state.params, this.state.oldParams);
  };

  fnIsNewNode = () => {
    const flag = _.isEmpty(this.props.rowNode);
    // console.log(flag, this.props.rowNode);
    return flag;
  };

  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <div className="con_box">
          <h3>
            <Trans>Setter Info</Trans>
          </h3>

          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Setter Id</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'setterId',
                      that: this,
                      form: this.state.params,
                      value: this.state.params.setterId,
                      maxLength: 20,
                      onChange: this.onChangeParam,
                      readOnly: !this.fnIsNewNode(),
                      width: 250,
                    })}
                  </td>
                  <th>
                    <Trans>Capacity</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'capacity',
                      type: 'N',
                      that: this,
                      form: this.state.params,
                      value: this.state.params.capacity,
                      minVal: 0,
                      maxLength: 22,
                      onChange: this.onChangeParam,
                      width: 250,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Brand</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'brand',
                      that: this,
                      form: this.state.params,
                      value: this.state.params.brand,
                      maxLength: 20,
                      onChange: this.onChangeParam,
                      width: 250,
                    })}
                  </td>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'remarks',
                      that: this,
                      form: this.state.params,
                      value: this.state.params.remarks,
                      maxLength: 1000,
                      onChange: this.onChangeParam,
                      width: 250,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>In Use</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'inuse',
                      type: 'YN',
                      that: this,
                      form: this.state.params,
                      value: this.state.params.inuse,
                      onChange: this.onChangeParam,
                      width: 250,
                      disabled: true,
                    })}
                  </td>
                  <th>
                    <Trans>Deleted</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'deleted',
                      type: 'YN',
                      that: this,
                      form: this.state.params,
                      value: this.state.params.deleted,
                      onChange: this.onChangeParam,
                      width: 250,
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="txt_center mT40">
            <button className="btn_gray" onClick={this.onBtClear}>
              <Trans>Clear</Trans>
            </button>
            <button
              className={this.fnNotChanged() ? 'btn_gray' : 'btn_save'}
              disabled={this.fnNotChanged()}
              onClick={this.onBtSave}
            >
              <Trans>Save</Trans>
            </button>
          </div>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}

export default withAlert()(SetterRegist);
