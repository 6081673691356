import React, { Fragment, Component } from 'react';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import DeliverItemlist from 'components/mobile/deliver/DeliverItemlist';
import MobileHeader from 'components/mobile/header/MobileHeader';
import { observer, inject } from 'mobx-react';
import { Button } from 'reactstrap';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 ** 4. 파일명과 클래스명을 동일하게 해 주세요.
 */

@inject((stores) => ({
  mStore: stores.MobileStore,
  mProjectStore: stores.MprojectStore,
  mDeliverStore: stores.MDeliverStore,
}))
@observer
class Deliver extends Component {
  componentDidMount() {
    const { mStore } = this.props;
    mStore.setIuFlagInsert();
    this.fnSelectDeliverList();
  }

  //출고 리스트 찾기 API
  fnSelectDeliverList = () => {
    const { mDeliverStore } = this.props;
    let params = {};

    params.farmCode = SessionUtil.getFarmCode();
    params.dateFormat = SessionUtil.getDateFormat();
    params.projCode = SessionUtil.getProjCode();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/mobile/broiler/mSelectTbTransferDtl.do',
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then((response) => {
        mDeliverStore.setDeliverList(response.data.rows1);
      })
      .catch(function (error) {
        return [];
      });
  };

  //입력 페이지로 이동
  fnMoveDeliverForm = () => {
    this.props.history.push('/mobile/broiler/deliverForm');
  };

  //수정 페이지로 이동
  fnSetDeliverInfo = (value) => {
    const { mStore, mDeliverStore } = this.props;
    mDeliverStore.setSelectedGrowLogForDel(value);
    mStore.setIuFlagUpdate();
    sessionStorage.setItem('deliverSeqNo', value.seqNo);
    this.props.history.push('/mobile/broiler/deliverForm');
  };

  fnPushBack = () => {
    this.props.history.push(Const.MOBILE_LOGINED_PAGE);
  };

  render() {
    const { mStore, mDeliverStore } = this.props;

    return (
      <Fragment>
        {mStore.headerYn == false ? '' : <MobileHeader mStore={mStore} />}

        <div className="pop_header">
          <div className="mback" onClick={(e) => this.fnPushBack()}>
            <Trans>back</Trans>
          </div>
          [{sessionStorage.getItem('projCode')}]
        </div>

        <div className="mobile_content_nofooter">
          <div>
            <div className="mobile_deliverlist_title">
              <p>
                <Trans>Deliever List</Trans>
              </p>
            </div>
            <div
              className="mobile_deliver_btn"
              onClick={(e) => this.fnMoveDeliverForm()}
            >
              <Button color="primary">Deliever</Button>
            </div>
          </div>
          <DeliverItemlist
            deliverList={mDeliverStore.deliverList}
            fnSetDeliverInfo={this.fnSetDeliverInfo}
          />
        </div>
      </Fragment>
    );
  }
}

export default Deliver;
