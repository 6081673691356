import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import SupplierReceivedList from './SupplierReceivedList';
import BreedRecvForm from './BreedRecvForm';
import scrollToComponent from 'react-scroll-to-component';

import { withAlert } from 'react-alert';
import '../Broiler.module.css';

/*
 ** breeding/receive/Flock
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class RcvSupplier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iuFlag: 'I',
      regDate: DateUtil.toDateTimeString(),
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  fnSetRegDate = () => {
    this.setState({
      regDate: DateUtil.toDateTimeString(),
    });
  };

  setOnChangeSelect = (value, col) => {
    this.setState(
      {
        form: { ...this.state.form, [col]: value },
        resisterInfoFormView: true,
      },
      function () {}
    );
  };

  fnClickModify = (data) => {
    this.setState(
      {
        formInfo: data,
        iuFlag: 'U',
      },
      function () {}
    );
    this.setState(
      {
        formInfo: {
          ...this.state.formInfo,
        },
      },
      function () {
        scrollToComponent(this['resisterInfoForm'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };

  render() {
    return (
      <Fragment>
        <h2>
          <Trans>Receive From Supplier</Trans>
        </h2>

        <BreedRecvForm
          ref={(section) => {
            this.registerInfoForm = section;
          }}
          iuFlag={this.state.iuFlag}
          formInfo={this.state.formInfo}
          fnSetRegDate={this.fnSetRegDate}
          regDate={this.state.regDate}
        ></BreedRecvForm>

        <SupplierReceivedList
          regDate={this.state.regDate}
          fnSetRegDate={this.fnSetRegDate}
          fnClickModify={this.fnClickModify}
        />
      </Fragment>
    );
  }
}
