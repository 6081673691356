import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { Calendar, Popover } from 'antd';
import i18n from 'i18next';
import FlockList from './FlockList';
import HenHouseList from './HenHouseList';
import EggProductionForm from './EggProductionForm';
import scrollToComponent from 'react-scroll-to-component';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
  GridOptions,
} from 'components/commons/ag-grid/AgGridUtil';
import { Select } from 'antd';
import '../Breeding.module.css';

/*
 ** Broiler > Glowing Log
 ** =======================
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class EggLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInstance: this,
      form: {
        projStatus: 'A',
      },
      prop: {},
      rowData: [],
      flockList: [],
      projectList: [],
      breedLogList: [],
      eggProductionForm: [],
      eggProductionFormView: false,
      openDatePicker: false,
      growingData: {},
      columnDefsProjectInfo: [
        {
          headerName: 'Flock',
          field: 'flockId',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Hen House',
          field: 'hhId',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Strain',
          field: 'strainName',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Age of Day',
          field: 'days',
          width: 100,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Week',
          field: 'weeks',
          width: 70,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Pop(F)',
          field: 'popF',
          width: 80,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Pop(M)',
          field: 'popM',
          type: 'numericColumn',
          width: 80,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Last Ep Date',
          field: 'lastEpDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Byproduct',
          field: 'byproduct',
          width: 80,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Good Egg',
          field: 'goodEggQty',
          width: 80,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Total Egg',
          field: 'totalEggQty',
          width: 80,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
      ],
      columnDefsBreedLog: [
        {
          headerName: '',
          field: 'iuFlag',
          width: 120,
          cellStyle: { textAlign: 'center' },
          cellRenderer: (params) => {
            console.log('=================================');
            console.log(params.data);
            console.log('=================================');
            if (params.data.iuflag === 'I')
              return (
                '<button class="btn_sel">' +
                i18n.t('Registration') +
                '</button>'
              );
            else if (params.data.iuflag === 'U')
              return (
                '<button class="btn_sel_green">' +
                i18n.t('Modify') +
                '</button>'
              );
            else if (params.data.epDate)
              return (
                '<button class="btn_sel_gray">' + i18n.t('Search') + '</button>'
              );
            else return null;
          },

          suppressSizeToFit: true,
        },
        {
          headerName: 'Work Date',
          field: 'wdate',
          width: 110,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Age',
          field: 'ageDay',
          width: 60,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Pop(F)',
          field: 'popF',
          width: 80,
          cellStyle: { textAlign: 'right' },
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
        {
          headerName: 'Pop(M)',
          field: 'popM',
          width: 80,
          cellStyle: { textAlign: 'right' },
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },

        {
          headerName: 'Damage',
          field: 'damageEggQty',
          width: 80,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Crack',
          field: 'crackEggQty',
          width: 80,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Dirty',
          field: 'dirtyEggQty',
          width: 80,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Miss Shape',
          field: 'missShapeEggQty',
          width: 80,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Jumbo',
          field: 'jumboEggQty',
          width: 80,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Small',
          field: 'smallEggQty',
          width: 80,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Thin',
          field: 'thinEggQty',
          width: 80,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Floor',
          field: 'floorEggQty',
          width: 80,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Good Egg',
          field: 'goodEggQty',
          width: 80,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },

        {
          headerName: 'Total Egg',
          field: 'totalEggQty',
          width: 80,
          cellStyle: { textAlign: 'right' },
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
        },
      ],
      defaultColDef: {
        editable: false,
        width: 100,
        filter: true,
        resizable: true,
        sortable: true,
        suppressSizeToFit: false,
      },
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnSearch();
  }

  fnSearch = () => {
    let data = this.state.form;
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    console.log('------------------');

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/egg/selectEggProductionLogList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('fnSearch.data', response.data);
        that.setState(
          {
            hhChickinList: response.data.rows1,
            flockList: response.data.rows2,
            eggProductionFormView: false,
            eggProductionForm: [],
          },
          function () {
            if (response.data.rows1.length > 0) {
              let rowIndexG1 = 0;
              if (that.state.hhId) {
                rowIndexG1 = that.state.rowIndexG1;
              } else {
              }
              let selectedRows = that['g1'].getRowNode(rowIndexG1);
              selectedRows.setSelected(true);

              that.setState(
                {
                  rowIndexG1: rowIndexG1,
                  flockId: selectedRows ? selectedRows.data.flockId : '',
                  hhId: selectedRows ? selectedRows.data.hhId : '',
                  days: selectedRows ? selectedRows.data.days : 0,
                  flockStatus: selectedRows ? selectedRows.data.flockStatus : 0,
                  chickinDate: selectedRows
                    ? selectedRows.data.chickinDate
                    : '',
                  epDate: selectedRows ? selectedRows.data.layingSdate : '',
                },
                function () {
                  that.fnSearchDtl(selectedRows.data);
                }
              );
            }
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  fnClickDate = (e, val) => {
    this.setState(
      {
        epDate: DateUtil.toFormat(moment(this.state.epDate).add(val, 'days')),
      },
      function () {
        this.fnSearch();
      }
    );
  };
  //Write button Click
  fnClickWrite = (row) => {
    let that = this;

    let data = Object.assign({}, row);

    row.iuFlag = 'I';
    data.iuFlag = 'I';
    data.epDate = this.state.epDate;
    this.setState(
      {
        eggProductionFormView: true,
        eggProductionForm: data,
      },
      function () {
        scrollToComponent(this['eggProductionForm'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };

  fnClickCancel = (val) => {
    this.setState({ [val]: false }, function () {});
  };
  //Modify button Click
  fnClickModify = (row) => {
    let data = Object.assign({}, row);
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();

    data.flockId = this.state.flockId;
    data.hhId = this.state.hhId;
    data.flockStatus = this.state.flockStatus;
    data.chickinDate = this.state.chickinDate;

    that.setState(
      {
        eggProductionFormView: true,
        eggProductionForm: data,
      },
      function () {
        scrollToComponent(this['eggProductionFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };
  fnClickCancel = (row) => {
    if (row.iuFlag === 'I') {
      row.epDate = '';
      row.iuFlag = '';
    } else if (row.iuFlag === 'U') {
      row.iuFlag = '';
    }
    this.setState(
      {
        eggProductionFormView: false,
        growingData: row,
      },
      function () {}
    );
  };
  fnCalendarChange = (value) => {
    this.setState(
      { epDate: moment(value).format('YYYY-MM-DD') },
      function () {}
    );
  };
  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  //Grid row 선택
  onCellClickedGrading = (e) => {
    if (e.column.colId !== 'iuFlag') return false;
    let that = this;
    const selectedRows = that['g2'].getRowNode(e.rowIndex);
    let data = Object.assign({}, e.data);

    console.log('data', data);

    data.stockF = data.populationF;
    data.stockM = data.populationM;
    this.setState(
      { eggProductionFormView: false, eggProductionForm: [] },
      function () {
        if (data.iuflag === 'U') {
          that.fnClickModify(data);
        } else if (data.iuflag === 'I') {
          data.iuFlag = 'I';
          data.epDate = data.wdate;
          data.flockId = this.state.flockId;
          data.hhId = this.state.hhId;
          data.days = this.state.days;
          data.flockStatus = this.state.flockStatus;
          data.chickinDate = this.state.chickinDate;

          console.log('****>', data);
          that.setState(
            {
              eggProductionFormView: true,
              eggProductionForm: data,
            },
            function () {
              scrollToComponent(this['eggProductionFormView'], {
                offset: 0,
                align: 'top',
                duration: 1000,
              });
            }
          );
        } else {
          that.fnClickModify(data);
        }
      }
    );
  };
  onRowClicked = (e) => {
    let data = e.data;
    let that = this;

    that.setState(
      {
        breedLogList: [],
        stock: 0,
        eggProductionForm: [],
        eggProductionFormView: false,

        flockId: data ? data.flockId : '',
        rowIndexG1: e.rowIndex,
        hhId: data ? data.hhId : '',
        days: data ? data.days : 0,
        flockStatus: data ? data.flockStatus : '',
        chickinDate: data ? data.chickinDate : '',
      },
      function () {
        this.fnSearchDtl(data);
      }
    );
  };

  fnSearchDtl = (row) => {
    let data = row ? row : {};
    data.hhId = row ? row.hhId : this.state.hhId;
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.countryCode = SessionUtil.getCountryCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.userId = SessionUtil.getUserId();
    data.epDate = this.state.epDate;
    data.sql = 'selectTpEggprodLog'; //controller 없음

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/egg/selectSql.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            breedLogList: response.data.rows1,
            eggProductionForm: [],
            eggProductionFormView: false,
          },
          function () {
            let toDate = DateUtil.today();
            let fromDate = moment(DateUtil.today())
              .add('-6', 'days')
              .format('YYYY-MM-DD');
            let populationF = Number(data.popF ? data.popF : 0);
            let populationM = Number(data.popM ? data.popM : 0);
            let breedQtyF = 0; //prev
            let breedQtyM = 0; //prev
            let moveQtyF = 0; //prev
            let moveQtyM = 0; //prev
            that.state.breedLogList.map(function (row, i) {
              row.prevPopF = populationF; //전일재고
              row.prevPopM = populationM; //전일재고

              // if (!row.epDate) {
              //   if (row.wdate >= fromDate && row.wdate <= toDate) {
              //     row.iuFlag = 'I';
              //   }
              //   if (Const.TEST_MODE) row.iuFlag = 'U'; // 테스트 및 검수를 위해 입력날짜 제한을 해제 합니다.
              // } else if (row.epDate) {
              //   if (row.wdate >= fromDate && row.wdate <= toDate) {
              //     row.iuFlag = 'U';
              //   }
              // }

              breedQtyF = Number(row.breedQtyF);
              breedQtyM = Number(row.breedQtyM);

              moveQtyF = Number(row.moveQtyF);
              moveQtyM = Number(row.moveQtyM);
            });

            var params = { force: true };
            that['g2'].refreshCells(params);
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  setOnChange = (e, params, dateString) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';

    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }
    this.setState({ form: { ...that.state.form, [col]: val } }, function () {});
  };
  render() {
    const Option = Select.Option;
    return (
      <Fragment>
        <h2>
          <Trans>Egg Production Log</Trans>
        </h2>

        <div className="con_box">
          <h3>
            <Trans>Hen House List</Trans>
          </h3>
          <div
            className="board_list ag-theme-balham"
            style={{ height: '220px', width: '100%' }}
          >
            <AgGridReact
              onGridReady={(e) => this.onGridReady(e, 'g1')}
              columnDefs={this.state.columnDefsProjectInfo}
              rowData={this.state.hhChickinList ? this.state.hhChickinList : []}
              defaultColDef={this.state.defaultColDef}
              onRowClicked={(e) => this.onRowClicked(e)}
              rowSelection="single"
              onCellFocused={(e) => {
                e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
              }}
            ></AgGridReact>
          </div>
          <div
            className="mT20"
            style={{
              width: '100%',
            }}
          >
            <h3>
              <Trans>Egg Production List</Trans>
            </h3>
            <div className="f_right">
              {GridUtil.renderInput({
                inputName: 'epDate', //*** */
                type: '', //*** */
                that: this, //*** */
                statsForm: this.state, //*** */
                setOnChange: this.setOnChange, //*** */
                width: 120, // px제외 : 없으면 100% */
                disabled: true,
              })}
            </div>
            <h2
              style={{
                textAlign: 'center',
                width: '60%',
                position: 'absolute',
                left: '20%',
              }}
            >
              {this.state.hhId ? this.state.hhId : ''}
            </h2>
          </div>

          <div className="board_list2">
            <div
              className="board_list ag-theme-balham"
              style={{ height: '300px', width: '100%' }}
            >
              <AgGridReact
                onGridReady={(e) => this.onGridReady(e, 'g2')}
                columnDefs={this.state.columnDefsBreedLog}
                rowData={this.state.breedLogList ? this.state.breedLogList : []}
                defaultColDef={this.state.defaultColDef}
                onCellClicked={(e) => this.onCellClickedGrading(e, 'g2')}
                rowSelection="single"
                onCellFocused={(e) => {
                  e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
                }}
              ></AgGridReact>
            </div>
            {this.state.eggProductionFormView ? (
              <EggProductionForm
                ref={(section) => {
                  this.eggProductionFormView = section;
                }}
                eggProductionFormView={this.state.eggProductionFormView} //Hen House view 여부
                eggProductionForm={this.state.eggProductionForm}
                fnClickCancel={this.fnClickCancel}
                fnSearch={this.fnSearch}
              />
            ) : null}
          </div>
        </div>
        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
