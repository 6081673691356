import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil, PopSearchFarm } from 'utils';
import { Trans } from 'react-i18next';
import { Const, Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';
import { moment } from 'components';
import i18n from 'i18next';
import { Select, Input, Modal } from 'antd';
import NumberFormat from 'react-number-format';
import { withAlert } from 'react-alert';
import SearchInput from '../release/SearchInput';
import '../Breeding.module.css';
const { Search } = Input;
const { confirm } = Modal;
/*
 ** Breed - Receive - Hen House - Move
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class PopMoveOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInstance: this,
      visible: false,
      popMoveOutView: false,
      form: [],
      currentQty: 0,
      currentQtyF: 0,
      currentQtyM: 0,
      inQty: 0,
      inQtyF: 0,
      inQtyM: 0,
      chickinDate: '',
      moveDate: '',
      remarks: '',
      farmCodeList: [],
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    //this.fnGetFarmCodeRowData();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.popMoveOutView !== nextProps.popMoveOutView) {
      return {
        form: nextProps.popMoveRow,
        popMoveOutView: nextProps.popMoveOutView,
      };
    }
    return null;
  }

  //UNSAFE_componentWillReceiveProps(nextProps) {
  //console.log('UNSAFE_componentWillReceiveProps', nextProps.form);
  //this.setState(nextProps.popMoveOutView, function() {
  // let _capa = Number(this.state.capacity);
  // _capa =
  //   _capa -
  //   (Number(this.state.chickinQtyF) + Number(this.state.chickinQtyM));
  // this.setState(
  //   {
  //     moveDate: this.state.fromChickinDate,
  //     capa: _capa,
  //     inQtyF: 0,
  //     inQtyM: 0,
  //     remarks: ''
  //   },
  //   function() {
  //     this.fnCalcQty(this.state, '');
  //   }
  // );
  //});
  //}

  fnInit = () => {
    let _capa = Number(this.state.capacity);
    _capa =
      _capa - (Number(this.state.currentQtyF) + Number(this.state.currentQtyM));

    this.setState(
      {
        moveDate: this.state.fromChickinDate,
        capa: _capa,
        inQtyF: 0,
        inQtyM: 0,
        remarks: '',
      },
      function () {
        this.fnCalcQty(this.state.form, '');
      }
    );
  };
  // setOnChange = e => {
  //   const that = this;
  //   const col = e.target.name ? e.target.name : '';
  //   this.setState({ [col]: e.target.value }, function() {
  //     that.fnCalcQty(this.state, col);
  //   });
  // };
  // FarmCode 정보 가져오기
  fnGetFarmCodeRowData = () => {
    const that = this;
    let params = {};
    let farmCodeList = [];
    //params.farmCode = SessionUtil.getFarmCode();
    params.farmClass = 'A';

    let url = Server.getRestAPI() + '/basis/selectTcFarmInfo.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ farmCodeList: data }, function () {});
        }
      })
      .catch(function (error) {
        return [];
      });
  };
  fnCalcQty = (data, col) => {
    console.log('fnCalcQty', data, col);
    //chickinQty
    if (col === '') {
      //처음
      let _chickinQty =
        Number(data.currentQtyF ? data.currentQtyF : 0) +
        Number(data.currentQtyM ? data.currentQtyM : 0);

      this.setState({ currentQty: _chickinQty }, function () {});
    } else {
      let _inQty =
        Number(data.moveQtyF ? data.moveQtyF : 0) +
        Number(data.moveQtyM ? data.moveQtyM : 0);

      if (
        _inQty >
        Number(data.currentQtyF ? data.currentQtyF : 0) +
          Number(data.currentQtyM ? data.currentQtyM : 0)
      ) {
        this.props.alert.error(i18n.t('The quantity is exceeded.'));
        this.setState({ form: { ...this.state.form, [col]: 0 } });

        this[col].focus();
        return false;
      }

      this.setState(
        {
          form: {
            ...this.state.form,
            outQty: _inQty,
          },
        },
        function () {}
      );
    }
  };
  fnSave = () => {
    let that = this;
    let data = this.state.form;

    if (!this.state.form.recvFarmCode) {
      this.props.alert.error(i18n.t('Please enter FarmCode.'));
      this.farmName.focus();
      return false;
    }
    if (!this.state.form.moveDate) {
      this.props.alert.error(i18n.t('Please enter Move Date.'));
      this.moveDate.focus();
      return false;
    }
    let moveQtyF = Number(data.moveQtyF ? data.moveQtyF : 0);
    let moveQtyM = Number(data.moveQtyM ? data.moveQtyM : 0);

    let currentQtyF = Number(data.currentQtyF ? data.currentQtyF : 0);
    let currentQtyM = Number(data.currentQtyM ? data.currentQtyM : 0);

    if (moveQtyF === 0 && moveQtyM === 0) {
      this.props.alert.error(i18n.t('Please enter quantity.'));
      this.moveQtyF.focus();
      return false;
    }

    if (moveQtyF > currentQtyF) {
      this.props.alert.error(i18n.t('The female quantity is exceeded.'));
      this.moveQtyF.focus();
      return false;
    }

    if (moveQtyM > currentQtyM) {
      this.props.alert.error(i18n.t('The male quantity is exceeded.'));
      this.moveQtyM.focus();
      return false;
    }

    if (moveQtyF + moveQtyM > currentQtyF + currentQtyM) {
      this.props.alert.error(i18n.t('The quantity is exceeded.'));
      this.moveQtyF.focus();
      return false;
    }

    data.moveQtyF = moveQtyF;
    data.moveQtyM = moveQtyM;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.moveDate = data.moveDate ? data.moveDate : DateUtil.today();
    data.iuFlag = 'I';
    data.currentQty = currentQtyF + currentQtyM - (moveQtyF + moveQtyM);

    if (data.currentQty == 0) {
      this.confirmForHHEmpty(); //Hen House empty confirm.
    } else {
      this.fnSelectCurrentFlockStock(); //Check flock close or not.
    }
  };

  confirmForHHEmpty = () => {
    Modal.confirm({
      content: 'This hen house will be empty. Are you sure?',
      visible: this.state.visible,
      okText: 'Yes',
      cancelText: 'No',
      onOk: this.fnSelectCurrentFlockStock,
      onCancel: this.hideModal,
    });
  };

  confirmForFlockClosed = () => {
    Modal.confirm({
      content: 'This flock will be closed. Are you sure?',
      visible: this.state.visibleFlock,
      okText: 'Yes',
      cancelText: 'No',
      onOk: this.fnInsertMoveout,
      onCancel: this.hideModal,
    });
  };

  hideModal = () => {
    this.setState(
      {
        visible: false,
      },
      function () {}
    );
  };

  fnSelectCurrentFlockStock = () => {
    let that = this;
    let data = this.state.form;

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/selectCurrentStock.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then((response) => {
        this.setState({
          form: {
            ...this.state.form,
            pop: Number(response.data.pop),
          },
        });
        console.log(response.data.pop);
        if (Number(response.data.pop) == 0) {
          this.confirmForFlockClosed(); // Flockk Close confirm
        } else {
          this.fnInsertMoveout(); // insertGrowingLog
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  fnInsertMoveout = () => {
    let that = this;
    let data = this.state.form;

    console.log('save Move Out', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/saveMoveout.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.fnSearch();
        that.props.fnClosePop('popMoveOutView');
      })
      .catch(function (error) {
        return [];
      });
  };

  onDateChange = (date, dateString, name) => {
    this.setState({ [name]: dateString }, function () {
      console.log(this.state);
    });
  };

  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text

  setOnChange = (e, params) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    that.setState({ form: { ...this.state.form, [col]: val } }, function () {
      that.fnCalcQty(this.state.form, col);
    });
  };
  setOnChangeSelect = (value, col) => {
    this.setState(
      { form: { ...this.state.form, [col]: value } },
      function () {}
    );
  };
  fnSearchFarm = (value) => {
    this.setState(
      { popSearchFarmView: true, recvFarmCode: value, farmClass: 'A' },
      function () {}
    );
  };
  fnClosePop = (data) => {
    this.setState({ [data]: false }, function () {});
  };
  setOnChangeFarm = (e) => {
    console.log(e);
    this.setState(
      {
        form: { ...this.state.form, recvFarmCode: e.target.value },
      },
      function () {}
    );
  };
  fnSelectFarm = (data) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          recvFarmCode: data.farmCode, //************** */
          farmName: data.farmName,
        },
      },
      function () {
        console.log(this.state);
      }
    );
  };
  render() {
    if (!this.props.popMoveOutView) return null;

    let row = this.state.form;
    const Option = Select.Option;
    const farmOptions = this.state.farmCodeList.map((d, i) => (
      <Option key={i} value={d.farmCode}>
        {d.farmName}
      </Option>
    ));
    return (
      <Fragment>
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            margin: 'auto',
            zIndex: 1000,
            backgroundColor: 'rgba(0,0,0, 0.5)',
          }}
        >
          <div
            style={{
              position: 'absolute',
              width: '900px',
              height: 'auto',
              left: '50%',
              transform: 'translateX(-50%)',
              top: '100px',
              //bottom: '15%',
              margin: 'auto',
              borderRadius: '10px',
              background: 'white',
            }}
          >
            <div className="pop_box">
              <h3>
                <Trans>Move Out</Trans>
              </h3>
              <ul className="divide mT10">
                <ul className="layer_list">
                  <li style={{ width: '100%' }}>
                    <span className="f_left">
                      <Trans>Flock : </Trans> {row.flockId}
                    </span>
                    <span className="f_left mL20">
                      <Trans>HH : </Trans>
                      {row.hhId}
                    </span>
                    <div className="board_list">
                      <table>
                        <colgroup>
                          <col style={{ width: '15%' }} />
                          <col style={{ width: '20%' }} />
                          <col style={{ width: '15%' }} />
                          <col style={{ width: '20%' }} />
                          <col />
                        </colgroup>
                        {/* <thead>
                          <tr>
                            <th colSpan="2">Female</th>
                            <th colSpan="2">Male</th>
                            <th>Qty</th>
                          </tr>
                        </thead> */}
                        <tbody>
                          <tr>
                            <th>
                              <Trans>Farm</Trans> <span className="red">*</span>
                            </th>
                            <td style={{ textAlign: 'left' }}>
                              <Search
                                placeholder="Farm Code"
                                onSearch={(value) => this.fnSearchFarm(value)} //Popup open
                                enterButton
                                style={{ width: '200px' }}
                                ref={(input) => {
                                  this['recvFarmCode'] = input;
                                }}
                                value={this.state.form.recvFarmCode}
                                onChange={(value) =>
                                  this.setOnChangeFarm(value)
                                } //select
                              />
                            </td>
                            <th>
                              <Trans>Move Date</Trans>{' '}
                              <span className="red">*</span>
                            </th>
                            <td colSpan="2" style={{ textAlign: 'left' }}>
                              {GridUtil.renderInput({
                                inputName: 'moveDate', //*** */
                                type: 'D', //*** */
                                that: this, //*** */
                                statsForm: this.state.form, //*** */
                                setOnChange: this.setOnChange, //*** */
                                width: 130, // px제외 : 없으면 100% */
                                placeholder: 'moveDate',
                              })}
                            </td>
                          </tr>

                          <tr>
                            <th>
                              <Trans>Order No</Trans>
                            </th>
                            <td style={{ textAlign: 'left' }}>
                              {GridUtil.renderInput({
                                inputName: 'orderNo', //*** */
                                type: '', //*** */
                                that: this, //*** */
                                statsForm: this.state.form, //*** */
                                setOnChange: this.setOnChange, //*** */
                                //width: 130, // px제외 : 없으면 100% */
                                maxLength: 20,
                                //placeholder: 'Date',
                              })}
                            </td>

                            <th>
                              <Trans>Truck No</Trans>
                            </th>
                            <td colSpan="2" style={{ textAlign: 'left' }}>
                              {GridUtil.renderInput({
                                inputName: 'truckNo', //*** */
                                type: '', //*** */
                                that: this, //*** */
                                statsForm: this.state.form, //*** */
                                setOnChange: this.setOnChange, //*** */
                                width: 130, // px제외 : 없으면 100% */
                                maxLength: 10,
                                //placeholder: 'Date',
                              })}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <Trans>Remarks</Trans>
                            </th>
                            <td colSpan="4" style={{ textAlign: 'left' }}>
                              {GridUtil.renderInput({
                                inputName: 'moveReason', //*** */
                                type: '', //*** */
                                that: this, //*** */
                                statsForm: this.state.form, //*** */
                                setOnChange: this.setOnChange, //*** */
                                //width: 120, // px제외 : 없으면 100% */
                                maxLength: 1000,
                              })}
                            </td>
                          </tr>
                          <tr>
                            <th colSpan="2">
                              <Trans>Female</Trans>
                            </th>
                            <th colSpan="2">
                              <Trans>Male</Trans>
                            </th>
                            <th>
                              <Trans>Qty</Trans> <span className="red">*</span>
                            </th>
                          </tr>
                          <tr>
                            <th>
                              {row.currentQtyF
                                ? row.currentQtyF.toLocaleString()
                                : 0}
                            </th>
                            <td style={{ textAlign: 'left' }}>
                              {GridUtil.renderInput({
                                inputName: 'moveQtyF', //*** */
                                type: 'N', //*** */
                                that: this, //*** */
                                statsForm: this.state.form, //*** */
                                setOnChange: this.setOnChange, //*** */
                                width: 130, // px제외 : 없으면 100% */
                                //dec: 2,
                                //maxLength: 6,
                                //maxVal: 999.99,
                              })}
                            </td>
                            <th>
                              {row.currentQtyM
                                ? row.currentQtyM.toLocaleString()
                                : 0}
                            </th>
                            <td style={{ textAlign: 'left' }}>
                              {GridUtil.renderInput({
                                inputName: 'moveQtyM', //*** */
                                type: 'N', //*** */
                                that: this, //*** */
                                statsForm: this.state.form, //*** */
                                setOnChange: this.setOnChange, //*** */
                                width: 130, // px제외 : 없으면 100% */
                              })}
                            </td>
                            <td>
                              <span>
                                {row.outQty ? row.outQty.toLocaleString() : '0'}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                </ul>
              </ul>
              <div className="f_right mT10 mB30">
                <button className="btn_save" onClick={(e) => this.fnSave(row)}>
                  <Trans>Save</Trans>
                </button>
                <button
                  className="btn_gray"
                  onClick={(e) => this.props.fnClosePop('popMoveOutView')}
                >
                  <Trans>Cancel</Trans>
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.popSearchFarmView ? (
          <PopSearchFarm
            popSearchFarmView={this.state.popSearchFarmView}
            farmCode={this.state.recvFarmCode}
            farmClass={this.state.farmClass}
            fnClosePop={this.fnClosePop}
            fnSelectFarm={this.fnSelectFarm}
          />
        ) : null}
      </Fragment>
    );
  }
}
export default withAlert()(PopMoveOut);
