import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import FHDateRangeSearch from '../utils/FHDateRangeSearch';
import ReportUtil from '../utils/ReportUtil';

import '../Report.module.css';

// 7. 산란기 일령별 보고서
export default class LayDailyR extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      excelName: 'Lay Daily Report',
      rowData: [],
      params: {},
      ColDefs: [
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Date'),
              field: 'date',
              pinned: 'left',
            },
            {
              headerName: i18n.t('Age'),
              field: 'age',
              pinned: 'left',
            },
            {
              headerName: i18n.t('Prod'),
              field: 'prod',
              pinned: 'left',
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('Population'),
              children: [
                {
                  headerName: i18n.t('Female'),
                  field: 'popsFemale',
                },
                {
                  headerName: i18n.t('Male'),
                  field: 'popsMale',
                },
                {
                  headerName: i18n.t('Ratio'),
                  field: 'popsRatio',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Depletion'),
          children: [
            {
              headerName: i18n.t('Female'),
              children: [
                {
                  headerName: i18n.t('Death'),
                  field: 'dfDeath',
                },
                {
                  headerName: i18n.t('Cull'),
                  field: 'dfCull',
                },
                {
                  headerName: i18n.t('%'),
                  field: 'dfRatio',
                },
                {
                  headerName: i18n.t('% Cum'),
                  field: 'dfCum',
                },
              ],
            },
            {
              headerName: i18n.t('Male'),
              children: [
                {
                  headerName: i18n.t('Death'),
                  field: 'dmDeath',
                },
                {
                  headerName: i18n.t('Cull'),
                  field: 'dmCull',
                },
                {
                  headerName: i18n.t('%'),
                  field: 'dmRatio',
                },
                {
                  headerName: i18n.t('% Cum'),
                  field: 'dmCum',
                },
              ],
            },
          ],
        },

        {
          headerName: i18n.t('Moving'),
          children: [
            {
              headerName: i18n.t('Female'),
              children: [
                {
                  headerName: i18n.t('In'),
                  field: 'mfIn',
                },
                {
                  headerName: i18n.t('Out'),
                  field: 'mfOut',
                },
              ],
            },
            {
              headerName: i18n.t('Male'),
              children: [
                {
                  headerName: i18n.t('In'),
                  field: 'mmIn',
                },
                {
                  headerName: i18n.t('Out'),
                  field: 'mmOut',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Feed Consumption'),
          children: [
            {
              headerName: i18n.t('Female'),
              children: [
                {
                  headerName: i18n.t('Kg'),
                  field: 'ffKg',
                },
                {
                  headerName: i18n.t('Cumm'),
                  field: 'ffCum',
                },
                {
                  headerName: i18n.t('/Bird'),
                  field: 'ffBird',
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'ffStd',
                },
              ],
            },
            {
              headerName: i18n.t('Male'),
              children: [
                {
                  headerName: i18n.t('Kg'),
                  field: 'fmKg',
                },
                {
                  headerName: i18n.t('Cumm'),
                  field: 'fmCumm',
                },
                {
                  headerName: i18n.t('/Bird'),
                  field: 'fmBird',
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'fmStd',
                },
              ],
            },
            {
              headerName: i18n.t('Feed Per day'),
              children: [
                {
                  headerName: i18n.t('F + M'),
                  field: 'fpwFM',
                },
                {
                  headerName: i18n.t('Hen/Day'),
                  field: 'fpwHD',
                },
              ],
            },
            {
              headerName: i18n.t('Feed Per EP'),
              field: 'fpEP',
            },
            {
              headerName: i18n.t('Feed Per HE'),
              field: 'fpHE',
            },
          ],
        },
        {
          headerName: i18n.t('Body Weight'),
          children: [
            {
              headerName: i18n.t('Female'),
              children: [
                {
                  headerName: i18n.t('Act'),
                  field: 'bfAct',
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'bfStd',
                },
                {
                  headerName: i18n.t('Uniformity'),
                  field: 'bfUniformity',
                },
              ],
            },
            {
              headerName: i18n.t('Male'),
              children: [
                {
                  headerName: i18n.t('Act'),
                  field: 'bmAct',
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'bmStd',
                },
                {
                  headerName: i18n.t('Uniformity'),
                  field: 'bmUniformity',
                },
              ],
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('Lighting'),
              children: [
                {
                  headerName: i18n.t('on'),
                  field: 'lightOn',
                },
                {
                  headerName: i18n.t('off'),
                  field: 'lightOff',
                },
              ],
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('Temp/Humidity'),
              children: [
                {
                  headerName: i18n.t('Temp'),
                  field: 'temp',
                },
                {
                  headerName: i18n.t('Humidity'),
                  field: 'humi',
                },
              ],
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('Vaccine'),
              children: [
                {
                  headerName: i18n.t('Name'),
                  field: 'vacName',
                },
                {
                  headerName: i18n.t('Amount'),
                  field: 'vacAmount',
                },
              ],
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('Medicine'),
              children: [
                {
                  headerName: i18n.t('Name'),
                  field: 'medName',
                },
                {
                  headerName: i18n.t('Amount'),
                  field: 'medAmount',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Verterinarian log'),
              field: 'spiking',
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('Remarks'),
              children: [
                {
                  headerName: i18n.t('Feed'),
                  field: 'feedRemark',
                },
                {
                  headerName: i18n.t('Vaccine'),
                  field: 'vacRemark',
                },
                {
                  headerName: i18n.t('Medicine'),
                  field: 'medRemark',
                },
              ],
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('Egg Prod'),
              children: [
                {
                  headerName: i18n.t('Qty'),
                  field: 'epQty',
                },
                {
                  headerName: i18n.t('Henday(%)'),
                  field: 'epHenday',
                },
                {
                  headerName: i18n.t('HenHouse(%)'),
                  field: 'epHenHouse',
                },
              ],
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('Culling'),
              children: [
                {
                  headerName: i18n.t('Crack'),
                  field: 'cullCrack',
                },
                {
                  headerName: i18n.t('Damage'),
                  field: 'cullDamage',
                },
                {
                  headerName: i18n.t('Dirty'),
                  field: 'cullDirty',
                },
                {
                  headerName: i18n.t('Jumbo'),
                  field: 'cullJumbo',
                },
                {
                  headerName: i18n.t('Miss Shape'),
                  field: 'cullMissShape',
                },
                {
                  headerName: i18n.t('Thin'),
                  field: 'cullThin',
                },
                {
                  headerName: i18n.t('Small'),
                  field: 'cullSmall',
                },
                {
                  headerName: i18n.t('Total'),
                  field: 'cullTotal',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Good Egg'),
              field: 'goodEgg',
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('To hatchery'),
              children: [
                {
                  headerName: i18n.t('Good Egg'),
                  field: 'thGE',
                },
              ],
            },
          ],
        },
        {
          headerName: '',
          children: [
            {
              headerName: i18n.t('Stock'),
              children: [
                {
                  headerName: i18n.t('Good Egg'),
                  field: 'stockGE',
                },
              ],
            },
          ],
        },
      ],
      gridHeight: '640px',
      defaultColDef: {
        minWidth: 100,
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        cellClass: 'text-center',
        suppressSizeToFit: false,
        valueFormatter: function (params) {
          return GridUtil.formatReport(params);
        },
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagenation: false,
      paginationPageSize: 10,
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    if (Const.RPT_TEST) {
      const params = ReportUtil.getDefaultParams(this.props);
      console.log('params:', params);
      this.fnGetRowData(params);
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  // -----------------------------------------------------------
  // 참고용 : ES/ETL 서버 주소(API)를 지정해야 합니다.
  // -----------------------------------------------------------
  fnGetRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/stat/breeding/layDailyR.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data.rows1 });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtExport = () => {
    const fileName =
      i18n.t(this.state.excelName) + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t(this.state.excelName);
    const params = {
      allColumns: false,
      columnGroups: true,
      fileName: fileName,
      sheetName: sheetName,
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onSearch = (params) => {
    console.log(params);
    this.fnGetRowData(params);
  };

  render() {
    return (
      <React.Fragment>
        <h3>
          {/* 산란기 일령별 보고서 */}
          <Trans>Title Lay Daily R</Trans>
        </h3>

        <div className="con_box">
          <FHDateRangeSearch onSearch={this.onSearch}></FHDateRangeSearch>

          <div className="f_right mB20">
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
          </div>
          <div className="board_list_s">
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  excelStyles={this.state.excelStyles}
                  onGridReady={this.onGridReady}
                  rowData={this.state.rowData}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

let i = 0;
const sampleData = [
  {
    date: i++,
    age: i++,
    prod: i++,
    pFemale: i++,
    pMale: i++,
    pRatio: i++,
    dfDeath: i++,
    dfCull: i++,
    dfPercent: i++,
    dfCum: i++,
    dmDeath: i++,
    dmCull: i++,
    dmPercent: i++,
    dmCum: i++,
    mfIn: i++,
    mfOut: i++,
    mmIn: i++,
    mmOut: i++,
    ffKg: i++,
    ffCum: i++,
    ffBird: i++,
    ffStd: i++,
    fmKg: i++,
    fmCumm: i++,
    fmBird: i++,
    fmStd: i++,
    fpwFM: i++,
    fpwHD: i++,
    fpEP: i++,
    fpHE: i++,
    bfAct: i++,
    bfStd: i++,
    bfUniformity: i++,
    bmAct: i++,
    bmStd: i++,
    bmUniformity: i++,
    lighting: i++,
    tempHumi: i++,
    vaccine: i++,
    spiking: i++,
    activity: i++,
    teQty: i++,
    teHenday: i++,
    tdHenHouse: i++,
    crack: i++,
    damage: i++,
    dirty: i++,
    jumbo: i++,
    small: i++,
    total: i++,
    goodEgg: i++,
    thGe: i++,
    thCe: i++,
    sGe: i++,
    sCe: i++,
  },
];
