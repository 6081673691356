import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';

import '../Breeding.module.css';
import { Row } from 'antd';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class HenHouseFlockList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      prop: {},
      rowData: []
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    //this.fnSearch();
  }

  fnClickFlock = row => {
    this.props.fnClickFlock(row);
  };
  fnClickFlockCancel = row => {
    this.props.fnClickFlockCancel(row);
  };
  flockButton = row => {
    if (this.props.flockListFlockId === row.flockId) {
      return (
        <dd>
          <Trans>Select HH</Trans>
          <button
            className="btn_gray2"
            onClick={e => this.fnClickFlockCancel(row)}
          >
            <Trans>Cancel</Trans>
          </button>
        </dd>
      );
    } else {
      return (
        <dd>
          <button className="btn_gray2" onClick={e => this.fnClickFlock(row)}>
            <Trans>Chick in HH</Trans>
          </button>
        </dd>
      );
    }
  };
  render() {
    let flockList = [];
    if (this.props.flockList && this.props.flockList.length > 0) {
      flockList = this.props.flockList.map((row, i) => {
        return (
          <div key={i} className="full">
            <dl className="reinfo">
              <dt className="color1">{row.flockName}</dt>
              <dd>{row.flockCdate}</dd>
              <dd>
                <Trans>Strain : {row.strainCode}</Trans>
              </dd>
              <dd>
                <Trans>F</Trans> :{' '}
                {row.flockInQtyF ? row.flockInQtyF.toLocaleString() : ''} /{' '}
                {row.chickinQtyF ? row.chickinQtyF.toLocaleString() : ''}
              </dd>
              <dd>
                <Trans>M</Trans> :{' '}
                {row.flockInQtyM ? row.flockInQtyM.toLocaleString() : ''} /{' '}
                {row.chickinQtyM ? row.chickinQtyM.toLocaleString() : ''}
              </dd>

              {this.flockButton(row)}
              {/* <dd>
                <Trans>{this.props.flockMsg}</Trans>
                <button
                  className="btn_gray2"
                  onClick={e => this.fnClickFlock(row)}
                >
                  <Trans>{this.props.flockButtonLabel}</Trans>
                </button>
              </dd> */}
            </dl>
          </div>
        );
      });
    }
    return (
      <Fragment>
        {/* Flock */}
        <li className="divide_left">
          <div className="dititle">
            <i className="mdi mdi-cube-outline"></i> <Trans>Flock</Trans>
          </div>
          {flockList}
          {/* left list */}
        </li>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default HenHouseFlockList;
