import React, { Fragment } from 'react';
import { SessionUtil, PopSearchMaterial } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { DateUtil, GridUtil } from 'utils';
import { TimePicker, Select, Input, Switch, Modal } from 'antd';
import i18n from 'i18next';
import NumberFormat from 'react-number-format';
import FlockList from './FlockList';
import HenHouseList from './HenHouseList';
import { withAlert } from 'react-alert';

import '../Breeding.module.css';
const { Search } = Input;
const { confirm } = Modal;
/*
 ** Breeding/Glowing Log
 ** saveGrowingLog
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class GrowingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      growingForm: [],
      matrialList: [],
      feedList: [],
      vaccineList: [],
      medicineList: [],
      fieldName: '',
      popSearchMaterialView: false,
      visible: false,
      visibleFlock: false,
      visibleClosing: false,
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    //필수 항목 setFocus를 위한 정의
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.growingView !== nextProps.growingView) {
      return {
        growingForm: nextProps.growingForm,
        growingView: nextProps.growingView,
      };
    }
    return null;
  }

  fnClickCancel = (e) => {
    this.props.fnClickCancel('growingView');
  };

  fnSave = () => {
    let that = this;
    let data = this.state.growingForm;

    if (!data.feedCode1) {
      that.props.alert.show(i18n.t('Please enter feedCode1.'));
      that.feedName1.focus();
      return false;
    }
    if (
      (!data.feedUseAmtF1 || data.feedUseAmtF1 === 0) &&
      (!data.feedUseAmtM1 || data.feedUseAmtM1 === 0)
    ) {
      that.props.alert.show(i18n.t('Please enter feedUseAmtF1.'));
      that.feedUseAmtF1.focus();
      return false;
    }

    if (Number(data.seF) > Number(data.cullingQtyF)) {
      that.props.alert.show(i18n.t('Please enter Sexing Error.'));
      that.seF.focus();
      return false;
    }
    if (Number(data.seM) > Number(data.cullingQtyM)) {
      that.props.alert.show(i18n.t('Please enter Sexing Error.'));
      that.seM.focus();
      return false;
    }
    if (Number(data.uniformityF) < 0 || Number(data.uniformityF) > 100) {
      that.props.alert.show(i18n.t('Please enter Uniformity.'));
      that.uniformityF.focus();
      return false;
    }
    if (Number(data.uniformityM) < 0 || Number(data.uniformityM) > 100) {
      that.props.alert.show(i18n.t('Please enter Uniformity.'));
      that.uniformityM.focus();
      return false;
    }
    if (Number(data.populationF) < 0) {
      that.props.alert.show(i18n.t('Please enter Current Population.'));
      return false;
    }
    if (Number(data.populationM) < 0) {
      that.props.alert.show(i18n.t('Please enter Current Population.'));
      return false;
    }

    if (data.bwF === undefined || Number(data.bwF) < 0) {
      that.props.alert.show(i18n.t('Please enter Female BodyWeight.'));
      return false;
    }

    if (data.bwM === undefined || Number(data.bwM) < 0) {
      that.props.alert.show(i18n.t('Please enter Male Population.'));
      return false;
    }

    data.temp = data.temp ? data.temp : 0;
    data.tempOut = data.tempOut ? data.tempOut : 0;
    data.humidity = data.humidity ? data.humidity : 0;
    data.humidityOut = data.humidityOut ? data.humidityOut : 0;

    data.ageDay = data.days ? data.days : 0; //일령 / Age of Day

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.updateId = SessionUtil.getUserId();

    if (data.closeQtyF > 0 || data.closeQtyM > 0) {
      this.confirmForClosing();
    } else {
      this.fnCheckStock();
    }
  };

  fnCheckStock = () => {
    let data = this.state.growingForm;
    if (data.stockF === 0 && data.stockM === 0) {
      this.confirmForHHEmpty(); //Hen House empty confirm.
    } else {
      this.fnSelectCurrentFlockStock(); //Check flock close or not.
    }
  };
  confirmForHHEmpty = () => {
    Modal.confirm({
      content: 'This hen house will be empty. Are you sure?',
      visible: this.state.visible,
      okText: 'Yes',
      cancelText: 'No',
      onOk: this.fnSelectCurrentFlockStock,
      onCancel: this.hideModal,
    });
  };

  confirmForFlockClosed = () => {
    Modal.confirm({
      content: 'This flock will be closed. Are you sure?',
      visible: this.state.visibleFlock,
      okText: 'Yes',
      cancelText: 'No',
      onOk: this.fnInsertGrowinLog,
      onCancel: this.hideModal,
    });
  };

  confirmForClosing = () => {
    Modal.confirm({
      content: 'This hen house begins closing. Are you sure?',
      visible: this.state.visibleClosing,
      okText: 'Yes',
      cancelText: 'No',
      onOk: this.fnCheckStock,
      onCancel: this.hideModal,
    });
  };

  hideModal = () => {
    this.setState(
      {
        visible: false,
        visibleFlock: false,
        visibleClosing: false,
      },
      function () {}
    );
  };

  fnSelectCurrentFlockStock = () => {
    let that = this;
    let data = this.state.growingForm;
    console.log('fnSelectCurrentFlockStock', data);
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/growing/selectCurrentStock.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then((response) => {
        console.log(response.data.pop);
        this.setState({
          growingForm: {
            ...this.state.growingForm,
            pop: Number(response.data.pop),
          },
        });
        if (Number(response.data.pop) == 0) {
          this.confirmForFlockClosed(); // Flockk Close confirm
        } else {
          this.fnInsertGrowinLog(); // insertGrowingLog
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  fnInsertGrowinLog = () => {
    let that = this;
    let data = this.state.growingForm;
    console.log('saveGrowingLog', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/growing/saveGrowingLog.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.fnSearch();
      })
      .catch(function (error) {
        return [];
      });
  };

  setOnChange = (e, params, dateString) => {
    //console.log('setOnChange', params);
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';

    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (
      params.type === 'D' ||
      params.type === 'T' ||
      params.type === 'TM'
    ) {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
      if (params.minVal) {
        if (Number(val) < Number(params.minVal)) {
          val = Number(params.minVal);
        }
      }
      val = val ? val : 0;
    }
    this.setState(
      {
        growingForm: {
          ...this.state.growingForm,
          [col]: val,
        },
      },
      function () {
        if (params.type === 'N') {
          that.fnCalcPopulation(params);
        } else if (params.type === 'SELECT') {
          that.fnSearchUnit(col, val);
        }
      }
    );
  };

  fnSearchUnit = (col, val) => {
    let that = this;
    let list = [];
    if (col.indexOf('feedCode') === 0) {
      list = that.state.feedList;
    } else if (col.indexOf('vaccineCode') === 0) {
      list = that.state.vaccineList;
    } else if (col.indexOf('medicineCode') === 0) {
      list = that.state.medicineList;
    }
    this.fnSearchUnit2(list, col, val);
  };
  fnSearchUnit2 = (list, col, val) => {
    let that = this;
    var findData = list.filter((item) => item.materialCode === val);
    if (findData.length > 0) {
      this.setState({
        growingForm: {
          ...this.state.growingForm,
          [col + 'Unit']: findData[0].unit,
        },
      });
    }
  };
  fnCalcPopulation = (params) => {
    let that = this;
    if (
      params.inputName === 'closeQtyF' ||
      params.inputName === 'cullingQtyF' ||
      params.inputName === 'deathQtyF' ||
      params.inputName === 'seF' ||
      params.inputName === 'bwF' ||
      params.inputName === 'uniformityF' ||
      params.inputName === 'closeQtyM' ||
      params.inputName === 'cullingQtyM' ||
      params.inputName === 'deathQtyM' ||
      params.inputName === 'seM' ||
      params.inputName === 'bwM' ||
      params.inputName === 'uniformityM'
    ) {
      let data = this.state.growingForm;
      let closeQtyF = Number(data.closeQtyF ? data.closeQtyF : 0);
      let cullingQtyF = Number(data.cullingQtyF ? data.cullingQtyF : 0);
      let deathQtyF = Number(data.deathQtyF ? data.deathQtyF : 0);
      let seF = Number(data.seF ? data.seF : 0);
      let bwF = Number(data.bwF ? data.bwF : 0);
      let uniformityF = Number(data.uniformityF ? data.uniformityF : 0);

      let closeQtyM = Number(data.closeQtyM ? data.closeQtyM : 0);
      let cullingQtyM = Number(data.cullingQtyM ? data.cullingQtyM : 0);
      let deathQtyM = Number(data.deathQtyM ? data.deathQtyM : 0);
      let seM = Number(data.seM ? data.seM : 0);
      let bwM = Number(data.bwM ? data.bwM : 0);
      let uniformityM = Number(data.uniformityM ? data.uniformityM : 0);

      let stockF = data.populationF - closeQtyF - cullingQtyF - deathQtyF;
      let stockM = data.populationM - closeQtyM - cullingQtyM - deathQtyM;

      let outQty =
        closeQtyF +
        cullingQtyF +
        deathQtyF +
        closeQtyM +
        cullingQtyM +
        deathQtyM;
      if (stockF < 0 || stockM < 0) {
        this.setState(
          {
            growingForm: {
              ...this.state.growingForm,
              [params.inputName]: 0,
            },
          },
          function () {}
        );
        return false;
      } else {
        this.setState(
          {
            growingForm: {
              ...this.state.growingForm,
              ['stockF']: stockF,
              ['stockM']: stockM,
              ['outQty']: outQty,
            },
          },
          function () {}
        );
      }
    }
  };
  fnSearchMatrial = (value, materialType, fieldCode, fieldName, fieldUnit) => {
    this.setState(
      {
        popSearchMaterialView: true,
        materialName: value,
        fieldCode: fieldCode, //return field
        fieldName: fieldName, //return field
        fieldUnit: fieldUnit, //return field

        materialType: materialType,
      },
      function () {}
    );
  };
  fnSelectMaterial = (data, fieldCode, fieldName, fieldUnit) => {
    let ix = fieldCode.slice(-1);
    let col = fieldCode.replace('Code', 'UseAmt');
    col =
      fieldCode.replace('Code', '').replace(ix, '') === 'feed'
        ? fieldCode.replace('Code', 'UseAmtF')
        : col;
    let colView = fieldCode.replace('Code', 'View');
    this[col].focus();
    this.setState(
      {
        growingForm: {
          ...this.state.growingForm,
          [fieldCode]: data.materialCode,
          [fieldName]: data.materialName,
          [fieldUnit]: data.unit,
          [colView]: true,
        },
      },
      function () {}
    );
  };
  fnClosePop = (data) => {
    this.setState({ [data]: false }, function () {});
  };
  setOnChangeCust = (e) => {
    this.setState({ fieldName: e.target.value, fieldCode: '' }, function () {});
  };
  fnClosing = () => {
    let that = this;
    let data = this.state.growingForm;
    this.setState(
      {
        growingForm: {
          ...this.state.growingForm,
          closeQtyF: data.stockF,
          closeQtyM: data.stockM,
        },
      },
      function () {}
    );
  };
  fnSetFields = (val, e, co) => {
    let ix = co.slice(-1);
    let col = co.replace(ix, '');
    console.log(val, col, col.slice(-1));
    this.setState(
      {
        growingForm: {
          ...this.state.growingForm,
          [col + 'Code' + ix]: '',
          [col + 'Name' + ix]: '',
          [col + 'Unit' + ix]: '',
          [col + 'UseAmt' + ix]: 0,
          [col + 'UseAmtF' + ix]: 0,
          [col + 'UseAmtM' + ix]: 0,
          [col + 'View' + ix]: val,
        },
      },
      function () {}
    );
  };
  render() {
    if (!this.props.growingView) return null;
    const dataArr = [1, 2, 3];
    let data = this.state.growingForm;

    return (
      <Fragment>
        <div className="layer_list writelist">
          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '170px' }} />
                <col style={{ width: '400px' }} />
                <col style={{ width: '130px' }} />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th colSpan="4" style={{ textAlign: 'center' }}>
                    <span className="f_left">{data.workDate}</span>
                    <span className="f_center">
                      <Trans>Growing</Trans> (<Trans>Flock : </Trans>
                      {data.flockId}) (<Trans>Hh : </Trans>
                      {data.hhId})
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <Trans>Population</Trans>
                  </th>
                  <td className="spac10_left label" colSpan="3">
                    <label htmlFor="" style={{ width: 60 }}>
                      <Trans>Female</Trans>
                    </label>
                    <Input
                      className="input_base"
                      disabled={true}
                      value={data.stockF ? data.stockF.toLocaleString() : 0}
                      style={{ width: '100px', textAlign: 'right' }}
                    />
                    <label htmlFor="" style={{ width: 60 }}>
                      <Trans>Male</Trans>
                    </label>
                    <Input
                      className="input_base"
                      disabled={true}
                      value={data.stockM ? data.stockM.toLocaleString() : 0}
                      style={{ width: '100px', textAlign: 'right' }}
                    />
                    {/* {data.iuFlag ? (
                      <button
                        className="btn_sel mL20"
                        onClick={(e) => this.fnClosing()}
                      ></button>
                    ) : null} */}
                    <span className="mL20">
                      <Trans> closing :</Trans>
                    </span>
                    <label htmlFor="" style={{ width: 60 }}>
                      <Trans>Female</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'closeQtyF', //*** */
                      type: 'N', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      minVal: 0,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      disabled: data.iuFlag ? false : true,
                    })}
                    <label htmlFor="" style={{ width: 60 }}>
                      <Trans>Male</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'closeQtyM', //*** */
                      type: 'N', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      minVal: 0,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      disabled: data.iuFlag ? false : true,
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Culling</Trans>
                  </th>
                  <td className="spac10_left label" colSpan="3">
                    <label htmlFor="" style={{ width: 60 }}>
                      <Trans>Female</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'cullingQtyF', //*** */
                      type: 'N', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      minVal: 0,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      //disabled: data.view ? true : false,
                      that: this, //*** */
                      disabled: data.iuFlag ? false : true,
                    })}
                    <label htmlFor="" style={{ width: 60 }}>
                      <Trans>Male</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'cullingQtyM', //*** */
                      type: 'N', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      minVal: 0,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      //disabled: data.view ? true : false,
                      that: this, //*** */
                      disabled: data.iuFlag ? false : true,
                    })}
                    <span className="mL20">
                      <Trans> Sexing Error :</Trans>
                    </span>
                    <label htmlFor="" style={{ width: 50 }}>
                      <Trans>Female</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'seF', //*** */
                      type: 'N', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      minVal: 0,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      //disabled: data.view ? true : false,
                      that: this, //*** */
                      disabled: data.iuFlag ? false : true,
                    })}
                    <label htmlFor="" style={{ width: 50 }}>
                      <Trans>Male</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'seM', //*** */
                      type: 'N', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      minVal: 0,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      //disabled: data.view ? true : false,
                      that: this, //*** */
                      disabled: data.iuFlag ? false : true,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Death</Trans>
                  </th>
                  <td className="spac10_left label" colSpan="3">
                    <label htmlFor="" style={{ width: 60 }}>
                      <Trans>Female</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'deathQtyF', //*** */
                      type: 'N', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      minVal: 0,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      //disabled: data.view ? true : false,
                      that: this, //*** */
                      disabled: data.iuFlag ? false : true,
                    })}

                    <label htmlFor="" style={{ width: 60 }}>
                      <Trans>Male</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'deathQtyM', //*** */
                      type: 'N', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      minVal: 0,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      //disabled: data.view ? true : false,
                      that: this, //*** */
                      disabled: data.iuFlag ? false : true,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Body Weight (g)</Trans>{' '}
                    <span className="red">*</span>
                  </th>
                  <td className="spac10_left label" colSpan="3">
                    <label htmlFor="" style={{ width: 60 }}>
                      <Trans>Female</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'bwF', //*** */
                      type: 'N', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      minVal: 0,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      //disabled: data.view ? true : false,
                      that: this, //*** */
                      disabled: data.iuFlag ? false : true,
                    })}
                    <label htmlFor="" style={{ width: 60 }}>
                      <Trans>Male</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'bwM', //*** */
                      type: 'N', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      minVal: 0,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      //disabled: data.view ? true : false,
                      that: this, //*** */
                      disabled: data.iuFlag ? false : true,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Uniformity (%)</Trans>
                  </th>
                  <td className="spac10_left label" colSpan="3">
                    <label htmlFor="" style={{ width: 60 }}>
                      <Trans>Female</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'uniformityF', //*** */
                      type: 'N', //*** */
                      dec: 2,
                      maxLength: 6,
                      maxVal: 999.99,
                      width: 100, // px제외 : 없으면 30 */
                      minVal: 0,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      //disabled: data.view ? true : false,
                      that: this, //*** */
                      disabled: data.iuFlag ? false : true,
                    })}
                    <label htmlFor="" style={{ width: 60 }}>
                      <Trans>Male</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'uniformityM', //*** */
                      type: 'N', //*** */
                      dec: 2,
                      maxLength: 6,
                      maxVal: 999.99,
                      width: 100, // px제외 : 없으면 30 */
                      minVal: 0,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      //disabled: data.view ? true : false,
                      that: this, //*** */
                      disabled: data.iuFlag ? false : true,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Water (L)</Trans>
                  </th>
                  <td className="spac10_left label" colSpan="3">
                    <label htmlFor="" style={{ width: 60 }}>
                      <Trans> </Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'waterAmt', //*** */
                      type: 'N', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      minVal: 0,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      //disabled: data.view ? true : false,
                      disabled: data.iuFlag ? false : true,
                      that: this, //*** */
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Feed</Trans>
                    <br></br>
                    {dataArr.map((ix) => (
                      <Switch
                        style={{ marginRight: '3px' }}
                        key={ix}
                        checkedChildren={ix}
                        unCheckedChildren={ix}
                        checked={
                          data['feedView' + ix] || ix === 1 ? true : false
                        }
                        onChange={(c, e) => this.fnSetFields(c, e, 'feed' + ix)}
                      />
                    ))}
                  </th>
                  <td className="spac10_left label" colSpan="3">
                    {dataArr.map((ix) => (
                      <div
                        key={ix}
                        style={{
                          display:
                            data['feedView' + ix] || ix === 1
                              ? 'block'
                              : 'none',
                        }}
                      >
                        <label htmlFor="" style={{ width: 60 }}>
                          <Trans>Name</Trans>
                          {ix}{' '}
                          {ix === 1 ? <span className="red">*</span> : null}
                        </label>
                        <Search
                          placeholder="Feed Name"
                          onSearch={(value) =>
                            this.fnSearchMatrial(
                              value,
                              'F',
                              'feedCode' + ix,
                              'feedName' + ix,
                              'feedUnit' + ix
                            )
                          }
                          enterButton
                          style={{ width: '250px' }}
                          className=""
                          ref={(input) => {
                            this['feedName' + ix] = input;
                          }}
                          value={this.state.growingForm['feedName' + ix]}
                          onChange={(value) => this.setOnChangeMaterial(value)}
                        />
                        <label htmlFor="" style={{ width: 60 }}>
                          <Trans>F Qty</Trans>
                        </label>
                        {GridUtil.renderInput({
                          inputName: 'feedUseAmtF' + ix, //*** */
                          type: 'N', //*** */
                          width: 100, // px제외 : 없으면 30 */
                          minVal: 0,
                          statsForm: this.state.growingForm, //*** */
                          setOnChange: this.setOnChange, //*** */
                          disabled: data.iuFlag ? false : true,
                          that: this, //*** */
                          dec: 2,
                        })}{' '}
                        <span
                          style={{ width: '30px', display: 'inline-block' }}
                        >
                          {data['feedUnit' + ix]}
                        </span>
                        <span className="mL10 mR10">
                          <Trans>M Qty</Trans>
                        </span>
                        {GridUtil.renderInput({
                          inputName: 'feedUseAmtM' + ix, //*** */
                          type: 'N', //*** */
                          width: 100, // px제외 : 없으면 30 */
                          minVal: 0,
                          statsForm: this.state.growingForm, //*** */
                          setOnChange: this.setOnChange, //*** */
                          disabled: data.iuFlag ? false : true,
                          that: this, //*** */
                          dec: 2,
                        })}{' '}
                        <span>{data['feedUnit' + ix]}</span>
                      </div>
                    ))}

                    <div>
                      <label htmlFor="" style={{ width: 60 }}>
                        <Trans>Remark</Trans>
                      </label>
                      {GridUtil.renderInput({
                        inputName: 'feedRem', //*** */
                        type: '', //*** */
                        width: 500, // px제외 : 없으면 30 */
                        maxLength: 1000,
                        statsForm: this.state.growingForm, //*** */
                        setOnChange: this.setOnChange, //*** */
                        disabled: data.iuFlag ? false : true,
                        that: this, //*** */
                      })}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Vaccine</Trans>
                    <br></br>
                    {dataArr.map((ix) => (
                      <Switch
                        style={{ marginRight: '3px' }}
                        key={ix}
                        checkedChildren={ix}
                        unCheckedChildren={ix}
                        checked={
                          data['vaccineView' + ix] || ix === 1 ? true : false
                        }
                        onChange={(c, e) =>
                          this.fnSetFields(c, e, 'vaccine' + ix)
                        }
                      />
                    ))}
                  </th>
                  <td className="spac10_left label" colSpan="3">
                    {dataArr.map((ix) => (
                      <div
                        key={ix}
                        style={{
                          display:
                            data['vaccineView' + ix] || ix === 1
                              ? 'block'
                              : 'none',
                        }}
                      >
                        <label htmlFor="" style={{ width: 60 }}>
                          <Trans>Name</Trans>
                          {ix}
                        </label>
                        <Search
                          placeholder="Vaccine Name"
                          onSearch={(value) =>
                            this.fnSearchMatrial(
                              value,
                              'V',
                              'vaccineCode' + ix,
                              'vaccineName' + ix,
                              'vaccineUnit' + ix
                            )
                          }
                          enterButton
                          style={{ width: '250px' }}
                          className=""
                          ref={(input) => {
                            this['vaccineName' + ix] = input;
                          }}
                          value={data['vaccineName' + ix]}
                          onChange={(value) => this.setOnChangeCust(value)}
                        />
                        <label htmlFor="" style={{ width: 50 }}>
                          <Trans>Qty</Trans>
                        </label>
                        {GridUtil.renderInput({
                          inputName: 'vaccineUseAmt' + ix, //*** */
                          type: 'N', //*** */
                          width: 100, // px제외 : 없으면 30 */
                          statsForm: this.state.growingForm, //*** */
                          setOnChange: this.setOnChange, //*** */
                          that: this, //*** */
                          dec: 2,
                        })}{' '}
                        {this.state.growingForm['vaccineUnit' + ix]}
                      </div>
                    ))}
                    <div>
                      <label htmlFor="" style={{ width: 60 }}>
                        <Trans>Remark</Trans>
                      </label>
                      {GridUtil.renderInput({
                        inputName: 'vaccineRem', //*** */
                        type: '', //*** */
                        width: 500, // px제외 : 없으면 30 */
                        maxLength: 100,
                        statsForm: this.state.growingForm, //*** */
                        setOnChange: this.setOnChange, //*** */
                        disabled: data.iuFlag ? false : true,
                        that: this, //*** */
                      })}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Medicine</Trans>
                    <br></br>
                    {dataArr.map((ix) => (
                      <Switch
                        style={{ marginRight: '3px' }}
                        key={ix}
                        checkedChildren={ix}
                        unCheckedChildren={ix}
                        checked={
                          data['medicineView' + ix] || ix === 1 ? true : false
                        }
                        onChange={(c, e) =>
                          this.fnSetFields(c, e, 'medicine' + ix)
                        }
                      />
                    ))}
                  </th>
                  <td className="spac10_left label" colSpan="3">
                    {dataArr.map((ix) => (
                      <div
                        key={ix}
                        style={{
                          display:
                            data['medicineView' + ix] || ix === 1
                              ? 'block'
                              : 'none',
                        }}
                      >
                        <label htmlFor="" style={{ width: 60 }}>
                          <Trans>Name</Trans>
                          {ix}
                        </label>
                        <Search
                          placeholder="Medicine Name"
                          onSearch={(value) =>
                            this.fnSearchMatrial(
                              value,
                              'M',
                              'medicineCode' + ix,
                              'medicineName' + ix,
                              'medicineUnit' + ix
                            )
                          }
                          enterButton
                          style={{ width: '250px' }}
                          className=""
                          ref={(input) => {
                            this['medicineName' + ix] = input;
                          }}
                          value={this.state.growingForm['medicineName' + ix]}
                          onChange={(value) => this.setOnChangeCust(value)}
                        />
                        <label htmlFor="" style={{ width: 50 }}>
                          <Trans>Qty</Trans>
                        </label>
                        {GridUtil.renderInput({
                          inputName: 'medicineUseAmt' + ix, //*** */
                          type: 'N', //*** */
                          width: 100, // px제외 : 없으면 30 */
                          statsForm: this.state.growingForm, //*** */
                          setOnChange: this.setOnChange, //*** */
                          that: this, //*** */
                          dec: 2,
                        })}{' '}
                        {this.state.growingForm['medicineUnit' + ix]}
                      </div>
                    ))}
                    <div>
                      <label htmlFor="" style={{ width: 60 }}>
                        <Trans>Remark</Trans>
                      </label>
                      {GridUtil.renderInput({
                        inputName: 'medicineRem', //*** */
                        type: '', //*** */
                        width: 500, // px제외 : 없으면 30 */
                        maxLength: 100,
                        statsForm: this.state.growingForm, //*** */
                        setOnChange: this.setOnChange, //*** */
                        disabled: data.iuFlag ? false : true,
                        that: this, //*** */
                      })}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Veterinarian</Trans>
                  </th>
                  <td className="spac10_left label" colSpan="3">
                    <label htmlFor="" style={{ width: 60 }}></label>
                    {GridUtil.renderInput({
                      inputName: 'veterinarian', //*** */
                      type: '', //*** */
                      width: 500, // px제외 : 없으면 30 */
                      maxLength: 100,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuFlag ? false : true,
                      that: this, //*** */
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="board_list2 mT10">
            <table>
              <colgroup>
                <col style={{ width: '130px' }} />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th colSpan="3">
                    <Trans>Environment</Trans>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <Trans>Lighting</Trans>
                  </th>
                  <td className="spac10_left">
                    <label htmlFor="">
                      <Trans>On Time</Trans>
                    </label>

                    {GridUtil.renderInput({
                      inputName: 'lightOnTime', //*** */
                      type: 'TM', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      placeholder: 'Time',
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuFlag ? false : true,
                      that: this, //*** */
                    })}

                    <label htmlFor="">
                      <Trans>Off Time</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'lightOffTime', //*** */
                      type: 'TM', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      placeholder: 'Time',
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuFlag ? false : true,
                      that: this, //*** */
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Temp (℃)</Trans>
                  </th>
                  <td className="spac10_left">
                    <label htmlFor="">
                      <Trans>Inside</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'temp', //*** */
                      type: 'N', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      minVal: -50,
                      maxVal: 60,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuFlag ? false : true,
                      that: this, //*** */
                      dec: 1,
                    })}
                    <label htmlFor="">
                      <Trans>Outside</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'tempOut', //*** */
                      type: 'N', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      minVal: -50,
                      maxVal: 60,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuFlag ? false : true,
                      that: this, //*** */
                      dec: 1,
                    })}
                    <br />
                    <label htmlFor="" className="mL0">
                      <Trans>Time</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'tempMtime', //*** */
                      type: 'TM', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      placeholder: 'Time',
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuFlag ? false : true,
                      that: this, //*** */
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Humidity (%)</Trans>
                  </th>
                  <td className="spac10_left">
                    <label htmlFor="">
                      <Trans>Inside</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'humidity', //*** */
                      type: 'N', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      minVal: 0,
                      maxVal: 100,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuFlag ? false : true,
                      that: this, //*** */
                      dec: 1,
                    })}
                    <label htmlFor="">
                      <Trans>Outside</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'humidityOut', //*** */
                      type: 'N', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      minVal: 0,
                      maxVal: 100,
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuFlag ? false : true,
                      that: this, //*** */
                      dec: 1,
                    })}
                    <br />

                    <label htmlFor="" className="mL0">
                      <Trans>Time</Trans>
                    </label>
                    {GridUtil.renderInput({
                      inputName: 'humidityMtime', //*** */
                      type: 'TM', //*** */
                      width: 100, // px제외 : 없으면 30 */
                      placeholder: 'Time',
                      statsForm: this.state.growingForm, //*** */
                      setOnChange: this.setOnChange, //*** */
                      disabled: data.iuFlag ? false : true,
                      that: this, //*** */
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {data.view ? null : (
            <div className="txt_center mT10">
              <button
                className="btn_gray"
                onClick={(e) => this.fnClickCancel()}
              >
                <Trans>Cancel</Trans>
              </button>

              {data.iuFlag ? (
                <button className="btn_save" onClick={(e) => this.fnSave()}>
                  <Trans>save</Trans>
                </button>
              ) : null}
            </div>
          )}
        </div>

        {this.state.popSearchMaterialView ? (
          <PopSearchMaterial
            popSearchMaterialView={this.state.popSearchMaterialView}
            fieldCode={this.state.fieldCode} //return
            fieldName={this.state.fieldName} //return
            fieldUnit={this.state.fieldUnit} //return
            materialCode={this.state.materialCode}
            materialName={this.state.materialName}
            materialType={this.state.materialType}
            fnClosePop={this.fnClosePop}
            fnSelectMaterial={this.fnSelectMaterial}
          />
        ) : null}
      </Fragment>
    );
  }
}
export default withAlert()(GrowingForm);
