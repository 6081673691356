import * as React from 'react';
import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import { SessionUtil, ObjectUtil } from 'utils';
import i18n from 'i18n';

import {
  BarChart,
  LineChart,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  Area,
  Bar,
} from 'recharts';

const GrwTotalR1Rechart = (props) => {
  const [width, setWidth] = useState(0);
  const [type, setType] = useState('monotone');
  const [currentChart, setCurrentChart] = useState();
  const [rowData, setRowData] = useState(props.rowData);
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    const conBoxWidth = ObjectUtil.getScrollWidth('con_box');
    setWidth(conBoxWidth);
    setRowData(props.rowData);
    // console.log(props.rowData);
    // setRowData(data);
  }, [props.rowData]);

  let i = 20;
  const inc = (base) => {
    if (!base) base = 0;
    const v = (base * Math.random() + i++).toFixed(2);
    if (v > maxHeight) setMaxHeight(v);
    return v;
  };
  const data = [
    { week: 'W1', Population: 15 },
    { week: 'W2', Population: 0 },
    { week: 'W3', Population: 0 },
    { week: 'W4', Population: 0 },
    { week: 'W5', Population: 0 },
    { week: 'W6', Population: 0 },
    { week: 'W7', Population: 0 },
    { week: 'W8', Population: 0 },
    { week: 'W9', Population: 35 },
    { week: 'W10', Population: 0 },
    { week: 'W11', Population: 0 },
    { week: 'W12', Population: 0 },
    { week: 'W13', Population: 0 },
    { week: 'W14', Population: 0 },
    { week: 'W15', Population: 0 },
    { week: 'W16', Population: 0 },
    { week: 'W17', Population: 25 },
    { week: 'W18', Population: 0 },
    { week: 'W19', Population: 0 },
    { week: 'W20', Population: 0 },
    { week: 'W21', Population: 0 },
    { week: 'W22', Population: 0 },
    { week: 'W23', Population: 20 },
    { week: 'W24', Population: 0 },
  ];

  // return null;

  return (
    <React.Fragment>
      <BarChart
        ref={(chart) => setCurrentChart(chart)}
        // onClick={() => {
        //   const chartSVG = ReactDOM.findDOMNode(currentChart).children[0];
        // }}
        width={width}
        height={175} // 사이즈 작게 요청. 2020.10.19 by CJ
        data={rowData}
        margin={{ top: 20, right: 60, left: 0, bottom: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="week" />
        <YAxis type="number" domain={[0, 'dataMax + 100']} />
        <Tooltip />
        <Legend />
        <Bar dataKey="PS Population" barSize={20} fill="#413ea0" />
      </BarChart>
    </React.Fragment>
  );
};

export default GrwTotalR1Rechart;
