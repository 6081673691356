import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { Empty, Tooltip } from 'antd';
import '../Breeding.module.css';
/*
 ** breeding/receive/HenHouseList
 ** 계사(HenHouse-HH)
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class ChickinHhList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      prop: {},
      rowData: [],
      hhChickinList: [],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.setState(
      {
        form: this.props.transferForm,
        hhChickinList: this.props.hhChickinList,
      },
      function () {}
    );
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (this.props === nextProps) {
  //     return null;
  //   }
  // }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   console.log('getDerivedStateFromProps', prevState, nextProps);
  //   if (prevState.hhInView !== nextProps.hhInView) {
  //     return {
  //       form: nextProps.transferForm,
  //       hhChickinList: nextProps.hhChickinList
  //     };
  //   }
  //   return null;
  // }

  fnSearch = () => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    //data.receiveNo = '';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/selectHenHouseList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('selectTpFlockInfo', response.data);

        that.setState({ rowData: response.data.rows1 }, function () {
          console.log(that.state);
        });
      })
      .catch(function (error) {
        return [];
      });
  };
  //Hen House Move click
  fnClickMove = (row) => {
    this.props.fnClickMove(row);
  };
  fnClickCancel = (row) => {
    this.props.fnClickCancel(row);
  };
  fnClickEmpty = (row) => {};
  fnClickIn = (row, tp) => {
    console.log('fnClickIn', row);
    if (tp === 'E') {
      //EMPTY
      row.flockInQtyF = 0;
      row.flockInQtyM = 0;
      //row.chickinQtyF = 0;
      //row.chickinQtyM = 0;
      row.currentQtyF = 0;
      row.currentQtyM = 0;
    }

    this.props.fnClickIn(row, this.props.hhFlockRow);
  };
  hhButton = (row) => {
    //console.log('hhButton', this.props, row);
    //console.log('hhButton', this.props.flockListFlockId, row.flockId, row.capa);
    //console.log('hhButton', this.props.hhId, row.hhId);
    if (this.props.flockListFlockId === row.flockId || row.inuse === 'N') {
      //if (row.capa > 0) {
      if (this.props.hhId !== row.hhId) {
        return (
          <dd>
            <button
              className="btn_green"
              onClick={(e) => this.fnClickIn(row, '')}
            >
              <Trans>In</Trans>
            </button>
          </dd>
        );
      } else {
        return (
          <dd>
            <button
              className="btn_gray2"
              onClick={(e) => this.fnClickCancel(row, 'N')}
            >
              <Trans>Cancel</Trans>
            </button>
          </dd>
        );
      }
      //} else {
      //  return null;
      //}
    } else {
      return null;
    }
  };
  hhEmptyButton = (row) => {
    if ('Y' === row.inMoveout)
      return (
        <dd>
          <button className="btn_gray">
            <Trans>In Moveout</Trans>
          </button>
        </dd>
      );

    if (!this.props.flockListFlockId) return null;
    return (
      <dd>
        <button className="btn_green" onClick={(e) => this.fnClickIn(row, 'E')}>
          <Trans>In</Trans>
        </button>
      </dd>
    );
  };
  render() {
    if (!this.props.hhInView) return null;
    let that = this;
    let hhChickinList = [];
    let cc = 0;
    let cc10 = 0;
    const ccClass = 'full_01 h';
    const ccClass100 = 'full_03 h100';
    if (this.props.hhChickinList && this.props.hhChickinList.length > 0) {
      hhChickinList = this.props.hhChickinList.map((row, i) => {
        //if (that.props.hhFlockRow) {
        //  row.flockInQtyF = that.props.hhFlockRow.flockInQtyF;
        //  row.flockInQtyM = that.props.hhFlockRow.flockInQtyM;
        //}
        row.propsHhId = that.props.hhId;
        //if (row.inuse === 'Y') {
        if (row.flockId) {
          // cc = parseInt(
          //   ((row.chickinQtyF + row.chickinQtyM) / row.capacity) * 100,
          //   10
          // );
          // cc10 = parseInt(cc / 10, 10) * 10;
          // row.capa = row.capacity - (row.chickinQtyF + row.chickinQtyM);
          // console.log('HenHouseList', row, that.props.hhFlockRow);

          return (
            <div key={i} className="full">
              <dl className="reinfo">
                <dt>
                  <Tooltip title={row.flockId}>{row.hhId}</Tooltip>
                </dt>
                <dd className="f_left mL10">
                  {row.lastWorkDate > row.lastMoveDate
                    ? row.lastWorkDate
                    : row.lastMoveDate}
                </dd>
                <dd className="f_right mR10">
                  {row.days ? row.days.toLocaleString() : '0'}{' '}
                  <Trans>Days</Trans>
                  {'/'}
                  {row.weeks ? row.weeks.toLocaleString() : '0'}{' '}
                  <Trans>Weeks</Trans>
                </dd>
                <dd>&nbsp; </dd>
                <dd className="txt_left mL10">
                  <Trans>Birds</Trans>
                  {' ('}
                  <Trans>Current</Trans> / <Trans>Chick-In</Trans> ){' '}
                </dd>
                <dd className="txt_left mL10">
                  <Trans>F</Trans> :{' '}
                  {row.currentQtyF ? row.currentQtyF.toLocaleString() : '0'} /{' '}
                  {row.chickinQtyF ? row.chickinQtyF.toLocaleString() : '0'}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Trans>M</Trans> :{' '}
                  {row.currentQtyM ? row.currentQtyM.toLocaleString() : '0'} /{' '}
                  {row.chickinQtyM ? row.chickinQtyM.toLocaleString() : '0'}
                </dd>
                {/* 병아리가 들어있는 계사는 Move 버튼 활성화 */}
                {that.hhButton(row)}
              </dl>
            </div>
          );
        } else {
          return (
            <div key={i} className="full">
              <dl className="reinfo">
                <dt>
                  <Trans>{row.hhId}</Trans>
                </dt>
                <dd>
                  <span className="green">
                    <Trans>EMPTY</Trans>
                  </span>
                </dd>
                {this.hhEmptyButton(row)}
              </dl>
            </div>
          );
        }
      });
    }
    return (
      <Fragment>
        {/* Hen House */}
        <li className="divide_right">
          <div className="dititle">
            <i className="mdi mdi-grid"></i> <Trans>Chick In HH</Trans>
          </div>
          {hhChickinList}
        </li>
      </Fragment>
    );
  }
}
export default ChickinHhList;
