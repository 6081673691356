import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import { Radio } from 'antd';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected
} from 'components/commons/ag-grid/AgGridUtil';
import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { message } from 'antd';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class BroilerHhList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      gridHeight: '365px',
      defaultColDef: {
        filter: true,
        resizable: true,
        editable: true,
        sortable: true
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      paginationPageSize: 10,
      // editType: 'fullRow', // selectEditor와 함께 사용하지 말라고 하네요...
      ColDefs: [
        {
          headerName: i18n.t('Farm Code'),
          field: 'farmCode',
          editable: false,
          getQuickFilterText: function(params) {
            return params.value;
          }
        },
        {
          headerName: i18n.t('HH Id'),
          field: 'hhId',
          editable: false
        },
        {
          headerName: i18n.t('HH Name'),
          field: 'hhName'
        },
        {
          headerName: i18n.t('Width'),
          field: 'width'
        },
        {
          headerName: i18n.t('Length'),
          field: 'length'
        },
        {
          headerName: i18n.t('Capacity'),
          field: 'capacity'
        },
        {
          headerName: i18n.t('HH Type'),
          field: 'hhType'
        },
        {
          headerName: i18n.t('HH Floor Type'),
          field: 'hhFloorType'
        },
        {
          headerName: i18n.t('HH Wall Type'),
          field: 'hhWallType'
        },
        {
          headerName: i18n.t('HH Roof Type'),
          field: 'hhRoofType'
        },
        {
          headerName: i18n.t('HH Frame Type'),
          field: 'hhFrameType'
        },
        {
          headerName: i18n.t('Feeder Type'),
          field: 'feederType'
        },
        {
          headerName: i18n.t('Drinker Type'),
          field: 'drinkerType'
        },
        {
          headerName: i18n.t('Remarks'),
          field: 'remarks'
        }
      ]
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log('nextProps.regDate:', nextProps.regDate);
    // console.log('this.props.regDate:', this.props.regDate);
    if (nextProps.regDate !== this.props.regDate) {
      const { param } = this.state;
      this.fnGetRowData(param);
    }
  }

  fnGetRowData = params => {
    const that = this;
    let url = Server.getRestAPI() + '/broiler/selectTcBroilerHhInfo.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data });
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  fnDeleteRowData = params => {
    const url = Server.getRestAPI() + '/broiler/deleteTcBroilerHhInfo.do';
    // ---------------------------------------------------------
    // (중요) await와 then 은 함께 사용해서는 안됩니다.
    // ---------------------------------------------------------
    return axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    });
  };

  fnUpdateRowData = params => {
    console.log('fnUpdateRowData:', params);
    const url = Server.getRestAPI() + '/broiler/updateTcBroilerHhInfo.do';
    // ---------------------------------------------------------
    // (중요) await와 then 은 함께 사용해서는 안됩니다.
    // ---------------------------------------------------------
    return axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    });
  };

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnGetRowData();
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  onBtRegist = () => {
    this.props.setEditing(true);
  };

  onBtDelete = () => {
    const that = this;
    const selectedRows = this.gridApi.getSelectedNodes();
    if (!selectedRows || selectedRows.length === 0) {
      return message.error(i18n.t('Not selected row'));
    }

    const selectedRow = selectedRows[0];
    const { data } = selectedRow;

    let param = {};
    param['farmCode'] = data['farmCode'];
    param['hhId'] = data['hhId'];

    this.fnDeleteRowData(param)
      .then(function(response) {
        const { data } = response;
        if (data > 0) {
          const { param } = that.state; // 주의: param변수가 교체됨.
          that.fnGetRowData(param);
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  onCellValueChanged = async params => {
    const fieldId = params.column.getId(); // 변경된 cell의 field 값이 전달된다.
    const that = this;

    // newValue의 경우 editType: 'fullRow'아닌 경우에만 의미가 있습니다.
    // fullRow 의 경우 data에 이미 변경된 값이 전달 됩니다.
    // 이 경우 data에 검증이 필요합니다. UNDO할 것 인가? APPLY할 것 인가?
    const { data, newValue } = params;
    let param = _.cloneDeep(data);

    // console.log('params:', params);
    // console.log('data:', data);
    param[fieldId] = newValue;

    console.log('newValue:', newValue);
    console.log('param:', param);

    this.fnUpdateRowData(param);
  };

  onFilterTextBoxChanged = e => {
    const { value } = e.target;
    this.gridApi.setQuickFilter(value);
  };

  render() {
    // return null 은 아무것도 표시하지 않는다는 의미입니다.
    // if (!this.props.localeListView) return null;
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Basic - Broiler HH Info</Trans>
        </h2>

        <div className="con_box">
          <div className="f_left mB20">
            <label htmlFor="">
              <Trans>Filter</Trans>
            </label>
            <input
              type="text"
              className="input_type1"
              onChange={e => this.onFilterTextBoxChanged(e)}
            />
          </div>
          <div className="f_right mB20">
            {!this.props.editing && (
              <button className="btn_blue" onClick={this.onBtRegist}>
                <Trans>Regist</Trans>
              </button>
            )}
            <button className="btn_gray" onClick={this.onBtDelete}>
              <Trans>Delete</Trans>
            </button>
          </div>

          <div className="board_list_s">
            <div
              id="myGrid"
              style={{
                height: this.state.gridHeight,
                width: '100%'
              }}
              className="ag-theme-balham"
            >
              <AgGridReact
                modules={this.state.modules}
                columnDefs={this.state.ColDefs}
                // components={this.state.components}
                // frameworkComponents={this.state.frameworkComponents}
                defaultColDef={this.state.defaultColDef}
                rowSelection={this.state.rowSelection}
                editType={this.state.editType}
                // enterMovesDownAfterEdit={true}
                // enterMovesDown={true}
                // rowModelType={this.state.rowModelType}

                onGridReady={this.onGridReady}
                onCellEditingStarted={this.onCellEditingStarted}
                onCellEditingStopped={this.onCellEditingStopped}
                onRowClicked={this.onRowSelected}
                onRowSelected={this.onRowSelected}
                onRowEditingStarted={this.onRowEditingStarted}
                onRowEditingStopped={this.onRowEditingStopped}
                onCellValueChanged={this.onCellValueChanged}
                rowData={this.state.rowData}
                pagination={true}
                paginationPageSize={this.state.paginationPageSize}
              />
            </div>
          </div>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
