import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, FormUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import '../Common.module.css';

import CountryCode from 'containers/pages/common/system/CountryCode';

import { Radio } from 'antd';
import produce from 'immer';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { message } from 'antd';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class CustomerView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      params: {}
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.rowNode !== this.props.rowNode) {
      let { rowNode } = this.props;
      console.log(rowNode);
      this.setState(
        produce(draft => {
          draft.params['customerCode'] = rowNode['customerCode'];
          draft.params['customerName'] = rowNode['customerName'];
          draft.params['customerKind'] = rowNode['customerKind'];
          draft.params['customerShortName'] = rowNode['customerShortName'];
          draft.params['chiefName'] = rowNode['chiefName'];
          draft.params['farmCode'] = rowNode['farmCode'];
          draft.params['mobilePhone'] = rowNode['mobilePhone'];
          draft.params['telephone'] = rowNode['telephone'];
          draft.params['bizNo'] = rowNode['bizNo'];
          draft.params['fax'] = rowNode['fax'];
          draft.params['division'] = rowNode['division'];
          draft.params['zipCode'] = rowNode['zipCode'];
          draft.params['region'] = rowNode['region'];
          draft.params['addr1'] = rowNode['addr1'];
          draft.params['subRegion'] = rowNode['subRegion'];
          draft.params['addr2'] = rowNode['addr2'];
          draft.params['countryCode'] = rowNode['countryCode'];
          draft.params['currency'] = rowNode['currency'];
          draft.params['bank'] = rowNode['bank'];
          draft.params['bankAccNo'] = rowNode['bankAccNo'];
          draft.params['remarks'] = rowNode['remarks'];
          draft.params['status'] = rowNode['status'];
          draft.params['deleted'] = rowNode['deleted'];
        }),
        function() {
          // console.log('changed params : ', this.state.params);
        }
      );
    }
  }

  setCode = (name, value) => {
    this.setState(
      produce(draft => {
        draft.params[name] = value;
      }),
      function() {}
    );
  };

  onChangeParam = e => {
    const { name, value } = e.target;
    this.setState(
      produce(draft => {
        draft.params[name] = value;
      })
    );
  };

  onBtCancel = () => {
    console.log('onBtCancel');
    this.props.setEditing(false);
  };

  onBtSave = () => {
    const that = this;
    const params = _.cloneDeep(this.state.params); // cloneDeep 중요
    console.log('onBtSave:', this.state.params);
    let url = Server.getRestAPI() + '/basis/insertTcCustomer.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        const { data } = response;
        console.log('onBtSave response.data:', data);
        if (data.error) {
          return message.error(i18n.t(data.error));
        }
        if (data > 0) {
          that.fnClearParams();
          that.props.setRegDate();
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  fnClearParams = () => {
    const that = this;
    this.setState(
      produce(draft => {
        Object.keys(that.state.params).forEach(key => {
          draft.params[`${key}`] = '';
        });
      }),
      function() {
        console.log('state.params:', that.state.params);
      }
    );
  };

  render() {
    if (!this.props.visable) return null;
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <div className="con_box">
          <h3>
            <Trans>Customer Info</Trans>
          </h3>

          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Customer Code</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'customerCode',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Customer Name</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'customerName',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Customer Kind</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'customerKind',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Customer Short Name</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'customerShortName',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Chief Name</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'chiefName',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Farm Code</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'farmCode',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Mobile Phone</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'mobilePhone',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Telephone</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'telephone',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Biz No</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'bizNo',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Fax</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'fax',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Division</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'division',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Zip Code</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'zipCode',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Region</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'region',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Addr1</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'addr1',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Sub Region</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'subRegion',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Addr2</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'addr2',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Country Code</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'countryCode',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Currency</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'currency',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Bank</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'bank',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Bank Account No</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'bankAcctno',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td colSpan="3">
                    {FormUtil.renderInput({
                      inputName: 'remarks',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      className: 'w-80'
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Status</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'status',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                  <th>
                    <Trans>Deleted</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'deleted',
                      type: 'S',
                      that: this,
                      form: this.state.params,
                      onChange: this.onChangeParam,
                      width: 250
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
