import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';
import StatusBar from './StatusBar';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import CustomPinnedRowRenderer from './customPinnedRowRenderer';

/*
 ** [공통 개발시 유의 사항 입니다]
 ** 1. 변수명은 camelCase 로 작성하시길 바랍니다.
 ** 2. 다국어 표현은 <Trans>글자</Trans> 또는 i18n.t('글자')로 부탁 드립니다.
 */
export default class Test01 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      excelName: 'Test01',
      rowData: [],
      params: {},
      ColDefs: [
        {
          headerName: i18n.t('Date'),
          field: 'date',
          pinned: 'left',
          pinnedRowCellRenderer: 'customPinnedRowRenderer',
          pinnedRowCellRendererParams: { style: { color: 'blue' } },
        },
        {
          headerName: i18n.t('Age'),
          field: 'age',
          pinned: 'left',
          pinnedRowCellRenderer: 'customPinnedRowRenderer',
          pinnedRowCellRendererParams: { style: { color: 'red' } },
        },
        {
          headerName: i18n.t('No. of birds'),
          children: [
            {
              headerName: i18n.t('Female'),
              field: 'female',
              pinnedRowCellRenderer: 'customPinnedRowRenderer',
              pinnedRowCellRendererParams: { style: { color: 'green' } },
            },
            {
              headerName: i18n.t('Male'),
              field: 'male',
            },
            {
              headerName: i18n.t('Ratio'),
              field: 'ratio',
            },
          ],
        },
        {
          headerName: i18n.t('Depletion'),
          children: [
            {
              headerName: i18n.t('Female'),
              children: [
                {
                  headerName: i18n.t('Death'),
                  field: 'dfDeath',
                },
                {
                  headerName: i18n.t('Cull'),
                  field: 'dfCull',
                },
                {
                  headerName: i18n.t('%'),
                  field: 'dfPercent0',
                },
                {
                  headerName: i18n.t('Cumm'),
                  field: 'dfCumm',
                },
                {
                  headerName: i18n.t('SE'),
                  field: 'dfSe',
                },
                {
                  headerName: i18n.t('%'),
                  field: 'dfPercent1',
                },
                {
                  headerName: i18n.t('Cumm SE'),
                  field: 'dfCummSE',
                },
              ],
            },
            {
              headerName: i18n.t('Male'),
              children: [
                {
                  headerName: i18n.t('Death'),
                  field: 'dmDeath',
                },
                {
                  headerName: i18n.t('Cull'),
                  field: 'dmCull',
                },
                {
                  headerName: i18n.t('%'),
                  field: 'dmPercent0',
                },
                {
                  headerName: i18n.t('Cumm'),
                  field: 'dmCumm',
                },
                {
                  headerName: i18n.t('SE'),
                  field: 'dmSe',
                },
                {
                  headerName: i18n.t('%'),
                  field: 'dmPercent1',
                },
                {
                  headerName: i18n.t('Cumm SE'),
                  field: 'dmCummSE',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Moving'),
          children: [
            {
              headerName: i18n.t('Female'),
              children: [
                {
                  headerName: i18n.t('In'),
                  field: 'mfIn',
                },
                {
                  headerName: i18n.t('Out'),
                  field: 'mfOut',
                },
              ],
            },
            {
              headerName: i18n.t('Male'),
              children: [
                {
                  headerName: i18n.t('In'),
                  field: 'mmIn',
                },
                {
                  headerName: i18n.t('Out'),
                  field: 'mmOut',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Feed'),
          children: [
            {
              headerName: i18n.t('Female'),
              children: [
                {
                  headerName: i18n.t('Total'),
                  field: 'ffTotal',
                },
                {
                  headerName: i18n.t('Bird'),
                  field: 'ffBird',
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'ffStd',
                },
              ],
            },
            {
              headerName: i18n.t('Male'),
              children: [
                {
                  headerName: i18n.t('Total'),
                  field: 'fmTotal',
                },
                {
                  headerName: i18n.t('Bird'),
                  field: 'fmBird',
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'fmStd',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Body Weight'),
          children: [
            {
              headerName: i18n.t('Female'),
              children: [
                {
                  headerName: i18n.t('Act'),
                  field: 'bfAct',
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'bfStd',
                },
                {
                  headerName: i18n.t('Uniformity'),
                  field: 'bfUniformity',
                },
              ],
            },
            {
              headerName: i18n.t('Male'),
              children: [
                {
                  headerName: i18n.t('Act'),
                  field: 'bmAct',
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'bmStd',
                },
                {
                  headerName: i18n.t('Uniformity'),
                  field: 'bmUniformity',
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Lighting'),
          field: 'lighting',
        },
        {
          headerName: i18n.t('Disease Incidence'),
          field: 'diseaseIncidence',
          width: 160,
          minWidth: 160,
        },
        {
          headerName: i18n.t('Activities/Vaccine'),
          field: 'activitiesVaccine',
          width: 160,
          minWidth: 160,
        },
        {
          headerName: i18n.t('Remark'),
          field: 'remark',
        },
      ],
      pinnedBottomRowData: [],
      frameworkComponents: {
        statusBarComponent: StatusBar,
        customPinnedRowRenderer: CustomPinnedRowRenderer,
      },
      statusBar: {
        statusPanels: [
          {
            // enableRangeSelection={true} 이어야만 의미가 있는 컴포넌트 입니다.
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
              // possible values are: 'count', 'sum', 'min', 'max', 'avg'
              aggFuncs: ['count', 'sum', 'min', 'max', 'avg'],
            },
          },
        ],
      },
      gridHeight: '365px',
      defaultColDef: {
        flex: 1,
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        width: 100,
        minWidth: 100,
        cellClass: 'text-center',
        suppressSizeToFit: true,
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagenation: false,
      paginationPageSize: 10,
      allowedAggFuncs: ['sum', 'avg'],
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.setState({ rowData: sampleData });
    console.log(this.state.ColDefs);
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // console.log(this.gridColumnApi.getAllGridColumns());
    // console.log(this.gridApi);

    // 1. 모든 colId 가져오기
    let columns = [];
    this.gridColumnApi.getAllGridColumns().map((row) => {
      if (row.visible) columns.push(row.colId);
    });

    // 2. 컬럼별 합을 구하기
    const obj = this.fnCalcStatus(columns);
    const arr = new Array(obj);

    // 3. 그리드에 합계 ROW를 Bottom에 반영하기
    // this.setState({ rowData: this.state.rowData.concat(obj) });
    this.gridApi.setPinnedBottomRowData(arr);

    params.api.sizeColumnsToFit();
  };

  // 컬럼별 계산하는 메소드 정의
  // 결과를 result={}에 담아서 줍니다.
  fnCalcStatus = (columns) => {
    // const columns = ['date', 'female', 'male'];
    let result = {};
    columns.forEach((ele) => {
      result[ele] = !result[ele] ? 0 : result[ele];
      this.gridApi.forEachNodeAfterFilter((row) => {
        if (row.data[ele]) result[ele] += Number(row.data[ele].toFixed(2));
      });
    });
    return result;
  };

  // -----------------------------------------------------------
  // 참고용 : ES/ETL 서버 주소(API)를 지정해야 합니다.
  // -----------------------------------------------------------
  fnGetRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/work/selectTcTaskDtl.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtExport = () => {
    const fileName =
      i18n.t(this.state.excelName) + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t(this.state.excelName);
    const params = {
      allColumns: true,
      columnGroups: true,
      fileName: fileName,
      sheetName: sheetName,
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onSearch = (params) => {
    // this.fnGetRowData(params);
    console.log(params);
  };

  render() {
    return (
      <React.Fragment>
        <h3>
          <Trans>Test01(컬럼별 합을 구하는 예제)</Trans>
        </h3>

        <div className="con_box">
          <div className="f_right mB20">
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
          </div>
          <div className="board_list_s">
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  excelStyles={this.state.excelStyles}
                  enableRangeSelection={true}
                  // pinnedBottomRowData={this.state.pinnedBottomRowData}
                  frameworkComponents={this.state.frameworkComponents}
                  statusBar={this.state.statusBar}
                  onGridReady={this.onGridReady}
                  rowData={this.state.rowData}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

let i = 0;
const sampleData = [
  {
    date: i++,
    age: i++,
    female: i++,
    male: i++,
    ratio: i++,
    dfDeath: i++,
    dfCull: i++,
    dfPercent0: i++,
    dfCumm: i++,
    dfSe: i++,
    dfPercent1: i++,
    dfCummSE: i++,
    dmDeath: i++,
    dmCull: i++,
    dmPercent0: i++,
    dmCumm: i++,
    dmSe: i++,
    dmPercent1: i++,
    dmCummSE: i++,
    mfIn: i++,
    mfOut: i++,
    mmIn: i++,
    mmOut: i++,
    ffTotal: i++,
    ffBird: i++,
    ffStd: i++,
    fmTotal: i++,
    fmBird: i++,
    fmStd: i++,
    bfAct: i++,
    bfStd: i++,
    bfUniformity: i++,
    bmAct: i++,
    bmStd: i++,
    bmUniformity: i++,
    lighting: i++,
    diseaseIncidence: i++,
    activitiesVaccine: i++,
    remark: i++,
  },
  {
    date: i++,
    age: i++,
    female: i++,
    male: i++,
    ratio: i++,
    dfDeath: i++,
    dfCull: i++,
    dfPercent0: i++,
    dfCumm: i++,
    dfSe: i++,
    dfPercent1: i++,
    dfCummSE: i++,
    dmDeath: i++,
    dmCull: i++,
    dmPercent0: i++,
    dmCumm: i++,
    dmSe: i++,
    dmPercent1: i++,
    dmCummSE: i++,
    mfIn: i++,
    mfOut: i++,
    mmIn: i++,
    mmOut: i++,
    ffTotal: i++,
    ffBird: i++,
    ffStd: i++,
    fmTotal: i++,
    fmBird: i++,
    fmStd: i++,
    bfAct: i++,
    bfStd: i++,
    bfUniformity: i++,
    bmAct: i++,
    bmStd: i++,
    bmUniformity: i++,
    lighting: i++,
    diseaseIncidence: i++,
    activitiesVaccine: i++,
    remark: i++,
  },
  {
    date: i++,
    age: i++,
    female: i++,
    male: i++,
    ratio: i++,
    dfDeath: i++,
    dfCull: i++,
    dfPercent0: i++,
    dfCumm: i++,
    dfSe: i++,
    dfPercent1: i++,
    dfCummSE: i++,
    dmDeath: i++,
    dmCull: i++,
    dmPercent0: i++,
    dmCumm: i++,
    dmSe: i++,
    dmPercent1: i++,
    dmCummSE: i++,
    mfIn: i++,
    mfOut: i++,
    mmIn: i++,
    mmOut: i++,
    ffTotal: i++,
    ffBird: i++,
    ffStd: i++,
    fmTotal: i++,
    fmBird: i++,
    fmStd: i++,
    bfAct: i++,
    bfStd: i++,
    bfUniformity: i++,
    bmAct: i++,
    bmStd: i++,
    bmUniformity: i++,
    lighting: i++,
    diseaseIncidence: i++,
    activitiesVaccine: i++,
    remark: i++,
  },
];
