import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import { Const, Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';

import i18n from 'i18next';
import MoveInOutList from './MoveInOutList';
import MoveInForm from './MoveInForm';
import MoveInList from './MoveInList';
import scrollToComponent from 'react-scroll-to-component';

import '../Hatchery.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class MoveIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iuFlag: 'I',
      rowData: [],
      moveInFormView: false,
      regDate: DateUtil.toDateTimeString()
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  fnSetRegDate = () => {
    this.setState({
      regDate: DateUtil.toDateTimeString(),
      moveInFormView: false
    });
  };

  fnClickMoveIn = data => {
    this.setState(
      { moveInFormView: true, rowData: data, iuFlag: 'I' },
      function() {
        scrollToComponent(this['moveInFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000
        });
      }
    );
  };

  fnClickCancel = () => {
    this.setState({ moveInFormView: false }, function() {});
  };

  fnClickModify = data => {
    this.setState(
      {
        rowData: data,
        moveInFormView: true,
        iuFlag: 'U'
      },
      function() {
        scrollToComponent(this['moveInFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000
        });
      }
    );
  };

  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Move In</Trans>
        </h2>

        <MoveInOutList
          fnClickMoveIn={this.fnClickMoveIn}
          regDate={this.state.regDate}
        />
        <MoveInForm
          ref={section => {
            this.moveInFormView = section;
          }}
          moveInFormView={this.state.moveInFormView}
          rowData={this.state.rowData}
          fnSetRegDate={this.fnSetRegDate}
          iuFlag={this.state.iuFlag}
          fnClickCancel={this.fnClickCancel}
        />
        <MoveInList
          regDate={this.state.regDate}
          fnSetRegDate={this.fnSetRegDate}
          fnClickModify={this.fnClickModify}
        />

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
