import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import CountryCode from 'containers/pages/common/system/CountryCode';

import { Radio } from 'antd';
import produce from 'immer';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { message } from 'antd';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class BroilerHhRegist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        farmCode: '',
        hhId: '',
        hhName: '',
        width: '',
        length: '',
        capacity: '',
        hhType: '',
        hhFloorType: '',
        hhWallType: '',
        hhRoofType: '',
        hhFrameType: '',
        feederType: '',
        drinkerType: '',
        remarks: ''
      }
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  setCountryCode = value => {
    this.setState(
      produce(draft => {
        draft.params['countryCode'] = value;
      }),
      function() {}
    );
  };

  onChangeParam = e => {
    const { name, value } = e.target;
    this.setState(
      produce(draft => {
        draft.params[name] = name === 'mesgId' ? value.toUpperCase() : value;
      })
    );
  };

  onBtCancel = () => {
    console.log('onBtCancel');
    this.props.setEditing(false);
  };

  onBtSave = () => {
    const that = this;
    const params = _.cloneDeep(this.state.params); // cloneDeep 중요
    console.log('onBtSave:', this.state.params);
    let url = Server.getRestAPI() + '/basis/insertTcBroilerHhInfo.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        const { data } = response;
        console.log('onBtSave response.data:', data);
        if (data.error) {
          return message.error(i18n.t(data.error));
        }
        if (data > 0) {
          that.fnClearParams();
          that.props.setRegDate();
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  fnClearParams = () => {
    const that = this;
    this.setState(
      produce(draft => {
        Object.keys(that.state.params).forEach(key => {
          draft.params[`${key}`] = '';
        });
      }),
      function() {
        console.log('state.params:', that.state.params);
      }
    );
  };

  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <div className="con_box">
          <h3>
            <Trans>New Broiler HH Info</Trans>
          </h3>

          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Farm Code</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="farmCode"
                      className="input_type1"
                      maxLength="9"
                      placeholder=""
                      value={this.state.params.farmCode}
                      onChange={e => this.onChangeParam(e)}
                    />
                  </td>
                  <th>
                    <Trans>HH Id</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="hhId"
                      className="input_type1"
                      maxLength="10"
                      placeholder=""
                      value={this.state.params.hhId}
                      onChange={e => this.onChangeParam(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>HH Name</Trans>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="hhName"
                      className="input_type1"
                      maxLength="10"
                      placeholder=""
                      value={this.state.params.hhName}
                      onChange={e => this.onChangeParam(e)}
                    />
                  </td>
                  <th>
                    <Trans>Width</Trans>
                  </th>
                  <td>
                    <input
                      type="number"
                      pattern="[0-9]*"
                      name="width"
                      className="input_type1"
                      maxLength="22"
                      placeholder=""
                      value={this.state.params.width}
                      onChange={e => this.onChangeParam(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Length</Trans>
                  </th>
                  <td>
                    <input
                      type="number"
                      pattern="[0-9]*"
                      name="length"
                      className="input_type1"
                      maxLength="22"
                      placeholder=""
                      value={this.state.params.length}
                      onChange={e => this.onChangeParam(e)}
                    />
                  </td>
                  <th>
                    <Trans>Capacity</Trans>
                  </th>
                  <td>
                    <input
                      type="number"
                      pattern="[0-9]*"
                      name="capacity"
                      className="input_type1"
                      maxLength="22"
                      placeholder=""
                      value={this.state.params.capacity}
                      onChange={e => this.onChangeParam(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>HH Type</Trans>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="hhType"
                      className="input_type1"
                      maxLength="1"
                      placeholder=""
                      value={this.state.params.hhType}
                      onChange={e => this.onChangeParam(e)}
                    />
                  </td>
                  <th>
                    <Trans>HH Floor Type</Trans>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="hhFloorType"
                      className="input_type1"
                      maxLength="1"
                      placeholder=""
                      value={this.state.params.hhFloorType}
                      onChange={e => this.onChangeParam(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>HH Wall Type</Trans>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="hhWallType"
                      className="input_type1"
                      maxLength="1"
                      placeholder=""
                      value={this.state.params.hhWallType}
                      onChange={e => this.onChangeParam(e)}
                    />
                  </td>
                  <th>
                    <Trans>HH Roof Type</Trans>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="hhRoofType"
                      className="input_type1"
                      maxLength="1"
                      placeholder=""
                      value={this.state.params.hhRoofType}
                      onChange={e => this.onChangeParam(e)}
                    />
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>HH Frame Type</Trans>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="hhFrameType"
                      className="input_type1"
                      maxLength=""
                      placeholder=""
                      value={this.state.params.hhFrameType}
                      onChange={e => this.onChangeParam(e)}
                    />
                  </td>
                  <th>
                    <Trans>Feeder Type</Trans>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="feederType"
                      className="input_type1"
                      maxLength="1"
                      placeholder=""
                      value={this.state.params.feederType}
                      onChange={e => this.onChangeParam(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Drinker Type</Trans>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="drinkerType"
                      className="input_type1"
                      maxLength=""
                      placeholder=""
                      value={this.state.params.drinkerType}
                      onChange={e => this.onChangeParam(e)}
                    />
                  </td>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="remarks"
                      className="input_type1"
                      maxLength="300"
                      placeholder=""
                      value={this.state.params.remarks}
                      onChange={e => this.onChangeParam(e)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="txt_center mT40">
            <button className="btn_gray" onClick={this.onBtCancel}>
              <Trans>Cancel</Trans>
            </button>
            <button className="btn_save" onClick={this.onBtSave}>
              <Trans>Save</Trans>
            </button>
          </div>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
