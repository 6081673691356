import React, { Fragment } from 'react';
import { SessionUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import HenHouseMoveList from './HenHouseMoveList';
import ChickinHhList from './ChickinHhList';
//import PopFlock from './ZPopFlock';
import PopMoveIn from './PopMoveIn'; //계군선택 - 계사선택 Move click
import PopChickinHh from './PopChickinHh'; //계군선택 - 계사선택 In button click
import MovingLogList from './MovingLogList'; //
import BreedRecvList from '../receive/BreedRecvList';
import FlockList from '../receive/FlockList'; //입력 후 Flock(계군) 선택, Flock 추가
import FlockInfo from '../../common/basic/FlockInfo'; //Flock 관리
import scrollToComponent from 'react-scroll-to-component';
import { Modal, Button } from 'antd';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';

const { confirm } = Modal;
/*
 ** breeding/receive/Flock
 ** Flock입식
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class Flock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      breedRecvListView: true,
      columnDefs: [
        {
          headerName: 'Receive No',
          field: 'docReceiveNo',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Hatchery',
          field: 'hatcheryId',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Supplier',
          field: 'customerName',
          width: 250,
          cellStyle: { textAlign: 'left' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Recv Date',
          field: 'receiveDate',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Strain',
          field: 'strainName',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Female Qty',
          field: 'totalRecvQtyF',
          width: 120,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          //precision: 0,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Male Qty',
          field: 'totalRecvQtyM',
          width: 120,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          //precision: 0,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Remarks',
          field: 'remarks',
          width: 300,
          cellStyle: { textAlign: 'left' },
          suppressSizeToFit: false,
        },
      ],
      columnDefs2: [
        {
          headerName: 'Flock',
          field: 'flockId',
          width: 200,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Receive No',
          field: 'docReceiveNo',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Strain',
          field: 'strainName',
          width: 200,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Receive Date',
          field: 'receiveDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Receive Qty (F)',
          field: 'totalRecvQtyF',
          width: 150,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          //precision: 0,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Receive Qty (M)',
          field: 'totalRecvQtyM',
          width: 150,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          //precision: 0,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Flock In Qty (F)',
          field: 'flockInQtyF',
          width: 150,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          //precision: 0,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Flock In Qty (M)',
          field: 'flockInQtyM',
          width: 150,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          //precision: 0,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: false,
        },
        {
          headerName: '',
          field: 'ck',
          width: 110,
          cellStyle: { textAlign: 'center' },
          cellRenderer: (params) => {
            if (params.data.flockId && !params.data.chickinDate) {
              return (
                '<button class="btn_del">' + i18n.t('Delete') + '</button>'
              );
            } else return null;
          },
          suppressSizeToFit: true,
        },
      ],

      breedRecvList: [],
      selectFlockListView: false,
      chickinFlockListView: false,
      form: {},
      prop: {},
      rowData: [],
      flockList: [],
      hhChickinList: [],
      movingLogList: [],
      hhMoveView: true,
      hhInView: false, //HenHouseFlockList 에서 Chick in HH click
      popMoveIn: false,
      popEmpty: false,
      popIn: false,
      popFlock: false,
      hhFlockId: '', //Flock click
      flockListFlockId: '', //
      popInRow: {},
      popMoveRow: {},
      flockView: false,
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    //this.fnSearch();
    this.setState(
      {
        breedRecvListView: true, //***** */
      },
      function () {}
    );
  }
  fnSearch = async () => {
    let that = this;

    await that.setState({
      breedRecvList: [],
      breedRecvList2: [],
      breedRecvListView: false, //***** */
    });

    let data = {};
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/selectDocTransfer.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            breedRecvList: response.data.rows3,
            breedRecvList2: response.data.rows4,
            breedRecvListView: true, //***** */
            selectFlockListView: false, //flock 선택 pop 닫기
            //selectFlockListView: false,
            //chickinFlockListView: false
          },
          function () {
            //that['g2'].refreshCells({ force: true });
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };

  //Flock List click
  //넣을수 없는 계사 = 다른 계군이 들어있는 계사는 회색
  //넣을수 있는 계사 = 같은 계군이 들어있는 계사, 비어있는 계사 는 In
  //<Trans>Chick in HH</Trans>
  //hhFlockList - Chick in HH click
  fnClickFlock = (row) => {
    this.setState(
      {
        hhMoveView: false,
        hhInView: true, //HenHouseFlockList 에서 Chick in HH click
        flockListFlockId: row.flockId,
        hhFlockRow: row,
        hhId: '', //Flcok clcik
      },
      function () {}
    );
  };

  //HenHouseFlockList 에서 Chick in HH click
  //hhFlockList - Cancel click
  fnClickFlockCancel = (row) => {
    this.setState(
      {
        flockList: [],
        flockListFlockId: '',
        hhMoveView: true,
        hhInView: false,
        hhFlockId: '',
      },
      function () {
        this.fnSearch();
      }
    );
  };
  fnClickCancel = (row) => {
    this.setState({ hhFlockId: '' }, function () {});
  };
  fnClosePopFlock = (e, data) => {
    this.setState({ [data]: false }, function () {});
  };
  fnClosePop = (data) => {
    this.setState({ [data]: false }, function () {});
  };
  fnClickMoveIn = (row, hhId, hhFlockRow) => {
    //if (hhId === '') {
    //  //Flock click - In click
    //  this.setState({ popIn: true, popInRow: row }, function() {});
    //} else {
    //Move click - In click
    row.fromHhId = hhFlockRow.hhId;
    row.fromFlockId = hhFlockRow.flockId;
    row.fromChickinDate = hhFlockRow.chickinDate;
    row.fromChickinQtyF = hhFlockRow.chickinQtyF;
    row.fromChickinQtyM = hhFlockRow.chickinQtyM;
    row.fromCurrentQtyF = hhFlockRow.currentQtyF;
    row.fromCurrentQtyM = hhFlockRow.currentQtyM;
    row.fromCapacity = hhFlockRow.capacity;
    console.log('fnClickMoveIn', row);
    this.setState({ popMoveIn: true, popMoveRow: row }, function () {});
    //}
  };
  //HenHouseFlockList 에서 Chick in HH click
  //click In
  fnClickIn = (row, hhFlockRow) => {
    row.fromHhId = hhFlockRow.hhId;
    row.fromFlockId = hhFlockRow.flockId;
    row.fromChickinDate = hhFlockRow.chickinDate;
    row.fromChickinQtyF = hhFlockRow.chickinQtyF;
    row.fromChickinQtyM = hhFlockRow.chickinQtyM;
    row.fromCapacity = hhFlockRow.capacity;
    //row.chickinQtyF = 0;
    //row.chickinQtyM = 0;
    console.log('fnClickIn', this.state);
    this.setState({ popIn: true, popInRow: row }, function () {});
  };
  //넣을수 없는 계사 = 다른 계군이 들어있는 계사는 회색
  //넣을수 있는 계사 = 같은 계군이 들어있는 계사, 비어있는 계사 는 In
  fnClickMove = (row) => {
    console.log('fnClickMove', row);

    this.setState(
      {
        hhId: row.hhId, //Hen House Move click
        hhFlockId: row.flockId,
        hhFlockRow: row,
      },
      function () {}
    );
  };
  onCellClicked = (data) => {
    console.log(data);
    this.setState(
      { selectFlockListView: true, tpBreedRecvData: data },
      function () {}
    );
  };
  onCellClickedDel = (e) => {
    if (e.column.colId !== 'ck') return false;
    let that = this;
    console.log(e.data);
    confirm({
      title: i18n.t('Are you sure delete this task?'),
      //icon: <ExclamationCircleOutlined />,
      //content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        console.log('OK');
        that.fnDelete(e.data);
      },
      onCancel() {
        console.log('Cancel');
      },
    });

    // this.setState(
    //   { selectFlockListView: true, tpBreedRecvData: data },
    //   function() {}
    // );
  };
  fnDelete = (data) => {
    let that = this;
    data.flockId = null;
    data.flockRdate = null;
    data.flockInQtyF = 0;
    data.flockInQtyM = 0;

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/saveBreedRecvFlock.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.alert.show(i18n.t('Has been saved.'));
        that.setState({ breedRecvListView: false }, function () {
          that.fnSearch();
        });
      })
      .catch(function (error) {
        return [];
      });
  };
  fnCloseFlockList = (e) => {
    this.setState({ selectFlockListView: false });
  };
  fnFlock = () => {
    this.setState({ flockView: true }, function () {
      scrollToComponent(this['flockForm'], {
        offset: 0,
        align: 'top',
        duration: 1000,
      });
    });
  };
  fnCloseFlock = (e) => {
    this.setState({ flockView: false });
  };
  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Chick In to Flock</Trans>
        </h2>
        <div className="con_box">
          {/* <div className="f_right">
            <button className="btn_blue" onClick={e => this.fnFlock()}>
              <Trans>Flock</Trans>
            </button>
          </div> */}
          {this.state.breedRecvListView ? (
            <div className="both">
              <BreedRecvList
                //breedRecvListView={this.state.breedRecvListView}
                rowData={this.state.breedRecvList}
                //onCellClicked={this.onCellClicked}
                fnSelectBreedReceive={this.onCellClicked}
                flockYn="" //AND A.FLOCK_ID IS NULL
                select={true}
                columnDefs={this.state.columnDefs}
              />
            </div>
          ) : null}
          {this.state.breedRecvListView ? (
            <div className="both mT50">
              <BreedRecvList
                //breedRecvListView={this.state.breedRecvListView}
                title={i18n.t('Chick In Flock List')}
                height="300px"
                rowData={this.state.breedRecvList2}
                onCellClicked={this.onCellClickedDel}
                //fnSelectBreedReceive={this.onCellClicked}
                flockYn="Yes" //AND A.FLOCK_ID IS NOT NULL
                select={true}
                height="400px"
                columnDefs={this.state.columnDefs2}
              />
            </div>
          ) : null}
        </div>
        {/* Flock 선택 popup */}
        {this.state.selectFlockListView ? (
          <FlockList
            title={i18n.t('Chick In Flock')}
            tpBreedRecvData={this.state.tpBreedRecvData}
            fnCloseFlockList={this.fnCloseFlockList}
            fnSearch={this.fnSearch} //선택-저장
          />
        ) : null}
        {/*
        {this.state.flockView ? (
          <FlockMng
            flockView={this.state.flockView}
            fnCloseFlock={this.fnCloseFlock}
          />
        ) : null} */}

        {this.state.flockView ? (
          <FlockInfo
            ref={(section) => {
              this.flockForm = section;
            }}
            flockView={this.state.flockView}
            fnCloseFlock={this.fnCloseFlock}
          />
        ) : null}
      </Fragment>
    );
  }
}
export default withAlert()(Flock);
