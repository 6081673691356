import React, { Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { Modal } from 'antd';
import NumberFormat from 'react-number-format';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';

const { confirm } = Modal;
/*
 ** Breed - Deliver - EggTransferList
 **
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class EggTransferList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInstance: this,
      deleteConfirm: false,
      customerName: '',
      searchFromWkDt: moment(DateUtil.today())
        .add('-1', 'M')
        .format('YYYY-MM-DD'),
      searchToWkDt: DateUtil.today(),
      form: {
        transferDate: DateUtil.today(),
      },
      toFarmCodeFocus: false,
      eggTransferMastList: [],
      modules: AllModules,
      rowData: [],
      columnDefs: [
        {
          headerName: 'Transfer No',
          field: 'transferNo',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Transfer Date',
          field: 'transferDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Hatchery',
          field: 'hatcheryId',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Transfer Qty',
          field: 'transferQty',
          type: 'numericColumn',
          width: 120,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Truck No',
          field: 'truckNo',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Order No',
          field: 'pOrderNo',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Remarks',
          field: 'remarks',
          width: 200,
          cellStyle: { textAlign: 'left' },
        },
        {
          headerName: '',
          field: 'ck',
          width: 110,
          cellStyle: { textAlign: 'center' },
          cellRenderer: (params) => {
            const { received } = params.data;
            //if (params.data.flockId && !params.data.chickinDate) {
            if (received === 'N')
              return (
                '<button class="btn_del">' + i18n.t('Delete') + '</button>'
              );
            //} else return null;
          },
          suppressSizeToFit: true,
        },
        // {
        //   headerName: 'Type',
        //   field: 'transferType',
        //   width: 100,
        //   cellStyle: { textAlign: 'center' },
        //   suppressSizeToFit: true
        // },
        // {
        //   headerName: 'Customer',
        //   field: 'customerName',
        //   width: 200,
        //   cellStyle: { textAlign: 'left' },
        //   suppressSizeToFit: true
        // },
        // {
        //   headerName: 'Weight',
        //   field: 'weight',
        //   type: 'numericColumn',
        //   width: 150,
        //   valueFormatter: function(params) {
        //     return GridUtil.formatNumber(params);
        //   },
        //   cellStyle: { textAlign: 'right' },
        //   suppressSizeToFit: true
        // },
      ],
      eggTransferEpdateRowData: [],
      eggTransferEpdateColumnDefs: [
        {
          headerName: 'Ep Date',
          field: 'epDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Flock',
          field: 'flockId',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'HH',
          field: 'hhId',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Strain',
          field: 'strainCode',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Good',
          field: 'goodEggQty',
          type: 'numericColumn',
          width: 100,
          function(params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Crack',
          field: 'crackEggQty',
          type: 'numericColumn',
          width: 100,
          function(params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Dirty',
          field: 'dirtyEggQty',
          type: 'numericColumn',
          width: 100,
          function(params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Jumbo',
          field: 'jumboEggQty',
          type: 'numericColumn',
          width: 100,
          function(params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Small',
          field: 'smallEggQty',
          type: 'numericColumn',
          width: 100,
          function(params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Thin',
          field: 'thinEggQty',
          type: 'numericColumn',
          width: 100,
          function(params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Miss Shape',
          field: 'missShapeEggQty',
          type: 'numericColumn',
          width: 100,
          function(params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
      ],
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        suppressSizeToFit: false,
      },
      rowSelection: 'single',
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    this.setState(
      { form: { ...this.state.form, searchFromWkDt: DateUtil.today() } },
      function () {
        this.fnSearch();
      }
    );
  }
  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  fnSearch = (t) => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.searchFromWkDt = this.state.searchFromWkDt;
    data.searchToWkDt = this.state.searchToWkDt;

    if (t !== 'F') {
      if (!data.searchFromWkDt) {
        this.searchFromWkDt.current.focus();
        return false;
      }
    }

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/selectTpEggTransferMastList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ rowData: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };

  //Grid row 선택
  onCellClicked = (e) => {};
  onCellClickedMast = (e) => {
    console.log('onCellClicked', e);
    //선택버튼 클릭
    //this.props.fnSelectFlock(e.data, this.state.tpBreedRecvData);

    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.transferNo = e.data.transferNo;
    data.discardNo = e.data.discardNo;

    console.log(data);
    let url = Server.getRestAPI();
    url += '/breed/release/selectTpEggTransferEpdateList.do';

    axios({
      method: 'post',
      url: url,
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            customerName: e.data.customerName,
            eggTransferEpdateRowData: response.data.rows1,
          },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });
    if (e.column.colId === 'delete') {
      if (e.data.salesDate === DateUtil.today()) {
        confirm({
          title: i18n.t('Do you Want to delete these items?'),
          //content: '아래 거래내역도 삭제됩니다',
          onOk() {
            that.fnDelete(e.data);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }
    }
  };

  onCellClickedDel = (e) => {
    if (e.column.colId !== 'ck') return false;
    if (e.data.received == 'Y') return false;

    let that = this;
    console.log(e.data);
    confirm({
      title: i18n.t('Are you sure delete this task?'),
      //icon: <ExclamationCircleOutlined />,
      //content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        console.log('OK');
        that.fnDelete(e.data);
      },
      onCancel() {
        console.log('Cancel');
      },
    });

    // this.setState(
    //   { selectFlockListView: true, tpBreedRecvData: data },
    //   function() {}
    // );
  };
  fnDelete = (data) => {
    let that = this;
    data.iuFlag = 'D';

    console.log('delete', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/saveEggTransfer.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        that.props.fnSearch();
        if (data === 0)
          return that.props.alert.error(
            i18n.t('The receipt is complete and cannot be deleted.')
          );
        that.props.alert.error(i18n.t('Canceled'));
      })
      .catch(function (error) {
        return [];
      });
  };

  setOnChange = (e, params) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    that.setState({ [col]: val }, function () {});
  };

  render() {
    let cullingStock = [];
    if (!this.props.eggSalesListView) return null;
    const data = this.state.form;

    return (
      <Fragment>
        <h3 className="mT20">
          <Trans>Transfer List</Trans>
        </h3>

        <div className="mT20 f_right">
          <label htmlFor="">
            <Trans>Date</Trans>
          </label>
          {GridUtil.renderInput({
            inputName: 'searchFromWkDt', //*** */
            type: 'D', //*** */
            that: this, //*** */
            statsForm: this.state, //*** */
            setOnChange: this.setOnChange, //*** */
            width: 120, // px제외 : 없으면 100% */
            placeholder: 'Date',
          })}
          &nbsp; ~ &nbsp;
          {GridUtil.renderInput({
            inputName: 'searchToWkDt', //*** */
            type: 'D', //*** */
            that: this, //*** */
            statsForm: this.state, //*** */
            setOnChange: this.setOnChange, //*** */
            width: 120, // px제외 : 없으면 100% */
            placeholder: 'Date',
          })}
          <button className="btn_gray" onClick={() => this.fnSearch()}>
            <Trans>Search</Trans>
          </button>
        </div>

        <div
          className="ag-theme-balham"
          style={{ height: '300px', width: '100%' }}
        >
          <AgGridReact
            modules={this.state.modules}
            onGridReady={(e) => this.onGridReady(e, 'mast')}
            columnDefs={this.state.columnDefs}
            rowData={this.state.rowData.length > 0 ? this.state.rowData : []}
            ////onRowClicked={e => this.onRowClicked(e)}
            ////onCellClicked={e => this.onCellClickedMast(e)}
            //onSelectionChanged={e => this.onSelectionChanged(e, 'mast')}
            onCellClicked={this.onCellClickedDel}
            rowSelection={'single'}
            onCellFocused={(e) => {
              e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
            }}
          ></AgGridReact>
        </div>
        {/*
            <h4 style={{ marginTop: '20px' }}>
              <Trans>Detail</Trans>
            </h4>
            <div
              className="ag-theme-balham"
              style={{ height: '300px', width: '100%' }}
            >
              <AgGridReact
                modules={this.state.modules}
                onGridReady={e => this.onGridReady(e, 'dtl')}
                getRowHeight={e => GridUtil.getRowHeight(e)}
                columnDefs={this.state.eggTransferEpdateColumnDefs}
                rowData={this.state.eggTransferEpdateRowData}
                //onRowClicked={e => this.onRowClicked(e)}
                //onCellClicked={e => this.onCellClicked(e)}
                //rowHeight="40"
                onCellFocused={e => {
                  e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
                }}
              ></AgGridReact>
            </div> */}
      </Fragment>
    );
  }
}
export default withAlert()(EggTransferList);
