import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, ObjectUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';
import { inject, observer } from 'mobx-react';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import {
  Select,
  Radio,
  Button,
  Icon,
  DatePicker,
  Checkbox,
  Row,
  Col,
} from 'antd';

import SearchInput from 'utils/SearchInput';

import '../Report.module.css';

/*
 ** [공통 개발시 유의 사항 입니다]
 ** 1. 변수명은 camelCase 로 작성하시길 바랍니다.
 ** 2. 다국어 표현은 <Trans>글자</Trans> 또는 i18n.t('글자')로 부탁 드립니다.
 */
{
  /* Division, Region, Plazma, Checkbox 검색 */
}
@inject((stores) => ({ mainStore: stores.MainStore }))
@observer
export default class AFCheckboxSearch extends React.PureComponent {
  constructor(props) {
    super(props);
    const { mainStore } = props;
    const { isAreaManager, isDivisionManager } = mainStore;
    const { groupId, userLevel, division } = mainStore.getUser();
    this.state = {
      groupId: groupId,
      userLevel: userLevel,
      disables: {
        checkBox: true,
        submit: true,
      },
      params: {
        // type: isAreaManager() ? 'area' : 'farm', // 매니저만이 지역 검색을 할 수 있습니다.
        type: 'area',
        dateFormat: SessionUtil.getDateFormat(),
        startDate: DateUtil.toDateFormat(new Date()),
        endDate: DateUtil.toDateFormat(new Date()),
        // REGION 권한 이하로는 자신의 DIVISION 값으로 고정 됩니다.
        // division:
        //   userLevel === Const.COUNTRY || userLevel === Const.DIVISION
        //     ? undefined
        //     : division,

        checkBox: [
          'depAct',
          'depStd',
          'pfAct',
          'pfStd',
          'bwAct',
          'bwStd',
          'fcrAct',
          'fcrStd',
          'ipAct',
          'ipStd',
        ],
      },
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // farmCode 또는 Area 정보가 설정이 되면, 날짜와 검색 버튼이 활성화 됩니다.
    if (
      prevState.params.farmCode !== this.state.params.farmCode ||
      prevState.params.projCode !== this.state.params.projCode ||
      prevState.params.division !== this.state.params.division
    ) {
      const flag =
        _.isEmpty(this.state.params.farmCode) &&
        _.isEmpty(this.state.params.projCode) &&
        _.isEmpty(this.state.params.division);
      console.log(flag, this.state.params);
      this.setState(
        produce((draft) => {
          draft.disables['checkBox'] = flag;
          draft.disables['submit'] = flag;
        }),
        function () {}
      );
    }
  }

  onBtSearch = () => {
    const { params } = this.state;
    this.props.onSearch(ObjectUtil.getClearnedObject(params));
  };

  onChangeRangePicker = (value, dateString) => {
    const that = this;
    this.setState(
      produce((draft) => {
        draft.params['startDate'] = dateString[0];
        draft.params['endDate'] = dateString[1];
      }),
      function () {}
    );
  };

  onChangeRadio = (e) => {
    const that = this;
    const { name, value } = e.target;
    this.setState(
      produce((draft) => {
        draft.params['type'] = value;
        draft.params['farmCode'] = undefined; // area, farm 변경시마다 초기화 되어야 합니다.
        draft.params['division'] = undefined;
        draft.params['region'] = undefined;
        draft.params['projCode'] = undefined;
      }),
      function () {
        const { params } = this.state;
        console.log(params);
      }
    );
  };

  onChangeCheckbox = (values) => {
    const that = this;
    // const { name, value } = e.target;
    this.setState(
      produce((draft) => {
        draft.params['checkBox'] = values;
      }),
      function () {
        const { params } = this.state;
        console.log(params);
      }
    );
  };

  setCode = (name, value) => {
    this.setState(
      produce((draft) => {
        draft.params[name] = value;
      }),
      function () {}
    );
  };

  render() {
    const { groupId, userLevel } = this.state;
    const { isAreaManager, isDivisionManager } = this.props.mainStore;
    return (
      <React.Fragment>
        <div className="f_left langsel">
          {/* Division,Region,Plazma 선택 */}
          {
            /* isAreaManager() && ( */
            <Radio.Group
              name="type" // area, farm
              value={this.state.params.type}
              onChange={this.onChangeRadio}
            >
              <Radio value={'area'}>
                <Trans>Area</Trans>
              </Radio>
              {/* 농장은 선택하지 않도록 요청. by 김기철 2020.09.29
               * <Radio value={'farm'}>
               * <Trans>Farm</Trans>
               *</Radio>
               */}
              <Radio value={'project'}>
                <Trans>Project</Trans>
              </Radio>
            </Radio.Group>
            /*)*/
          }

          {this.state.params.type === 'area' && (
            <React.Fragment>
              {
                /* isDivisionManager() && ( */
                <SearchInput
                  placeholder={i18n.t('divisionCode')}
                  style={{
                    width: 200,
                  }}
                  inputParam="division" // SelectInput의 분류
                  inputName="division" // member variable of this.state.params
                  inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
                  // farmClass="A"
                  defaultValue={this.state.params.division}
                  getValue={(value) => {
                    this.setCode('division', value);
                    this.setCode('region', undefined);
                  }} //***** */
                  that={this} //***** */
                />
                /*)*/
              }
              <SearchInput
                placeholder="regionCode"
                style={{
                  width: 200,
                  marginRight: 20,
                }}
                inputParam="region" // SelectInput의 분류
                inputName="region" // member variable of this.state.params
                inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
                // farmClass="A"
                pValue0={this.state.params.division} // division이 먼저 선택되어 있어야 활성화
                defaultValue={this.state.params.region}
                getValue={(value) => {
                  this.setCode('region', value);
                }} //***** */
                that={this} //***** */
              />
            </React.Fragment>
          )}

          {this.state.params.type === 'farm' && (
            <SearchInput
              placeholder={i18n.t('farmCode')}
              style={{
                width: 200,
                marginRight: 20,
                // textTransform: 'uppercase'
              }}
              inputParam="farmCode" // SelectInput의 분류
              inputName="farmCode" // member variable of this.state.params
              inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
              // farmClass="A"
              defaultValue={this.state.params.farmCode}
              getValue={(value) => this.setCode('farmCode', value)} //***** */
              that={this} //***** */
            />
          )}
          {this.state.params.type === 'project' && (
            <SearchInput
              placeholder={i18n.t('projectCode')}
              style={{
                width: 200,
                marginRight: 20,
                // textTransform: 'uppercase'
              }}
              inputParam="projectCode" // SelectInput의 분류
              inputName="projectCode" // member variable of this.state.params
              inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
              // farmClass="A"
              defaultValue={this.state.params.projCode}
              getValue={(value) => this.setCode('projCode', value)} //***** */
              that={this} //***** */
            />
          )}
        </div>

        <div className="f_left mT10 mB10 langsel both">
          <Checkbox.Group
            disabled={this.state.disables.checkBox}
            onChange={this.onChangeCheckbox}
            value={this.state.params.checkBox}
          >
            <Row>
              <Checkbox value="depAct">Dep.Act</Checkbox>
              <Checkbox value="depStd">Dep.Std</Checkbox>
              <Checkbox value="pfAct">PF Act</Checkbox>
              <Checkbox value="pfStd">PF Std</Checkbox>
              <Checkbox value="bwAct">BW Act</Checkbox>
              <Checkbox value="bwStd">BW Std</Checkbox>
              <Checkbox value="fcrAct">FCR Act</Checkbox>
              <Checkbox value="fcrStd">FCR Std</Checkbox>
              <Checkbox value="ipAct">IP Act</Checkbox>
              <Checkbox value="ipStd">IP Std</Checkbox>
            </Row>
          </Checkbox.Group>
        </div>
        <div className="f_left mT0 mB10 langsel">
          <button
            className={this.state.disables.submit ? 'btn_gray' : 'btn_green'}
            disabled={this.state.disables.submit}
            onClick={this.onBtSearch}
          >
            <Trans>Search</Trans>
          </button>
        </div>
      </React.Fragment>
    );
  }
}
