import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil, PopSearchCust } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { Input, Select, Radio } from 'antd';
import NumberFormat from 'react-number-format';
import { useAlert } from 'react-alert';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';
const { Search } = Input;

/*
 ** Breeding > Deliver > PS Sales - PsSalesForm
 ** =============================================
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class PsSalesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salesListView: true,
      epDate: DateUtil.today(),
      form: [],
      customerList: [],
      customerCodeFocus: false,
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    //필수 항목 setFocus를 위한 정의
    this.transferDate = React.createRef();
    this.customerCode = React.createRef();
    this.cullingRelease = React.createRef();
    this.closingRelease = React.createRef();
    this.selectCustomerList("'11','12','13','14'");
  }
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps.salesForm !== this.props.salesForm) {
  //     this.setState({ form: nextProps.salesForm }, function() {});
  //   }
  // }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.salesFormView !== nextProps.salesFormView) {
      console.log('getDerivedStateFromProps', prevState, nextProps);
      return {
        salesFormView: nextProps.salesFormView,
        form: nextProps.salesForm,
      };
    }
    return null;
  }
  //거래처 찾기
  selectCustomerList = (value) => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.customerName = '';
    data.customerKind = value;
    //customerKind="'11','12','13','14'"
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/selectCustomerList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ customerList: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };
  //Sales 저장
  fnSave = () => {
    let that = this;
    let data = this.state.form;
    console.log('fnSave', data);
    let cullingQty = Number(data.cullingQty ? data.cullingQty : '0');
    let cullingWeight = Number(data.cullingWeight ? data.cullingWeight : '0');
    let closingQty = Number(data.closingQty ? data.closingQty : '0');
    let closingWeight = Number(data.closingWeight ? data.closingWeight : '0');

    if (!data.transferDate) {
      that.props.alert.show(i18n.t('Please enter Transfer Date.'));
      this.transferDate.focus();
      return false;
    }

    if (!data.customerCode) {
      that.props.alert.show(i18n.t('Please enter CustomerCode.'));
      this.customerName.focus();
      return false;
    }

    if (cullingQty === 0 && closingQty === 0) {
      this.props.alert.show(i18n.t('No data entered.'));
    }

    data.cullingQty = cullingQty;
    data.cullingWeight = cullingWeight;
    data.closingQty = closingQty;
    data.closingWeight = closingWeight;

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    if (data.iuFlag === 'I') {
      //Type
    }

    console.log('save', data);
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/savePsSales.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.fnInit();
      })
      .catch(function (error) {
        return [];
      });
  };
  //Write Qty & Press Send
  fnSend = () => {
    let that = this;
    let cullingRelease = Number(this.state.form.cullingRelease);
    let closingRelease = Number(this.state.form.closingRelease);

    let cullingStock = Number(this.state.form.cullingStock); //재고
    let closingStock = Number(this.state.form.closingStock); //재고

    if (cullingRelease > cullingStock) {
      that.props.alert.show(i18n.t('The quantity is exceeded.'));
      this.cullingRelease.focus(); //NumberFormat은 focus만
      return false;
    }
    if (closingRelease > closingStock) {
      that.props.alert.show(i18n.t('The quantity is exceeded.'));
      this.closingRelease.focus(); //NumberFormat은 focus만
      return false;
    }

    this.setState(
      {
        form: {
          ...this.state.form,
          cullingQty: Number(this.state.form.cullingRelease),
          closingQty: Number(this.state.form.closingRelease),
        },
      },
      function () {}
    );
  };

  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  setOnChange = (e, params) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    that.setState({ form: { ...this.state.form, [col]: val } }, function () {
      if (params.type === 'N') {
        let closingStock = Number(
          that.state.form[col.replace('Release', 'Stock')]
        );
        if (Number(val) > closingStock) {
          that.setState({
            form: {
              ...that.state.form,
              [col]: 0,
              [col.replace('Release', 'Qty')]: 0,
            },
          });
          //useAlert.error('You just broke something!');
          that.props.alert.show(i18n.t('The quantity is exceeded.'));
          return false;
        }
        that.setState({
          form: {
            ...that.state.form,
            [col.replace('Release', 'Qty')]: Number(val),
          },
        });
      }
    });
  };

  //거래처 찾기
  handleSearch = (value) => {};
  handleChange = (value) => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmNmNo();
    data.dateFormat = SessionUtil.getDateFormat();

    data.customerName = value;
    console.log('save', data);
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/selectCustomerList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('savePsSales', response.data);
        const children = [];

        response.data.rows1.forEach((row) => {
          children.push({
            value: row.customerCode,
            text: row.customerName,
          });
        });
        const options = response.data.rows1.map((d) => (
          <Option key={d.value}>{d.text}</Option>
        ));

        that.setState(
          {
            customerList: options,
          },
          function () {
            console.log('savePsSales', that.state);
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  setOnChangeSelect = (value, col) => {
    this.setState({ form: { ...this.state.form, [col]: value } }, function () {
      console.log('%%%%%%', this.state);
    });
  };
  onChangeTransferType = (e, col) => {
    this.setState(
      { form: { ...this.state.form, [col]: e.target.value } },
      function () {
        console.log('onChangeTranferType', this.state);
      }
    );
  };
  fnSearchCust = (value) => {
    this.setState(
      {
        popSearchCustView: true,
        customerCode: value,
        customerKind: [
          '11',
          '12',
          '13',
          '14',
          '21',
          '31',
          '41',
          '51',
          '98',
          '99',
        ],
      },
      function () {}
    );
  };
  fnSelectCust = (data) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          customerCode: data.customerCode,
          customerName: data.customerName,
        },
      },
      function () {}
    );
  };
  fnClosePop = (data) => {
    this.setState({ [data]: false }, function () {});
  };
  setOnChangeCust = (e) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          customerCode: e.target.value,
          customerName: '',
        },
      },
      function () {}
    );
  };

  fnCancel = () => {
    this.setState(
      {
        form: this.props.salesForm,
      },
      function () {}
    );
  };

  render() {
    if (!this.props.salesFormView) return null;
    const data = this.state.form;
    //console.log('=========>', data);
    const Option = Select.Option;
    const customerOptions = this.state.customerList.map((d, i) => (
      <Option key={i} value={d.customerCode}>
        {d.customerName}
      </Option>
    ));
    return (
      <Fragment>
        {/* <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            margin: 'auto',
            zIndex: 1000,
            backgroundColor: 'rgba(0,0,0, 0.5)'
          }}
        >
          <div
            style={{
              position: 'absolute',
              width: '900px',
              height: '300px',
              left: '15%',
              right: '15%',
              top: '50px',
              //bottom: '15%',
              margin: 'auto',
              borderRadius: '10px',
              background: 'white',
              scrollBehavior: 'smooth'
            }}
          > */}
        {/* 디자인 영역 */}
        <h3>
          <Trans>Sales</Trans>
        </h3>

        <div className="board_list2">
          <table>
            <colgroup>
              <col style={{ width: '20%' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '20%' }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <Trans>Date</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'transferDate', //*** */
                    type: 'D', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    placeholder: 'transferDate',
                  })}
                </td>
                <th>
                  <Trans>Sales Order No</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'sOrderNo', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 180, // px제외 : 없으면 100% */
                    maxLength: 20,
                    //placeholder: 'Date',
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Customer</Trans>
                </th>
                <td>
                  <Search
                    placeholder="Customer Code"
                    onSearch={(value) => this.fnSearchCust(value)}
                    enterButton
                    className="w100"
                    //style={{ width: '300px' }}
                    ref={(input) => {
                      this['customerCode'] = input;
                    }}
                    value={this.state.form.customerCode}
                    onChange={(value) => this.setOnChangeCust(value)}
                  />
                </td>
                <th>
                  <Trans>Truck No.</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'truckNo', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    maxLength: 10,
                    //placeholder: 'Date',
                  })}
                </td>
              </tr>
              <tr>
                <th>
                  <Trans>Type</Trans>
                </th>
                <td className="spac50_right">
                  <Radio.Group
                    onChange={(e) =>
                      this.onChangeTransferType(e, 'transferType')
                    }
                    value={this.state.form.transferType}
                  >
                    <Radio value={'S'}>Sales</Radio>
                    <Radio value={'D'}>Donation</Radio>
                  </Radio.Group>
                </td>
                <th>
                  <Trans>Remarks</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'remarks', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    //width: 120, // px제외 : 없으면 100% */
                    maxLength: 100,
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="board_list mT20 mB20">
          <table>
            <colgroup>
              <col style={{ width: '16%' }} />
              <col style={{ width: '16%' }} />
              <col style={{ width: '18%' }} />
              <col style={{ width: '16%' }} />
              <col style={{ width: '16%' }} />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th colSpan="3">
                  <Trans>Closing</Trans>
                </th>
                <th colSpan="3">
                  <Trans>Culling</Trans>
                </th>
              </tr>
              <tr>
                <th>
                  <Trans>Salable Qty</Trans>
                </th>
                <th>
                  <Trans>Sales Qty</Trans>
                </th>
                <th>
                  <Trans>Weight(Kg)</Trans>
                </th>
                <th>
                  <Trans>Salable Qty</Trans>
                </th>
                <th>
                  <Trans>Sales Qty</Trans>
                </th>
                <th>
                  <Trans>Weight(Kg)</Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span>
                    {data.closingStock
                      ? data.closingStock.toLocaleString()
                      : '0'}
                  </span>
                </td>
                <td>
                  {data.closingStock
                    ? GridUtil.renderInput({
                        inputName: 'closingRelease', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        //width: 70, // px제외 : 없으면 100% */
                        //dec: 2,
                        //maxLength: 6,
                        //maxVal: 999.99,
                        minVal: 0,
                      })
                    : null}
                </td>
                <td>
                  {Number(data.closingStock) !== 0
                    ? GridUtil.renderInput({
                        inputName: 'closingWeight', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        dec: 1,
                        setOnChange: this.setOnChange, //*** */
                        //width: 70, // px제외 : 없으면 100% */
                        //dec: 2,
                        //maxLength: 6,
                        //maxVal: 999.99,
                        minVal: 0,
                      })
                    : null}
                </td>
                <td>
                  <span>
                    {data.cullingStock
                      ? data.cullingStock.toLocaleString()
                      : '0'}
                  </span>
                </td>
                <td>
                  {this.state.form.cullingStock
                    ? GridUtil.renderInput({
                        inputName: 'cullingRelease', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        //width: 70, // px제외 : 없으면 100% */
                        //dec: 2,
                        //maxLength: 6,
                        //maxVal: 999.99,
                        minVal: 0,
                      })
                    : null}
                </td>
                <td>
                  {Number(data.cullingQty) !== 0
                    ? GridUtil.renderInput({
                        inputName: 'cullingWeight', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        dec: 1,
                        setOnChange: this.setOnChange, //*** */
                        //width: 70, // px제외 : 없으면 100% */
                        //dec: 2,
                        //maxLength: 6,
                        //maxVal: 999.99,
                        minVal: 0,
                      })
                    : null}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="txt_center mT10 mB10">
          <button className="btn_gray" onClick={() => this.fnCancel()}>
            <Trans>Cancel</Trans>
          </button>
          <button className="btn_save" onClick={() => this.fnSave()}>
            <Trans>save</Trans>
          </button>
        </div>

        {this.state.popSearchCustView ? (
          <PopSearchCust
            popSearchCustView={this.state.popSearchCustView}
            customerCode={this.state.customerCode}
            customerKind={this.state.customerKind}
            fnClosePop={this.fnClosePop}
            fnSelectCust={this.fnSelectCust}
          />
        ) : null}
      </Fragment>
    );
  }
}
export default withAlert()(PsSalesForm);
