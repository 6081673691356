import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import '../Hatchery.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class ReceiveInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rcvDate: DateUtil.today()
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  render() {
    if (!this.props.receiveInfoFormView) return null;
    return (
      <Fragment>
        {/* 디자인 영역 */}

        <h3 className="mT40">
          <Trans>Receiving Form</Trans>
        </h3>
        <div className="board_list2">
          <table>
            <colgroup>
              <col style={{ width: '15%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '15%' }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <Trans>Date</Trans>
                </th>
                <td>
                  <span>{this.state.rcvDate}</span>
                </td>
                <th>
                  <Trans>Qty</Trans>
                </th>
                <td>
                  <span>{this.props.transferQty}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default ReceiveInfoForm;
