import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, FormUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import {
  AgGridReact,
  AllModules,
  isRowSelected
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import produce from 'immer';
import { Input } from 'antd';
import { withAlert } from 'react-alert';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class GroupRegist extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: 'New Group',
      regDate: DateUtil.toDateTimeString(),
      oldParams: {},
      params: {} // 등록시 전달할 데이타
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.rowNode !== this.props.rowNode) {
      // console.log( prevProps.rowNode, this.props.rowNode, _.isEmpty(this.props.rowNode));
      let title = 'Modify Group';
      if (_.isEmpty(this.props.rowNode)) title = 'New Group';
      let { rowNode } = this.props;
      console.log(rowNode);
      this.setState(
        produce(draft => {
          draft.title = title;
          draft.params['groupId'] = rowNode['userGroupId'];
          draft.params['groupNm'] = rowNode['userGroupName'];
          draft.oldParams = draft.params;
        }),
        function() {
          // console.log('changed params : ', this.state.params);
        }
      );
    }
  }

  fnRefreshRowData = () => {
    this.fnClearInput();
    this.props.onRowDeselect();
    this.props.fnSetRegDate();
  };

  // Group Id 존재유무 확인
  fnSaveRowData = (params, insertCallback, updateCallback) => {
    const that = this;
    let url = Server.getRestAPI() + '/users/checkGroup.do';
    return axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        const { data } = response;
        Number(data) > 0 ? updateCallback(params) : insertCallback(params);
        // handler(params).then(function(response) {
        //   that.fnRefreshRowData();
        // });
      })
      .catch(function(error) {
        return [];
      });
  };

  // 사용자 그룹정보를 생성/조회/폼리셋 합니다.
  fnInsertRowData = params => {
    const that = this;
    let url = Server.getRestAPI() + '/users/insertGroup.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    }).then(function(response) {
      that.fnRefreshRowData();
    });
  };

  fnUpdateRowData = params => {
    const that = this;
    const url = Server.getRestAPI() + '/users/updateGroup.do';
    // ---------------------------------------------------------
    // (중요) await와 then 은 함께 사용해서는 안됩니다.
    // ---------------------------------------------------------
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin'
    }).then(function(response) {
      that.fnRefreshRowData();
    });
  };

  fnClearInput = () => {
    this.setState(
      produce(draft => {
        draft.params['groupId'] = '';
        draft.params['groupNm'] = '';
      }),
      function() {}
    );
  };

  checkMandatoryFields = params => {
    let result = false;
    if (_.isEmpty(params.groupId)) {
      this.props.alert.error(i18n.t('groupId is empty'));
      return result;
    }
    if (_.isEmpty(params.groupNm)) {
      this.props.alert.error(i18n.t('groupNm is empty'));
      return result;
    }
    result = true;
    return result;
  };

  // 저장
  onBtSave = () => {
    const { params } = this.state;
    if (!this.checkMandatoryFields(params)) return;
    this.fnSaveRowData(params, this.fnInsertRowData, this.fnUpdateRowData);
  };

  onBtClear = () => {
    // this.fnClearInput();
    this.props.onRowDeselect();
  };

  onChangeInputText = e => {
    const { name, value } = e.target;
    this.setState(
      produce(draft => {
        draft.params[name] = value;
      })
    );
  };

  // 파라미터의 변경여부 확인
  fnNotChanged = () => {
    return _.isEqual(this.state.params, this.state.oldParams);
  };

  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <div className="con_box">
          <h3>
            <Trans>Group Info</Trans>
          </h3>

          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Group Id</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    <Input
                      type="text"
                      name="groupId"
                      value={this.state.params.groupId}
                      onChange={e => this.onChangeInputText(e)}
                      style={{ width: 250 }}
                    />
                  </td>
                  <th>
                    <Trans>Group Name</Trans> <span className="red">*</span>
                  </th>
                  <td>
                    <Input
                      type="text"
                      name="groupNm"
                      value={this.state.params.groupNm}
                      onChange={e => this.onChangeInputText(e)}
                      style={{ width: 250 }}
                      placeholder=""
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="txt_center mT40">
            <button className="btn_gray" onClick={this.onBtClear}>
              <Trans>Clear</Trans>
            </button>
            <button
              className={this.fnNotChanged() ? 'btn_gray' : 'btn_save'}
              onClick={this.onBtSave}
              disabled={this.fnNotChanged()}
            >
              <Trans>Save</Trans>
            </button>
          </div>
        </div>
        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}

export default withAlert()(GroupRegist);
