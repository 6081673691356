import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import {
  Popconfirm,
  Switch,
  message,
  DatePicker,
  Modal,
  Row,
  Input,
} from 'antd';
import PopStatus from './PopStatus';
import '../Breeding.module.css';
/*
 ** Breeding/Glowing Log/FlockList
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class FlockList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      prop: {},
      rowData: [],
      flockListView: true,
      visible: false,
      popStatusView: false,
      selectFlockId: '',
      row: [],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    //this.fnSearch();
  }

  fnClickFlockClose = (row) => {
    let vi = row.flockId + 'View';
    this.setState({ [vi]: true, selectFlockId: row.flockId, rowData: row });
  };
  //
  confirm = () => {
    //this.setState({ visible: false });
    let data = this.state.rowData;
    let that = this;
    let vi = data.flockId + 'View';
    let hhList = [];

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.closingSdate = data.closingSdate
      ? data.closingSdate
      : DateUtil.today();
    data.iuFlag = 'U';
    data.flockStatus = 'S'; //C:closed - S:closing

    that.props.hhChickinList.map(function (rowNode, index) {
      console.log('node ', rowNode);
      if (data.flockId === rowNode.flockId) {
        let flockInQtyF = rowNode.flockInQtyF ? Number(rowNode.flockInQtyF) : 0;
        let flockInQtyM = rowNode.flockInQtyM ? Number(rowNode.flockInQtyM) : 0;
        let dataDef = {};
        dataDef.farmCode = rowNode.farmCode;
        dataDef.flockId = rowNode.flockId;
        dataDef.hhId = rowNode.hhId;
        dataDef.chickoutQtyF = flockInQtyF;
        dataDef.chickoutQtyM = flockInQtyM;

        hhList.push(dataDef);
      }
    });
    data.hhList = hhList;
    data.hhListLength = hhList.length;

    console.log('saveFlockClose', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/growing/saveFlockClose.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('saveChickMoveHh', response.data);

        //that.props.fnSearch();
        that.setState({ [vi]: false, selectFlockId: '', rowData: [] });
        that.props.fnSearch();
      })
      .catch(function (error) {
        return [];
      });
  };
  cancel = () => {
    let vi = this.state.selectFlockId + 'View';

    this.setState({ [vi]: false, selectFlockId: '' });
  };
  fnClickChangeStatus = (row) => {
    row.layingSdate = row.layingSdate ? row.layingSdate : DateUtil.today();
    this.setState({ popStatusView: true, rowData: row });
  };
  handleOk = (e) => {
    this.setState({
      popStatusView: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      popStatusView: false,
    });
  };
  statusButton = (row) => {
    if (row.breedStatus === 'Growing' && Number(row.weeks) > 3) {
      let vi = row.flockId + 'View';
      return (
        <span
          className="btn_green btn_flock"
          onClick={(e) => this.fnClickChangeStatus(row)}
        >
          <Trans>변경</Trans>
        </span>
      );
    } else return null;
  };
  flockButton = (row) => {
    if (row.flockStatus === 'S') {
      //if (row.weeks > 0) { 2020-03-23
      let vi = row.flockId + 'View';
      return (
        <Popconfirm
          title={i18n.t('Are you sure you want to destroy?')}
          visible={this.state[vi]}
          onVisibleChange={this.handleVisibleChange}
          onConfirm={this.confirm}
          onCancel={this.cancel}
          okText="OK"
          cancelText="Cancel"
        >
          <dd>
            <button
              className="btn_orange"
              onClick={(e) => this.fnClickFlockClose(row)}
            >
              <Trans>Flock Close</Trans>
            </button>
          </dd>
        </Popconfirm>
      );
    } else return;
  };
  render() {
    let flockList = [];
    if (this.props.flockList && this.props.flockList.length > 0) {
      flockList = this.props.flockList.map((row, i) => {
        let msg = '';
        let feedMsg = '';
        let vaccineMsg = '';
        let medicineMsg = '';
        for (var ii = 1; ii < 4; ii++) {
          feedMsg += row['tfFeedCode' + ii]
            ? ' Feed(F) : ' + row['tfFeedCode' + ii] + ' \n'
            : '';
          feedMsg += row['tmFeedCode' + ii]
            ? ' Feed(M) : ' + row['tmFeedCode' + ii] + ' \n'
            : '';
          vaccineMsg += row['tfVaccineCode' + ii]
            ? ' Vaccine(F) :' + row['tfVaccineCode' + ii] + ' \n'
            : '';
          vaccineMsg += row['tmVaccineCode' + ii]
            ? ' Vaccine(M) :' + row['tmVaccineCode' + ii] + ' \n'
            : '';
          medicineMsg += row['tfMedicineCode' + ii]
            ? ' Medicine(F) : ' + row['tfMedicineCode' + ii] + ' \n'
            : '';
          medicineMsg += row['tmMedicineCode' + ii]
            ? ' Medicine(M) : ' + row['tmMedicineCode' + ii] + ' \n'
            : '';
        }
        msg = feedMsg + vaccineMsg + medicineMsg;

        return (
          <div key={i} className="full">
            <dl className="reinfo">
              <dt className="color1">{row.flockId}</dt>
              <dd className="f_left mL10">{row.strainName}</dd>
              <dd className="f_right mR10">{row.flockCdate}</dd>
              <dd>&nbsp; </dd>
              <dd className="txt_left mL10">
                <Trans>Age</Trans>
                {' : '}
                {row.days ? row.days.toLocaleString() : '0'}
                <Trans> Days</Trans>
                {' / '}
                {row.weeks ? row.weeks.toLocaleString() : '0'}
                <Trans> Weeks</Trans>
              </dd>
              <dd className="txt_left mL10">
                <Trans>Status : {row.breedStatus ? row.breedStatus : ''}</Trans>
                {this.statusButton(row)}
              </dd>
              <dd className="txt_left mL10">
                <Trans>Birds</Trans>
                {' ('}
                <Trans>Current</Trans> / <Trans>Chick-In</Trans> ){' '}
              </dd>
              <dd className="txt_left mL10">
                <Trans>F</Trans> :{' '}
                {row.chickinQtyF ? row.chickinQtyF.toLocaleString() : '0'} /{' '}
                {row.flockInQtyF ? row.flockInQtyF.toLocaleString() : '0'}
              </dd>
              <dd className="txt_left mL10">
                <Trans>M</Trans> :{' '}
                {row.chickinQtyM ? row.chickinQtyM.toLocaleString() : '0'} /{' '}
                {row.flockInQtyM ? row.flockInQtyM.toLocaleString() : '0'}
              </dd>
              {/* 툴팁 class egg_tooltip */}
              {msg ? (
                <dd className="egg_tooltip">
                  <i className="mdi mdi-tooltip-text"></i>
                  <div className="egg_tooltiptext">{msg}</div>
                </dd>
              ) : null}

              {this.flockButton(row)}
            </dl>
          </div>
        );
      });
    }
    return (
      <Fragment>
        {/* Flock */}
        <li className="divide_left">
          <div className="dititle">
            <i className="mdi mdi-cube-outline"></i> <Trans>Flock</Trans>
          </div>
          {flockList}
          {/* left list */}
        </li>

        <PopStatus
          popStatusView={this.state.popStatusView}
          popMoveRow={this.state.rowData}
          fnSearch={this.props.fnSearch}
          handleCancel={this.handleCancel}
        />
      </Fragment>
    );
  }
}
export default FlockList;
