import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import EggLayout from 'layout/Layout';

import { BreedingRouteList } from 'route';
import { EggError } from 'containers/pages/common';
import { Navs } from 'components/';

import { SessionUtil } from 'utils';
import { Const, Server } from 'components/Properties';
import { Login, Logout } from 'containers/pages/common/login';

import '../App.css';

const BreedingRoute = props => {
  const getRouteInfo = (cate, id) => {
    const pathname = window.location.pathname;
    return BreedingRouteList.find(page => page.uri === pathname);
  };

  // 미인증 사용자 접근 금지 기능
  if (!SessionUtil.isLogined())
    return <Redirect to={Const.AUTO_LOGOUT_PAGE} />;

  const url = props.match.url;
  const cate = props.match.params.cate;
  const id = props.match.params.id;
  const { page } = getRouteInfo(cate, id);
  return (
    <EggLayout {...props}>
      <Route path={url} component={page ? page : Navs} />
    </EggLayout>
  );
};

export default withRouter(BreedingRoute);
