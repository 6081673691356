import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import { Radio } from 'antd';
import produce from 'immer';

import BroilerHhList from './BroilerHhList';
import BroilerHhRegist from './BroilerHhRegist';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class BroilerHhInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      regDate: DateUtil.toDateTimeString()
    };
  }

  fnSetEditing = data => {
    console.log('setEditing:', data);
    this.setState({
      editing: data
    });
  };

  fnSetRegDate = () => {
    this.setState({
      regDate: DateUtil.toDateTimeString()
    });
  };

  onBtRegister = () => {
    this.setState({ editing: true });
  };

  render() {
    return (
      <Fragment>
        <BroilerHhList
          setRegDate={this.fnSetRegDate}
          regDate={this.state.regDate}
          setEditing={this.fnSetEditing}
          editing={this.state.editing}
        />
        {this.state.editing && (
          <BroilerHhRegist
            setRegDate={this.fnSetRegDate}
            setEditing={this.fnSetEditing}
          />
        )}
      </Fragment>
    );
  }
}
