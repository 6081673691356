import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { Const, Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import { moment } from 'components';
import i18n from 'i18next';
import '../Breeding.module.css';
/*
 ** breeding > receive > Hatchery , Suppiler > BreedRecvList
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class BreedRecvList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      breedRecvListView: false,
      searchFromWkDt: moment(DateUtil.today())
        .add('-1', 'M')
        .format('YYYY-MM-DD'),
      searchToWkDt: DateUtil.today(),
      columnDefs: [
        {
          headerName: 'Receive No',
          field: 'docReceiveNo',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Supplier',
          field: 'customerName',
          width: 300,
          cellStyle: { textAlign: 'left' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Recv Date',
          field: 'receiveDate',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Strain',
          field: 'strainName',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Female',
          field: 'totalRecvQtyF',
          width: 100,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          //precision: 0,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Male',
          field: 'totalRecvQtyM',
          width: 100,
          type: 'numericColumn',
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          //precision: 0,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Flock',
          field: 'flockId',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Remarks',
          field: 'remarks',
          width: 300,
          cellStyle: { textAlign: 'left' },
          suppressSizeToFit: false,
        },
      ],
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        suppressSizeToFit: false,
      },
      paginationPageSize: 15,
    };
  }
  //componentWillMount() {}
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    let that = this;
    this.setState(
      {
        searchFromWkDt: moment(DateUtil.today())
          .add('-1', 'M')
          .format('YYYY-MM-DD'),
        searchToWkDt: DateUtil.today(),
      },
      function () {
        that.fnSearch();
      }
    );
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (prevState.breedRecvListView !== nextProps.breedRecvListView) {
  //     return {
  //       breedRecvListView: nextProps.breedRecvListView
  //     };
  //   }
  //   return null;
  // }
  fnSearch = () => {
    let that = this;
    let data = {};
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.customer = this.props.customer; //*************** */
    data.flockYn = this.props.flockYn; //Flock Mapping 된 Receive 건은 나오지 않습니다. Receive 된 건은 무조건 다 나오게 해주세요.

    data.searchFromWkDt = this.state.searchFromWkDt;
    data.searchToWkDt = this.state.searchToWkDt;
    data.sql = 'selectTpBreedRecv';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/selectSql.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ rowData: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };
  onRowClicked = (e) => {
    this.props.fnSelectBreedReceive
      ? this.props.fnSelectBreedReceive(e.data)
      : null;
  };
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  onCellClicked = (e) => {
    if (e.column.colId === 'ck') {
      //   //선택버튼 클릭
      this.props.onCellClicked
        ? this.props.onCellClicked(e)
        : this.props.fnSelectBreedReceive
        ? this.props.fnSelectBreedReceive(e.data)
        : null;
    }
  };
  setOnChange = (e, params, dateString) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';

    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }
    this.setState({ [col]: val }, function () {});
  };
  render() {
    //if (!this.props.breedRecvListView) return null;
    const title = this.props.title ? this.props.title : 'Receive List';
    return (
      <Fragment>
        <h3>{title}</h3>
        <div className="f_right">
          <label htmlFor="">
            <Trans>Date</Trans>
          </label>
          {GridUtil.renderInput({
            inputName: 'searchFromWkDt', //*** */
            type: 'D', //*** */
            that: this, //*** */
            statsForm: this.state, //*** */
            setOnChange: this.setOnChange, //*** */
            width: 120, // px제외 : 없으면 100% */
            placeholder: 'Date',
          })}
          &nbsp; ~ &nbsp;
          {GridUtil.renderInput({
            inputName: 'searchToWkDt', //*** */
            type: 'D', //*** */
            that: this, //*** */
            statsForm: this.state, //*** */
            setOnChange: this.setOnChange, //*** */
            width: 120, // px제외 : 없으면 100% */
            placeholder: 'Date',
          })}
          <button className="btn_gray" onClick={() => this.fnSearch()}>
            <Trans>Search</Trans>
          </button>
        </div>
        <div
          style={{
            height: this.props.height ? this.props.height : '210px',
            width: '100%',
          }}
          className="board_list ag-theme-balham mB40"
        >
          <AgGridReact
            onGridReady={this.onGridReady}
            columnDefs={
              this.props.columnDefs
                ? this.props.columnDefs
                : this.state.columnDefs
            }
            rowData={this.state.rowData}
            defaultColDef={this.state.defaultColDef}
            onRowClicked={(e) => this.onRowClicked(e)}
            onCellClicked={(e) => this.onCellClicked(e)}
            rowSelection={'single'}
            onCellFocused={(e) => {
              e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
            }}
          ></AgGridReact>
        </div>
      </Fragment>
    );
  }
}
export default BreedRecvList;
