import React, { Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import i18n from 'i18next';
import GrowingForm from './GrowingForm';
import { withAlert } from 'react-alert';
import scrollToComponent from 'react-scroll-to-component';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
  GridOptions,
} from 'components/commons/ag-grid/AgGridUtil';
import { Select } from 'antd';
import '../Broiler.module.css';

/*
 ** Broiler > Glowing Log
 ** =======================
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class GrowLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInstance: this,
      workDate: DateUtil.today(),
      form: {
        searchFromWkDt: moment(DateUtil.today())
          .add('-1', 'M')
          .format('YYYY-MM-DD'),
        searchToWkDt: DateUtil.today(),
        projStatus: 'A',
      },
      selectedPLIdx: 0,
      prop: {},
      rowData: [],
      flockList: [],
      projectList: [],
      growingLogList: [],
      growingForm: [],
      growingView: false,
      openDatePicker: false,
      growingData: {},
      columnDefsProjectInfo: [
        {
          headerName: 'Project',
          field: 'projCode',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Name',
          field: 'projName',
          width: 300,
          cellStyle: { textAlign: 'left' },
          suppressSizeToFit: false,
        },
        {
          headerName: 'Strain',
          field: 'strainName',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Class',
          field: 'projClassName',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Start Date',
          field: 'startDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Status',
          field: 'projStatusName',
          width: 80,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Remarks',
          field: 'remarks',
          width: 300,
          cellStyle: { textAlign: 'left' },
          suppressSizeToFit: false,
        },
      ],
      columnDefsGrowingInfo: [
        {
          headerName: '',
          field: 'iuFlag',
          width: 110,
          cellStyle: { textAlign: 'center' },
          cellRenderer: (params) => {
            if (
              params.data.iuFlag === 'I' &&
              params.data.workDate != this.state.startDate
            )
              return (
                '<button class="btn_sel">' +
                i18n.t('Registration') +
                '</button>'
              );
            else if (params.data.iuFlag === 'U')
              return (
                '<button class="btn_sel_green">' +
                i18n.t('modify') +
                '</button>'
              );
            else return null;
          },

          suppressSizeToFit: true,
        },
        {
          headerName: 'Work Date',
          field: 'wdate',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Age',
          field: 'ageDay',
          width: 60,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Pop.',
          field: 'population',
          width: 60,
          cellStyle: { textAlign: 'right' },
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Death',
          field: 'deathQty',
          width: 70,
          cellStyle: { textAlign: 'right' },
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Culling',
          children: [
            {
              headerName: 'Ompal',
              field: 'ompalQty',
              width: 80,
              cellStyle: { textAlign: 'right' },
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              suppressSizeToFit: true,
            },
            {
              headerName: 'Weak',
              field: 'weakQty',
              width: 80,
              cellStyle: { textAlign: 'right' },
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              suppressSizeToFit: true,
            },
            {
              headerName: 'Abnormal',
              field: 'abnormalQty',
              width: 85,
              cellStyle: { textAlign: 'right' },
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              suppressSizeToFit: true,
            },
            {
              headerName: 'Claim',
              field: 'claimQty',
              width: 80,
              cellStyle: { textAlign: 'right' },
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              suppressSizeToFit: true,
            },
            {
              headerName: 'Dry leg',
              field: 'dryLegQty',
              width: 80,
              cellStyle: { textAlign: 'right' },
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              suppressSizeToFit: true,
            },
          ],
        },
        {
          headerName: 'Feed',
          field: 'feed',
          width: 100,
          dec: 2,
          cellStyle: { textAlign: 'right' },
          valueFormatter: function (params) {
            return GridUtil.formatNumberDec(params);
          },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Culling Sale',
          children: [
            {
              headerName: 'Qty',
              field: 'cullingSale',
              width: 80,
              cellStyle: { textAlign: 'right' },
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              suppressSizeToFit: true,
            },
            {
              headerName: 'Kg',
              field: 'cullingWeight',
              width: 80,
              dec: 3,
              cellStyle: { textAlign: 'right' },
              valueFormatter: function (params) {
                return GridUtil.formatNumberDec(params);
              },
              suppressSizeToFit: true,
            },
          ],
        },
        {
          headerName: 'Harvest Sale',
          children: [
            {
              headerName: 'Qty',
              field: 'harvestSale',
              width: 80,
              cellStyle: { textAlign: 'right' },
              valueFormatter: function (params) {
                return GridUtil.formatNumber(params);
              },
              suppressSizeToFit: true,
            },
            {
              headerName: 'Kg',
              field: 'harvestWeight',
              width: 80,
              dec: 3,
              cellStyle: { textAlign: 'right' },
              valueFormatter: function (params) {
                return GridUtil.formatNumberDec(params);
              },
              suppressSizeToFit: true,
            },
          ],
        },
      ],
      defaultColDef: {
        editable: false,
        width: 100,
        filter: true,
        resizable: true,
        sortable: true,
        suppressSizeToFit: false,
      },
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnSearch();
  }

  fnSearch = () => {
    let data = this.state.form;
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.workDate = this.state.workDate;

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/broiler/selectProjectList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            growingLogList: [],
            projectList: response.data.rows1,
            startDate: response.data.startDate,
            growingView: false,
            growingForm: [],
          },
          function () {
            if (response.data.rows1.length > 0) {
              let rowIndexG1 = this.state.selectedPLIdx;

              let selectedRows = that['g1'].getRowNode(rowIndexG1);
              selectedRows.setSelected(true);
              this.fnSearchGrowingList(selectedRows.data);
            }
            //console.log(that.state);
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  fnClickDate = (e, val) => {
    this.setState(
      {
        workDate: DateUtil.toFormat(
          moment(this.state.workDate).add(val, 'days')
        ),
      },
      function () {
        this.fnSearch();
      }
    );
  };
  //Write button Click
  fnClickWrite = (row) => {
    let that = this;

    let data = Object.assign({}, row);

    row.iuFlag = 'I';
    data.iuFlag = 'I';
    data.workDate = this.state.workDate;
    this.setState(
      {
        growingView: true,
        growingForm: data,
      },
      function () {
        scrollToComponent(this['growingForm'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };

  fnClickCancel = (val) => {
    this.setState({ [val]: false }, function () {});
  };
  //Modify button Click
  fnClickModify = (row) => {
    let data = Object.assign({}, row);
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/broiler/selectTpBreedLog3.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        data = response.data.rows4[0];
        data.salesList = response.data.rows5; //2020-03-27

        data.iuFlag = row.iuFlag;
        data.prevPop = row.prevPop ? Number(row.prevPop) : 0;
        data.deathQty = data.deathQty ? Number(data.deathQty) : 0;
        data.cullingQty = data.cullingQty ? Number(data.cullingQty) : 0;
        data.transferQty = data.transferQty ? Number(data.transferQty) : 0;
        data.population =
          data.prevPop - data.deathQty - data.cullingQty - data.transferQty;

        response.data.rows1.map((row, i) => {
          if (row.seqNo) {
            data['seqNo' + (i + 1)] = row.seqNo;
            data['feedCode' + (i + 1)] = row.feedCode;
            data['feedName' + (i + 1)] = row.materialName;
            data['feedUnit' + (i + 1)] = row.unit ? row.unit : '';
            data['feedUseAmt' + (i + 1)] = row.useAmt;
            data['feedView' + (i + 1)] = true;
          }
        });
        response.data.rows2.map((row, i) => {
          if (row.seqNo) {
            data['seqNo' + (i + 1)] = row.seqNo;
            data['medicineCode' + (i + 1)] = row.medicineCode;
            data['medicineName' + (i + 1)] = row.materialName;
            data['medicineUnit' + (i + 1)] = row.unit ? row.unit : '';
            data['medicineUseAmt' + (i + 1)] = row.useAmt;
            data['medicineView' + (i + 1)] = true;
          }
        });
        response.data.rows3.map((row, i) => {
          if (row.seqNo) {
            data['seqNo' + (i + 1)] = row.seqNo;
            data['vaccineCode' + (i + 1)] = row.vaccineCode;
            data['vaccineName' + (i + 1)] = row.materialName;
            data['vaccineUnit' + (i + 1)] = row.unit ? row.unit : '';
            data['vaccineUseAmt' + (i + 1)] = row.useAmt;
            data['vaccineView' + (i + 1)] = true;
          }
        });
        that.setState(
          {
            growingView: true,
            growingForm: data,
          },
          function () {
            scrollToComponent(this['growingForm'], {
              offset: 0,
              align: 'top',
              duration: 1000,
            });
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  fnClickCancel = (row) => {
    if (row.iuFlag === 'I') {
      row.workDate = '';
      row.iuFlag = '';
    } else if (row.iuFlag === 'U') {
      row.iuFlag = '';
    }
    this.setState(
      {
        growingView: false,
        growingData: row,
      },
      function () {}
    );
  };
  fnCalendarChange = (value) => {
    this.setState(
      { workDate: moment(value).format('YYYY-MM-DD') },
      function () {}
    );
  };
  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  //Grid row 선택
  onCellClickedGrading = (e) => {
    console.log('e', e.data);

    if (e.column.colId !== 'iuFlag') return false;
    let that = this;
    const selectedRows = that['g2'].getRowNode(e.rowIndex);
    let data = Object.assign({}, e.data);

    data.workDate = e.data.wdate;
    data.projCode = e.data.projCode ? e.data.projCode : that.state.projCode;
    data.projName = e.data.projName ? e.data.projName : that.state.projName;
    data.startDate = e.data.startDate ? e.data.startDate : that.state.startDate;
    data.recvQty = e.data.recvQty ? e.data.recvQty : that.state.recvQty;

    this.setState({ growingView: false, growingForm: [] }, function () {
      console.log('wDate', e.data.wdate);
      console.log('startDate', this.state.startDate);
      if (data.iuFlag === 'U') {
        that.fnClickModify(data);
      } else if (
        (data.iuFlag === 'I' && data.chk === 1) ||
        e.data.wdate === that.state.startDate
      ) {
        that.setState(
          {
            growingView: true,
            growingForm: data,
          },
          function () {
            scrollToComponent(this['growingForm'], {
              offset: 0,
              align: 'top',
              duration: 1000,
            });
          }
        );
      } else {
        that.props.alert.show(
          i18n.t('Please enter previous growing log first.')
        );
      }
    });
  };
  onCellClickedProject = (e) => {
    let data = e.data;

    this.setState(
      {
        growingLogList: [],
        stock: 0,
        growingForm: [],
        projCode: data.projCode,
        projName: data.projName,
        startDate: data.startDate,
        recvQty: data.recvQty,
        growingView: false,
        selectedPLIdx: e.rowIndex,
      },
      function () {
        this.fnSearchGrowingList(data);
      }
    );
  };
  fnSearchGrowingList = (data) => {
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.countryCode = SessionUtil.getCountryCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.userId = SessionUtil.getUserId();

    console.log('data', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/broiler/selectGlowingLogList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('====>', response.data);
        that.setState(
          {
            growingLogList: response.data.rows1,
            stock: response.data.stock[0] ? response.data.stock[0].stock : 0,
            growingForm: [],
            projCode: data.projCode,
            projName: data.projName,
            startDate: data.startDate,
            growingView: false,
          },
          function () {
            let toDate = DateUtil.today();
            let fromDate = moment(DateUtil.today())
              .add('-7', 'days')
              .format('YYYY-MM-DD');
            let stock = Number(that.state.stock ? that.state.stock : 0);
            that.state.growingLogList.reverse().map(function (row, i) {
              //전일재고
              row.prevPop = stock;

              if (!row.workDate) {
                if (row.wdate >= fromDate && row.wdate <= toDate) {
                  row.iuFlag = 'I';
                }
                if (Const.TEST_MODE) row.iuFlag = 'I'; // 테스트 및 검수를 위해 입력날짜 제한을 해제 합니다.
              } else if (row.workDate) {
                if (row.wdate >= fromDate && row.wdate <= toDate) {
                  row.iuFlag = 'U';
                }
                if (Const.TEST_MODE) row.iuFlag = 'U'; // 테스트 및 검수를 위해 입력날짜 제한을 해제 합니다.
              }
              stock =
                stock -
                Number(row.deathQty) -
                Number(row.cullingQty) -
                Number(row.harvestSale);
              //Number(row.harvestSale) -
              //Number(row.cullingSale);
              row.population = stock;
            });
            var params = { force: true };
            that['g2'].refreshCells(params);
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  setOnChange = (e, params, dateString) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';

    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;

      // Status 변경에 따른 항목 자동 선택 부분에서 오류 때문에 임시로 Status변경시 첫번째 리스트 선택으로 변경
      this.setState({ selectedPLIdx: 0 }, function () {});
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }
    this.setState({ form: { ...that.state.form, [col]: val } }, function () {});
  };
  render() {
    const Option = Select.Option;
    let options = [
      <Option key="o" value="O">
        {'O(생성)'}
      </Option>,
      <Option key="a" value="A">
        {'A(육성 단계)'}
      </Option>,
      <Option key="c" value="C">
        {'C(출고 완료)'}
      </Option>,
    ];

    return (
      <Fragment>
        <h2>
          <Trans>Growing Log</Trans>
        </h2>

        <div className="con_box">
          <h3>
            <Trans>Project List</Trans>
          </h3>

          <div className="border txt_center">
            <label htmlFor="">
              <Trans>Status</Trans>
            </label>
            {GridUtil.renderInput({
              inputName: 'projStatus', //*** */
              type: 'SELECT', //*** */
              statsForm: this.state.form, //*** */
              setOnChange: this.setOnChange, //*** */
              that: this, //*** */
              options: options, //*** */ type:'SELECT' options*/
              //placeholder: 'Strain', //*** */
              width: 120, // px제외 : 없으면 30 */
            })}
            <label htmlFor="" className="mL20">
              <Trans>Date</Trans>
            </label>
            {GridUtil.renderInput({
              inputName: 'searchFromWkDt', //*** */
              type: 'D', //*** */
              that: this, //*** */
              statsForm: this.state.form, //*** */
              setOnChange: this.setOnChange, //*** */
              width: 120, // px제외 : 없으면 100% */
              placeholder: 'Date',
            })}
            &nbsp; ~ &nbsp;
            {GridUtil.renderInput({
              inputName: 'searchToWkDt', //*** */
              type: 'D', //*** */
              that: this, //*** */
              statsForm: this.state.form, //*** */
              setOnChange: this.setOnChange, //*** */
              width: 120, // px제외 : 없으면 100% */
              placeholder: 'Date',
            })}
            <button className="btn_gray" onClick={() => this.fnSearch()}>
              <Trans>Search</Trans>
            </button>
          </div>
          <div
            className="board_list ag-theme-balham mT10"
            style={{ height: '220px', width: '100%' }}
          >
            <AgGridReact
              onGridReady={(e) => this.onGridReady(e, 'g1')}
              columnDefs={this.state.columnDefsProjectInfo}
              rowData={
                this.state.projectList.length > 0 ? this.state.projectList : []
              }
              defaultColDef={this.state.defaultColDef}
              onCellClicked={(e) => this.onCellClickedProject(e)}
              rowSelection="single"
              onCellFocused={(e) => {
                e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
              }}
            ></AgGridReact>
          </div>

          <h3 className="mT20">
            <Trans>Growing List</Trans>
            <span className="mL20">
              {this.state.projCode
                ? this.state.projCode + '(' + this.state.projName + ')'
                : ''}
            </span>
          </h3>
          <div
            className="board_list ag-theme-balham"
            style={{ height: '300px', width: '100%' }}
          >
            <AgGridReact
              onGridReady={(e) => this.onGridReady(e, 'g2')}
              columnDefs={this.state.columnDefsGrowingInfo}
              rowData={
                this.state.growingLogList.length > 0
                  ? this.state.growingLogList
                  : []
              }
              defaultColDef={this.state.defaultColDef}
              onCellClicked={(e) => this.onCellClickedGrading(e, 'g2')}
              rowSelection="single"
              onCellFocused={(e) => {
                e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
              }}
            ></AgGridReact>
          </div>

          {this.state.growingView ? (
            <GrowingForm
              ref={(section) => {
                this.growingForm = section;
              }}
              growingView={this.state.growingView} //Hen House view 여부
              growingForm={this.state.growingForm}
              fnClickCancel={this.fnClickCancel}
              fnSearchGrowingList={this.fnSearchGrowingList}
              fnSearch={this.fnSearch}
            />
          ) : null}
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default withAlert()(GrowLog);
