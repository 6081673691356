import React, { Fragment } from 'react';
import { withAlert } from 'react-alert';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { Const, Server } from 'components/Properties';
import { Modal, Button, Select, DatePicker } from 'antd';
import axios from 'axios';
import qs from 'qs';
import i18n from 'i18next';

import { moment } from 'components';
import NumberFormat from 'react-number-format';

import '../Hatchery.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class MoveOutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rcvDate: DateUtil.today(),
      form: [],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    this.fnSearchHatchery();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let that = this;
    if (prevProps.rowData != this.props.rowData) {
      this.setState(
        {
          form: this.props.rowData,
        },
        function () {}
      );
    }
  }

  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  renderInput = (inputName, ty, dec, len) => {
    let cl = len ? 'input_type1 w' + len : 'input_type1';

    if (ty && ty === 'N') {
      cl = len ? 'input_base w' + len : 'input_base';
      return (
        <NumberFormat
          style={{ textAlign: 'right' }}
          className={cl}
          thousandSeparator={true}
          decimalScale={dec ? dec : 0}
          name={inputName}
          //ref={this[inputName]}
          getInputRef={(el) => (this[inputName] = el)}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : ''
          }
          onChange={(e) => this.setOnChange(e, ty)}
          disabled={true}
        />
      );
    } else if (ty && ty === 'T') {
      return (
        <TimePicker
          //defaultValue={moment('12:08', 'HH:mm')}
          className={cl}
          format={'HH:mm'}
          ref={this[inputName]}
          value={
            this.state.form[inputName]
              ? moment(this.state.form[inputName], 'HH:mm') || ''
              : null
          }
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
        />
      );
    } else if (ty && ty === 'S') {
      return (
        <input
          type="text"
          name={inputName}
          className={cl}
          ref={this[inputName]}
          value={this.state.rcvDate}
          disabled={true}
        />
      );
    } else {
      return (
        <input
          type="text"
          name={inputName}
          className={cl}
          ref={this[inputName]}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : ''
          }
          onChange={(e) => this.setOnChange(e)}
        />
      );
    }
  };

  setOnChange = (e, params, dateString) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';

    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    this.setState(
      {
        form: {
          ...this.state.form,
          [col]: val,
        },
      },
      function () {}
    );
  };

  fnSearchHatchery = () => {
    window.scrollTo(0, 0);

    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.hatchDate = DateUtil.today();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/move/selectHatcheryInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('selectGlowingLogList', response.data);
        if (response.data.rows1.length > 0) {
          that.setState(
            {
              hatcheryList: response.data.rows1,
            },
            function () {}
          );
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  setOnChangeSelect = (value, col) => {
    this.setState(
      { form: { ...this.state.form, [col]: value } },
      function () {}
    );
  };

  fnClickSaveBtn = (e) => {
    let that = this;
    let data = this.state.form;

    data.hatcheryId = SessionUtil.getHatcheryId();
    data.dateFormat = SessionUtil.getDateFormat();
    data.iuFlag = this.props.iuFlag;

    if (data.farmCode === 'undefined') {
      data.farmCode = '';
    }

    if (!data.recvHatcheryId) {
      that.props.alert.show(i18n.t('Please select Hatchery'));
      that.recvHatcheryId.focus();
      return false;
    }

    if (!data.moveOutDate) {
      data.moveOutDate = this.state.rcvDate;
    }

    if (data.receiveDate > data.moveOutDate) {
      that.props.alert.show(
        i18n.t('move out date must be later than receive date')
      );
      return false;
    }

    if (data.moveOutDate > this.state.rcvDate) {
      that.props.alert.show(i18n.t('move out date must be earlier than today'));
      return false;
    }

    console.log('data', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/move/saveMoveOutInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.alert.show(i18n.t('Saved'));
        that.fnClear();
        that.props.fnSetRegDate();
      })
      .catch(function (error) {
        that.props.alert.show(i18n.t('Save failed'));
        return [];
      });
  };

  fnClickCancel = (e) => {
    this.props.fnClickCancel();
    this.fnClear();
  };

  fnClear = (e) => {
    if (this.props.iuFlag === 'I') {
      this.setState(
        {
          form: {
            ...this.state.form,
            recvHatcheryId: '',
            moveOutDate: '',
            remarks: '',
          },
        },
        function () {}
      );
    }
    if (this.props.iuFlag === 'U') {
      this.setState(
        {
          form: this.props.rowData,
        },
        function () {}
      );
    }
  };

  onDateChange = (date, dateString, name) => {
    this.setState(
      { form: { ...this.state.form, [name]: dateString } },
      function () {}
    );
  };

  render() {
    if (!this.props.moveOutFormView) return null;

    let iuFlag = this.props.iuFlag;
    let hatcheryOptions = [];

    if (this.state.hatcheryList && this.state.hatcheryList.length > 0) {
      hatcheryOptions = this.state.hatcheryList.map((row, i) => {
        return (
          <Select.Option key={i} value={row.hatcheryId}>
            {row.hatcheryId + ' - ' + row.hatcheryName}
          </Select.Option>
        );
      });
    }

    return (
      <Fragment>
        {/* 디자인 영역 */}
        <div className="con_box">
          <h3>
            <Trans>Move Out</Trans>
          </h3>

          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Hatchery</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'recvHatcheryId', //*** */
                      type: 'SELECT', //*** */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      options: hatcheryOptions, //*** */ type:'SELECT' options*/
                      placeholder: 'Hatchery', //*** */
                      width: 210, // px제외 : 없으면 30 */
                    })}
                  </td>
                  <th>
                    <Trans>Move Out Date</Trans>
                  </th>
                  <td>
                    <DatePicker
                      name="moveOutDate"
                      style={{ width: 120 }}
                      value={
                        this.state.form.moveOutDate
                          ? moment(this.state.form.moveOutDate)
                          : moment(DateUtil.today())
                      }
                      onChange={(date, dateString) =>
                        this.onDateChange(date, dateString, 'moveOutDate')
                      }
                      allowClear={false}
                    />
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Qty</Trans>
                  </th>
                  <td>
                    <span>
                      {this.state.form.goodEggQty
                        ? this.state.form.goodEggQty.toLocaleString() || ''
                        : 0}
                    </span>
                  </td>

                  <th>
                    <Trans>Strain</Trans>
                  </th>
                  <td>
                    <span>{this.props.rowData.strain}</span>
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td colSpan="3">
                    {GridUtil.renderInput({
                      inputName: 'remarks', //*** */
                      type: '', //*** */
                      //dec: 2,
                      maxLength: 1000,
                      //maxVal: 999.99,
                      //width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="txt_center mT40">
            <button className="btn_gray" onClick={(e) => this.fnClickCancel()}>
              <Trans>Cancel</Trans>
            </button>
            {(this.props.iuFlag === 'I' ||
              this.state.form.moveOutYn === 'N') && (
              <button
                className="btn_save"
                onClick={(e) => this.fnClickSaveBtn(e)}
              >
                <Trans>Save</Trans>
              </button>
            )}
          </div>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default withAlert()(MoveOutForm);
