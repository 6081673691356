import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';
import { Progress, InputNumber, DatePicker } from 'antd';

import { Const, Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';

import { moment } from 'components';
import NumberFormat from 'react-number-format';
import { withAlert } from 'react-alert';
import i18n from 'i18next';

import '../Hatchery.module.css';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 ** 4. colSpan => conSpan, rowSpan => rowSpan 입니다.
 */
class SettingInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settingDate: DateUtil.today(),
      settingQty: '',
      totQty: 0,
      form: [],
      arr: [],
      tmpForm: [],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let that = this;
    if (prevProps.fnGradingInfo !== this.props.fnGradingInfo) {
      that.setState(
        { form: this.props.fnGradingInfo, tmpForm: [], arr: [], totQty: 0 },
        function () {}
      );
    }
    if (prevProps.fnSettingInfo !== this.props.fnSettingInfo) {
      that.setState(
        { form: this.props.fnSettingInfo, tmpForm: [], arr: [], totQty: 0 },
        function () {}
      );
    }
    if (prevProps.regDate !== this.props.regDate) {
      this.fnClear();
    }
  }

  setOnChange = (e, row) => {
    const that = this;
    const col = e.target.name ? e.target.name : '';
    let val = e.target.value;
    let tot = 0;

    val = val.replace(/,/gi, '');
    // Setting 버튼을 클릭한 경우
    if (this.props.iuFlag === 'I') {
      // 각 setter의 남은 수용량 <= 셋팅하려는 수량
      if (Number(row.capacity) - Number(row.settingQty) < Number(val)) {
        that.props.alert.show(
          i18n.t(
            'The quantity that can be set in the setter has been exceeded.'
          )
        );
        this.setState(
          {
            form: {
              ...this.state.form,
              col: col,
              val: this.state.tmpForm[col],
            },
          },
          function () {}
        );
        return false;
      } else if (Number(row.capacity) - Number(row.settingQty) >= Number(val)) {
        that.setState(
          {
            form: {
              ...this.state.form,
              col: col,
              val: val,
            },
            tmpForm: {
              ...this.state.tmpForm,
              [col]: val,
            },
          },
          function () {
            that.state.arr.map((row, i) => {
              if (row.col === this.state.form.col) {
                that.state.arr.splice(i, 1);
              }
            });
            that.state.arr.push(this.state.form);
            that.state.arr.map((row, i) => {
              tot = tot + Number(row.val);
            });
            that.setState(
              {
                totQty: tot,
              },
              function () {}
            );
          }
        );
      }
    }
    // Modify 버튼을 클릭한 경우
    else if (this.props.iuFlag === 'U') {
      if (
        Number(row.capacity) -
          Number(row.settingQty) +
          Number(this.props.fnSettingInfo.settingQty) <
        Number(val)
      ) {
        that.props.alert.show(
          i18n.t(
            'The quantity that can be set in the setter has been exceeded.'
          )
        );
        this.setState(
          {
            form: {
              ...this.state.form,
              col: col,
              val: this.state.tmpForm[col],
            },
          },
          function () {}
        );
        return false;
      } else if (
        Number(row.capacity) -
          Number(row.settingQty) +
          Number(this.props.fnSettingInfo.settingQty) >=
        Number(val)
      ) {
        that.setState(
          {
            form: {
              ...this.state.form,
              col: col,
              val: val,
            },
            tmpForm: {
              ...this.state.tmpForm,
              [col]: val,
            },
          },
          function () {
            that.setState(
              { [col]: val, totQty: this.state.form.val },
              function () {}
            );
          }
        );
      }
    }
  };

  fnClickSaveBtn = (e) => {
    const url = Server.getRestAPI();

    let that = this;
    let data = {};

    if (this.props.iuFlag === 'I') {
      data = this.props.fnGradingInfo;
    }
    if (this.props.iuFlag === 'U') {
      data = this.props.fnSettingInfo;
    }

    let settingInfoList = [];

    data.farmCode = SessionUtil.getFarmCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.dateFormat = SessionUtil.getDateFormat();
    data.countryCode = SessionUtil.getCountryCode();
    data.iuFlag = this.props.iuFlag;
    data.settingDate = this.state.form.settingDate;
    data.hhId = this.props.fnGradingInfo.hhId;

    if (data.farmCode === 'undefined') {
      data.farmCode = '';
    }

    if (!data.settingDate) {
      data.settingDate = this.state.settingDate;
    }

    if (data.iuFlag === 'I') {
      console.log(this.props.fnGradingInfo.hatchingEggQty);
      console.log(this.state.totQty);
      console.log(this.props.fnGradingInfo.transferQty);
      console.log(this.props.fnGradingInfo.discardQty);
      console.log(this.props.cuttingEggQty);
      if (
        Number(this.props.fnGradingInfo.noneSettingQty) -
          Number(this.state.totQty) <
        0
      ) {
        that.props.alert.show(
          i18n.t(
            'The total number of possible setting quantity has been exceeded.'
          )
        );
        return false;
      }
      // data.noneSettingQty =
      //   Number(this.props.fnGradingInfo.noneSettingQty) -
      //   (Number(this.state.totQty) +
      //     Number(this.props.fnGradingInfo.transferQty) +
      //     Number(this.props.fnGradingInfo.discardQty) +
      //     Number(this.props.cuttingEggQty));
      data.noneSettingQty =
        Number(this.props.fnGradingInfo.noneSettingQty) -
        Number(this.state.totQty);
    } else if (data.iuFlag === 'U') {
      if (
        Number(this.props.fnGradingInfo.noneSettingQty) +
          Number(this.props.fnSettingInfo.settingQty) -
          Number(this.state.totQty) <
        0
      ) {
        that.props.alert.show(
          i18n.t(
            'The total number of possible setting quantity has been exceeded.'
          )
        );
        return false;
      }
      data.noneSettingQty =
        Number(this.props.fnGradingInfo.noneSettingQty) +
        Number(this.props.fnSettingInfo.settingQty) -
        Number(this.state.totQty);
    }

    data.settingQty = this.state.totQty;

    this.state.arr.forEach((row) => {
      let dataDef = {};
      if (Number(row.val) != 0 && row.val != '') {
        dataDef.setterId = row.col;
        dataDef.settingQty = row.val;

        settingInfoList.push(dataDef);
      }
    });
    data.settingInfoList = settingInfoList;

    if (settingInfoList.length === 0 && data.iuFlag === 'I') {
      that.props.alert.show(i18n.t('Please check setting info.'));
      return false;
    }

    data.settingInfoListLength = settingInfoList.length;

    console.log('data', data);

    axios({
      method: 'post',
      url: url + '/hatchery/setting/saveSettingInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.alert.show(i18n.t('Saved'));
        that.props.fnSetRegDate();
        that.setState({ totQty: 0 }, function () {});
      })
      .catch(function (error) {
        that.props.alert.show(i18n.t('Save failed'));
        return [];
      });
  };

  fnClickCancel = (e) => {
    this.props.fnClickCancel();
    this.setState({ form: [], tmpForm: [], totQty: 0 }, function () {});
  };

  onDateChange = (date, dateString, name) => {
    let that = this;

    if (this.props.fnGradingInfo.receiveDate <= dateString) {
      this.setState(
        { form: { ...this.state.form, [name]: dateString } },
        function () {}
      );
    } else {
      that.props.alert.show(i18n.t('Please Check Setting Date.'));
    }
  };

  disabledDate = (current) => {
    if (this.props.iuFlag === 'U') {
      return current;
    }
  };

  render() {
    if (!this.props.settingInfoFormView) return null;

    let available = 0;
    if (this.props.iuFlag === 'I') {
      available = this.props.fnGradingInfo.noneSettingQty;
    }
    if (this.props.iuFlag === 'U') {
      available =
        Number(this.props.fnGradingInfo.noneSettingQty) +
        Number(this.props.fnSettingInfo.settingQty);
    }
    let setterList = [];
    if (this.props.setterInfo && this.props.setterInfo.length > 0) {
      setterList = this.props.setterInfo.map((row, i) => {
        let availableQty = 0;
        if (this.props.iuFlag === 'I') {
          availableQty = Number(row.capacity) - Number(row.settingQty);
        }
        if (this.props.iuFlag === 'U') {
          availableQty =
            Number(row.capacity) -
            Number(row.settingQty) +
            Number(this.props.fnSettingInfo.settingQty);
        }
        return (
          <tr key={i}>
            <th
              style={{
                textAlign: 'center',
              }}
            >
              <Trans>{row.setterId}</Trans>
            </th>
            <td
              style={{
                textAlign: 'right',
              }}
            >
              {row.capacity.toLocaleString()}
            </td>
            <td
              style={{
                textAlign: 'right',
              }}
            >
              {row.settingQty.toLocaleString()}
            </td>
            <td
              style={{
                textAlign: 'right',
              }}
            >
              {availableQty.toLocaleString()}
            </td>
            <td
              style={{
                textAlign: 'center',
              }}
            >
              <NumberFormat
                className="input_base"
                defaultValue={0}
                thousandSeparator={true}
                decimalScale="0"
                name={row.setterId}
                allowNegative={false}
                style={{
                  textAlign: 'right',
                  width: '90%',
                }}
                value={
                  this.state.tmpForm[row.setterId]
                    ? this.state.tmpForm[row.setterId] || ''
                    : ''
                }
                onChange={(e) => this.setOnChange(e, row)}
                onFocus={(e) => {
                  e.target.select();
                }}
              />
            </td>
          </tr>
        );
      });
    }
    if (!this.props.setterInfo || this.props.setterInfo.length === 0) {
    }

    return (
      <Fragment>
        {/* 디자인 영역 */}

        <div className="con_box">
          <h3>
            <Trans>Setting Info</Trans>
          </h3>
          {this.props.setterInfo && this.props.setterInfo.length > 0 && (
            <div className="border mB20 txt_center">
              <label htmlFor="">
                <Trans>Setting Date :</Trans>
              </label>
              <DatePicker
                name="settingDate"
                style={{ width: 120 }}
                value={
                  this.state.form.settingDate
                    ? moment(this.state.form.settingDate)
                    : moment(DateUtil.today())
                }
                onChange={(date, dateString) =>
                  this.onDateChange(date, dateString, 'settingDate')
                }
                disabledDate={this.disabledDate}
                allowClear={false}
              />
              <ul className="st_bar">
                <li className="bold">Total Qty</li>
                <li>
                  <div>
                    <Progress
                      percent={Math.round(
                        (Number(this.state.totQty) / Number(available)) * 100
                      )}
                    />
                  </div>
                </li>
                <li>
                  <font>{this.state.totQty.toLocaleString()}</font> /{' '}
                  {available.toLocaleString()}
                </li>
              </ul>
              <div style={{ width: '51%', marginTop: '9px' }}>
                <p style={{ color: 'red' }}>
                  <span>*</span>
                  <Trans>Total Qty</Trans> = <Trans>Hatching Egg</Trans> -{' '}
                  <Trans>Setting Egg</Trans> - <Trans>Cutting Egg</Trans>
                </p>
              </div>
            </div>
          )}

          {this.props.setterInfo && this.props.setterInfo.length > 0 && (
            <div className="board_list2 mT20">
              <table>
                <colgroup>
                  <col style={{ width: '5%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '10%' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Trans>Setter Id</Trans>
                    </th>
                    <th
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Trans>Capacity</Trans>
                    </th>
                    <th
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Trans>Current Set Qty</Trans>
                    </th>
                    <th
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Trans>Available Qty</Trans>
                    </th>
                    <th
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Trans>Setting Qty</Trans>
                    </th>
                  </tr>
                  {setterList}
                </tbody>
              </table>
            </div>
          )}

          {!this.props.setterInfo ||
            (this.props.setterInfo.length === 0 && (
              <div
                className="board_list2"
                style={{
                  textAlign: 'center',
                }}
              >
                <Trans>Setting 가능한 Setter가 없습니다</Trans>
              </div>
            ))}

          {this.props.setterInfo && this.props.setterInfo.length > 0 && (
            <div className="txt_center mT40">
              <button
                className="btn_gray"
                onClick={(e) => this.fnClickCancel()}
              >
                <Trans>Cancel</Trans>
              </button>
              {this.props.iuFlag === 'I' ? (
                <button
                  className="btn_save"
                  onClick={(e) => this.fnClickSaveBtn(e)}
                >
                  <Trans>Save</Trans>
                </button>
              ) : null}
            </div>
          )}
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default withAlert()(SettingInfoForm);
