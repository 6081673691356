/* eslint-disable no-undef */
import React, { Component, Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import { Popconfirm, Switch, message, DatePicker, Select } from 'antd';
import NumberFormat from 'react-number-format';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';
/*
 ** breeding/receive/FlockList
 ** 입고저장 후 계군에 저장
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class FlockList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      tpBreedRecvData: {},
      rowData: [],
      strainList: [],
      formView: false,
      columnDefs: [
        {
          headerName: '',
          field: 'ck',
          width: 70,
          cellStyle: { textAlign: 'center' },
          editable: true,
          cellRenderer: (params) => {
            return '<button class="btn_sel">' + i18n.t('Select') + '</button>';
          },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Flock',
          field: 'flockId',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Flock Date',
          field: 'flockCdate',
          width: 120,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Strain',
          field: 'strainName',
          width: 150,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Female Qty',
          field: 'flockInQtyF',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Male Qty',
          field: 'flockInQtyM',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          precision: 0,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Remarks',
          field: 'remarks',
          width: 150,
          cellStyle: { textAlign: 'left' },
        },
      ],
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        suppressSizeToFit: false,
      },
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    this.flockCdate = React.createRef();
    this.strainCode = React.createRef();

    this.setState(
      { tpBreedRecvData: this.props.tpBreedRecvData },
      function () {}
    );
    this.fnSearch();
    //this.fnNewFlock();
  }
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  fnSearch = () => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();
    //data.receiveNo = '';
    data.where = 'AND LAYING_SDATE IS NULL';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/selectBreedReceiveFlockList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('searchFlockList', response.data);
        that.setState(
          { rowData: response.data.rows1, strainList: response.data.rows2 },
          function () {
            console.log(that.state);
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  //flock선택 - 저장 save
  fnSelectFlock = (data, tpBreedRecvData) => {
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.docReceiveNo = tpBreedRecvData.docReceiveNo;
    data.flockRdate = tpBreedRecvData.receiveDate;
    data.flockInQtyF = tpBreedRecvData.totalRecvQtyF;
    data.flockInQtyM = tpBreedRecvData.totalRecvQtyM;

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/saveBreedRecvFlock.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.alert.show(i18n.t('Has been saved.'));
        that.props.fnSearch();
      })
      .catch(function (error) {
        return [];
      });
  };

  fnCancel = (e) => {
    this.props.fnCancelFlockList(this.state.form);
  };
  //save
  onCellClicked = (e) => {
    //console.log('onCellClicked', e);
    if (e.column.colId === 'ck') {
      //선택버튼 클릭
      this.fnSelectFlock(e.data, this.state.tpBreedRecvData);
    }
  };
  // setOnChange = e => {
  //   const col = e.target.name ? e.target.name : '';
  //   this.setState(
  //     {
  //       form: { ...this.state.form, [col]: col, [col]: e.target.value }
  //     },
  //     function() {
  //       //console.log('=====>', this.state);
  //     }
  //   );
  // };
  setChangeSelect = (e) => {
    const col = e.target.name ? e.target.name : '';
    //console.log("setChange", e.target);
    const txt = e.target.options[e.target.selectedIndex].text;
    this.setState(
      {
        form: { ...this.state.form, [col]: txt, [col]: e.target.value },
      },
      function () {}
    );
  };
  fnNewFlock = (e) => {
    let data = {};
    data.iuFlag = 'I';
    data.flockCdate = DateUtil.today();
    data.flockInQtyF = 0;
    data.flockInQtyM = 0;
    data.remarks = '';

    this.setState({ form: data, formView: true }, function () {});
  };

  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  renderInput = (inputName, ty, dec, len) => {
    let cl = len ? 'input_type1 w' + len : 'input_type1';

    if (ty && ty === 'N') {
      cl = len ? 'input_type2 w' + len : 'input_type2';
      return (
        <NumberFormat
          style={{ textAlign: 'right' }}
          className={cl}
          thousandSeparator={true}
          decimalScale={dec ? dec : 0}
          name={inputName}
          //ref={this[inputName]}
          getInputRef={(el) => (this[inputName] = el)}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : null
          }
          onChange={(e) => this.setOnChange(e, ty)}
        />
      );
    } else if (ty && ty === 'T') {
      return (
        <TimePicker
          //defaultValue={moment('12:08', 'HH:mm')}
          className={cl}
          format={'HH:mm'}
          ref={this[inputName]}
          value={
            this.state.form[inputName]
              ? moment(this.state.form[inputName], 'HH:mm') || ''
              : null
          }
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
        />
      );
    } else if (ty && ty === 'S') {
      return (
        <span>
          {this.state.form[inputName] ? this.state.form[inputName] || '' : ''}
        </span>
      );
    } else if (ty && ty === 'D') {
      return (
        <DatePicker
          name={inputName}
          //className={cl}
          ref={this[inputName]}
          value={moment(this.state.form[inputName])}
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
          allowClear={false}
        />
      );
    } else {
      return (
        <input
          type="text"
          name={inputName}
          className={cl}
          ref={this[inputName]}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : ''
          }
          onChange={(e) => this.setOnChange(e)}
        />
      );
    }
  };
  setOnChange = (e, ty) => {
    let that = this;
    const col = e.target.name ? e.target.name : '';
    //console.log('setOnChangeTime', e);
    //console.log('setOnChangeTime', e.target.value);
    //this.setState({ form[col]: e.target.value }, function() {});
    let val = e.target.value;
    if (ty === 'N') {
      val = val.replace(/,/gi, '');
    }
    this.setState(
      {
        form: {
          ...that.state.form,
          [col]: val,
        },
      },
      function () {
        // if (ty === 'N') {
        //   that.setState({
        //     form: that.fnCalcQty(that.state.form, col)
        //   });
        //   //this.fnCalcEggGood();
        // }
      }
    );
  };
  setOnChangeTime = (date, dateString, inputName) => {
    let that = this;
    const col = inputName ? inputName : '';
    //this.setState({ form[col]: e.target.value }, function() {});

    this.setState(
      {
        form: {
          ...this.state.form,
          [col]: dateString,
        },
      },
      function () {
        that.getNewFlockId(that.state.form);
      }
    );
  };
  setOnChangeSelect = (value, col) => {
    let that = this;
    this.setState({ form: { ...this.state.form, [col]: value } }, function () {
      that.getNewFlockId(that.state.form);
    });
  };

  getNewFlockId = (data) => {
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();

    if (this.state.form.strainCode && this.state.form.flockCdate) {
      const url = Server.getRestAPI();
      axios({
        method: 'post',
        url: url + '/breed/receive/getNewFlockId.do',
        data: qs.stringify(data),
        withCredentials: true,
        credentials: 'same-origin',
      })
        .then(function (response) {
          that.setState(
            { form: { ...that.state.form, flockId: response.data.flockId } },
            function () {
              console.log(that.state.form);
            }
          );
        })
        .catch(function (error) {
          return [];
        });
    }
  };

  formatNumber = (params) => {
    if (!params.value) {
      return '';
    } else {
      return Math.floor(params.value)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  };
  render() {
    let strainOptions = [];
    const Option = Select.Option;
    if (this.state.strainList) {
      strainOptions = this.state.strainList.map((row, i) => {
        return (
          <Option key={i} value={row.codeValue}>
            {row.codeText}
          </Option>
        );
      });
    }
    return (
      <Fragment>
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            margin: 'auto',
            zIndex: 1000,
            backgroundColor: 'rgba(0,0,0, 0.5)',
          }}
        >
          <div
            style={{
              height: 'auto',
              position: 'absolute',
              left: '15%',
              right: '15%',
              top: '15%',
              //bottom: '15%',
              //margin: 'auto',
              borderRadius: '10px',
              background: 'white',
            }}
          >
            <div className="pop_box">
              <h3>
                {this.props.title ? (
                  this.props.title
                ) : (
                  <Trans>Select a Flock</Trans>
                )}
              </h3>
              <div className="board_list2 mB10">
                <table>
                  <colgroup>
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '35%' }} />
                    <col style={{ width: '15%' }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <Trans>Receive No</Trans>
                      </th>
                      <td>
                        {GridUtil.renderInput({
                          inputName: 'docReceiveNo', //*** */
                          type: '', //*** */
                          that: this, //*** */
                          statsForm: this.state.tpBreedRecvData, //*** */
                          disabled: true,
                        })}
                      </td>
                      <th>
                        <Trans>Receive Date</Trans>
                      </th>
                      <td>
                        {GridUtil.renderInput({
                          inputName: 'receiveDate', //*** */
                          type: '', //*** */
                          that: this, //*** */
                          statsForm: this.state.tpBreedRecvData, //*** */
                          disabled: true,
                        })}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans>Female Qty</Trans>
                      </th>
                      <td>
                        {GridUtil.renderInput({
                          inputName: 'totalRecvQtyF', //*** */
                          value: this.state.tpBreedRecvData.totalRecvQtyF
                            ? this.state.tpBreedRecvData.totalRecvQtyF.toLocaleString()
                            : 0,
                          type: '', //*** */
                          that: this, //*** */
                          statsForm: this.state.tpBreedRecvData, //*** */
                          disabled: true,
                        })}
                      </td>
                      <th>
                        <Trans>Male Qty</Trans>
                      </th>
                      <td>
                        {GridUtil.renderInput({
                          inputName: 'totalRecvQtyM', //*** */
                          value: this.state.tpBreedRecvData.totalRecvQtyM
                            ? this.state.tpBreedRecvData.totalRecvQtyM.toLocaleString()
                            : 0,
                          type: '', //*** */
                          that: this, //*** */
                          statsForm: this.state.tpBreedRecvData, //*** */
                          disabled: true,
                        })}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                className="f_right mB10 ag-theme-balham"
                style={{ height: '300px', width: '100%' }}
              >
                <AgGridReact
                  onGridReady={this.onGridReady}
                  defaultColDef={this.state.defaultColDef}
                  columnDefs={this.state.columnDefs}
                  rowData={
                    this.state.rowData.length > 0 ? this.state.rowData : []
                  }
                  //onRowClicked={e => this.onRowClicked(e)}
                  onCellClicked={(e) => this.onCellClicked(e)}
                  rowSelection={'single'}
                  onCellFocused={(e) => {
                    e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
                  }}
                />
              </div>
              {this.state.formView ? (
                <div className="board_list2 mB10">
                  <table>
                    <colgroup>
                      <col style={{ width: '15%' }} />
                      <col style={{ width: '35%' }} />
                      <col style={{ width: '15%' }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>
                          <Trans>Create Date</Trans>
                        </th>
                        <td>
                          {this.renderInput('flockCdate', 'D', 0, '200px')}
                        </td>
                        <th>
                          <Trans>Strain</Trans>
                        </th>
                        <td>
                          <Select
                            showSearch
                            value={this.state.form.strainCode}
                            placeholder={'Strain'}
                            style={{ width: 200 }}
                            defaultActiveFirstOption={false}
                            showArrow={true}
                            //filterOption={false}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            optionFilterProp="children"
                            //onSearch={this.handleSearch}
                            onChange={(value) =>
                              this.setOnChangeSelect(value, 'strainCode')
                            }
                            ref={(select) => {
                              this.strainCode = select;
                            }}
                          >
                            {strainOptions}
                          </Select>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Trans>Remarks</Trans>
                        </th>
                        <td colSpan="3">
                          {this.renderInput('remarks', '', 0, 100)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}

              <div className="f_right mB10">
                {/* <button className="btn_blue" onClick={e => this.fnNewFlock()}>
                  <Trans>New Flock</Trans>
                </button>
                {this.state.formView ? (
                  <button className="btn_save" onClick={e => this.fnSave()}>
                    <Trans>Save Flock</Trans>
                  </button>
                ) : null} */}
                <button
                  className="btn_gray"
                  onClick={(e) => this.props.fnCloseFlockList()}
                >
                  <Trans>Cancel</Trans>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withAlert()(FlockList);
