import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import produce from 'immer';
import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { message, Select } from 'antd';

// import _ from 'lodash';

import '../Common.module.css';

/*
 * Breeding Type : Growing('G') / Laying('L'), Code ID EP29
 * Breed 는 G, L 모두 가능
 * Broiler는 G만 가능
 */
export default class BreedingType extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        masterCodeId: 'EP29',
      },
      codes: [],
      code: '',
      disabled: false,
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnGetRowData(this.state.params);
  }

  // =========================================================================
  // deprecated 되었으므로, 사용을 금합니다.
  // componentWillReceiveProps(nextProps) {}
  // =========================================================================
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.disabled !== this.props.disabled) {
      this.setState({ code: '', disabled: this.props.disabled });
    }
  }

  // 국가 코드 정보 가져오기
  fnGetRowData = (params) => {
    const that = this;
    let url = Server.getRestAPI() + '/system/selectCodes.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        const codes = [];
        if (data) {
          data.map((row, i) => {
            const { codeValue, codeText } = row;
            if (codeValue == 'L' && that.props.isBroiler == true) return;
            const obj = {
              codeValue: row.codeValue,
              codeText: row.codeText,
            };
            codes.push(obj);
          });
          that.setState({ codes: codes });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onChange = (value) => {
    const that = this;
    // Q. 여기서 draft의 원형이 되는 값은 어디서 오는거에요??
    // A. this.setState가 원래 prevState를 인자로 주는 함수이기때문에
    //    prevState가 앞에 들어가서 고차함수로 빠지게됩니다.
    this.setState(
      produce((draft) => {
        draft.code = value;
      }),
      function () {
        that.props.setCode(that.props.name, value);
      }
    );
  };

  render() {
    return (
      <Select
        type="text"
        name="breedingType"
        className="input_type1"
        // value={this.state.code}
        onChange={this.onChange}
        placeholder={i18n.t('Select code')}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={this.props.disabled}
      >
        {this.state.codes.map((row, i) => {
          return (
            <Select.Option key={i} value={row.codeValue}>
              {row.codeText}
            </Select.Option>
          );
        })}
      </Select>
    );
  }
}
