import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import AFCheckboxSearch from '../utils/AFCheckboxSearch';
import AFDatePickerSearch from '../utils/AFDatePickerSearch';
import AFIndexSearch from '../utils/AFIndexSearch';
import ProjectDateRangeSearch from '../utils/ProjectDateRangeSearch';
import ReportUtil from '../utils/ReportUtil';

import '../Report.module.css';

// 지표별 프로젝트 분석 보고서
export default class ProjIdxAnlysR extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      excelName: i18n.t('Title Proj Anlys R(Idx)'),
      rowData: [],
      params: {},
      ColDefs: [
        {
          headerName: i18n.t('Division'),
          field: 'division',
          pinned: 'left',
          minWidth: 100,
          colSpan: function (params) {
            const { division } = params.data;
            switch (division) {
              case 'Standard':
              case 'Farm Total':
              case 'Region Total':
              case 'Division Total':
                return 6;
            }
            return 1;
          },
        },
        {
          headerName: i18n.t('Region'),
          field: 'region',
          pinned: 'left',
          minWidth: 100,
          valueFormatter: function (params) {
            // 숫자형이지만, 쉼표가 표기되면 안됨
          },
        },
        {
          headerName: i18n.t('Project'),
          field: 'project',
          pinned: 'left',
          minWidth: 120,
        },
        {
          headerName: i18n.t('Strain'),
          field: 'strain',
          minWidth: 120,
          pinned: 'left',
        },
        {
          headerName: i18n.t('Chick In'),
          field: 'chickIn',
          minWidth: 120,
          pinned: 'left',
        },
        {
          headerName: i18n.t('Age'),
          field: 'age',
          pinned: 'left',
        },
        { headerName: i18n.t('D1'), field: 'D1' },
        { headerName: i18n.t('D2'), field: 'D2' },
        { headerName: i18n.t('D3'), field: 'D3' },
        { headerName: i18n.t('D4'), field: 'D4' },
        { headerName: i18n.t('D5'), field: 'D5' },
        { headerName: i18n.t('D6'), field: 'D6' },
        { headerName: i18n.t('D7'), field: 'D7' },
        { headerName: i18n.t('D8'), field: 'D8' },
        { headerName: i18n.t('D9'), field: 'D9' },
        { headerName: i18n.t('D10'), field: 'D10' },
        { headerName: i18n.t('D11'), field: 'D11' },
        { headerName: i18n.t('D12'), field: 'D12' },
        { headerName: i18n.t('D13'), field: 'D13' },
        { headerName: i18n.t('D14'), field: 'D14' },
        { headerName: i18n.t('D15'), field: 'D15' },
        { headerName: i18n.t('D16'), field: 'D16' },
        { headerName: i18n.t('D17'), field: 'D17' },
        { headerName: i18n.t('D18'), field: 'D18' },
        { headerName: i18n.t('D19'), field: 'D19' },
        { headerName: i18n.t('D20'), field: 'D20' },
        { headerName: i18n.t('D21'), field: 'D21' },
        { headerName: i18n.t('D22'), field: 'D22' },
        { headerName: i18n.t('D23'), field: 'D23' },
        { headerName: i18n.t('D24'), field: 'D24' },
        { headerName: i18n.t('D25'), field: 'D25' },
        { headerName: i18n.t('D26'), field: 'D26' },
        { headerName: i18n.t('D27'), field: 'D27' },
        { headerName: i18n.t('D28'), field: 'D28' },
        { headerName: i18n.t('D29'), field: 'D29' },
        { headerName: i18n.t('D30'), field: 'D30' },
        { headerName: i18n.t('D31'), field: 'D31' },
        { headerName: i18n.t('D32'), field: 'D32' },
        { headerName: i18n.t('D33'), field: 'D33' },
        { headerName: i18n.t('D34'), field: 'D34' },
        { headerName: i18n.t('D35'), field: 'D35' },
        { headerName: i18n.t('D36'), field: 'D36' },
        { headerName: i18n.t('D37'), field: 'D37' },
        { headerName: i18n.t('D38'), field: 'D38' },
        { headerName: i18n.t('D39'), field: 'D39' },
        { headerName: i18n.t('D40'), field: 'D40' },
        { headerName: i18n.t('D41'), field: 'D41' },
        { headerName: i18n.t('D42'), field: 'D42' },
        { headerName: i18n.t('D43'), field: 'D43' },
        { headerName: i18n.t('D44'), field: 'D44' },
        { headerName: i18n.t('D45'), field: 'D45' },
        { headerName: i18n.t('D46'), field: 'D46' },
        { headerName: i18n.t('D47'), field: 'D47' },
        { headerName: i18n.t('D48'), field: 'D48' },
        { headerName: i18n.t('D49'), field: 'D49' },
        { headerName: i18n.t('D50'), field: 'D50' },
      ],
      gridHeight: '640px',
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        width: 80,
        minWidth: 80,
        cellClass: 'text-center',
        suppressSizeToFit: false,
        cellStyle: function (params) {
          const { division } = params.data;
          switch (division) {
            case 'Standard':
            case 'Farm Total':
            case 'Region Total':
            case 'Division Total':
              return { 'background-color': Const.AGGR_COLOR };
          }
        },
        valueFormatter: function (params) {
          return GridUtil.formatReport(params);
        },
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagenation: false,
      paginationPageSize: 10,
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    if (Const.RPT_TEST) {
      const params = ReportUtil.getDefaultParams(this.props);
      console.log('params:', params);
      this.fnGetRowData(params);
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  // -----------------------------------------------------------
  // 참고용 : ES/ETL 서버 주소(API)를 지정해야 합니다.
  // -----------------------------------------------------------
  fnGetRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/stat/broiler/projIdxAnlysR.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        console.log(data.rows1);
        if (data.rows1) {
          that.setState({ rowData: data.rows1 });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtExport = () => {
    const fileName =
      i18n.t(this.state.excelName) + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t(this.state.excelName);
    const params = {
      allColumns: false,
      columnGroups: true,
      fileName: fileName,
      sheetName: sheetName,
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onSearch = (params) => {
    console.log(params);
    this.fnGetRowData(params);
  };

  render() {
    return (
      <React.Fragment>
        <h3>
          {/* 지표별 프로젝트 분석 보고서 */}
          <Trans>Title Proj Anlys R(Idx)</Trans>
        </h3>

        <div className="con_box">
          <AFDatePickerSearch
            index1={true}
            onSearch={this.onSearch}
          ></AFDatePickerSearch>

          <div className="f_right mB20">
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
          </div>
          <div className="board_list_s">
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  excelStyles={this.state.excelStyles}
                  onGridReady={this.onGridReady}
                  rowData={this.state.rowData}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// const sampleData = [
//   { project: 'PJ19001' },
//   { project: 'PJ19002' },
//   { project: 'PJ19003' },
//   { project: 'Area1 Sub tot' },
//   { project: 'PJ19004' },
//   { project: 'PJ19005' },
//   { project: 'PJ19006' },
//   { project: 'Area2 Sub tot' },
//   { project: 'Total' },
// ];
