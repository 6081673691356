import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { withAlert } from 'react-alert';

import { moment } from 'components';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { DateUtil } from 'utils';
import scrollToComponent from 'react-scroll-to-component';

import PopUpSelectHatcherList from './PopUpSelectHatcherList';

import { TimePicker, InputNumber, Select, DatePicker, Modal } from 'antd';
import i18n from 'i18next';
import NumberFormat from 'react-number-format';
import '../Hatchery.module.css';

/*
 ** Breeding/Glowing Log
 ** saveGrowingLog
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class CandlingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      settingQty: 0,
      fertileQty: 0,
      candlingDate: DateUtil.today(),
      popUpSelectHatcherListView: false,
      form: [],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    let param = {};
    this.fnSearchHatcher();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let that = this;
    if (prevProps.form !== this.props.form) {
      this.setState(
        {
          form: this.props.form,
          settingQty: this.props.form.settingQty,
          fertileQty: this.props.form.fertileQty,
        },
        function () {}
      );
    }
    if (prevProps.regDate !== this.props.regDate) {
      this.fnSearchHatcher();
      this.fnClear();
    }
  }

  fnClickCancel = (e) => {
    this.props.fnClickCancel();
    this.fnClear();
  };

  fnSave = () => {
    this.setState({ visible: false }, function () {});

    let that = this;
    let data = this.state.form;

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.hatcheryId = SessionUtil.getHatcheryId();

    data.settingSeq = this.props.form.settingSeq;
    data.eggReceiveNo = this.props.form.eggReceiveNo;

    if (!data.candlingDate) {
      data.candlingDate = this.state.candlingDate;
    }

    if (data.candlingDate < this.props.form.settingDate) {
      that.props.alert.show(i18n.t('CandlingDate must be before SettingDate'));
      return false;
    }

    if (data.farmCode === 'undefined') {
      data.farmCode = '';
    }

    console.log('data', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/candling/saveCandlingInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.fnSetRegDate();
        that.props.alert.show(i18n.t('Saved'));
        that.fnClear();
        that.fnSearchHatcher();
      })
      .catch(function (error) {
        that.props.alert.show(i18n.t('Save failed'));
        return [];
      });
  };

  fnClear = (e) => {
    if (this.props.iuFlag === 'I') {
      this.setState(
        {
          form: [],
          iuFlag: 'I',
        },
        function () {}
      );
    }
    if (this.props.iuFlag === 'U') {
      this.setState(
        {
          form: this.props.form,
        },
        function () {}
      );
    }
  };

  setOnChange = (e, params, dateString) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';

    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    let tot = Number(this.props.form.settingQty);

    this.setState(
      {
        fertileQty: tot,
        form: {
          ...this.state.form,
          [col]: col,
          [col]: val,
        },
      },
      function () {
        if (Number(this.state.form.explodeQty)) {
          tot = tot - Number(this.state.form.explodeQty);
        }
        if (Number(this.state.form.infertileQty)) {
          tot = tot - Number(this.state.form.infertileQty);
        }
        if (Number(this.state.form.discardQty)) {
          tot = tot - Number(this.state.form.discardQty);
        }

        that.setState(
          {
            fertileQty: tot,
            form: {
              ...this.state.form,
              fertileQty: 'fertileQty',
              fertileQty: tot,
            },
          },
          function () {}
        );
      }
    );
  };

  fnSelectHatcher = () => {
    this.setState(
      {
        popUpSelectHatcherListView: true,
      },
      function () {
        scrollToComponent(this['popUpSelectHatcherListView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };

  fnSearchHatcher = () => {
    window.scrollTo(0, 0);

    let that = this;
    let data = {};
    data.farmCode = SessionUtil.getFarmCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.dateFormat = SessionUtil.getDateFormat();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/candling/selectHatcherInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        if (response.data.rows1.length > 0) {
          that.setState(
            {
              hatcherList: response.data.rows1,
            },
            function () {}
          );
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  setOnChangeSelect = (value, col) => {
    this.setState(
      { form: { ...this.state.form, [col]: value } },
      function () {}
    );
  };

  fnClickPopUpCancel = () => {
    this.setState({ popUpSelectHatcherListView: false }, function () {});
  };

  onDateChange = (date, dateString, name) => {
    this.setState(
      { form: { ...this.state.form, [name]: dateString } },
      function () {}
    );

    // var settingDate = new Date(this.props.form.settingDate);
    // settingDate.setDate(settingDate.getDate() + 19);

    // var year = settingDate.getFullYear();
    // var month = settingDate.getMonth() + 1;
    // var day = settingDate.getDate();

    // day = day < 10 ? '0' + day : day;
    // month = month < 10 ? '0' + month : month;

    // settingDate = year + '-' + month + '-' + day;

    // if (settingDate != dateString) {
    //   this.props.alert.show(
    //     i18n.t('기준 Candling Date는 ' + settingDate + ' 입니다.')
    //   );
    // }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState(
      {
        visible: false,
      },
      function () {}
    );
  };

  confirm = () => {
    if (
      Number(this.state.form.explodeQty) +
        Number(this.state.form.infertileQty) >
      this.state.form.settingQty
    ) {
      this.props.alert.show(
        i18n.t(
          'The total number of possible candling quantity has been exceeded.'
        )
      );
      return false;
    }

    if (!this.state.form.hatcherId) {
      this.props.alert.show(i18n.t('Please select Hatcher.'));
      this.hatcherId.focus();
      return false;
    }

    console.log('a', this.props.form);
    var candlingDate = new Date(this.props.form.settingDate);
    candlingDate.setDate(candlingDate.getDate() + 18);

    var year = candlingDate.getFullYear();
    var month = candlingDate.getMonth() + 1;
    var day = candlingDate.getDate();

    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    candlingDate = year + '-' + month + '-' + day;

    if (candlingDate != this.state.form.candlingDate) {
      Modal.confirm({
        content:
          "The standard candling date is '" +
          candlingDate +
          "'. Would you like to save it anyway?",
        visible: this.state.visible,
        okText: 'Save',
        cancelText: 'Cancel',
        onOk: this.fnSave,
        onCancel: this.hideModal,
      });
    } else {
      this.fnSave();
    }
  };

  render() {
    if (!this.props.candlingFormView) return null;

    let hatcherOptions = [];
    if (this.state.hatcherList && this.state.hatcherList.length > 0) {
      hatcherOptions = this.state.hatcherList.map((row, i) => {
        return (
          <Select.Option key={i} value={row.hatcherId}>
            {row.hatcherId}
          </Select.Option>
        );
      });
    }

    return (
      <Fragment>
        {/* write&view */}
        <div className="con_box">
          <h3>
            <Trans>Candling Info</Trans>
          </h3>

          <div className="board_list2 mT20">
            <table>
              <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '30%' }} />
                <col style={{ width: '20%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th style={{ height: '45px' }}>
                    <Trans>Setter</Trans>
                  </th>
                  <td>
                    <span>{this.state.form.setterId}</span>
                  </td>
                  <th>
                    <Trans>Setting Qty</Trans>
                  </th>
                  <td>
                    <span>{this.state.settingQty.toLocaleString()}</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Candling Date</Trans> <span className="red">*</span>{' '}
                  </th>
                  <td>
                    <DatePicker
                      name="candlingDate"
                      style={{ width: 120 }}
                      value={
                        this.state.form.candlingDate
                          ? moment(this.state.form.candlingDate)
                          : moment(DateUtil.today())
                      }
                      onChange={(date, dateString) =>
                        this.onDateChange(date, dateString, 'candlingDate')
                      }
                      allowClear={false}
                    />
                  </td>
                  <th>
                    <Trans>Hatcher</Trans> <span className="red">*</span>{' '}
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'hatcherId', //*** */
                      type: 'SELECT', //*** */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      options: hatcherOptions, //*** */ type:'SELECT' options*/
                      placeholder: 'Hatcher', //*** */
                      width: 210, // px제외 : 없으면 30 */
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Explode</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'explodeQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 120, // px제외 : 없으면 100% */
                    })}
                  </td>
                  <th>
                    <Trans>Infertile</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'infertileQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 120, // px제외 : 없으면 100% */
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Fertile</Trans>
                  </th>
                  <td style={{ height: '45px' }}>
                    <span>
                      {this.state.fertileQty
                        ? this.state.fertileQty.toLocaleString() || ''
                        : 0}
                    </span>
                  </td>
                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'candlingRem', //*** */
                      type: '', //*** */
                      //dec: 2,
                      maxLength: 1000,
                      //maxVal: 999.99,
                      //width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Discard</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'discardQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 120, // px제외 : 없으면 100% */
                      disabled: true,
                    })}
                  </td>
                  <th>
                    <Trans>Discard Remarks</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'discardRem', //*** */
                      type: '', //*** */
                      //dec: 2,
                      maxLength: 1000,
                      //maxVal: 999.99,
                      //width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      disabled: true,
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p style={{ color: 'red' }}>
            <span>*</span>
            <Trans>Setting Qty</Trans> = <Trans>Setting Qty</Trans> -{' '}
            <Trans>Cutting</Trans>
          </p>

          <div className="txt_center mT40">
            <button className="btn_gray" onClick={(e) => this.fnClickCancel()}>
              <Trans>Cancel</Trans>
            </button>
            {this.props.iuFlag === 'I' ? (
              <button className="btn_save" onClick={this.confirm}>
                <Trans>Save</Trans>
              </button>
            ) : null}
          </div>
        </div>

        {/* write&view end*/}
      </Fragment>
    );
  }
}
export default withAlert()(CandlingForm);
