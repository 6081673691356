import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';

// For Antd ==================================
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Icon,
  Button,
  Checkbox
} from 'antd';
import { Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';
import { remove } from 'lodash';

// For Design
import '../Common.module.css';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */

export default class InsertGroup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: i18n.t('GROUP ID'),
          dataIndex: 'group_id',
          render: text => <Link to="#">{text}</Link>
        },
        {
          title: i18n.t('GROUP NAME'),
          dataIndex: 'group_nm'
        }
      ],
      data: []
    };
  }

  // 사용자 그룹정보를 생성/조회/폼리셋 합니다.
  fnInsertGroup = data => {
    const that = this;
    let url = Server.getRestAPI() + '/users/insertGroup.do';
    axios({
 	method: "post",
      url: url,
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        // console.log(response.data);
        if (response.data) {
          that.props.fnSelectGroup();
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  handleSubmit = e => {
    e.preventDefault();
    // console.log(this.props.form.values);
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        this.fnInsertGroup(values);
      }
    });
  };

  // rowSelection object indicates the need for row selection
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows
      );
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name
    })
  };

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError
    } = this.props.form;
    return (
      <React.Fragment>
        {/* 디자인 영역 */}
        <div>
          <Form
            layout="inline"
            onSubmit={this.handleSubmit}
            className="group-form"
          >
            <Form.Item>
              {getFieldDecorator('group_id', {
                rules: [
                  { required: true, message: i18n.t('Please input group id!') }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  placeholder={i18n.t('USER GROUP ID')}
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('group_nm', {
                rules: [
                  {
                    required: true,
                    message: i18n.t('Please input group name!')
                  }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  placeholder={i18n.t('USER GROUP NAME')}
                />
              )}
            </Form.Item>
            <Form.Item>
              {/*
              {getFieldDecorator('deleted', {
                valuePropName: 'checked',
                initialValue: false
              })(
                <Checkbox style={{ display: 'none' }}>
                  <Trans>DELETED</Trans>
                </Checkbox>
              )}
              */}
              <Button type="primary" htmlType="submit" className="reg-button">
                <Trans>REGISTER</Trans>
              </Button>
            </Form.Item>
          </Form>
        </div>
        {/* 디자인 영역 */}
      </React.Fragment>
    );
  }
}
