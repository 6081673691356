import React, { Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { Const, Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';
import { moment } from 'components';
import i18n from 'i18next';
import { Popconfirm, Switch, message, DatePicker } from 'antd';
import NumberFormat from 'react-number-format';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';
/*
 ** Breed - Receive - Hen House - In
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class PopChickinHh extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      inQty: 0, //입력받는 field 합계
      inQtyF: 0, //입력받는 field
      inQtyM: 0, //입력받는 field
      chickinDate: '',
      moveDate: '',
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnInit(this.props.popInRow);
  }
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   //console.log('componentWillReceiveProps', nextProps.popInRow);
  //   this.setState(nextProps.popInRow, function() {
  //     let _capa = Number(this.state.capacity);
  //     _capa =
  //       _capa -
  //       (Number(this.state.chickinQtyF) + Number(this.state.chickinQtyM));

  //     this.setState(
  //       {
  //         capa: _capa,
  //         inQtyF: 0,
  //         inQtyM: 0
  //       },
  //       function() {
  //         this.fnCalcQty(this.state, '');
  //       }
  //     );
  //   });
  // }
  fnInit(popInRow) {
    let that = this;
    this.setState(popInRow, function () {
      //let _capa = Number(that.state.capacity);
      // 가능 : fromFlockInQtyF
      // Chickin : hh.chickinQty
      // Qty : 가능수량 < ==> 오류
      let _capaF =
        Number(that.state.fromFlockInQtyF) - Number(that.state.fromChickinQtyF);
      let _capaM =
        Number(that.state.fromFlockInQtyM) - Number(that.state.fromChickinQtyM);

      that.setState(
        {
          moveDate: that.state.moveDate
            ? that.state.moveDate
            : DateUtil.today(),
          capaF: _capaF,
          capaM: _capaM,
          inQtyF: _capaF,
          inQtyM: _capaM,
        },
        function () {
          that.fnCalcQty(that.state, '');
        }
      );
    });
  }
  // setOnChange = e => {
  //   const that = this;
  //   const col = e.target.name ? e.target.name : '';
  //   this.setState({ [col]: e.target.value }, function() {
  //     that.fnCalcQty(this.state, col);
  //   });
  // };
  fnCalcQty = (data, col) => {
    console.log(data);
    let inQtyF = Number(data.inQtyF ? data.inQtyF : 0);
    let inQtyM = Number(data.inQtyM ? data.inQtyM : 0);
    if (data.capaF < inQtyF) {
      this.props.alert.show(i18n.t('The quantity is exceeded.'));
      this.setState({ [col]: 0 });
      this.inQtyF.focus();
      return false;
    } else if (data.capaM < inQtyM) {
      this.props.alert.show(i18n.t('The quantity is exceeded.'));
      this.setState({ [col]: 0 });
      this.inQtyM.focus();
      return false;
    }

    // if (col === '') {
    //   //처음
    //   let _chickinQty =
    //     Number(data.chickinQtyF ? data.chickinQtyF : 0) +
    //     Number(data.chickinQtyM ? data.chickinQtyM : 0);

    //   this.setState({ inQty: _chickinQty }, function() {});
    // } else {
    let _inQty =
      Number(data.inQtyF ? data.inQtyF : 0) +
      Number(data.inQtyM ? data.inQtyM : 0);

    this.setState({ inQty: _inQty }, function () {});
    // }
  };
  fnSave = () => {
    console.log('fnSave', this.state);
    if (this.state.inQty > this.state.capacity) {
      this.setState({ visible: true });
    } else {
      this.setState({ visible: false });
      this.confirm(); //수량 정상이면 저장
    }
  };
  handleVisibleChange = (visible) => {
    if (!visible) {
      this.setState({ visible });
      return;
    }
    // Determining condition before show the popconfirm.
    // console.log(this.state.condition);
    // if (this.state.condition) {
    //   this.confirm(); // next step
    // } else {
    //   this.setState({ visible }); // show the popconfirm
    // }
  };
  confirm = () => {
    this.setState({ visible: false });

    let data = this.state;
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.moveDate = data.moveDate ? data.moveDate : DateUtil.today();
    data.flockId = data.fromFlockId;
    data.chickinQtyF = data.inQtyF; //입력받은 수량 ************
    data.chickinQtyM = data.inQtyM; //입력받은 수량 ************

    //console.log('saveChickInHh', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/saveChickInHh.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        //console.log('saveChickInHh', response.data);
        that.setState(
          {
            inQty: 0,
            inQtyF: 0,
            inQtyM: 0,
            chickinDate: '',
            moveDate: '',
          },
          function () {}
        );

        that.props.fnSearch();
        that.props.fnClosePop('popIn');
      })
      .catch(function (error) {
        return [];
      });
  };

  cancel = () => {
    this.setState({ visible: false });
  };

  setOnChange = (e, params, dateString) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    this.setState({ [col]: val }, function () {
      if (params.type === 'N') {
        that.fnCalcQty(that.state, col);
      }
    });
  };

  render() {
    if (!this.props.popIn) return null;
    let row = this.state;
    //console.log('PopChickinHh', row);
    return (
      <Fragment>
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            margin: 'auto',
            zIndex: 1000,
            backgroundColor: 'rgba(0,0,0, 0.5)',
          }}
        >
          <div
            style={{
              position: 'absolute',
              width: '850px',
              height: '360px',
              left: '15%',
              right: '15%',
              top: '15%',
              bottom: '15%',
              margin: 'auto',
              borderRadius: '10px',
              background: 'white',
            }}
          >
            <div className="pop_box" style={{ marginBottom: '1px' }}>
              <h2>
                <Trans>Chick In HH</Trans>
              </h2>
              <ul className="divide">
                <ul className="layer_list">
                  <li className="w100">
                    <span className="f_left">
                      {' '}
                      <Trans>Flock : {row.fromFlockId}</Trans>
                      {' / '}
                      <Trans>Hen House : {row.hhId}</Trans>
                    </span>
                    <div className="board_list w100">
                      <table>
                        <colgroup>
                          <col style={{ width: '11%' }} />
                          <col style={{ width: '11%' }} />
                          <col style={{ width: '12%' }} />
                          <col style={{ width: '11%' }} />
                          <col style={{ width: '11%' }} />
                          <col style={{ width: '12%' }} />
                          <col />
                        </colgroup>
                        <thead>
                          <tr>
                            <th colSpan="3">Female</th>
                            <th colSpan="3">Male</th>
                            <th rowSpan="2">Qty/Capa</th>
                          </tr>
                          <tr>
                            <th>
                              <Trans>Available</Trans>
                            </th>
                            <th>
                              <Trans>Chickin</Trans>
                            </th>
                            <th>
                              <Trans>Qty</Trans>
                            </th>
                            <th>
                              <Trans>Available</Trans>
                            </th>
                            <th>
                              <Trans>Chickin</Trans>
                            </th>
                            <th>
                              <Trans>Qty</Trans>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              {row.capaF ? row.capaF.toLocaleString() : 0}
                            </th>
                            <th>
                              {row.chickinQtyF
                                ? row.chickinQtyF.toLocaleString()
                                : 0}
                            </th>
                            <td>
                              {GridUtil.renderInput({
                                inputName: 'inQtyF', //*** */
                                type: 'N', //*** */
                                that: this, //*** */
                                statsForm: this.state, //*** */
                                setOnChange: this.setOnChange, //*** */
                                minVal: 0,
                                width: 100, // px제외 : 없으면 30 */
                              })}
                            </td>
                            <th>
                              {row.capaM ? row.capaM.toLocaleString() : 0}
                            </th>
                            <th>
                              {row.chickinQtyM
                                ? row.chickinQtyM.toLocaleString()
                                : 0}
                            </th>
                            <td>
                              {GridUtil.renderInput({
                                inputName: 'inQtyM', //*** */
                                type: 'N', //*** */
                                that: this, //*** */
                                statsForm: this.state, //*** */
                                setOnChange: this.setOnChange, //*** */
                                minVal: 0,
                                width: 100, // px제외 : 없으면 30 */
                              })}
                            </td>
                            <td>
                              <span>
                                {' '}
                                {this.state.inQty
                                  ? this.state.inQty.toLocaleString()
                                  : 0}{' '}
                                /{' '}
                                {this.state.capacity
                                  ? this.state.capacity.toLocaleString()
                                  : '0'}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <Trans>Date</Trans>
                            </th>
                            <td colSpan="6" style={{ textAlign: 'left' }}>
                              {GridUtil.renderInput({
                                inputName: 'moveDate', //*** */
                                type: 'D', //*** */
                                that: this, //*** */
                                statsForm: this.state, //*** */
                                setOnChange: this.setOnChange, //*** */
                                width: 120, // px제외 : 없으면 30 */
                                placeholder: 'moveDate',
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                </ul>
              </ul>
              <div className="f_right mT10">
                <button
                  className="btn_gray"
                  onClick={(e) => this.props.fnClosePop('popIn')}
                >
                  <Trans>Cancel</Trans>
                </button>
                <Popconfirm
                  title={i18n.t(
                    'The capacity has been exceeded. Would you like to save it anyway?'
                  )}
                  visible={this.state.visible}
                  onVisibleChange={this.handleVisibleChange}
                  onConfirm={this.confirm}
                  onCancel={this.cancel}
                  okText="OK"
                  cancelText="Cancel"
                >
                  <button
                    className="btn_save"
                    onClick={(e) => this.fnSave(row)}
                  >
                    <Trans>Save</Trans>
                  </button>
                </Popconfirm>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withAlert()(PopChickinHh);
