import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import { message } from 'antd';
import { withAlert } from 'react-alert';

import ScoreDtlExcel from './ScoreDtlExcel';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class ScoreDtl extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      // gridApi: null,
      gridHeight: '365px',
      regDate: DateUtil.toDateTimeString(),
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        suppressSizeToFit: true,
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      paginationPageSize: 10,
      // editType: 'fullRow', // selectEditor와 함께 사용하지 말라고 하네요...
      ColDefs: [
        {
          headerName: i18n.t('Age'),
          field: 'age',
          width: 100,
          minWidth: 100,
          cellClass: 'textFormat',
          // cellStyle: { textAlign: 'center' },
          getQuickFilterText: function (params) {
            return params.value;
          },
        },
        { field: 'countryCode', hide: true },
        { field: 'standardId', hide: true },
        {
          headerName: i18n.t('Depletion(%)'),
          // headerGroupComponent: FeedGroupComponent,
          children: [
            {
              headerName: i18n.t('Female'),
              field: 'depF',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Male'),
              field: 'depM',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
          ],
        },
        {
          headerName: i18n.t('Depletion(Cum)(%)'),
          // headerGroupComponent: VaccineGroupComponent,
          children: [
            {
              headerName: i18n.t('Female'),
              field: 'depCumF',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Male'),
              field: 'depCumM',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
          ],
        },
        {
          headerName: i18n.t('Feed Amount(g)'),
          children: [
            {
              headerName: i18n.t('Female'),
              field: 'feedAmtF',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Male'),
              field: 'feedAmtM',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
          ],
        },
        {
          headerName: i18n.t('Body Weight(g)'),
          children: [
            {
              headerName: i18n.t('Female'),
              field: 'bwF',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
            {
              headerName: i18n.t('Male'),
              field: 'bwM',
              width: 120,
              minWidth: 120,
              cellClass: 'textFormat',
            },
          ],
        },
        {
          headerName: i18n.t('FCR'),
          field: 'fcr',
          width: 120,
          minWidth: 120,
          cellClass: 'textFormat',
        },
        {
          headerName: i18n.t('EP(%)'),
          field: 'ep',
          width: 120,
          minWidth: 120,
          cellClass: 'textFormat',
        },
        {
          headerName: i18n.t('HE Cum(ea)'),
          field: 'heCum',
          width: 120,
          minWidth: 120,
          cellClass: 'textFormat',
        },
        {
          headerName: i18n.t('Egg Weight(g)'),
          field: 'eggWeight',
          width: 120,
          minWidth: 120,
          cellClass: 'textFormat',
        },
        {
          headerName: i18n.t('SC Cum(ea)'),
          field: 'scCum',
          width: 120,
          minWidth: 120,
          cellClass: 'textFormat',
        },
        {
          headerName: i18n.t('Hatch(%)'),
          field: 'hatch',
          width: 120,
          minWidth: 120,
          cellClass: 'textFormat',
        },
        {
          headerName: i18n.t('Body Weight(g)'),
          field: 'bw',
          width: 120,
          minWidth: 120,
          cellClass: 'textFormat',
        },
        {
          headerName: i18n.t('Depletion Cum'),
          field: 'depCum',
          width: 120,
          minWidth: 120,
          cellClass: 'textFormat',
        },
        {
          headerName: i18n.t('Intake Cum'),
          field: 'intakeCum',
          width: 120,
          minWidth: 120,
          cellClass: 'textFormat',
        },
        {
          headerName: i18n.t('Remarks'),
          field: 'remarks',
          width: 120,
          minWidth: 120,
          cellClass: 'textFormat',
        },
      ],
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
      scoreDtlExcelView: false,
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  // deplicated : UNSAFE_componentWillReceiveProps(nextProps)
  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log(prevProps.mastParams, this.props.mastParams);
    if (prevProps.mastParams !== this.props.mastParams) {
      return this.fnGetRowData(this.props.mastParams);
    }

    console.log(prevState.regDate, this.state.regDate);
    if (prevState.regDate !== this.state.regDate) {
      return this.fnGetRowData(this.props.mastParams);
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    // this.setState({ gridApi: params.api });
  };

  onChange = (info) => {
    console.log(info);
  };

  onBtExcelUpload = (e) => {
    this.setState({ scoreDtlExcelView: true });
  };

  onBtClose = (val) => {
    this.setState({ [val]: false });
  };

  onBtExport = () => {
    const fileName =
      i18n.t('Standard Score') + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t('Detail');
    const params = {
      columnWidth: 120,
      fileName: fileName,
      sheetName: sheetName,
      // exportMode: 'xml',
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  fnGetRowData = (params) => {
    const that = this;
    let url = Server.getRestAPI() + '/std/selectTcStandardDtl.do';
    axios({
      withCredentials: true,
      credentials: 'same-origin',
      method: 'post',
      url: url,
      data: qs.stringify(params),
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  fnSetRegDate = () => {
    const that = this;
    this.setState({ regDate: DateUtil.toDateTimeString() }, function () {});
  };

  render() {
    if (!this.props.mastSelected) return null;
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <div className="con_box">
          <div className="f_left mB20">
            <h3>Detail</h3>
          </div>
          <div className="f_right mB20">
            <button
              className="btn_sky"
              onClick={() => {
                location.href =
                  Server.getRestAPI() +
                  '/dl.do' +
                  '?fid=2&key=' +
                  Const.APP_DOWNLOAD_KEY;
              }}
            >
              <Trans>Excel Form Download</Trans>
            </button>
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
            <button className="btn_sky" onClick={this.onBtExcelUpload}>
              <Trans>Excel Upload</Trans>
            </button>
          </div>

          <div className="board_list_s">
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs}
                  // components={this.state.components}
                  // frameworkComponents={this.state.frameworkComponents}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  editType={this.state.editType}
                  // enterMovesDownAfterEdit={true}
                  // enterMovesDown={true}
                  // rowModelType={this.state.rowModelType}

                  excelStyles={this.state.excelStyles}
                  onGridReady={this.onGridReady}
                  onCellEditingStarted={this.onCellEditingStarted}
                  onCellEditingStopped={this.onCellEditingStopped}
                  onRowClicked={this.onRowSelected}
                  onRowSelected={this.onRowSelected}
                  onRowEditingStarted={this.onRowEditingStarted}
                  onRowEditingStopped={this.onRowEditingStopped}
                  onCellValueChanged={this.onCellValueChanged}
                  rowData={this.state.rowData}
                  pagination={true}
                  paginationPageSize={this.state.paginationPageSize}
                />
              </div>
            </div>
          </div>
        </div>

        <ScoreDtlExcel
          mastParams={this.props.mastParams}
          scoreDtlExcelView={this.state.scoreDtlExcelView}
          setRegDate={this.fnSetRegDate}
          fnClose={this.onBtClose}
        />

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
