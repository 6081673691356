import React, { Fragment } from 'react';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import { Const, Server } from 'components/Properties';
import { Popconfirm, Switch, message, DatePicker, Select, Modal } from 'antd';
import axios from 'axios';
import qs from 'qs';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import '../Hatchery.module.css';
import DiscardDocForm from './DiscardDocForm';
import DiscardList from './DiscardList';
import scrollToComponent from 'react-scroll-to-component';
const { confirm } = Modal;

/*
 ** Hatchery - Deliver - PsDiscardDoc(Trans)
 ** =============================
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class DocDiscard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInstance: this,
      searchFromWkDt: moment(DateUtil.today())
        .add('-1', 'M')
        .format('YYYY-MM-DD'),
      searchToWkDt: DateUtil.today(),
      form: [],
      discardDocFormView: false,
      dataListView: false,
      hatchingList: [],
      discardDocForm: [],
      rowData: [],
      columnDefs: [
        {
          headerName: i18n.t('Hatchery'),
          field: 'hatcheryId',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('Date'),
          field: 'discardDate',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('F Qty'),
          field: 'psdocFQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('M Qty'),
          field: 'psdocMQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        // {
        //   headerName: i18n.t('Sex Qty'),
        //   field: 'seQty',
        //   type: 'numericColumn',
        //   width: 100,
        //   valueFormatter: this.formatNumber,
        //   cellStyle: { textAlign: 'right' },
        //   suppressSizeToFit: true
        // },
        {
          headerName: i18n.t('S/P Qty'),
          field: 'spQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('A2 Qty'),
          field: 'a2Qty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('A1 Qty'),
          field: 'a1Qty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('BM Qty'),
          field: 'bmQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('B Qty'),
          field: 'bQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('Polos Qty'),
          field: 'polosQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: this.formatNumber,
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: i18n.t('Hatch Date'),
          field: 'hatchDate',
          width: 130,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: i18n.t('Remarks'),
          width: 200,
          field: 'remarks',
          cellStyle: { textAlign: 'left' },
        },
        {
          headerName: '',
          field: 'delete',
          suppressSizeToFit: false,
          width: 70,
          cellStyle: { textAlign: 'center' },
          editable: false,
          cellRenderer: (params) => {
            return '<button class="btn_sel">' + i18n.t('Delete') + '</button>';
          },
          suppressSizeToFit: true,
        },
      ],
      defaultColDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        suppressSizeToFit: false,
      },
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnSearch();
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (prevState !== nextProps) {
  //     prevState.currentInstance.fnSearch();
  //     return { nextProps };
  //   }
  //   return null;
  // }
  fnInit = () => {
    window.scrollTo(0, 0);
    this.setState(
      {
        discardDocFormView: false,
        dataListView: false,
      },
      function () {
        this.fnSearch();
      }
    );
  };
  fnSearch = () => {
    window.scrollTo(0, 0);
    this.setState(
      {
        discardDocFormView: true,
        dataListView: true,
        discardDocForm: {
          iuFlag: 'I',
          discardDate: DateUtil.today(),
          hatchDate: DateUtil.today(),
        },
      },
      function () {}
    );

    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.countryCode = SessionUtil.getCountryCode();
    data.searchFromWkDt = this.state.searchFromWkDt;
    data.searchToWkDt = this.state.searchToWkDt;

    console.log(data);
    //data.receiveNo = '';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/transfer/selectTransferDiscardList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            rowData: response.data.rows1,
          },
          function () {
            console.log('********>', that.state.rowData);
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  fnClickPsDoc = (e) => {
    this.setState(
      {
        broilerDocFormView: false,
        discardDocFormView: false,
        salableDocFormView: false,
        psDocFormView: true,
        psDocForm: {
          iuFlag: 'I',
          hatchDate: DateUtil.today(),
          transferDate: DateUtil.today(),
        },
      },
      function () {
        // scrollToComponent(this['psDocFormView'], {
        //   offset: 0,
        //   align: 'top',
        //   duration: 1000
        // });
      }
    );
  };

  fnClickDiscardDoc = (e) => {
    this.setState(
      {
        broilerDocFormView: false,
        discardDocFormView: true,
        psDocFormView: false,
        salableDocFormView: false,
        discardDocForm: {
          iuFlag: 'I',
          discardDate: DateUtil.today(),
          hatchDate: DateUtil.today(),
        },
      },
      function () {
        // scrollToComponent(this['discardDocFormView'], {
        //   offset: 0,
        //   align: 'top',
        //   duration: 1000
        // });
      }
    );
  };
  fnClosePopup = (val) => {
    window.scrollTo(0, 0);
    this.setState({ [val]: false }, function () {});
  };
  //Grid row 선택
  onCellClicked = (e) => {
    if (e.column.colId !== 'delete') return;

    let that = this;

    confirm({
      title: i18n.t('Are you sure delete this task?'),
      //icon: <ExclamationCircleOutlined />,
      //content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        that.fnDelete(e.data);
      },
      onCancel() {},
    });
  };
  fnDelete = (data) => {
    let that = this;

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.updateId = SessionUtil.getUserId();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.iuFlag = 'D';
    //console.log('data', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/transfer/savePsDiscardDoc.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.fnInit();
      })
      .catch(function (error) {
        return [];
      });
  };
  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  renderInput = (inputName, ty, dec, len) => {
    let cl = len ? 'input_type1 w' + len : 'input_type1';

    if (ty && ty === 'N') {
      cl = len ? 'input_base w' + len : 'input_base';
      return (
        <NumberFormat
          style={{ textAlign: 'right' }}
          className={cl}
          thousandSeparator={true}
          decimalScale={dec ? dec : 0}
          name={inputName}
          //ref={this[inputName]}
          getInputRef={(el) => (this[inputName] = el)}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : null
          }
          onChange={(e) => this.setOnChange(e, ty)}
        />
      );
    } else if (ty && ty === 'T') {
      return (
        <TimePicker
          //defaultValue={moment('12:08', 'HH:mm')}
          className={cl}
          format={'HH:mm'}
          ref={this[inputName]}
          value={
            this.state.form[inputName]
              ? moment(this.state.form[inputName], 'HH:mm') || ''
              : null
          }
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
        />
      );
    } else if (ty && ty === 'S') {
      return (
        <span>
          {this.state.form[inputName] ? this.state.form[inputName] || '' : ''}
        </span>
      );
    } else if (ty && ty === 'D') {
      return (
        <DatePicker
          name={inputName}
          //className={cl}
          style={{ width: len }}
          ref={this[inputName]}
          value={this.state[inputName] ? moment(this.state[inputName]) : ''}
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
          allowClear={false}
        />
      );
    } else {
      return (
        <input
          type="text"
          name={inputName}
          className={cl}
          ref={this[inputName]}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : ''
          }
          onChange={(e) => this.setOnChange(e)}
        />
      );
    }
  };
  setOnChange = (e, ty) => {
    const col = e.target.name ? e.target.name : '';
    //console.log('setOnChangeTime', e);
    //console.log('setOnChangeTime', e.target.value);
    //this.setState({ form[col]: e.target.value }, function() {});
    let val = e.target.value;
    if (ty === 'N') {
      val = val.replace(/,/gi, '');
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          [col]: val,
        },
      },
      function () {
        if (ty === 'N') {
          this.fnCalcEggGood();
        }
      }
    );
  };
  setOnChangeTime = (date, dateString, inputName) => {
    const col = inputName ? inputName : '';
    this.setState({ [col]: dateString }, function () {});
  };
  setOnChangeSelect = (value, col) => {
    this.setState(
      { form: { ...this.state.form, [col]: value } },
      function () {}
    );
  };
  render() {
    return (
      <Fragment>
        <h2>
          <Trans>Deliver - DOC Discard</Trans>
        </h2>
        <div className="con_box">
          {/* <DiscardList rowData={this.state.rowData} /> */}
          {/* <h3>
            <Trans>Deliver - Discard List</Trans>
          </h3> */}
          {/* {this.state.dataListView ? (
            <div
              id="myGrid"
              style={{
                height: '300px',
                width: '100%',
              }}
              className="ag-theme-balham"
            >
              <AgGridReact
                modules={this.state.modules}
                onGridReady={this.onGridReady}
                columnDefs={this.state.columnDefs}
                rowData={
                  this.state.rowData.length > 0 ? this.state.rowData : []
                }
                defaultColDef={this.state.defaultColDef}
                rowSelection={this.state.rowSelection}
                rowSelection={'single'}
                onCellClicked={(e) => this.onCellClicked(e)}
                //pagination={true}
                //paginationPageSize={this.state.paginationPageSize}
                onCellFocused={(e) => {
                  e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
                }}
              />
            </div>
          ) : null} */}

          <DiscardDocForm
            ref={(section) => {
              this.discardDocFormView = section;
            }}
            discardDocFormView={this.state.discardDocFormView}
            discardDocForm={this.state.discardDocForm}
            fnClose={this.fnClosePopup}
            fnInit={this.fnInit}
          />
        </div>
      </Fragment>
    );
  }
}
