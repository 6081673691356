import React, { Fragment } from 'react';
import { withAlert } from 'react-alert';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import { AgGridReact } from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { Modal, Button, DatePicker } from 'antd';
import NumberFormat from 'react-number-format';

import '../Broiler.module.css';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class HatcheryReceivedListDtl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: [],
      rcvDate: DateUtil.today(),
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    this.fnSearchStrain();
    this.fnSearchProject();
  }

  componentDidUpdate(prevProps) {
    let that = this;
    if (prevProps.transferInfo !== this.props.transferInfo) {
      this.setState(
        {
          form: {
            ...this.props.transferInfo,
          },
        },
        function () {
          that.fnSearchProject();
          if (that.props.iuFlag === 'I') {
            this.setState(
              {
                form: {
                  ...this.state.form,
                  recvQty: that.props.transferInfo.transferQty,
                  aQty: '0',
                  bQty: '0',
                  pQty: '0',
                  othersQty: '0',
                },
              },
              function () {}
            );
          }
        }
      );
    }
    if (prevProps.regDate !== this.props.regDate) {
      this.fnSearch();
    }
  }

  fnSearch = () => {
    let that = this;
    let data = this.props.transferInfo;
    data.dateFormat = SessionUtil.getDateFormat();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/receive/selectResisterInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState({ rowData: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };

  setOnChange = (e, params, dateString) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';

    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    this.setState(
      {
        form: {
          ...this.state.form,
          [col]: col,
          [col]: val,
        },
      },
      function () {}
    );
  };

  setOnChangeSelect = (value, col) => {
    this.setState(
      { form: { ...this.state.form, [col]: value } },
      function () {}
    );
  };

  onDateChange = (date, dateString, name) => {
    this.setState(
      { form: { ...this.state.form, [name]: dateString } },
      function () {}
    );
  };

  fnSearchStrain = () => {
    window.scrollTo(0, 0);

    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.farmClass = SessionUtil.getFarmClass();
    data.dateFormat = SessionUtil.getDateFormat();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.countryCode = SessionUtil.getCountryCode();
    data.hatchDate = DateUtil.today();
    data.codeId = 'EP26';
    //data.codeValue = "AND CODE_VALUE LIKE 'A%' ";

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/broiler/receive/selectCodeList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            strainCodeList: response.data.rows,
          },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });
  };

  fnSearchProject = () => {
    window.scrollTo(0, 0);

    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.hatchDate = DateUtil.today();

    if (this.props.iuFlag === 'U') {
      data.prevProjCode = this.props.transferInfo.projCode;
    }

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/broiler/receive/selectProjectListForUpdate.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        if (response.data.rows1.length > 0) {
          that.setState(
            {
              projectList: response.data.rows1,
            },
            function () {}
          );
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  fnClickSaveBtn = () => {
    let that = this;
    let params = this.state.form;

    params.farmCode = SessionUtil.getFarmCode();
    params.dateFormat = SessionUtil.getDateFormat();
    params.hatcheryId = this.props.transferInfo.hatcheryId;

    params.iuFlag = this.props.iuFlag;

    params.transferNo = this.props.transferInfo.transferNo;
    params.orderNo = this.props.transferInfo.orderNo;
    params.customerCode = this.props.transferInfo.customerCode;
    params.prevProjCode = this.props.transferInfo.projCode;

    if (params.hatcheryId === 'undefined') {
      params.hatcheryId = '';
    }

    if (!params.aQty) params.aQty = 0;
    if (!params.bQty) params.bQty = 0;
    if (!params.pQty) params.othersQty = 0;
    if (!params.othersQty) params.othersQty = 0;

    if (
      params.recvQty <= 0 ||
      params.recvQty > this.props.transferInfo.transferQty
    ) {
      that.props.alert.error(
        i18n.t('Please check Receive Qty.') // 입고 수량을 확인하세요
      );
      that.recvQty.focus();
      return false;
    }

    if (!params.projCode) {
      that.props.alert.error(i18n.t('Please select Project.')); // 프로젝트를 선택하세요
      that.projCode.focus();
      return false;
    }

    if (!params.strainCode) {
      that.props.alert.error(i18n.t('Please select Strain.')); // 품종을 선택하세요
      that.strainCode.focus();
      return false;
    }

    if (!params.receiveDate) {
      params.receiveDate = this.state.rcvDate;
    }

    if (
      Number(params.aQty) +
        Number(params.bQty) +
        Number(params.pQty) +
        Number(params.othersQty) >
      0
    ) {
      if (
        Number(params.aQty) +
          Number(params.bQty) +
          Number(params.pQty) +
          Number(params.othersQty) !=
        Number(params.recvQty)
      ) {
        that.props.alert.error(
          i18n.t('The sum of P, A, B, and Others must equal Receive Qty.') // P, A, B, Others 총 합은 Receive Qty와 같아야 합니다
        );
        return false;
      }
    }

    console.log('params', params);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/broiler/receive/saveHatcheryReceiveInfo.do',
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        that.props.fnSetRegDate();
        that.fnSearchProject();
        that.fnClear();
        if (data === 0)
          return that.props.alert.error(
            i18n.t(
              'The hatchery transfer information has been deleted and cannot be registered.'
            )
          ); // 이미 취소됨
        that.props.alert.show(i18n.t('Saved')); // 저장 성공
      })
      .catch(function (error) {
        that.props.alert.show(i18n.t('Save failed')); // 저장 실패
        return [];
      });
  };

  fnClickCancel = (e) => {
    this.props.fnClickCancel();
    this.fnClear();
  };

  fnClear = (e) => {
    if (this.props.iuFlag === 'I') {
      this.setState(
        {
          form: [],
        },
        function () {}
      );
    }
    if (this.props.iuFlag === 'U') {
      this.setState(
        {
          form: this.props.transferInfo,
        },
        function () {}
      );
    }
  };

  disabledDate = (current) => {
    var transferDate = new Date(this.props.transferInfo.transferDate);
    transferDate.setDate(transferDate.getDate() - 1);
    return current && current < moment(transferDate).endOf('day');
  };

  render() {
    // return null 은 아무것도 표시하지 않는다는 의미입니다.
    if (!this.props.hatcheryTransferListDtlView) return null;

    let strainOptions = [];
    if (this.state.strainCodeList && this.state.strainCodeList.length > 0) {
      strainOptions = this.state.strainCodeList.map((row, i) => {
        return (
          <Option key={i} value={row.codeValue}>
            {row.codeValue + ' - ' + row.codeText}
          </Option>
        );
      });
    }

    let projectOptions = [];
    if (this.state.projectList && this.state.projectList.length > 0) {
      projectOptions = this.state.projectList.map((row, i) => {
        return (
          <Option key={i} value={row.projCode}>
            {row.projCode}
          </Option>
        );
      });
    }

    return (
      <Fragment>
        {/* 디자인 영역 */}

        <div className="con_box">
          <h3>
            <Trans>Receiving Info</Trans>
          </h3>

          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '30%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th style={{ height: '45px' }}>
                    <Trans>Hatchery</Trans>
                  </th>
                  <td>
                    <span>{this.props.transferInfo.hatcheryId}</span>
                  </td>
                  <th>
                    <Trans>Strain</Trans>
                  </th>
                  <td>
                    <span>{this.props.transferInfo.strain}</span>
                  </td>
                </tr>

                <tr>
                  <th style={{ height: '45px' }}>
                    <Trans>Transfer No</Trans>
                  </th>
                  <td>
                    <span>{this.props.transferInfo.transferNo}</span>
                  </td>
                  <th>
                    <Trans>Order No</Trans>
                  </th>
                  <td>
                    <span>{this.props.transferInfo.orderNo}</span>
                  </td>
                </tr>

                <tr>
                  <th style={{ height: '45px' }}>
                    <Trans>Transfer Date</Trans>
                  </th>
                  <td>
                    <span>{this.props.transferInfo.transferDate}</span>
                  </td>
                  <th>
                    <Trans>Transfer Qty</Trans>
                  </th>
                  <td>
                    <span>
                      {this.props.transferInfo.transferQty.toLocaleString()}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="board_list2" style={{ marginTop: '20px' }}>
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '30%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>Receive Date</Trans>
                  </th>
                  <td>
                    <DatePicker
                      name="receiveDate"
                      style={{ width: 120 }}
                      value={
                        this.state.form.receiveDate
                          ? moment(this.state.form.receiveDate)
                          : moment(DateUtil.today())
                      }
                      onChange={(date, dateString) =>
                        this.onDateChange(date, dateString, 'receiveDate')
                      }
                      disabledDate={this.disabledDate}
                      allowClear={false}
                    />
                  </td>

                  <th>
                    <Trans>Receive Qty</Trans> <span className="red">*</span>{' '}
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'recvQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      maxVal: this.props.transferInfo.transferQty,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 80, // px제외 : 없으면 100% */
                    })}
                  </td>
                  <td className="spac10_left label">
                    <Trans>P</Trans>{' '}
                    {GridUtil.renderInput({
                      inputName: 'pQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      maxVal: this.props.transferInfo.transferQty,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 53, // px제외 : 없으면 100% */
                      height: 25,
                    })}{' '}
                    <span className="mL20"></span>
                    <Trans>A</Trans>{' '}
                    {GridUtil.renderInput({
                      inputName: 'aQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      maxVal: this.props.transferInfo.transferQty,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 53, // px제외 : 없으면 100% */
                      height: 25,
                    })}{' '}
                    <span className="mL20"></span>
                    <Trans>B</Trans>{' '}
                    {GridUtil.renderInput({
                      inputName: 'bQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      maxVal: this.props.transferInfo.transferQty,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 53, // px제외 : 없으면 100% */
                      height: 25,
                    })}{' '}
                    <span className="mL10"></span>
                    <Trans>Others</Trans>{' '}
                    {GridUtil.renderInput({
                      inputName: 'othersQty', //*** */
                      type: 'N', //*** */
                      that: this, //*** */
                      minVal: 0,
                      maxVal: this.props.transferInfo.transferQty,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 53, // px제외 : 없으면 100% */
                      height: 25,
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Project</Trans> <span className="red">*</span>{' '}
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'projCode', //*** */
                      type: 'SELECT', //*** */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      options: projectOptions, //*** */ type:'SELECT' options*/
                      placeholder: 'Project', //*** */
                      width: 210, // px제외 : 없으면 30 */
                    })}
                  </td>

                  <th>
                    <Trans>Strain</Trans> <span className="red">*</span>{' '}
                  </th>
                  <td colSpan="5">
                    {GridUtil.renderInput({
                      inputName: 'strainCode', //*** */
                      type: 'SELECT', //*** */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                      options: strainOptions, //*** */ type:'SELECT' options*/
                      placeholder: 'Strain', //*** */
                      width: 210, // px제외 : 없으면 30 */
                    })}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Trans>Truck No</Trans>
                  </th>
                  <td>
                    {GridUtil.renderInput({
                      inputName: 'truckNo', //*** */
                      type: '', //*** */
                      that: this, //*** */
                      maxLength: 10,
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      width: 120, // px제외 : 없으면 100% */
                    })}
                  </td>

                  <th>
                    <Trans>Remarks</Trans>
                  </th>
                  <td colSpan="5">
                    {GridUtil.renderInput({
                      inputName: 'remarks', //*** */
                      type: '', //*** */
                      //dec: 2,
                      maxLength: 1000,
                      //maxVal: 999.99,
                      //width: 70, // px제외 : 없으면 30 */
                      statsForm: this.state.form, //*** */
                      setOnChange: this.setOnChange, //*** */
                      that: this, //*** */
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="txt_center mT40">
            <button className="btn_gray" onClick={(e) => this.fnClickCancel()}>
              <Trans>Cancel</Trans>
            </button>
            {(this.props.iuFlag === 'I' || this.state.form.receiveYn === 0) && (
              <button
                className="btn_save"
                onClick={(e) => this.fnClickSaveBtn(e)}
              >
                <Trans>Save</Trans>
              </button>
            )}
          </div>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default withAlert()(HatcheryReceivedListDtl);
