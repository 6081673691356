import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, FormUtil, DateUtil, PagingUtil } from 'utils';
import { Trans } from 'react-i18next';
import { withAlert } from 'react-alert';

import { moment } from 'components';

import i18n from 'i18next';

// import PasswordEditor from './PasswordEditor';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import produce from 'immer';
import { Input, Select } from 'antd';
import { cloneDeep, remove } from 'lodash';

import UserRegist from './UserRegist';
import CountryCode from 'containers/pages/common/system/CountryCode';
import SearchInput from 'utils/SearchInput';

import '../Common.module.css';

class UserAccess extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [], // 전체 목록
      modules: AllModules,
      gridHeight: '700px',
      columnDefs: [
        {
          headerName: i18n.t('User Id'),
          field: 'userId',
          getQuickFilterText: function (params) {
            return params.value;
          },
        },
        // { headerName: i18n.t('User Name'), field: 'userNm', }, // 사용자명 지원을 위해서는 index mapping부터 변경되어야 함
        {
          headerName: i18n.t('From'),
          field: 'clientIp',
        },
        {
          headerName: i18n.t('DateTime'),
          field: 'serverTime',
        },
      ],
      defaultColDef: {
        filter: true,
        resizable: true,
        editable: false,
        sortable: true,
        suppressSizeToFit: false,
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagination: true,
      paginationPageSize: 20,
      // editType: 'fullRow', // selectEditor와 함께 사용하지 말라고 하네요...
    };
  }

  onGridReady = (params) => {
    // console.log('onGridReady:', params);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    // this.fnGetGroupRowData();
    // this.fnGetFarmCodeRowData(); // 대량의 데이타로 성능 이슈 있음.
    const { params } = this.state;
    this.fnGetRowData(params);
  }

  // 사용자 접속 정보 가져오기
  fnGetRowData = (params) => {
    const that = this;
    let url = Server.getRestAPI() + '/users/selectLoginSuccess.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        console.log(data);
        if (data) {
          that.setState(
            produce((draft) => {
              draft.rowData = data;
            }),
            function () {
              // console.log(this.state);
            }
          );
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onFilterTextBoxChanged = (e) => {
    const { value } = e.target;
    this.gridApi.setQuickFilter(value);
  };

  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Member - User Access Log</Trans>
        </h2>

        <div className="con_box">
          <div className="f_left mB20">
            <input
              type="text"
              className="input_type1"
              placeholder={i18n.t('Filter')}
              onChange={(e) => this.onFilterTextBoxChanged(e)}
            />
          </div>

          <div className="board_list_s">
            <div
              id="myGrid"
              style={{
                height: this.state.gridHeight,
                width: '100%',
              }}
              className="ag-theme-balham"
            >
              <AgGridReact
                modules={this.state.modules}
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                onGridReady={this.onGridReady}
                rowData={this.state.rowData}
                pagination={this.state.pagination}
                paginationPageSize={this.state.paginationPageSize}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withAlert()(UserAccess);
