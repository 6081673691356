import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import PsSalesList from './PsSalesList';
import PsSalesForm from '../release/PsSalesForm';
import PsMoveoutForm from '../release/PsMoveoutForm';
import PsDiscardForm from '../release/PsDiscardForm';
import scrollToComponent from 'react-scroll-to-component';
import '../Breeding.module.css';
/*
 ** Breeding > Release > Ps : Ps Release
 ** 출고
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class DeliverPsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salesListView: true,
      salesFormView: false,
      discardFormView: false,
      epDate: DateUtil.today(),
      psStock: [{ cullingStock: 0, closingStock: 0 }],
      form: [],
      prop: [],
      rowData: [],
      flockList: [],
      hhChickinList: [],
      salesForm: []
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnSearch();
  }
  fnSearch = () => {
    window.scrollTo(0, 0);
    //입력화면 들 숨기기
    this.setState(
      {
        salesFormView: false,
        salesListView: false,
        discardFormView: false
      },
      function() {
        this.setState({ salesListView: true }, function() {});
      }
    );

    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    console.log(data);
    //data.receiveNo = '';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/selectPsStock.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        //console.log('selectGlowingLogList', response.data);
        if (response.data.rows1.length > 0) {
          that.setState(
            {
              psStock: response.data.rows1[0],
              flockList: response.data.rows2,
              growingView: false,
              growingForm: []
            },
            function() {}
          );
        }
      })
      .catch(function(error) {
        return [];
      });
  };

  fnClickSales = e => {
    let that = this;

    this.setState(
      {
        discardFormView: false,
        salesFormView: true,
        salesForm: {
          ...this.state.salesForm,
          iuFlag: 'I',
          transferType: 'S',
          cullingStock: this.state.psStock.cullingStock
            ? this.state.psStock.cullingStock
            : 0,
          closingStock: this.state.psStock.closingStock
            ? this.state.psStock.closingStock
            : 0
        }
      },
      function() {
        // scrollToComponent(this['salesFormView'], {
        //   offset: 0,
        //   align: 'top',
        //   duration: 1000
        // });
      }
    );
  };
  fnClickDiscard = e => {
    this.setState(
      {
        salesFormView: false,
        discardFormView: true,
        discardForm: {
          ...this.state.discardForm,
          iuFlag: 'I',
          transferType: 'D',
          cullingStock: this.state.psStock.cullingStock
            ? this.state.psStock.cullingStock
            : 0,
          closingStock: this.state.psStock.closingStock
            ? this.state.psStock.closingStock
            : 0
        }
      },
      function() {
        // scrollToComponent(this['discardFormView'], {
        //   offset: 0,
        //   align: 'top',
        //   duration: 1000
        // });
      }
    );
  };
  fnClosePopup = val => {
    window.scrollTo(0, 0);
    this.setState({ [val]: false }, function() {});
  };
  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Deliver - PS Delivery List</Trans>
        </h2>

        <div className="con_box">
          {/*
          LNB측으로 이동되었습니다. by 김기철 2020.02.18
          <div className="txt_center mB20">
            <button className="btn_green2" onClick={() => this.fnClickSales()}>
              <i className="mdi mdi-logout-variant"></i>
              <Trans>Sales</Trans>
            </button>
            <button
              className="btn_green2"
              onClick={() => this.fnClickDiscard()}
            >
              <i className="mdi mdi-layers-off"></i>
              <Trans>Discard</Trans>
            </button>
          </div>
        */}

          <h3>
            <Trans>Salable Qty</Trans>
          </h3>
          <div className="board_list">
            <table>
              <colgroup>
                <col style={{ width: '50%' }} />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <Trans>Closing</Trans>
                  </th>
                  <th>
                    <Trans>Culling</Trans>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {this.state.psStock.closingStock
                      ? this.state.psStock.closingStock.toLocaleString()
                      : ''}
                  </td>
                  <td>
                    {this.state.psStock.cullingStock
                      ? this.state.psStock.cullingStock.toLocaleString()
                      : ''}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="con_box">
          <PsSalesList
            salesListView={this.state.salesListView}
            fnSearch={this.fnSearch} //삭제기능 후 다시 조회
          />
          <PsSalesForm
            ref={section => {
              this.salesFormView = section;
            }}
            salesFormView={this.state.salesFormView}
            salesForm={this.state.salesForm}
            fnClose={this.fnClosePopup}
            fnSearch={this.fnSearch}
          />
          <PsMoveoutForm
            ref={section => {
              this.moveoutFormView = section;
            }}
            moveoutFormView={this.state.moveoutFormView}
            moveoutForm={this.state.moveoutForm}
            fnClose={this.fnClosePopup}
            fnSearch={this.fnSearch}
          />
          <PsDiscardForm
            ref={section => {
              this.discardFormView = section;
            }}
            discardFormView={this.state.discardFormView}
            discardForm={this.state.discardForm}
            fnClose={this.fnClosePopup}
            fnSearch={this.fnSearch}
          />
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
