import * as Mobile from 'containers/pages/mobile';
import i18n from 'i18next';

const MobileRouteList = [
  { uri: '/mobile/login', page: Mobile.Login.MobileLogin },

  { uri: '/mobile/broiler/home', page: Mobile.Broiler.Home },
  {
    uri: '/mobile/broiler/rcv/selectHatchery',
    page: Mobile.Broiler.BroilerRcvSelectHatchery,
  },
  {
    uri: '/mobile/broiler/rcv/hatchery',
    page: Mobile.Broiler.BroilerRcvFromHatchery,
  },
  {
    uri: '/mobile/broiler/rcv/supplier',
    page: Mobile.Broiler.BroilerRcvFromSupplier,
  },
  {
    uri: '/mobile/broiler/deliver',
    page: Mobile.Broiler.Deliver,
  },
  {
    uri: '/mobile/broiler/deliverForm',
    page: Mobile.Broiler.DeliverForm,
  },
  {
    uri: '/mobile/broiler/grow',
    page: Mobile.Broiler.Growing,
  },
  {
    uri: '/mobile/broiler/growForm',
    page: Mobile.Broiler.GrowingForm,
  },
  {
    uri: '/mobile/broiler/material',
    page: Mobile.Broiler.Material,
  },
  {
    uri: '/mobile/broiler/materialForm',
    page: Mobile.Broiler.MaterialForm,
  },
];

export default MobileRouteList;
