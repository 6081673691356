import React, { Fragment } from 'react';
import { withAlert } from 'react-alert';
import { SessionUtil, DateUtil, GridUtil, FormUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

import { AgGridReact } from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { Modal, Button, DatePicker } from 'antd';
import NumberFormat from 'react-number-format';

import '../Hatchery.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class FarmReceivedListDtl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      rowData: [],
      form: [],
      arr: [],
      iuFlag: 'I',
      rcvDate: DateUtil.today(),
      gridHeight: '250px',
      rowSelection: 'single',
      enableCellChangeFlash: true,
      columnDefs: [
        {
          headerName: i18n.t('Farm'),
          field: 'farmName',
          width: 150,
          suppressSizeToFit: false,
          editable: false,
        },
        {
          headerName: i18n.t('Flock'),
          field: 'flockId',
          width: 150,
          suppressSizeToFit: false,
          editable: false,
        },
        {
          headerName: i18n.t('Hen House'),
          field: 'hhId',
          width: 150,
          suppressSizeToFit: false,
          editable: false,
        },
        {
          headerName: i18n.t('Transfer Qty'),
          field: 'goodEggQty',
          width: 150,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          suppressSizeToFit: false,
          editable: false,
        },
        {
          headerName: i18n.t('Strain'),
          field: 'strain',
          width: 150,
          suppressSizeToFit: false,
          editable: false,
        },
        {
          headerName: i18n.t('Received'),
          field: 'received',
          width: 150,
          suppressSizeToFit: false,
          editable: false,
        },
        {
          headerName: i18n.t('Receive Qty'),
          field: 'receiveQty',
          suppressSizeToFit: false,
          singleClickEdit: true,
          enterMovesDown: true,
          width: 150,
          cellStyle: { textAlign: 'right' },
          editable: function (params) {
            if (
              (params.data.iuFlag === 'I' && params.data.received === 'N') ||
              (Number(params.data.gradeYn) != 0 && params.data.iuFlag === 'U')
            ) {
              return true;
            } else {
              return false;
            }
          },
          valueFormatter: function (params) {
            if (isNaN(params.data.receiveQty) === false)
              return GridUtil.formatNumber(params);
          },
          cellStyle: function (params) {
            if (
              (params.data.iuFlag === 'I' && params.data.received === 'N') ||
              (Number(params.data.gradeYn) != 0 && params.data.iuFlag === 'U')
            ) {
              const w = params.column.actualWidth;
              return FormUtil.getEditCell('E', w);
            }
          },
        },
      ],
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.transferInfo !== this.props.transferInfo) {
      this.fnSearch();
    }
  }

  fnSearch = () => {
    let that = this;
    let data = this.props.transferInfo;
    data.dateFormat = SessionUtil.getDateFormat();
    data.countryCode = SessionUtil.getCountryCode();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/receive/selectResisterInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('response.data.rows1', response.data.rows1);
        response.data.rows1.map((row, i) => {
          row.iuFlag = that.props.iuFlag;
        });
        if (that.props.iuFlag === 'I') {
          response.data.rows1.map((row, i) => {
            if (row.received === 'N') {
              row.receiveQty = row.goodEggQty;
            }
          });
        }
        that.setState({ rowData: response.data.rows1 }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };

  onCellValueChanged = async (params) => {
    const fieldId = params.column.getId(); // 변경된 cell의 field 값이 전달된다.
    const that = this;

    // newValue의 경우 editType: 'fullRow'아닌 경우에만 의미가 있습니다.
    // fullRow 의 경우 data에 이미 변경된 값이 전달 됩니다.
    // 이 경우 data에 검증이 필요합니다. UNDO할 것 인가? APPLY할 것 인가?
    const { data, newValue } = params;
    let param = _.cloneDeep(data);

    param[fieldId] = newValue;

    this.state.rowData.map((row, i) => {
      if (
        row.farmCode === param.farmCode &&
        row.flockId === param.flockId &&
        row.hhId === param.hhId
      ) {
        that.state.rowData.splice(i, 1);
      }
    });
    this.state.rowData.push(param);
  };

  fnClickSaveBtn = (e) => {
    let that = this;
    let data = {};
    let chkValidation = 'Y';

    data.receiveDate = this.state.form.receiveDate;
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.dateFormat = SessionUtil.getDateFormat();
    data.farmPoFlag = 'F';
    data.receiveDate = this.state.form.receiveDate;

    if (!data.receiveDate) {
      data.receiveDate = this.state.rcvDate;
    }

    if (data.receiveDate > this.state.rcvDate) {
      this.props.alert.error(i18n.t('Receive date must earlier than today'));
      return false;
    }

    if (data.receiveDate < this.props.transferInfo.transferDate) {
      this.props.alert.error(
        i18n.t('Receive date must later than transfer date')
      );
      return false;
    }

    let receiveInfoList = [];

    this.state.rowData.forEach((row) => {
      let dataDef = {};

      if (row.received === 'N' || row.iuFlag === 'U') {
        dataDef = row;
        data.iuFlag = row.iuFlag;
        data.transferNo = row.transferNo;
        data.hhId = row.hhId;
        data.flockId = row.flockId;
        data.eggReceiveNo = row.eggReceiveNo;

        if (isNaN(dataDef.receiveQty)) {
          this.props.alert.error(
            i18n.t('You can enter only the number in Receive Qty.')
          );
          chkValidation = 'N';
        }
        if (dataDef.goodEggQty < dataDef.receiveQty || dataDef.receiveQty < 0) {
          this.props.alert.error(i18n.t('Please check Receive Qty.'));
          chkValidation = 'N';
        }
        if (dataDef.receiveQty === '') {
          chkValidation = 'N';
        }

        dataDef.receiveQty = Number(dataDef.receiveQty);

        receiveInfoList.push(dataDef);
      }
    });

    if (chkValidation === 'N') {
      return false;
    }

    data.receiveInfoList = receiveInfoList;

    data.receiveInfoListLength = receiveInfoList.length;

    console.log('data111', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/receive/saveReceiveInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        that.fnSearch(data);
        that.props.fnSetRegDate();
        that.fnClickClearBtn();
        that.setState({ arr: [] }, function () {});
        if (data === 0)
          return that.props.alert.error(
            i18n.t(
              'Farm transfer information has been deleted and cannot be registered.'
            )
          );
        that.props.alert.show(i18n.t('Saved'));
      })
      .catch(function (error) {
        that.props.alert.error(i18n.t('Save failed'));
        return [];
      });
  };

  fnClickCancel = (e) => {
    this.props.fnClickCancel();
  };

  fnClickClearBtn = (e) => {
    let that = this;
    this.setState(
      {
        form: [],
      },
      function () {}
    );
  };

  onDateChange = (date, dateString, name) => {
    this.setState(
      { form: { ...this.state.form, [name]: dateString } },
      function () {}
    );
  };

  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();

    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      params.api.setRowData(data.slice(0, 100));
    };
  };

  render() {
    // return null 은 아무것도 표시하지 않는다는 의미입니다.
    // if (!this.props.localeListView) return null;

    const { visible, loading } = this.state;
    if (!this.props.receivingFormView) return null;

    console.log('props', this.props);
    return (
      <Fragment>
        {/* 디자인 영역 */}

        <div className="con_box">
          <h3>
            <Trans>Receiving Form</Trans>
          </h3>
          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th style={{ height: '45px' }}>
                    <Trans>Date</Trans>
                  </th>
                  <td>
                    <DatePicker
                      name="receiveDate"
                      style={{ width: 120 }}
                      value={
                        this.state.form.receiveDate
                          ? moment(this.state.form.receiveDate)
                          : moment(DateUtil.today())
                      }
                      onChange={(date, dateString) =>
                        this.onDateChange(date, dateString, 'receiveDate')
                      }
                      allowClear={false}
                    />
                  </td>
                  <th>
                    <Trans>Qty</Trans>
                  </th>
                  <td>
                    <span>{this.props.transferQty}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="board_list2" style={{ marginTop: '15px' }}>
            <div
              id="myGrid"
              style={{
                height: this.state.gridHeight,
                width: '100%',
              }}
              className="ag-theme-balham"
            >
              <AgGridReact
                onGridReady={(e) => this.onGridReady(e, 'gridApiSettingInfoS')}
                columnDefs={this.state.columnDefs}
                rowData={this.state.rowData}
                onCellValueChanged={this.onCellValueChanged}
                stopEditingWhenGridLosesFocus={true}
                rowSelection={this.state.rowSelection}
              ></AgGridReact>
            </div>
          </div>

          <div className="txt_center mT40">
            <button className="btn_gray" onClick={(e) => this.fnClickCancel()}>
              <Trans>Cancel</Trans>
            </button>
            {((this.props.iuFlag === 'I' &&
              this.props.transferInfo.received === 'N') ||
              (Number(this.props.transferInfo.gradeYn) != 0 &&
                this.props.iuFlag === 'U')) && (
              <button
                className="btn_save"
                onClick={(e) => this.fnClickSaveBtn(e)}
              >
                <Trans>Save</Trans>
              </button>
            )}
          </div>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default withAlert()(FarmReceivedListDtl);
