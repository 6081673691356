import React, { Fragment } from 'react';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import MoveoutList from './MoveoutList';
import MoveinListGrid from './MoveinListGrid';
import HhMoveinList from './HhMoveinList';
//import ChickinHhList from './ChickinHhList';
//import PopFlock from './ZPopFlock';
import MoveinForm from './MoveinForm';
import PopMoveIn from './PopMoveIn'; //계군선택 - 계사선택 Move click
//import PopChickinHh from './PopChickinHh'; //계군선택 - 계사선택 In button click
//import MovingLogList from './MovingLogList'; //
import { withAlert } from 'react-alert';

import '../Breeding.module.css';
/*
 ** breeding/receive/HenHouse
 ** 계사(HenHouse-HH)입식
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class MoveIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      moveinFormView: false, //
      searchFromWkDt: moment(DateUtil.today())
        .add('-1', 'M')
        .format('YYYY-MM-DD'),
      searchToWkDt: DateUtil.today(),
      moveinForm: [], //
      form: {},
      prop: {},
      rowData: [],
      flockList: [],
      hhChickinList: [],
      movingLogList: [],
      hhInView: false, //HenHouseFlockList 에서 Chick in HH click
      popMoveInView: false,
      popEmpty: false,
      popIn: false,
      popFlock: false,
      hhFlockId: '', //Flock click
      flockListFlockId: '', //
      popInRow: {},
      popMoveRow: {},
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.setState(
      {
        searchFromWkDt: moment(DateUtil.today())
          .add('-1', 'M')
          .format('YYYY-MM-DD'),
        searchToWkDt: DateUtil.today(),
      },
      function () {}
    );

    this.fnSearch();
  }
  fnInit = () => {
    this.setState(
      {
        moveinFormView: false, //*** */
        hhChickinList: [],
        moveoutList: [],
        moveinList: [],
        moveinRow: [], //button - Move In
        //flockListFlockId: '', //button - Move In
        flockListTransferNo: '', //button - Move In
      },
      function () {
        this.fnSearch();
      }
    );
  };
  fnSearch = () => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.countryCode = SessionUtil.getCountryCode();
    data.dateFormat = SessionUtil.getDateFormat();

    data.searchFromWkDt = this.state.searchFromWkDt;
    data.searchToWkDt = this.state.searchToWkDt;

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/selectMoveoutList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            //hhChickinList: response.data.rows1,
            moveoutList: response.data.rows2,
            moveinList: response.data.rows3,
          },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });
    // axios({
    //   method: 'post',
    //   url: url + '/breed/receive/selectFlockList.do',
    //   params: data
    // })
    //   .then(function(response) {
    //     _flockList = response.data.rows1;
    //   })
    //   .catch(function(error) {
    //     return [];
    //   });

    // axios({
    //   method: 'post',
    //   url: url + '/breed/receive/selectMovingLog.do',
    //   params: data
    // })
    //   .then(function(response) {
    //     _movingLogList = response.data.rows1;
    //   })
    //   .catch(function(error) {
    //     return [];
    //   });

    //   that.setState({hhChickinList: _hhChickinList,
    //     flockList: _flockList,
    //     movingLogList: _movingLogList},
    //     function() {}
    //   );
  };
  //Moveout List의 Move In 버튼 click
  //넣을수 없는 계사 = 다른 계군이 들어있는 계사는 회색
  //넣을수 있는 계사 = 같은 계군이 들어있는 계사, 비어있는 계사 는 In
  fnClickListMovein = async (row) => {
    row.fromMoveQtyF = row.moveQtyF;
    row.fromMoveQtyM = row.moveQtyM;
    row.fromMoveDate = row.transferDate;
    row.moveDate = DateUtil.today();
    row.hhId = '';
    row.flockId = '';
    console.log('movein', row);

    await this.setState({ moveinFormView: false });
    this.setState(
      {
        moveinFormView: true, //*** */
        moveinForm: row, //*** */

        moveinRow: row, //Move In

        hhInView: true, //HenHouseFlockList 에서 Chick in HH click
        flockListFlockId: row.flockId,
        flockListTransferNo: row.transferNo,
        hhId: '', //Flcok clcik
      },
      function () {}
    );
  };
  //HenHouseFlockList 에서 Chick in HH click
  //hhFlockList - Cancel click
  fnClickFlockCancel = (row) => {
    this.setState({ hhInView: false, hhFlockId: '' }, function () {});
  };
  fnClickCancel = (row) => {
    this.setState({ hhFlockId: '' }, function () {});
  };
  fnClosePopFlock = (e, data) => {
    this.setState({ [data]: false }, function () {});
  };
  fnClosePop = (data) => {
    this.setState({ [data]: false, moveinForm: [] }, function () {});
  };
  fnClickMovein = (row, moveinRow) => {
    //Move In click - In click
    row.fromHhId = moveinRow.hhId;
    row.fromTransferNo = moveinRow.transferNo;
    row.fromFlockId = moveinRow.flockId;
    row.fromStrainCode = moveinRow.strainCode;
    row.fromChickinDate = moveinRow.chickinDate;
    row.fromCapacity = moveinRow.capacity;
    row.fromMoveQtyF = moveinRow.moveQtyF;
    row.fromMoveQtyM = moveinRow.moveQtyM;
    row.moveQtyF = moveinRow.moveQtyF;
    row.moveQtyM = moveinRow.moveQtyM;

    row.moveDate = DateUtil.today();
    //row.moveQtyF = 0;
    //row.moveQtyM = 0;

    console.log('fnClickMovein', row);
    this.setState({ popMoveInView: true, popMoveRow: row }, function () {});
    //}
  };
  //HenHouseFlockList 에서 Chick in HH click
  //click In
  fnClickIn = (row, moveinRow) => {
    row.fromHhId = moveinRow.hhId;
    row.fromFlockId = moveinRow.flockId;
    row.fromChickinDate = moveinRow.chickinDate;
    row.fromChickinQtyF = moveinRow.chickinQtyF;
    row.fromChickinQtyM = moveinRow.chickinQtyM;
    row.fromCapacity = moveinRow.capacity;
    //row.chickinQtyF = 0;
    //row.chickinQtyM = 0;
    console.log('fnClickIn', this.state);
    this.setState({ popIn: true, popInRow: row }, function () {});
    //}
  };
  //넣을수 없는 계사 = 다른 계군이 들어있는 계사는 회색
  //넣을수 있는 계사 = 같은 계군이 들어있는 계사, 비어있는 계사 는 In
  fnClickMove = (row) => {
    console.log('fnClickMove', row);

    this.setState(
      {
        hhId: row.hhId, //Hen House Move click
        hhFlockId: row.flockId,
        moveinRow: row,
      },
      function () {}
    );
  };
  setOnChange = (e, params, dateString) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';

    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }
    this.setState({ [col]: val }, function () {});
  };
  render() {
    return (
      <Fragment>
        <h2>
          <Trans>Move In</Trans>
        </h2>

        <div className="con_box">
          <MoveoutList
            moveoutList={this.state.moveoutList}
            flockButtonLabel={this.state.flockButtonLabel}
            flockMsg={this.state.flockMsg}
            fnClickListMovein={this.fnClickListMovein} //Move in - click
            fnInit={this.fnInit} //Cancel - click
            //fnClickFlockCancel={this.fnClickFlockCancel}
            //flockListFlockId={this.state.flockListFlockId}
            flockListTransferNo={this.state.flockListTransferNo}
          />
          {this.state.moveinFormView ? (
            <MoveinForm
              form={this.state.moveinForm}
              moveinFormView={this.state.moveinFormView}
              fnClose={this.fnClosePop}
              fnInit={this.fnInit}
            />
          ) : null}

          {/* Hen House */}
          {/* <ul className="divide">
            <HhMoveinList
              hhMoveView={this.state.hhMoveView}
              hhChickinList={this.state.hhChickinList}
              flockListFlockId={this.state.flockListFlockId}
              hhId={this.state.hhId}
              moveinRow={this.state.moveinRow}
              //fnClickMove={this.fnClickMove}
              fnClickMovein={this.fnClickMovein}
              fnClickCancel={this.fnClickCancel}
            />
          </ul> */}

          <div className="f_left mT20">
            <h3>
              <Trans>Move In List</Trans>
            </h3>
          </div>
          <div className="f_right mT20">
            {GridUtil.renderInput({
              inputName: 'searchFromWkDt', //*** */
              type: 'D', //*** */
              that: this, //*** */
              statsForm: this.state, //*** */
              setOnChange: this.setOnChange, //*** */
              width: 120, // px제외 : 없으면 100% */
              placeholder: 'Date',
            })}
            &nbsp; ~ &nbsp;
            {GridUtil.renderInput({
              inputName: 'searchToWkDt', //*** */
              type: 'D', //*** */
              that: this, //*** */
              statsForm: this.state, //*** */
              setOnChange: this.setOnChange, //*** */
              width: 120, // px제외 : 없으면 100% */
              placeholder: 'Date',
            })}
            <button className="btn_gray" onClick={() => this.fnInit()}>
              <Trans>Search</Trans>
            </button>
          </div>

          <MoveinListGrid
            moveinList={this.state.moveinList}
            fnInit={this.fnInit}
          />
        </div>
        {/* <PopFlock
          popFlock={this.state.popFlock}
          fnClosePopFlock={this.fnClosePopFlock}
        /> */}
        {/* //HenHouseFlockList 에서 Chick in HH click
        //click In */}
        {/* <PopChickinHh
          popIn={this.state.popIn}
          popInRow={this.state.popInRow}
          fnSearch={this.fnSearch}
          fnClosePop={this.fnClosePop}
          />
        */}

        <PopMoveIn
          popMoveInView={this.state.popMoveInView}
          popMoveRow={this.state.popMoveRow}
          fnInit={this.fnInit}
          fnClosePop={this.fnClosePop}
        />
      </Fragment>
    );
  }
}
export default withAlert()(MoveIn);
