import * as Hatchery from 'containers/pages/hatchery';
import * as Common from 'containers/pages/common';
import * as Report from 'containers/pages/report';
import i18n from 'i18next';

const HatcheryRouteList = [
  {
    uri: '/hatchery/dashboard',
    page: Hatchery.Dashboard.Dashboard,
  },
  {
    uri: '/hatchery/receive/rcvfarm/0',
    page: Hatchery.Receive.RcvFarm,
  },
  {
    uri: '/hatchery/receive/rcvfarm/1',
    page: Hatchery.Receive.RcvFarm1,
  },
  {
    uri: '/hatchery/receive/rcvfarm/2',
    page: Hatchery.Receive.RcvFarm2,
  },
  {
    uri: '/hatchery/receive/mng/0',
    page: Hatchery.Receive.MngPage0,
  },
  {
    uri: '/hatchery/receive/mng/1',
    page: Hatchery.Receive.MngPage1,
  },

  {
    uri: '/hatchery/grade/grade/0',
    page: Hatchery.Grade.Grade,
  },

  {
    uri: '/hatchery/grade/discard',
    page: Hatchery.Grade.GradeDiscard,
  },

  {
    uri: '/hatchery/receive/rcvpo',
    page: Hatchery.Receive.RcvPO,
  },
  {
    uri: '/hatchery/receive/list/0',
    page: Hatchery.Receive.ListPage0,
  },

  {
    uri: '/hatchery/move/in/0',
    page: Hatchery.Move.MoveIn,
  },
  {
    uri: '/hatchery/move/out/0',
    page: Hatchery.Move.MoveOut,
  },

  {
    uri: '/hatchery/setting/0',
    page: Hatchery.Setting.SettingPage0,
  },
  {
    uri: '/hatchery/setting/discard',
    page: Hatchery.Setting.SettingDiscard,
  },
  {
    uri: '/hatchery/setting/1',
    page: Hatchery.Setting.SettingPage1,
  },

  {
    uri: '/hatchery/candling/candling',
    page: Hatchery.Candling.Candling,
  },
  {
    uri: '/hatchery/candling/discard',
    page: Hatchery.Candling.CandlingDiscard,
  },
  {
    uri: '/hatchery/candling/0',
    page: Hatchery.Candling.CandlePage0,
  },

  {
    uri: '/hatchery/hatching/hatcherGPS',
    page: Hatchery.Hatcher.HatcherGPS,
  },
  {
    uri: '/hatchery/hatching/hatcherPS',
    page: Hatchery.Hatcher.HatcherPS,
  },
  {
    uri: '/hatchery/hatching/discard',
    page: Hatchery.Hatcher.HatchingDiscard,
  },
  {
    uri: '/hatchery/hatching/0',
    page: Hatchery.Hatcher.HatcherPage0,
  },

  {
    uri: '/hatchery/deliver/eggSales',
    page: Hatchery.Deliver.EggSales,
  },
  {
    uri: '/hatchery/transfer/egg/0',
    page: Hatchery.Deliver.EggPage0,
  },
  {
    uri: '/hatchery/transfer/egg/1',
    page: Hatchery.Deliver.EggPage1,
  },
  {
    uri: '/hatchery/transfer/egg/2',
    page: Hatchery.Deliver.EggPage2,
  },

  // {
  //   uri: '/hatchery/transfer/chick',
  //   page: Hatchery.Deliver.TransChick,
  // },
  {
    uri: '/hatchery/deliver/psDoc',
    page: Hatchery.Deliver.PsDoc,
  },
  {
    uri: '/hatchery/deliver/broilerDoc',
    page: Hatchery.Deliver.BroilerDoc,
  },
  {
    uri: '/hatchery/deliver/broilerDocSales',
    page: Hatchery.Deliver.BroilerDocSales,
  },
  {
    uri: '/hatchery/deliver/docDiscard',
    page: Hatchery.Deliver.DocDiscard,
  },

  {
    uri: '/hatchery/transfer/chick/0',
    page: Hatchery.Deliver.ChickPage0,
  },
  {
    uri: '/hatchery/transfer/chick/1',
    page: Hatchery.Deliver.ChickPage1,
  },
  {
    uri: '/hatchery/transfer/chick/2',
    page: Hatchery.Deliver.ChickPage2,
  },
  {
    uri: '/hatchery/transfer/chick/3',
    page: Hatchery.Deliver.ChickPage3,
  },
  {
    uri: '/hatchery/transfer/chick/4',
    page: Hatchery.Deliver.ChickPage4,
  },
  {
    uri: '/hatchery/transfer/chick/5',
    page: Hatchery.Deliver.ChickPage5,
  },

  {
    uri: '/hatchery/cutting/cutting',
    page: Hatchery.Cutting.Cutting,
  },
  {
    uri: '/hatchery/cutting/cuttingLists',
    page: Hatchery.Cutting.CuttingLists,
  },
  {
    uri: '/hatchery/cutting/0',
    page: Hatchery.Cutting.CuttingPage0,
  },
  {
    uri: '/hatchery/report/hatDocTermHhR',
    page: Report.Breeding.HatDocTermHhR, // Breeding의 리포트를 공유합니다.
  },
  {
    uri: '/hatchery/report/hatEpDailyHhR',
    page: Report.Breeding.HatEpDailyHhR, // Breeding의 리포트를 공유합니다.
  },
  {
    uri: '/hatchery/report/hatEpTermHhR',
    page: Report.Breeding.HatEpTermHhR, // Breeding의 리포트를 공유합니다.
  },
  {
    uri: '/hatchery/report/hatEpWklyHhR',
    page: Report.Breeding.HatEpWklyHhR, // Breeding의 리포트를 공유합니다.
  },
  {
    uri: '/hatchery/report/hatStkTermHhR1',
    page: Report.Breeding.HatStkTermHhR1, // Breeding의 리포트를 공유합니다.
  },
  {
    uri: '/hatchery/report/hatStkTermHhR2',
    page: Report.Breeding.HatStkTermHhR2, // Breeding의 리포트를 공유합니다.
  },
];

export default HatcheryRouteList;
