import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, FormUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';

// import PasswordEditor from './PasswordEditor';
import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import produce from 'immer';
import { Tag, Divider, Popover, Input, Select } from 'antd';
import { cloneDeep, remove } from 'lodash';
import { withAlert } from 'react-alert';

import CountryCode from 'containers/pages/common/system/CountryCode';
import UserLevel from 'containers/pages/common/member/UserLevel';
import SearchInput from 'utils/SearchInput';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 **
 ** CellEditing 기능 : https://www.ag-grid.com/javascript-grid-cell-editing
 */
class UserRegist extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: 'New User',
      regDate: DateUtil.toDateTimeString(),
      visable: false,
      oldParams: {},
      params: {}, // 등록시 전달할 데이타
      groupIds: [], // (중요) 수정 SelectBox 에서 사용할 Group Id만을 가진 목록 입니다.
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.fnGetGroupRowData({});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.rowNode !== this.props.rowNode) {
      // console.log( prevProps.rowNode, this.props.rowNode, _.isEmpty(this.props.rowNode));
      let title = 'Modify User';
      if (_.isEmpty(this.props.rowNode)) title = 'New User';
      // this.setState({ title });
      let { rowNode } = this.props;
      this.setState(
        produce((draft) => {
          draft.title = title;
          draft.params = rowNode;
          draft.oldParams = draft.params;
        }),
        function () {
          console.log('changed params : ', this.state.params);
        }
      );
    }
  }

  // Group 정보 가져오기
  fnGetGroupRowData = (params) => {
    const that = this;
    let url = Server.getRestAPI() + '/users/selectGroup.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        // console.log('[response.data]:', response.data);
        const { data } = response;
        let rows = [];
        data.map((row) => rows.push(row.userGroupId));
        that.setState({ groupIds: rows });
      })
      .catch(function (error) {
        return [];
      });
  };

  fnRefreshRowData = () => {
    this.fnClearInput();
    this.props.onRowDeselect();
    this.props.fnSetRegDate();
  };

  // User Id 존재유무 확인
  fnSaveRowData = (params, insertCallback, updateCallback) => {
    const that = this;
    let url = Server.getRestAPI() + '/users/checkMember.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        const callback = Number(data) > 0 ? updateCallback : insertCallback;
        callback(params);
      })
      .catch(function (error) {
        return [];
      });
  };

  fnClearInput = () => {
    this.setState(
      produce((draft) => {
        draft.title = 'New User';
        draft.params = {};
      }),
      function () {}
    );
  };

  // 사용자 정보를 생성 합니다.
  fnInsertRowData = (params) => {
    const that = this;
    let url = Server.getRestAPI() + '/users/insertMember.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    }).then(function (response) {
      const { data } = response;
      if (data.error) {
        return that.props.alert.error(i18n.t(data.error));
      }
      that.fnRefreshRowData();
    });
  };

  fnUpdateRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/users/updateMember.do';
    // ---------------------------------------------------------
    // (중요) await와 then 은 함께 사용해서는 안됩니다.
    // ---------------------------------------------------------
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    }).then(function (response) {
      const { data } = response;
      if (data.error) {
        return that.props.alert.error(i18n.t(data.error));
      }
      that.fnRefreshRowData();
    });
  };

  onChangeParam = (e) => {
    const { name, value } = e.target;
    this.setState(
      produce((draft) => {
        draft.params[name] = value;
      })
    );
  };

  onChangeGroupId = (value) => {
    const that = this;
    this.setState(
      produce((draft) => {
        draft.params['groupId'] = value;
        if (value === Const.CONSULT) {
          draft.params['userLevel'] = Const.COUNTRY;
          draft.params['ppl'] = undefined;
        }
      }),
      function () {}
    );
  };

  checkMandatoryFields = (params) => {
    let result = false;
    if (_.isEmpty(params.userId)) {
      this.props.alert.error(i18n.t('userId is empty'));
      return result;
    }
    if (_.isEmpty(params.userNm)) {
      this.props.alert.error(i18n.t('userNm is empty'));
      return result;
    }
    if (this.fnIsNewNode() && _.isEmpty(params.userPw)) {
      this.props.alert.error(i18n.t('userPw is empty'));
      return result;
    }
    if (_.isEmpty(params.groupId)) {
      this.props.alert.error(i18n.t('groupId is empty'));
      return result;
    }
    if (_.isEmpty(params.countryCode)) {
      this.props.alert.error(i18n.t('countryCode is empty'));
      return result;
    }

    if (params.groupId !== Const.CONSULT && _.isEmpty(params.userLevel)) {
      this.props.alert.error(i18n.t('userLevel is empty'));
      return result;
    }
    result = true;
    return result;
  };

  // 저장
  onBtSave = () => {
    // 그룹ID 존재유무 확인
    const { params } = this.state;
    if (!this.checkMandatoryFields(params)) return;
    this.fnSaveRowData(params, this.fnInsertRowData, this.fnUpdateRowData);
  };

  onBtClear = () => {
    // const that = this;
    // this.fnClearInput();
    this.props.onRowDeselect();
  };

  setCode = (name, value) => {
    this.setState(
      produce((draft) => {
        draft.params[name] = value;
      }),
      function () {}
    );
  };

  // setCode와 같은 기능 입니다.
  setOnChangeSelect = (value, col) => {
    this.setState(
      { params: { ...this.state.params, [col]: value } },
      function () {}
    );
  };

  setVisable = (visiable) => {
    this.setState({ visible });
  };

  // 파라미터의 변경여부 확인
  fnNotChanged = () => {
    return _.isEqual(this.state.params, this.state.oldParams);
  };

  fnIsNewNode = () => {
    return _.isEmpty(this.props.rowNode);
  };

  render() {
    // if (!this.props.visable) return null;
    const passwordRules = (
      <div>
        <Trans>CJ 요청사항</Trans>
        <br />
        <Trans>암호는 사용자ID를 포함할 수 없습니다.</Trans>
        <br />
        <Trans>암호는 영문자,숫자,특수문자 혼용8자리만 가능합니다.</Trans>
      </div>
    );
    return (
      <Fragment>
        {/* 디자인 영역 */}
        {/* UserInfo Add */}
        <div className="con_box">
          <h3>
            <Trans>User Info</Trans>
          </h3>
          <div className="board_list2">
            <table>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '15%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Trans>User Id</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'userId',
                      that: this,
                      form: this.state.params,
                      value: this.state.params.userId,
                      maxLength: 20,
                      onChange: this.onChangeParam,
                      readOnly: !this.fnIsNewNode(),
                      width: 250,
                    })}
                  </td>
                  <th>
                    <Trans>User Name</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'userNm',
                      that: this,
                      form: this.state.params,
                      value: this.state.params.userNm,
                      maxLength: 50,
                      onChange: this.onChangeParam,
                      width: 250,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Password</Trans>
                    {this.fnIsNewNode() && <span className="red">*</span>}
                  </th>
                  <td>
                    <Input
                      type="password"
                      name="userPw"
                      style={{
                        width: 250,
                      }}
                      placeholder=""
                      value={this.state.params.userPw}
                      maxLength={64}
                      onChange={(e) => this.onChangeParam(e)}
                    />
                  </td>
                  <th>
                    <Trans>Group</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    <Select
                      style={{
                        width: 250,
                        // textTransform: 'uppercase' // placeholder의 글자도 대문자로 표시되는 문제점
                      }}
                      onChange={this.onChangeGroupId}
                      placeholder={i18n.t('Select Code')} // SearchInput에 표시되지 않는 현상 있음.
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={this.state.params.groupId}
                      disabled={this.props.disabled}
                    >
                      {this.state.groupIds.map((row, i) => {
                        return (
                          <Select.Option key={i} value={row}>
                            {row}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Country Code</Trans>
                    <span className="red">*</span>
                  </th>
                  <td>
                    <CountryCode
                      name="countryCode"
                      style={{ width: 250 }}
                      setCode={this.setCode}
                      defaultValue={this.state.params.countryCode}
                    />
                  </td>
                  <th>
                    <Trans>User Level</Trans>
                    {this.state.params.groupId !== Const.CONSULT && (
                      <span className="red">*</span>
                    )}
                  </th>
                  <td>
                    <UserLevel
                      name="userLevel"
                      style={{ width: 250 }}
                      setCode={this.setCode}
                      defaultValue={this.state.params.userLevel}
                      disabled={
                        this.state.params.groupId === Const.CONSULT
                          ? true
                          : false
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Division</Trans>
                  </th>
                  <td>
                    <SearchInput
                      placeholder="Minimum 2 chars or digits"
                      style={{
                        width: 250,
                      }}
                      inputParam="division" // SelectInput의 분류
                      inputName="division" // member variable of this.state.params
                      inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
                      // farmClass="A"
                      defaultValue={this.state.params.division}
                      getValue={(value) => {
                        this.setCode('division', value);
                        this.setCode('region', undefined);
                        this.setCode('subRegion', undefined);
                      }} //***** */
                      that={this} //***** */
                    />
                  </td>
                  <th>
                    <Trans>Farm Code</Trans>
                  </th>
                  <td>
                    <SearchInput
                      placeholder={i18n.t('Minimum 2 chars or digits')}
                      style={{
                        width: 250,
                        // textTransform: 'uppercase'
                      }}
                      inputParam="farmCode" // SelectInput의 분류
                      inputName="farmCode" // member variable of this.state.params
                      inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
                      // farmClass="A"
                      defaultValue={this.state.params.farmCode}
                      getValue={(value) => this.setCode('farmCode', value)} //***** */
                      that={this} //***** */
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Region</Trans>
                  </th>
                  <td>
                    <SearchInput
                      placeholder="Minimum 2 chars or digits"
                      style={{
                        width: 250,
                      }}
                      inputParam="region" // SelectInput의 분류
                      inputName="region" // member variable of this.state.params
                      inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
                      // farmClass="A"
                      pValue0={this.state.params.division} // division이 먼저 선택되어 있어야 활성화
                      defaultValue={this.state.params.region}
                      getValue={(value) => {
                        this.setCode('region', value);
                        this.setCode('subRegion', undefined);
                      }} //***** */
                      that={this} //***** */
                    />
                  </td>
                  <th>
                    <Trans>Hatchery Id</Trans>
                  </th>
                  <td>
                    <SearchInput
                      placeholder="Minimum 2 chars or digits"
                      style={{
                        width: 250,
                      }}
                      inputParam="hatcheryId" // SelectInput의 분류
                      inputName="hatcheryId" // member variable of this.state.params
                      inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
                      // farmClass="A"
                      defaultValue={this.state.params.hatcheryId}
                      getValue={(value) => this.setCode('hatcheryId', value)} //***** */
                      that={this} //***** */
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Sub Region</Trans>
                  </th>
                  <td>
                    <SearchInput
                      placeholder="Minimum 2 chars or digits"
                      style={{
                        width: 250,
                      }}
                      inputParam="subRegion" // SelectInput의 분류
                      inputName="subRegion" // member variable of this.state.params
                      inputMinLen="2" // 검색코드 최소 자리수 : (성능으로 2자리 이상으로 제한) */
                      // farmClass="A"
                      pValue0={this.state.params.division} // division이 먼저 선택되어 있어야 활성화
                      pValue1={this.state.params.region} // division이 먼저 선택되어 있어야 활성화
                      defaultValue={this.state.params.subRegion}
                      getValue={(value) => this.setCode('subRegion', value)} //***** */
                      that={this} //***** */
                    />
                  </td>
                  <th>
                    <Trans>PPL</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'ppl',
                      that: this,
                      form: this.state.params,
                      value: this.state.params.ppl,
                      maxLength: 4,
                      onChange: this.onChangeParam,
                      width: 250,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>PW Changed</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'pwChanged',
                      type: 'YN',
                      that: this,
                      form: this.state.params,
                      disabled: true,
                      value: this.state.params.pwChanged
                        ? this.state.params.pwChanged
                        : 'N',
                      onChange: this.onChangeParam,
                      width: 250,
                    })}
                  </td>
                  <th>
                    <Trans>Disabled</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'disabled',
                      type: 'YN',
                      that: this,
                      form: this.state.params,
                      value: this.state.params.disabled,
                      onChange: this.onChangeParam,
                      width: 250,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Trans>Blocked</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'blocked',
                      type: 'YN',
                      that: this,
                      form: this.state.params,
                      value: this.state.params.blocked,
                      onChange: this.onChangeParam,
                      width: 250,
                    })}
                  </td>
                  <th>
                    <Trans>Deleted</Trans>
                  </th>
                  <td>
                    {FormUtil.renderInput({
                      inputName: 'deleted',
                      type: 'YN',
                      that: this,
                      form: this.state.params,
                      value: this.state.params.deleted,
                      onChange: this.onChangeParam,
                      width: 250,
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="txt_center mT40">
            <button className="btn_gray" onClick={this.onBtClear}>
              <Trans>Clear</Trans>
            </button>
            <button
              className={this.fnNotChanged() ? 'btn_gray' : 'btn_save'}
              onClick={this.onBtSave}
              disabled={this.fnNotChanged()}
            >
              <Trans>Save</Trans>
            </button>
          </div>
        </div>
        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}

export default withAlert()(UserRegist);
