import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { DateUtil, GridUtil } from 'utils';
import { Const, Server } from 'components/Properties';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import { Pagination } from 'antd';
import '../Breeding.module.css';
import scrollToComponent from 'react-scroll-to-component';

/*
 ** Breeding > Deliver > Egg Sales, Egg Discard
 ** 출고
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class EggList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInstance: this,
      value: '',
      transferView: false,
      salesFormView: false,
      discardFormView: false,
      eggTransferMastListView: false,
      epDate: DateUtil.today(),
      hhList: '',
      form: {},
      salesForm: [],
      transferForm: [],
      eggGoodRowData: [],
      eggGoodColumnDefs: [
        {
          field: 'transferNo',
          hide: true,
        },
        {
          headerName: 'Flock',
          field: 'flocdId',
          width: 120,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: 'Date',
          field: 'transferDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
        },
      ],
      eggCullingRowData: [],
      eggCullingColumnDefs: [
        {
          headerName: 'Date',
          field: 'epDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: 'CRACK',
          field: 'crackEggQty',
          type: 'numericColumn',
          width: 150,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'DIRTY',
          field: 'dirtyEggQty',
          type: 'numericColumn',
          width: 150,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'JUMBO',
          field: 'jumboEggQty',
          type: 'numericColumn',
          width: 150,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'SMALL',
          field: 'smallEggQty',
          type: 'numericColumn',
          width: 150,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'MISS SHAPE',
          field: 'missShapeEggQty',
          type: 'numericColumn',
          width: 150,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
      ],
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        suppressSizeToFit: false,
      },
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps !== this.props) {
  //     this.fnSearch();
  //     //this.setState({ form: nextProps.discardForm }, function() {});
  //   }
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.eggListView !== nextProps.eggListView) {
      prevState.currentInstance.fnSearch();
      return {
        eggListView: nextProps.eggListView,
      };
    }
    return null;
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextProps !== this.props) {
  //     this.fnSearch();
  //     //console.log("shouldComponentUpdate: " + JSON.stringify(nextProps) + " " + JSON.stringify(nextState));
  //     return true;
  //   } else if (nextState !== this.state) {
  //     return true;
  //   } else return false;
  // }
  fnSearch = () => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/selectEggReleaseList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        let eggGoodColumnDefs = [
          {
            headerName: 'Flock',
            field: 'flockId',
            width: 150,
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: 'Strain',
            field: 'strainCode',
            width: 100,
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: 'EP Date',
            field: 'epDate',
            width: 100,
            cellStyle: { textAlign: 'center' },
          },
        ];
        if (response.data.hhList) {
          let hhList = response.data.hhList.split(',');
          hhList.forEach((row) => {
            let field = row.split(' ');
            let headDef = {};
            headDef.headerName = field[2];
            headDef.field = field[2].toLowerCase();
            headDef.type = 'numericColumn';
            headDef.width = 100;
            headDef.valueFormatter = function (params) {
              return GridUtil.formatNumber(params);
            };
            headDef.cellStyle = { textAlign: 'right' };
            headDef.valueGetter = function (params) {
              return Number(
                params.data[field[2].toLowerCase()]
                  ? params.data[field[2].toLowerCase()]
                  : 0
              );
            };
            eggGoodColumnDefs.push(headDef);
          });
        }
        that.setState(
          {
            eggGoodColumnDefs: eggGoodColumnDefs,
            eggGoodRowData: response.data.rows1,
            eggCullingRowData: response.data.rows2,
            hhList: response.data.hhList,
          },
          function () {
            that['eggGood'].sizeColumnsToFit();
          }
        );
      })
      .catch(function (error) {
        return [];
      });
  };

  fnClickSales = (e) => {
    this.setState(
      {
        salesFormView: true,
        salesForm: {
          ...this.state.salesForm,
          iuFlag: 'I',
          tranferType: 'S',
        },
      },
      function () {
        scrollToComponent(this['salesFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };
  fnClickTransfer = (e) => {
    this.setState(
      {
        transferFormView: true,
        transferForm: {
          ...this.state.transferForm,
          iuFlag: 'I',
          tranferType: 'T',
        },
      },
      function () {
        scrollToComponent(this['transferFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };
  fnClickDiscard = (e) => {
    this.setState(
      {
        discardFormView: true,
        discardForm: {
          ...this.state.discardForm,
          iuFlag: 'I',
          tranferType: 'D',
        },
      },
      function () {
        scrollToComponent(this['discardFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };
  fnClosePopup = (val) => {
    window.scrollTo(0, 0);
    this.setState({ [val]: false }, function () {});
  };

  onPaginationChange = (current, pageSize) => {
    console.log(current, pageSize);
  };
  formatNumber = (params) => {
    return Math.floor(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };
  onCellClicked = (e) => {};
  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  render() {
    if (!this.props.eggListView) return null;
    return (
      <Fragment>
        <h3>
          <Trans>Good Egg List</Trans>
        </h3>

        <div
          className="board_list ag-theme-balham"
          style={{ height: '300px', width: '100%' }}
        >
          <AgGridReact
            onGridReady={(e) => this.onGridReady(e, 'eggGood')}
            paginationAutoPageSize={true}
            pagination={true}
            columnDefs={this.state.eggGoodColumnDefs}
            rowData={
              this.state.eggGoodRowData.length > 0
                ? this.state.eggGoodRowData
                : []
            }
            getRowHeight={(e) => GridUtil.getRowHeight(e)}
            onCellClicked={(e) => this.onCellClicked(e)}
            onCellFocused={(e) => {
              e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
            }}
          ></AgGridReact>
        </div>

        <h3 className="mT40">
          <Trans>Culling Egg List</Trans>
        </h3>

        <div
          className="board_list ag-theme-balham"
          style={{ height: '300px', width: '100%' }}
        >
          <AgGridReact
            onGridReady={(e) => this.onGridReady(e, 'culling')}
            paginationAutoPageSize={true}
            pagination={true}
            columnDefs={this.state.eggCullingColumnDefs}
            rowData={
              this.state.eggCullingRowData.length > 0
                ? this.state.eggCullingRowData
                : []
            }
            getRowHeight={(e) => GridUtil.getRowHeight(e)}
            onCellClicked={(e) => this.onCellClicked(e)}
            onCellFocused={(e) => {
              e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
            }}
          ></AgGridReact>
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
