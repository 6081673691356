import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import CandlingSettingList from './CandlingSettingList';
import CandlingList from './CandlingList';
import CandlingForm from './CandlingForm';
import { Const, Server } from 'components/Properties';
import scrollToComponent from 'react-scroll-to-component';

import '../Hatchery.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class Candling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      candlingFormView: false,
      regDate: DateUtil.toDateTimeString(),
      iuFlag: 'I',
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  fnClickCandling = (data) => {
    let that = this;
    this.setState(
      {
        form: data,
        candlingFormView: true,
        iuFlag: 'I',
      },
      function () {}
    );
    this.setState(
      {
        form: {
          ...this.state.form,
          fertileQty: this.state.form.settingQty,
        },
      },
      function () {
        scrollToComponent(this['candlingFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };

  fnClickModify = (data) => {
    this.setState(
      {
        form: data,
        candlingFormView: true,
        iuFlag: 'U',
      },
      function () {
        scrollToComponent(this['candlingFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };

  fnClickCancel = () => {
    this.setState({ candlingFormView: false }, function () {});
  };

  fnSetRegDate = () => {
    this.setState({
      regDate: DateUtil.toDateTimeString(),
      candlingFormView: false,
    });
  };

  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Candling</Trans>
        </h2>

        <CandlingSettingList
          fnClickCandling={this.fnClickCandling}
          regDate={this.state.regDate}
        />

        <CandlingForm
          ref={(section) => {
            this.candlingFormView = section;
          }}
          candlingFormView={this.state.candlingFormView}
          form={this.state.form}
          fnClickCancel={this.fnClickCancel}
          fnSetRegDate={this.fnSetRegDate}
          regDate={this.state.regDate}
          iuFlag={this.state.iuFlag}
        />

        <CandlingList
          fnClickModify={this.fnClickModify}
          fnSetRegDate={this.fnSetRegDate}
          regDate={this.state.regDate}
        />

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
