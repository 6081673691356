import * as React from 'react';
import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import { SessionUtil, ObjectUtil } from 'utils';
import { Trans } from 'react-i18next';
import i18n from 'i18n';
import { Tag, Divider, Popover, Button } from 'antd';

import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from 'recharts';

const LayIdxAnlysRechart = (props) => {
  const [width, setWidth] = useState(0);
  const [type, setType] = useState('monotone');
  const [dashArray, setDashArray] = useState('5 5');
  const [currentChart, setCurrentChart] = useState();
  const [rowData, setRowData] = useState(props.rowData);
  const [maxHeight, setMaxHeight] = useState(0);
  const [visible, setVisible] = useState(false);
  const Color = {
    DFS: '#7594B0', // (실선) - Dep.Cum Female
    BFS: '#C28A62', // (실선) - Body Weight Female
    BMS: '#A6A6A6', // (실선) - Body Weight Male
    FFS: '#DCBF5F', // (실선) - Feed Female
    FMS: '#597298', // (실선) - Feed Male
    EPS: '#849C69', // (실선) - EP
    ECS: '#3E5775', // (실선) - EP Cum
    HCS: '#7E502E', // (실선) - HE Cum
    EWS: '#646464', // (실선) - Egg Weight
    SCS: '#847234', // (실선) - SC Cum
    HTS: '#39425B', // (실선) - Hatch
    DFA: '#4E613A', // (점선) - Dep.Cum Female
    EPA: '#8BAACC', // (점선) - EP
    HCA: '#EF9955', // (점선) - HE Cum
    SCA: '#B7B8B9', // (점선) - SC Cum
    BFA: '#FCCB26', // (점선) - Body Weight Female
    BMA: '#708AC7', // (점선) - Body Weight Male
    FFA: '#93BB6C', // (점선) - Feed Female
    FMA: '#337EC2', // (점선) - Feed Male
    EWA: '#D75E04', // (점선) - Egg Weight
    HTA: '#808080', // (점선) - Hatch
  };

  useEffect(() => {
    const conBoxWidth = ObjectUtil.getScrollWidth('con_box');
    setWidth(conBoxWidth);
    setRowData(props.rowData);
    // console.log(props.rowData);
  }, [props.rowData]);

  const weightedInfo = (
    <div>
      <Trans>Dep.Cum Female * 100</Trans>
      <br />
      <Trans>EP * 100</Trans>
      <br />
      {/* <Trans>EP Cum * 50</Trans><br /> EP Cumm은 지표 없으므로 가중치 표기란에서 제외 2020.09.24 */}
      <Trans>HE Cum * 50</Trans>
      <br />
      <Trans>SC Cum * 50</Trans>
      <br />
      <Trans>Feed Female * 10 +9000</Trans>
      <br />
      <Trans>Feed Male * 10 + 9000</Trans>
      <br />
      <Trans>Egg Weight * 100</Trans>
      <br />
      <Trans>Hatch * 100</Trans>
    </div>
  );

  return (
    <React.Fragment>
      <div style={{ textAlign: 'right', marginRight: '62px' }}>
        <Popover
          title={i18n.t('Weighted Information')}
          content={
            <div>
              {weightedInfo}
              <div style={{ textAlign: 'center' }}>
                <button className="btn_gray" onClick={() => setVisible(false)}>
                  Close
                </button>
              </div>
            </div>
          }
          trigger="click"
          visible={visible}
          onVisibleChange={setVisible}
        >
          <Tag color="magenta" style={{ cursor: 'pointer' }}>
            <Trans>The figures on the graph are weighted.</Trans>
          </Tag>
        </Popover>
      </div>
      <LineChart
        ref={(chart) => setCurrentChart(chart)}
        style={{ zIndex: 1 }}
        // onClick={() => {
        //   const chartSVG = ReactDOM.findDOMNode(currentChart).children[0];
        // }}
        width={width}
        height={700}
        data={rowData}
        margin={{ top: 20, right: 60, left: 0, bottom: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="week" />
        <YAxis type="number" domain={[0, 'dataMax + 100']} />
        {/*
          좌표값을 표시하지 않는 것으로 요청 by 김기홍 2020.09.25
        */}
        <Tooltip />
        <Legend
          width={width - 120}
          wrapperStyle={{
            marginLeft: '60px',
            backgroundColor: '#ffffff',
            border: '1px solid #ffffff',
            borderRadius: 3,
            lineHeight: '30px',
          }}
        />
        <Line
          type={type}
          dataKey="Dep.Cum.Std"
          stroke={Color.DFS}
          dot={false}
        />
        <Line
          type={type}
          dataKey="Dep.Cum"
          stroke={Color.DFA}
          strokeDasharray={dashArray}
          dot={false}
        />

        <Line type={type} dataKey="EP.Std" stroke={Color.EPS} dot={false} />
        <Line
          type={type}
          dataKey="EP"
          stroke={Color.EPA}
          strokeDasharray={dashArray}
          dot={false}
        />

        <Line type={type} dataKey="HE.Cum.Std" stroke={Color.HCS} dot={false} />
        <Line
          type={type}
          dataKey="HE.Cum"
          stroke={Color.HCA}
          strokeDasharray={dashArray}
          dot={false}
        />

        <Line type={type} dataKey="SC.Cum.Std" stroke={Color.SCS} dot={false} />
        <Line
          type={type}
          dataKey="SC.Cum"
          stroke={Color.SCA}
          strokeDasharray={dashArray}
          dot={false}
        />

        <Line type={type} dataKey="BW.F.Std" stroke={Color.BFS} dot={false} />
        <Line
          type={type}
          dataKey="BW.F"
          stroke={Color.BFA}
          strokeDasharray={dashArray}
          dot={false}
        />

        <Line type={type} dataKey="BW.M.Std" stroke={Color.BMS} dot={false} />
        <Line
          type={type}
          dataKey="BW.M"
          stroke={Color.BMA}
          strokeDasharray={dashArray}
          dot={false}
        />

        <Line type={type} dataKey="Feed.F.Std" stroke={Color.FFS} dot={false} />
        <Line
          type={type}
          dataKey="Feed.F"
          stroke={Color.FFA}
          strokeDasharray={dashArray}
          dot={false}
        />

        <Line type={type} dataKey="Feed.M.Std" stroke={Color.FMS} dot={false} />
        <Line
          type={type}
          dataKey="Feed.M"
          stroke={Color.FMA}
          strokeDasharray={dashArray}
          dot={false}
        />

        <Line
          type={type}
          dataKey="Egg.Weight.Std"
          stroke={Color.EWS}
          dot={false}
        />
        <Line
          type={type}
          dataKey="Egg.Weight"
          stroke={Color.EWA}
          strokeDasharray={dashArray}
          dot={false}
        />

        <Line type={type} dataKey="Hatch.Std" stroke={Color.HTS} dot={false} />
        <Line
          type={type}
          dataKey="Hatch"
          stroke={Color.HTA}
          strokeDasharray={dashArray}
          dot={false}
        />
      </LineChart>
    </React.Fragment>
  );
};

export default LayIdxAnlysRechart;
