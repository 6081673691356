import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server, Const } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import AFCheckboxSearch from '../utils/AFCheckboxSearch';
import AFDatePickerSearch from '../utils/AFDatePickerSearch';
import AFIndexSearch from '../utils/AFIndexSearch';
import ProjectDateRangeSearch from '../utils/ProjectDateRangeSearch';
import ReportUtil from '../utils/ReportUtil';

import ProjGphAnlysRechart from './ProjGphAnlysRechart';

import '../Report.module.css';

// 4. 주령별 사육현황 리포트
export default class ProjGphAnlysR extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      excelName: i18n.t('Title Proj Anlys R(Gph)'),
      rowData1: [],
      rowData2: [],
      params: {},
      ColDefs: [
        { headerName: 'Index', field: 'kind', pinned: 'left', minWidth: 140 },
        { headerName: i18n.t('D0'), field: 'D0' },
        { headerName: i18n.t('D1'), field: 'D1' },
        { headerName: i18n.t('D2'), field: 'D2' },
        { headerName: i18n.t('D3'), field: 'D3' },
        { headerName: i18n.t('D4'), field: 'D4' },
        { headerName: i18n.t('D5'), field: 'D5' },
        { headerName: i18n.t('D6'), field: 'D6' },
        { headerName: i18n.t('D7'), field: 'D7' },
        { headerName: i18n.t('D8'), field: 'D8' },
        { headerName: i18n.t('D9'), field: 'D9' },
        { headerName: i18n.t('D10'), field: 'D10' },
        { headerName: i18n.t('D11'), field: 'D11' },
        { headerName: i18n.t('D12'), field: 'D12' },
        { headerName: i18n.t('D13'), field: 'D13' },
        { headerName: i18n.t('D14'), field: 'D14' },
        { headerName: i18n.t('D15'), field: 'D15' },
        { headerName: i18n.t('D16'), field: 'D16' },
        { headerName: i18n.t('D17'), field: 'D17' },
        { headerName: i18n.t('D18'), field: 'D18' },
        { headerName: i18n.t('D19'), field: 'D19' },
        { headerName: i18n.t('D20'), field: 'D20' },
        { headerName: i18n.t('D21'), field: 'D21' },
        { headerName: i18n.t('D22'), field: 'D22' },
        { headerName: i18n.t('D23'), field: 'D23' },
        { headerName: i18n.t('D24'), field: 'D24' },
        { headerName: i18n.t('D25'), field: 'D25' },
        { headerName: i18n.t('D26'), field: 'D26' },
        { headerName: i18n.t('D27'), field: 'D27' },
        { headerName: i18n.t('D28'), field: 'D28' },
        { headerName: i18n.t('D29'), field: 'D29' },
        { headerName: i18n.t('D30'), field: 'D30' },
        { headerName: i18n.t('D31'), field: 'D31' },
        { headerName: i18n.t('D32'), field: 'D32' },
        { headerName: i18n.t('D33'), field: 'D33' },
        { headerName: i18n.t('D34'), field: 'D34' },
        { headerName: i18n.t('D35'), field: 'D35' },
        { headerName: i18n.t('D36'), field: 'D36' },
        { headerName: i18n.t('D37'), field: 'D37' },
        { headerName: i18n.t('D38'), field: 'D38' },
        { headerName: i18n.t('D39'), field: 'D39' },
        { headerName: i18n.t('D40'), field: 'D40' },
        { headerName: i18n.t('D41'), field: 'D41' },
        { headerName: i18n.t('D42'), field: 'D42' },
        { headerName: i18n.t('D43'), field: 'D43' },
        { headerName: i18n.t('D44'), field: 'D44' },
        { headerName: i18n.t('D45'), field: 'D45' },
        { headerName: i18n.t('D46'), field: 'D46' },
        { headerName: i18n.t('D47'), field: 'D47' },
        { headerName: i18n.t('D48'), field: 'D48' },
        { headerName: i18n.t('D49'), field: 'D49' },
        { headerName: i18n.t('D50'), field: 'D50' },
        { headerName: i18n.t('D51'), field: 'D51' },
        { headerName: i18n.t('D52'), field: 'D52' },
        { headerName: i18n.t('D53'), field: 'D53' },
        { headerName: i18n.t('D54'), field: 'D54' },
        { headerName: i18n.t('D55'), field: 'D55' },
        { headerName: i18n.t('D56'), field: 'D56' },
        { headerName: i18n.t('D57'), field: 'D57' },
        { headerName: i18n.t('D58'), field: 'D58' },
        { headerName: i18n.t('D59'), field: 'D59' },
        { headerName: i18n.t('D60'), field: 'D60' },
      ],
      gridHeight: '242px',
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        width: 60,
        minWidth: 60,
        cellClass: 'text-center',
        suppressSizeToFit: false,
        valueFormatter: function (params) {
          return GridUtil.formatReport(params);
        },
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagenation: false,
      paginationPageSize: 10,
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    if (Const.RPT_TEST) {
      const params = ReportUtil.getDefaultParams(this.props);
      console.log('params:', params);
      this.fnGetRowData(params);
    }
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  // -----------------------------------------------------------
  // 참고용 : ES/ETL 서버 주소(API)를 지정해야 합니다.
  // -----------------------------------------------------------
  fnGetRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/stat/broiler/projGphAnlysR.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        console.log(data);
        if (data) {
          that.setState({
            rowData1: data.rows1,
            rowData2: data.rows2,
          });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtExport = () => {
    const fileName =
      i18n.t(this.state.excelName) + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t(this.state.excelName);
    const params = {
      allColumns: false,
      columnGroups: true,
      fileName: fileName,
      sheetName: sheetName,
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onSearch = (params) => {
    console.log(params);
    this.fnGetRowData(params);
  };

  render() {
    return (
      <React.Fragment>
        <h3>
          {/* 프로젝트 분석 */}
          <Trans>Title Proj Anlys R(Gph)</Trans>
        </h3>

        <div className="con_box">
          <AFCheckboxSearch onSearch={this.onSearch}></AFCheckboxSearch>

          <div className="f_right mB20">
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
          </div>
          <div className="board_list_s">
            <div style={{ width: '100%' }}>
              <ProjGphAnlysRechart rowData={this.state.rowData2} />
            </div>
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  excelStyles={this.state.excelStyles}
                  onGridReady={this.onGridReady}
                  rowData={this.state.rowData1}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// let i = 0;
// const sampleData = [
//   { kind: 'Population', D1: i++, D2: i++, D3: i++, D4: i++, D5: i++, D6: i++, D7: i++, D8: i++, D9: i++, D10: i++, D11: i++, D12: i++, D13: i++, D14: i++, D15: i++, D16: i++, D17: i++, D18: i++, D19: i++, D20: i++, D21: i++, D22: i++, D23: i++, D24: i++, D25: i++, D26: i++, D27: i++, D28: i++, D29: i++, D30: i++, D31: i++, D32: i++, D33: i++, D34: i++, D35: i++, D36: i++, D37: i++, D38: i++, D39: i++, D40: i++, D41: i++, D42: i++, D43: i++, D44: i++, D45: i++, D46: i++, D47: i++, D48: i++, D49: i++, D50: i++, D51: i++, D52: i++, D53: i++, D54: i++, D55: i++, D56: i++, D57: i++, D58: i++, D59: i++, D60: i++, },
//   { kind: 'Depletion', D1: i++, D2: i++, D3: i++, D4: i++, D5: i++, D6: i++, D7: i++, D8: i++, D9: i++, D10: i++, D11: i++, D12: i++, D13: i++, D14: i++, D15: i++, D16: i++, D17: i++, D18: i++, D19: i++, D20: i++, D21: i++, D22: i++, D23: i++, D24: i++, D25: i++, D26: i++, D27: i++, D28: i++, D29: i++, D30: i++, D31: i++, D32: i++, D33: i++, D34: i++, D35: i++, D36: i++, D37: i++, D38: i++, D39: i++, D40: i++, D41: i++, D42: i++, D43: i++, D44: i++, D45: i++, D46: i++, D47: i++, D48: i++, D49: i++, D50: i++, D51: i++, D52: i++, D53: i++, D54: i++, D55: i++, D56: i++, D57: i++, D58: i++, D59: i++, D60: i++, },
//   { kind: 'Point Feed', D1: i++, D2: i++, D3: i++, D4: i++, D5: i++, D6: i++, D7: i++, D8: i++, D9: i++, D10: i++, D11: i++, D12: i++, D13: i++, D14: i++, D15: i++, D16: i++, D17: i++, D18: i++, D19: i++, D20: i++, D21: i++, D22: i++, D23: i++, D24: i++, D25: i++, D26: i++, D27: i++, D28: i++, D29: i++, D30: i++, D31: i++, D32: i++, D33: i++, D34: i++, D35: i++, D36: i++, D37: i++, D38: i++, D39: i++, D40: i++, D41: i++, D42: i++, D43: i++, D44: i++, D45: i++, D46: i++, D47: i++, D48: i++, D49: i++, D50: i++, D51: i++, D52: i++, D53: i++, D54: i++, D55: i++, D56: i++, D57: i++, D58: i++, D59: i++, D60: i++, },
//   { kind: 'Body Weight', D1: i++, D2: i++, D3: i++, D4: i++, D5: i++, D6: i++, D7: i++, D8: i++, D9: i++, D10: i++, D11: i++, D12: i++, D13: i++, D14: i++, D15: i++, D16: i++, D17: i++, D18: i++, D19: i++, D20: i++, D21: i++, D22: i++, D23: i++, D24: i++, D25: i++, D26: i++, D27: i++, D28: i++, D29: i++, D30: i++, D31: i++, D32: i++, D33: i++, D34: i++, D35: i++, D36: i++, D37: i++, D38: i++, D39: i++, D40: i++, D41: i++, D42: i++, D43: i++, D44: i++, D45: i++, D46: i++, D47: i++, D48: i++, D49: i++, D50: i++, D51: i++, D52: i++, D53: i++, D54: i++, D55: i++, D56: i++, D57: i++, D58: i++, D59: i++, D60: i++, },
//   { kind: 'FCR', D1: i++, D2: i++, D3: i++, D4: i++, D5: i++, D6: i++, D7: i++, D8: i++, D9: i++, D10: i++, D11: i++, D12: i++, D13: i++, D14: i++, D15: i++, D16: i++, D17: i++, D18: i++, D19: i++, D20: i++, D21: i++, D22: i++, D23: i++, D24: i++, D25: i++, D26: i++, D27: i++, D28: i++, D29: i++, D30: i++, D31: i++, D32: i++, D33: i++, D34: i++, D35: i++, D36: i++, D37: i++, D38: i++, D39: i++, D40: i++, D41: i++, D42: i++, D43: i++, D44: i++, D45: i++, D46: i++, D47: i++, D48: i++, D49: i++, D50: i++, D51: i++, D52: i++, D53: i++, D54: i++, D55: i++, D56: i++, D57: i++, D58: i++, D59: i++, D60: i++, },
//   { kind: 'IP', D1: i++, D2: i++, D3: i++, D4: i++, D5: i++, D6: i++, D7: i++, D8: i++, D9: i++, D10: i++, D11: i++, D12: i++, D13: i++, D14: i++, D15: i++, D16: i++, D17: i++, D18: i++, D19: i++, D20: i++, D21: i++, D22: i++, D23: i++, D24: i++, D25: i++, D26: i++, D27: i++, D28: i++, D29: i++, D30: i++, D31: i++, D32: i++, D33: i++, D34: i++, D35: i++, D36: i++, D37: i++, D38: i++, D39: i++, D40: i++, D41: i++, D42: i++, D43: i++, D44: i++, D45: i++, D46: i++, D47: i++, D48: i++, D49: i++, D50: i++, D51: i++, D52: i++, D53: i++, D54: i++, D55: i++, D56: i++, D57: i++, D58: i++, D59: i++, D60: i++, },
// ];
