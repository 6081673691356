import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { message } from 'antd';
import { withAlert } from 'react-alert';

import ScoreMastRegist from './ScoreMastRegist';

import '../Common.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class ScoreMast extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      mastSelected: false,
      params: {
        countryCode: '',
        standardId: '',
        farmClass: props.farmClass,
      },
      regDate: DateUtil.toDateTimeString(),
      rowData: [],
      gridHeight: '365px',
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        // suppressSizeToFit: true
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      paginationPageSize: 15,
      // editType: 'fullRow', // selectEditor와 함께 사용하지 말라고 하네요...
      ColDefs: [
        {
          headerName: i18n.t('Std. Name'),
          field: 'standardName',
          // cellStyle: { textAlign: 'center' },
          getQuickFilterText: function (params) {
            return params.value;
          },
        },
        {
          headerName: i18n.t('countryCode'),
          field: 'countryCode',
          hide: true,
        },
        {
          headerName: i18n.t('Std. Id'),
          field: 'standardId',
          hide: true,
        },
        {
          headerName: i18n.t('Farm Class'),
          field: 'farmClass',
        },
        {
          headerName: i18n.t('Strain Name'),
          field: 'strainName',
        },
        {
          headerName: i18n.t('Strain Code'),
          field: 'strainCode',
          hide: true,
        },
        {
          headerName: i18n.t('Breeding Type'),
          field: 'breedingType',
        },
        {
          headerName: i18n.t('Active Date'),
          field: 'activeDate', // ORACLE.DATE 필드임
          cellRenderer: (params) => {
            const { value } = params;
            // console.log('activeDate:', activeDate);
            return moment(value).format(SessionUtil.getDatetimeFormat());
          },
        },
        {
          headerName: i18n.t('Update Time'),
          field: 'updateTime', // ORACLE.TIMESTAMP 필드임
          cellRenderer: (params) => {
            const { value } = params;
            // console.log('updateTime:', params);
            return moment(value).format(SessionUtil.getDatetimeFormat());
          },
        },
        {
          headerName: i18n.t('Remarks'),
          field: 'remarks',
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    const { params } = this.state;
    this.fnGetRowData(params);
  }

  // deplicated : componentWillReceiveProps(nextProps)
  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log(prevState.regDate, this.state.regDate);
    const { params } = this.state;
    if (prevState.regDate !== this.state.regDate) {
      this.fnGetRowData(params);
    }
  }

  fnSetEditing = (data) => {
    const that = this;
    console.log('setEditing:', data);
    this.setState({
      editing: data,
    });
  };

  onRowSelected = (params) => {
    const that = this;
    if (!isRowSelected(params)) return;
    this.setState(
      produce((draft) => {
        draft.params['countryCode'] = params.data['countryCode'];
        draft.params['standardId'] = params.data['standardId'];
      }),
      that.setState({ mastSelected: true }, that.props.setMastParams(params))
    );
  };

  fnSetRegDate = () => {
    const that = this;
    this.setState(
      {
        regDate: DateUtil.toDateTimeString(),
      },
      that.props.setRegDate()
    );
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  onBtRegist = () => {
    this.setState({ editing: true });
  };

  onBtDelete = () => {
    const that = this;
    const selectedRows = this.gridApi.getSelectedNodes();
    if (!selectedRows || selectedRows.length === 0) {
      return message.error(i18n.t('Not selected row'));
    }

    const selectedRow = selectedRows[0];
    const { data } = selectedRow;

    let param = {};
    param['countryCode'] = data['countryCode'];
    param['standardId'] = data['standardId'];

    this.fnDeleteRowData(param)
      .then(function (response) {
        const { data } = response;
        if (data !== 0) {
          const { params } = that.state; // 주의: param변수가 교체됨.
          that.setState({ mastSelected: false });
          that.fnGetRowData(params);
          that.props.setRegDate();
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onChangeParam = (e) => {
    const { name, value } = e.target;
    this.setState(
      produce((draft) => {
        draft.params[name] = name === 'mesgId' ? value.toUpperCase() : value;
      })
    );
  };

  fnDeleteRowData = (params) => {
    const url = Server.getRestAPI() + '/std/deleteTcStandardMast.do';
    // ---------------------------------------------------------
    // (중요) await와 then 은 함께 사용해서는 안됩니다.
    // ---------------------------------------------------------
    return axios({
      withCredentials: true,
      credentials: 'same-origin',
      method: 'post',
      url: url,
      params: params,
    });
  };

  // 마스터 코드 정보 가져오기
  fnGetRowData = (params) => {
    const that = this;
    let url = Server.getRestAPI() + '/std/selectTcStandardMast.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        // console.log('json.parse:', data);
        if (data) {
          that.setState({ rowData: data });
        }
      })
      .catch(function (error) {
        console.error('data:', error);
        return [];
      });
  };

  render() {
    return (
      <Fragment>
        {/* Maser 리스트 */}
        <div className="con_box">
          <div className="f_left mB20">
            <h3>Master</h3>
          </div>
          <div className="f_right mB20">
            {!this.state.editing && (
              <button className="btn_blue" onClick={this.onBtRegist}>
                <Trans>Regist</Trans>
              </button>
            )}
            <button className="btn_gray" onClick={this.onBtDelete}>
              <Trans>Delete</Trans>
            </button>
          </div>
          <div className="board_list_s">
            <div
              id="myGrid"
              style={{
                height: this.state.gridHeight,
                width: '100%',
              }}
              className="ag-theme-balham"
            >
              <AgGridReact
                modules={this.state.modules}
                columnDefs={this.state.ColDefs}
                // components={this.state.components}
                // frameworkComponents={this.state.frameworkComponents}
                defaultColDef={this.state.defaultColDef}
                rowSelection={this.state.rowSelection}
                editType={this.state.editType}
                // enterMovesDownAfterEdit={true}
                // enterMovesDown={true}
                // rowModelType={this.state.rowModelType}

                onGridReady={this.onGridReady}
                onCellEditingStarted={this.onCellEditingStarted}
                onCellEditingStopped={this.onCellEditingStopped}
                onRowClicked={this.onRowSelected}
                onRowSelected={this.onRowSelected}
                onRowEditingStarted={this.onRowEditingStarted}
                onRowEditingStopped={this.onRowEditingStopped}
                onCellValueChanged={this.onCellValueChanged}
                rowData={this.state.rowData}
                pagination={true}
                paginationPageSize={this.state.paginationPageSize}
              />
            </div>
          </div>
        </div>

        <ScoreMastRegist
          setRegDate={this.fnSetRegDate}
          setEditing={this.fnSetEditing}
          editing={this.state.editing}
          farmClass={this.props.farmClass}
        />

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
