import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';
import i18n from 'i18next';
import produce from 'immer';

import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import 'ag-grid-enterprise';

import axios from 'axios';
import qs from 'qs';
import { Server } from 'components/Properties';
import { Select, Radio, Button, Icon, DatePicker } from 'antd';

import FHDateRangeSearch from '../utils/FHDateRangeSearch';
import AFDateRangeSearch from '../utils/AFDateRangeSearch';

import '../Report.module.css';

// 16. 완료 계군 분석 보고서 (일/Flock 별)
export default class ClsFlkAnlysDF extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      excelName: i18n.t('Closed Flock Analysis Report (Day/Flock)'),
      rowData: [],
      params: {},
      ColDefs: [
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Flock(HH)'),
              field: 'flock',
              pinned: 'left',
              width: 100,
              minWidth: 100,
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Age'),
              field: 'age',
              pinned: 'left',
              width: 100,
              minWidth: 100,
            },
          ],
        },
        {
          headerName: i18n.t(''),
          children: [
            {
              headerName: i18n.t('Prod'),
              field: 'prod',
              pinned: 'left',
              width: 100,
              minWidth: 100,
            },
          ],
        },
        {
          headerName: i18n.t('Population'),
          children: [
            {
              headerName: i18n.t('Female'),
              field: 'pFemale',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('Male'),
              field: 'pMale',
              width: 100,
              minWidth: 100,
            },
          ],
        },
        {
          headerName: i18n.t('Egg Production'),
          children: [
            {
              headerName: i18n.t(''),
              children: [
                {
                  headerName: i18n.t('Egg Good'),
                  field: 'epEggGood',
                  width: 100,
                  minWidth: 100,
                },
              ],
            },
            {
              headerName: i18n.t(''),
              children: [
                {
                  headerName: i18n.t('Culling Egg'),
                  field: 'epCullingEgg',
                  width: 100,
                  minWidth: 100,
                },
              ],
            },
            {
              headerName: i18n.t('%'),
              children: [
                {
                  headerName: i18n.t('Act'),
                  field: 'epAct',
                  width: 100,
                  minWidth: 100,
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'epStd',
                  width: 100,
                  minWidth: 100,
                },
              ],
            },
            {
              headerName: i18n.t('Egg Good Weight'),
              children: [
                {
                  headerName: i18n.t('Act'),
                  field: 'egAct',
                  width: 100,
                  minWidth: 100,
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'egStd',
                  width: 100,
                  minWidth: 100,
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Grade Out'),
          children: [
            {
              headerName: i18n.t('HE'),
              field: 'goHE',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('CE'),
              field: 'goCE',
              width: 100,
              minWidth: 100,
            },
          ],
        },
        {
          headerName: i18n.t('Setting'),
          children: [
            {
              headerName: i18n.t('Qty'),
              field: 'sQty',
              width: 100,
              minWidth: 100,
            },
          ],
        },
        {
          headerName: i18n.t('Fertility'),
          children: [
            {
              headerName: i18n.t('Qty'),
              field: 'fQty',
              width: 100,
              minWidth: 100,
            },
            {
              headerName: i18n.t('%'),
              field: 'fPercent',
              width: 100,
              minWidth: 100,
            },
          ],
        },
        {
          headerName: i18n.t('Hatchability'),
          children: [
            {
              headerName: i18n.t(''),
              children: [
                {
                  headerName: i18n.t('Qty'),
                  field: 'hQty',
                  width: 100,
                  minWidth: 100,
                },
              ],
            },
            {
              headerName: i18n.t('%'),
              children: [
                {
                  headerName: i18n.t('Act'),
                  field: 'hpAct',
                  width: 100,
                  minWidth: 100,
                },
                {
                  headerName: i18n.t('Std'),
                  field: 'hpStd',
                  width: 100,
                  minWidth: 100,
                },
              ],
            },
            {
              headerName: i18n.t(''),
              children: [
                {
                  headerName: i18n.t('DOC'),
                  field: 'hDOC',
                  width: 100,
                  minWidth: 100,
                },
              ],
            },
            {
              headerName: i18n.t(''),
              children: [
                {
                  headerName: i18n.t('Cull'),
                  field: 'hCull',
                  width: 100,
                  minWidth: 100,
                },
              ],
            },
          ],
        },
        {
          headerName: i18n.t('Saleable Chick'),
          children: [
            {
              headerName: i18n.t('DOC'),
              children: [
                {
                  headerName: i18n.t('Qty'),
                  field: 'sdQty',
                  width: 100,
                  minWidth: 100,
                },
                {
                  headerName: i18n.t('Cumm'),
                  field: 'sdCumm',
                  width: 100,
                  minWidth: 100,
                },
              ],
            },
            {
              headerName: i18n.t('Per Hen House'),
              children: [
                {
                  headerName: i18n.t('Act/Bird'),
                  field: 'spActBird',
                  width: 100,
                  minWidth: 100,
                },
                {
                  headerName: i18n.t('Std/Bird'),
                  field: 'spStdBird',
                  width: 100,
                  minWidth: 100,
                },
                {
                  headerName: i18n.t('Act Cumm'),
                  field: 'spActCumm',
                  width: 100,
                  minWidth: 100,
                },
                {
                  headerName: i18n.t('Std Cumm'),
                  field: 'spStdCumm',
                  width: 100,
                  minWidth: 100,
                },
              ],
            },
          ],
        },
      ],
      gridHeight: '365px',
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        cellClass: 'text-center',
        suppressSizeToFit: false,
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      enableCellChangeFlash: true,
      rowSelection: 'single',
      pagenation: false,
      paginationPageSize: 10,
      excelStyles: [
        {
          id: 'textFormat',
          font: { size: 12 },
        },
      ],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
    this.setState({ rowData: sampleData });
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  // -----------------------------------------------------------
  // 참고용 : ES/ETL 서버 주소(API)를 지정해야 합니다.
  // -----------------------------------------------------------
  fnGetRowData = (params) => {
    const that = this;
    const url = Server.getRestAPI() + '/work/selectTcTaskDtl.do';
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        const { data } = response;
        if (data) {
          that.setState({ rowData: data });
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  onBtExport = () => {
    const fileName =
      i18n.t(this.state.excelName) + ' ' + DateUtil.toDateTimeString();
    const sheetName = i18n.t(this.state.excelName);
    const params = {
      allColumns: false,
      columnGroups: true,
      fileName: fileName,
      sheetName: sheetName,
      suppressTextAsCDATA: true,
      rowHeight: 20,
    };
    this.gridApi.exportDataAsExcel(params);
  };

  onSearch = (params) => {
    console.log(params);
    this.fnGetRowData(params);
  };

  render() {
    return (
      <React.Fragment>
        <h3>
          {/*  16. 완료 계군 분석 보고서 (일/Flock 별) */}
          <Trans>Hat EP, Daily/HH R</Trans>
        </h3>

        <div className="con_box">
          <FHDateRangeSearch onSearch={this.onSearch}></FHDateRangeSearch>

          <div className="f_right mB20">
            <button className="btn_sky" onClick={this.onBtExport}>
              <Trans>Excel Export</Trans>
            </button>
          </div>
          <div className="board_list_s">
            <div className="board_list2">
              <div
                id="myGrid"
                style={{
                  height: this.state.gridHeight,
                  width: '100%',
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  // modules={AllModules}
                  columnDefs={this.state.ColDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowSelection={this.state.rowSelection}
                  excelStyles={this.state.excelStyles}
                  onGridReady={this.onGridReady}
                  rowData={this.state.rowData}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const sampleData = [
  {
    flock: 0,
    age: 1,
    prod: 2,
    pFemale: 3,
    pMale: 4,
    epEggGood: 5,
    epCullingEgg: 6,
    epAct: 7,
    epStd: 8,
    egAct: 9,
    egStd: 10,
    goHE: 11,
    goCE: 12,
    sQty: 13,
    fQty: 14,
    fPercent: 15,
    hQty: 16,
    hpAct: 17,
    hpStd: 18,
    hDOC: 19,
    hCull: 20,
    sdQty: 21,
    sdCumm: 22,
    spActBird: 23,
    spStdBird: 24,
    spActCumm: 25,
    spStdCumm: 26,
  },
];
