import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';

import '../Breeding.module.css';
/*
 ** breeding/receive/HenHouseList
 ** 계사(HenHouse-HH)
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class HenHouseInList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      prop: {},
      rowData: []
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(this.props, nextProps);
    if (this.props === nextProps) {
      return null;
    }
  }
  fnSearch = () => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    //data.receiveNo = '';
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/receive/selectHenHouseList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        console.log('selectTpFlockInfo', response.data);

        that.setState({ rowData: response.data.rows1 }, function() {
          console.log(that.state);
        });
      })
      .catch(function(error) {
        return [];
      });
  };
  //Hen House Move click
  fnClickMove = row => {
    this.props.fnClickMove(row);
  };
  fnClickCancel = row => {
    this.props.fnClickCancel(row);
  };
  fnClickEmpty = row => {};
  fnClickIn = (row, tp) => {
    //console.log('fnClickIn', row);
    if (tp === 'E') {
      //EMPTY
      row.flockInQtyF = 0;
      row.flockInQtyM = 0;
      //row.chickinQtyF = 0;
      //row.chickinQtyM = 0;
      row.currentQtyF = 0;
      row.currentQtyM = 0;
    }

    this.props.fnClickIn(row, this.props.hhFlockRow);
  };
  hhButton = row => {
    //console.log('hhButton', this.props, row);
    console.log('hhButton', this.props.flockListFlockId, row.flockId, row.capa);
    console.log('hhButton', this.props.hhId, row.hhId);
    if (this.props.flockListFlockId === row.flockId && row.capa > 0) {
      if (this.props.hhId !== row.hhId) {
        return (
          <dd>
            <button
              className="btn_gray2"
              onClick={e => this.fnClickIn(row, '')}
            >
              <Trans>In</Trans>
            </button>
          </dd>
        );
      } else {
        return (
          <dd>
            <button
              className="btn_gray2"
              onClick={e => this.fnClickCancel(row, 'N')}
            >
              <Trans>Cancel</Trans>
            </button>
          </dd>
        );
      }
      // } else if (this.props.flockListFlockId === '') {
      //   return (
      //     <dd>
      //       <button className="btn_gray2" onClick={e => this.fnClickMove(row)}>
      //         <Trans>move</Trans>
      //       </button>
      //     </dd>
      //   );
    } else {
      return null;
    }
  };
  hhEmptyButton = row => {
    if (this.props.flockListFlockId) {
      return (
        <dd>
          <button className="btn_gray2" onClick={e => this.fnClickIn(row, 'E')}>
            <Trans>In</Trans>
          </button>
        </dd>
      );
      // } else if (this.props.flockListFlockId === '') {
      //   return (
      //     <dd>
      //       <button className="btn_gray2">
      //         <Trans>EMPTY</Trans>
      //       </button>
      //     </dd>
      //   );
    } else {
      return null;
    }
  };
  render() {
    if (!this.props.hhInView) return null;
    let that = this;
    let hhChickinList = [];
    let cc = 0;
    let cc10 = 0;
    const ccClass = 'full_01 h';
    const ccClass100 = 'full_03 h100';
    if (this.props.hhChickinList && this.props.hhChickinList.length > 0) {
      hhChickinList = this.props.hhChickinList.map((row, i) => {
        if (that.props.hhFlockRow) {
          row.flockInQtyF = that.props.hhFlockRow.flockInQtyF;
          row.flockInQtyM = that.props.hhFlockRow.flockInQtyM;
        }
        row.propsHhId = that.props.hhId;
        if (row.flockId) {
          cc = parseInt(
            ((row.chickinQtyF + row.chickinQtyM) / row.capacity) * 100,
            10
          );
          cc10 = parseInt(cc / 10, 10) * 10;
          row.capa = row.capacity - (row.chickinQtyF + row.chickinQtyM);
          console.log('HenHouseList', row, that.props.hhFlockRow);
          return (
            <div key={i} className="full">
              <dl className="reinfo">
                <dt>
                  <Trans>{row.hhId}</Trans>
                </dt>
                <dd>{row.chickinDate}</dd>
                <dd>
                  <Trans>Days :</Trans> {row.days.toLocaleString()}
                </dd>
                <dd>
                  <Trans>Weeks :</Trans> {row.weeks.toLocaleString()}
                </dd>
                <dd>
                  <Trans>F</Trans> : {row.chickinQtyF.toLocaleString()}
                </dd>
                <dd>
                  <Trans>M </Trans>: {row.chickinQtyM.toLocaleString()}
                </dd>
                <dd>
                  <Trans>FlockId </Trans>: {row.flockId}
                </dd>
                {/* 병아리가 들어있는 계사는 Move 버튼 활성화 */}
                {that.hhButton(row)}
                {/* 
                <dd>
                  <button
                    className="btn_gray2"
                    onClick={e => this.fnClickMove(row)}
                  >
                    <Trans>move</Trans>
                  </button>
                </dd>
                 */}
              </dl>
              <div
                className={cc10 === 100 ? ccClass100 : ccClass + cc10}
                title={cc}
              ></div>
            </div>
          );
        } else {
          return (
            <div key={i} className="full">
              <dl className="reinfo">
                <dt>
                  <Trans>{row.hhId}</Trans>
                </dt>
                <dd>
                  <Trans>EMPTY</Trans>
                </dd>
                {this.hhEmptyButton(row)}
                {/* 
                <dd>
                  <button
                    className="btn_gray2"
                    onClick={e => this.fnClickEmpty(row)}
                  >
                    <Trans>EMPTY</Trans>
                  </button>
                </dd> */}
              </dl>
            </div>
          );
        }
      });
    }
    return (
      <Fragment>
        {/* Hen House */}
        <li className="divide_right">
          <div className="dititle">
            <i className="mdi mdi-grid"></i> <Trans>Hen House In</Trans>
          </div>
          {hhChickinList}
        </li>
      </Fragment>
    );
  }
}
export default HenHouseInList;
