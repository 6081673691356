import React, { Fragment } from 'react';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';
import scrollToComponent from 'react-scroll-to-component';

import { moment } from 'components';
import CandlingDiscardForm from './CandlingDiscardForm';
import CandlingListForDiscard from './CandlingListForDiscard';
import CandlingDiscardHistList from './CandlingDiscardHistList';
import '../Hatchery.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class CandlingDiscard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CandlingDiscardFormView: false,
      rowData: [],
      regDate: DateUtil.toDateTimeString(),
      iuFlag: 'I',
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  fnClickGrade = (data) => {
    console.log(data);
    this.setState(
      { CandlingDiscardFormView: true, rowData: data, iuFlag: 'I' },
      function () {
        scrollToComponent(this['CandlingDiscardFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );
  };

  fnSetRegDate = () => {
    this.setState({
      regDate: DateUtil.toDateTimeString(),
      CandlingDiscardFormView: false,
    });
  };

  fnClickModify = (data) => {
    this.setState(
      {
        rowData: data,
        CandlingDiscardFormView: true,
        iuFlag: 'U',
      },
      function () {
        scrollToComponent(this['CandlingDiscardFormView'], {
          offset: 0,
          align: 'top',
          duration: 1000,
        });
      }
    );

    if (data.fertileQty) {
      this.setState({
        rowData: {
          ...this.state.rowData,
          discardQtyInput: data.fertileQty,
        },
      });
    }
  };

  fnClickCancel = () => {
    this.setState({ CandlingDiscardFormView: false }, function () {});
  };

  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Candling Discard</Trans>
        </h2>

        <CandlingListForDiscard
          fnClickGrade={this.fnClickGrade}
          regDate={this.state.regDate}
        />

        <CandlingDiscardForm
          ref={(section) => {
            this.CandlingDiscardFormView = section;
          }}
          CandlingDiscardFormView={this.state.CandlingDiscardFormView}
          rowData={this.state.rowData}
          fnSetRegDate={this.fnSetRegDate}
          iuFlag={this.state.iuFlag}
          fnClickCancel={this.fnClickCancel}
        />

        <CandlingDiscardHistList
          regDate={this.state.regDate}
          fnClickModify={this.fnClickModify}
          fnSetRegDate={this.fnSetRegDate}
        />

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
