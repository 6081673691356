import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { Popconfirm, Switch, message, DatePicker, Select } from 'antd';
import NumberFormat from 'react-number-format';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class PsDiscardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: [],
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (nextProps.discardForm !== this.props.discardForm) {
  //     this.setState({ form: nextProps.discardForm }, function() {});
  //   }
  // }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.discardFormView !== nextProps.discardFormView) {
      console.log('getDerivedStateFromProps', prevState, nextProps);
      return {
        discardFormView: nextProps.discardFormView,
        form: nextProps.discardForm,
      };
    }
    return null;
  }
  //Sales 저장
  fnSave = () => {
    let that = this;
    let data = this.state.form;

    data.closingQty = data.closingQty ? Number(data.closingQty) : 0;
    data.cullingQty = data.cullingQty ? Number(data.cullingQty) : 0;

    if (!data.discardDate) {
      that.props.alert.show(i18n.t('Please enter DiscardDate.'));
      that.discardDate.focus();
      return false;
    }
    if (data.closingQty === 0 && data.cullingQty === 0) {
      that.props.alert.show(i18n.t('Please enter ClosingQty.'));
      that.closingQty.focus();
      return false;
    }

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();

    console.log('save', data);
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/savePsDiscard.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.props.fnInit();
      })
      .catch(function (error) {
        return [];
      });
  };
  //Write Qty & Press Send
  fnSend = () => {
    let that = this;
    let cullingRelease = Number(this.state.form.cullingRelease);
    let closingRelease = Number(this.state.form.closingRelease);

    let cullingStock = Number(this.state.form.cullingStock); //재고
    let closingStock = Number(this.state.form.closingStock); //재고

    if (cullingRelease > cullingStock) {
      that.props.alert.show(i18n.t('The quantity is exceeded.'));
      this.cullingRelease.focus(); //NumberFormat은 focus만
      return false;
    }
    if (closingRelease > closingStock) {
      that.props.alert.show(i18n.t('The quantity is exceeded.'));
      this.closingRelease.focus(); //NumberFormat은 focus만
      return false;
    }

    this.setState(
      {
        form: {
          ...this.state.form,
          cullingQty: Number(this.state.form.cullingRelease),
          closingQty: Number(this.state.form.closingRelease),
        },
      },
      function () {}
    );
  };

  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text

  setOnChange = (e, params) => {
    let that = this;
    let col = '';
    let val = '';
    params.type = params.type ? params.type.toUpperCase() : '';
    if (params.type === 'SELECT') {
      //Select
      col = params.inputName;
      val = e;
    } else if (params.type === 'D' || params.type === 'T') {
      col = params.inputName;
      val = e;
    } else {
      col = e.target.name ? e.target.name : '';
      val = e.target.value;
    }
    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
      if (params.maxVal) {
        if (Number(val) > Number(params.maxVal)) {
          val = Number(params.maxVal);
        }
      }
    }

    that.setState({ form: { ...this.state.form, [col]: val } }, function () {
      if (params.type === 'N') {
        let closingStock = Number(
          that.state.form[col.replace('Release', 'Stock')]
        );
        if (Number(val) > closingStock) {
          that.setState({
            form: {
              ...that.state.form,
              [col]: 0,
              [col.replace('Release', 'Qty')]: 0,
            },
          });
          //useAlert.error('You just broke something!');
          that.props.alert.show(i18n.t('The quantity is exceeded.'));
          return false;
        }
        that.setState({
          form: {
            ...that.state.form,
            [col.replace('Release', 'Qty')]: Number(val),
          },
        });
      }
    });
  };

  fnCancel = () => {
    this.setState(
      {
        form: this.props.discardForm,
      },
      function () {}
    );
  };

  render() {
    if (!this.props.discardFormView) return null;
    const data = this.state.form;
    return (
      <Fragment>
        {/* <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            margin: 'auto',
            zIndex: 1000,
            backgroundColor: 'rgba(0,0,0, 0.5)'
          }}
        >
          <div
            style={{
              position: 'absolute',
              width: '900px',
              height: '300px',
              left: '15%',
              right: '15%',
              top: '50px',
              //bottom: '15%',
              margin: 'auto',
              borderRadius: '10px',
              background: 'white',
              scrollBehavior: 'smooth'
            }}
          > */}
        {/* 디자인 영역 */}
        <h3>
          <Trans>Discard</Trans>
        </h3>

        <div className="board_list2">
          <table>
            <colgroup>
              <col style={{ width: '20%' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '20%' }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <Trans>Date</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'discardDate', //*** */
                    type: 'D', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    width: 120, // px제외 : 없으면 100% */
                    placeholder: 'discardDate',
                  })}
                </td>
                <th>
                  <Trans>Remarks</Trans>
                </th>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'remarks', //*** */
                    type: '', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    //width: 120, // px제외 : 없으면 100% */
                    maxLength: 100,
                    //placeholder: 'Date',
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="board_list mT20 mB20">
          <table>
            <colgroup>
              <col style={{ width: '25%' }} />
              <col style={{ width: '25%' }} />
              <col style={{ width: '25%' }} />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th colSpan="2">
                  <Trans>Closing</Trans>
                </th>
                <th colSpan="2">
                  <Trans>Culling</Trans>
                </th>
              </tr>
              <tr>
                <th>
                  <Trans>Available Qty</Trans>
                </th>
                <th>
                  <Trans>Discard Qty</Trans>
                </th>
                <th>
                  <Trans>Available Qty</Trans>
                </th>
                <th>
                  <Trans>Discard Qty</Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {data.closingStock
                    ? this.state.form.closingStock.toLocaleString()
                    : '0'}
                </td>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'closingRelease', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    //width: 120, // px제외 : 없으면 100% */
                    //dec: 2,
                    //maxLength: 6,
                    //maxVal: 999.99,
                    minVal: 0,
                  })}
                </td>
                <td>
                  {data.cullingStock ? data.cullingStock.toLocaleString() : '0'}
                </td>
                <td>
                  {GridUtil.renderInput({
                    inputName: 'cullingRelease', //*** */
                    type: 'N', //*** */
                    that: this, //*** */
                    statsForm: this.state.form, //*** */
                    setOnChange: this.setOnChange, //*** */
                    //width: 120, // px제외 : 없으면 100% */
                    //dec: 2,
                    //maxLength: 6,
                    //maxVal: 999.99,
                    minVal: 0,
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="txt_center mT10 mB10">
          <button className="btn_gray" onClick={() => this.fnCancel()}>
            <Trans>Cancel</Trans>
          </button>
          <button className="btn_save" onClick={() => this.fnSave()}>
            <Trans>save</Trans>
          </button>
        </div>
      </Fragment>
    );
  }
}
export default withAlert()(PsDiscardForm);
