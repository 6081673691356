import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';
import { moment } from 'components';
import i18n from 'i18next';
import axios from 'axios';
import qs from 'qs';
import { Const, Server } from 'components/Properties';
import { Popconfirm, Switch, message, DatePicker, Select, Modal } from 'antd';
import NumberFormat from 'react-number-format';
import {
  AgGridReact,
  AllModules,
  isRowSelected,
} from 'components/commons/ag-grid/AgGridUtil';
import { withAlert } from 'react-alert';
import '../Breeding.module.css';

const { confirm } = Modal;
/*
 **
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class EggSalesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInstance: this,
      deleteConfirm: false,
      transferTypeS: true,
      transferTypeT: true,
      transferTypeD: true,
      customerName: '',
      searchFromWkDt: moment(DateUtil.today())
        .add('-1', 'M')
        .format('YYYY-MM-DD'),
      searchToWkDt: DateUtil.today(),
      form: {
        transferDate: DateUtil.today(),
      },
      toFarmCodeFocus: false,
      eggTransferMastList: [],
      rowData: [],
      columnDefs: [
        {
          headerName: '',
          field: 'delete',
          width: 110,
          //cellRenderer: 'buttonRenderer',
          cellRenderer: function (params) {
            //if (params.data.salesDate === DateUtil.today()) {
            //return '<input type="button" style="color:red" value="&nbsp;&nbsp;Delete&nbsp;&nbsp;"/>';
            return '<button class="btn_del">Delete</button>';

            //var eButton = eDiv.querySelectorAll('.btn-simple')[0];

            //eButton.addEventListener('click', function() {
            //  console.log('button was clicked!!');
            //});

            // return eDiv; //'<button className="btn_save" style="color: red">&nbsp;&nbsp;Delete&nbsp;&nbsp;</button>';
            //} else return null;
          },
          // cellRendererParams: {
          //   //onClick: this.onBtnClick.bind(this),
          //   label: 'Delete',
          //   btnClass: 'btn_gray'
          // },
          cellStyle: { textAlign: 'center' },
          // editable: true,
          // cellRenderer: params => {
          //   return '<button style="color: #000;width:150;">&nbsp;&nbsp;Delete&nbsp;&nbsp;</button>';
          // }
          suppressSizeToFit: true,
        },
        {
          headerName: 'Date',
          field: 'salesDate',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Type',
          field: 'transferType',
          width: 100,
          cellStyle: { textAlign: 'center' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Vendor',
          field: 'customerName',
          width: 200,
          cellStyle: { textAlign: 'left' },
          suppressSizeToFit: true,
        },

        {
          headerName: 'Good Qty',
          field: 'goodEggQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Good Weight',
          field: 'goodEggWeight',
          type: 'numericColumn',
          width: 100,
          // valueFormatter: function (params) {
          //   return GridUtil.formatNumber(params);
          // },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Crack Qty',
          field: 'crackEggQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Crack Weight',
          field: 'crackEggWeight',
          type: 'numericColumn',
          width: 100,
          // valueFormatter: function (params) {
          //   return GridUtil.formatNumber(params);
          // },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Dirty Qty',
          field: 'dirtyEggQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Dirty Weight',
          field: 'dirtyEggWeight',
          type: 'numericColumn',
          width: 100,
          // valueFormatter: function (params) {
          //   return GridUtil.formatNumber(params);
          // },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Jumbo Qty',
          field: 'jumboEggQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Jumbo Weight',
          field: 'jumboEggWeight',
          type: 'numericColumn',
          width: 100,
          // valueFormatter: function (params) {
          //   return GridUtil.formatNumber(params);
          // },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Small Qty',
          field: 'smallEggQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Small Weight',
          field: 'smallEggWeight',
          type: 'numericColumn',
          width: 100,
          // valueFormatter: function (params) {
          //   return GridUtil.formatNumber(params);
          // },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Thin Qty',
          field: 'thinEggQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Thin Weight',
          field: 'thinEggWeight',
          type: 'numericColumn',
          width: 100,
          // valueFormatter: function (params) {
          //   return GridUtil.formatNumber(params);
          // },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Miss Shape Qty',
          field: 'missShapeEggQty',
          type: 'numericColumn',
          width: 100,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
        {
          headerName: 'Miss Shape Weight',
          field: 'missShapeEggWeight',
          type: 'numericColumn',
          width: 100,
          // valueFormatter: function (params) {
          //   return GridUtil.formatNumber(params);
          // },
          cellStyle: { textAlign: 'right' },
          suppressSizeToFit: true,
        },
      ],
      eggTransferHhRowData: [],
      eggTransferHhColumnDefs: [
        {
          field: 'transferNo',
          hide: true,
        },
        {
          headerName: 'Ep Date',
          field: 'epDate',
          width: 120,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: 'Good',
          field: 'goodEggQty',
          type: 'numericColumn',
          width: 150,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Crack',
          field: 'crackEggQty',
          type: 'numericColumn',
          width: 150,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Dirty',
          field: 'dirtyEggQty',
          type: 'numericColumn',
          width: 150,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Jumbo',
          field: 'jumboEggQty',
          type: 'numericColumn',
          width: 150,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Small',
          field: 'smallEggQty',
          type: 'numericColumn',
          width: 150,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Thin',
          field: 'thinEggQty',
          type: 'numericColumn',
          width: 150,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
        {
          headerName: 'Miss Shape',
          field: 'missShapeEggQty',
          type: 'numericColumn',
          width: 150,
          valueFormatter: function (params) {
            return GridUtil.formatNumber(params);
          },
          cellStyle: { textAlign: 'right' },
        },
      ],
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        editable: false,
        suppressSizeToFit: false,
      },
      rowSelection: 'single',
    };
  }
  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());

    this.setState(
      { form: { ...this.state.form, searchFromWkDt: DateUtil.today() } },
      function () {}
    );
    //필수 항목 setFocus를 위한 정의
    this.transferDate = React.createRef();
    this.flockId = React.createRef();
    this.searchFromWkDt = React.createRef();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.eggSalesListView !== nextProps.eggSalesListView) {
      prevState.currentInstance.fnSearch('F');
      return {
        form: nextProps.transferForm,
        eggSalesListView: nextProps.eggSalesListView,
      };
    }
    return null;
  }
  onGridReady = (params, grid) => {
    this[grid] = params.api;
    this[grid + 'ColumnApi'] = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  fnSearch = (t) => {
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.searchFromWkDt = this.state.searchFromWkDt;
    data.searchToWkDt = this.state.searchToWkDt;

    if (t !== 'F') {
      if (!data.searchFromWkDt) {
        this.searchFromWkDt.current.focus();
        return false;
      }
    }

    if (
      (this.state.transferTypeS ? "'S'" : '') !== '' &&
      (this.state.transferTypeD ? "'D'" : '') !== ''
    ) {
      data.transferType = '';
    } else if ((this.state.transferTypeS ? "'S'" : '') !== '') {
      data.transferType = 'Sales';
    } else if ((this.state.transferTypeD ? "'D'" : '') !== '') {
      data.transferType = 'Discard';
    } else return;

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/selectTpEggSalesMastList.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        let data = response.data.rows1 ? response.data.rows1 : [];
        that.setState({ rowData: data }, function () {});
      })
      .catch(function (error) {
        return [];
      });
  };
  //Sales 저장
  fnSave = () => {
    let that = this;
    let data = this.state.form;
    console.log(data);

    if (data.iuFlag === 'I' && !data.transferDate) {
      data.transferDate = DateUtil.today();
    }
    if (!data.transferDate) {
      that.props.alert.show(i18n.t('Please enter Transfer Date.'));

      this.transferDate.current.focus();
      return false;
    }
    if (!data.flockId) {
      that.props.alert.show(i18n.t('Please enter FlockId.'));
      this.flockId.current.focus();
      return false;
    }

    if (!data.toFarmCode) {
      that.props.alert.show(i18n.t('Please enter FarmCode.'));
      this.setState({ toFarmCodeFocus: true }, function () {});
      //this.customerCode.focus();
      return false;
    }

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    if (data.iuFlag === 'I') {
      //Type
    }

    console.log('save', data);
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/release/savePsMoveout.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        console.log('savePsMoveout', response.data);
        that.props.fnSearch();
      })
      .catch(function (error) {
        return [];
      });
  };

  //Grid row 선택
  onCellClicked = (e) => {};
  onCellClickedMast = (e) => {
    let that = this;

    if (e.column.colId === 'delete') {
      if (e.data.salesDate === DateUtil.today()) {
        confirm({
          title: i18n.t('Do you Want to delete these items?'),
          content: i18n.t('The history below will also be deleted.'),
          onOk() {
            that.props.alert.show(i18n.t('Deleted.'));
            that.fnDelete(e.data);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      } else {
        // 판매일이 오늘이 아닌경우
        that.props.alert.error(
          DateUtil.today() + ' ' + i18n.t('only delete possible')
        );
      }
    }
  };
  onSelectionChanged = (params, grid) => {
    var e = this[grid].getSelectedRows()[0]; //rowSelection: "single"
    let data = {};
    let that = this;
    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.transferNo = e.transferNo;
    data.discardNo = e.discardNo;

    console.log(e, data);
    let url = Server.getRestAPI();
    if (data.transferNo !== 0) {
      url += '/breed/release/selectTpEggSalesDtlList.do';
    } else if (data.discardNo !== 0) {
      url += '/breed/release/selectTpEggDiscardDtlList.do';
    } else return false;
    axios({
      method: 'post',
      url: url,
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.setState(
          {
            customerName: e.customerName,
            eggTransferHhRowData: response.data.rows1,
          },
          function () {}
        );
      })
      .catch(function (error) {
        return [];
      });
  };

  fnDelete = (data) => {
    let that = this;

    data.farmCode = SessionUtil.getFarmCode();
    data.dateFormat = SessionUtil.getDateFormat();
    data.iuFlag = 'D';

    console.log('delete', data);
    let url = Server.getRestAPI();
    if (data.transferNo !== 0) {
      url += '/breed/release/saveEggSales.do';
    } else if (data.discardNo !== 0) {
      url += '/breed/release/saveEggDiscard.do';
    } else return false;

    axios({
      method: 'post',
      url: url,
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.fnSearch();
        that.props.fnSearch();
      })
      .catch(function (error) {
        return [];
      });
  };
  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  renderInput = (inputName, ty, dec, len) => {
    let cl = len ? 'input_type1 w' + len : 'input_type1';

    if (ty && ty === 'N') {
      cl = len ? 'input_base w' + len : 'input_base';
      return (
        <NumberFormat
          style={{ textAlign: 'right' }}
          className={cl}
          thousandSeparator={true}
          decimalScale={dec ? dec : 0}
          name={inputName}
          //ref={this[inputName]}
          getInputRef={(el) => (this[inputName] = el)}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : null
          }
          onChange={(e) => this.setOnChange(e, ty)}
        />
      );
    } else if (ty && ty === 'T') {
      return (
        <TimePicker
          //defaultValue={moment('12:08', 'HH:mm')}
          className={cl}
          format={'HH:mm'}
          ref={this[inputName]}
          value={
            this.state.form[inputName]
              ? moment(this.state.form[inputName], 'HH:mm') || ''
              : null
          }
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
        />
      );
    } else if (ty && ty === 'S') {
      return (
        <span>
          {this.state.form[inputName] ? this.state.form[inputName] || '' : ''}
        </span>
      );
    } else if (ty && ty === 'D') {
      return (
        <DatePicker
          name={inputName}
          style={{ width: len ? len : null }}
          ref={this[inputName]}
          value={this.state[inputName] ? moment(this.state[inputName]) : ''}
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
          allowClear={false}
        />
      );
    } else {
      return (
        <input
          type="text"
          name={inputName}
          className={cl}
          ref={this[inputName]}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : ''
          }
          onChange={(e) => this.setOnChange(e)}
        />
      );
    }
  };
  setOnChange = (e, ty) => {
    const col = e.target.name ? e.target.name : '';
    let val = e.target.value;
    if (ty === 'N') {
      val = val.replace(/,/gi, '');
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          [col]: val,
        },
      },
      function () {
        if (ty === 'N') {
          this.fnCalcEggGood();
        }
      }
    );
  };
  setOnChangeTime = (date, dateString, inputName) => {
    const col = inputName ? inputName : '';
    this.setState({ [col]: dateString }, function () {});
  };
  setOnChangeSelect = (value, col) => {
    this.setState(
      { form: { ...this.state.form, [col]: value } },
      function () {}
    );
  };

  //Salse - Transfer - Discard 선택 checkbox
  onCheckboxChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.checked }, function () {});
  };
  formatNumber = (params) => {
    return Math.floor(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };
  render() {
    let cullingStock = [];
    if (!this.props.eggSalesListView) return null;
    const data = this.state.form;

    return (
      <Fragment>
        <h3 className="mT20">
          <Trans>Sales / Discard List</Trans>
        </h3>

        <div className="border mB20 txt_center">
          <p className="spac50_right">
            <input
              type="checkbox"
              name="transferTypeS"
              id="1"
              checked={this.state.transferTypeS}
              onChange={this.onCheckboxChangeHandler}
            />
            <label htmlFor="1">
              <Trans>Sales</Trans>
            </label>
            <input
              type="checkbox"
              name="transferTypeD"
              id="3"
              checked={this.state.transferTypeD}
              onChange={this.onCheckboxChangeHandler}
            />
            <label htmlFor="3">
              <Trans>Discard</Trans>
            </label>
          </p>

          <div className="mT10">
            <label htmlFor="">
              <Trans>Date</Trans>
            </label>
            {this.renderInput('searchFromWkDt', 'D', 0, 120)}
            &nbsp; ~ &nbsp;
            {this.renderInput('searchToWkDt', 'D', 0, 120)}
            <button className="btn_gray" onClick={() => this.fnSearch()}>
              <Trans>Search</Trans>
            </button>
          </div>
        </div>

        <div className="board_list" style={{ height: '300px' }}>
          <div
            className="f_right ag-theme-balham"
            style={{ height: '100%', width: '100%' }}
          >
            <AgGridReact
              onGridReady={(e) => this.onGridReady(e, 'mast')}
              columnDefs={this.state.columnDefs}
              rowData={this.state.rowData.length > 0 ? this.state.rowData : []}
              onCellClicked={(e) => this.onCellClickedMast(e)}
              onSelectionChanged={(e) => this.onSelectionChanged(e, 'mast')}
              getRowHeight={(e) => GridUtil.getRowHeight(e)}
              rowSelection={'single'}
              onCellFocused={(e) => {
                e.api.gridOptionsWrapper.gridOptions.suppressCellSelection = true;
              }}
            ></AgGridReact>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default withAlert()(EggSalesList);
